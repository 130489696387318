<script>/* eslint-disable */</script>
<template>
    <div>
        <b-card title="Summary Log" :sub-title="infoPopup.title" header-tag="h5" class="mb-4">
            <hr>
            <b-form>
                <b-form-row>
					<div class="col-md-12 col-12" >
						<div class="row">
							<b-form-group label="Month" class="col-md-6 col-12" style="height:42px" >
								<Multiselect 
									class="mt-1"
									:options="filterData.month"
									v-model="filter.month"
									label="name"
									track-by="value"
								/>
							</b-form-group>
							<b-form-group label="Year" class="col-md-6 col-12" style="height:42px" >
								<Multiselect 
									class="mt-1"
									:options="filterData.year"
									v-model="filter.year"
								/>
							</b-form-group>
						</div>
					</div>
				</b-form-row>
                <b-form-row>
                    <b-form-group class="col-md-2 mt-3">
                        <b-btn class="w-100" type="button" variant="primary" @click="search(0)" :disabled="isFetching">Filter</b-btn>
                    </b-form-group>
					<b-form-group class="col-md-2 mt-3">
                        <b-btn class="w-100" type="button" variant="success" @click="search(1)" :disabled="isFetching"><span class="fa fa-file-excel"></span>&nbsp;&nbsp;Export To Excel</b-btn>
                    </b-form-group>
                </b-form-row>
            </b-form>
        </b-card>

        <div class="row mt-5 mb-5" v-if="isFetching">
            <div class="col">
                <h1 class="text-center" style="
                color: #616161;
                text-shadow: 4px 4px 6px #BDBDBD;">Loading...</h1>
            </div>
        </div>

        <div class="row mb-2" v-if="!isFetching">

            <div class="col-12">
                <b-card no-body class="mb-4">
                    <div style="overflow-x:scroll">
                        <logTable :data="data"></logTable>
                    </div>
                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect  from 'vue-multiselect'
import moment, { months } from 'moment'

import logTable from './childComponents/logTable'
export default {
    components: {
		Multiselect ,
		// Components
		logTable
    },
    data() {
		let startOfYear = new moment().startOf('year');

        return {
            isFetching : false,

            filterData: Object.assign({} , this.$store.state.filterData),
            filter: {
                // from_month:  {
                //     name: startOfYear.format("MMMM"),
                //     value: startOfYear.format("M")
                // },
				// from_year:  startOfYear.format("YYYY"),
				month:  {
                    name: new moment().format("MMMM"),
                    value: new moment().format("M")
                },
                year:  new moment().format("YYYY")
            },
			data: null,
            mobile: this.isMobile(),
            infoPopup : this.$store.state.hoverInformation.report.summaryLog
        }
    },
    mounted () {
        this.search(0)
    },
    methods: {
        async search (flag) {
            this.isFetching = true;

            let filter = await this.transformFilter(this.filter)
			filter = await this.initializeFilter(filter)
			if(flag == 0)
				this.loadAPI(filter)
			else
				this.downloadExcel(filter)
		},
		downloadExcel (filter) {
			let url = '/api/reports/summary/log/download-excel' + filter

			this.$http.get(url , {responseType : 'blob'})
            .then((resp)=>{
                const blob = new Blob([resp.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                let url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = `Summary Log ${this.filter.from_month.name} ${this.filter.from_year} To ${this.filter.to_month.name} ${this.filter.to_year}`;
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((err)=>{
                console.log(err);
            })
            .finally(()=>{
                this.isFetching = false;
            })

		},
        loadAPI (filter) {
            this.data = null
            let url = "/api/reports/summary/log" + filter

            this.$http.get(url)
            .then((resp) => {
                this.data = resp.data.data;                
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(()=>{
                this.isFetching = false;
            })
        },
        async initializeFilter (filters) {
            let filterUrl = '?'
            let keys = Object.keys(filters)
            for (var i = 0; i < keys.length; i++) {
                if(typeof filters[keys[i]] == 'object' && filters[keys[i]].length > 0)
                    filterUrl += `${keys[i]}=${filters[keys[i]]}&`
                else if (typeof filters[keys[i]] != 'object') {
                    filterUrl += `${keys[i]}=${filters[keys[i]]}&`
                }
            }
            
            return filterUrl
        },
        async transformFilter (filter) {
            let obj = {}
            let keys = Object.keys(filter)
            for (var i = 0 ; i < keys.length; i++) {
				 if (keys[i].includes('month')) {
                    obj[keys[i]] = filter[keys[i]].value
                } else {
                    obj[keys[i]] = filter[keys[i]]
                }
            }
            return obj
		},
		isMobile() {
			if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				return true
			} else {
				return false
			}
		}
    }
}
</script>

<style scoped>
	.Aligner {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.Aligner-item {
		max-width: 50%;
	}

	.rounded {
		border-radius: 10%;
	}
</style>