<template>
    <div id="frequencyVisitChart" style="width: 100%; height: 500px"></div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import axios from "axios";

am4core.useTheme(am4themes_animated);

export default {
    name: "FrequencyVisitChart",
    props: {
        startDate: {
            type: String,
            required: true,
        },
        endDate: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            chart: null,
        };
    },
    mounted() {
        this.fetchDataAndCreateChart();
    },
    beforeDestroy() {
        if (this.chart) {
            this.chart.dispose();
        }
    },
    watch: {
        startDate() {
            this.fetchDataAndCreateChart();
        },
        endDate() {
            this.fetchDataAndCreateChart();
        },
    },
    methods: {
        fetchDataAndCreateChart() {
            axios
                .get(`/api/distribution/tracking/frequency-visit`, {
                    params: {
                        start: this.startDate,
                        end: this.endDate,
                    },
                })
                .then((response) => {
                    const chartData = response.data.data;
                    this.createChart(chartData);
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        },
        createChart(data) {
            if (this.chart) {
                this.chart.dispose();
            }

            let chart = am4core.create(
                "frequencyVisitChart",
                am4charts.XYChart
            );
            chart.data = data;

            // Create X-axis
            let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "freq_visit";
            categoryAxis.title.text = "Frequency Visit";
            categoryAxis.renderer.minGridDistance = 30;

            // Create Y-axis
            let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.title.text = "Total Motor (Engine Number)";

            // Create series
            let series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueY = "total_unit";
            series.dataFields.categoryX = "freq_visit";
            series.name = "Total Units";

            // Update the tooltip text
            series.columns.template.tooltipText =
                "[bold]{valueY}[/] Engine Number";

            series.columns.template.fill = am4core.color("#4e80ed");
            series.columns.template.stroke = am4core.color("#4e80ed");
            series.columns.template.column.cornerRadiusTopLeft = 5;
            series.columns.template.column.cornerRadiusTopRight = 5;
            series.columns.template.column.cornerRadiusBottomLeft = 5;
            series.columns.template.column.cornerRadiusBottomRight = 5;

            // Add cursor
            chart.cursor = new am4charts.XYCursor();

            this.chart = chart;
        },
    },
};
</script>

<style scoped>
#frequencyVisitChart {
    width: 100%;
    height: 500px;
}
</style>
