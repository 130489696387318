<template>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper" @click="$emit('closeModal')">
          <div class="row">
            <div class="col-xs-0 col-sm-0 col-md-1 col-lg-2"></div>
            <div class="col-xs-12 col-sm-12 col-md-10 col-lg-8">
              <div class="modal-container" @click.stop>
  
                <div class="modal-header row" style="justify-content: flex-end;">
                  <!-- <div class="col-6">
                    <button type="button" class="btn btn-sm btn-outline-primary" @click="$emit('newUserBack')">< Back</button>
                  </div> -->
                  <div class="col">
                    <button type="button" class="close" @click="$emit('closeModal')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
  
                <div class="modal-body">
                  <h2 class="title text-center">Set Custom Date Range</h2>
                  <form>
                    <div class="row row-space">
                      <div class="col-xs-12 col-md-6">
                        <div class="input-group">
                          <label class="label">Start<span style="color: red;">*</span></label>
                          <flat-pickr
                            v-model="formReturn.start"
                            :config="rangeConfig"
                            placeholder="Choose Start Date"
                          ></flat-pickr>
                        </div>
                      </div>
                      <div class="col-xs-12 col-md-6">
                        <div class="input-group">
                          <label class="label">End<span style="color: red;">*</span></label>
                          <flat-pickr
                            v-model="formReturn.end"
                            :config="rangeConfig"
                            placeholder="Choose End Date"
                          ></flat-pickr>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col mt-5" style="text-align: center">
                        <button type="button" class="btn btn-outline-danger" style="font-size:140%" @click="$emit('closeModal')">Back</button>
                        <button type="button" class="btn btn-primary ml-5" style="font-size:150%" @click="submitForm()">Done</button>
                      </div>
                    </div>
                  </form>
                </div>
            </div>
          </div>
        </div>
        <div class="col-xs-0 col-sm-0 col-md-1 col-lg-2"></div>
      </div>
    </div>
  </transition>
  </template>
  
  <script>
    import moment from 'moment';
    import flatPickr from 'vue-flatpickr-component';
  
    export default {
      data () {
        return {
          rangeConfig: {
            altInput: true,
            altInputClass: 'form-control input flatpickr-input w-100',
            animate: !this.isRTL
          },
          formReturn : {
            start : moment().startOf('month').format('YYYY-MM-DD 00:00:00'),
            end : moment().format('YYYY-MM-DD 23:59:59'),
          }
        }
      },
      components : {
        flatPickr
      },
      props:['currentSelected'],
      mounted () {
        this.formReturn = JSON.parse(JSON.stringify(this.currentSelected));
      },
      methods : {
        validateForm(){
            var valid = true;
            var error = [];
    
            if(this.formReturn.start == '' || this.formReturn.start == null){//Start [Required]
                valid = false;
                error.push('Start date is required');
            }
  
            if(this.formReturn.end == '' || this.formReturn.end == null){//End [Required]
                valid = false;
                error.push('End date is required');
            }

            if(moment(this.formReturn.end).diff(moment(this.formReturn.start), 'seconds') < 0){//If End > Start date
                valid = false;
                error.push('Start date must be earlier than end date');
            }
  
            if(!valid) this.$store.dispatch('triggerAlert', [false, error]);
            else return valid;
        },
        submitForm(){
          if(this.validateForm()){
            this.$emit('setCustomDate', this.formReturn);
          }
        },
      }
    }
  </script>
  
  <style scoped>
    .label {
      font-size: 16px;
      color: #555;
      text-transform: capitalize;
      display: block;
      margin-bottom: 5px;
    }
  </style>
  