<template>
    <div>
      <!--Filter Option-->
      <b-card title="Error Report" :sub-title="infoPopup.title" header-tag="h6" class="mb-4">
        <hr>
          <b-form>
              <b-form-row>
                <b-form-group label="Month" class="col-md-3">
                    <Multiselect
                      v-model="filter.month"
                      :options="options.month.map(x => x.value)"
                      :custom-label="opt => options.month.find(x => x.value == opt).name"
                      :allow-empty="false"
                    ></Multiselect>
                </b-form-group>
                <b-form-group label="Year" class="col-md-3">
                    <Multiselect
                      v-model="filter.year"
                      :options="options.year"
                      :allow-empty="false"
                    ></Multiselect>
                </b-form-group>
                <b-form-group label="Status" class="col-md-3">
                    <Multiselect
                      v-model="filter.status"
                      :options="options.status.map(x => x.value)"
                      :custom-label="opt => options.status.find(x => x.value == opt).name"
                      placeholder="All Status"
                    ></Multiselect>
                </b-form-group>
                <b-form-group label="Code" class="col-md-3">
                    <Multiselect
                      v-model="filter.code"
                      :options="options.code"
                      placeholder="All Codes"
                    ></Multiselect>
                </b-form-group>
              </b-form-row>
              <b-btn @click="fetchData(0)" :disabled="isFetching" class="col-md-2" type="button" variant="primary"><span class="fa fa-filter"></span>&nbsp;&nbsp;Search</b-btn>
              <b-btn @click="fetchData(1)" :disabled="isFetching" class="col-md-2" type="button" variant="success"><span class="fa fa-file-excel"></span>&nbsp;&nbsp;Export To Excel</b-btn>
          </b-form>
      </b-card>
  
      <b-card class="mb-4">
        <vue-universal-table
          v-if="data && universal_fields"
          :data="data"
          :fields="universal_fields"
          :disable_sort="true"
          @fetchStart="isFetching = true"
          @fetchEnd="isFetching = false"
        ></vue-universal-table>
        <fold v-else :loading="true"></fold>
      </b-card>
    </div>
  </template>
  
  <script>
  import qs from 'qs'
  import XLSX from 'xlsx'

  import flatPickr from 'vue-flatpickr-component'
  import moment from 'moment'
  import Multiselect  from 'vue-multiselect'
  import { FoldSpinner } from 'vue-spinners'
  
  export default {
    metaInfo: {
      title: 'Error Report'
    },
    data () {
      return {
        isFetching : false,

        rangeConfig: {
          altInput: true,
          animate: !isRTL()
        },
        infoPopup : this.$store.state.hoverInformation.report.errorReport,
        options : {
            month : this.$store.getters.getMonth,
            year : this.$store.getters.getYear,
            status : [
                {name:"Success", value: 1},
                {name:"Failed", value: 0}
            ],
            code : [
                "40",
                "45",
                "48",
                "49",
                "50",
                "200",
                "400",
                "401",
                "500",
                "503"
            ]
        },
        filter : {
          month : (new Date()).getMonth()+1,
          year : (new Date()).getFullYear(),
          status : null,
          code : null
        },
        vue_universal_table : {
          fields : [
            {
              title : 'Date',
              variable : 'DATE'
            },
            {
              title : 'SLAHMTOMD',
              variable : 'SLAHMTOMD',
              number_formatting : true
            },
            {
              title : 'GRAHMtoMD',
              variable : 'GRAHMtoMD',
              number_formatting : true
            },
            {
              title : 'Unit From AHM to MD',
              variable : 'UNIT FROM AHM TO MAIN DEALER',
              number_formatting : true
            },
            {
              title : 'GIMDTOD',
              variable : 'GIMDTOD',
              number_formatting : true
            },
            {
              title : 'Unit From MD to D',
              variable : 'UNIT FROM MAIN DEALER TO DEALER',
              number_formatting : true
            },
            {
              title : 'Unit From MD to POS',
              variable : 'UNIT FROM MAIN DEALER TO POS',
              number_formatting : true
            },
            {
              title : 'Unit From MD to Cust',
              variable : 'UNIT FROM MAIN DEALER TO CUSTOMER',
              number_formatting : true
            },
            {
              title : 'SPG',
              variable : 'SPG',
              number_formatting : true
            },
            {
              title : 'BSTK',
              variable : 'BSTK',
              number_formatting : true
            },
            {
              title : 'Unit From D to Cust',
              variable : 'UNIT FROM DEALER TO CUSTOMER',
              number_formatting : true
            },
            {
              title : 'CreateVehicle',
              variable : 'CREATEVEHICLE',
              number_formatting : true
            },
            {
              title : 'MapEngineQR',
              variable : 'MAPENGINEQR',
              number_formatting : true
            },
            {
              title : 'MapEngineQRH2',
              variable : 'MAPENGINEQRH2',
              number_formatting : true
            },
            {
              title : 'GRCust',
              variable : 'GRCUST',
              number_formatting : true
            },
            {
              title : 'Service',
              variable : 'SERVICE',
              number_formatting : true
            },
            {
              title : 'UpdateTrackService',
              variable : 'UPDATETRACKSERVICE',
              number_formatting : true
            },
            {
              title : 'Map Cust',
              variable : 'MAPCUST',
              number_formatting : true
            }
          ],
          update : 0,
        },
        data : null,
        monthNames : ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      }
    },
    components: {
      flatPickr,
      Multiselect,
      fold : FoldSpinner
    },
    mounted () {
      this.fetchData();
    },
    computed : {
      universal_fields () {
        return !this.data ? null : this.vue_universal_table.fields.filter((x)=>{return Object.keys(this.data[0]).indexOf(x.variable) != -1})
      },
    },
    methods: {
      fetchData(exportExcel){
        this.isFetching = true;
        this.data = null;
        this.$http.get(`/api/reports/error-report${qs.stringify(this.filter, {skipNulls:true, addQueryPrefix:true})}`)
        .then((resp)=>{
          let allKeys = this.vue_universal_table.fields.map((x)=>{return x.variable.toUpperCase()})
          let respKeys = Object.keys(resp.data.data[0]);

          //convert all object keys to uppercase
          let data = resp.data.data.map((x)=>{
            let obj = {}
            for (var i in respKeys) obj[respKeys[i].toUpperCase()] = x[respKeys[i]]
            console.log(obj);
            return obj;
          })

          //zero fill the rest
          data = data.map((x)=>{
            let obj = {}
            for (var i in allKeys) obj[allKeys[i].toUpperCase()] = x[allKeys[i]] ? x[allKeys[i]] : 0
            return obj
          });

          this.data = data;

          if (exportExcel) this.exportExcel();
        })
        .catch((err)=>{console.log(err)})
        .finally(()=>{
          this.isFetching = false;
        })
      },
      exportExcel(type) {
        let wb = XLSX.utils.book_new()
        let exportFileName = `Error Log ${this.monthNames(this.filter.month-1)} ${this.filter.year}.xlsx`
        var ws = XLSX.utils.json_to_sheet(this.data, {raw:true});
        XLSX.utils.book_append_sheet(wb, ws, 'Data')
        XLSX.writeFile(wb, exportFileName)
      },
      initialiseFilter(){
        if(!(typeof this.filter === 'object' && this.filter !== null)){
          this.filterUrl = '';
          return;
        }
        var keys = Object.keys(this.filter);
        let stringUrl = '';
        var queryParams = this.filter;
  
        for(var i = 0 ; i < keys.length; i++){
          if(!queryParams[keys[i]])
            continue;
  
          if(queryParams[keys[i]] == '' || queryParams[keys[i]].length == 0)
            continue 
  
          if((keys[i] == 'user_type') && queryParams[keys[i]] == 'All')
            continue;
          
          // if(keys[i] == 'start_hour' || keys[i] == 'end_hour')
          //   queryParams[keys[i]] += ":00";
  
          stringUrl += `&${keys[i]}=${queryParams[keys[i]]}`;
          
        }
        return stringUrl;
      },
    }
  }
  
  function isRTL () {
    return document.documentElement.getAttribute('dir') === 'rtl' || document.body.getAttribute('dir') === 'rtl'
  }
  </script>
  
  <style>
    .dateCss div input{
      border: 1px solid #e8e8e8;
      border-radius: 5px !important;
      height: 42px;
      margin-top: 3px;
      padding-left: 1% !important;
    }
    .dateCss div input::placeholder{
      padding-left: 1%;
    }
  </style>
  