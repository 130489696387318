<template>
	<div>
		<!-- <div class="row">
			<div class="col-md-3">
				<button class="btn w-100" :class="{'btn-outline-secondary' : area != 'Jawa Timur' , 'btn-secondary' : area == 'Jawa Timur'}" type="button" @click="area = 'Jawa Timur'">Jawa Timur</button>
			</div>
			<div class="col-md-3">
				<button class="btn w-100" :class="{'btn-outline-secondary' : area != 'NTT' , 'btn-secondary' : area == 'NTT'}" type="button" @click="area = 'NTT'">NTT</button>
			</div>
		</div> -->

		<div class="row mt-3">
			<div class="col-12 border border-secondary rounded" style="background-color:white">
				<Map :area="area" :data="data" v-if="data"></Map>
			</div>
		</div>
	</div>
</template>

<script>
import Map from './Map'

export default {
	components: {
		Map
	},
	mounted () {
		console.log(this.data)
	},
	props: ['area','data'],
	data () {
		return {
		}
	}
}
</script>