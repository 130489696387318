<template>
  <div ref='customerIdentity' style='min-height:450px'></div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

export default {
  data () {
    return {
      chart: null
    }
  },
  props : ['filterUrl'],
  mounted () {
    this.createChart()
    this.fetchData()
  },
  watch : {
    'filterUrl' : function () {
      this.fetchData();
    }
  },
  methods: {
      fetchData(){
        let vm = this
        var length = vm.chart.data.length

        this.$http.get('/api/h2/qr-registration/customer-identifications?' + this.filterUrl)
        .then((resp)=>{
          var data = resp.data.data;
          vm.chart.addData((data != null && data.filter(desc=>desc.count>0).length != 0) ? data : {description : 'No Data', count : 1} , length)
        })
        .catch((err)=>{
          console.log(err);
        })
      },
    createChart () {
      this.chart = am4core.create(this.$refs.customerIdentity, am4charts.PieChart);

      // Add and configure Series
      let pieSeries = this.chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "count";
      pieSeries.dataFields.category = "description";

      // Let's cut a hole in our Pie chart the size of 30% the radius
      this.chart.innerRadius = am4core.percent(30);

      // Put a thick white border around each Slice
      pieSeries.slices.template.stroke = am4core.color("#fff");
      pieSeries.slices.template.strokeWidth = 2;
      pieSeries.slices.template.strokeOpacity = 1;
      pieSeries.slices.template
        // change the cursor on hover to make it apparent the object can be interacted with
        .cursorOverStyle = [
          {
            "property": "cursor",
            "value": "pointer"
          }
        ];

      pieSeries.alignLabels = false;
      pieSeries.labels.template.bent = true;
      pieSeries.labels.template.radius = 3;
      pieSeries.labels.template.padding(0,0,0,0);

      pieSeries.ticks.template.disabled = true;

      // Create a base filter effect (as if it's not there) for the hover to return to
      let shadow = pieSeries.slices.template.filters.push(new am4core.DropShadowFilter);
      shadow.opacity = 0;

      // Create hover state
      let hoverState = pieSeries.slices.template.states.getKey("hover"); // normally we have to create the hover state, in this case it already exists

      // Slightly shift the shadow and make it more prominent on hover
      let hoverShadow = hoverState.filters.push(new am4core.DropShadowFilter);
      hoverShadow.opacity = 0.7;
      hoverShadow.blur = 5;

      // Add a legend
      this.chart.legend = new am4charts.Legend();
    }
  }
}
</script>
