<template>
  <div ref="byDate" style="min-height: 700px"></div>
</template>

<script>
  import * as am4core from "@amcharts/amcharts4/core";
  import * as am4charts from "@amcharts/amcharts4/charts";

  export default {
    data () {
      return {
        chart : null,
      }
    },
    props : ['filterUrl'],
    mounted () {
      this.createChart();
      this.fetchData();
    },
    watch : {
      "filterUrl" : function (){
        this.fetchData();
      }
    },
    methods : {
      fetchData () {
        let vm = this;
        var length = this.chart.data.length;

        this.$http.get('/api/h2/qr-registration/by-day?' + this.filterUrl)
        .then((resp)=>{
          var data = resp.data.data;
          for(var i = 0; i < data.length; i++){
            data[i].tanggal  = data[i].tanggal.slice(0,10)
          }
          vm.chart.addData(data, length);
        })
        .catch((err)=>{
          console.log(err);
        })
      },
      createChart () {
        this.chart = am4core.create(this.$refs.byDate, am4charts.XYChart);

        // Set input format for the dates
        this.chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";

        var dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.renderer.minGridDistance = 40;

        var valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());

        // Create series
        var series = this.chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = "count";
        series.dataFields.dateX = "tanggal";
        series.strokeWidth = 1;

        var bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.stroke = am4core.color("#fff");
        var labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = "[bold]{valueY}";


        // Create axes
        // let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
        // let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());

        // // Create series
        // let series = this.chart.series.push(new am4charts.LineSeries());
        // series.dataFields.valueY = "count";
        // series.dataFields.dateX = "tanggal";
        // series.tooltipText = "{dateX} : {count}"
        // series.strokeWidth = 2;
        // series.minBulletDistance = 15;
        // console.log(series)


        // // Drop-shaped tooltips
        // 
        // // series.tooltip.showTooltipOn = 'always'
        // // series.tooltip.alwaysShowTooltip = true
        

        // // Make bullets grow on hover
        // let bullet = series.bullets.push(new am4charts.CircleBullet());
        // bullet.circle.strokeWidth = 2;
        // bullet.circle.radius = 4;
        // bullet.circle.fill = am4core.color("#fff");
        // // bullet.propertyFields.showTooltipOn = "showTooltip";

        // // bullet.showTooltipOn = "always";

        // let bullethover = bullet.states.create("hover");
        // bullethover.properties.scale = 1.3;

        // // Make a panning cursor
        this.chart.cursor = new am4charts.XYCursor();
        // this.chart.cursor.behavior = "panXY";
        // this.chart.cursor.xAxis = dateAxis;
        // this.chart.cursor.snapToSeries = series;

        // // Create vertical scrollbar and place it before the value axis
        // this.chart.scrollbarY = new am4core.Scrollbar();
        // this.chart.scrollbarY.parent = this.chart.leftAxesContainer;
        // this.chart.scrollbarY.toBack();

        // Create a horizontal scrollbar with previe and place it underneath the date axis
        // this.chart.scrollbarX = new am4charts.XYChartScrollbar();
        // this.chart.scrollbarX.series.push(series);
        // this.chart.scrollbarX.parent = this.chart.bottomAxesContainer;

        // dateAxis.start = 0.79;
        // dateAxis.keepSelection = true;
      }
    }
  }
</script>

<style scoped>

</style>