<template>
  <div>
    <div class="row">
      <div class="col-md-4 col-12 mt-2 highlightFilter">
        <Multiselect 
					class="mt-1 "
					:options="[{ code: 'destination' , name : 'By Destination ( Out )'} , { code: 'origin' , name : 'By Origin ( In )'}]"
					:searchable="true"
					:close-on-select="true"
					:show-labels="false"
					label="name"
					:allow-empty="false"
					v-model="filter.by"
					placeholder="All Destination"
				/>
      </div>
      <div class="col-md-8 col-12 mt-2">
        <div ref="movementStockDetailsChart" style="height:300px;"></div>
      </div>
    </div>
    
    <div ref="legend" style="height:100px"></div>
  </div>
</template>

<script>
  import * as am4core from "@amcharts/amcharts4/core";
  import * as am4charts from "@amcharts/amcharts4/charts";
  import am4themes_animated from "@amcharts/amcharts4/themes/animated";
  import Multiselect  from 'vue-multiselect'

  export default {
    data () {
      return {
        chart : null,
      }
    },
    components: {
      Multiselect 
    },
    props : ['filter', 'period', 'data' , 'options'],
    mounted () {
      this.createChart();
      this.fetchData();
    },

    methods : {
      fetchData () {
        var length = this.chart.data.length;
        let data = []
        let total = 0

        for (var i = 0 ; i < this.data.length ; i ++) {
          let obj = {
            destination : this.data[i].lokasi + " - " + this.data[i].name,
            dValue: this.data[i].jml
          }
          total += this.data[i].jml
          data.push(obj)
        }

        if(data.length <= 0 ) {
          data.push({
            dValue: 1,
            destination: 'No Data'
          })
        }
        this.label2.text =this.numberWithCommas(total)

        this.chart.addData(data, length)
      },
      createChart () {
        /* Chart code */
        // Themes begin
        am4core.useTheme(am4themes_animated);
        // Themes end

        // Create chart instance
        this.chart = am4core.create(this.$refs.movementStockDetailsChart, am4charts.PieChart);

        // Let's cut a hole in our Pie chart the size of 40% the radius
        this.chart.innerRadius = am4core.percent(40);

        // Add and configure Series
        let pieSeries = this.chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "dValue";
        pieSeries.dataFields.category = "destination";
        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeWidth = 2;
        pieSeries.slices.template.strokeOpacity = 1;

        // Disable sliding out of slices
        pieSeries.slices.template.states.getKey("hover").properties.shiftRadius = 0;
        pieSeries.slices.template.states.getKey("hover").properties.scale = 0.9;

        // Add second series

        // Disabling labels and ticks on inner circle and outer circle
        pieSeries.labels.template.disabled = true;
        pieSeries.ticks.template.disabled = true;

        // Add Legend
        var legendContainer = am4core.create(this.$refs.legend, am4core.Container);
        legendContainer.width = am4core.percent(100);
        legendContainer.height = am4core.percent(100);
        this.chart.legend = new am4charts.Legend()
        this.chart.legend.parent = legendContainer
        // this.chart.legend.position="right";
        // this.chart.legend.valign="middle";

        //Add Label
        var label = pieSeries.createChild(am4core.Label);
        label.text = "Unit Left";
        label.horizontalCenter = "middle";
        label.verticalCenter = "bottom";
        label.fontSize = 15;

        this.label2 = pieSeries.createChild(am4core.Label);
        this.label2.horizontalCenter = "middle";
        this.label2.verticalCenter = "top";
        this.label2.fontWeight = "bold";
        this.label2.fontSize = 25;
      }
    }
  }
</script>

<style scoped>
  
</style>