<template>
    <div class="w-100 h-100">
        <div class="row">
            <div class="col-md" v-for="(button, index) in options">
                <button
                    type="button"
                    class="btn"
                    :class="{ 'btn-outline-primary' : period != index , 'btn-primary' : period == index}"
                    style="width:100%"
                    @click="period = index"
                >{{ button.title }}</button>
            </div>
            <!-- Custom Start -->
            <div class="col-md">
                <button
                    type="button"
                    class="btn"
                    :class="{ 'btn-outline-primary' : period != 100 , 'btn-primary' : period == 100}"
                    style="width:100%"
                    @click="modal.customDate = true"
                >Range Date</button>
            </div>
            <!-- Custom End -->
        </div>

        <!-- Modal Start -->
        <custom-period
            v-if="modal.customDate"
            :currentSelected="form"
            @closeModal="modal.customDate = false"
            @setCustomDate="setCustomDate"
        ></custom-period>
        <!-- Modal End -->

    </div>
</template>

<script>
    import moment from 'moment'

    import customPeriod from './customPeriod'

    export default {
        data () {
            return {
                modal : {
                    customDate : false,
                },
                moment : moment,
                period : 0,
                options : [
                    {
                        title : "H-1",
                        start : "this.moment().subtract(1, 'days').format('YYYY-MM-DD 00:00:00')",
                        end : "this.moment().subtract(1, 'days').format('YYYY-MM-DD 23:59:59')"
                    },
                    // {
                    //     title : "MTD",
                    //     start : "this.moment().startOf('month').format('YYYY-MM-DD 00:00:00')",
                    //     end : "this.moment().format('YYYY-MM-DD 23:59:59')"
                    // },
                    // {
                    //     title : "Last Month",
                    //     start : "this.moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD 00:00:00')",
                    //     end : "this.moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD 23:59:59')",
                    // },
                    // {
                    //     title : "This Year",
                    //     start : "this.moment().startOf('year').format('YYYY-MM-DD 00:00:00')",
                    //     end : "this.moment().format('YYYY-MM-DD 23:59:59')"
                    // },
                    // {
                    //     title : "Last Year",
                    //     start : "this.moment().startOf('year').subtract(1, 'year').format('YYYY-MM-DD 00:00:00')",
                    //     end : "this.moment().endOf('year').subtract(1, 'year').format('YYYY-MM-DD 23:59:59')"
                    // }
                ],
                form : {
                    start : moment().startOf('month').format('YYYY-MM-DD 00:00:00'),
                    end : moment().format('YYYY-MM-DD 23:59:59')
                }
            }
        },
        props : ['periodFilter'],
        components : {
            customPeriod,
        },
        mounted () {
            // this.$emit('newDateRange', this.form);
        },
        watch : {
            period () {
                if (this.period == 100) return;

                this.form.start = eval(this.options[this.period].start);
                this.form.end = eval(this.options[this.period].end);

                this.$emit('newDateRange', this.form);
            }
        },
        methods : {
            setCustomDate (dates) {
                this.form.start = moment(dates.start).format('YYYY-MM-DD 00:00:00');
                this.form.end = moment(dates.end).format('YYYY-MM-DD 23:59:59');

                this.period = 100;
                this.$emit('newDateRange', this.form);
                
                this.modal.customDate = false;
            },
        }
    }
</script>

<style scoped>
    .btn-primary {
      background-color: #E25A5A;
      border-color: transparent;
      box-shadow: 1px 1px 3px 3px lightgrey;
    }
  
    .btn-outline-primary {
      color: #E66D71;
      box-shadow: 0 0 0 1px #FC8E92 inset;
      border-color: transparent;
    }
  
    .btn-outline-primary:hover {
      color: white;

      background-color: #E25A5A; 
    }
</style>