<template>
	<table class="table text-center">
		<thead>
			<tr style="background-color:black;color:white">
				<th rowspan="2">Date</th>
				<th colspan="3" class="headerSeperatorLeft">SL AHM</th>
				<th colspan="3" class="headerSeperatorLeft">GR AHM TO MD</th>
				<th colspan="3" class="headerSeperatorLeft">GI MD TO D</th>
				<th colspan="3" class="headerSeperatorLeft">SPG</th>
				<th colspan="3" class="headerSeperatorLeft">BSTK</th>
			</tr>
			<tr>
				<th class="headerSeperatorLeft">Success</th>
				<th>Failed</th>
				<th>Total</th>
				<th class="headerSeperatorLeft">Success</th>
				<th>Failed</th>
				<th>Total</th>
				<th class="headerSeperatorLeft">Success</th>
				<th>Failed</th>
				<th>Total</th>
				<th class="headerSeperatorLeft">Success</th>
				<th>Failed</th>
				<th>Total</th>
				<th class="headerSeperatorLeft">Success</th>
				<th>Failed</th>
				<th>Total</th>
			</tr>
		</thead>
		<tbody>
			<tr v-for="(datum, index) in data" :key="index">
				<td>{{index+1}}</td>

				<!-- SL AHM -->
				<td class="headerSeperatorLeft" style="color:green">{{numberWithCommas(datum.slahm_success)}}</td>
				<td style="color:red">{{numberWithCommas(datum.slahm_failed)}}</td>
				<td>{{numberWithCommas(parseInt(datum.slahm_success) + parseInt(datum.slahm_failed))}}</td>

				<!-- GR AHM TO MD -->
				<td class="headerSeperatorLeft" style="color:green">{{numberWithCommas(datum.grahmtomd_success)}}</td>
				<td style="color:red">{{numberWithCommas(datum.grahmtomd_failed)}}</td>
				<td>{{numberWithCommas(parseInt(datum.grahmtomd_success) + parseInt(datum.grahmtomd_failed))}}</td>

				<!-- GI MD TO D -->
				<td class="headerSeperatorLeft" style="color:green">{{numberWithCommas(datum.gimdtod_success)}}</td>
				<td style="color:red">{{numberWithCommas(datum.gimdtod_failed)}}</td>
				<td>{{numberWithCommas(parseInt(datum.gimdtod_success) + parseInt(datum.gimdtod_failed))}}</td>

				<!-- SPG -->
				<td class="headerSeperatorLeft" style="color:green">{{numberWithCommas(datum.spg_success)}}</td>
				<td style="color:red">{{numberWithCommas(datum.spg_failed)}}</td>
				<td>{{numberWithCommas(parseInt(datum.spg_success) + parseInt(datum.spg_failed))}}</td>

				<!-- BSTK -->
				<td class="headerSeperatorLeft" style="color:green">{{numberWithCommas(datum.bstk_success)}}</td>
				<td style="color:red">{{numberWithCommas(datum.bstk_failed)}}</td>
				<td>{{numberWithCommas(parseInt(datum.bstk_success) + parseInt(datum.bstk_failed))}}</td>
			</tr>
			<tr>
				<td>Total</td>
				<!-- SL AHM Total -->
				<td class="headerSeperatorLeft" style="color:green">{{numberWithCommas(dataTotal.slahm_success)}}</td>
				<td style="color:red">{{numberWithCommas(dataTotal.slahm_failed)}}</td>
				<td>{{numberWithCommas(parseInt(dataTotal.slahm_success) + parseInt(dataTotal.slahm_failed))}}</td>

				<!-- GR AHM TO MD Total -->
				<td class="headerSeperatorLeft" style="color:green">{{numberWithCommas(dataTotal.grahmtomd_success)}}</td>
				<td style="color:red">{{numberWithCommas(dataTotal.grahmtomd_failed)}}</td>
				<td>{{numberWithCommas(parseInt(dataTotal.grahmtomd_success) + parseInt(dataTotal.grahmtomd_failed))}}</td>

				<!-- GI MD TO D Total -->
				<td class="headerSeperatorLeft" style="color:green">{{numberWithCommas(dataTotal.gimdtod_success)}}</td>
				<td style="color:red">{{numberWithCommas(dataTotal.gimdtod_failed)}}</td>
				<td>{{numberWithCommas(parseInt(dataTotal.gimdtod_success) + parseInt(dataTotal.gimdtod_failed))}}</td>

				<!-- SPG Total -->
				<td class="headerSeperatorLeft" style="color:green">{{numberWithCommas(dataTotal.spg_success)}}</td>
				<td style="color:red">{{numberWithCommas(dataTotal.spg_failed)}}</td>
				<td>{{numberWithCommas(parseInt(dataTotal.spg_success) + parseInt(dataTotal.spg_failed))}}</td>

				<!-- BSTK Total -->
				<td class="headerSeperatorLeft" style="color:green">{{numberWithCommas(dataTotal.bstk_success)}}</td>
				<td style="color:red">{{numberWithCommas(dataTotal.bstk_failed)}}</td>
				<td>{{numberWithCommas(parseInt(dataTotal.bstk_success) + parseInt(dataTotal.bstk_failed))}}</td>
			</tr>
		</tbody>
	</table>
</template>

<script>
export default {
	data () {
		return {
			dataTotal : {},
		}
	},
	props: ['data'],
	watch : {
		data : {
			handler () {
				let variables = ['slahm', 'grahmtomd', 'gimdtod', 'spg', 'bstk']
				for(var j = 0; j < variables.length; j++){
					this.dataTotal[variables[j]+'_success'] = 0
					this.dataTotal[variables[j]+'_failed'] = 0
				}

				if(this.data != null){
					for(var i = 0; i < this.data.length; i++){
						for(var j = 0; j < variables.length; j++){
							this.dataTotal[variables[j]+'_success'] += parseInt(this.data[i][variables[j]+'_success'])
							this.dataTotal[variables[j]+'_failed'] += parseInt(this.data[i][variables[j]+'_failed'])
						}
					}
				}
			},
			deep : true,
			immediate : true,
		}
	},
	methods: {
		numberWithCommas(x) {
			return parseInt(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		},
	}
}
</script>

<style scoped>
	.headerSeperatorLeft {
		border-left: 2px solid grey !important;
	}
</style>