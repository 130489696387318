<template>
<div>
<div id='chartdiv' ref='averageServiceCycle'></div>
</div>
</template>

<script>
/* eslint-disable */
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import axios from 'axios'

export default {
	data() {
		return {
			company: sessionStorage.getItem('company'),
			chart: null,
			categories : ['New Customer', '< 1 Months', '1 to 2 Months', '2 to 3 Months', '3 to 4 Months', '4 to 6 Months', 'No Record'],
			apiVar : ['baru', 'bulan_0_1', 'bulan_1_2', 'bulan_2_3', 'bulan_3_4', 'bulan_4_6', 'no_record'],
			// data: null
		}
	},
	props: ['filterUrl'],
	mounted() {
		this.generateChart();
		this.fetchData()
	},
	watch: {
		filterUrl: function () {
			this.fetchData()
		}
	},
	methods: {
		fetchData() {
			var length = this.chart.data.length
			var vm = this;
			var compiledData = [];

			this.$http.get('/api/h2/service-cycle/average?' + this.filterUrl)
				.then((resp) => {
					var data = resp.data.data;

					for(var i = 0; i < vm.categories.length; i++){
						compiledData.push({
							category : vm.categories[i],
							value : data[0][vm.apiVar[i]]
						})
					}

					console.log(compiledData);
					vm.chart.addData(compiledData, length)
				})
				.catch((err) => {
					console.log(err)
				})
		},
		generateChart() {
			this.chart = am4core.create(this.$refs.averageServiceCycle, am4charts.XYChart);

			let title = this.chart.titles.create()
			title.text = 'Average Service Cycle'
			title.fontSize = 20
			title.marginBottom = 30
			title.fontWeight = 'Bold'

			var categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis())
			categoryAxis.dataFields.category = 'category'
			categoryAxis.renderer.grid.template.location = 0
			categoryAxis.renderer.minGridDistance = 10

			if (this.isMobile()) {
				categoryAxis.renderer.labels.template.horizontalCenter = 'right'
				categoryAxis.renderer.labels.template.verticalCenter = 'middle'
				categoryAxis.renderer.labels.template.rotation = 270
			}

			var valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis())
			valueAxis.min = 0
			valueAxis.title.text = 'Number of Vehicles'

			// Create series
			var series = this.chart.series.push(new am4charts.ColumnSeries())
			series.dataFields.valueY = 'value'
			series.dataFields.categoryX = 'category'
			series.name = 'value'
			series.columns.template.tooltipText = '{categoryX}: [bold]{valueY}[/]'
			series.columns.template.fillOpacity = .8

			var labelBullet = series.bullets.push(new am4charts.LabelBullet());
			labelBullet.label.text = "[bold]{valueY}";
			labelBullet.label.dy = -15;

			var columnTemplate = series.columns.template
			columnTemplate.strokeWidth = 2
			columnTemplate.strokeOpacity = 1
		},
	}
}
</script>

<style scoped>
	body {
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	}

	#chartdiv {
		width: 100%;
		height: 500px;
	}
</style>