<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper" @click="$emit('closeModal')">
                <div class="row">
                    <div class="col-xs-0 col-sm-0 col-md-3 col-lg-4"></div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                        <div class="modal-container" @click.stop>

                            <!-- <div class="modal-header row" style="justify-content: flex-end;">
                                <div class="col">
                                    <button type="button" class="close" @click="$emit('closeModal')">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div> -->

                            <div class="modal-body">
                                <h5>Download Detail</h5>
                                <p class="title-desc">*Select date to Download (Max range : 1 Month)</p>

                                <div class="text-center mb-5">
                                    <div class="btn-group" role="group">
                                        <button
                                            type="button"
                                            class="btn"
                                            :class="form.toggle == 'GR' ? 'btn-elegant' : 'btn-elegant-unselected'"
                                            @click="form.toggle = 'GR'"
                                        >By GR</button>
                                        <button
                                            type="button"
                                            class="btn"
                                            :class="form.toggle == 'GI' ? 'btn-elegant' : 'btn-elegant-unselected'"
                                            @click="form.toggle = 'GI'"
                                        >By GI</button>
                                    </div>
                                </div>

                                <MultiSelect
                                    v-model="form.region"
                                    v-if="!regionList"
                                    class="custom-download-detail-multiselect mt-3 mb-3"
                                    :options="options.region.map(x => x.loc_code)"
                                    :custom-label="opt => options.region.find(x => x.loc_code == opt).name"
                                    :allow-empty="false"
                                    placeholder="Select Region"
                                ></MultiSelect>
                                <flat-pickr
                                    class="mt-3 mb-3"
                                    v-model="selected.range"
                                    :config="rangeConfig"
                                    :placeholder="!isIEMode ? 'Range picker' : null"
                                />
                                <p class="pl-1" style="color:red; font-size: 75%" v-if="rangeLimitError">Max range : 1 Month</p>

                                <tile v-if="isDownloading"></tile>

                                <div class="text-center mt-5">
                                    <button class="btn btn-download-large" :disabled="!isFormValid || isDownloading" @click="submitForm">
                                        Download Data
                                    </button>
                                </div>
                            </div>

                            <!-- Custom Exit Sign Start -->
                            <div class="exit-modal" @click="$emit('closeModal')">
                                <div class="w-100 h-100 text-center align-middle">
                                    <i class="fas fa-times"></i>
                                </div>
                            </div>
                            <!-- Custom Exit Sign End -->
                        </div>
                    </div>
                </div>
                <div class="col-xs-0 col-sm-0 col-md-3 col-lg-4"></div>
            </div>
        </div>
    </transition>
</template>

<script>
    import MultiSelect from 'vue-multiselect';
    import flatPickr from 'vue-flatpickr-component';
    import moment from 'moment';
    import qs from 'qs';
    import XLSX from 'xlsx';
    import { TileSpinner } from 'vue-spinners'
    import 'vue-spinners/dist/vue-spinners.css'

    export default {
        data() {
            return {
                rangeConfig: {
                    mode: 'range',
                    altInput: true,
                    altInputClass: 'form-control input custom-download-flatpickr w-100',
                    animate: !this.isRTL()
                },
                rangeLimitError : false,
                // rangeConfig: {
                //     altInput: true,
                //     altInputClass: 'form-control input flatpickr-input w-100',
                //     animate: !this.isRTL()
                // },
                options : {
                    region : this.$store.getters.getRegion,
                },
                selected: {
                    range : `${moment().startOf('month').format('YYYY-MM-DD')} to ${moment().format('YYYY-MM-DD')}`
                },
                form: {
                    toggle : 'GR',
                    region : null,
                    start : moment().subtract(1, 'days').format('YYYY-MM-DD 00:00:00'),
                    end : moment().subtract(1, 'days').format('YYYY-MM-DD 23:59:59'),
                },
                isDownloading : false,
                isFormValid : false,
            }
        },
        props : ['regionList'],
        components: {
            MultiSelect,
            flatPickr,
            tile : TileSpinner,
        },
        mounted() {
            if (this.regionList){
                if (this.regionList.length > 0)
                    this.form.region = this.regionList.join(",")
                else  //If region is not selected
                    delete this.form.region
            }
            this.formValidCheck();
        },
        watch: {
            'form' : {
                deep : true,
                handler : 'formValidCheck'
            },
            'selected.range' () {
                let splitDateRange = this.selected.range.split(' to ');
                this.form.start = splitDateRange[0] ? moment(splitDateRange[0]).format('YYYY-MM-DD 00:00:00') : null;
                this.form.end = splitDateRange[1] ? moment(splitDateRange[1]).format('YYYY-MM-DD 23:59:59') : null;
            },
        },
        computed : {
        },
        methods: {
            formValidCheck () {
                let valid = true;
                for (var i in this.form) {
                    if (!(this.form[i] && this.form[i] != null && this.form[i] != '')) valid = false;
                }
                this.isFormValid = valid;
            },
            validateForm() {
                var valid = true;

                if(moment(this.form.end).diff(moment(this.form.start), 'days') > 31){//If End > Start date
                    valid = false;
                }

                
                if (!valid) this.rangeLimitError = true;
                else return valid;
            },
            submitForm() {
                let vm = this;
                if (vm.validateForm()) {
                    this.isDownloading = true;
                    this.$http.get(`/api/highlight/warehouse/summary/download${qs.stringify(this.form, {skipNulls:true, addQueryPrefix:true})}`)
                    .then(async (resp) => {
                        this.$emit('closeModal');
                        this.$swal.fire({
                            title: 'Request Submitted',
                            text: "QR Registration Control report document request has been successfully submitted",
                            icon: 'success',
                            showCancelButton: true,
                            confirmButtonColor: '#3085D6',
                            cancelButtonColor: '#D33',
                            confirmButtonText: 'To Download Page',
                            cancelButtonText: 'Ok'
                        }).then((result) => {
                            console.log(result);
                            if (result.value) {
                                console.log('confirmed');
                                this.$router.push(`/report/download-report/`)
                            }
                        })
                    })
                    // .then(()=>{
                    //     this.isDownloading = false;
                    //     this.$emit('closeModal');
                    // })
                    .catch((err) => {
                        console.log(err);
                        this.$store.dispatch('triggerAlert', [false, ['An Error has occured']]);
                    })
                }
            },
            async exportFile(data){
                let wb = XLSX.utils.book_new()
                var ws = XLSX.utils.json_to_sheet(data, {raw:true});
                XLSX.utils.book_append_sheet(wb, ws, 'Data')

                let exportFileName = `${this.form.toggle} Detail QR Registration Control${' - ' + this.form.region} (${this.selected.range}).xlsx`
                XLSX.writeFile(wb, exportFileName)
            },
        }
    }
</script>

<style>
.custom-download-flatpickr {
    border: 0;
    background: #FFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 0px 6px #0000001A;
    border-radius: 5px !important;
    padding: 7px 0px 7px 8px !important;
    height: 40px;
}

.custom-download-flatpickr:focus {
    border: 0;
    box-shadow: 0px 0px 6px #0000001A;
}

.custom-download-detail-multiselect .multiselect__tags{
    border: 0;
    background: #FFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 0px 6px #0000001A;
}
</style>

<style scoped>
    h5 {
        text-align: center;
        font: normal normal 500 23px/26px Ubuntu;
        color: #707070;
        margin: 0;
    }

    .title-desc {
        text-align: center;
        font: normal normal 500 11px/13px Ubuntu;
        color: #A8A8A8;
    }

    .btn-download-large {
        background: #FDFDFD 0% 0% no-repeat padding-box;
        border:1px solid #D0D0D0;
        font: normal normal 500 16px/18px Ubuntu;
        padding: 20px 30px;
    }

    .btn-download-large:disabled {
        color: #BCBCBC;
    }

    .modal-container {

    }
    
    .exit-modal {
        top: -10px;
        right: 0px;
        position: absolute;
        background-color: white;
        width: 30px;
        height: 30px;
        text-align: center;
        border-radius: 50px;
        box-shadow: 0px 3px 6px #00000029;
        cursor: pointer;
    }
</style>