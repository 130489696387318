<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper" @click="$emit('closeModal')">
                <div class="row">
                    <div class="col-xs-0 col-sm-0 col-md-1 col-lg-2"></div>
                    <div class="col-xs-12 col-sm-12 col-md-10 col-lg-8">
                        <div class="modal-container" @click.stop>
  
                            <div class="modal-header row">
                              <div class="col" style="justify-content: flex-end;">
                                <button type="button" class="close" @click="$emit('closeModal')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                            </div>
                            
                            <div class="modal-body" style="display: flex; flex-direction: column; justify-content: space-around;">
                                <h2 class="title text-center" style="margin-bottom: 20px;">Download Excel</h2>

                                <div class="row row-space" v-if="!downloading">
                                    <div class="offset-md-3 col-md-6">
                                        <div class="input-group">
                                            <label class="label">Data Group<span style="color: red;">*</span></label>
                                            <MultiSelect
                                                v-model="filter.page"
                                                v-if="options.page"
                                                :options="options.page.map(x => x.page)"
                                                :custom-label="opt => options.page.find(x => x.page == opt).label"
                                                :allow-empty="false"
                                                placeholder="Choose Data Group"
                                            ></MultiSelect>
                                        </div>
                                    </div>
                                </div>
                                <div class="row row-space" v-else>
                                    <div class="col text-center">
                                        <h2 class="loading">Loading...</h2>
                                        <span style="font-size:20px">File is currently downloading</span>
                                    </div>
                                </div>
                                
                                <div class="row mt-5">
                                  <div class="col text-center">
                                    <button type="button" class="btn btn-outline-danger" style="font-size:140%" @click="$emit('closeModal')">Cancel</button>
                                    <button type="button" class="btn btn-primary ml-5" style="font-size:150%" @click="downloading = true; $emit('downloadExcel', filter.page)">Download</button>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-0 col-sm-0 col-md-1 col-lg-2"></div>
                </div>
            </div>
        </div>
    </transition>
  </template>
  
  <script>
    import MultiSelect from 'vue-multiselect';

    export default {
      data(){
        return{
          mobile : this.isMobile(),
          downloading : false,
          options : {
            page : null,
          },
          filter : {
            page : 1,
          },
        }
      },
      props:['data', 'type'],
      components : {
        MultiSelect
      },
      mounted(){
        this.generatePageOptions();
      },
      watch : {
        
      },
      methods:{
        generatePageOptions () {
            let page = [];
            for (var i = 0; i < 100; i++) {
                page.push({
                    label : `${this.numberWithCommas(i*1000+1)} - ${this.numberWithCommas(i*1000+1000)}`,
                    page : i+1
                })
            }
            this.options.page = page;
        },
      }
    }
  </script>
  
  <style>
  
  </style>
  
  <style scoped>
    .modal-mask {
        position: fixed;
        z-index: 1020;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .3s ease;
    }
  
    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }
  
    .modal-container {
        width: 100%;
        margin: 0px auto;
        padding: 20px 30px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
        transition: all .3s ease;
        font-family: Helvetica, Arial, sans-serif;
    }
  
    .modal-header h3 {
        margin-top: 0;
        color: #42b983;
    }
  
    .modal-body {
        margin: 20px 0;
        overflow: auto;
        max-height: 80vh;
        max-width: 80vw;
    }
  
    .modal-default-button {
        float: right;
    }
  
    /*
    * The following styles are auto-applied to elements with
    * transition="modal" when their visibility is toggled
    * by Vue.js.
    *
    * You can easily play with the modal transition by editing
    * these styles.
    */
  
    .modal-enter {
        opacity: 0;
    }
  
    .modal-leave-active {
        opacity: 0;
    }
  
    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
  
    /* MODAL STARTS */
    .modal-body{
        margin:0;
        min-height: 50vh;
    }
  
    .modal-body .moduleLine{
        margin-bottom: 0vh;
    }
  
    .modal-body .moduleLine a{
        text-decoration: none;
    }
  
    .modal-body .moduleLine .moduleBox:hover{
        background-color: #E0E0E0;
        transition: background-color 0.7s ease;
        color: #212121;
        transition: color 0.7s ease;
    }
  
    .modal-body .moduleLine .moduleBox{
        border:1px solid #BDBDBD;
        border-radius: 20px;
        padding: 20px 0;
        color:black;
        margin-bottom: 3vh;
    }
  
    .modal-body .moduleLine .moduleBox i{
        font-size:5vh;
    }
  
    h3.modalHeadline{
        margin:0 0 20px 0;
        font-weight: bold;
        text-align : center;
    }
    /* MODAL ENDS */
    
    
    .loading{
        color: #616161;
        font-size: 90px;
        text-shadow: 4px 4px 6px #BDBDBD;
    }
  
  </style>