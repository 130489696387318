<template>
	<table class="table text-center">
		<thead>
			<tr style="background-color:black;color:white">
				<th rowspan="2">Date</th>
				<th class="headerSeperatorLeft">SL AHM</th>
			</tr>
		</thead>
		<tbody>
			<tr v-for="datum in data">
				<td>{{datum.date}}</td>
				<td class="headerSeperatorLeft" style="color:green">{{numberWithCommas(datum.slahm)}}</td>
			</tr>
		</tbody>
	</table>
</template>

<script>
export default {
	data () {
		return {

		}
	},
	props: ['data'],
	mounted () {

	},
	methods: {
		numberWithCommas(x) {
			return parseInt(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		},
	}
}
</script>

<style scoped>
	.headerSeperatorLeft {
		border-left: 2px solid grey !important;
	}
</style>