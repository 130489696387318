<template>
  <div>
    <!--Filter Option-->
    <b-card title="Customer" :sub-title="infoPopup.title" header-tag="h6" class="mb-4">
      <hr>
        <b-form>
            <b-form-row>
                <b-form-group label="Region" class="col-md-3">
                  <Multiselect
                    v-model="filter.region"
                    :options="options.region.map(x => x.loc_code)"
                    :custom-label="opt => options.region.find(x => x.loc_code == opt).name"
                    placeholder="All Region"
                  ></Multiselect>
                </b-form-group>
                <b-form-group label="Branch" class="col-md-3">
                  <Multiselect
                    v-model="filter.branch"
                    :options="options.branch.map(x => x.loc_code)"
                    :custom-label="opt => options.branch.find(x => x.loc_code == opt).name"
                    placeholder="All Branch"
                  ></Multiselect>
                </b-form-group>
                <b-form-group label="Start Active Date" class="col-md-3">
                  <flat-pickr
                    class="mt-1"
                    v-model="filter.start"
                    :config="rangeConfigSeperated"
                    placeholder="Select Start Active Date"
                  />
                </b-form-group>
                <b-form-group label="End Active Date" class="col-md-3">
                  <flat-pickr
                    class="mt-1"
                    v-model="filter.end"
                    :config="rangeConfigSeperated"
                    placeholder="Select End Active Date"
                  />
                </b-form-group>
            </b-form-row>
            <b-form-row>
              <b-form-group label="Activity" class="col-md">
                <Multiselect
                  v-model="filter.activity"
                  :options="options.activity.map(x => x.value)"
                  :custom-label="opt => options.activity.find(x => x.value == opt).title"
                  placeholder="All Activity"
                ></Multiselect>
              </b-form-group>
              <b-form-group label="Scan Source" class="col-md">
                <Multiselect
                  v-model="filter.scan_source"
                  :options="options.scan_source"
                  placeholder="All Scan Source"
                ></Multiselect>
              </b-form-group>
                <b-form-group label="Identity Number" class="col-md">
                    <b-input type="text" placeholder="Identity Number" v-model="filter.id_no" />
                </b-form-group>
                <b-form-group label="Full Name" class="col-md">
                    <b-input type="text" placeholder="Full Name" v-model="filter.name" />
                </b-form-group>
                <!-- <b-form-group label="Phone Number" class="col-md-3">
                    <b-input type="text" placeholder="Phone Number" v-model="filter.phone_no" />
                </b-form-group> -->
                <b-form-group label="Engine Number" class="col-md">
                    <b-input type="text" placeholder="Engine Number" v-model="filter.engine_no" />
                </b-form-group>
            </b-form-row>
            <b-btn @click="vue_universal_table.update++" :disabled="isFetching" class="col-md-2" type="button" variant="primary"><span class="fa fa-filter"></span>&nbsp;&nbsp;Search</b-btn>
            <b-btn @click="showExcelModal = true" :disabled="isFetching" class="col-md-2" type="button" variant="success"><span class="fa fa-file-excel"></span>&nbsp;&nbsp;Export To Excel</b-btn>
        </b-form>
    </b-card>

    <b-card class="mb-4">
      <div class="row mt-2 mb-4">
        <div class="col-md-3 col-6" style="padding-right:0px">
          <button class="btn btn-primary w-100"
            :class="{'btn-primary' : filter.flag == 'MasterData' , 'btn-outline-primary' : filter.flag != 'MasterData'}"
            @click="
              filter.flag = `MasterData`;
              vue_universal_table.update++
            "
            style="border-radius : 15px 0px 0px 15px"
          >
            Master Data
          </button>
        </div>
        <div class="col-md-3 col-6" style="padding-left:0px">
          <button class="btn w-100"
            :class="{'btn-primary' : filter.flag == 'DataCustomer' , 'btn-outline-primary' : filter.flag != 'DataCustomer'}"
            style="border-radius : 0px 15px 15px 0px"
            @click="
              filter.flag = `DataCustomer`;
              vue_universal_table.update++
            "
          >
            Customer Data
          </button>
        </div>
      </div>
      <vue-universal-table
        :api_url="'/api/reports/customer'"
        :fields="vue_universal_table.fields"
        :update="vue_universal_table.update"
        :filter="filter"
        :fixed_header="true"
        :infinite_scroll="true"
        @fetchStart="isFetching = true"
        @fetchEnd="isFetching = false"
      ></vue-universal-table>
    </b-card>

    <transition name="modal" v-if="showExcelModal">
            <div class="modal-mask">
                <div class="modal-wrapper" @click="showExcelModal = false">
                    <div class="row">
                        <div class="col-xs-0 col-sm-0 col-md-3 col-lg-3"></div>
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <div class="modal-container" @click.stop>

                                <div class="modal-header" style="justify-content: flex-end;">
                                    <button type="button" class="close" @click="showExcelModal = false">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                
                                <div class="modal-body">
                                    <h4>Please Select The Time Range To Generate the Excel Report</h4>

                                    <p class="mt-2 mb-2" style="color:red">{{error}}</p>

                                    <flat-pickr v-model="range" :config="rangeConfig" :placeholder="!isIEMode ? 'Select Date' : null"/>

                                    <div class="row mt-4">
                                        <div class="col-md-3 col-12">
                                            <button type="button" @click="downloadExcel()" class="btn btn-success w-100">Download Excel</button>
                                        </div>
                                        <div class="col-md-3 col-12">
                                            <button type="button" @click="showExcelModal = false" class="btn btn-danger w-100">Cancel</button>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-0 col-sm-0 col-md-1 col-lg-2"></div>
                    </div>
                </div>
            </div>
        </transition>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'

function isRTL () {
  return document.documentElement.getAttribute('dir') === 'rtl' || document.body.getAttribute('dir') === 'rtl'
}


export default {
  name: 'Customer Report',
  metaInfo: {
    title: 'Customer Report'
  },
  components: {
    Multiselect,
    flatPickr
  },
  data () {
    return {
      isFetching : false,

      options : {
        region : this.$store.getters.getRegion,
        branch : this.$store.getters.getBranch(),
        activity : [
          { title: 'BSTK', value: 'BSTK'},
          { title: 'SERVICE', value: 'SERVICE'},
          { title: 'MapCust', value: 'MapCust'}
        ],
        scan_source : ['H1', 'H2']
      },
      infoPopup : this.$store.state.hoverInformation.report.customer,
      rangeConfigSeperated: {
        altInput: true,
        animate: !isRTL() 
      },
      vue_universal_table : {
        fields : [
          {
            title : 'QRCode',
            variable : 'qrcode'
          },
          {
            title : 'Code Dealer',
            variable : 'loc_code'
          },
          {
            title : 'Name Dealer',
            variable : 'name'
          },
          // {
          //   title : 'Identity Card',
          //   variable : 'id_card'
          // },
          // {
          //   title : 'Email',
          //   variable : 'email'
          // },
          {
            title : 'Engine No',
            variable : 'engine_no'
          },
          {
            title : 'Frame No',
            variable : 'frame_no'
          },
          {
            title : 'Activity',
            variable : 'activity'
          },
          {
            title : 'Scan Source',
            variable : 'scan_source'
          },
          {
            title : 'Current Region',
            variable : 'current_region'
          },
          {
            title : 'Active Date',
            variable : 'active_date',
            date_format : 'DD MMM YYYY HH:mm:ss'
          },
          {
            title : 'Scan Date',
            variable : 'scan_date',
            date_format : 'DD MMM YYYY HH:mm:ss'
          },
        ],
        update : 0,
        // process_data : `
        //   for (var i in data) data[i].activity = data[i].activity == 'MapCust' ? 'Service' : data[i].activity
        // `
      },
      filter : {
        region : '',
        branch : '',
        start : moment().startOf('month').format('YYYY-MM-DD'),
        end : moment().format('YYYY-MM-DD'),
        id_no : '',
        name : '',
        // phone_no : '',
        engine_no : '',
        activity : '',
        scan_source : '',
        flag : 'MasterData'
      },
      range: '',
      showExcelModal: false,
      error: '',
      rangeConfig: {
        mode: 'range',
        altInput: true,
        animate: !isRTL()
      },
    }
  },
  mounted () {
    // this.fetchActivity();
  },
  watch : {
    'filter.region'(){
      this.filter.branch = ''
      this.options.branch = this.$store.getters.getBranchByCode(this.filter.region);
    }
  },
  methods: {
    fetchActivity () {
      this.$http.get('/api/master/tracking-activity')
      .then((resp)=>{
        var data = resp.data.data
        this.options.activity = data.filter((a) => {if(a.activity != null && !a.activity.includes('\n')) return a}).map(a => a.activity)
      })
      .catch((err)=>{
        console.log(err);
      })
    },
    async downloadExcel (filter) {
      if (this.range == '') {
        this.error = "Please Select Time Range To Download The Excel"
        return;
      }
      let url = '/api/reports/customer/download-excel?' + await this.initialiseFilter()

      this.$http.get(url , {responseType : 'blob'})
      .then((resp)=>{
        const blob = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = "Customers Data (" + this.range + ")";
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((err)=>{
        console.log(err);
      })
    },
    initialiseFilter(){
      if(!(typeof this.filter === 'object' && this.filter !== null)){
        this.filterUrl = '';
        return;
      }
      var keys = Object.keys(this.filter);
      let stringUrl = '';
      var queryParams = this.filter;

      for(var i = 0 ; i < keys.length; i++){
        if(!queryParams[keys[i]])
          continue;

        if(queryParams[keys[i]] == '' || queryParams[keys[i]].length == 0)
          continue 

        if((keys[i] == 'user_type') && queryParams[keys[i]] == 'All')
          continue;
        
        // if(keys[i] == 'start_hour' || keys[i] == 'end_hour')
        //   queryParams[keys[i]] += ":00";

        stringUrl += `&${keys[i]}=${queryParams[keys[i]]}`;
        
      }
      return stringUrl;
    },
  }
}
</script>

<style scoped>
  .modal-mask {
      position: fixed;
      z-index: 1020;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .5);
      display: table;
      transition: opacity .3s ease;
  }

  .modal-wrapper {
      display: table-cell;
      vertical-align: middle;
  }

  .modal-container {
      width: 100%;
      margin: 0px auto;
      padding: 20px 30px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
      transition: all .3s ease;
      font-family: Helvetica, Arial, sans-serif;
  }

  .modal-header h3 {
      margin-top: 0;
      color: #42b983;
  }

  .modal-body {
      margin: 20px 0;
  }

  .modal-default-button {
      float: right;
  }

  /*
  * The following styles are auto-applied to elements with
  * transition="modal" when their visibility is toggled
  * by Vue.js.
  *
  * You can easily play with the modal transition by editing
  * these styles.
  */

  .modal-enter {
      opacity: 0;
  }

  .modal-leave-active {
      opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
  }

  /* MODAL STARTS */
  .modal-body{
      margin:0
  }

  .modal-body .moduleLine{
      margin-bottom: 0vh;
  }

  .modal-body .moduleLine a{
      text-decoration: none;
  }

  .modal-body .moduleLine .moduleBox:hover{
      background-color: #E0E0E0;
      transition: background-color 0.7s ease;
      color: #212121;
      transition: color 0.7s ease;
  }

  .modal-body .moduleLine .moduleBox{
      border:1px solid #BDBDBD;
      border-radius: 20px;
      padding: 20px 0;
      color:black;
      margin-bottom: 3vh;
  }

  .modal-body .moduleLine .moduleBox i{
      font-size:5vh;
  }

  h3.modalHeadline{
      margin:0 0 20px 0;
      font-weight: bold;
      text-align : center;
  }
  /* MODAL ENDS */
</style>
