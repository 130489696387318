<template>
  <component :is="currentComponent">
  </component>
</template>

<script>
/*
Import all parent components to this file.
*/ 

//Welcome
import Welcome from '@/components/Welcome'

//Highlight
import Highlight from '@/components/Highlight/highlight'

import WarehouseMappingOverview from '@/components/WarehouseMappingOverview/WarehouseMappingOverview'

import HighlightWarehouse from '@/components/HighlightWarehouse/HighlightWarehouse'

//H1
import RealTimeQRRegistration from '@/components/h1/RealTimeQRRegistration/RealTimeQRRegistration'
import QRRegistered from '@/components/h1/QRRegistered/QRRegistered'
import MonthQRRegistration from '@/components/h1/MonthQRRegistration/MonthQRRegistration'
import ReplacedQrH1 from '@/components/h1/ReplacedQr/replacedQr'
import StockAvailability from '@/components/h1/StockAvailability/StockAvailability'
import StockIntransit from '@/components/h1/StockIntransit/StockIntransit'
import AverageDaysOfStock from '@/components/h1/AverageDaysOfStock/AverageDaysOfStock'
import AgingStock from '@/components/h1/AgingStock/AgingStock'
import LeadTime from '@/components/h1/LeadTime/LeadTime'
import Sales from '@/components/h1/Sales/Sales'
import RetailSales from '@/components/h1/RetailSales/RetailSales'
import CustomerLocation from '@/components/h1/CustomerLocation/CustomerLocation'
import Deviation from '@/components/h1/Deviation/Deviation'//TEMPORARY
import IncomingWarehouseBehaviour from '@/components/h1/WarehouseBehaviour/Incoming'
import OutgoingWarehouseBehaviour from '@/components/h1/WarehouseBehaviour/Outgoing'
import GIMD from '@/components/h1/GIMD/GIMD'
import GRMD from '@/components/h1/GRMD/GRMD'
import GRD from '@/components/h1/GRD/GRD'

//H2
import QrRegistration from '@/components/h2/QrRegistration/QrRegistration'
import ReplacedQr from '@/components/h2/ReplacedQr/ReplacedQr'
import ChangeOwnership from '@/components/h2/ChangeOwnership/ChangeOwnership'
import ServiceOrder from '@/components/h2/ServiceOrder/ServiceOrder'
import ServiceCycle from '@/components/h2/ServiceCycle/ServiceCycle'
import WorkshopTraffic from '@/components/h2/WorkshopTraffic/WorkshopTraffic'
import ServiceDuration from '@/components/h2/ServiceDuration/ServiceDuration'

//Reports
import VehicleSearch from '@/components/reports/VehicleSearch/VehicleSearch'
import QRCode from '@/components/reports/QRCode/QRCode'
import ChangeOwnershipReport from '@/components/reports/ChangeOwnership/ChangeOwnership'
import Customer from '@/components/reports/Customer/Customer'
import QrCodeReplacement from '@/components/reports/QrCodeReplacement/QrCodeReplacement'
import LogReport from '@/components/reports/LogReport/LogReport'
import LogDetailReport from '@/components/reports/LogReport/childComponents/LogDetailReport'
import ErrorReport from '@/components/reports/ErrorReport/ErrorReport'
import SummaryQRRegistration from '@/components/reports/SummaryQRRegistration/SummaryQRRegistration'
import SummaryLog from '@/components/reports/SummaryLog/SummaryLog'
import SummaryDocumentData from '@/components/reports/SummaryDocumentData/SummaryDocumentData'
import H1Reports from '@/components/reports/H1Reports/H1Reports'
import H2Reports from '@/components/reports/H2Reports/H2Reports'
import H2DealerReport from '@/components/reports/H2DealerReport/H2DealerReport'
import TrackingReport from '@/components/reports/Tracking/tracking'
import StockReport from '@/components/reports/Stock/stock'
import LeadTimeReport from '@/components/reports/LeadTime/leadTime'
import DownloadReport from '@/components/reports/DownloadReport/DownloadReport'

//Distribution Report
import H2ReportVisit from '@/components/DistributionReport/H2ReportVisit/H2ReportVisit.vue';
import H2VisitJourney from '@/components/DistributionReport/H2ReportVisit/H2VisitJourney.vue';
import H2DetailVisit from '@/components/DistributionReport/H2ReportVisit/H2DetailVisit.vue';
import QRegistration from '@/components/DistributionReport/QRegistration/QRegistration.vue';
import Replacement from '@/components/DistributionReport/Replacement/Replacement.vue';

//Settings
import UserManagement from '@/components/setting/UserManagement/UserManagement'
import Target from '@/components/setting/Target/Target'
import Locations from '@/components/setting/Locations/Locations'
import VehicleType from '@/components/setting/VehicleType/VehicleType.vue'
import Color from '@/components/setting/Color/Color.vue'

//Profile
import profilePage from '@/components/Profile/Profile'
  
export default {
  metaInfo: {
    title: 'BI Dashboard',
    titleTemplate: '%s - QTrust',
    htmlAttrs: {
      lang: 'en',
      amp: true
    }
  },
  components : {
    Welcome,
    
    Highlight,
    WarehouseMappingOverview,
    HighlightWarehouse,

    //H1
    RealTimeQRRegistration,
    QRRegistered,
    MonthQRRegistration,
    ReplacedQrH1,
    StockAvailability,
    StockIntransit,
    AverageDaysOfStock,
    AgingStock,
    LeadTime,
    Sales,
    RetailSales,
    CustomerLocation,
    Deviation,
    IncomingWarehouseBehaviour,
    OutgoingWarehouseBehaviour,
    GIMD,
    GRMD,
    GRD,

    //H2
    QrRegistration,
    ReplacedQr,
    ChangeOwnership,
    ServiceOrder,
    ServiceCycle,
    WorkshopTraffic,
    ServiceDuration,

    //Reports
    VehicleSearch,
    QRCode,
    ChangeOwnershipReport,
    Customer,
    QrCodeReplacement,
    LogReport,
    LogDetailReport,
    ErrorReport,
    SummaryLog,
    SummaryDocumentData,
    SummaryQRRegistration,
    H1Reports,
    H2Reports,
    H2DealerReport,
    TrackingReport,
    StockReport,
    LeadTimeReport,
    DownloadReport,

    //Distribution Report
    QRegistration,
    Replacement,
    H2ReportVisit, 
    H2DetailVisit,
    H2VisitJourney,
    
    //Settings
    UserManagement,
    Target,
    Locations,
    VehicleType,
    Color,

    //Profile
    profilePage
  },
  data(){
    return{
      currentComponent : ''
    }
  },
  created(){

  },
  mounted(){
    this.currentComponent = this.$route.name
  },
  watch : {
    '$route.name' : function() {
      this.currentComponent = this.$route.name
    }
  },
  methods:{

  }
}
</script>

<style>
  .align-middle{
    display: flex;
    flex-direction: column;
    justify-content: center; 
  }

  /* .outerBox{
    padding: 0 20px;
  }
  .innerBox{
    padding: 1.5rem;
    border:1px solid #EEEEEE;
    border-radius:5px;
    background-color: white;
    box-shadow: 2px 2px 5px #E0E0E0;
  } */

	/* FLATPICKR CUSTOM CSS START */
	input[readonly].flatpickr-input{
		background-color:#fff !important;
		height:43px;
	}
	/* FLATPICKR CUSTOM CSS END */

  /* MODAL STARTS */
  .modal-mask {
    position: fixed;
    z-index: 1020;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    width: 100%;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }

  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-body {
    margin: 20px 0;
  }

  .modal-default-button {
    float: right;
  }

  /*
  * The following styles are auto-applied to elements with
  * transition="modal" when their visibility is toggled
  * by Vue.js.
  *
  * You can easily play with the modal transition by editing
  * these styles.
  */

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  /* MODAL STARTS */
  .modal-body{
    margin:0;
    max-height: 80vh;
    overflow-x : auto;
    overflow-y : auto;
  }

  .modal-body .moduleLine{
    margin-bottom: 0vh;
  }

  .modal-body .moduleLine a{
    text-decoration: none;
  }

  .modal-body .moduleLine .moduleBox:hover{
    background-color: #E0E0E0;
    transition: background-color 0.7s ease;
    color: #212121;
    transition: color 0.7s ease;
  }

  .modal-body .moduleLine .moduleBox{
    border:1px solid #BDBDBD;
    border-radius: 20px;
    padding: 20px 0;
    color:black;
    margin-bottom: 3vh;
  }

  .modal-body .moduleLine .moduleBox i{
    font-size:5vh;
  }

  h3.modalHeadline{
    margin:0 0 20px 0;
    font-weight: bold;
    text-align : center;
  }
  /* MODAL ENDS */

  /* ALERT MESSAGE STARTS */
  .alertmessage {
      position: fixed;
      top: 10%;
      left: 50%;
      transform: translate(-50%, 0);
      z-index:9999;
      width: 300px;
      text-align: center;
  }
  .alertmessage strong{
      font-size: 150%
  }
  .fade-enter-active, .fade-leave-active {
      transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
  }
  /* ALERT MESSAGE ENDS */

</style>