<template>
<div>
    <!-- ALERT MESSAGE STARTS -->
    <transition name="fade">
        <div class="alert alertmessage" :class="{'alert-warning' : alert.isError, 'alert-success' : !alert.isError}" v-if="alert.showAlert">
          <strong v-if="alert.isError">Error!</strong>
          <strong v-else>Success!</strong>

          <br><br>
          <p v-for="message in alert.alertMessage">♢ {{ message }}</p>
        </div>
    </transition>
    <!-- ALERT MESSAGE ENDS -->

    <!--Filter Option-->
    <b-card title="User Management" :sub-title="infoPopup.title" class="mb-4">
        <hr>
        <b-form @submit.prevent="vue_universal_table.update++">
            <b-form-row>
                <b-form-group label="Username / Name" class="col-md-4 dateCss" style="height:42px" >
                    <b-form-input v-model="filter.search" placeholder="Type username / name"/>
                </b-form-group>
                <div class="col-md-4 offset-md-4" style="display: flex; flex-direction: column-reverse;">
                    <b-btn type="button" variant="primary" @click="vue_universal_table.update++" style="height: 42px;"><i class="fa fa-filter"></i>&nbsp;&nbsp;Search</b-btn>
                </div>
            </b-form-row>
        </b-form>
    </b-card>

    <div class="mb-4 p-4 card">
        <vue-universal-table
            :api_url="'/api/user/userDashboardList'"
            :filter="filter"
            :update="vue_universal_table.update"
            :fields="vue_universal_table.fields"
            :buttons="vue_universal_table.buttons"
            :special_buttons="vue_universal_table.special_buttons"
            :process_data="vue_universal_table.process_data"
            :sort_by="'user_id'"
            :sort_order="'DESC'"
            @onEdit="editUserClicked"
            @onDelete="deleteUser"
            @onAddUser="viewModal.newUser = true"
        ></vue-universal-table>
    </div>

    <div v-if="viewModal.newUser">
        <new-user-modal
            @closeModal="viewModal.newUser = false"
            @triggerAlert="triggerAlert"
        ></new-user-modal>
    </div>

    <div v-if="viewModal.editUser">
        <edit-user-modal
            :user_id="user_id_for_edit"
            @closeModal="viewModal.editUser = false"
            @triggerAlert="triggerAlert"
        ></edit-user-modal>
    </div>
</div>
</template>

<script>
import newUserModal from './childComponents/newUserModal'
import editUserModal from './childComponents/editUserModal'

export default {
    name: 'user-management',
    metaInfo: {
        title: 'User Management'
    },
    data () {
        return {
            infoPopup : this.$store.state.hoverInformation.setting.userManagement,
            alert : {
                showAlert : false,
                isError : false,
                alertMessage : [],
            },
            filter : {
                search : '',
            },
            vue_universal_table : {
                update : 0,
                fields : [
                    {
                        title : 'User ID',
                        variable : 'user_id'
                    },
                    {
                        title : 'Username',
                        variable : 'username'
                    },
                    {
                        title : 'Name',
                        variable : 'name'
                    },
                    {
                        title : 'Status',
                        variable : 'status',
                        style : '',
                        style_condition : {
                            ACTIVE : 'background-color: green; color: white; font-weight: bold;',
                            INACTIVE : 'background-color: red; color: white; font-weight: bold;'
                        }
                    },
                    {
                        title : 'User Type',
                        variable : 'type'
                    },
                    {
                        title : 'Region / Dealer Access',
                        variable : 'permissionString'
                    }
                ],
                buttons : [
                    {
                        title : 'Edit',
                        class : 'fas fa-user-edit btn-outline-primary',
                        variable : 'user_id'
                    },
                    {
                        title : 'Delete',
                        class : 'fas fa-user-times btn-outline-danger',
                        variable : 'user_id'
                    }
                ],
                special_buttons : ['addUser'],
                process_data : `for (var i in data) data[i].permissionString = data[i].permission ? data[i].permission.join(", ") : '-'`,
            },
            viewModal : {
                newUser : false,
                editUser : false,
            },
            user_id_for_edit : 0,
        }
    },
    components : {
        newUserModal,
        editUserModal,
    },
    mounted () {

    },
    methods : {
        closeAllModal(){
            var keys = Object.keys(this.viewModal)
            for(var i = 0; i < keys.length; i++){
                this.viewModal[keys[i]] = false;
            }
            this.vue_universal_table.update++
        },
        deleteUser(user_id){
            let vm = this;
            var user = {'userId' : user_id}
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085D6',
                cancelButtonColor: '#D33',
                confirmButtonText: 'Delete'
            }).then((result)=>{
                console.log(result)
                if (result.value) {
                    this.$http.delete('/api/user/deleteUserDashboard', {data : user})
                    .then((res)=>{
                        vm.vue_universal_table.update++
                        vm.$swal.fire(
                            'Deleted',
                            'User has been successfully removed',
                            'success'
                        )
                    })
                    .catch((err)=>{
                        console.log(err)
                    })
                } 
            })
        },
        editUserClicked(user_id){
            this.user_id_for_edit = user_id;
            this.viewModal.editUser = true;
        },
        triggerAlert(isError, alertMessage){//Boolean, Array
            let vm = this;

            if(!isError) this.closeAllModal();//SPECIAL FOR THIS PAGE (CLOSE MODAL AND REFRESH DATA)

            vm.alert.isError = isError;
            vm.alert.alertMessage.length = 0;
            vm.alert.alertMessage = this.alert.alertMessage.concat(alertMessage);

            vm.alert.showAlert = true;
            setTimeout(()=>{
                vm.alert.showAlert = false;
            }, 5000);
        },
    }
}
</script>

<style>
    /* ALERT MESSAGE STARTS */
    .alertmessage {
        position: fixed;
        top: 10%;
        left: 47.5%;
        z-index:9999;
        width: 300px;
        text-align: center;
    }
    .alertmessage strong{
        font-size: 150%
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    /* ALERT MESSAGE ENDS */
</style>