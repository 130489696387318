<script>/* eslint-disable */</script>
<template>
    <div>
        <!--Filter Option-->
        <b-card title="QR Code Replacement" :sub-title="infoPopup.title" header-tag="h6" class="mb-4">
            <hr>
            <b-form>
                <b-form-row>
                    <b-form-group label="Search By" class="col-md-4">
                       <b-input-group class="dateCss mt-1">
                            <b-select class="mt-1" v-model="filter.search_by" :options="filterOptions" />
                            <b-input  class="mt-1" placeholder="Search for..." v-model="filter[filter.search_by]" />
                        </b-input-group>
                    </b-form-group>
                    <b-form-group label="Region" class="col-md-4">
                        <Multiselect 
                            v-if="filterData"
                            class="mt-1"
                            :options="filterData.regions"
                            label="name"
                            track-by="loc_code"
                            :multiple="true"
                            v-model="filter.region"
                            placeholder="All Regions"
                        />
                    </b-form-group>
                </b-form-row>
                <b-btn class="col-md-4" type="button" variant="primary" @click="vue_universal_table.update++" :disabled="isFetching"><span class="fa fa-filter"></span>&nbsp;&nbsp;Search</b-btn>
                <b-btn class="col-md-4" type="button" variant="success" @click="showExcelModal = true" :disabled="isFetching"><span class="fa fa-file-excel"></span>&nbsp;&nbsp;Export To Excel</b-btn>
            </b-form>
        </b-card>
        <b-card>
            <vue-universal-table
                :api_url="'api/reports/qr-code-replacement'"
                :fields="vue_universal_table.fields"
                :update="vue_universal_table.update"
                :fixed_header="true"
                :filter="filter"
                :infinite_scroll="true"
                @fetchStart="isFetching = true"
                @fetchEnd="isFetching = false"
            ></vue-universal-table>
        </b-card>

        <transition name="modal" v-if="showExcelModal">
            <div class="modal-mask">
                <div class="modal-wrapper" @click="showExcelModal = false">
                    <div class="row">
                        <div class="col-xs-0 col-sm-0 col-md-3 col-lg-3"></div>
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <div class="modal-container" @click.stop>

                                <div class="modal-header" style="justify-content: flex-end;">
                                    <button type="button" class="close" @click="showExcelModal = false">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                
                                <div class="modal-body">
                                    <h4>Please Select The Time Range To Generate the Excel Report</h4>

                                    <p class="mt-2 mb-2" style="color:red">{{error}}</p>

                                    <flat-pickr v-model="range" :config="rangeConfig" :placeholder="!isIEMode ? 'Select Date' : null"/>

                                    <div class="row mt-4">
                                        <div class="col-md-3 col-12">
                                            <button type="button" @click="downloadExcel()" class="btn btn-success w-100">Download Excel</button>
                                        </div>
                                        <div class="col-md-3 col-12">
                                            <button type="button" @click="showExcelModal = false" class="btn btn-danger w-100">Cancel</button>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-0 col-sm-0 col-md-1 col-lg-2"></div>
                    </div>
                </div>
            </div>
        </transition>

    </div>
</template>

<script>
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import Multiselect  from 'vue-multiselect'

function isRTL () {
  return document.documentElement.getAttribute('dir') === 'rtl' || document.body.getAttribute('dir') === 'rtl'
}

export default {
    metaInfo: {
        title: 'QR Code Replacement Report'
    },
    components: {
        flatPickr,
        Multiselect 
    },
    data () {
        return {
            isFetching : false,

            moment: moment,
            infoPopup : this.$store.state.hoverInformation.report.qrCodeReplacement,
            vue_universal_table : {
                fields : [
                    {
                        title : 'Old QR Code',
                        variable : 'old_qr'
                    },
                    {
                        title : 'New QR Code',
                        variable : 'new_qr'
                    },
                    {
                        title : 'Engine Number',
                        variable : 'engine_no'
                    },
                    {
                        title : 'Location',
                        variable : 'loc_code'
                    },
                    {
                        title : 'Replaced Date',
                        variable : 'active_date'
                    }
                ],
                update : 0,
            },
            filter: {
                qrcode: '',
                engine_no: '',
                search_by: 'qrcode',
                region: []
            },
            filterOptions: [
                {
                    text: 'QR Code',
                    value: 'qrcode'
                }, {
                    text: 'Engine Number',
                    value: 'engine_no'
                }
            ],
            filterData: Object.assign({} , this.$store.state.filterData),
            rangeConfig: {
                mode: 'range',
                altInput: true,
                animate: !isRTL()
            },
            range: '',
            showExcelModal: false,
            error: ''
        }
    },
    mounted () {

    },
    watch: {
        'filter.search_by' : function () {
          this.filter.qrcode = ''
          this.filter.engine_no = ''
        }
    },
    methods: {
        downloadExcel () {
            this.error = ''
            if (this.range == '') {
                this.error = "Please Select Date Range Before Downloading Excel."
                return;
            }

            let filterString = '?'
            let dateTime = this.range.split(' to ')
            filterString += `start=${dateTime[0]}&`
            filterString += `end=${dateTime[1]}&`

            if (this.filter.region.length > 0) {
              let region = []
                for (var j = 0 ; j < this.filter.region.length ; j++ ) {
                  region.push(this.filter.region[j].loc_code)
                }
                filterString += `region=${region}&`
            }
 
            let url = '/api/reports/qr-code-replacement/download-excel' + filterString

            this.$http.get(url , {responseType : 'blob'})
            .then((resp)=>{
                const blob = new Blob([resp.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                let url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = "QR Code Data (" + this.range + ")";
                a.click();
                window.URL.revokeObjectURL(url);
                this.range = ''
            })
            .catch((err)=>{
                console.log(err);
            })
        }
    }
}
</script>

<style scoped>
  .modal-mask {
      position: fixed;
      z-index: 1020;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .5);
      display: table;
      transition: opacity .3s ease;
  }

  .modal-wrapper {
      display: table-cell;
      vertical-align: middle;
  }

  .modal-container {
      width: 100%;
      margin: 0px auto;
      padding: 20px 30px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
      transition: all .3s ease;
      font-family: Helvetica, Arial, sans-serif;
  }

  .modal-header h3 {
      margin-top: 0;
      color: #42b983;
  }

  .modal-body {
      margin: 20px 0;
  }

  .modal-default-button {
      float: right;
  }

  /*
  * The following styles are auto-applied to elements with
  * transition="modal" when their visibility is toggled
  * by Vue.js.
  *
  * You can easily play with the modal transition by editing
  * these styles.
  */

  .modal-enter {
      opacity: 0;
  }

  .modal-leave-active {
      opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
  }

  /* MODAL STARTS */
  .modal-body{
      margin:0
  }

  .modal-body .moduleLine{
      margin-bottom: 0vh;
  }

  .modal-body .moduleLine a{
      text-decoration: none;
  }

  .modal-body .moduleLine .moduleBox:hover{
      background-color: #E0E0E0;
      transition: background-color 0.7s ease;
      color: #212121;
      transition: color 0.7s ease;
  }

  .modal-body .moduleLine .moduleBox{
      border:1px solid #BDBDBD;
      border-radius: 20px;
      padding: 20px 0;
      color:black;
      margin-bottom: 3vh;
  }

  .modal-body .moduleLine .moduleBox i{
      font-size:5vh;
  }

  h3.modalHeadline{
      margin:0 0 20px 0;
      font-weight: bold;
      text-align : center;
  }
  /* MODAL ENDS */

</style>
