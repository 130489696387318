<template>
	<div>
		<div class="row">
			<div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 stats-outer-box">
				<div class="stats-inner-box" style="background-color:#49B6D6">
					<div class="row" style="height: 100%">
						<div class="col-8">
						<p>Breach</p>
						<p class="stats-value">
							-
							<!-- <span class="stats-value-support">(4,5)</span> -->
						</p>
						</div>
						<div class="col-4 icon">
						<i class="fas fa-map-marker-alt"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 stats-outer-box">
				<div class="stats-inner-box" style="background-color: #F59C19;">
					<div class="row" style="height: 100%">
						<div class="col-8">
						<p>Breach Other Teritories</p>
						<p class="stats-value">
							<!-- {{ numberWithCommas(data.total_h2) }} -->
							-
							<!-- <span class="stats-value-support">(4,5)</span> -->
						</p>
						</div>
						<div class="col-4 icon">
						<i class="fas fa-map-marked-alt"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 stats-outer-box">
				<div class="stats-inner-box" style="background-color:#DC143C">
					<div class="row" style="height: 100%">
						<div class="col-8">
						<p>Return To Service</p>
						<p class="stats-value" v-if="mapData">
							{{mapData.purchase_and_service.length + mapData.purchase_and_service_distant.length}}
							<!-- <span class="stats-value-support">(4,5)</span> -->
						</p>
						<p class="stats-value" v-else>
							Loading.....
							<!-- <span class="stats-value-support">(4,5)</span> -->
						</p>
						</div>
						<div class="col-4 icon">
						<i class="fas fa-tools"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 stats-outer-box">
				<div class="stats-inner-box" style="background-color: #BA55D3;">
					<div class="row" style="height: 100%">
						<div class="col-8">
						<p>Purchase Only</p>
						<p class="stats-value" v-if="mapData">
							{{mapData.purchase_only.length + mapData.purchase_distant.length}}
							<!-- <span class="stats-value-support">(4,5)</span> -->
						</p>
						<p class="stats-value" v-else>
							Loading.....
							<!-- <span class="stats-value-support">(4,5)</span> -->
						</p>
						</div>
						<div class="col-4 icon">
						<i class="fas fa-motorcycle"></i>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div style="height:500px" ref="testMap">
		</div>
		<div class="mt-3 row">
			<h5 class="col-12 col-md-3">Legend :</h5>
			<div class="row col-md-3 col-12">
				<div class="col-3">
					<div class="dot" style="background-color:#BA55D3"></div>
				</div>
				<div class="col-9">
					<p>Purchase Only</p>
				</div>
			</div>
			<div class="row col-md-3 col-12">
				<div class="col-3">
					<div class="dot" style="background-color:#DC143C"></div>
				</div>
				<div class="col-9">
					<p>Purchase And Service In Distant Dealer</p>
				</div>
			</div>
			<div class="row col-md-3 col-12">
				<div class="col-3">
					<div class="dot" style="background-color:#0F0"></div>
				</div>
				<div class="col-9">
					<p>Purchase And Service</p>
				</div>
			</div>
		</div>
		<div class=" row">
			<div class="col-md-3"></div>
			<div class="row col-md-3 col-12">
				<div class="col-3">
					<div class="dot" style="background-color:#FFA500"></div>
				</div>
				<div class="col-9">
					<p>Purchase Only In Distant Dealer</p>
				</div>
			</div>
			<div class="row col-md-3 col-12">
				<div class="col-3">
					<div><img src="/honda.png" style="width:20px;height:20px"></div>
				</div>
				<div class="col-9">
					<p>Dealer Honda HSO</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core"
import * as am4maps from "@amcharts/amcharts4/maps"

export default {
	async mounted () {
		this.data = await this.sampleData()
		let data = await this.loadData()
		this.mapData = data
		this.createChart(data)
	},
	data () {
		return {
			map: null,
			mapData: null
		}
	},
	props: ['area', 'data'],
	watch: {
		area : async function () {
			this.map.dispose()
			this.mapData = null
			if(!this.data) this.data = []
			let data = await this.loadData()
			this.mapData = data
			this.createChart(data)
		}
	},
	methods: {
		sampleData () {
			let data = [
  {
    "buy_lat": -7.18032,
    "buy_lng": 109.830661,
    "buy_name": "Annette Valencia",
    "commercial_name": "SPORT",
    "service_lat": -7.121009,
    "service_lng": 109.864273,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.117731,
    "buy_lng": 109.823698,
    "buy_name": "Dona Monroe",
    "commercial_name": "NMAX",
    "service_lat": -7.147462,
    "service_lng": 110.146215,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.25379,
    "buy_lng": 109.977747,
    "buy_name": "Giles Hardin",
    "commercial_name": "AT",
    "service_lat": -7.33242,
    "service_lng": 110.157423,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.222021,
    "buy_lng": 109.98689,
    "buy_name": "Jenifer Willis",
    "commercial_name": "NMAX",
    "service_lat": -7.219491,
    "service_lng": 110.072567,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.088724,
    "buy_lng": 109.801709,
    "buy_name": "Esther Summers",
    "commercial_name": "AT",
    "service_lat": -7.193129,
    "service_lng": 110.02214,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.084046,
    "buy_lng": 109.882019,
    "buy_name": "Lori Stanley",
    "commercial_name": "AT",
    "service_lat": -7.208288,
    "service_lng": 109.884015,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.257482,
    "buy_lng": 109.933687,
    "buy_name": "Osborne Peters",
    "commercial_name": "SPORT",
    "service_lat": -7.312796,
    "service_lng": 110.024177,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.098855,
    "buy_lng": 109.799682,
    "buy_name": "Dillard Washington",
    "commercial_name": "SPORT",
    "service_lat": -7.199971,
    "service_lng": 109.929868,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.238486,
    "buy_lng": 109.819516,
    "buy_name": "Freda Sims",
    "commercial_name": "SPORT",
    "service_lat": -7.088258,
    "service_lng": 109.858831,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.315121,
    "buy_lng": 109.862858,
    "buy_name": "Robbins Peterson",
    "commercial_name": "NMAX",
    "service_lat": -7.199412,
    "service_lng": 110.071192,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.091241,
    "buy_lng": 109.999765,
    "buy_name": "Castaneda Harding",
    "commercial_name": "NMAX",
    "service_lat": -7.149862,
    "service_lng": 109.820555,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.186487,
    "buy_lng": 110.005535,
    "buy_name": "Brigitte Anthony",
    "commercial_name": "SPORT",
    "service_lat": -7.196227,
    "service_lng": 109.977118,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.24986,
    "buy_lng": 110.104609,
    "buy_name": "Wheeler Colon",
    "commercial_name": "SPORT",
    "service_lat": -7.332488,
    "service_lng": 109.933914,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.080363,
    "buy_lng": 109.882716,
    "buy_name": "Valenzuela Edwards",
    "commercial_name": "AT",
    "service_lat": -7.157414,
    "service_lng": 110.075424,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.27867,
    "buy_lng": 109.973649,
    "buy_name": "Billie Hobbs",
    "commercial_name": "AT",
    "service_lat": -7.26169,
    "service_lng": 110.070175,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.196539,
    "buy_lng": 109.953556,
    "buy_name": "Lena Mcdaniel",
    "commercial_name": "AT",
    "service_lat": -7.177624,
    "service_lng": 109.925268,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.178527,
    "buy_lng": 110.014087,
    "buy_name": "Katherine Valentine",
    "commercial_name": "SPORT",
    "service_lat": -7.244469,
    "service_lng": 110.111105,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.138341,
    "buy_lng": 109.88843,
    "buy_name": "Moore Buckner",
    "commercial_name": "AT",
    "service_lat": -7.213591,
    "service_lng": 109.989662,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.168975,
    "buy_lng": 109.806125,
    "buy_name": "Brewer Macdonald",
    "commercial_name": "SPORT",
    "service_lat": -7.110754,
    "service_lng": 109.873727,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.268905,
    "buy_lng": 109.880171,
    "buy_name": "Harriett Cooper",
    "commercial_name": "AT",
    "service_lat": -7.328987,
    "service_lng": 109.801592,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.219813,
    "buy_lng": 110.029667,
    "buy_name": "Bush Robbins",
    "commercial_name": "SPORT",
    "service_lat": -7.147279,
    "service_lng": 110.136016,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.277831,
    "buy_lng": 109.903783,
    "buy_name": "Verna Miller",
    "commercial_name": "SPORT",
    "service_lat": -7.152777,
    "service_lng": 110.055605,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.115023,
    "buy_lng": 110.135395,
    "buy_name": "Bettye Lott",
    "commercial_name": "AT",
    "service_lat": -7.215625,
    "service_lng": 110.079793,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.151539,
    "buy_lng": 110.023788,
    "buy_name": "Leonor Mills",
    "commercial_name": "NMAX",
    "service_lat": -7.289047,
    "service_lng": 110.143583,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.237643,
    "buy_lng": 110.088383,
    "buy_name": "Miranda Mathews",
    "commercial_name": "SPORT",
    "service_lat": -7.159131,
    "service_lng": 109.834275,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.193658,
    "buy_lng": 110.008746,
    "buy_name": "Fleming Scott",
    "commercial_name": "AT",
    "service_lat": -7.156553,
    "service_lng": 110.118902,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.207853,
    "buy_lng": 109.809095,
    "buy_name": "Chaney Richard",
    "commercial_name": "AT",
    "service_lat": -7.121494,
    "service_lng": 110.123667,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.164175,
    "buy_lng": 109.962107,
    "buy_name": "Dunlap Montoya",
    "commercial_name": "AT",
    "service_lat": -7.106149,
    "service_lng": 109.95095,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.139687,
    "buy_lng": 110.088374,
    "buy_name": "Lee Ratliff",
    "commercial_name": "SPORT",
    "service_lat": -7.227167,
    "service_lng": 109.823705,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.230392,
    "buy_lng": 109.970498,
    "buy_name": "Adrian Fischer",
    "commercial_name": "SPORT",
    "service_lat": -7.142853,
    "service_lng": 109.841278,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.106992,
    "buy_lng": 110.1544,
    "buy_name": "Celia Mcgowan",
    "commercial_name": "SPORT",
    "service_lat": -7.153497,
    "service_lng": 109.852799,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.306663,
    "buy_lng": 110.046826,
    "buy_name": "Carly Barnes",
    "commercial_name": "NMAX",
    "service_lat": -7.330016,
    "service_lng": 110.071199,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.207897,
    "buy_lng": 110.097726,
    "buy_name": "Luisa Cortez",
    "commercial_name": "SPORT",
    "service_lat": -7.260504,
    "service_lng": 110.070853,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.227825,
    "buy_lng": 110.123089,
    "buy_name": "Marquez Carey",
    "commercial_name": "NMAX",
    "service_lat": -7.323925,
    "service_lng": 110.035633,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.253603,
    "buy_lng": 110.053472,
    "buy_name": "Lang Hodges",
    "commercial_name": "NMAX",
    "service_lat": -7.140979,
    "service_lng": 110.096601,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.094213,
    "buy_lng": 109.939508,
    "buy_name": "Hunt Reynolds",
    "commercial_name": "AT",
    "service_lat": -7.140183,
    "service_lng": 109.880506,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.147802,
    "buy_lng": 110.005203,
    "buy_name": "Isabel Henry",
    "commercial_name": "SPORT",
    "service_lat": -7.302671,
    "service_lng": 109.982533,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.145909,
    "buy_lng": 109.915281,
    "buy_name": "Workman Randall",
    "commercial_name": "SPORT",
    "service_lat": -7.240011,
    "service_lng": 109.827648,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.162727,
    "buy_lng": 109.901252,
    "buy_name": "Acosta Kirkland",
    "commercial_name": "NMAX",
    "service_lat": -7.209135,
    "service_lng": 110.046694,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.126331,
    "buy_lng": 110.111796,
    "buy_name": "Randi Sparks",
    "commercial_name": "SPORT",
    "service_lat": -7.109559,
    "service_lng": 109.863153,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.323255,
    "buy_lng": 110.093273,
    "buy_name": "Hines Delgado",
    "commercial_name": "NMAX",
    "service_lat": -7.218032,
    "service_lng": 110.050477,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.240217,
    "buy_lng": 110.009361,
    "buy_name": "Pamela Frank",
    "commercial_name": "AT",
    "service_lat": -7.094155,
    "service_lng": 109.973756,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.153254,
    "buy_lng": 109.903113,
    "buy_name": "Magdalena Savage",
    "commercial_name": "NMAX",
    "service_lat": -7.22944,
    "service_lng": 109.97913,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.095947,
    "buy_lng": 109.937301,
    "buy_name": "Odom Bowers",
    "commercial_name": "AT",
    "service_lat": -7.172373,
    "service_lng": 109.99522,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.256829,
    "buy_lng": 110.135126,
    "buy_name": "Duffy Gutierrez",
    "commercial_name": "NMAX",
    "service_lat": -7.284439,
    "service_lng": 110.159682,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.283683,
    "buy_lng": 109.932373,
    "buy_name": "Cathy Marsh",
    "commercial_name": "SPORT",
    "service_lat": -7.326113,
    "service_lng": 109.923267,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.272855,
    "buy_lng": 109.963415,
    "buy_name": "Dorothea Cook",
    "commercial_name": "NMAX",
    "service_lat": -7.1175,
    "service_lng": 109.806217,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.268701,
    "buy_lng": 109.978769,
    "buy_name": "Mercer Justice",
    "commercial_name": "NMAX",
    "service_lat": -7.297008,
    "service_lng": 110.133104,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.116132,
    "buy_lng": 109.845592,
    "buy_name": "Fisher Mclean",
    "commercial_name": "SPORT",
    "service_lat": -7.096464,
    "service_lng": 109.994089,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.263447,
    "buy_lng": 110.084802,
    "buy_name": "Edith Barber",
    "commercial_name": "SPORT",
    "service_lat": -7.158849,
    "service_lng": 110.050943,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.158058,
    "buy_lng": 109.95576,
    "buy_name": "Peterson House",
    "commercial_name": "AT",
    "service_lat": -7.111857,
    "service_lng": 110.137551,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.271496,
    "buy_lng": 109.914577,
    "buy_name": "Farrell Bauer",
    "commercial_name": "SPORT",
    "service_lat": -7.303075,
    "service_lng": 110.056566,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.170893,
    "buy_lng": 109.995295,
    "buy_name": "Singleton Hays",
    "commercial_name": "AT",
    "service_lat": -7.125315,
    "service_lng": 110.154712,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.126527,
    "buy_lng": 110.107547,
    "buy_name": "Alma Vance",
    "commercial_name": "NMAX",
    "service_lat": -7.316899,
    "service_lng": 110.056712,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.093774,
    "buy_lng": 110.098751,
    "buy_name": "Beulah Moreno",
    "commercial_name": "NMAX",
    "service_lat": -7.145977,
    "service_lng": 110.019013,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.276557,
    "buy_lng": 110.159925,
    "buy_name": "Roxanne Benton",
    "commercial_name": "NMAX",
    "service_lat": -7.188523,
    "service_lng": 109.980512,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.148681,
    "buy_lng": 109.802605,
    "buy_name": "Sheena Weber",
    "commercial_name": "SPORT",
    "service_lat": -7.176653,
    "service_lng": 109.916495,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.279992,
    "buy_lng": 109.964789,
    "buy_name": "Kennedy May",
    "commercial_name": "SPORT",
    "service_lat": -7.315032,
    "service_lng": 109.953535,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.267273,
    "buy_lng": 109.864049,
    "buy_name": "Alford Adkins",
    "commercial_name": "SPORT",
    "service_lat": -7.329489,
    "service_lng": 109.937929,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.182047,
    "buy_lng": 110.099588,
    "buy_name": "Susanne Mcdonald",
    "commercial_name": "AT",
    "service_lat": -7.218078,
    "service_lng": 109.895209,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.169707,
    "buy_lng": 109.975289,
    "buy_name": "Courtney Lucas",
    "commercial_name": "NMAX",
    "service_lat": -7.217231,
    "service_lng": 110.053484,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.168359,
    "buy_lng": 110.104537,
    "buy_name": "Melissa Carson",
    "commercial_name": "AT",
    "service_lat": -7.240001,
    "service_lng": 109.882493,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.272222,
    "buy_lng": 109.951679,
    "buy_name": "Miles Maynard",
    "commercial_name": "NMAX",
    "service_lat": -7.219662,
    "service_lng": 109.949159,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.280594,
    "buy_lng": 109.80396,
    "buy_name": "Stacy Heath",
    "commercial_name": "SPORT",
    "service_lat": -7.195633,
    "service_lng": 109.863866,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.275646,
    "buy_lng": 109.969941,
    "buy_name": "Emma Franks",
    "commercial_name": "AT",
    "service_lat": -7.316453,
    "service_lng": 109.800606,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.106292,
    "buy_lng": 109.901344,
    "buy_name": "Livingston Hensley",
    "commercial_name": "SPORT",
    "service_lat": -7.127734,
    "service_lng": 109.981852,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.274499,
    "buy_lng": 109.810494,
    "buy_name": "Leta Bullock",
    "commercial_name": "NMAX",
    "service_lat": -7.133139,
    "service_lng": 109.853156,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.307094,
    "buy_lng": 109.84405,
    "buy_name": "Wilda Molina",
    "commercial_name": "SPORT",
    "service_lat": -7.082382,
    "service_lng": 109.963828,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.129381,
    "buy_lng": 110.066504,
    "buy_name": "Virgie Vaughan",
    "commercial_name": "AT",
    "service_lat": -7.301327,
    "service_lng": 109.977972,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.099539,
    "buy_lng": 109.873432,
    "buy_name": "Milagros Camacho",
    "commercial_name": "SPORT",
    "service_lat": -7.154384,
    "service_lng": 110.033902,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.259448,
    "buy_lng": 109.942503,
    "buy_name": "Nash Ryan",
    "commercial_name": "NMAX",
    "service_lat": -7.245107,
    "service_lng": 109.858436,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.264185,
    "buy_lng": 109.943412,
    "buy_name": "Shanna Kerr",
    "commercial_name": "AT",
    "service_lat": -7.085146,
    "service_lng": 109.938538,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.245582,
    "buy_lng": 109.840953,
    "buy_name": "Goodwin Tucker",
    "commercial_name": "SPORT",
    "service_lat": -7.197558,
    "service_lng": 109.845997,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.286469,
    "buy_lng": 110.152137,
    "buy_name": "Dennis Fitzpatrick",
    "commercial_name": "AT",
    "service_lat": -7.084842,
    "service_lng": 110.090168,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.208695,
    "buy_lng": 109.963482,
    "buy_name": "Graham Ayala",
    "commercial_name": "SPORT",
    "service_lat": -7.166946,
    "service_lng": 109.86847,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.273991,
    "buy_lng": 109.88439,
    "buy_name": "Ewing Bennett",
    "commercial_name": "NMAX",
    "service_lat": -7.297136,
    "service_lng": 109.908012,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.329295,
    "buy_lng": 109.86051,
    "buy_name": "Morin Higgins",
    "commercial_name": "NMAX",
    "service_lat": -7.231186,
    "service_lng": 110.154866,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.258697,
    "buy_lng": 109.873783,
    "buy_name": "Vang Quinn",
    "commercial_name": "AT",
    "service_lat": -7.243593,
    "service_lng": 109.986322,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.161308,
    "buy_lng": 110.151362,
    "buy_name": "Lara Lester",
    "commercial_name": "NMAX",
    "service_lat": -7.225811,
    "service_lng": 110.006226,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.14681,
    "buy_lng": 110.066665,
    "buy_name": "Marsh Langley",
    "commercial_name": "NMAX",
    "service_lat": -7.332401,
    "service_lng": 109.812873,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.32276,
    "buy_lng": 110.144215,
    "buy_name": "Alison Whitfield",
    "commercial_name": "AT",
    "service_lat": -7.181672,
    "service_lng": 109.917257,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.173694,
    "buy_lng": 109.805315,
    "buy_name": "Mallory Byers",
    "commercial_name": "SPORT",
    "service_lat": -7.237269,
    "service_lng": 110.142466,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.149362,
    "buy_lng": 109.986136,
    "buy_name": "Lorrie Rasmussen",
    "commercial_name": "AT",
    "service_lat": -7.266275,
    "service_lng": 109.999749,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.331362,
    "buy_lng": 109.803232,
    "buy_name": "Lowery Contreras",
    "commercial_name": "NMAX",
    "service_lat": -7.276624,
    "service_lng": 110.106559,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.09338,
    "buy_lng": 110.012785,
    "buy_name": "Dolores Mcdowell",
    "commercial_name": "NMAX",
    "service_lat": -7.27294,
    "service_lng": 110.061622,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.280477,
    "buy_lng": 109.959576,
    "buy_name": "Tammie Durham",
    "commercial_name": "NMAX",
    "service_lat": -7.217894,
    "service_lng": 110.091087,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.118399,
    "buy_lng": 110.127196,
    "buy_name": "Isabella Bradford",
    "commercial_name": "AT",
    "service_lat": -7.125842,
    "service_lng": 109.98492,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.17322,
    "buy_lng": 110.125791,
    "buy_name": "Mooney Stephens",
    "commercial_name": "SPORT",
    "service_lat": -7.165355,
    "service_lng": 109.924936,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.24499,
    "buy_lng": 109.984161,
    "buy_name": "Melva Terry",
    "commercial_name": "SPORT",
    "service_lat": -7.333221,
    "service_lng": 110.076675,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.220516,
    "buy_lng": 109.872146,
    "buy_name": "Farmer Woods",
    "commercial_name": "AT",
    "service_lat": -7.099396,
    "service_lng": 110.074988,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.09047,
    "buy_lng": 110.064798,
    "buy_name": "Margie Cross",
    "commercial_name": "AT",
    "service_lat": -7.211037,
    "service_lng": 110.043506,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.316493,
    "buy_lng": 109.848511,
    "buy_name": "Renee Miles",
    "commercial_name": "NMAX",
    "service_lat": -7.168854,
    "service_lng": 110.114146,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.308911,
    "buy_lng": 110.00884,
    "buy_name": "Donovan Preston",
    "commercial_name": "AT",
    "service_lat": -7.218252,
    "service_lng": 109.995926,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.180771,
    "buy_lng": 109.990504,
    "buy_name": "Gonzales Chaney",
    "commercial_name": "NMAX",
    "service_lat": -7.178389,
    "service_lng": 110.086551,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.1374,
    "buy_lng": 110.160377,
    "buy_name": "Ortega Cox",
    "commercial_name": "AT",
    "service_lat": -7.318179,
    "service_lng": 109.947129,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.15994,
    "buy_lng": 110.152102,
    "buy_name": "Frances Zimmerman",
    "commercial_name": "SPORT",
    "service_lat": -7.110248,
    "service_lng": 109.977869,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.135314,
    "buy_lng": 109.939036,
    "buy_name": "Henrietta Oneil",
    "commercial_name": "NMAX",
    "service_lat": -7.150763,
    "service_lng": 109.90876,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.028298,
    "dealer_lng": 109.583596
  },
  {
    "buy_lat": -7.102643,
    "buy_lng": 109.907018,
    "buy_name": "Callie Salazar",
    "commercial_name": "SPORT",
    "service_lat": -7.139293,
    "service_lng": 109.90414,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.376583,
    "dealer_lng": 109.899239
  },
  {
    "buy_lat": -7.263769,
    "buy_lng": 110.136491,
    "buy_name": "Helena Foley",
    "commercial_name": "NMAX",
    "service_lat": -7.08638,
    "service_lng": 109.905768,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  },
  {
    "buy_lat": -7.254133,
    "buy_lng": 110.037162,
    "buy_name": "Aimee Hewitt",
    "commercial_name": "AT",
    "service_lat": -7.141062,
    "service_lng": 109.867914,
    "distant_buyer": 0,
    "distant_service": 0,
    "dealer_lat": -7.458422,
    "dealer_lng": 109.790577
  }
]
			return data;
		},
		loadData () {
			let arrayData = {
				purchase_only : [],
				purchase_and_service : [],
				purchase_distant : [],
				purchase_and_service_distant : [],
				dealer : []
			}
			console.log(this.data)

			for (var i = 0; i < this.data.length; i++) {
				if (!this.data[i].service_lat && this.data[i].service_lng) {
					if(this.data[i].distant_buyer == 0) {
						arrayData.purchase_only.push(this.data[i])
					}
					else {
						arrayData.purchase_distant.push(this.data[i])
					}
				}
				else {
					if(this.data[i].distant_service == 0) {
						arrayData.purchase_and_service.push(this.data[i])
					}
					else {
						arrayData.purchase_and_service_distant.push(this.data[i])
					}
				}
				arrayData.dealer.push(this.data[i])
 			}

			return arrayData
		},
		createChart(data) {
			
			this.map = am4core.create(this.$refs.testMap, am4maps.MapChart);
			this.map.geodataSource.url = `/MapGeoJSON/${this.area.name}.json`;
			
			this.map.projection = new am4maps.projections.Miller();

			var polygonSeries = new am4maps.MapPolygonSeries();


			polygonSeries.useGeodata = true;
			this.map.series.push(polygonSeries);

			var polygonTemplate = polygonSeries.mapPolygons.template;
			polygonTemplate.tooltipText = "{NAME_2}";
			polygonTemplate.fill =  am4core.color("#ffe")
			polygonTemplate.stroke = am4core.color("grey")
			


			var hs = polygonTemplate.states.create("hover");
			hs.properties.fill = am4core.color("#367B25");

			// Purchase And Service

			var imageSeries = this.map.series.push(new am4maps.MapImageSeries());
			

			var imageSeriesTemplate = imageSeries.mapImages.template;
			var circle = imageSeriesTemplate.createChild(am4core.Circle);
			circle.radius = 4;
			circle.fill = am4core.color("#00FF00");
			circle.stroke = am4core.color("#FFF");
			circle.strokeWidth = 1;
			circle.nonScaling = true;
			circle.tooltipText = "{buy_name} - {commercial_name}";

			imageSeriesTemplate.propertyFields.latitude = "service_lat";
			imageSeriesTemplate.propertyFields.longitude = "service_lng";

			imageSeries.data =  data.purchase_and_service
			console.log(data)
			

			// Purchase and Service in Distant Dealer

			var purchaseServiceDistantSeries = this.map.series.push(new am4maps.MapImageSeries());

			var purchaseServiceDistantSeriesTemplate = purchaseServiceDistantSeries.mapImages.template;
			var circle = purchaseServiceDistantSeriesTemplate.createChild(am4core.Circle);
			circle.radius = 4;
			circle.fill = am4core.color("#DC143C");
			circle.stroke = am4core.color("#FFF");
			circle.strokeWidth = 1;
			circle.nonScaling = true;
			circle.tooltipText = "{buy_name} - {commercial_name}";

			purchaseServiceDistantSeriesTemplate.propertyFields.latitude = "service_lat";
			purchaseServiceDistantSeriesTemplate.propertyFields.longitude = "service_lng";

			purchaseServiceDistantSeries.data = data.purchase_and_service_distant

			// Purchase Only

			var purchaseSeries = this.map.series.push(new am4maps.MapImageSeries());

			var purchaseSeriesTemplate = purchaseSeries.mapImages.template;
			var circle = purchaseSeriesTemplate.createChild(am4core.Circle);
			circle.radius = 4;
			circle.fill = am4core.color("#BA55D3");
			circle.stroke = am4core.color("#FFF");
			circle.strokeWidth = 1;
			circle.nonScaling = true;
			circle.tooltipText = "{buy_name} - {commercial_name}";

			purchaseSeriesTemplate.propertyFields.latitude = "dealer_lat";
			purchaseSeriesTemplate.propertyFields.longitude = "dealer_lng";

			purchaseSeries.data = data.purchase_only

			// Purchase Only in Distant Dealer

			var purchaseDistantSeries = this.map.series.push(new am4maps.MapImageSeries());

			var purchaseDistantSeriesTemplate = purchaseDistantSeries.mapImages.template;
			var circle = purchaseDistantSeriesTemplate.createChild(am4core.Circle);
			circle.radius = 4;
			circle.fill = am4core.color("#FFA500");
			circle.stroke = am4core.color("#FFF");
			circle.strokeWidth = 1;
			circle.nonScaling = true;
			circle.tooltipText = "{buy_name} - {commercial_name}";

			purchaseDistantSeriesTemplate.propertyFields.latitude = "dealer_lat";
			purchaseDistantSeriesTemplate.propertyFields.longitude = "dealer_lng";

			purchaseDistantSeries.data = data.purchase_distant
			
			// Dealer Honda Marker
			var imageSeries2 = this.map.series.push(new am4maps.MapImageSeries());

			var imageSeriesTemplate2 = imageSeries2.mapImages.template;
			var marker = imageSeriesTemplate2.createChild(am4core.Image);
			marker.href = "/honda.png";
			marker.width = 20;
			marker.height = 20;
			marker.nonScaling = true;
			marker.tooltipText = "Dealer Honda HSO";
			marker.horizontalCenter = "middle";
			marker.verticalCenter = "bottom";

			imageSeriesTemplate2.propertyFields.latitude = "dealer_lat";
			imageSeriesTemplate2.propertyFields.longitude = "dealer_lng";

			imageSeries2.data = this.data
		}
	}
}
</script>

<style scoped>
	.dot {
		height: 10px;
		width: 10px;
		border-radius: 50%;
		display: inline-block;
	}
</style>