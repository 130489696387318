<template>
	<transition name="modal" >
		<div class="modal-mask">
			<div class="modal-wrapper" @click="$emit('closeModal')">
				<div class="row">
					<div class="col-xs-0 col-sm-0 col-md-2 col-lg-2"></div>
					<div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
						<div class="modal-container" @click.stop>

							<div class="modal-header" style="justify-content: flex-end;">
								<button type="button" class="close" @click="$emit('closeModal')">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							
							<div class="modal-body" style="overflow-y:scroll;height:75vh !important">
								<!-- Vehicle Data Start -->
								<div class="row">
									<div class="col-md-6 col-12">
										<timelineChart :data="data.scan_history" :mobile="true" :popUp="popUp.vehicleTrackingMap" class="shadow border border-secondary" style="border-radius:10px"></timelineChart>
									</div>
									<div class="col-md-6 col-12" style="overflow-y:scroll">
										<div>
											<h2 class="text-center">Vehicle Information 
												<span class="col-md-1 col-sm-1 col-2 mt-2">
													<i class="fa fa-info-circle" id="vehicleInformation"></i>
													<b-popover
														target="vehicleInformation"
														title="Vehicle Information"
														triggers="hover focus"
														placement="top"
														:content="popUp.vehicleInformation"
													></b-popover>
												</span>
											</h2>
											
											<div class="row">
												<div class="col-12">
													<h5>QR Code</h5>
													<p>{{info.qrcode}}</p>
												</div>
											</div>
											<div class="row">
												<div class="col-12">
													<h5>Engine Number</h5>
													<p>{{info.engine_no}}</p>
												</div>
											</div>
											<div class="row">
												<div class="col-12">
													<h5>Frame Number</h5>
													<p>{{info.frame_no}}</p>
												</div>
											</div>
											<div class="row">
												<div class="col-12">
													<h5>Vehicle Type Description</h5>
													<table class="table w-75 table-borderless">
														<tr>
															<td>Segment </td>
															<td> : </td>
															<td> {{info.segment}}</td>
														</tr>
														<tr>
															<td>Series </td>
															<td> : </td>
															<td> {{info.series}}</td>
														</tr>
														<tr>
															<td>Commercial Name </td>
															<td> : </td>
															<td> {{info.commercial_name}}</td>
														</tr>
														<tr>
															<td>Colour </td>
															<td> : </td>
															<td> {{info.color_code}} - {{info.color_description}}</td>
														</tr>
													</table>
												</div>
											</div>
											<div class="row">
												<div class="col-12">
													<h5>Location Details</h5>
													<table class="table w-75 table-borderless">
														<tr>
															<td>Original Region </td>
															<td> : </td>
															<td> {{info.ori_loc_code}}</td>
														</tr>
														<tr>
															<td>Scan Source </td>
															<td> : </td>
															<td> {{info.scan_source}}</td>
														</tr>
														<tr>
															<td>Current Position </td>
															<td> : </td>
															<td> {{info.current_loc_code}} ( {{info.current_loc_type}} )</td>
														</tr>
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- Vehicle Data End -->
								
								<!-- Service History Start -->
								<div style="margin-top:75px" >
									<h3 class="text-center">Service History 
										<span class="col-md-1 col-sm-1 col-2 mt-2">
											<i class="fa fa-info-circle" id="serviceHistory"></i>
											<b-popover
												target="serviceHistory"
												title="Service History"
												triggers="hover focus"
												placement="top"
												:content="popUp.serviceHistory"
											></b-popover>
										</span>
									</h3>
									<div class="row">
										<div class="col-md-1 col-0 circle" >
											<i class="fas fa-cogs fa-3x"></i>
										</div>
										<div class="col-md-11 col-12">
											<p class="text-center" v-if="data.service_history.length <= 0">No Service Data Available</p>
											<table v-else class="table table-bordered">
												<thead>
													<th>No.</th>
													<th>Location</th>
													<th>Time Of Service</th>
												</thead>
												<tbody>
													<tr v-for="(service, index) in data.service_history" :key="service.histoy_id">
														<td>{{index + 1}}</td>
														<td>{{service.current_loc_code}} ( {{service.current_loc_type}} )</td>
														<td>{{new moment(service.scan_date).format("YYYY-MM-DD HH:mm:ss")}}</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
									
								</div>
								<!-- Service History End -->

								<!-- Customer Claim History Start -->
								<div style="margin-top:50px">
									<h3 class="text-center">Customer Claim History
										<span class="col-md-1 col-sm-1 col-2 mt-2">
											<i class="fa fa-info-circle" id="customerClaim"></i>
											<b-popover
												target="customerClaim"
												title="Customer Claim History"
												triggers="hover focus"
												placement="top"
												:content="popUp.customerClaim"
											></b-popover>
										</span>
									</h3>
									<div class="row">
										<div class="col-md-1 col-0 circle" >
											<i class="fas fa-users fa-3x"></i>
										</div>
										<div class="col-md-11 col-12">
											<p class="text-center" v-if="data.customer_claim.length <= 0">Vehicle Has Not Been Sold</p>
											<table v-else class="table table-bordered">
												<thead>
													<th>No.</th>
													<th>Name of Customer</th>
													<th>Customer Location</th>
													<th>Time Of Claim</th>
												</thead>
												<tbody>
													<tr v-for="(owner, index) in data.customer_claim" :key="owner.owner_id">
														<td>{{index + 1}}</td>
														<td>{{owner.name}}</td>
														<td>{{owner.province}}</td>
														<td>{{new moment(owner.created_at).format("YYYY-MM-DD HH:mm:ss")}}</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
								<!-- Customer Claim History End -->
							</div>
						</div>
					</div>
					<div class="col-xs-0 col-sm-0 col-md-1 col-lg-2"></div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
// Charts
import timelineChart from './timelineChart'
// Library
import moment from 'moment'
export default {
	props:['data', 'info', 'popUp'],
	components: {
		timelineChart
	},
	data () {
		return {
			moment: moment
		}
	}
}
</script>

<style scoped>
  .modal-mask {
      position: fixed;
      z-index: 1020;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .5);
      display: table;
      transition: opacity .3s ease;
  }

  .modal-wrapper {
      display: table-cell;
      vertical-align: middle;
  }

  .modal-container {
      width: 100%;
      margin: 0px auto;
      padding: 20px 30px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
      transition: all .3s ease;
      font-family: Helvetica, Arial, sans-serif;
  }

  .modal-header h3 {
      margin-top: 0;
      color: #42b983;
  }

  .modal-body {
      margin: 20px 0;
  }

  .modal-default-button {
      float: right;
  }

  /*
  * The following styles are auto-applied to elements with
  * transition="modal" when their visibility is toggled
  * by Vue.js.
  *
  * You can easily play with the modal transition by editing
  * these styles.
  */

  .modal-enter {
      opacity: 0;
  }

  .modal-leave-active {
      opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
  }

  /* MODAL STARTS */
  .modal-body{
      margin:0
  }

  .modal-body .moduleLine{
      margin-bottom: 0vh;
  }

  .modal-body .moduleLine a{
      text-decoration: none;
  }

  .modal-body .moduleLine .moduleBox:hover{
      background-color: #E0E0E0;
      transition: background-color 0.7s ease;
      color: #212121;
      transition: color 0.7s ease;
  }

  .modal-body .moduleLine .moduleBox{
      border:1px solid #BDBDBD;
      border-radius: 20px;
      padding: 20px 0;
      color:black;
      margin-bottom: 3vh;
  }

  .modal-body .moduleLine .moduleBox i{
      font-size:5vh;
  }

  h3.modalHeadline{
      margin:0 0 20px 0;
      font-weight: bold;
      text-align : center;
  }
  /* MODAL ENDS */

  .button_outer_box{
    padding:30px;
  }
  .button_inner_box{
    border:1px solid #E0E0E0;
    border-radius:15px;
    box-shadow:5px 5px 15px 1px;
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    position: relative; /* If you want text inside of it */
  }
  .button_inner_box:hover{
    cursor: pointer;
  }

  .button_text{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .slightlyGray{
    color:#424242
  }

  .hoverBig{
    -webkit-animation: hoverBig 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: hoverBig 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }
  .unHoverBig{
    -webkit-animation: unHoverBig 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: unHoverBig 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }
  @-webkit-keyframes hoverBig {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
  }
  @keyframes hoverBig {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
  }
  @-webkit-keyframes unHoverBig {
    0% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes unHoverBig {
    0% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
	h5 {
		font-weight: bold;
	}
	p {
		padding-left: 10px;
		font-size: 15px;
	}
	td {
		font-size: 15px;
	}
</style>