<script>/* eslint-disable */</script>
<template>
    <div>
        <b-card title="Warehouse Behaviour - Outgoing" :sub-title="infoPopup.title" header-tag="h5" class="mb-4">
            <hr>
            <b-form>
                <b-form-row>
                    <b-form-group label="Region" class="col-md-3">
                    <Multiselect 
                        v-if="filterData"
                        class="mt-1"
                        :options="filterData.regions"
                        label="name"
                        track-by="loc_code"
                        :multiple="true"
                        v-model="filter.region"
                        :close-on-select="false"
                        placeholder="All Regions"
                    />
                    </b-form-group>
                    <b-form-group label="Branch" class="col-md-3">
                    <Multiselect 
                        v-if="filterData"
                        class="mt-1"
                        :options="filterData.branch"
                        label="name"
                        track-by="loc_code"
                        :multiple="true"
                        :close-on-select="false"
                        v-model="filter.branch"
                        placeholder="All Branches"
                    />
                    </b-form-group>
                    <b-form-group label="Month" class="col-md-3" style="height:42px" >
                    <Multiselect 
                        class="mt-1"
                        :close-on-select="true"
                        :options="filterData.month"
                        v-model="filter.month"
                        label="name"
                        track-by="value"
                    />
                    </b-form-group>
                    <b-form-group label="Year" class="col-md-3" style="height:42px" >
                    <Multiselect 
                        class="mt-1"
                        :close-on-select="true"
                        :options="filterData.year"
                        v-model="filter.year"
                    />
                    </b-form-group>
                    </b-form-row>
                    <b-form-row class="mt-4">
                    <b-form-group label="Segment" class="col-md-3">
                    <Multiselect 
                        class="mt-1"
                        :close-on-select="false"
                        v-if="filterData"
                        :options="filterData.segment"
                        label="segment"
                        track-by="segment"
                        :multiple="true"
                        v-model="filter.segment"
                        placeholder="All Segments"
                    />
                    </b-form-group>
                    <b-form-group label="Series" class="col-md-3">
                    <Multiselect 
                        class="mt-1"
                        :close-on-select="false"
                        v-if="filterData"
                        :options="filterData.series"
                        label="series"
                        track-by="series"
                        :multiple="true"
                        v-model="filter.series"
                        placeholder="All Series"
                    />
                    </b-form-group>
                    <b-form-group label="Type" class="col-md-3">
                    <Multiselect 
                        class="mt-1"
                        v-if="filterData"
                        :close-on-select="false"
                        :options="filterData.type"
                        label="type"
                        track-by="type"
                        :multiple="true"
                        v-model="filter.type"
                        placeholder="All Vehicle Types"
                    />
                    </b-form-group>
                    <b-form-group label="Colour" class="col-md-3">
                    <Multiselect 
                        class="mt-1"
                        :close-on-select="false"
                        v-if="filterData"
                        :options="filterData.colour"
                        v-model="filter.colour"
                        label="name"
                        track-by="color_code"
                        :multiple="true"
                        placeholder="All Colours"
                    />
                    </b-form-group>
                </b-form-row>
                <b-form-row>
                    <b-form-group class="col-md-1">
                        <b-btn type="button" variant="primary" @click="search">Filter</b-btn>
                    </b-form-group>
                </b-form-row>
            </b-form>
        </b-card>
        <div class="row">
			<div class="col-12">

				<b-card no-body class="mb-4">
				<b-card-header header-tag="h6" class="with-elements border-0 pr-0 pb-0">
					<h6 class="card-header-title">Outgoing Pattern by Day
                        <span class="col-md-1 col-sm-1 col-2">
                            <i class="fa fa-info-circle fa" id="outgoingPatternByDay"></i>
                            <b-popover
                            target="outgoingPatternByDay"
                            title="Outgoing Pattern by Day"
                            triggers="hover focus"
                            placement="top"
                            :content="infoPopup.outgoingPatternByDay"
                            ></b-popover>
                        </span>
                    </h6>
				</b-card-header>
				<div class="py-4 px-4">
					<ByDay :data="by_day" type="Outgoing" v-if="by_day"/>
				</div>
				</b-card>

			</div>
			<div class="col-12">

				<b-card no-body class="mb-4">
				<b-card-header header-tag="h6" class="with-elements border-0 pr-0 pb-0">
					<h6 class="card-header-title">Outgoing Pattern by Hour
                        <span class="col-md-1 col-sm-1 col-2">
                            <i class="fa fa-info-circle fa" id="outgoingPatternByHour"></i>
                            <b-popover
                            target="outgoingPatternByHour"
                            title="Outgoing Pattern by Hour"
                            triggers="hover focus"
                            placement="top"
                            :content="infoPopup.outgoingPatternByHour"
                            ></b-popover>
                        </span>
                    </h6>
				</b-card-header>
				<div class="py-4 px-4">
					<ByHour :data="by_hour" type="Outgoing" v-if="by_hour"/>
				</div>
				</b-card>

			</div>
			<div class="col-md-12">

				<b-card no-body class="mb-4">
				<b-card-header header-tag="h6" class="with-elements border-0 pr-0 pb-0">
					<h6 class="card-header-title">Outgoing Vehicle by Date
                        <span class="col-md-1 col-sm-1 col-2">
                            <i class="fa fa-info-circle fa" id="outgoingVehicleByDate"></i>
                            <b-popover
                            target="outgoingVehicleByDate"
                            title="Outgoing Vehicle by Date"
                            triggers="hover focus"
                            placement="top"
                            :content="infoPopup.outgoingVehicleByDate"
                            ></b-popover>
                        </span>
                    </h6>
				</b-card-header>
				<div class="py-4 px-4">
					<ByDate :data="by_date" type="Outgoing" v-if="by_date"/>
				</div>
				</b-card>

			</div>
        </div>
    </div>
</template>

<script>
//components
import ByHour from './childComponents/ByHour'
import ByDay from './childComponents/ByDay'
import ByDate from './childComponents/ByDate'
// library
import Multiselect  from 'vue-multiselect'
import moment, { months } from 'moment'
export default {
    components: {
		Multiselect ,
		// components
		ByHour,
		ByDay,
		ByDate
    },
    data() {
        return {
            filterData: {
                regions: [],
                branch: [],
                segment: [],
                series: [],
                type: [],
                colour: [],
                month: [],
                year: []
            },
            filter: {
                region: [],
                branch: [],
                segment: [],
                series: [],
                type: [],
                colour: [],
                month: {
                    name: new moment().format("MMMM"),
                    value: new moment().format("M")
                },
				year:  new moment().format("YYYY"),
            },
			by_day: null,
			by_hour: null,
            by_date: null,
            infoPopup: this.$store.state.hoverInformation.h1.warehouseBehaviourOutgoing
        }
    },
    mounted () {
        this.loadFilter(this.filter, '')
        this.search()
    },
    watch: {
        'filter.region' : function() {
        let region = []
        for(var i = 0 ; i < this.filter.region.length ; i++){
            region.push(this.filter.region[i].loc_code)
        }
        this.loadFilter('region' , region)
        },
        'filter.segment' : function () {
        let segment = []
        for(var i = 0 ; i < this.filter.segment.length ; i++){
            segment.push(this.filter.segment[i].segment)
        }
        this.loadFilter('segment' , segment)
        },
        'filter.series' : function () {
        let series = []
        for(var i = 0 ; i < this.filter.series.length ; i++){
            series.push(this.filter.series[i].series)
        }
        this.loadFilter('series' , series)
        }
    },
    methods: {
        async search () {
            let filter = await this.transformFilter(this.filter)
            filter = await this.initializeFilter(filter)
            this.loadAPI(filter)
        },
        loadAPI (filter) {
			console.log(filter)
            let url = ['/qr-registration/outgoing-pattern/by-day', '/qr-registration/outgoing-pattern/by-hour' , '/qr-registration/outgoing-pattern/by-date']
      
			for(var i = 0 ; i < url.length ; i++) {
				let list = url[i].split('/')
				var regex = new RegExp('-', 'g');
				let variable = list[list.length - 1].replace(regex , '_')

				this[variable] = null

				this.$http.get('/api/h1' + url[i] + filter)
				.then((resp) => {
					this[variable] = resp.data.data
				})
				.catch((err) => {
				console.log(err)
				})
			}
        },
        async initializeFilter (filters) {
            let filterUrl = '?'
            let keys = Object.keys(filters)
            for (var i = 0; i < keys.length; i++) {
                if(typeof filters[keys[i]] == 'object' && filters[keys[i]].length > 0)
                    filterUrl += `${keys[i]}=${filters[keys[i]]}&`
                else if (typeof filters[keys[i]] != 'object') {
                    filterUrl += `${keys[i]}=${filters[keys[i]]}&`
                }
            }
            
            return filterUrl
        },
        async transformFilter (filter) {
            let obj = {}
			let keys = Object.keys(filter)
            for (var i = 0 ; i < keys.length; i++) {
                if (keys[i] != 'month' && keys[i] != 'year') {
                    obj[keys[i]] = []
                    for (var j = 0; j < filter[keys[i]].length; j++) {
                        keys[i] == 'region' || keys[i] == 'branch' ? obj[keys[i]].push(filter[keys[i]][j].loc_code) : obj[keys[i]].push(filter[keys[i]][j][keys[i].toLowerCase()])
                    }
                } else {
					if(obj.start) continue
					let date = new moment(new Date(filter.year , filter.month.value - 1 , 1))
					obj.start = date.startOf('month').format('YYYY-MM-DD');
					obj.end   = date.endOf('month').format('YYYY-MM-DD');
                }
            }
            return obj
        },
        loadFilter (type , data) {
            this.filterData = Object.assign({} , this.$store.state.filterData)
            if (type == 'region') {
                this.filterData.branch = Object.assign([] , this.$store.state.filterData.branch)
                this.filter.branch.length = 0
                if(this.filter.region.length == 0){
                return;
                } 
                let branch = []
                for(var i = 0 ; i < this.filterData.branch.length; i++){
                if(data.includes(this.filterData.branch[i].region_code))
                    branch.push(this.filterData.branch[i])
                }

                this.filterData.branch = branch
            }
            
            if (type == 'segment') {
                this.filter.series.length = 0
                this.filter.type.length = 0
                this.filterData.series = Object.assign([] , this.$store.state.filterData.series)
                this.filterData.type = Object.assign([] , this.$store.state.filterData.type)
                if(this.filter.segment.length == 0){
                
                return;
                } 
                let series = []
                let type = []
                for(var i = 0 ; i < this.filterData.series.length; i++){
                if(data.includes(this.filterData.series[i].segment))
                    series.push(this.filterData.series[i])
                }

                for(var i = 0 ; i < this.filterData.type.length; i++){
                if(data.includes(this.filterData.type[i].segment))
                    type.push(this.filterData.type[i])
                }

                this.filterData.series = series
                this.filterData.type = type
            }

            if (type == 'series') {
                this.filterData.type = Object.assign([] , this.$store.state.filterData.type)
                this.filter.type.length = 0
                if(this.filter.series.length == 0){
                return;
                } 
                let type = []
                for(var i = 0 ; i < this.filterData.type.length; i++){
                if(data.includes(this.filterData.type[i].series))
                    type.push(this.filterData.type[i])
                }
                this.filterData.type = type
            }
        },
    }
}
</script>