<template>
  <div ref='h1h2comparison' style='min-height:450px'></div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import material from '@amcharts/amcharts4/themes/material.js'
import animated from '@amcharts/amcharts4/themes/animated.js'

am4core.useTheme(material)
am4core.useTheme(animated)

export default {
  props:['filterUrl'],
  data () {
    return {
      chart: null,
      chart2: null,
      selectedSlice: null,
      pieSeries2: null,
      pieSeries: null,
      line1: null,
      line2: null
    }
  },
  mounted () {
    this.createChart()
  },
  watch : {
    'filterUrl' : function () {
      this.getData();
    }
  },
  methods: {
    getData () {
      let vm = this;
      var length = this.chart.data.length;
      var compiledData = [];
      var dataExist = false;

      this.$http.get('/api/h2/service-cycle/comparison?' + this.filterUrl)
      .then((resp)=>{
        var data = resp.data.data;
        var keys = Object.keys(data);
        for(var i = 0; i < keys.length; i++){
          if(data[keys[i]] > 0) dataExist = true;
          compiledData.push({
            'part' : keys[i],
            'count' : data[keys[i]]
          })
        }
        (dataExist) ? vm.chart.addData(compiledData, length) : vm.chart.addData([{'part' : 'No Data', 'count' : 1}], length)
      })
      .catch((err)=>{
        console.log(err)
      })
    },
    createChart () {
      let container = am4core.create(this.$refs.h1h2comparison, am4core.Container)
      container.width = am4core.percent(100)
      container.height = am4core.percent(100)
      container.layout = 'horizontal'

      this.chart = container.createChild(am4charts.PieChart)

      // Add and configure Series
      this.pieSeries = this.chart.series.push(new am4charts.PieSeries())
      this.pieSeries.dataFields.value = 'count'
      this.pieSeries.dataFields.category = 'part'
      this.pieSeries.slices.template.states.getKey('active').properties.shiftRadius = 0
      this.pieSeries.labels.template.text = "{category}: {value.percent.formatNumber('#.#')}% ({value} Units)"

      let selectSlice = this.selectSlice

      this.pieSeries.slices.template.events.on('hit', function (event) {
        selectSlice(event.target.dataItem)
      })

      this.chart.legend = new am4charts.Legend();
    },
    selectSlice (dataItem) {
      this.selectedSlice = dataItem.slice

      let fill = this.selectedSlice.fill

      let count = dataItem.dataContext.subData.length
      this.pieSeries2.colors.list = []
      for (var i = 0; i < count; i++) {
        this.pieSeries2.colors.list.push(fill.brighten(i * 2 / count))
      }

      this.chart2.data = dataItem.dataContext.subData
      this.pieSeries2.appear()

      let middleAngle = this.selectedSlice.middleAngle
      let firstAngle = this.pieSeries.slices.getIndex(0).startAngle
      let animation = this.pieSeries.animate([{ property: 'startAngle', to: firstAngle - middleAngle }, { property: 'endAngle', to: firstAngle - middleAngle + 360 }], 600, am4core.ease.sinOut)
      animation.events.on('animationprogress', this.updateLines)

      this.selectedSlice.events.on('transformed', this.updateLines)

    //  var animation = this.chart2.animate({property:'dx', from:-container.pixelWidth / 2, to:0}, 2000, am4core.ease.elasticOut)
    //  animation.events.on('animationprogress', this.updateLines)
    },
    updateLines () {
      if (this.selectedSlice) {
        let p11 = { x: this.selectedSlice.radius * am4core.math.cos(this.selectedSlice.startAngle), y: this.selectedSlice.radius * am4core.math.sin(this.selectedSlice.startAngle) }
        let p12 = { x: this.selectedSlice.radius * am4core.math.cos(this.selectedSlice.startAngle + this.selectedSlice.arc), y: this.selectedSlice.radius * am4core.math.sin(this.selectedSlice.startAngle + this.selectedSlice.arc) }

        p11 = am4core.utils.spritePointToSvg(p11, this.selectedSlice)
        p12 = am4core.utils.spritePointToSvg(p12, this.selectedSlice)

        let p21 = { x: 0, y: -this.pieSeries2.pixelRadius }
        let p22 = { x: 0, y: this.pieSeries2.pixelRadius }

        p21 = am4core.utils.spritePointToSvg(p21, this.pieSeries2)
        p22 = am4core.utils.spritePointToSvg(p22, this.pieSeries2)

        this.line1.x1 = p11.x
        this.line1.x2 = p21.x
        this.line1.y1 = p11.y
        this.line1.y2 = p21.y

        this.line2.x1 = p12.x
        this.line2.x2 = p22.x
        this.line2.y1 = p12.y
        this.line2.y2 = p22.y
      }
    }
  }
}
</script>
