<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th>Dealer</th>
          <th>Average Duration</th>
          <th>In Transit</th>
          <th>Arrived</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="!data">
          <td colspan="100%" class="text-center">Loading...</td>
        </template>
        <template v-else-if="data.length == 0">
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
        </template>
        <template v-else>
          <tr v-for="dat in ((showAll) ? data : data.slice(0, 5))">
            <td>{{ dat.dealer }} - {{ dat.name }}</td>
            <td>{{ dat.avg }} Day{{ (parseFloat(dat.avg) > 1) ? 's' : '' }}</td>
            <td>{{ dat.intransit }}</td>
            <td>{{ dat.arrived }}</td>
            <td>{{ dat.total }}</td>
          </tr>
          <tr v-if="showAllButton">
            <td v-if="!showAll" colspan="9" class="moreButton" @click="showAll = true">Lihat Semua</td>
            <td v-else colspan="9" class="moreButton" @click="showAll = false">Sembunyikan</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        showAllButton : false,
        showAll : true,
        data : [],
      }
    },
    props:['filter','period', 'data'],
    mounted () {

    },
    watch : {
      'period' : function () {
        // this.fetchData();
      },
      // 'filter' : {
      //   deep : true,
      //   immediate : true,
      //   handler : 'fetchData',
      // },
      'data' : {
        deep : true,
        immediate : true,
        handler : function (){
          if (!this.data) return;

          if (this.data.length <= 5) {
            this.showAllButton = false;
          } else if (this.data.length > 5){
            this.showAllButton = true;
            this.showAll = false;
          }
        }
      }
    },
    methods : {
      fetchData () {
        var data = []
        for (var i = 0; i < 10; i++) {
          var target = this.randomInt(0, 15000);
          var hit = this.randomInt(0, target);
          data.push({
            dealer : `${this.randomInt(10000, 99999)} - Sumber Kenari Jaya`,
            target : this.randomInt(1, 3),
            inTransit : this.randomInt(0, 50),
            arrived : this.randomInt(0, 60),
            averageDuration : (Math.random() * (3 - 1) + 1).toFixed(1)
          })
        }
        this.data = data;
      }
    }
  }
</script>

<style scoped>
  table tr th, table tr td {
    text-align: center;
    vertical-align: middle;
  }

  table tr th {
    border-bottom: 1px solid #F75047;
  }

</style>