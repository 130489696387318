<template>
  <div id="NewIdChartOverallProductionYear" style="width: 100%; height: 500px"></div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

export default {
  name: "NewIdChartOverallProductionYear",
  props: {
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
    selectedYearGlobal: {
      type: [String, Number],
      default: null,
    },
    chartData: {
      type: Array, // Accept chart data directly from the parent
      required: true,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    startDate() {
      this.updateChart();
    },
    endDate() {
      this.updateChart();
    },
    selectedYearGlobal() {
      this.updateChart();
    },
    chartData: {
      handler(newData) {
        this.updateChart(newData); // Update chart whenever chartData changes
      },
      immediate: true,
    },
  },
  mounted() {
    this.createChart(this.chartData); // Create the chart when the component is mounted
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose(); // Ensure the chart is destroyed when the component is destroyed
      this.chart = null; // Clear the reference to avoid issues when re-creating the chart
    }
  },
  methods: {
    createChart(data) {
      let chart = am4core.create("NewIdChartOverallProductionYear", am4charts.PieChart);
      chart.data = data;

      chart.innerRadius = am4core.percent(60);

      let pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "value";
      pieSeries.dataFields.category = "year_group";

      pieSeries.colors.list = [am4core.color("#f2bc80"), am4core.color("#4e80ed"), am4core.color("#55b586"), am4core.color("#c2b6f6")];

      pieSeries.labels.template.disabled = true;
      pieSeries.ticks.template.disabled = true;

      let total = data.reduce((sum, item) => sum + item.value, 0);

      // Find the highest percentage
      let highestPercentage = 0;
      let highestCategory = "";
      data.forEach((item) => {
        let percentage = (item.value / total) * 100;
        if (percentage > highestPercentage) {
          highestPercentage = percentage;
          highestCategory = item.year_group;
        }
      });

      // Create a label in the center of the chart
      let innerLabel = chart.seriesContainer.createChild(am4core.Label);
      innerLabel.text = `[bold]${highestCategory}[/]\n${highestPercentage.toFixed(2)}%`;
      innerLabel.horizontalCenter = "middle";
      innerLabel.verticalCenter = "middle";
      innerLabel.fontSize = 20;
      innerLabel.textAlign = "middle";

      // Add a legend
      chart.legend = new am4charts.Legend();
      chart.legend.position = "bottom";
      chart.legend.valueLabels.template.text = "{value.percent.formatNumber('#.0')}%";

      // Remove commas from year labels in the legend
      chart.legend.labels.template.adapter.add("text", function (text, target) {
        if (target.dataItem && target.dataItem.dataContext) {
          let yearGroup = target.dataItem.dataContext.year_group;
          return yearGroup ? yearGroup.toString().replace(/,/g, "") : text;
        }
        return text;
      });

      // Ensure 'year_group' is shown without commas in tooltips as well
      pieSeries.slices.template.tooltipText = "{category.formatNumber('#')}: {value} ({value.percent.formatNumber('#.0')}%)";

      chart.hiddenState.properties.opacity = 0;
      this.chart = chart;
    },
    updateChart(data = this.chartData) {
      if (this.chart) {
        this.chart.data = data;
        this.chart.invalidateData(); // Refresh chart with new data
      } else {
        this.createChart(data); // If the chart is not initialized, create it
      }
    },
  },
};
</script>

<style>
#NewIdChartOverallProductionYear {
  width: 100%;
  height: 500px;
}
</style>
