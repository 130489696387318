<script>
/* eslint-disable */
</script>
<template>
  <div>
    <b-card title="Daily QR Registration" :sub-title="infoPopup.title" header-tag="h5" class="mb-4">
      <hr>
      <b-form>
        <b-form-row>
          <b-form-group label="Region" class="col-md-3">
           <Multiselect 
            v-if="filterData"
            class="mt-1"
            :options="filterData.region"
            label="name"
            track-by="loc_code"
            :close-on-select="false"
            :multiple="true"
            v-model="filter.region"
            placeholder="All Regions"
           />
          </b-form-group>
          <b-form-group label="Branch" class="col-md-3">
           <Multiselect 
            v-if="filterData"
            :close-on-select="false"
            class="mt-1"
            :options="filterData.branch"
            label="name"
            track-by="loc_code"
            :multiple="true"
            v-model="filter.branch"
            placeholder="All Branches"
           />
          </b-form-group>
          <b-form-group label="Start Date" class="col-md-3 dateCss" style="height:42px" >
            <datepicker name="start" id="start" :format="customFormatter" :disabled-dates="datePickerConfig.disabledDates" bootstrap-styling v-model="filter.start"></datepicker>
          </b-form-group>
          <b-form-group label="End Date" class="col-md-3 dateCss" style="height:42px" >
            <datepicker name="end" id="end" :format="customFormatter" :disabled-dates="datePickerConfig.disabledDates" bootstrap-styling v-model="filter.end"></datepicker>
          </b-form-group>
        </b-form-row>
        <b-form-row>
          <b-form-group label="Segment" class="col-md-3">
           <Multiselect 
            class="mt-1"
            v-if="filterData"
            :options="filterData.segment"
            label="segment"
            :close-on-select="false"
            track-by="segment"
            :multiple="true"
            v-model="filter.segment"
            placeholder="All Segments"
           />
          </b-form-group>
          <b-form-group label="Series" class="col-md-3">
           <Multiselect 
            class="mt-1"
            v-if="filterData"
            :close-on-select="false"
            :options="filterData.series"
            label="series"
            track-by="series"
            :multiple="true"
            v-model="filter.series"
            placeholder="All Series"
           />
          </b-form-group>
          <b-form-group label="Type" class="col-md-3">
           <Multiselect 
            class="mt-1"
            v-if="filterData"
            :close-on-select="false"
            :options="filterData.type"
            label="type"
            track-by="type"
            :multiple="true"
            v-model="filter.type"
            placeholder="All Vehicle Types"
           />
          </b-form-group>
          <b-form-group label="Colour" class="col-md-3">
           <Multiselect 
            class="mt-1"
            v-if="filterData"
            :close-on-select="false"
            :options="filterData.color"
            v-model="filter.colour"
            label="name"
            track-by="color_code"
            :multiple="true"
            placeholder="All Colours"
           />
          </b-form-group>
        </b-form-row>
        <b-form-row>
          <b-form-group class="col-md-2">
            <b-btn type="button" variant="primary" @click="search(1)" style="width:100%">Filter</b-btn>
          </b-form-group>
          <b-form-group class="col-md-2">
            <b-btn type="button" variant="success" @click="downloadExcel" style="width:100%">Export XLSX</b-btn>
          </b-form-group>
        </b-form-row>
      </b-form>
    </b-card>
    <div class="row">
       <div class="col-md-8 col-lg-12 col-xl-9">

        <!-- Popular queries -->
        <b-card no-body class="mb-4">
            <div class="col-md-12 col-lg-12 col-xl-12 align-items-center p-4">
              <h5>Daily Registered
                <span class="col-md-1 col-sm-1 col-2">
                  <i class="fa fa-info-circle fa" id="dailyRegisteredChart"></i>
                  <b-popover
                  target="dailyRegisteredChart"
                  title="Daily Registered"
                  triggers="hover focus"
                  placement="top"
                  :content="infoPopup.dailyRegisteredChart"
                  ></b-popover>
                </span>
              </h5>
              <div class="d-flex row"><DailyRegistered class="w-100" v-if="qr_registered" :data="qr_registered.daily"></DailyRegistered></div>

            </div>
        </b-card>
        <!-- / Popular queries -->

      </div>
      <div class="col-md-4 col-lg-12 col-xl-3">

        <!-- Stats -->
        <div class="row">
          <div class="col-sm-6 col-md-12 col-lg-6 col-xl-12">

            <b-card no-body class="mb-4">
              <b-card-header class="border-0 pb-0">Total Registered
                <span class="col-md-1 col-sm-1 col-2">
                  <i class="fa fa-info-circle fa" id="totalRegistered"></i>
                  <b-popover
                  target="totalRegistered"
                  title="Total Registered"
                  triggers="hover focus"
                  placement="top"
                  :content="infoPopup.totalRegistered"
                  ></b-popover>
                </span>
              </b-card-header>
              <b-card-body class="text-center text-success text-xlarge py-3" v-if="qr_registered">{{numberWithCommas(qr_registered.total)}}</b-card-body>
            </b-card>

          </div>
          <div class="col-sm-6 col-md-12 col-lg-6 col-xl-12">

            <b-card no-body class="mb-4">
              <b-card-header class="border-0 pb-0">AVG Daily Registered
                <span class="col-md-1 col-sm-1 col-2">
                  <i class="fa fa-info-circle fa" id="avgDailyRegistered"></i>
                  <b-popover
                  target="avgDailyRegistered"
                  title="AVG Daily Registered"
                  triggers="hover focus"
                  placement="top"
                  :content="infoPopup.avgDailyRegistered"
                  ></b-popover>
                </span>
              </b-card-header>
              <b-card-body class="text-center text-danger text-xlarge py-3" v-if="qr_registered">{{numberWithCommas(qr_registered.average)}}</b-card-body>
            </b-card>

          </div>

        </div>
        <!-- / Stats -->

      </div>

      <div class="col-12 mb-2">
        <div style="background-color:black;color:white;padding:0.5%">
          <h3 style="margin:0">VEHICLE MAPPING SUMMARY
            <span class="col-md-1 col-sm-1 col-2">
              <i class="fa fa-info-circle fa" id="vehicleMappingSummary"></i>
              <b-popover
              target="vehicleMappingSummary"
              title="Vehicle Mapping Summary"
              triggers="hover focus"
              placement="top"
              :content="infoPopup.vehicleMappingSummary"
              ></b-popover>
            </span>
          </h3>
        </div>
      </div>
      <div class="col-12">

        <b-card no-body class="mb-4">
          <b-card-header header-tag="h6" class="with-elements border-0 pr-0 pb-0">
            <div class="card-header-title">Registered by Region
              <span class="col-md-1 col-sm-1 col-2">
                <i class="fa fa-info-circle fa" id="registeredByRegion"></i>
                <b-popover
                target="registeredByRegion"
                title="Registered by Region"
                triggers="hover focus"
                placement="top"
                :content="infoPopup.registeredByRegion"
                ></b-popover>
              </span>
            </div>
          </b-card-header>
          <div class="py-4 px-4">
            <RegisteredByRegion v-if="registered_by_location" :data="registered_by_location" />
          </div>
        </b-card>

      </div>
      <div class="col-12">

        <b-card no-body class="mb-4">
          <b-card-header header-tag="h6" class="with-elements border-0 pr-0 pb-0">
            <div class="card-header-title">Registered by Segment
              <span class="col-md-1 col-sm-1 col-2">
                <i class="fa fa-info-circle fa" id="registeredBySegment"></i>
                <b-popover
                target="registeredBySegment"
                title="Registered by Segment"
                triggers="hover focus"
                placement="top"
                :content="infoPopup.registeredBySegment"
                ></b-popover>
              </span>
            </div>
          </b-card-header>
          <div class="py-4 px-4">
            <RegisteredBySegment v-if="registered_by_variant" :data="registered_by_variant" />
          </div>
        </b-card>

      </div>
      <div class="col-md-6 col-12">

        <b-card no-body class="mb-4">
          <b-card-header header-tag="h6" class="with-elements border-0 pr-0 pb-0">
            <div class="card-header-title">Cub Motor Registration
              <span class="col-md-1 col-sm-1 col-2">
                <i class="fa fa-info-circle fa" id="registeredBySeriesCub"></i>
                <b-popover
                target="registeredBySeriesCub"
                title="Registered by Series Cub"
                triggers="hover focus"
                placement="top"
                :content="infoPopup.registeredBySeries + ' Cub'"
                ></b-popover>
              </span>
            </div>
          </b-card-header>
          <div class="py-4 px-4">
            <SegmentChart v-if="motor_bebek_honda" :data="motor_bebek_honda" :type="'Cub'" />
          </div>
        </b-card>

      </div>
      <div class="col-md-6 col-12">

        <b-card no-body class="mb-4">
          <b-card-header header-tag="h6" class="with-elements border-0 pr-0 pb-0">
            <div class="card-header-title">Matic Motor Registration
              <span class="col-md-1 col-sm-1 col-2">
                <i class="fa fa-info-circle fa" id="registeredBySeriesMatic"></i>
                <b-popover
                target="registeredBySeriesMatic"
                title="Registered by Series Matic"
                triggers="hover focus"
                placement="top"
                :content="infoPopup.registeredBySeries + ' Matic'"
                ></b-popover>
              </span>
            </div>
          </b-card-header>
          <div class="py-4 px-4">
            <SegmentChart v-if="motor_matik_honda" :data="motor_matik_honda" :type="'Matic'" />
          </div>
        </b-card>

      </div>
      <div class="col-md-6 col-12">

        <b-card no-body class="mb-4">
          <b-card-header header-tag="h6" class="with-elements border-0 pr-0 pb-0">
            <div class="card-header-title">Sport Motor Registration
              <span class="col-md-1 col-sm-1 col-2">
                <i class="fa fa-info-circle fa" id="registeredBySeriesSport"></i>
                <b-popover
                target="registeredBySeriesSport"
                title="Registered by Series Sport"
                triggers="hover focus"
                placement="top"
                :content="infoPopup.registeredBySeries + ' Sport'"
                ></b-popover>
              </span>
            </div>
          </b-card-header>
          <div class="py-4 px-4">
            <SegmentChart v-if="motor_sport_honda" :data="motor_sport_honda" :type="'Sport'" />
          </div>
        </b-card>

      </div>
      <div class="col-md-6 col-12">

        <b-card no-body class="mb-4">
          <b-card-header header-tag="h6" class="with-elements border-0 pr-0 pb-0">
            <div class="card-header-title">Big Bike Motor Registration
              <span class="col-md-1 col-sm-1 col-2">
                <i class="fa fa-info-circle fa" id="registeredBySeriesBigBike"></i>
                <b-popover
                target="registeredBySeriesBigBike"
                title="Registered by Series Big Bike"
                triggers="hover focus"
                placement="top"
                :content="infoPopup.registeredBySeries + ' Big Bike'"
                ></b-popover>
              </span>
            </div>
          </b-card-header>
          <div class="py-4 px-4">
            <SegmentChart v-if="big_bike" :data="big_bike" :type="'Big Bike'" />
          </div>
        </b-card>

      </div>
      
    </div>
  </div>
</template>

<script>
// import flatPickr from 'vue-flatpickr-component'
import Datepicker from 'vuejs-datepicker';
import Multiselect  from 'vue-multiselect'

import DailyRegistered from './childComponents/DailyRegistered'
import RegisteredByRegion from './childComponents/RegisteredByRegion'
import RegisteredBySegment from './childComponents/RegisteredBySegment'
import SegmentChart from './childComponents/SegmentChart'

import * as am4core from '@amcharts/amcharts4/core'
import moment from 'moment'



export default {
  name: 'qr-registered',
  metaInfo: {
    title: 'QR Registered'
  },
  components: {
    // library
    // flatPickr,
    Multiselect ,
    Datepicker,
    //charts / components
    DailyRegistered,
    RegisteredByRegion,
    RegisteredBySegment,
    SegmentChart
  },
  data () {
    // const startOfMonth = moment().startOf('month').toDate()
    // const endOfMonth = moment().toDate()

    const startDate = moment().subtract(8, 'days').format("YYYY-MM-DD")
    const endDate = moment().subtract(1, 'days').format("YYYY-MM-DD")

    var currDate = new Date();
    return {
      datePickerConfig : {
        disabledDates: {
          from: moment().subtract(1, 'days').toDate()
        }
      },

      //filter data
      filterData: null,
      filter: {
        start: startDate,
        end: endDate,
        region: [],
        branch: [],
        segment: [],
        series: [],
        type: [],
        colour: []
      },
      // master data variables
      // api variables
      qr_registered: null,
      registered_by_location: null,
      registered_by_variant: null,
      motor_bebek_honda: null,
      motor_matik_honda: null,
      motor_sport_honda: null,
      big_bike: null,
      infoPopup: this.$store.state.hoverInformation.h1.qrRegistration.dailyQrRegistration
    }
  },
  mounted () {
    this.search()
    this.loadFilter()
  },
  watch: {
    'filter.region' : function() {
      this.loadFilter('region')
    },
    'filter.segment' : function () {
      this.loadFilter('segment')
    },
    'filter.series' : function () {
      this.loadFilter('series')
    }
  },
  beforeDestroy () {
    this.$store.state.filterUrl = ''
    am4core.disposeAllCharts()
  },
  methods: {
    customFormatter : function(date){
			return moment(date).format('Y-MM-DD');
		},
    numberWithCommas(x) {
      return parseInt(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    // Filter Settings Start
    async initializeFilter (filters) {
      let filterUrl = '?'
      let keys = Object.keys(filters)
      for (var i = 0; i < keys.length; i++) {
        if(typeof filters[keys[i]] == 'object'  && filters[keys[i]].length > 0)
          filterUrl += `${keys[i]}=${filters[keys[i]]}&`
        else if( filters[keys[i]] != null && filters[keys[i]] != ''){
          filterUrl += `${keys[i]}=${filters[keys[i]]}&`
        }
      }
      
      return filterUrl
    },
    async transformFilter (filter) {
      let obj = {}
      let keys = Object.keys(filter)
      for (var i = 0 ; i < keys.length; i++) {
        if (keys[i] != 'start' && keys[i] != 'end') {
          obj[keys[i]] = []
          for (var j = 0; j < filter[keys[i]].length; j++) {
            keys[i] == 'region' || keys[i] == 'branch' ? obj[keys[i]].push(filter[keys[i]][j].loc_code) : obj[keys[i]].push(filter[keys[i]][j][keys[i].toLowerCase()])
          }
        }
        else  {
          obj[keys[i]] = moment(filter[keys[i]]).format("YYYY-MM-DD")
        }
      }
      return obj
    },
    loadFilter (type) {
      if (type == 'region'){
        this.filter.branch.length = 0;
        this.filterData.branch = this.$store.getters.getBranch(this.filter.region)
      } else if (type == 'segment'){
        this.filter.series.length = 0;
        this.filterData.series = this.$store.getters.getSeries(this.filter.segment)

        this.filter.type.length = 0;
        this.filterData.type = this.$store.getters.getType(this.filter.segment, this.filter.series)
      } else if (type == 'series'){
        this.filter.type.length = 0;
        this.filterData.type = this.$store.getters.getType(this.filter.segment, this.filter.series)
      } else {
        this.filterData = this.$store.getters.getFilterOptions
      } 
    },
    // function to load All API
    loadData (filter) {

      //Old URL
      // let url = ['/qr-registration/qr-registered' , '/qr-registration/vehicle-mapping-summary/registered-by-location', '/qr-registration/vehicle-mapping-summary/registered-by-variant', '/qr-registration/vehicle-mapping-summary/motor-bebek-honda', '/qr-registration/vehicle-mapping-summary/motor-matik-honda', '/qr-registration/vehicle-mapping-summary/motor-sport-honda', '/qr-registration/vehicle-mapping-summary/big-bike']

      //New URL
      let url = ['/gi-qr-registration/qr-registered' , '/gi-qr-registration/vehicle-mapping-summary/registered-by-location', '/gi-qr-registration/vehicle-mapping-summary/registered-by-variant', '/gi-qr-registration/vehicle-mapping-summary/motor-bebek-honda', '/gi-qr-registration/vehicle-mapping-summary/motor-matik-honda', '/gi-qr-registration/vehicle-mapping-summary/motor-sport-honda', '/gi-qr-registration/vehicle-mapping-summary/big-bike']
      
      let dateDiff = moment(this.filter.end).diff(moment(this.filter.start), 'days')+1;
      if (dateDiff <= 0) {
        alert('Start date must be earlier than end date!');
        return;
      } else if (dateDiff > 31) {
        alert('Date filter cannot exceed 31 days!');
        return;
      }
      console.log(dateDiff);

      for(var i = 0 ; i < url.length ; i++) {
        let list = url[i].split('/')
        var regex = new RegExp('-', 'g');
        let variable = list[list.length - 1].replace(regex , '_')

        this[variable] = null

        this.$http.get('/api/h1' + url[i] + filter)
        .then((resp) => {
          this[variable] = resp.data.data
        })
        .catch((err) => {
          console.log(err)
        })
      }
    },
    async search (flag) {
      let filters = await this.transformFilter(this.filter)
      filters = await this.initializeFilter(filters)
      this.loadData(filters)
    },
    async downloadExcel () {
      let filter = await this.transformFilter(this.filter)
      this.downloadQrRegistration(filter, 'Daily QR Registration', false);
    },
  }
}
</script>

<style>
  .dateCss div input{
    border: 1px solid #e8e8e8;
    border-radius: 5px !important;
    height: 42px;
    margin-top: 3px;
    padding-left: 1% !important;
  }

  .dateCss div input::placeholder{
    padding-left: 1%;
  }

</style>
