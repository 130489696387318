<template>
    <div ref='byVehicleSegment' style='height:425px'></div>
  </template>
  
  <script>
    /* Imports */
    import * as am4core from "@amcharts/amcharts4/core";
    import * as am4charts from "@amcharts/amcharts4/charts";
  
    export default {
      data () {
        return {
          chart : null,
        }
      },
      props : ['filterUrl'],
      mounted () {
        this.createChart();
        this.fetchData();
      },
      watch : {
        'filterUrl' : function () {
          this.fetchData();
        }
      },
      methods : {
        fetchData(){
          let vm = this
          var length = vm.chart.data.length
  
          this.$http.get('/api/h2/change-ownership/by-vehicle-segment?' + this.filterUrl)
          .then((resp)=>{
            var data = resp.data.data;
            vm.chart.addData((data != null && data.length != 0) ? data : {segment : 'No Data', count : 0} , length)
          })
          .catch((err)=>{
            console.log(err);
          })
        },
        createChart(){
        this.chart = am4core.create(this.$refs.byVehicleSegment, am4charts.XYChart);

        // Create axes

        let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "segment";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;

        categoryAxis.renderer.labels.template.adapter.add("dy", function(dy, target) {
          if (target.dataItem && target.dataItem.index & 2 == 2) {
            return dy + 25;
          }
          return dy;
        });

        let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());

        // Create series
        let series = this.chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "count";
        series.dataFields.categoryX = "segment";
        series.name = "Total";
        series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
        series.columns.template.fillOpacity = .8;

        var labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = "[bold]{valueY}";
        labelBullet.label.dy = -10;

        let columnTemplate = series.columns.template;
        columnTemplate.strokeWidth = 2;
        columnTemplate.strokeOpacity = 1;
        }
      }
    }
  </script>