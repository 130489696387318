<template>
	<div id="replacementSegmentLineChart" style="width: 100%; height: 500px"></div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

export default {
	name: "AmChart",
	props: {
		startDate: {
			type: String,
			required: true
		},
		endDate: {
			type: String,
			required: true
		},
		selectedSegments: {
			type: Array,
			default: () => []
		},
	},
	data() {
		return {
			chart: null,
			colors: ["#2fbf56", "#ff6b6b", "#4ecdc4", "#45b7d1", "#f7a072", "#588da8"]
		};
	},
	mounted() {
		this.createChart();
	},
	methods: {
		createChart() {
			am4core.useTheme(am4themes_animated);

			this.chart = am4core.create("replacementSegmentLineChart", am4charts.XYChart);

			let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
			dateAxis.renderer.minGridDistance = 50;
			dateAxis.dateFormats.setKey("month", "MMM");
			dateAxis.periodChangeDateFormats.setKey("month", "MMM");

			let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());

			this.chart.cursor = new am4charts.XYCursor();
			this.chart.cursor.xAxis = dateAxis;

			this.chart.legend = new am4charts.Legend();
			this.chart.legend.position = "bottom";
			this.chart.legend.scrollable = true;

			this.noDataLabel = this.chart.tooltipContainer.createChild(am4core.Label);
			this.noDataLabel.text = "No data available";
			this.noDataLabel.fontSize = 20;
			this.noDataLabel.align = "center";
			this.noDataLabel.valign = "middle";
			this.noDataLabel.dy = -20;
			this.noDataLabel.horizontalCenter = "middle";
			this.noDataLabel.verticalCenter = "middle";
			this.noDataLabel.hide();
		},
		updateChartData(newData) {
			if (!this.chart) return;

			// Clear existing series
			this.chart.series.clear();

			if (!newData || Object.keys(newData).length === 0) {
				this.noDataLabel.show();
				return;
			}

			this.noDataLabel.hide();

			const processedData = {};

			Object.entries(newData).forEach(([month, segments]) => {
				segments.forEach(segment => {
					if (!processedData[segment.segment]) {
						processedData[segment.segment] = [];
					}
					processedData[segment.segment].push({
						date: new Date(month),
						value: segment.value
					});
				});
			});

			Object.entries(processedData).forEach(([segment, data], index) => {
				let series = this.chart.series.push(new am4charts.LineSeries());
				series.dataFields.valueY = "value";
				series.dataFields.dateX = "date";
				series.strokeWidth = 2;
				series.minBulletDistance = 10;
				series.tooltipText = "{name}: [bold]{value}[/]";
				series.tooltip.pointerOrientation = "vertical";
				series.name = segment;
				series.stroke = am4core.color(this.colors[index % this.colors.length]);
				series.fill = series.stroke;
				series.data = data;
			});

			this.chart.invalidateData();
		},
	},
	beforeDestroy() {
		if (this.chart) {
			this.chart.dispose();
		}
	},
};
</script>