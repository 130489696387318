<template>
  <table class='table table-bordered'>
    <tr>
      <th>Month</th>
      <th>Year</th>
      <th>Min</th>
      <th>Max</th>
      <th>Avg</th>
    </tr>
    <tr v-for='dailyData in data.slice().reverse()' :key="dailyData.bulan">
      <th >{{ dailyData.bulan }}</th>
      <th >{{ year }}</th>
      <template>
          <td>{{dailyData.minimumstring}}</td>
          <td>{{dailyData.maximumstring}}</td>
          <td>{{dailyData.averagestring}}</td>
      </template>
    </tr>
  </table>
</template>

<script>
export default {
  props: ['data' , 'year']
}
</script>

<style scoped>
  th, td{
    text-align: center;
  }
</style>
