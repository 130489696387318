<script>/* eslint-disable */</script>
<template>
  <div>
    <!--Filter Option-->
    <b-card title="Stock Availability" :sub-title="infoPopup.title" header-tag="h5" class="mb-4">
      <hr>
      <b-form>
        <b-form-row>
          <b-form-group label="Region" class="col-md-6">
           <Multiselect 
            :close-on-select="false"
            v-if="filterData"
            class="mt-1"
            :options="filterData.region"
            label="name"
            track-by="loc_code"
            :multiple="true"
            v-model="filter.region"
            placeholder="All Regions"
           />
          </b-form-group>
          <b-form-group label="Branch" class="col-md-6">
           <Multiselect 
            :close-on-select="false"
            v-if="filterData"
            class="mt-1"
            :options="filterData.branch"
            label="name"
            track-by="loc_code"
            :multiple="true"
            v-model="filter.branch"
            placeholder="All Branches"
           />
          </b-form-group>
        </b-form-row>
        <b-form-row>
          <b-form-group label="Segment" class="col-md-3">
           <Multiselect 
            :close-on-select="false"
            class="mt-1"
            v-if="filterData"
            :options="filterData.segment"
            label="segment"
            track-by="segment"
            :multiple="true"
            v-model="filter.segment"
            placeholder="All Segments"
           />
          </b-form-group>
          <b-form-group label="Series" class="col-md-3">
           <Multiselect 
            :close-on-select="false"
            class="mt-1"
            v-if="filterData"
            :options="filterData.series"
            label="series"
            track-by="series"
            :multiple="true"
            v-model="filter.series"
            placeholder="All Series"
           />
          </b-form-group>
          <b-form-group label="Type" class="col-md-3">
           <Multiselect 
            :close-on-select="false"
            class="mt-1"
            v-if="filterData"
            :options="filterData.type"
            label="type"
            track-by="type"
            :multiple="true"
            v-model="filter.type"
            placeholder="All Vehicle Types"
           />
          </b-form-group>
          <b-form-group label="Colour" class="col-md-3">
           <Multiselect 
            :close-on-select="false"
            class="mt-1"
            v-if="filterData"
            :options="filterData.color"
            v-model="filter.colour"
            label="name"
            track-by="color_code"
            :multiple="true"
            placeholder="All Colours"
           />
          </b-form-group>
        </b-form-row>
        <b-form-row>
          <b-form-group class="col-md-1">
            <b-btn type="button" variant="primary" @click="search(1)">Filter</b-btn>
          </b-form-group>
        </b-form-row>
      </b-form>
    </b-card>
    <!--Stats-->
    <div class="row">
       <div class="col-md-8 col-lg-12 col-xl-9">

        <!-- Popular queries -->
        <b-card no-body>
            <div class="col-md-12 col-lg-12 col-xl-12 align-items-center p-4">
              <h6>Stock by Location
                <span class="col-md-1 col-sm-1 col-2">
                    <i class="fa fa-info-circle fa" id="stockByLocation"></i>
                    <b-popover
                    target="stockByLocation"
                    title="Stock By Location"
                    triggers="hover focus"
                    placement="top"
                    :content="infoPopup.stockByLocation"
                    ></b-popover>
                </span>
              </h6>
              <div class="d-flex row">
                <StockByLocation style="height:450px" v-if="stockLocation" :data="stockLocation.location"></StockByLocation>
              </div>
            </div>
        </b-card>
        <!-- / Popular queries -->

      </div>
      <div class="col-md-4 col-lg-12 col-xl-3">

        <!-- Stats -->
        <div class="row" style="height:100%">
          <div class="col-sm-6 col-md-12 col-lg-6 col-xl-12" style="height:30%">
            <!-- <b-card no-body class="mb-4">
              <b-card-header class="border-0 pb-0">Current Ending Stock</b-card-header>
              <b-card-body class="text-center text-success text-xlarge py-3">3,235</b-card-body>
            </b-card> -->
            <div class="stats-inner-box" style="background-color:#49B6D6;height:100%; min-height:100px">
              <div class="row" style="height: 100%">
                <div class="col-8">
                  <p>Current Ending Stock
                    <span class="col-md-1 col-sm-1 col-2">
                        <i class="fa fa-info-circle fa" id="currentEndingStock"></i>
                        <b-popover
                        target="currentEndingStock"
                        title="Current Ending Stock"
                        triggers="hover focus"
                        placement="top"
                        :content="infoPopup.currentEndingStock"
                        ></b-popover>
                    </span>
                  </p>
                  <p class="stats-value" v-if="stockLocation">
                    {{numberWithCommas(stockLocation.current_stock)}}
                  </p>
                  <p class="stats-value" v-else>Loading....</p>
                </div>
                <div class="col-4 icon">
                  <i class="fas fa-warehouse"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-12 col-lg-6 col-xl-12" style="height:30%">
            <!-- <b-card no-body class="mb-4">
              <b-card-header class="border-0 pb-0">Most Series In Stock</b-card-header>
              <b-card-body class="text-center text-primary text-xlarge py-3">Beat</b-card-body>
            </b-card> -->
            <div class="stats-inner-box" style="background-color:#229944;height:100%; min-height:100px">
              <div class="row" style="height: 100%">
                <div class="col-8">
                  <p>Most Series In Stock
                    <span class="col-md-1 col-sm-1 col-2">
                        <i class="fa fa-info-circle fa" id="mostSeriesInStock"></i>
                        <b-popover
                        target="mostSeriesInStock"
                        title="Most Series In Stock"
                        triggers="hover focus"
                        placement="top"
                        :content="infoPopup.mostSeriesInStock"
                        ></b-popover>
                    </span>
                  </p>
                  <p class="stats-value" v-if="stockLocation && stockLocation.most_series && stockLocation.most_series.length > 0">
                    {{stockLocation.most_series[0].series}}
                  </p>
                  <p class="stats-value" v-else>Loading....</p>
                </div>
                <div class="col-4 icon">
                  <i class="fas fa-motorcycle"></i>
                </div>
              </div>
            </div>

          </div>
        </div>
        <!-- / Stats -->
      </div>
      <!--Location and Type-->
      <!--Average Days of Stock By Series-->
      <div class='col-md-12 col-lg-12 col-xl-12 mt-4' v-if="segmentSeries">
        <b-card no-body>
            <div class='col-md-12 col-lg-12 col-xl-12 align-items-center p-4' >
              <h5>Stock Availability By Segment and Series
                <span class="col-md-1 col-sm-1 col-2">
                    <i class="fa fa-info-circle fa" id="stockAvailibilityBySegmentAndSeries"></i>
                    <b-popover
                    target="stockAvailibilityBySegmentAndSeries"
                    title="Stock Availability By Segment and Series"
                    triggers="hover focus"
                    placement="top"
                    :content="infoPopup.stockAvailibilityBySegmentAndSeries"
                    ></b-popover>
                </span>
              </h5>
              <div class='d-flex row'><BySegment @getSeries="getSeries" :data="segmentSeries" class='w-100' /></div>
            </div>
        </b-card>
      </div>
      <!--Average Days of Stock By Type-->
      <div class='col-md-12 col-lg-12 col-xl-12 mt-4' v-if="typesColours">
        <b-card no-body>
            <div class='col-md-12 col-lg-12 col-xl-12 align-items-center p-4'>
              <h5>Stock Availability By Types and Colours
                <span class="col-md-1 col-sm-1 col-2">
                    <i class="fa fa-info-circle fa" id="stockAvailabilityByTypesAndColour"></i>
                    <b-popover
                    target="stockAvailabilityByTypesAndColour"
                    title="Stock Availability By Types and Colours"
                    triggers="hover focus"
                    placement="top"
                    :content="infoPopup.stockAvailabilityByTypesAndColour"
                    ></b-popover>
                </span>
              </h5>
              <div class='d-flex row'><ByTypes class='w-100' :data="typesColours"/></div>
            </div>
        </b-card>
      </div>
      <!--end-->
    </div>
  </div>
</template>

<script>

//  charts
import ByTypes from './childComponents/ByTypes'
import BySegment from './childComponents/BySegment'
import StockByLocation from './childComponents/StockByLocation'
import Multiselect  from 'vue-multiselect'

export default {
  name: 'stock-availability',
  metaInfo: {
    title: 'Stock Availability'
  },
  components: {
    // dropdown
    Multiselect ,
    //  charts
    ByTypes,
    BySegment,
    StockByLocation
  },

  data() {
    return {
      filter: {
        region: [],
        branch: [],
        segment: [],
        series: [],
        type: [],
        colour: []
      },
      rangeConfig: {
        mode: 'range',
        altInput: true,
        animate: !isRTL()
      },
      // variable for filterData
      filterData: this.$store.getters.getFilterOptions,
      // variable for charts and stats
      stockLocation: null,
      segmentSeries: null,
      typesColours: null,
      selectSeries: 0,
      infoPopup: this.$store.state.hoverInformation.h1.stockDetails.stockAvailability
    }
  },
  mounted () {
    this.search()
  },
  watch: {
    'filter.region' : function() {
      this.loadFilter('region')
    },
    'filter.segment' : function () {
      this.loadFilter('segment')
    },
    'filter.series' : function () {
      this.loadFilter('series')
    }
  },
  methods: {
    numberWithCommas(x) {
      return parseInt(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async search(flag) {
      let filters = await this.transformFilter(this.filter)
      this.$store.state.filterUrl = await this.initializeFilter(filters)
      console.log(this.$store.state.filterUrl)
      if (this.filter.series.length > 0 && flag == 1) {
        this.segmentSeries = null
        this.loadTypesColours()
        this.loadStockLocation()
      }
      else {
        
        if(this.filter.series.length > 0)
          this.loadTypesColours()
        else{
          this.typesColours = null
          this.loadVehicleTypes()
          this.loadStockLocation()
        }
      }
    },
    // Filter Settings Start
    async initializeFilter (filters) {
      let filterUrl = '?'
      let keys = Object.keys(filters)
      for (var i = 0; i < keys.length; i++) {
        if(keys[i] != 'range' && filters[keys[i]].length > 0)
          filterUrl += `${keys[i]}=${filters[keys[i]]}&`
        else if (keys[i] == 'range') {
          let splitDateRange = filters.range.split(' to ');
          filterUrl += 'start=' + splitDateRange[0] + '&'
          filterUrl += 'end=' + splitDateRange[1] + '&'
        }
      }
      
      return filterUrl
    },
    async transformFilter (filter) {
      let obj = {}
      let keys = Object.keys(filter)
      for (var i = 0 ; i < keys.length; i++) {
        if (keys[i] != 'range') {
          obj[keys[i]] = []
          for (var j = 0; j < filter[keys[i]].length; j++) {
            keys[i] == 'region' || keys[i] == 'branch' ? obj[keys[i]].push(filter[keys[i]][j].loc_code) : obj[keys[i]].push(filter[keys[i]][j][keys[i].toLowerCase()])
          }
        }
        else if (keys[i] == 'range') {
          obj[keys[i]] = filter.range
        }
      }
      return obj
    },
    loadFilter(type){
      if (type == 'region'){
        this.filter.branch.length = 0;
        this.filterData.branch = this.$store.getters.getBranch(this.filter.region)
      } else if (type == 'segment'){
        if(this.selectSeries != 1){
          this.filter.series.length = 0;
          this.selectSeries = 0
        }
        this.filterData.series = this.$store.getters.getSeries(this.filter.segment)

        this.filter.type.length = 0;
        this.filterData.type = this.$store.getters.getType(this.filter.segment, this.filter.series)
      } else if (type == 'series'){
        this.filter.type.length = 0;
        this.filterData.type = this.$store.getters.getType(this.filter.segment, this.filter.series)
      } else {
        this.filterData = this.$store.getters.getfilterData
      } 
    },
    getSeries (data, segment) {
      let series = data
      this.selectSeries = 1
      this.filter.segment.length = 0
      this.filter.segment.push({
        segment: segment
      })

      this.filter.series.length = 0
      this.filter.series.push({
        series: series
      })
      
      this.search(0)
    },
    loadTypesColours() {
      this.typesColours = null
      let url = '/api/h1/stock-availability/by-types-colours' + this.$store.state.filterUrl

      this.$http.get(url)
      .then((resp) => {
        this.typesColours = resp.data.data
      })
      .then(()=>{
        window.scrollTo(0, document.body.scrollHeight);
      })
      .catch((err) => {
        console.log(err)
      })
    },
    loadStockLocation () {
      let url = "/api/h1/stock-availability" + this.$store.state.filterUrl
      this.stockLocation = null

      this.$http.get(url)
      .then((resp) => {
        this.stockLocation = resp.data.data

      })
      .catch((err) => {
        console.log(err)
      })
    },
    loadVehicleTypes () {
      let url = "/api/h1/stock-availability/by-segment-series"
      this.segmentSeries = null

      this.$http.get(url)
      .then((resp) => {
        this.segmentSeries = resp.data.data
      })
      .catch((err) => {
        console.log(err)
      })
    }
  }
}

function isRTL () {
  return document.documentElement.getAttribute('dir') === 'rtl' || document.body.getAttribute('dir') === 'rtl'
}
</script>
