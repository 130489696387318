<script> /* eslint-disable */ </script>
<template>
	<div>
		<b-card title="API Log Reports" :sub-title="infoPopup.title" header-tag="h6" class="mb-4">
			<hr>
            <b-form>
                <b-form-row>
                    <b-form-group label="Process Type" class="col-md-4">
                        <Multiselect 
                            class="mt-1"
                            :options="filterData.type"
                            :multiple="true"
                            v-model="filter.type"
                            placeholder="All Process Type"
                        />
                    </b-form-group>
                    <b-form-group label="API Status" class="col-md-4">
                        <Multiselect 
                            v-if="filterData.status"
                            class="mt-1"
                            :options="filterData.status"
                            v-model="filter.status"
                            placeholder="All Status"
                        />
                    </b-form-group>
                    <b-form-group label="Date" class="col-md-4 dateCss" style="height:42px" >
                        <flat-pickr v-model="filter.range" :config="rangeConfig" :placeholder="!isIEMode ? 'Range picker' : null"/>
                    </b-form-group>
					
                </b-form-row>
				<b-form-row>
					<b-form-group label="RQUUID / Log ID" class="col-md-12 dateCss" style="height:42px" >
						<div class="row">
							<div style="padding-right:0" class="col-2">
								<b-form-select 
									v-model="selectSearch"
									:options="[{value : 'rquuid' , text: 'RQUUID'} , {value : 'log_id' , text : 'Log ID'}]"
								/>
							</div>
							<div style="padding-left:0" class="col-10">
								<b-form-input v-model="filter[selectSearch]" />
							</div>
						</div>

                        
                    </b-form-group>
				</b-form-row>
                <b-btn class="col-md-4 mt-4" type="button" variant="primary" @click="search(false)" :disabled="isFetching"><span class="fa fa-filter"></span>&nbsp;&nbsp;Search</b-btn>
            </b-form>
        </b-card>

        <div class="row mt-5 mb-5" v-if="isFetching">
            <div class="col">
                <h1 class="text-center" style="
                color: #616161;
                text-shadow: 4px 4px 6px #BDBDBD;">Loading...</h1>
            </div>
        </div>

		<b-card style="overflow-x: auto;" v-if="!isFetching">
			<!-- <div class="row">
                <div class="col">
                    <b-form-group>
                        Per Page: &nbsp;
                        <b-select size="md" v-model="filter.limit" :options="[15,50,100]" class="d-inline-block w-auto" />
                    </b-form-group>
                </div>
            </div> -->
			<table class="table tableBodyScroll">
				<thead class="text-center">
					<th>Log ID / RQUUID</th>
					<th>Process Type</th>
					<th>Endpoint</th>
					<th>Status</th>
					<th>Time</th>
					<th><i class="fas fa-cog"></i></th>
				</thead>
				<tbody>
					<tr v-for="(data, index) in tableData" :key="data.log_id" class="text-center">
						<td>{{data.rquuid ? data.rquuid : data.log_id}}</td>
						<td>{{data.process_type}}</td>
						<td>{{data.endpoint}}</td>
						<td>{{data.status}}</td>
						<td>{{new moment(data.created_at).format("YYYY-MM-DD HH:mm:ss")}}</td>
						<td><button class="btn btn-outline-primary " type="button" @click="showDetail(data)"><i class="fas fa-info-circle fa-lg"></i></button></td>
					</tr>
					<tr v-if="meta.current_page < meta.last_page || meta.last_page == -1">
						<td colspan="100%" v-observe-visibility="loadingSeen">
							<h4 style="text-align: center; width: 100%; margin: 0">
								Loading...
							</h4>
						</td>
					</tr>
				</tbody>
			</table>
			<!-- <div class="row mt-2" v-if="meta">
                <div class="col-sm text-sm-left text-center mb-3 mb-sm-0">
                    <span class="text-muted">Showing {{((filter.page - 1) * filter.limit) + 1}} to {{filter.page * filter.limit < meta.total ? filter.page * filter.limit : meta.total}} of {{meta.total}} entries</span>
                </div>
                <div class="col-sm">
                    <b-pagination size="md" class="justify-content-center justify-content-sm-end" :total-rows="meta.total" v-model="filter.page" :per-page="15" />
                </div>
            </div> -->
		</b-card>
		
	</div>
</template>

<script>
import moment from 'moment'
import Multiselect  from 'vue-multiselect'
import flatPickr from 'vue-flatpickr-component'

import { ObserveVisibility } from "vue-observe-visibility";

function isRTL () {
  return document.documentElement.getAttribute('dir') === 'rtl' || document.body.getAttribute('dir') === 'rtl'
}

export default {
	metaInfo: {
        title: 'API Log Report'
    },
	components: {
		flatPickr,
		Multiselect 
	},
	data () {
		return {
			isFetching : false,

			infoPopup : this.$store.state.hoverInformation.report.apiLogReports,
			filter: {
				page: 1,
				limit: 15,
				status: [],
				type: [],
				range: `${moment().format("YYYY-MM-DD")} to ${moment().format("YYYY-MM-DD")}`,
				rquuid: '',
				log_id: ''
			},
			rangeConfig: {
                mode: 'range',
                altInput: true,
                animate: !isRTL()
			},
			filterData: {
				type: []
			},
			tableData: [],
			meta: {
					total : 0,
					current_page : 0,
					last_page : 0
			},
			moment: moment,
			fetching : false,
			logInfo: null,
			selectSearch: 'rquuid'
		}
	},
	mounted () {
		this.loadProcessTypes()
		this.search(false)
	},
	watch: {
        'filter.page': function () {
            this.search(true)
        },
        'filter.limit': function () {
            this.filter.page = 1;
            this.search()
		},
		'filter.type' : function () {
			this.filter.page = 1;
		},
		'selectSearch' : function () {
			this.filter.log_id = ''
			this.filter.rquuid = ''
		}
    },
    directives: {
      ObserveVisibility
    },
	methods: {
		loadProcessTypes () {
			let url = '/api/master/process-type'

			this.$http.get(url)
			.then((resp)=> {
				let data = resp.data.data
				for (var i = 0; i < data.length; i++) {
					this.filterData.type.push(data[i].process_type)
				}
			})
			.catch((err) => {
				console.log(err)
			})

			let url2 = '/api/master/logs-status'
			this.filterData.status = []

			this.$http.get(url2)
			.then((resp)=> {
				let data = resp.data.data
				for (var i = 0; i < data.length; i++) {
					this.filterData.status.push(data[i].status_code)
				}
			})
			.catch((err) => {
				console.log(err)
			})
		},
		initialiseFilter (filter) {
			let filterString = '?'
			let keys = Object.keys(filter)

			for (var i = 0; i < keys.length; i++) {
				if(filter[keys[i]] == null || filter[keys[i]] == '' || (Array.isArray(filter[keys[i]]) && filter[keys[i]].length <= 0))
					continue;
				
				if (keys[i] == 'range') {
					let date = filter[keys[i]].split(' to ')
					if (date.length == 2) {
						filterString += 'start=' + date[0] + '&'
						filterString += `end=${date[1]}&`
					} else if (date.length == 1) {
						filterString += 'start=' + date[0] + '&'
						filterString += `end=${date[0]}&`
					}
				}
				else {
					filterString += `${keys[i]}=${filter[keys[i]]}&`
				}
			}
			
			return filterString
		},
		async search (inf) {
			this.isFetching = true;
			if(!inf) {this.tableData.length = 0; this.filter.page = 1}
			let filter = await this.initialiseFilter(this.filter)
			this.loadAPI(filter)
		},
		loadAPI (filter) {
			this.fetching = true
			let url = "/api/reports/log-api" + filter

			this.$http.get(url)
			.then((resp) => {
				let data = resp.data.data
				let meta = resp.data.meta
				for (var i = 0; i < data.length; i++) {
					let json = JSON.parse(data[i].api_string)

				}
				this.tableData = this.tableData.concat(data)
				this.meta.current_page = meta.currentPage;
				this.meta.last_page = (meta.lastPage != null) ? meta.lastPage : (data.length == 0) ? 0 : -1
			})
			.then(()=>{
				this.fetching = false;
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(()=>{
				this.isFetching = false;
			})
		},
		showDetail (data) {
			this.$store.state.logInfo = data
			this.$router.push({ path: `/report/log-report/${data.log_id}`})
		},
		loadingSeen(isVisible, entry){
			if(isVisible &&
			(this.meta.current_page < this.meta.last_page || this.meta.last_page == -1) &&
			!this.fetching){
				this.filter.page++
			}
		}
	}
}
</script>

<style>
  .dateCss div input, .dateCss div select{
    border: 1px solid #e8e8e8;
    border-radius: 5px !important;
    height: 42px;
    margin-top: 3px;
  }

  .dateCss div input {
	padding-left: 1% !important;
  }

  .dateCss div select {
	padding-left: 5% !important;
  }

  .dateCss div input::placeholder{
    padding-left: 1%;
  }

</style>
