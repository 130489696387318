<template>
  <div>

    <!-- FILTER STARTS -->
    <b-card :title="'Target'" :sub-title="infoPopup.title" header-tag="h5" class="mb-4" v-if="filterReady">
      <hr>
      <b-form>
        <b-form-row>
          <b-form-group label="Region" class="col-md-4">
            <Multiselect 
              v-if="filterOptions"
              class="mt-1"
              :options="filterOptions.region"
              label="name"
              track-by="loc_code"
              :multiple="true"
              v-model="filter.region"
              placeholder="All Regions"
            />
          </b-form-group>
          <b-form-group label="Branch" class="col-md-4">
            <Multiselect 
              v-if="filterOptions"
              class="mt-1"
              :options="filterOptions.branch"
              label="name"
              track-by="loc_code"
              :multiple="true"
              v-model="filter.branch"
              placeholder="All Branches"
            />
          </b-form-group>
          <b-form-group label="Status" class="col-md-4">
            <Multiselect 
              v-if="filterOptions"
              class="mt-1"
              :options="statusOptions"
              :multiple="false"
              v-model="filter.status"
              placeholder="All Status"
            />
          </b-form-group>
        </b-form-row>
        <b-form-row>
          <b-form-group label="Type" class="col-md-3">
            <Multiselect 
              v-if="filterOptions"
              class="mt-1"
              :options="targetTypeOptions"
              :multiple="true"
              v-model="filter.type"
              placeholder="All Types"
            />
          </b-form-group>
          <b-form-group label="Month" class="col-md-3">
            <Multiselect 
              v-if="filterOptions"
              class="mt-1"
              :options="filterOptions.month"
              label="name"
              track-by="value"
              :multiple="true"
              v-model="filter.month"
              placeholder="All Months"
            />
          </b-form-group>
          <b-form-group label="Year" class="col-md-3">
            <Multiselect 
              v-if="filterOptions"
              class="mt-1"
              :options="filterOptions.year"
              :multiple="true"
              v-model="filter.year"
              placeholder="All Years"
            />
          </b-form-group>
          <b-form-group class="col-md-3">
            <b-btn :class="(mobile) ? '' : 'mt-4'" type="button" variant="primary" @click="vue_universal_table.update++" style="width: 100%;">Filter</b-btn>
          </b-form-group>
        </b-form-row>
      </b-form>
    </b-card>
    <!-- FILTER ENDS -->

    <!-- ALERT MESSAGE STARTS -->
      <transition name="fade">
        <div class="alert alertmessage" :class="{'alert-warning' : alert.isError, 'alert-success' : !alert.isError}" v-if="alert.showAlert">
          <strong v-if="alert.isError">Error!</strong>
          <strong v-else>Success!</strong>

          <br><br>
          <p v-for="message in alert.alertMessage">♢ {{ message }}</p>
        </div>
    </transition>
    <!-- ALERT MESSAGE ENDS -->

    <div class="mb-4 p-4 card">
      <vue-universal-table
        :api_url="'/api/target/getTargets'"
        :filter="cleanedFilter"
        :update="vue_universal_table.update"
        :fields="vue_universal_table.fields"
        :sort_order="'DESC'"
        :sort_by="'created_at'"
        :special_buttons="['importTarget']"
        @onImportTarget="showUploadTarget"
      ></vue-universal-table>
    </div>

    <div v-if="showUploadTargetModal">
      <upload-target
        @closeModal="showUploadTargetModal = false"
        @triggerAlert="triggerAlert"
      ></upload-target>
    </div>

  </div>
</template>

<script>
  import Multiselect  from 'vue-multiselect'
  import uploadTarget from './childComponents/uploadTarget'

  export default {
    data () {
      return {
        infoPopup : this.$store.state.hoverInformation.setting.target,
        alert : {
          showAlert : false,
          isError : false,
          alertMessage : [],
        },

        mobile : this.isMobile(),
        filterReady : false,
        showUploadTargetModal : false,
        statusOptions : ['ACTIVE', 'INACTIVE'],
        targetTypeOptions : ['SALES', 'SERVICE'],
        filterOptions: {
          region: [],
          branch: [],
          target_type : ['SALES', 'SERVICE'],
          month : [],
          year : []
        },
        filter: {
          region: [],
          branch: [],
          type : [],
          status : '',
          month : [],
          year : []
        },
        cleanedFilter : {},
        vue_universal_table : {
          update : 0,
          fields : [
            {
              title : 'Unique ID',
              variable : 'unique_id'
            },
            {
              title : 'ID',
              variable : 'id'
            },
            {
              title : 'Type',
              variable : 'type'
            },
            {
              title : 'Loc Code',
              variable : 'loc_code'
            },
            {
              title : 'Region Code',
              variable : 'region_code'
            },
            {
              title : 'Value',
              variable : 'value'
            },
            {
              title : 'Month',
              variable : 'month'
            },
            {
              title : 'Year',
              variable : 'year'
            },
            {
              title : 'Created At',
              variable : 'created_at'
            },
            {
              title : 'Status',
              variable : 'status'
            }
          ]
        },
      }
    },
    components : {
      Multiselect ,
      uploadTarget,
    },
    mounted () {
      this.loadFilter();

      this.filterReady = true;
    },
    watch : {
      'filter.region' : function() {
        this.loadFilter('region')
      },
      'filter' : {
        handler : 'transformFilter',
        deep : true,
      }
    },
    methods : {
      showUploadTarget(){
        this.showUploadTargetModal = true;
      },
      loadFilter(type){
        if (type == 'region'){
          this.filter.branch.length = 0;
          this.filterOptions.branch = this.$store.getters.getBranch(this.filter.region)
        } else {
          this.filterOptions = this.$store.getters.getFilterOptions
        } 
      },
      async transformFilter (filter) {
        let obj = {}
        let keys = Object.keys(filter)
        for (var i = 0 ; i < keys.length; i++) {
          if(keys[i] == 'status'){
            obj[keys[i]] = filter[keys[i]]
          } else if(filter[keys[i]] != null){
            obj[keys[i]] = []
            for (var j = 0; j < filter[keys[i]].length; j++) {
              keys[i] == 'region' || keys[i] == 'branch' ? obj[keys[i]].push(filter[keys[i]][j].loc_code) : keys[i] == 'month' ? obj[keys[i]].push(filter[keys[i]][j].value) : keys[i] == 'year' || keys[i] == 'type' ? obj[keys[i]].push(filter[keys[i]][j]) : obj[keys[i]].push(filter[keys[i]][j][keys[i].toLowerCase()])
            }
          }
        }
        this.cleanedFilter = obj;
      },
      triggerAlert(isError, alertMessage){//Boolean, Array
        let vm = this;

        if(!isError){//SPECIAL FOR THIS PAGE (CLOSE MODAL AND REFRESH DATA)
          this.showUploadTarget = false;
          this.vue_universal_table.update++;
        }

        vm.alert.isError = isError;
        vm.alert.alertMessage.length = 0;
        vm.alert.alertMessage = this.alert.alertMessage.concat(alertMessage);

        vm.alert.showAlert = true;
        setTimeout(()=>{
            vm.alert.showAlert = false;
        }, 5000);
      },
    }
  }
</script>

<style scoped>
  /* ALERT MESSAGE STARTS */
  .alertmessage {
      position: fixed;
      top: 10%;
      left: 47.5%;
      z-index:9999;
      width: 300px;
      text-align: center;
  }
  .alertmessage strong{
      font-size: 150%
  }
  .fade-enter-active, .fade-leave-active {
      transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
  }
  /* ALERT MESSAGE ENDS */
</style>