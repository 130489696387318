<template>
	<div>

		<div class="row mt-2 mb-3">
			<div class="col-md-5 col-12 row" style="padding-top: 6px">
				<div class="col mt-auto mb-auto" style="padding-right: 0">
					<h4>H2 Report Visit Monitoring</h4>
				</div>
			</div>
			<div class="col-md-1 col-12" />
			<div class="col-12 col-md-6 row ml-auto">
				<div class="col-12 col-md-4 highlightFilter"></div>
				<div class="col-12 col-md-4">
					<flat-pickr v-model="selected.date" :config="rangeConfig" class="mt-3 mb-3" />
				</div>

				<div class="col-12 col-md-4 highlightFilter">
					<button class="hso-download-button btn rounded" @click="downloadReport">
						Download CSV<i class="fas fa-file-download ml-2"></i>
					</button>
				</div>
			</div>
		</div>

		<div class="card mb-4">
			<div class="card-body">
				<div class="d-flex align-items-center mb-3">
					<h5 class="card-title mb-0 mr-2">Frequency Visit</h5>
					<div class="tooltip-container">
						<span><i class="fas fa-info-circle"></i></span>
						<div class="tooltip">
							<div class="tooltip-title">Frequency Visit</div>
							<div class="tooltip-content">Total motor that visit for service based on engine number</div>
						</div>
					</div>
					<div class="d-flex"></div>
				</div>
				<frequency-visit-chart :start-date="dateRange.start" :end-date="dateRange.end"></frequency-visit-chart>
			</div>
		</div>

		<b-card class="mb-4">
			<div class="d-flex align-items-center mb-3">
				<h5 class="card-title mb-0 mr-2" style="color: #9D9D9D;">Tracking Last Visit</h5>
				<div class="tooltip-container">
					<span><i class="fas fa-info-circle"></i></span>
					<div class="tooltip">
						<div class="tooltip-title">Tooltip</div>
						<div class="tooltip-content">This is a tooltip</div>
					</div>
				</div>
				<div class="input-group col-md-2 ml-auto">
					<div class="input-group-prepend">
						<span class="hso-search-bar-icon input-group-text" id="basic-addon1">
							<i class="fas fa-search ml-2" style="color: #d8d8d8"></i>
						</span>
					</div>
					<input type="text" class="form-control hso-search-bar" placeholder="Search" v-model="searchQuery"
						@input="paginate" aria-describedby="basic-addon1" />
				</div>
			</div>

			<table class="table">
				<thead>
					<tr>
						<th class="hso-table-header">Engine Number</th>
						<th class="hso-table-header">Frequency Visit</th>
						<th class="hso-table-header">Latest Visit Date</th>
						<th class="hso-table-header">Latest Visit Dealer</th>
						<th class="action-header">Action</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="item in data" :key="item.engine_no">
						<td class="hso-table-body">{{ item.engine_no }}</td>
						<td class="hso-table-body">{{ item.freq_visit }}</td>
						<td class="hso-table-body">{{ formatDate(item.latest_visit_date) }}</td>
						<td class="hso-table-body">{{ item.latest_visit_dealer }}</td>
						<td class="action-body">
							<div class="action-buttons">
								<button class="btn btn-detail rounded" @click="onDetailVisitClick(item.engine_no)">
									Detail Visit
								</button>
								<button class="btn btn-journey rounded" @click="onVisitJourneyClick(item.engine_no)">
									<i class="fas fa-map-marker-alt"></i>
								</button>
							</div>
						</td>
					</tr>
				</tbody>
			</table>

			<!-- Pagination -->
			<div class="d-flex justify-content-between align-items-center mt-3">
				<div class="hso-table-pagination">
					<!-- Showing {{ paginationStart + 1 }} to {{ paginationEnd }} of
					{{ totalItems }} entries -->
				</div>
				<div class="pagination-buttons">
					<button class="btn btn-outline-secondary mr-2" @click="previousPage" :disabled="currentPage === 1">
						Previous
					</button>
					<span class="current-page mx-2">{{ currentPage }}</span>
					<button class="btn btn-outline-secondary" @click="nextPage" :disabled="!hasMoreItems">
						Next
					</button>
				</div>
			</div>
		</b-card>

	</div>
</template>

<script>
import axios from "axios";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import Multiselect from "vue-multiselect";
import frequencyVisitChart from "./childComponents/frequencyVisitChart";
import Tooltip from './childComponents/Tooltip.vue';

export default {
	name: "h2-report-visit",
	metaInfo: {
		title: "H2 Report Visit Monitoring",
	},
	data() {
		return {
			modal: {
				download: false,
			},

			options: this.$store.state.filterData,

			filter: {
				start: moment().startOf('month').format('YYYY-MM-DD'),
				end: moment().format('YYYY-MM-DD'),
				by: 'freq_visit',
				order: 'desc',
			},

			rangeConfig: {
				mode: "range",
				altInput: true,
				altInputClass: "form-control input hso-highlight-flatpickr w-100",
				dateFormat: "Y-m-d",
				onChange: this.onDateChange,
				defaultDate: [
					moment().startOf('month').format('YYYY-MM-DD'), // First day of the current month
					moment().format("YYYY-MM-DD"), // Today's date
				],
			},
			selected: {
				date_filter: true,
				date: [
					moment().startOf("month").format("YYYY-MM-DD"), // First day of the current month
					moment().format("YYYY-MM-DD"), // Today's date
				],
			},
			dateRange: {
				start: moment().startOf('month').format('YYYY-MM-DD'), // First day of the current month
				end: moment().format("YYYY-MM-DD"), // Today's date
			},

			data: [],
			currentPage: 1,
			rowsPerPage: 20,
			totalItems: 0,
			hasMoreItems: false,
			searchQuery: "",
		};
	},
	components: {
		flatPickr,
		Multiselect,
		frequencyVisitChart,
		Tooltip,
	},
	mounted() {
		this.fetchData();
	},
	methods: {
		fetchData() {
			const params = {
				start: this.dateRange.start,
				end: this.dateRange.end,
				search: this.searchQuery,
				by: "freq_visit",
				order: "desc",
				page: this.currentPage,
				limit: this.rowsPerPage,
			};
			axios
				.get("/api/distribution/tracking/last-visit", { params })
				.then((response) => {
					console.log('API response:', response.data);
					this.data = response.data.data;
					console.log('Updated data:', this.data);
					this.totalItems = response.data.total || response.data.data.length;
					this.hasMoreItems = this.data.length === this.rowsPerPage;
				})
				.catch((error) => {
					console.error("Error fetching data:", error);
				});
		},
		formatDate(date) {
			return moment(date).format("DD/MM/YYYY");
		},
		previousPage() {
			if (this.currentPage > 1) {
				this.currentPage--;
				this.fetchData();
			}
		},
		nextPage() {
			if (this.hasMoreItems) {
				this.currentPage++;
				this.fetchData();
			}
		},
		onDateChange(selectedDates) {
			if (selectedDates.length === 2) {
				this.dateRange.start = moment(selectedDates[0]).format("YYYY-MM-DD");
				this.dateRange.end = moment(selectedDates[1]).format("YYYY-MM-DD");
				this.currentPage = 1;
				this.fetchData(); // Re-fetch data based on the updated date range
			}
		},
		downloadReport() {

			const params = {
				start: this.dateRange.start,
				end: this.dateRange.end,
				search: this.searchQuery,
				by: "freq_visit",
				order: "desc",

			};
			axios
				.get("/api/distribution/tracking/download-last-visit", { params })
				.then((response) => {
					console.log('API response:', response.data);

					this.$swal.fire({
						title: 'Report Submitted',
						text: "Frequency visit request has been successfully submitted",
						icon: 'success',
						showCancelButton: true,
						confirmButtonColor: '#3085D6',
						cancelButtonColor: '#D33',
						confirmButtonText: 'To Download Page',
						cancelButtonText: 'Ok'
					}).then((result) => {
						console.log(result);
						if (result.value) {
							console.log('confirmed');
							this.$router.push(`/report/download-report/`)
						}
					})
				})
				.catch((error) => {
					console.error("Error fetching data:", error);
				});

		},
		paginate() {
			this.currentPage = 1; // Reset to the first page whenever we change the search query or data
		},
		onDetailVisitClick(engineNo) {
			this.$router.push({
				name: "H2DetailVisit",
				params: { engineNo: engineNo },
				query: {
					start: this.dateRange.start,
					end: this.dateRange.end,
				},
			});
		},
		onVisitJourneyClick(engineNo) {
			this.$router.push({
				name: "H2VisitJourney",
				params: { engineNo: engineNo },
				query: {
					start: this.dateRange.start,
					end: this.dateRange.end,
				},
			});
		},
		showTooltip() {
			this.$refs.tooltip.showTooltip()
		},
		hideTooltip() {
			this.$refs.tooltip.hideTooltip()
		},


	},
	computed: {
		filteredData() {
			return this.data.filter((item) =>
				item.engine_no.includes(this.searchQuery)
			);
		},
		paginatedData() {
			const start = (this.currentPage - 1) * this.rowsPerPage;
			const end = start + this.rowsPerPage;
			return this.filteredData.slice(start, end);
		},
		paginationStart() {
			return (this.currentPage - 1) * this.rowsPerPage;
		},
		paginationEnd() {
			return Math.min(this.paginationStart + this.data.length, this.totalItems);
		},
	},
	watch: {
		searchQuery() {
			this.currentPage = 1;
			this.fetchData();
		},
	},
};
</script>

<style scoped>
.hso-search-bar {
	border: 1px solid #ced4da;
	border-left: none;
	border-radius: 0 5px 5px 0;
	/* Right side rounded corners */
}

.hso-search-bar-icon {
	border: 1px solid #ced4da;
	border-right: none;
	border-radius: 5px 0 0 5px;
	/* Left side rounded corners */
}

.action-header {
	width: 1%;
	/* This forces the column to be as narrow as possible */
	white-space: nowrap;
	color: #4B4B4B;
	background-color: #FFFFFF;
	text-align: left;
	padding-right: 10px;
	/* Kurangi padding untuk mendekatkan */
}

.action-body {
	width: 1%;
	/* This forces the column to be as narrow as possible */
	white-space: nowrap;
	color: #4B4B4B;
	background-color: #FFFFFF;
	border-top: none;
	border-bottom: none;
	text-align: center;
	/* Set ke center */
	display: flex;
	justify-content: center;
	align-items: center;
}

.action-buttons {
	display: flex;
	gap: 10px;
	justify-content: flex-start;
	align-items: center;
	border-radius: 50px;
}

.btn-detail {
	height: 40px;
	width: 150px;
	border: 1px solid #efefef;
}

.btn-journey {
	height: 40px;
	width: 40px;
	border: 1px solid #267ce5;
	display: flex;
	/* Use flexbox for centering */
	align-items: center;
	/* Center vertically */
	justify-content: center;
	/* Center horizontally */
	padding: 0;
	/* Remove any default padding */
}

.btn-journey i {
	color: #267ce5;
	font-size: 16px;
	/* Adjust the font size if needed */
	margin: 0;
	/* Ensure no margins are added */
}

.hso-download-button {
	margin-top: 12px;
	height: 40px;
	margin-top: 0px;
	color: #2F2F2F;
	background-color: #FFFFFF;
	box-shadow: none;
	width: 100%;
	white-space: nowrap;
}

.hso-table-header {
	color: #4B4B4B;
	background-color: #FFFFFF;
	text-align: left;
	padding-left: 10px;
}

.hso-table-body {
	border-bottom: none;
	border-top: none;
	text-align: left;
}

.hso-table-pagination {
	color: #CBCBCB;
}

.tooltip-container {
	position: relative;
	display: inline-block;
}

.tooltip {
	visibility: hidden;
	position: absolute;
	bottom: 100%;
	left: 50%;
	transform: translateX(-50%);
	background-color: white;
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: 10px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	width: 200px;
	opacity: 0;
	transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip {
	visibility: visible;
	opacity: 1;
}

.tooltip-title {
	font-weight: bold;
	margin-bottom: 5px;
}

.tooltip-content {
	font-size: 0.9em;
	color: #666;
}
</style>
