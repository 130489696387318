<template>
  <div>
    <!--Filter Option-->
    <b-card title="Tracking" :sub-title="infoPopup.title" header-tag="h6" class="mb-4">
      <hr>
        <b-form>
            <b-form-row>
              <b-form-group label="From" class="col-md-3">
                <flat-pickr class="mt-1" v-model="filter.start" :config="rangeConfig" :placeholder="!isIEMode ? 'Select From Date' : null"/>
              </b-form-group>
              <b-form-group label="To" class="col-md-3">
                <flat-pickr class="mt-1" v-model="filter.end" :config="rangeConfig" :placeholder="!isIEMode ? 'Select To Date' : null"/>
              </b-form-group>
              <b-form-group label="Engine Number" class="col-md-3">
                  <b-input class="mt-1" type="text" placeholder="Engine Number" v-model="selected.engine_no" />
              </b-form-group>
              <b-form-group label="Assembled Year" class="col-md-3">
                  <b-input class="mt-1" type="text" placeholder="Assembled Year" v-model="filter.assembled_year" />
              </b-form-group>
            </b-form-row>
            <b-form-row>
              <b-form-group label="Activity" class="col-md">
                <Multiselect
                  v-model="filter.activity"
                  class="mt-1"
                  :options="options.activity"
                  :searchable="true"
                  :close-on-select="true"
                  :multiple="false"
                  placeholder="Choose Activity"
                ></Multiselect>
              </b-form-group>
              <b-form-group label="Scan Source" class="col-md">
                <Multiselect
                  v-model="filter.scan_source"
                  class="mt-1"
                  :options="options.scan_source"
                  placeholder="Choose Scan Source"
                ></Multiselect>
              </b-form-group>
              <!-- <b-form-group label="Fun Loc Awal" class="col-md-3">
                <Multiselect
                  v-if="filter.activity.toLowerCase() == 'grahmtomd'"
                  v-model="dummyFunLocAwal"
                  class="mt-1"
                  :options="['AHM']"
                  placeholder="Choose Fun Loc Awal"
                  :disabled="true"
                ></Multiselect>
                <Multiselect
                  v-else
                  v-model="filter.from"
                  class="mt-1"
                  :options="options.locations.map(x => x.loc_code)"
                  :custom-label="opt => options.locations.find(x => x.loc_code == opt).name"
                  placeholder="Choose Fun Loc Awal"
                  :disabled="filter.activity == null || filter.activity == ''"
                ></Multiselect>
              </b-form-group>
              <b-form-group label="Fun Loc Akhir" class="col-md-3">
                <Multiselect
                  v-model="filter.to"
                  class="mt-1"
                  :options="options.locations.map(x => x.loc_code)"
                  :custom-label="opt => options.locations.find(x => x.loc_code == opt).name"
                  placeholder="Choose Fun Loc Akhir"
                  :disabled="filter.activity == null || filter.activity == ''"
                ></Multiselect>
              </b-form-group> -->
            </b-form-row>
            <b-form-row>
              <b-form-group label="Upload Engine Number" class="col-md" style="display: flex; flex-direction: column-reverse;">
                <b-btn @click="uploadEngineNumber" :disabled="isFetching" class="w-100" type="button" variant="secondary" style="min-height: 40px;"><span class="fa fa-file-upload"></span>&nbsp;&nbsp;Upload txt File</b-btn>
              </b-form-group>
              <b-form-group class="col-md" style="display: flex; flex-direction: column-reverse;">
                <b-btn @click="vue_universal_table.update++" :disabled="isFetching" class="w-100" type="button" variant="primary" style="min-height: 40px;"><span class="fa fa-filter"></span>&nbsp;&nbsp;Search</b-btn>
              </b-form-group>
              <b-form-group class="col-md" style="display: flex; flex-direction: column-reverse;">
                <b-btn @click="downloadExcel" :disabled="isFetching" class="w-100" type="button" variant="success" style="min-height: 40px;"><span class="fa fa-file-excel"></span>&nbsp;&nbsp;Export To Excel</b-btn>
              </b-form-group>
              <!-- <b-form-group class="col-md-4 offset-md-2" style="display: flex; flex-direction: column-reverse;">
                <div class="row">
                  <b-btn @click="vue_universal_table.update++" :disabled="isFetching" class="col-5" type="button" variant="primary"><span class="fa fa-filter"></span>&nbsp;&nbsp;Search</b-btn>
                  <div class="col-2"></div>
                  <b-btn @click="downloadExcel" :disabled="isFetching" class="col-5" type="button" variant="success"><span class="fa fa-file-excel"></span>&nbsp;&nbsp;Export To Excel</b-btn>
                </div>
              </b-form-group> -->
            </b-form-row>
        </b-form>
    </b-card>

    <b-card class="mb-4">
      <vue-universal-table
        api_url="/api/reports/tracking"
        :update="vue_universal_table.update"
        :fields="vue_universal_table.fields"
        :filter="filter"
        :fixed_header="true"
        :infinite_scroll="true"
        @fetchStart="isFetching = true"
        @fetchEnd="isFetching = false"
      ></vue-universal-table>
    </b-card>

    <!-- Modals Start -->
    <download-excel-modal
      v-if="modal.download"
      @downloadExcel="downloadExcel"
      @closeModal="modal.download = false"
    ></download-excel-modal>
    <!-- Modals End -->

  </div>
</template>

<script>
import qs from 'qs'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import Multiselect from 'vue-multiselect'

import downloadExcelModal from './children/downloadExcelModal'

export default {
  metaInfo: {
    title: 'Tracking Report'
  },
  data () {
    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    const endOfMonth   = moment().format('YYYY-MM-DD');
    return {
      isFetching : false,
      modal : {
        download : false,
      },

      dummyFunLocAwal : 'AHM',

      infoPopup : this.$store.state.hoverInformation.report.tracking,
      rangeConfig: {
        altInput: true,
        animate: !isRTL()
      },
      options : {
        region : this.$store.getters.getRegion,
        branch : this.$store.getters.getSimpleBranch(),
        ori_branch : this.$store.getters.getSimpleBranch(),
        locations : [],
        activity : [],
        scan_source : ['H1', 'H2'],
      },
      selected : {
        engine_no : '',
      },
      filter : {
        start : moment().startOf('month').format('YYYY-MM-DD'),
        end : moment().format('YYYY-MM-DD'),
        engine_no : '',
        assembled_year : '',
        region : '',
        branch : '',
        activity : '',
        scan_source : '',
        from : '',
        to : ''
      },
      vue_universal_table : {
        fields : [
          {
            title : 'Engine Number',
            variable : 'engine_no'
          },
          {
            title : 'Frame Number',
            variable : 'frame_no'
          },
          {
            title : 'Scan Date',
            variable : 'tanggal_activity',
            date_format : 'DD MMM YYYY HH:mm:ss'
          },
          {
            title : 'Activity',
            variable : 'activity'
          },
          {
            title : 'Fun Loc Awal',
            variable : 'current_region'
          },
          {
            title : 'Fun Loc Tujuan',
            variable : 'current_branch'
          },
        ],
        update : 0,
      }
    }
  },
  components: {
    flatPickr,
    Multiselect,
    downloadExcelModal
  },
  mounted () {
    this.getActivityOptions();

    this.options.locations = this.$store.getters.getRegion.concat(this.$store.getters.getSimpleBranch());
  },
  watch : {
    'filter.region' () {
      this.filter.branch = null;
      this.options.branch = this.$store.getters.getSimpleBranch(this.filter.region)
    },
    'filter.ori_region' () {
      this.filter.ori_branch = null;
      this.options.ori_branch = this.$store.getters.getSimpleBranch(this.filter.ori_region)
    },
    'selected.engine_no' () {
      this.filter.engine_no = this.selected.engine_no.replace(" ", "");
    },
    'filter.activity' () {
      this.filter.from = '';
      this.filter.to = '';
    }
  },
  methods: {
    getActivityOptions () {
      this.$http.get('/api/master/tracking-activity')
      .then((resp)=>{
        var data = resp.data.data
        this.options.activity = data.filter((a) => {if(a.activity != null && !a.activity.includes('\n')) return a}).map(a => a.activity)
      })
      .catch((err)=>{
        console.log(err);
      })
    },
    async uploadEngineNumber () {
      const { value: file } = await this.$swal.fire({
        title: 'Upload File',
        text : 'Only upload .txt file',
        input: 'file',
        icon : 'info',
        inputAttributes: {
          'accept' : '.txt',
          'aria-label' : 'Upload your engine no file'
        }
      })

      if (file) {
        var reader = new FileReader();
        reader.onload = (e) => {
          let engineNos = e.target.result.split(/[\r\n]+/g);
          this.selected.engine_no = this.filter.engine_no = engineNos.map(x=>x.replace(",", "")).join(",");
          this.vue_universal_table.update++;
        }
        reader.readAsText(file);
      }
    },
    async downloadExcel (page) {
      let filter = JSON.parse(JSON.stringify(this.filter));
      for (var i in filter) if (filter[i] == '') delete filter[i];

      this.$http.get(`/api/reports/tracking/download-excel?${qs.stringify(filter, {skipNulls:true})}`)
      .then((resp)=>{
        this.$swal.fire({
          title: 'Report Submitted',
          text: "Tracking report document request has been successfully submitted",
          icon: 'success',
          showCancelButton: true,
          confirmButtonColor: '#3085D6',
          cancelButtonColor: '#D33',
          confirmButtonText: 'To Download Page',
          cancelButtonText: 'Ok'
        }).then((result) => {
          console.log(result);
          if (result.value) {
            console.log('confirmed');
            this.$router.push(`/report/download-report/`)
          }
        })
      })
      .catch((err)=>{console.log(err)})

      // let filter = JSON.parse(JSON.stringify(this.filter));
      // for (var i in filter) if (filter[i] == '') delete filter[i];
      
      // let countUrl = `/api/reports/tracking/count?${qs.stringify(filter, {skipNulls:true})}`
      // let url = `/api/reports/tracking/download-excel?${qs.stringify(filter, {skipNulls:true})}`

      // let totalFetched = 0;
      // let totalData = null;

      // this.isFetching = true;
			// this.$swal({
      //   title : "Please stand by",
      //   text : `Data is currently being downloaded...`,
      //   allowOutsideClick : false,
      //   allowEscapeKey : false
      // });

      // this.$swal.showLoading();

      // //Get total number of data
      // await this.$http.get(countUrl)
      // .then((resp)=>{
      //   totalData = resp.data.data.count;
      // })
      // .catch((err)=>{console.log(err)})

      // // this.exportCsv([{title:"test", value:1},{title:"hello", value:5}], 'Test file')
      // this.exportCsv(await this.recurseFetchDownload(url, 0, [], 0, totalData), `Tracking data ${qs.stringify(filter, {skipNulls:true}).replace(/&/g, ", ")}`)
      // this.exportExcel(await this.recurseFetchDownload(url, 0, [], 0, totalData), `Tracking data ${qs.stringify(filter, {skipNulls:true}).replace(/&/g, ", ")}`)

      // //Clear loading
      // this.$swal.close();
      // this.isFetching = false;
    },
    async myFunc (message, url) {
      let a = await this.$http.get(url)
      .then(async ()=>{
        // console.log(message)
        if (message == 'hello') {
          return await this.myFunc('mellow', url);
        } else {
          return 'not hello'
        }
      })
      .catch((err)=>{console.log(err)})
      return a ? a : 'waw'
    },
    async recurseFetchDownload (url, last_id, data, totalFetched, totalData) {
      let respond = await this.$http.get(`${url}&last_id=${last_id}`)
      .then(async (resp)=>{
        let dataLength = totalFetched + resp.data.data.data.length;
        console.log(resp.data.data.data.length);
        if (totalData > dataLength) {
          return await this.recurseFetchDownload(url, resp.data.data.last_id, data.concat(resp.data.data.data), dataLength, totalData);
        } else {
          return data.concat(resp.data.data.data);
        }
      })
      .catch((err)=>{console.log(err)})

      return respond ? respond : 'An error has occured';
    },
  }
}

function isRTL () {
  return document.documentElement.getAttribute('dir') === 'rtl' || document.body.getAttribute('dir') === 'rtl'
}
</script>

<style>
  .dateCss div input{
    border: 1px solid #e8e8e8;
    border-radius: 5px !important;
    height: 42px;
    margin-top: 3px;
    padding-left: 1% !important;
  }
  .dateCss div input::placeholder{
    padding-left: 1%;
  }
</style>
