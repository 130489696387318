<template>
  <!-- FILTER STARTS -->
  <b-card :title="(page_name != null) ? page_name : 'Filter'" :sub-title="popUp.title" header-tag="h5" class="mb-4" v-if="filterReady">
    <hr>
    <b-form>
      <b-form-row>
        <b-form-group label="Region" :class="(config.seperatedRange) ? 'col-md-3' : 'col-md-4'">
          <Multiselect
            v-if="filterOptions"
            class="mt-1"
            :options="filterOptions.region"
            label="name"
            track-by="loc_code"
            :multiple="true"
            v-model="filter.region"
            placeholder="All Regions"
          />
        </b-form-group>
        <b-form-group label="Branch" :class="(config.seperatedRange) ? 'col-md-3' : 'col-md-4'">
          <Multiselect
            v-if="filterOptions"
            class="mt-1"
            :options="filterOptions.branch"
            label="name"
            track-by="loc_code"
            :multiple="true"
            v-model="filter.branch"
            placeholder="All Branches"
          />
        </b-form-group>
        <b-form-group label="Date" class="col-md-4 dateCss" style="height:42px" v-if="!config.seperatedRange">
          <flat-pickr
            class="mt-1"
            v-model="filter.range"
            :config="rangeConfig"
            :placeholder="!isIEMode ? 'Range picker' : null"
          />
        </b-form-group>
        <b-form-group label="From" class="col-md-3 dateCss" style="height:42px" v-if="config.seperatedRange">
          <flat-pickr
            class="mt-1"
            v-model="filter.start"
            :config="rangeConfigSeperated"
            :placeholder="!isIEMode ? 'Select From Date' : null"
          />
        </b-form-group>
        <b-form-group label="To" class="col-md-3 dateCss" style="height:42px" v-if="config.seperatedRange">
          <flat-pickr
            class="mt-1"
            v-model="filter.end"
            :config="rangeConfigSeperated"
            :placeholder="!isIEMode ? 'Select To Date' : null"
          />
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <b-form-group label="Segment" class="col-md-3">
          <Multiselect 
            class="mt-1"
            v-if="filterOptions"
            :options="filterOptions.segment"
            label="segment"
            track-by="segment"
            :multiple="true"
            v-model="filter.segment"
            placeholder="All Segments"
          />
        </b-form-group>
        <b-form-group label="Series" class="col-md-3">
          <Multiselect 
            class="mt-1"
            v-if="filterOptions"
            :options="filterOptions.series"
            label="series"
            track-by="series"
            :multiple="true"
            v-model="filter.series"
            placeholder="All Series"
          />
        </b-form-group>
        <b-form-group label="Type" class="col-md-3">
          <Multiselect 
            class="mt-1"
            v-if="filterOptions"
            :options="filterOptions.type"
            label="type"
            track-by="type"
            :multiple="true"
            v-model="filter.type"
            placeholder="All Vehicle Types"
          />
        </b-form-group>
        <b-form-group label="Color" class="col-md-3">
          <Multiselect 
            class="mt-1"
            v-if="filterOptions"
            :options="filterOptions.color"
            label="color_desc"
            track-by="color_code"
            :multiple="true"
            v-model="filter.color"
            placeholder="All Colour"
          />
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <b-form-group class="col mt-2" style="text-align: center;">

          <b-btn
            type="button"
            variant="primary"
            @click="submitButton()"
            :style="(mobile) ? 'width : 100%' : 'width: 20vw'"
          >Filter</b-btn>
          <b-btn
            v-if="config.exportUrl"
            class="ml-3"
            type="button"
            variant="success"
            @click="fetchForExport()"
            :style="(mobile) ? 'width : 100%' : 'width: 20vw'"
          >Export Details</b-btn>

        </b-form-group>
      </b-form-row>
    </b-form>
  </b-card>
  <!-- FILTER ENDS -->
</template>

<script>
/* eslint-disable */
  import flatPickr from 'vue-flatpickr-component'
  import Multiselect  from 'vue-multiselect'
  import moment from 'moment'
  import XLSX from 'xlsx'

  export default {
    data () {
      const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
      const endOfMonth   = moment().format('YYYY-MM-DD');
      return {
        filterReady : false,
        mobile : this.isMobile(),
        config : {
          seperatedRange : false,
          daysLimit : null,
          exportUrl : null,
        },
        rangeConfig: {
          mode: 'range',
          altInput: true,
          animate: !isRTL()
        },
        rangeConfigSeperated: {
          altInput: true,
          animate: !isRTL() 
        },
        // Filter Data and Filter Starts
        filterOptions: {
          region: [],
          branch: [],
          segment: [],
          series: [],
          type: [],
          color: [],
        },
        filter: {
          range: `${startOfMonth} to ${endOfMonth}`,
          start : startOfMonth,
          end : endOfMonth,
          region: [],
          branch: [],
          segment: [],
          series: [],
          type: [],
          color: []
        },
        // Filter Data and Filter Ends
        exportData : [],
      }
    },
    props : [
      'page_name',//PAGE NAME OR TITLE
      'seperated_range',//BOOLEAN - Default=false - SEPERATED RANGE DATE ('from' and 'to' instead of 'range date')
      'return_type',//RETURN TYPE ('Object' or 'String') -- string == string for params url
      'fetch_filter',//INTEGER (0, 1, 2, 3),
      'popUp',
      'days_limit',//INTEGER (7, 14, 31, 90) - Default=null -- If not specified, days will not be limited, otherwise, check days limit
      'export_url',//STRING (https://....) - Defualt=null -- If not specified, export button is not shown
    ],
    components : {
      Multiselect ,
      flatPickr,
    },
    mounted () {
      this.loadFilter()

      //CHECK 'seperated_range'
      if(this.seperated_range != null){
        if (typeof this.seperated_range == 'boolean'){
          this.config.seperatedRange = this.seperated_range;
        } else {
          this.errorPrint("'seperated_range' is a boolean");
        }
      }

      //CHECK 'days_limit'
      if(this.days_limit != null){
        if (typeof this.days_limit == 'number'){
          this.config.daysLimit = parseInt(this.days_limit);
        } else {
          this.errorPrint("'days_limit' is a number");
        }
      }

      //CHECK 'export_url'
      if(this.export_url != null){
        if (typeof this.export_url == 'string'){
          this.config.exportUrl = this.export_url;
        } else {
          this.errorPrint("'export_url' is a string");
        }
      }

      this.filterReady = true;
    },
    watch: {
      'fetch_filter' : function() {
        this.submitButton();
      },
      'filter.region' : function() {
        this.loadFilter('region')
      },
      'filter.segment' : function () {
        this.loadFilter('segment')
      },
      'filter.series' : function () {
        this.loadFilter('series')
      }
    },
    methods : {
      loadFilter(type){
        if (type == 'region'){
          this.filter.branch.length = 0;
          this.filterOptions.branch = this.$store.getters.getBranch(this.filter.region)
        } else if (type == 'segment'){
          this.filter.series.length = 0;
          this.filterOptions.series = this.$store.getters.getSeries(this.filter.segment)

          this.filter.type.length = 0;
          this.filterOptions.type = this.$store.getters.getType(this.filter.segment, this.filter.series)
        } else if (type == 'series'){
          this.filter.type.length = 0;
          this.filterOptions.type = this.$store.getters.getType(this.filter.segment, this.filter.series)
        } else {
          this.filterOptions = this.$store.getters.getFilterOptions
        } 
      },
      async pacifiedUrl (filters) {
        let filterUrl = ''
        let keys = Object.keys(filters)
        for (var i = 0; i < keys.length; i++) {
          if(keys[i] != 'range' && filters[keys[i]].length > 0){
            if(!this.config.seperatedRange) continue;
            filterUrl += `${keys[i]}=${filters[keys[i]]}&`
          } else if (!this.config.seperatedRange && keys[i] == 'range') {
            let splitDateRange = filters.range.split(' to ');
            filterUrl += 'start=' + splitDateRange[0] + '&'
            filterUrl += 'end=' + splitDateRange[1] + '&'
          }
        }
        
        return filterUrl.substring(0, filterUrl.length - 1)
      },
      async transformFilter (filter) {
        let obj = {}
        let keys = Object.keys(filter)
        for (var i = 0 ; i < keys.length; i++) {
          if (keys[i] == 'range' || keys[i] == 'start' || keys[i] == 'end') {
            obj[keys[i]] = filter[keys[i]]
          } else {
            obj[keys[i]] = []
            for (var j = 0; j < filter[keys[i]].length; j++) {
              keys[i] == 'region' || keys[i] == 'branch' ? obj[keys[i]].push(filter[keys[i]][j].loc_code) : obj[keys[i]].push(filter[keys[i]][j][keys[i].toLowerCase()])
            }
          }
        }
        return obj
      },
      async submitButton(){
      

        let start, end;
        if(this.config.seperatedRange){
          start = this.filter.start;
          end = this.filter.end;
        } else {
          let splitDateRange = filters.range.split(' to ');
          start = splitDateRange[0];
          end = splitDateRange[1];
        }

        let dateDiff = moment(this.filter.end).diff(moment(this.filter.start), 'days')+1;

        if (dateDiff <= 0) {
          alert('Start date must be earlier than end date!');
          return;
        }
        if(this.config.daysLimit && dateDiff > this.config.daysLimit){
          alert('Date filter cannot exceed 31 days!');
          return;
        }

        this.$emit('onFilter', (this.return_type != null && this.return_type.toLowerCase() == 'object') ? this.filter : await this.pacifiedUrl(await this.transformFilter(this.filter)))
      },
      exportToExcel(data){
        let wb = XLSX.utils.book_new()
        var ws = XLSX.utils.json_to_sheet(data, {raw:true});
        XLSX.utils.book_append_sheet(wb, ws, 'Data')

        let exportFileName = `${this.page_name}.xlsx`
        XLSX.writeFile(wb, exportFileName)
      },
      async fetchForExport(){
        let start, end;
        if(this.config.seperatedRange){
          start = this.filter.start;
          end = this.filter.end;
        } else {
          let splitDateRange = filters.range.split(' to ');
          start = splitDateRange[0];
          end = splitDateRange[1];
        }

        let dateDiff = moment(this.filter.end).diff(moment(this.filter.start), 'days')+1;

        if (dateDiff <= 0) {
          alert('Start date must be earlier than end date!');
          return;
        }
        if(this.config.daysLimit && dateDiff > this.config.daysLimit){
          alert('Date filter cannot exceed 31 days!');
          return;
        }
        
        this.$http.get(`${this.config.exportUrl}?${await this.pacifiedUrl(await this.transformFilter(this.filter))}`)
        .then((resp)=>{
          this.exportToExcel(resp.data.data);
        })
        .catch((err)=>{console.log(err)})
      },
    }
  }

  function isRTL () {
    return document.documentElement.getAttribute('dir') === 'rtl' || document.body.getAttribute('dir') === 'rtl'
  }
</script>

<style>
  .dateCss div input{
    border: 1px solid #e8e8e8;
    border-radius: 5px !important;
    height: 42px;
    margin-top: 3px;
    padding-left: 1% !important;
  }
  .dateCss div input::placeholder{
    padding-left: 1%;
  }
</style>

<style scoped>

</style>
