<template>
  <div ref='byRegion' style='min-height:700px'></div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import spiritedaway from '@amcharts/amcharts4/themes/spiritedaway.js'

am4core.useTheme(spiritedaway)

export default {
  mounted () {
    this.createChart()
  },
  data () {
    return {
      chart: null
    }
  },
  methods: {
    createChart () {
      this.chart = am4core.create(this.$refs.byRegion, am4charts.XYChart)

      this.chart.data = [
        {
          'region': 'H101',
          'region_name': 'Jakarta',
          'stock': 1757
        },
        {
          'region': 'H350',
          'region_name': 'Semarang',
          'stock': 589
        },
        {
          'region': 'H360',
          'region_name': 'Yogyakarta',
          'stock': 1722
        },
        {
          'region': 'H530',
          'region_name': 'NTB',
          'stock': 1599
        },
        {
          'region': 'H540',
          'region_name': 'Denpasar',
          'stock': 965
        },
        {
          'region': 'H600',
          'region_name': 'Palembang',
          'stock': 494
        },
        {
          'region': 'H650',
          'region_name': 'Bengkulu',
          'stock': 193
        },
        {
          'region': 'H700',
          'region_name': 'Balikpapan',
          'stock': 27
        },
        {
          'region': 'H720',
          'region_name': 'Pontianak',
          'stock': 313
        },
        {
          'region': 'H760',
          'region_name': 'Makassar',
          'stock': 1553
        },
        {
          'region': 'H800',
          'region_name': 'Samarinda',
          'stock': 8
        }
      ]

      let categoryAxis = this.chart.yAxes.push(new am4charts.CategoryAxis())
      categoryAxis.dataFields.category = 'region'
      categoryAxis.numberFormatter.numberFormat = '#'
      categoryAxis.renderer.inversed = true
      categoryAxis.renderer.grid.template.location = 0
      categoryAxis.renderer.cellStartLocation = 0.1
      categoryAxis.renderer.cellEndLocation = 0.9

      let valueAxis = this.chart.xAxes.push(new am4charts.ValueAxis())
      valueAxis.renderer.opposite = true

      this.chart.legend = new am4charts.Legend()

      this.createSeries('stock', 'Average Stock')
    },
    createSeries (field, name) {
      let series = this.chart.series.push(new am4charts.ColumnSeries())
      series.dataFields.valueX = field
      series.dataFields.categoryY = 'region'
      series.name = name
      series.columns.template.tooltipText = '{name}: [bold]{valueX}[/]'
      series.columns.template.height = am4core.percent(100)
      series.sequencedInterpolation = true

      let valueLabel = series.bullets.push(new am4charts.LabelBullet())
      valueLabel.label.text = '{valueX}'
      valueLabel.label.horizontalCenter = 'left'
      valueLabel.label.dx = 10
      valueLabel.label.hideOversized = false
      valueLabel.label.truncate = false

      let categoryLabel = series.bullets.push(new am4charts.LabelBullet())
      categoryLabel.label.horizontalCenter = 'right'
      categoryLabel.label.dx = -10
      categoryLabel.label.fill = am4core.color('#fff')
      categoryLabel.label.hideOversized = false
      categoryLabel.label.truncate = false
    }
  }
}
</script>
