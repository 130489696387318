<template>
  <div>
    <!-- FILTER STARTS -->
    <div>
      <filter-complete
        :page_name="'Service Cycle'"
        :seperated_range="true"
        :fetch_filter="filterUrl.initial"
        @onFilter="setFilterUrl"
        :popUp="infoPopup"
      ></filter-complete>
    </div>
    <!-- FILTER ENDS -->

    <div class="mb-4 p-4 card">
      <h5>Average Service Cycle
        <span class="col-md-1 col-sm-1 col-2">
            <i class="fa fa-info-circle fa-lg" id="averageServiceCycle"></i>
            <b-popover
            target="averageServiceCycle"
            title="Average Service Cycle"
            triggers="hover focus"
            placement="top"
            :content="infoPopup.averageServiceCycle"
            ></b-popover>
        </span>
      </h5>
      <AverageServiceCycle
        v-if="filterUrl.ready"
        :filterUrl="filterUrl.url"
      ></AverageServiceCycle>
    </div>
    <div class="mb-4 p-4 card">
      <h5>H1 & H2 Comparison
        <span class="col-md-1 col-sm-1 col-2">
            <i class="fa fa-info-circle fa-lg" id="h1h2Comparison"></i>
            <b-popover
            target="h1h2Comparison"
            title="H1 & H2 Comparison"
            triggers="hover focus"
            placement="top"
            :content="infoPopup.h1h2Comparison"
            ></b-popover>
        </span>
      </h5>
      <Comparison
        :v-if="filterUrl.ready"
        :filterUrl="filterUrl.url"
      ></Comparison>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import filterComplete from '@/components/miscellaneous/filter-complete'

import AverageServiceCycle from './childComponents/AverageServiceCycle'
import Comparison from './childComponents/h1h2Comparison'

export default {
  name: 'service-cycle',
  metaInfo: {
    title: 'Service Cycle'
  },
  components: {
    filterComplete,
    AverageServiceCycle,
    Comparison
  },
  watch: {
    sampleData: function () {
      this.calcTotalPerHour()
    }
  },
  data () {
    return {
      infoPopup: this.$store.state.hoverInformation.h2.serviceCycle,
      averageServiceCycle: {
        "total": 100,
        "graph": [
          {
            "category": "New Customer",
            "value": 56
          },
          {
            "category": "<1 months",
            "value": 234
          },
          {
            "category": "1 - 2 months",
            "value": 75
          },
          {
            "category": "2 to 3 months",
            "value": 24
          },
          {
            "category": "3 to 4 months",
            "value": 35
          },
          {
            "category": "4 to 6 months",
            "value": 258
          },
          {
            "category": "No Record",
            "value": 100
          }
        ]
      },
      filterUrl : {
        initial : 0,
        ready : false,
        url : '',
      },
    }
  },
  mounted () {
    this.filterUrl.initial++;
  },
  methods: {
    setFilterUrl(urlString){
      this.filterUrl.url = urlString;
      this.filterUrl.ready = true;
    },
  }
}
</script>

<style scoped>
  .table-div{
      display: block;
      overflow: scroll;
  }
  .bold{
      font-weight: bold;
  }
  .no-data {
      text-align: center;
      /* width: 100%; */
  }
  .secondrow {
    margin-top: 20px;
  }
</style>
