<script>/* eslint-disable */</script>
<template>
    <div ref="AverageNumberOfServiceChart" class="h-100" style="min-height: 400px"></div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

export default {
    data () {
        return {
            chart: null,
        }
    },
    props:['data', 'filter'],
    mounted () {
        this.createChart()
        this.getChart()
    },
    watch : {
        'data' : {
            handler : 'getChart',
            deep : true
        }
    },
    methods: {
        getChart () {
          var length = this.chart.data.length;
          
          var data = this.data;
          
          this.chart.addData(data, length);
        },
        createChart () {
            this.chart = am4core.create(this.$refs.AverageNumberOfServiceChart, am4charts.XYChart);

            // Create axes
            let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = 'current_region';
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.minGridDistance = 30;
            categoryAxis.title.text = "Region";

            let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.title.text = "No of Mapping";
            valueAxis.min = 0;

            // Create series

            let series2 = this.chart.series.push(new am4charts.ColumnSeries());
            series2.dataFields.valueY = "count";
            series2.dataFields.categoryX = 'current_region';
            series2.clustered = false;
            series2.name = "Services";
            series2.columns.template.width = am4core.percent(50);
            series2.tooltipText = "Total Services of {categoryX}: [bold]{valueY}[/]";

            let series3 = this.chart.series.push(new am4charts.LineSeries());
            series3.dataFields.valueY = "target";
            series3.dataFields.categoryX = 'current_region';
            series3.strokeWidth = 2;
            series3.name = "Target";
            series3.tooltipText = "Target Services of {categoryX}: [bold]{valueY}[/]";
            series3.tooltipColorSource = am4core.color("#000")
            series3.tensionX = 0.8;
            series3.showOnInit = true;

            let interfaceColors = new am4core.InterfaceColorSet();

            let bullet = series3.bullets.push(new am4charts.Bullet());
            bullet.width = 12;
            bullet.height = 12;
            bullet.horizontalCenter = "middle";
            bullet.verticalCenter = "middle";
            
            let triangle = bullet.createChild(am4core.Triangle);
            triangle.stroke = interfaceColors.getFor("background");
            triangle.strokeWidth = 2;
            triangle.direction = "top";
            triangle.width = 12;
            triangle.height = 12;

            this.chart.cursor = new am4charts.XYCursor();
            this.chart.cursor.lineX.disabled = true;
            this.chart.cursor.lineY.disabled = true;

            let labelBullet = series2.bullets.push(new am4charts.LabelBullet())
            labelBullet.label.horizontalCenter = "middle";
            labelBullet.label.dy = -10;
            // labelBullet.label.text = "{values.valueX.workingValue.formatNumber('#.0as')}";
            labelBullet.label.text = "{values.valueY.workingValue}";
            labelBullet.locationY = 1;

            // Add legend
            this.chart.legend = new am4charts.Legend();
        }
    }
}
</script>