<script>/* eslint-disable */</script>
<template>
    <div ref="RegisteredByRegion" class="h-100" style="min-height: 400px"></div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

export default {
    data () {
        return {
            chart: null
        }
	},
	props: ['data'],
    mounted () {
		this.createChart()
		this.loadData()
    },
    methods: {
		loadData () {
			for(var i = 0 ; i < this.data.length ; i++) {
				if(!this.data[i].segment) this.data[i].segment  = "Unknown"
			}
			let length = this.chart.data.length
			this.chart.addData(this.data , length)
		},
        createChart () {
			this.chart = am4core.create(this.$refs.RegisteredByRegion, am4charts.XYChart);
			
			var gradient2 = new am4core.LinearGradient();
            gradient2.addColor(am4core.color("#ffafbd"));
            gradient2.addColor(am4core.color("#ffc3a0"));
			
            // Create axes
            let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "segment";
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.minGridDistance = 30;
            categoryAxis.title.text = "Segment"

            let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.title.text = "No of QR Registered";

            // Create series
            let series2 = this.chart.series.push(new am4charts.ColumnSeries());
            series2.dataFields.valueY = "count";
            series2.dataFields.categoryX = "segment";
            series2.clustered = false;
            series2.name = "Actual";
            series2.columns.template.width = am4core.percent(50);
            series2.tooltipText = "Actual QR Registered For {categoryX}: [bold]{valueY}[/]";
            series2.columns.template.fill = gradient2;
            series2.strokeWidth = 0;

            //  let series3 = this.chart.series.push(new am4charts.LineSeries());
            // series3.dataFields.valueY = "average";
            // series3.dataFields.categoryX = "segment";
            // series3.strokeWidth = 2;
            // series3.name = "Average Registered";
            // series3.tooltipText = "Average QR Registered For {categoryX}: [bold]{valueY}[/]";
            // series3.tooltipColorSource = am4core.color("#000")
            // series3.tensionX = 0.8;
            // series3.showOnInit = true;

            // let interfaceColors = new am4core.InterfaceColorSet();

            // let bullet = series3.bullets.push(new am4charts.Bullet());
            // bullet.width = 12;
            // bullet.height = 12;
            // bullet.horizontalCenter = "middle";
            // bullet.verticalCenter = "middle";
            
            // let triangle = bullet.createChild(am4core.Triangle);
            // triangle.stroke = interfaceColors.getFor("background");
            // triangle.strokeWidth = 2;
            // triangle.direction = "top";
            // triangle.width = 12;
            // triangle.height = 12;

            this.chart.cursor = new am4charts.XYCursor();
            // chart.cursor.lineX.disabled = true;
            // chart.cursor.lineY.disabled = true;

            // Add legend
            this.chart.legend = new am4charts.Legend();

            let labelBullet = series2.bullets.push(new am4charts.LabelBullet())
            labelBullet.label.horizontalCenter = "middle";
            labelBullet.label.dy = -10;
            labelBullet.label.text = "[bold]{values.valueY.workingValue}";
            labelBullet.locationY = 1;
        }
    }
}
</script>