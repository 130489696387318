<template>
    <div>

        <!-- Aging Stock Start -->
        <div style="padding: 1.7rem">
            <div class="row mb-3">
                <div class="col-md-6">
                    <h4>Aging Stock
                        <span>
                            <i class="fa fa-info-circle fa-sm" id="agingStock"></i>
                            <b-popover
                                target="agingStock"
                                title="Aging Stock"
                                triggers="hover focus"
                                placement="top"
                                :content="infoPopup.agingStock"
                            ></b-popover>
                        </span>
                    </h4>
                </div>
                <div class="col-md-6 mt-1">
                    <div class="row">
                        <div class="col text-right">
                            <Multiselect 
                                :options="options.agingStock.segment.map(x => x.segment)"
                                placeholder="All Segment"
                                class="multiselect-total-stock-analytics"      
                                v-model="filter.agingStock.segment"      
                            />
                        </div>
                        <div class="col text-right">
                            <Multiselect 
                                :options="options.agingStock.type.map(x => x.type)"
                                placeholder="All Type"      
                                class="multiselect-total-stock-analytics"     
                                v-model="filter.agingStock.type"  
                            />
                        </div>
                        <div class="col text-right">
                            <Multiselect 
                                :options="options.agingStock.color.map(x => x.color_code)"
                                :custom-label="opt => options.agingStock.color.find(x => x.color_code == opt).name"
                                placeholder="All Color"  
                                class="multiselect-total-stock-analytics"
                                v-model="filter.agingStock.color" 
                            />
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="row">
                <div class="col-md-8 pr-5">
                    <aging-stock-bar-chart
                        :data="agingStockData"
                    ></aging-stock-bar-chart>
                </div>
                <div class="col-md-4 pl-5" style="border-left: 0.5px solid #DBDBDB">
                    <aging-stock-pie-chart
                        :data="agingStockData"
                    ></aging-stock-pie-chart>
                </div>
            </div>

            <!-- Aging Stock Stats Start -->
            <aging-stock-stats
                v-if="data.agingStockStats"
                :data="data.agingStockStats"
            ></aging-stock-stats>
            <!-- Aging Stock Stats End -->

        </div>
        <!-- Aging Stock End -->

        <div class="hw--divider"></div>

        <!-- Aging Stock Detail Start -->
        <div style="padding: 1.7rem">
            <div class="row mb-3">
                <div class="col-md-4">
                    <h4>Detail Aging Stock
                        <span>
                            <i class="fa fa-info-circle fa-sm" id="detailAgingStock"></i>
                            <b-popover
                                target="detailAgingStock"
                                title="Detail Aging Stock"
                                triggers="hover focus"
                                placement="top"
                                :content="infoPopup.detailAgingStock"
                            ></b-popover>
                        </span>
                    </h4>
                </div>
                <div class="col-md-8 mt-1">
                    <div class="row">
                        <div class="col text-right">
                            <Multiselect 
                                :options="options.agingStockDetails.segment.map(x => x.segment)"
                                placeholder="All Segment"
                                class="multiselect-total-stock-analytics"      
                                v-model="filter.agingStockDetails.segment"      
                            />
                        </div>
                        <div class="col text-right">
                            <Multiselect 
                                :options="options.agingStockDetails.type.map(x => x.type)"
                                placeholder="All Type"      
                                class="multiselect-total-stock-analytics"     
                                v-model="filter.agingStockDetails.type"  
                            />
                        </div>
                        <div class="col text-right">
                            <Multiselect 
                                :options="options.agingStockDetails.color.map(x => x.color_code)"
                                :custom-label="opt => options.agingStockDetails.color.find(x => x.color_code == opt).name"
                                placeholder="All Color"  
                                class="multiselect-total-stock-analytics"
                                v-model="filter.agingStockDetails.color" 
                            />
                        </div>
                        <div class="col text-right">
                            <button
                                class="btn btn-outline-download"
                                @click="exportAgingStockDetails()"
                                style="height: 100%; width: 100%;"
                            >Download Detail&nbsp;&nbsp;<i class="fas fa-download"></i></button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Aging Stock Detail Table Start -->
            <aging-stock-detail
                :data="data.agingStockDetails"
            ></aging-stock-detail>
            <!-- Aging Stock Detail Table End -->

        </div>
        <!-- Aging Stock Detail End -->

    </div>
</template>

<script>
    import qs from 'qs'
    import XLSX from 'xlsx'
    import Multiselect from 'vue-multiselect'

    import agingStockBarChart from './charts/agingStockBarChart'
    import agingStockPieChart from './charts/agingStockPieChart'

    import agingStockStats from './children/agingStockStats'
    import agingStockDetail from './children/agingStockDetail'

    export default {
        data () {
            return {
                infoPopup : this.$store.state.hoverInformation.highlightWarehouse.stockAnalytics,

                options : {
                    agingStock : {
                        segment : this.$store.getters.getSegment,
                        type : this.$store.getters.getType(),
                        color : this.$store.getters.getColor
                    },
                    agingStockDetails : {
                        segment : this.$store.getters.getSegment,
                        type : this.$store.getters.getType(),
                        color : this.$store.getters.getColor
                    }
                },

                agingStockData : [],
            }
        },
        props : ['data', 'filter', 'periodFilter'],
        components : {
            Multiselect,

            agingStockBarChart,
            agingStockPieChart,
            agingStockStats,
            agingStockDetail
        },
        mounted () {
            this.convertAgingStockData();
        },
        watch : {
            //Initialize converting aging stock data
            'data.agingStock' : {
                deep : true,
                handler : 'convertAgingStockData'
            },

            //Aging Stock Stats have the same filter as Aging Stock
            'filter.agingStock' : {
                deep : true,
                handler () {
                    this.filter.agingStockStats = JSON.parse(JSON.stringify(this.filter.agingStock))
                }
            },

            //Search for the appropriate type for the selected segment
            'filter.agingStock.segment' () {
                this.options.agingStock.type = this.filter.agingStock.segment ? this.$store.getters.getSimpleType(this.filter.agingStock.segment) : this.$store.getters.getSimpleType();
                this.filter.agingStock.type = null;
            },
            //Search for the appropriate type for the selected segment
            'filter.agingStockDetails.segment' () {
                this.options.agingStockDetails.type = this.filter.agingStockDetails.segment ? this.$store.getters.getSimpleType(this.filter.agingStockDetails.segment) : this.$store.getters.getSimpleType();
                this.filter.agingStockDetails.type = null;
            },
        },
        methods : {
            convertAgingStockData () {
                // Test Data Sample
                // let testData = [
                //     {
                //         "total" : 9888,
                //         "month_1" : "0",
                //         "month_2" : "10250",
                //         "month_3" : "246",
                //         "month_3_6" : "0",
                //         "month_6" : "0"
                //     }
                // ]

                let data = this.data.agingStock[0];

                delete data.total;

                let final_data = [];

                for (var i in data) {
                    let labels = i.split("_")

                    final_data.push({
                        label : labels.length == 2 ? `>${labels[1]} ${this.capitalize(labels[0])}s` : `${labels[1]} - ${labels[2]} ${this.capitalize(labels[0])}s`,
                        count : parseInt(data[i])
                    })
                }
                this.agingStockData = final_data;
            },
            exportAgingStockDetails () {
                this.$http.get(`/api/highlight/warehouse/agingStockDetailsDownload${qs.stringify(this.filter.agingStockDetails, {skipNulls:true, addQueryPrefix:true})}`)
                .then((resp)=>{
                    this.exportFile(resp.data.data, `Aging Stock Detail ${this.periodFilter.start.split(" ")[0]} to ${this.periodFilter.end.split(" ")[0]}`)
                })
                .catch((err)=>{console.log(err)})
            },
            exportFile(data, name){
                let wb = XLSX.utils.book_new()
                var ws = XLSX.utils.json_to_sheet(data, {raw:true});
                XLSX.utils.book_append_sheet(wb, ws, 'Data')

                XLSX.writeFile(wb, `${name}.xlsx`)
            },
        }
    }
</script>

<style>

.multiselect__placeholder {
    font-size:12px;
    margin: 0;
}

.multiselect-total-stock-analytics > .multiselect__tags {
    border : 0px solid white;
    min-height: 36px;
}

.multiselect-total-stock-analytics > .multiselect__select {
    height: 36px;
}
</style>

<style scoped>


.multiselect-total-stock-analytics {
    min-height: 36px;
    box-shadow: 0px 2px 6px #0000001A;
    border-radius: 5px;
    font: normal normal 500 10px/11px Ubuntu;
    color: #676767;
}
</style>