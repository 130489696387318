<script>/* eslint-disable */</script>
<template>
    <div>
        <b-card title="Monthly QR Registration" :sub-title="infoPopup.title" header-tag="h5" class="mb-4">
            <hr>
            <b-form>
                <b-form-row>
                    <b-form-group label="Region" class="col-md-3">
                    <Multiselect 
                        v-if="filterData"
                        class="mt-1"
                        :options="filterData.regions"
                        label="name"
                        track-by="loc_code"
                        :multiple="true"
                        v-model="filter.region"
                        :close-on-select="false"
                        placeholder="All Regions"
                    />
                    </b-form-group>
                    <b-form-group label="Branch" class="col-md-3">
                    <Multiselect 
                        v-if="filterData"
                        class="mt-1"
                        :options="filterData.branch"
                        label="name"
                        track-by="loc_code"
                        :multiple="true"
                        v-model="filter.branch"
                        :close-on-select="false"
                        placeholder="All Branches"
                    />
                    </b-form-group>
                    <b-form-group label="Month" class="col-md-3" style="height:42px" >
                    <Multiselect 
                        class="mt-1"
                        :options="filterData.month"
                        v-model="filter.month"
                        label="name"
                        :close-on-select="false"
                        track-by="value"
                    />
                    </b-form-group>
                    <b-form-group label="Year" class="col-md-3" style="height:42px" >
                    <Multiselect 
                        class="mt-1"
                        :options="filterData.year"
                        :close-on-select="false"
                        v-model="filter.year"
                    />
                    </b-form-group>
                    </b-form-row>
                    <b-form-row class="mt-4">
                    <b-form-group label="Segment" class="col-md-3">
                    <Multiselect 
                        class="mt-1"
                        v-if="filterData"
                        :options="filterData.segment"
                        label="segment"
                        track-by="segment"
                        :multiple="true"
                        v-model="filter.segment"
                        :close-on-select="false"
                        placeholder="All Segments"
                    />
                    </b-form-group>
                    <b-form-group label="Series" class="col-md-3">
                    <Multiselect 
                        class="mt-1"
                        v-if="filterData"
                        :options="filterData.series"
                        label="series"
                        track-by="series"
                        :multiple="true"
                        v-model="filter.series"
                        :close-on-select="false"
                        placeholder="All Series"
                    />
                    </b-form-group>
                    <b-form-group label="Type" class="col-md-3">
                    <Multiselect 
                        class="mt-1"
                        v-if="filterData"
                        :options="filterData.type"
                        label="type"
                        track-by="type"
                        :multiple="true"
                        v-model="filter.type"
                        :close-on-select="false"
                        placeholder="All Vehicle Types"
                    />
                    </b-form-group>
                    <b-form-group label="Colour" class="col-md-3">
                    <Multiselect 
                        class="mt-1"
                        v-if="filterData"
                        :options="filterData.colour"
                        v-model="filter.colour"
                        label="name"
                        track-by="color_code"
                        :multiple="true"
                        :close-on-select="false"
                        placeholder="All Colours"
                    />
                    </b-form-group>
                </b-form-row>
                <b-form-row>
                    <b-form-group class="col-md-2">
                        <b-btn type="button" variant="primary" @click="search" style="width: 100%;">Filter</b-btn>
                    </b-form-group>
                    <b-form-group class="col-md-2">
                        <b-btn type="button" variant="success" @click="downloadExcel" style="width: 100%;">Download XLSX</b-btn>
                    </b-form-group>
                </b-form-row>
            </b-form>
        </b-card>
        <div class="row">
            <div class="col-12 mb-2">
                <div style="background-color:black;color:white;padding:0.5%">
                    <div class="row">
                        <h3 style="margin:auto;" class="col-md-4">VEHICLE MAPPING
                            <span class="col-md-1 col-sm-1 col-2">
                                <i class="fa fa-info-circle fa" id="vehicleMapping"></i>
                                <b-popover
                                target="vehicleMapping"
                                title="Vehicle Mapping"
                                triggers="hover focus"
                                placement="top"
                                :content="infoPopup.vehicleMapping"
                                ></b-popover>
                            </span>
                        </h3>
                        <div class="col-md-3 offset-md-5" >
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <b-card no-body class="mb-4">
                    <!-- <b-card-header header-tag="h6" class="with-elements border-0 pr-0 pb-0">
                        <div class="card-header-title">Vehicle Mapping <br>(1 August 2019 to 10 September 2019 - All Warehouse)</div>
                    </b-card-header> -->
                    <div>
                        <IncomingVehicle v-if="data" :data="data" :filter="filter"></IncomingVehicle>
                    </div>
                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
import IncomingVehicle from './childComponents/IncomingVehicle/IncomingVehicle'
import Multiselect  from 'vue-multiselect'
import moment, { months } from 'moment'
export default {
    components: {
        IncomingVehicle,
        Multiselect 
    },
    data() {
        return {
            filterData: {
                regions: [],
                branch: [],
                segment: [],
                series: [],
                type: [],
                colour: [],
                month: [],
                year: []
            },
            filter: {
                region: [],
                branch: [],
                segment: [],
                series: [],
                type: [],
                colour: [],
                month:  {
                    name: new moment().format("MMMM"),
                    value: new moment().format("M")
                },
                year:  new moment().format("YYYY")
            },
            data: null,
            infoPopup: this.$store.state.hoverInformation.h1.qrRegistration.monthlyQrRegistration
        }
    },
    mounted () {
        this.loadFilter(this.filter, '')
        this.search()
    },
    watch: {
        'filter.region' : function() {
        let region = []
        for(var i = 0 ; i < this.filter.region.length ; i++){
            region.push(this.filter.region[i].loc_code)
        }
        this.loadFilter('region' , region)
        },
        'filter.segment' : function () {
        let segment = []
        for(var i = 0 ; i < this.filter.segment.length ; i++){
            segment.push(this.filter.segment[i].segment)
        }
        this.loadFilter('segment' , segment)
        },
        'filter.series' : function () {
        let series = []
        for(var i = 0 ; i < this.filter.series.length ; i++){
            series.push(this.filter.series[i].series)
        }
        this.loadFilter('series' , series)
        }
    },
    methods: {
        async search () {
            let filter = await this.transformFilter(this.filter)
            filter = await this.initializeFilter(filter)
            this.loadAPI(filter)
        },
        loadAPI (filter) {
            this.data = null
            let url = "/api/h1/monthly-qr-registration/vehicle-mapping" + filter

            this.$http.get(url)
            .then((resp) => {
                this.data = resp.data.data;                
            })
            .catch((err) => {
                console.log(err);
            })
        },
        async initializeFilter (filters) {
            let filterUrl = '?'
            let keys = Object.keys(filters)
            for (var i = 0; i < keys.length; i++) {
                if(typeof filters[keys[i]] == 'object' && filters[keys[i]].length > 0)
                    filterUrl += `${keys[i]}=${filters[keys[i]]}&`
                else if (typeof filters[keys[i]] != 'object') {
                    filterUrl += `${keys[i]}=${filters[keys[i]]}&`
                }
            }
            
            return filterUrl
        },
        async transformFilter (filter) {
            let obj = {}
            let keys = Object.keys(filter)
            for (var i = 0 ; i < keys.length; i++) {
                if (keys[i] != 'month' && keys[i] != 'year') {
                    obj[keys[i]] = []
                    for (var j = 0; j < filter[keys[i]].length; j++) {
                        keys[i] == 'region' || keys[i] == 'branch' ? obj[keys[i]].push(filter[keys[i]][j].loc_code) : obj[keys[i]].push(filter[keys[i]][j][keys[i].toLowerCase()])
                    }
                } else if (keys[i] == 'month') {
                    obj[keys[i]] = filter[keys[i]].value
                } else {
                    obj[keys[i]] = filter[keys[i]]
                }
            }
            return obj
        },
        loadFilter (type , data) {
            this.filterData = Object.assign({} , this.$store.state.filterData)
            if (type == 'region') {
                this.filterData.branch = Object.assign([] , this.$store.state.filterData.branch)
                this.filter.branch.length = 0
                if(this.filter.region.length == 0){
                return;
                } 
                let branch = []
                for(var i = 0 ; i < this.filterData.branch.length; i++){
                if(data.includes(this.filterData.branch[i].region_code))
                    branch.push(this.filterData.branch[i])
                }

                this.filterData.branch = branch
            }
            
            if (type == 'segment') {
                this.filter.series.length = 0
                this.filter.type.length = 0
                this.filterData.series = Object.assign([] , this.$store.state.filterData.series)
                this.filterData.type = Object.assign([] , this.$store.state.filterData.type)
                if(this.filter.segment.length == 0){
                
                return;
                } 
                let series = []
                let type = []
                for(var i = 0 ; i < this.filterData.series.length; i++){
                if(data.includes(this.filterData.series[i].segment))
                    series.push(this.filterData.series[i])
                }

                for(var i = 0 ; i < this.filterData.type.length; i++){
                if(data.includes(this.filterData.type[i].segment))
                    type.push(this.filterData.type[i])
                }

                this.filterData.series = series
                this.filterData.type = type
            }

            if (type == 'series') {
                this.filterData.type = Object.assign([] , this.$store.state.filterData.type)
                this.filter.type.length = 0
                if(this.filter.series.length == 0){
                return;
                } 
                let type = []
                for(var i = 0 ; i < this.filterData.type.length; i++){
                if(data.includes(this.filterData.type[i].series))
                    type.push(this.filterData.type[i])
                }
                this.filterData.type = type
            }
        },
        async downloadExcel () {
            let filter = await this.transformFilter(this.filter);

            let dateFilter = moment().set({year:filter.year, month:filter.month-1});
            delete filter.month;
            delete filter.year;

            filter.start = dateFilter.startOf('month').format('YYYY-MM-DD');
             filter.end = dateFilter.endOf('month').format('YYYY-MM-DD');

            this.downloadQrRegistration(filter, 'Monthly QR Registration', false);
        },
    }
}
</script>