<script>/* eslint-disable */</script>
<template>
  <div :class="{'mobileHeight' : mobile, 'nonMobileHeight' : !mobile}">
    <h2 class="mt-2 w-100" style="text-align: center;">Vehicle Tracking Map
      <span class="col-md-1 col-sm-1 col-2 mt-2">
          <i class="fa fa-info-circle" id="vehicleTrackingMap"></i>
          <b-popover
            target="vehicleTrackingMap"
            title="Vehicle Tracking Map"
            triggers="hover focus"
            placement="top"
            :content="popUp"
          ></b-popover>
        </span>
    </h2>

    <div ref="timelineChart" style="min-height:100%" ></div>
  </div>
</template>

<script>

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4plugins_timeline from "@amcharts/amcharts4/plugins/timeline"; 
import * as am4plugins_bullets from "@amcharts/amcharts4/plugins/bullets"; 

am4core.useTheme(am4themes_animated);


export default {
  data(){
      return {
          eventSeries : null,
          chart : null
      }
  },
  props : ['data', 'mobile', 'popUp'],
  async mounted(){
      await this.createChart();
      this.getData();
  },
  methods : {
      getData(){
		let length = this.eventSeries.data.length
        var data = this.data;

        let chartData = [];
        var tzoffset = (new Date()).getTimezoneOffset() * 60000;

        for(var i = 0 ; i < data.length ; i++){

          data[i].seq = i+1;
          var date = new Date(data[i].scan_date)

          let obj = {
            category : 'Vehicle Timeline',
            eventDate : date,
            letter : data[i].status,
            description : new Date(date - tzoffset).toISOString().slice(0,10) + " - Sequence Number " + data[i].seq + " : " + data[i].status + " " + (data[i].current_loc_code)
		  }
          
          chartData.push(obj);
        }
		console.log(chartData)
        this.eventSeries.addData(chartData , length);

      },
      createChart(){
          this.chart = am4core.create(this.$refs.timelineChart, am4plugins_timeline.SerpentineChart);
          this.chart.curveContainer.padding(50, 20, 50, 20);
          this.chart.levelCount = 4;
          this.chart.yAxisRadius = am4core.percent(25);
          this.chart.yAxisInnerRadius = am4core.percent(-25);
          this.chart.maskBullets = false;

          let colorSet = new am4core.ColorSet();
          colorSet.saturation = 0.5;

          this.chart.data = [{
              "category": "Vehicle Timeline",
          }];

          this.chart.dateFormatter.dateFormat = "yyyy-MM-dd";
          this.chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";
          this.chart.fontSize = 11;

          let categoryAxis = this.chart.yAxes.push(new am4charts.CategoryAxis());
          categoryAxis.dataFields.category = "category";
          categoryAxis.renderer.grid.template.disabled = true;
          categoryAxis.renderer.labels.template.paddingRight = 25;
          categoryAxis.renderer.minGridDistance = 10;
          categoryAxis.renderer.innerRadius = -60;
          categoryAxis.renderer.radius = 60;

          let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
          dateAxis.renderer.minGridDistance = 70;
          dateAxis.baseInterval = { count: 1, timeUnit: "hour" };
          dateAxis.renderer.tooltipLocation = 0;
          dateAxis.startLocation = -0.5;
          dateAxis.renderer.line.strokeDasharray = "1,4";
          dateAxis.renderer.line.strokeOpacity = 0.6;
          dateAxis.tooltip.background.fillOpacity = 0.2;
          dateAxis.tooltip.background.cornerRadius = 5;
          dateAxis.tooltip.label.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
          dateAxis.tooltip.label.paddingTop = 7;

          let labelTemplate = dateAxis.renderer.labels.template;
          labelTemplate.verticalCenter = "middle";
          labelTemplate.fillOpacity = 0.7;
          labelTemplate.background.fill = new am4core.InterfaceColorSet().getFor("background");
          labelTemplate.background.fillOpacity = 1;
          labelTemplate.padding(7, 7, 7, 7);

          let series = this.chart.series.push(new am4plugins_timeline.CurveColumnSeries());
          series.columns.template.height = am4core.percent(20);
          series.columns.template.tooltipText = "{task}: [bold]{openDateX}[/] - [bold]{dateX}[/]";

          series.dataFields.openDateX = "start";
          series.dataFields.dateX = "end";
          series.dataFields.categoryY = "category";
          series.columns.template.propertyFields.fill = "color"; // get color from data
          series.columns.template.propertyFields.stroke = "color";
          series.columns.template.strokeOpacity = 0;

          let bullet = series.bullets.push(new am4charts.CircleBullet());
          bullet.circle.radius = 3;
          bullet.circle.strokeOpacity = 0;
          bullet.propertyFields.fill = "color";
          bullet.locationX = 0;


          let bullet2 = series.bullets.push(new am4charts.CircleBullet());
          bullet2.circle.radius = 3;
          bullet2.circle.strokeOpacity = 0;
          bullet2.propertyFields.fill = "color";
          bullet2.locationX = 1;

          let imageBullet1 = series.bullets.push(new am4plugins_bullets.PinBullet());
          imageBullet1.disabled = true;
          imageBullet1.propertyFields.disabled = "disabled1";
          imageBullet1.locationX = 1;
          imageBullet1.circle.radius = 20;
          imageBullet1.propertyFields.stroke = "color";
          imageBullet1.background.propertyFields.fill = "color";
          imageBullet1.image = new am4core.Image();
          imageBullet1.image.propertyFields.href = "image1";

          let imageBullet2 = series.bullets.push(new am4plugins_bullets.PinBullet());
          imageBullet2.disabled = true;
          imageBullet2.propertyFields.disabled = "disabled2";
          imageBullet2.locationX = 0;
          imageBullet2.circle.radius = 20;
          imageBullet2.propertyFields.stroke = "color";
          imageBullet2.background.propertyFields.fill = "color";
          imageBullet2.image = new am4core.Image();
          imageBullet2.image.propertyFields.href = "image2";


          this.eventSeries = this.chart.series.push(new am4plugins_timeline.CurveLineSeries());
          this.eventSeries.dataFields.dateX = "eventDate";
          this.eventSeries.dataFields.categoryY = "category";
          this.eventSeries.strokeOpacity = 0;

          let flagBullet = this.eventSeries.bullets.push(new am4plugins_bullets.FlagBullet())
          flagBullet.label.propertyFields.text = "letter";
          flagBullet.locationX = 0;
          flagBullet.tooltipText = "{description}";

          this.chart.scrollbarX = new am4core.Scrollbar();
          this.chart.scrollbarX.align = "center"
          this.chart.scrollbarX.width = am4core.percent(85);

          let cursor = new am4plugins_timeline.CurveCursor();
          this.chart.cursor = cursor;
          cursor.xAxis = dateAxis;
          cursor.yAxis = categoryAxis;
          cursor.lineY.disabled = true;
          cursor.lineX.strokeDasharray = "1,4";
          cursor.lineX.strokeOpacity = 1;

          dateAxis.renderer.tooltipLocation2 = 0;
          categoryAxis.cursorTooltipEnabled = false;

      }
  }
}
</script>

<style>
  .mobileHeight{
	min-height: 65vh;
	height:100%;
  }
  .nonMobileHeight{
    height: 95%;
  }
</style>