/* eslint-disable */
import createPersistedState from 'vuex-persistedstate';

import userData from './modules/userData'
import company from './modules/company'
import filterOptions from './modules/filterOptions'

//initiliase store for Vuex
const store  = {
  plugins: [
    createPersistedState({})
  ],
  state : {
    filterUrl: '',
    logInfo: null,
    updateData: 0,
    filterData: {
      regions: null,
      branch: null,
      segment: null,
      series: null,
      type: null,
      colour: null,
      month: null,
      year: null,
    },
    modules : null,
  },
  modules : {
    userData,
    company,
    filterOptions
  }
};

export default store;