<template>
  <div>
    <h3 class='text-center mt-2'>Aging Time By Segments
      <span class="col-md-1 col-sm-1 col-2">
          <i class="fa fa-info-circle fa" id="agingTimeBySegments"></i>
          <b-popover
          target="agingTimeBySegments"
          title="Aging Time By Segments"
          triggers="hover focus"
          placement="top"
          :content="infoPopup.agingTimeBySegments"
          ></b-popover>
      </span>
    </h3>
    <table class='table mt-3 ' >
      <thead class='thead-dark'>
        <th>Segment Type</th>
        <th>Less than 1 Day</th>
        <th>1 to 3 Days</th>
        <th>4 to 7 Days</th>
        <th>8 to 14 Days</th>
        <th>15 to 30 Days</th>
        <th>More than 1 Month</th>
        <th>Total</th>
      </thead>
      <tbody>
        <tr  v-for='(data ,index) in datas' :key='data.segment'>
          <td>{{data.segment ? data.segment : 'Unknown'}}</td>
          <template v-for='tableHeader in tableHeaders' >
            <td :key='tableHeader' :style="{'background-color' : `rgba(0 , 214, 203 , ${gradient[index][tableHeader]})`}">{{numberWithCommas(data[tableHeader])}}</td>
          </template>
          <td>{{numberWithCommas(data.total)}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ['datas'],
  data () {
    return {
      tableHeaders: ['under_1_day', 'day_1_3', 'day_4_7', 'day_8_14', 'day_15_30', 'month_1'],
      gradient: [],
      infoPopup: this.$store.state.hoverInformation.h1.stockDetails.agingStock
    }
  },
  mounted () {
    this.getGradient()
  },
  watch: {
    '$store.state.updateData': function () {
      this.getGradient()
    }
  },
  methods: {
    getGradient () {
      this.gradient.length = 0
      for (var i = 0; i < this.datas.length; i++) {
        let obj = {}
        let max = 0
        let maxHeader = ''
        for (var j = 0; j < this.tableHeaders.length; j++) {
          this.datas[i][this.tableHeaders[j]] =  parseInt(this.datas[i][this.tableHeaders[j]])
          obj[this.tableHeaders[j]] = Math.round(this.datas[i][this.tableHeaders[j]] / this.datas[i].total * 100) / 100
          if (max < obj[this.tableHeaders[j]]) {
            max = obj[this.tableHeaders[j]]
            maxHeader = this.tableHeaders[j]
          }
        }
        obj[maxHeader] = 1
        this.gradient.push(obj)
        console.clear()
      }
    },
    numberWithCommas(x) {
      return parseInt(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  }
}
</script>

<style scoped>
    th , td{
        text-align: center
    }
    .highlight {
        background-color: #e4ffad
    }
</style>
