<template>
  <div>
    <div class="row mt-3 mb-2">
      <div class="col-md-4">
        <Multiselect
          v-model="monthChosen"
          :options="options.timeChoices"
          track-by="value"
          label="label"
          :allow-empty="false"
          :multiple="false"
        ></Multiselect>
      </div>
      <div class="col-md-4 text-center">
        <h3 class="mb-0">Details by {{ capitalize(type) }}</h3>
        <p v-if="type == 'dealer'">Top 10 Dealer</p>
      </div>
      <div class="col-md-4 text-right">
        <button class="btn btn-sm btn-primary" @click="exportExcel">Export To Excel</button>
      </div>
    </div>
    <div class="row">
      <div class="col" style="overflow-x: auto;">
        <table class="table w-100" v-if="data">
          <thead>
            <tr>
              <th rowspan="2" class="text-center" style="vertical-align: middle;">{{ capitalize(type) }} Code</th>
              <th :colspan="maxDate" class="text-center">Date</th>
              <th rowspan="2" class="text-center" style="vertical-align: middle;">Total</th>
            </tr>
            <tr>
              <th v-for="i in maxDate" class="text-center">{{ i }}</th>
            </tr>
          </thead>
          <tbody style="max-height:300px;">
            <tr v-for="(dat, index) in dataList">
              <td>{{ index }}</td>
              <td v-for="da in dat" class="text-center">{{ da.count }}</td>
              <!-- <td>{{ dat.date }}</td>
              <td>{{ dat.count }}</td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import XLSX from 'xlsx'
  import Multiselect  from 'vue-multiselect'

  export default {
    data () {
      return {
        options : {
          timeChoices : [],
        },
        monthChosen : null,
        maxDate : 30,
        data : [],
        rawData : [],
      }
    },
    props : ['filterUrl', 'time_choices', 'type'],
    components : {
      Multiselect,
    },
    mounted () {
      this.fetchData()
    },
    watch : {
      'filterUrl' : function () {
        this.fetchData()
      },
      'time_choices' : {
        handler () {
          // console.log(JSON.stringify(this.time_choices.map(x=>x.value)));
          if (this.monthChosen == '') this.monthChosen = this.time_choices[0]
          else if (this.time_choices.map(x=>x.value).indexOf(this.monthChosen) === -1) this.monthChosen = this.time_choices[0]
          this.options.timeChoices = this.time_choices
        },
        immediate:true
      },
      'monthChosen' : function () {
        this.fetchData();
      },
    },
    computed : {
      //Create TOP 10 For Details By Dealer
      dataList(){
        var keys = Object.keys(this.data);
        //Direct return when data is less than 10 or is region (not dealer)
        if(keys.length <= 10 || this.type == 'region') return this.data;
        
        var final = {};
        for (var i = 0; i < 10; i++){
          final[keys[i]] = this.data[keys[i]];
        }
        return final;
      }
    },
    methods : {
      fetchData(){
        this.$http.get(`/api/h2/qr-registration/table${this.type == 'dealer' ? '-dealer' : ''}?` + this.filterUrl + `&month=${this.monthChosen.month}&year=${this.monthChosen.year}`)
        .then((resp)=>{
          var data = resp.data.data
          for (var i in data) {
            data[i].date = new Date(data[i].date)
          }
          this.rawData = data;
          this.data = this.splitRegion(data);
          // this.data = this.splitRegion(data);
          // console.log(JSON.stringify(this.data));
        })
        .then(()=>{
          this.addTotalCount();
        })
        .catch((err)=>{console.log(err);})
      },
      exportExcel() {
        let wb = XLSX.utils.book_new()
        let exportFileName = `QR Registration By Date - ${this.capitalize(this.type)}.xlsx`
        var ws = XLSX.utils.json_to_sheet(this.rawData, {raw:true});
        XLSX.utils.book_append_sheet(wb, ws, 'Data')
        XLSX.writeFile(wb, exportFileName)
      },
      // splitGroupingDate(data) {
      //   var obj = {}
      //   data.sort((a, b) =>  (b.count)- (a.count));
      //   for(var i = 0 ; i < data.length ; i++) {
      //     var groupingDate = `${data[i].date.getFullYear()}-${data[i].date.getMonth()}`
      //     if(!obj[groupingDate]) obj[groupingDate] = []

      //     obj[groupingDate].push(data[i])
      //   }
      //   return obj
      // },
      splitRegion(data) {
        var obj = {}
        // data.sort((a, b) =>  (b.count)- (a.count));
        if (this.type == 'dealer'){
          for(var i = 0 ; i < data.length ; i++) {
            if(!obj[data[i].dealer]) obj[data[i].dealer] = []

            obj[data[i].dealer].push(data[i])
          }
        } else {
          for(var i = 0 ; i < data.length ; i++) {
            if(!obj[data[i].region_code]) obj[data[i].region_code] = []

            obj[data[i].region_code].push(data[i])
          }
        }

        //Max Date
        // console.log(Object.keys(obj)[0]);
        this.maxDate = obj[Object.keys(obj)[0]].length;

        return obj
      },
      addTotalCount() {

        //Return when data is empty
        if (this.data == null) return;

        let totalDate = [];
        for (var i = 0; i < this.maxDate; i++) totalDate.push({count: 0})

        //Add total count per date
        for (var i in this.data) {//Keys (region / dealer)
          for (var j in this.data[i]) {
            totalDate[j].count += this.data[i][j].count;
          }
        }
        this.data['Total'] = totalDate

        let data = JSON.parse(JSON.stringify(this.data));

        //Add total count per region
        for (var i in data) {//Keys (region / dealer)
          let total = 0;
          for (var j in data[i]) {
            total += data[i][j].count;
          }

          let pushTotal = {
            count: total,
            date: "Total"
          }
          pushTotal[this.type=="dealer" ? "dealer" : "region_code"] = i;

          this.data[i].push(pushTotal)
        }
      },
    }
  }
</script>

<style scoped>

</style>