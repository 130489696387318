<template>
	<b-card header="Profile" header-tag="h5" class="mb-4">
		<div class="row">
			<div class="col-md-4 col-12 rightbgblack Aligner">
				<img src='@/assets/img/Profile/noPicUser.png'/>
			</div>
			<div class="col-md-8 col-12">
				 <p style="background-color:#FFB6C1;font-weight:bold;color:black;" class="p-2" v-if="error != ''">{{error}}</p>
				 <b-form>
					<b-form-row>
						<b-form-group label="Name" class="col-md-12 dateCss" style="height:42px" >
							<div class="row">
								<div class="col-12">
									<b-form-input v-model="userData.fullname" />
								</div>
							</div>

						</b-form-group>
					</b-form-row>
					<hr>
					<b-form-row>
						<b-form-group label="Old Password" class="col-md-12 dateCss" style="height:42px" >
							<div class="row">
								<div class="col-12">
									<b-form-input type="password" v-model="userData.old_pass" />
								</div>
							</div>

						</b-form-group>
					</b-form-row>
					<b-form-row class="mt-2">
						<b-form-group label="New Password" class="col-md-12 dateCss" style="height:42px" >
							<div class="row">
								<div class="col-12">
									<b-form-input v-model="userData.new_pass" />
								</div>
							</div>

						</b-form-group>
					</b-form-row>
					<b-form-row class="mt-2">
						<b-form-group label="Re-Enter Password" class="col-md-12 dateCss" style="height:42px" >
							<div class="row">
								<div class="col-12">
									<b-form-input v-model="userData.new_pass_copy" />
								</div>
							</div>

						</b-form-group>
					</b-form-row>
					<b-btn class="col-md-4 mt-4" type="button" variant="primary" @click="updateUserData()">Update Data</b-btn>
				</b-form>
			</div>
		</div>
    </b-card>
</template>

<script>
export default {
	data () {
		return {
			userData: Object.assign({}, this.$store.state.userData.userData),
			error: ''
		}
	},
	methods: {
		updateUserData () {
			if (!this.userData.old_pass && this.userData_new_pass && this.userData.new_pass_copy) {
				this.error = 'Please input old password field before submitting'
				return;
			}

			if (this.userData.new_pass != this.userData.new_pass_copy) {
				this.error = 'New Password and Re-Enter Password is not the same'
			}

			this.error = ''
			let usrObj = {
				name: this.userData.fullname,
				new_pass: this.userData.new_pass,
				old_pass: this.userData.old_pass
			}

			
			this.$http.post('/api/auth/update-profile', usrObj)
			.then((resp) => {
				alert("Update Profile Succesfully")
				this.$store.state.userData.userData.fullname = usrObj.name
				this.$router.push({name : 'Highlight'})
			})
			.catch((err) => {
				this.error = err;
			})
			
		}
	}
}
</script>

<style scoped>
	@media only screen and (min-device-width: 900px) {
        .rightbgblack {
			border-right : 1px solid grey;
		}
    }

	.Aligner {
        display: flex;
        align-items: center;
        justify-content: center;
    }
	
</style>

