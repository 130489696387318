<template>
    <div class='w-100 bg-white border border-secondary rounded' style='min-height:500px'>
        <div class='bg-black'>
        <h3 class='text-right pt-2 mr-4' style='color:white'>Newly Registered QR Code</h3>
        <hr>
        </div>
        <div ref='totalQRRegisteredByVehicleType' style='height:425px'></div>
    </div>
</template>

<script>
/* eslint indent: 0 */
/* eslint camelcase: 0 */
/* eslint eqeqeq: 0 */
/* eslint no-debugger: 0 */
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

am4core.useTheme(am4themes_animated)
export default {
    props: ['data', 'isUpdate'],
    data () {
        return {
            chart: null,
            image: null,
            route: null
        }
    },
    mounted () {
        this.createChart()
        this.loadData()
    },
    watch: {
        isUpdate: function () {
            this.loadData()
        }
    },
    methods: {
        loadData () {
            console.log(this.data)
            var length = this.chart.data.length
            for (var i = 0; i < this.data.length; i++) {
                if (this.data[i].type == null) this.data[i].type = 'Unknown'
            }
            this.chart.addData(this.data, length)
        },
        createChart () {
            this.chart = am4core.create(this.$refs.totalQRRegisteredByVehicleType, am4charts.XYChart)
            this.chart.hiddenState.properties.opacity = 0 // this creates initial fade-in

            this.chart.paddingBottom = 30

            let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis())
            categoryAxis.dataFields.category = 'type'
            // categoryAxis.renderer.grid.template.strokeOpacity = 0
            categoryAxis.renderer.minGridDistance = 30
            categoryAxis.renderer.labels.template.dy = 35
            categoryAxis.renderer.tooltip.dy = 35

            let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis())
            valueAxis.renderer.inside = true
            valueAxis.renderer.labels.template.fillOpacity = 0.3
            valueAxis.renderer.grid.template.strokeOpacity = 0
            valueAxis.min = 0
            valueAxis.cursorTooltipEnabled = false
            // valueAxis.renderer.baseGrid.strokeOpacity = 0

            let series = this.chart.series.push(new am4charts.ColumnSeries())
            series.dataFields.valueY = 'sum'
            series.dataFields.categoryX = 'type'
            series.tooltipText = '{valueY.value}'
            series.tooltip.pointerOrientation = 'vertical'
            series.tooltip.dy = -6
            series.columnsContainer.zIndex = 100

            let columnTemplate = series.columns.template
            columnTemplate.width = am4core.percent(50)
            columnTemplate.maxWidth = 66
            columnTemplate.column.cornerRadius(60, 60, 10, 10)
            columnTemplate.strokeOpacity = 0

            series.heatRules.push({ target: columnTemplate, property: 'fill', dataField: 'valueY', min: am4core.color('#40bfc1'), max: am4core.color('#f0134d') })
            series.mainContainer.mask = undefined

            let cursor = new am4charts.XYCursor()
            this.chart.cursor = cursor
            cursor.lineX.disabled = true
            cursor.lineY.disabled = true
            cursor.behavior = 'none'

            let bullet = columnTemplate.createChild(am4charts.CircleBullet)
            bullet.circle.radius = 30
            bullet.valign = 'bottom'
            bullet.align = 'center'
            bullet.isMeasured = true
            bullet.mouseEnabled = false
            bullet.verticalCenter = 'bottom'
            bullet.interactionsEnabled = false

            // let hoverState = bullet.states.create('hover')
            let outlineCircle = bullet.createChild(am4core.Circle)
            outlineCircle.adapter.add('radius', function (radius, target) {
                let circleBullet = target.parent
                return circleBullet.circle.pixelRadius + 10
            })

            let image = bullet.createChild(am4core.Image)
            image.width = 60
            image.height = 60
            image.horizontalCenter = 'middle'
            image.verticalCenter = 'middle'
            image.propertyFields.href = 'href'

            image.adapter.add('mask', function (mask, target) {
                let circleBullet = target.parent
                return circleBullet.circle
            })

            let previousBullet
            this.chart.cursor.events.on('cursorpositionchanged', function (event) {
                let dataItem = series.tooltipDataItem

                if (dataItem.column) {
                    let bullet = dataItem.column.children.getIndex(1)

                    if (previousBullet && previousBullet != bullet) {
                        previousBullet.isHover = false
                    }

                    if (previousBullet != bullet) {
                        let hs = bullet.states.getKey('hover')
                        hs.properties.dy = -bullet.parent.pixelHeight + 30
                        bullet.isHover = true
                        previousBullet = bullet
                    }
                }
            })
        }
    }
}
</script>
