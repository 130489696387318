<script>/* eslint-disable */</script>
<template>
    <div>
        <!--Filter Option-->
        <b-card title="QR Code" :sub-title="infoPopup.title" header-tag="h6" class="mb-4">
            <hr>
            <b-form>
                <b-form-row>
                    <b-form-group label="Search By" class="col-md-6">
                       <b-input-group>
                            <b-select v-model="filter.search_by" :options="filterOptions" />
                            <b-input placeholder="Search for..." v-model="filter.keyword" />
                        </b-input-group>
                    </b-form-group>
                    <b-form-group label="Status" class="col-md-6">
                        <Multiselect 
                            class="mt-1"
                            :options="['ACTIVE' , 'INACTIVE' , 'DELETED']"
                            :multiple="false"
                            v-model="filter.status"
                            placeholder="All Status"
                        />
                    </b-form-group>
                </b-form-row>
                <b-btn class="col-md-4" type="button" variant="primary" @click="search(false)" :disabled="isFetching"><span class="fa fa-filter"></span>&nbsp;&nbsp;Search</b-btn>
                <!-- <b-btn class="col-md-4" type="button" variant="success" @click="showExcelModal = true"><span class="fa fa-file-excel"></span>&nbsp;&nbsp;Export To Excel</b-btn> -->
            </b-form>
        </b-card>
        <b-card style="overflow-x: auto;">
            <!-- <div class="row">
					<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 stats-outer-box">
						<div class="stats-inner-box" style="background-color: #F59C19;">
							<div class="row" style="height: 100%">
								<div class="col-8">
								<p>Total QR Code Active
                                    <span class="col-md-1 col-sm-1 col-2 mt-2">
                                        <i class="fa fa-info-circle" id="total-qr-active"></i>
                                        <b-popover
                                            target="total-qr-active"
                                            title="Total QR Code Active"
                                            triggers="hover focus"
                                            placement="top"
                                            :content="infoPopup.stats.totalActive"
                                        ></b-popover>
                                    </span>
                                </p>
								<p class="stats-value" v-if="statsTotal">
									{{numberWithCommas(statsTotal.active)}}
								</p>
								<p class="stats-value" v-else>
									Loading.....
								</p>
								</div>
								<div class="col-4 icon">
								<i class="fas fa-qrcode"></i>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 stats-outer-box">
						<div class="stats-inner-box" style="background-color:#DC143C">
							<div class="row" style="height: 100%">
								<div class="col-8">
								<p>Total QR Code InActive
                                    <span class="col-md-1 col-sm-1 col-2 mt-2">
                                        <i class="fa fa-info-circle" id="total-qr-inactive"></i>
                                        <b-popover
                                            target="total-qr-inactive"
                                            title="Total QR Code InActive"
                                            triggers="hover focus"
                                            placement="top"
                                            :content="infoPopup.stats.totalInActive"
                                        ></b-popover>
                                    </span>
                                </p>
								<p class="stats-value" v-if="statsTotal">
									{{numberWithCommas(statsTotal.inactive)}}
								</p>
								<p class="stats-value" v-else>
									Loading.....
								</p>
								</div>
								<div class="col-4 icon">
								<i class="fas fa-qrcode"></i>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 stats-outer-box">
						<div class="stats-inner-box" style="background-color: #BA55D3;">
							<div class="row" style="height: 100%">
								<div class="col-8">
								<p>Total QR Code Delete
                                    <span class="col-md-1 col-sm-1 col-2 mt-2">
                                        <i class="fa fa-info-circle" id="total-qr-delete"></i>
                                        <b-popover
                                            target="total-qr-delete"
                                            title="Total QR Code Delete"
                                            triggers="hover focus"
                                            placement="top"
                                            :content="infoPopup.stats.totalDelete"
                                        ></b-popover>
                                    </span>
                                </p>
								<p class="stats-value" v-if="statsTotal">
									{{numberWithCommas(statsTotal.deleted)}}
								</p>
								<p class="stats-value" v-else>
									Loading.....
								</p>
								</div>
								<div class="col-4 icon">
								<i class="fas fa-qrcode"></i>
								</div>
							</div>
						</div>
					</div>
				</div> -->
            <table class="tableBodyScroll table table-striped mb-0">
                <thead>
                    <tr>
                        <th class="text-center"><span class="fa fa-image"></span>&nbsp;</th>
                        <th>Code</th>
                        <th>Serial Number</th>
                        <th>Engine Number</th>
                        <th>Status</th>
                        <th>Active Date</th>
                        <th>Created At</th>
                        <th class="text-center"><span class="fa fa-cog"></span>&nbsp;</th>
                    </tr>
                </thead>
                <tbody v-if="tableData">
                    <tr v-for="data in tableData">
                        <td class="text-center">
                            <a href="#">
                                <img :src="`http://chart.googleapis.com/chart?chs=100x100&amp;cht=qr&amp;chl=https%3A%2F%2Fqtrust.id%${data.qrcode}&amp;chld=L|0`" width="50">
                            </a>
                        </td>
                        <td>{{data.qrcode}}</td>
                        <td>{{data.serial}}</td>
                        <td>{{data.engine_no}}</td>
                        <td>{{data.status}}</td>
                        <td>{{data.status == 'ACTIVE' ? new moment(data.active_date).format("YYYY-MM-DD HH:mm:ss") : '-'}}</td>
                        <td>{{new moment(data.created_at).format("YYYY-MM-DD HH:mm:ss")}}</td>
                        <td class="text-center">
                            <b-btn v-if="data.status.toUpperCase() == 'ACTIVE'" type="button" variant="outline-primary icon-btn" @click="showDetail(data.qrcode)"><span class="fa fa-link"></span></b-btn>
                        </td>
                    </tr>
                    <tr>
                        <div class="row mt-2"
                        v-if="meta.current_page < meta.last_page || meta.last_page == -1"
                        v-observe-visibility="loadingSeen">
                            <div class="col">
                                <h4 style="text-align: center; width: 100%; margin: 0">
                                Loading...
                                </h4>
                            </div>
                        </div>
                    </tr>
                </tbody>
            </table>
            
            <!-- <div class="row mt-2" v-if="meta">
                <div class="col-sm text-sm-left text-center mb-3 mb-sm-0">
                    <span class="text-muted">Showing {{((filter.page - 1) * filter.limit) + 1}} to {{filter.page * filter.limit < meta.total ? filter.page * filter.limit : meta.total}} of {{meta.total}} entries</span>
                </div>
                <div class="col-sm">
                    <b-pagination size="md" class="justify-content-center justify-content-sm-end" :total-rows="meta.total" v-model="filter.page" :per-page="15" />
                </div>
            </div> -->
        </b-card>

        <transition name="modal" v-if="showModal">
            <div class="modal-mask">
                <div class="modal-wrapper" @click="showModal = false">
                    <div class="row">
                        <div class="col-xs-0 col-sm-0 col-md-2 col-lg-2"></div>
                        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                            <div class="modal-container" @click.stop>

                                <div class="modal-header" style="justify-content: flex-end;">
                                    <button type="button" class="close" @click="showModal = false">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                
                                <div class="modal-body">
                                    <h2 class="text-center">QR Code Details</h2>
                                    <div class="row">
                                        <div class="col-md-7 col-12">
                                            <table class="table table-borderless">
                                                <tbody class="border border-secondary shadow" style="border-radius:5px !important">
                                                    <tr><td colspan="3" class="text-center"><h4>Vehicle Matched Details</h4></td></tr>
                                                    <tr>
                                                        <td>QR Code</td>
                                                        <td>:</td>
                                                        <td>{{vehicleData.qrcode ? vehicleData.qrcode : '-'}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Engine Number</td>
                                                        <td>:</td>
                                                        <td>{{vehicleData.engine_no ? vehicleData.engine_no : '-'}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Frame Number</td>
                                                        <td>:</td>
                                                        <td>{{vehicleData.frame_no ? vehicleData.frame_no : '-'}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Active Date</td>
                                                        <td>:</td>
                                                        <td>{{ vehicleData.active_date ? new moment(vehicleData.active_date).format("YYYY-MM-DD HH:mm:ss") : '-'}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Original Location</td>
                                                        <td>:</td>
                                                        <td>{{vehicleData.ori_loc_code ? vehicleData.ori_loc_code : '-'}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Last Known Location</td>
                                                        <td>:</td>
                                                        <td>{{vehicleData.current_loc_code ? vehicleData.current_loc_code : '-'}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Activity Type</td>
                                                        <td>:</td>
                                                        <td>{{vehicleData.current_activity ? vehicleData.current_activity : '-'}}</td>
                                                    </tr>
                                                </tbody>
                                                <br>
                                                <tbody class="border border-secondary shadow" style="border-radius:5px !important">
                                                    <tr><td colspan="3" class="text-center"><h4>Vehicle Information</h4></td></tr>
                                                    <tr>
                                                        <td>Vehicle Type</td>
                                                        <td>:</td>
                                                        <td>{{vehicleData.type_code ? vehicleData.type_code : '-'}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Commercial Name</td>
                                                        <td>:</td>
                                                        <td>{{vehicleData.commercial_name ? vehicleData.commercial_name : '-'}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Segment</td>
                                                        <td>:</td>
                                                        <td>{{vehicleData.segment ? vehicleData.segment : '-'}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Series</td>
                                                        <td>:</td>
                                                        <td>{{vehicleData.series ? vehicleData.series : '-'}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Colour</td>
                                                        <td>:</td>
                                                        <td>{{vehicleData.color_code}}{{vehicleData.color_code && vehicleData.color_description ? ' - ' : '-'}}{{vehicleData.color_description}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Manufacture Year</td>
                                                        <td>:</td>
                                                        <td>{{vehicleData.manufacture_year ? vehicleData.manufacture_year : '-'}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="col-md-5 col-12 Aligner" >
                                            <img :src="vehicleData.bullet" v-if="vehicleData.bullet" style="height:auto;width:500px" />
                                            <div class="row" v-else>
                                                <div class="col text-center" style="display: flex; flex-direction: column; justify-content: center;">
                                                    <h2>NO IMAGE</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-0 col-sm-0 col-md-1 col-lg-2"></div>
                    </div>
                </div>
            </div>
        </transition>

        <transition name="modal" v-if="showExcelModal">
            <div class="modal-mask">
                <div class="modal-wrapper" @click="showExcelModal = false">
                    <div class="row">
                        <div class="col-xs-0 col-sm-0 col-md-3 col-lg-3"></div>
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <div class="modal-container" @click.stop>

                                <div class="modal-header" style="justify-content: flex-end;">
                                    <button type="button" class="close" @click="showExcelModal = false">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                
                                <div class="modal-body">
                                    <h4>Please Select The Time Range To Generate the Excel Report</h4>

                                    <p class="mt-2 mb-2" style="color:red">{{error}}</p>

                                    <flat-pickr v-model="range" :config="rangeConfig" :placeholder="!isIEMode ? 'Select Date' : null"/>

                                    <div class="row mt-4">
                                        <div class="col-md-3 col-12">
                                            <button type="button" @click="downloadExcel()" class="btn btn-success w-100">Download Excel</button>
                                        </div>
                                        <div class="col-md-3 col-12">
                                            <button type="button" @click="showExcelModal = false" class="btn btn-danger w-100">Cancel</button>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-0 col-sm-0 col-md-1 col-lg-2"></div>
                    </div>
                </div>
            </div>
        </transition>

    </div>
</template>

<script>
import moment from 'moment'
import Multiselect from 'vue-multiselect'

import { ObserveVisibility } from "vue-observe-visibility";


export default {
    metaInfo: {
        title: 'QR Code Report'
    },
    components: {
        Multiselect
    },
    data () {
        return {
            isFetching : false,

            moment: moment,
            filter: {
                search_by: 'qrcode',
                keyword: '',
                page: 1,
                limit: 15,
                status: ''
            },
            filterOptions: [
                {
                    text: 'QR Code',
                    value: 'qrcode'
                }, {
                    text: 'Engine Number',
                    value: 'engine_no'
                }, {
                    text: 'Serial Number',
                    value: 'serial'
                }
            ],
            fetching: false,
            meta: {
                total : 0,
                current_page : 0,
                last_page : 0
            },
            tableData: [],
            showModal: false,
            vehicleData: null,
            showExcelModal: false,
            error: '',
            statsTotal: null,
            infoPopup : this.$store.state.hoverInformation.report.qrcode
        }
    },
    mounted () {
        this.search(false)
    },
    watch: {
        'filter.page': function () {
            this.search(true)
        },
        'filter.limit': function () {
            this.filter.page = 1;
            this.search()
        }
    },
    directives: {
      ObserveVisibility
    },
    methods: {
        showDetail (qrcode) {
            let url = '/api/reports/qr-code/detail/' + qrcode
            this.vehicleData = null
            this.$http.get(url)
            .then((resp) => {
                let listOfSeriesImage = [{
                    'name': 'Genio',
                    'bullet': 'https://cdnhonda.azureedge.net/uploads/products/25183.jpg'
                }, {
                    'name': 'BeAT',
                    'bullet': 'https://cdnhonda.azureedge.net/uploads/products/63228.jpg'
                }, {
                    'name': 'Monkey',
                    'bullet': 'https://cdnhonda.azureedge.net/uploads/products/89252.png'
                }, {
                    'name': 'Scoopy',

                    'bullet': 'https://cdnhonda.azureedge.net/uploads/products/25324.png'
                }, {
                    'name': 'PCX 150',
                    'bullet': 'https://cdnhonda.azureedge.net/uploads/products/88723.png'
                }, {
                    'name': 'ADV 150',
                    'bullet': 'https://cdnhonda.azureedge.net/uploads/products/48889.jpg'
                }, {
                    'name': 'SHX150i',
                    'bullet': 'https://cdnhonda.azureedge.net/uploads/products/39999.png'
                }, {
                    'name': 'Forza',
                    'bullet': 'https://cdnhonda.azureedge.net/uploads/products/83922.png'
                }, {
                    'name': 'CRF150L',
                    'bullet': 'https://cdnhonda.azureedge.net/uploads/products/79470.png'
                }, {
                    'name': 'Revo X',
                    'bullet': 'https://cdnhonda.azureedge.net/uploads/products/67412.png'
                }, {
                    name: 'Supra X',
                    bullet: 'https://cdnhonda.azureedge.net/uploads/products/43165.png'
                }, {
                    name: 'Vario',
                    bullet: '/img/Vehicle/Vario.png'
                }]
                this.vehicleData = resp.data.data[0]
                for (var i = 0 ; i < listOfSeriesImage.length ; i++) {
                    if (this.vehicleData.series != null && this.vehicleData.series.toLowerCase() == listOfSeriesImage[i].name.toLowerCase()) {
                        this.vehicleData.bullet = listOfSeriesImage[i].bullet
                    }
                }
                
                this.showModal = true
            })
            .catch((err) => {
                console.log(err)
            })
        },
        downloadExcel () {
            this.error = ''
            if (this.range == '') {
                this.error = "Please Select Date Range Before Downloading Excel."
                return;
            }

            let filterString = '?'
            let dateTime = this.range.split(' to ')
            filterString += `start=${dateTime[0]}&`
            filterString += `end=${dateTime[1]}&`

            let url = '/api/reports/qr-code/download-excel' + filterString

            this.$http.get(url , {responseType : 'blob'})
            .then((resp)=>{
                const blob = new Blob([resp.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                let url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = "QR Code Data (" + this.range + ")";
                a.click();
                window.URL.revokeObjectURL(url);
                this.range = ''
            })
            .catch((err)=>{
                console.log(err);
            })
        },
        loadAPI (filter) {
            this.fetching = true
            let url = '/api/reports/qr-code' + filter

            this.$http.get(url)
            .then((resp) => {
                this.tableData = this.tableData.concat(resp.data.data)
				let meta = resp.data.meta
				this.meta.current_page = meta.currentPage;
				this.meta.last_page = (meta.lastPage != null) ? meta.lastPage : (resp.data.data.length == 0) ? 0 : -1
            })
            .then(()=>{
                this.fetching = false
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(()=>{
                this.isFetching = false;
            })

            // this.$http.get('/api/reports/qr-code/total' + filter)
            // .then((resp) => {
            //     this.statsTotal = resp.data.data
            // })
            // .catch((err) => {
            //     console.log(err)
            // })
        },
        async search (inf) {
            this.isFetching = true;
            if(!inf) {this.tableData.length = 0; this.filter.page = 1}
            let filter = await this.initialiseFilter(this.filter)
            this.loadAPI(filter)
        },
        async initialiseFilter (filter) {
            let filterString = '?'
            let keys = Object.keys(filter)

            for (var i = 0; i < keys.length; i++) {
                if((keys[i] == 'keyword' || keys[i] == 'search_by') && filter['keyword'] == '')
                    continue
                if(keys[i] == 'status' && filter[keys[i]] == null)
                    continue
                filterString += `${keys[i]}=${filter[keys[i]]}&`
            }

            return filterString
        },
        loadingSeen(isVisible, entry){
            if(isVisible &&
			(this.meta.current_page < this.meta.last_page || this.meta.last_page == -1) &&
            !this.fetching){
                this.filter.page++
            }
        }
    }
}
</script>

<style scoped>
  .modal-mask {
      position: fixed;
      z-index: 1100;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .5);
      display: table;
      transition: opacity .3s ease;
  }

  .modal-wrapper {
      display: table-cell;
      vertical-align: middle;
  }

  .modal-container {
      width: 100%;
      margin: 0px auto;
      padding: 20px 30px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
      transition: all .3s ease;
      font-family: Helvetica, Arial, sans-serif;
  }

  .modal-header h3 {
      margin-top: 0;
      color: #42b983;
  }

  .modal-body {
      margin: 20px 0;
  }

  .modal-default-button {
      float: right;
  }

  /*
  * The following styles are auto-applied to elements with
  * transition="modal" when their visibility is toggled
  * by Vue.js.
  *
  * You can easily play with the modal transition by editing
  * these styles.
  */

  .modal-enter {
      opacity: 0;
  }

  .modal-leave-active {
      opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
  }

  /* MODAL STARTS */
  .modal-body{
      margin:0
  }

  .modal-body .moduleLine{
      margin-bottom: 0vh;
  }

  .modal-body .moduleLine a{
      text-decoration: none;
  }

  .modal-body .moduleLine .moduleBox:hover{
      background-color: #E0E0E0;
      transition: background-color 0.7s ease;
      color: #212121;
      transition: color 0.7s ease;
  }

  .modal-body .moduleLine .moduleBox{
      border:1px solid #BDBDBD;
      border-radius: 20px;
      padding: 20px 0;
      color:black;
      margin-bottom: 3vh;
  }

  .modal-body .moduleLine .moduleBox i{
      font-size:5vh;
  }

  h3.modalHeadline{
      margin:0 0 20px 0;
      font-weight: bold;
      text-align : center;
  }
  /* MODAL ENDS */

  .button_outer_box{
    padding:30px;
  }
  .button_inner_box{
    border:1px solid #E0E0E0;
    border-radius:15px;
    box-shadow:5px 5px 15px 1px;
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    position: relative; /* If you want text inside of it */
  }
  .button_inner_box:hover{
    cursor: pointer;
  }

  .button_text{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .slightlyGray{
    color:#424242
  }

  .hoverBig{
    -webkit-animation: hoverBig 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: hoverBig 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }
  .unHoverBig{
    -webkit-animation: unHoverBig 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: unHoverBig 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }
  @-webkit-keyframes hoverBig {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
  }
  @keyframes hoverBig {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
  }
  @-webkit-keyframes unHoverBig {
    0% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes unHoverBig {
    0% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
    .Aligner {
        display: flex;
        align-items: center;
        justify-content: center;
    }   
    h2, h4 {
        font-weight: bold !important;
    }

</style>
