<script>/* eslint-disable */</script>

<template>
  <div ref='AgingStockBarChart' style='min-height:400px'></div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

export default {
  mounted () {
    this.createChart()
    this.loadData()
  },
  props: ['data', 'dealerData' , 'selected'],
  data () {
    return {
      chart: null,
      title: null,
      selectedData: 'data'
    }
  },
  watch: {
    selected : function () {
      if(this.selected == 'MD')
        this.selectedData = 'data'
      else
        this.selectedData = 'dealerData'

      this.loadData()
    }
  },
  methods: {
    loadData () {
      let length = this.chart.data.length
      let keys = Object.keys(this[this.selectedData])
      let data = []

      for (var i = 0 ; i < keys.length; i++) {
        if(keys[i] == 'from_date' || keys[i]== 'to_date' || keys[i] == 'total')
          continue;
        
        let range = keys[i].split('day_')[1]
        let obj = {
          range: range.includes('_') ? range.replace('_' , ' - ') + '': '> ' + range + "",
          count: this[this.selectedData][keys[i]] ? this[this.selectedData][keys[i]] : 0
        }

        data.push(obj)
      }
      data.reverse()
      
      this.chart.addData(data, length)
    },
    createChart () {
      this.chart = am4core.create(this.$refs.AgingStockBarChart, am4charts.XYChart)

      this.title = this.chart.titles.create()
      this.title.align = "left"
      this.title.fontSize = 20;
      this.title.marginBottom = 20;

      let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis())
      categoryAxis.dataFields.category = 'range'
      categoryAxis.title.text = "Range Days"
      categoryAxis.numberFormatter.numberFormat = '#'
      categoryAxis.renderer.inversed = true
      // categoryAxis.renderer.grid.template.location = 0
      categoryAxis.renderer.cellStartLocation = 0.1
      categoryAxis.renderer.cellEndLocation = 0.9
      categoryAxis.renderer.minGridDistance = 20;

      let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis())
    //   valueAxis.renderer.opposite = true
      valueAxis.title.text = "Number of Vehicles"
      valueAxis.min = 0

      this.createSeries('count', 'Vehicles Of Aging Range')
    },
    createSeries (field, name) {
      let series = this.chart.series.push(new am4charts.ColumnSeries())
      series.dataFields.valueY = field
      series.dataFields.categoryX = 'range'
      series.name = name
      series.columns.template.tooltipText = '{name} {categoryX} Days: [bold]{valueY}[/]'
      series.columns.template.height = am4core.percent(100)
      series.sequencedInterpolation = true

      series.columns.template.adapter.add("fill", (fill, target)=>{
        return this.chart.colors.getIndex(target.dataItem.index);
      })

      let valueLabel = series.bullets.push(new am4charts.LabelBullet())
      valueLabel.label.horizontalCenter = 'left'
      valueLabel.label.dx = 10
      valueLabel.label.hideOversized = false
      valueLabel.label.truncate = false

      let categoryLabel = series.bullets.push(new am4charts.LabelBullet())
      categoryLabel.label.horizontalCenter = 'right'
      categoryLabel.label.dx = -10
      categoryLabel.label.fill = am4core.color('#fff')
      categoryLabel.label.hideOversized = false
      categoryLabel.label.truncate = false
    }
  }
}
</script>
