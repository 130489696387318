<script>/* eslint-disable */</script>

<template>
  <div ref='AgingStockEndToEndChart' style='min-height:400px'></div>
</template>

<script>
	import * as am4core from '@amcharts/amcharts4/core'
	import * as am4charts from '@amcharts/amcharts4/charts'
	import am4themesAnimated from '@amcharts/amcharts4/themes/animated'


	export default {
		data () {
			return {
				chart: null
			}
		},
		mounted () {
			this.createChart()
			this.loadData() 
		},
		props: ['data'],
		methods: {
			loadData () {
				let length = this.chart.data.length;

				this.chart.addData(this.data , length)
			},
			createChart () {
				this.chart = am4core.create(this.$refs.AgingStockEndToEndChart , am4charts.XYChart);
				this.chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

				// this.chart.data = [
				// {
				// 	dateTime: "Jan 20",
				// 	intransit_ahm_md: 2,
				// 	md: 5,
				// 	intransit_d: 3,
				// 	d: 9,
				// 	stock: 25
				// },
				// {
				// 	dateTime: "Feb 20",
				// 	intransit_ahm_md: 3,
				// 	md: 5,
				// 	intransit_d: 4,
				// 	d: 1,
				// 	stock: 40
				// },
				// {
				// 	dateTime: "Mar 20",
				// 	intransit_ahm_md: 4,
				// 	md: 5,
				// 	intransit_d: 6,
				// 	d: 2,
				// 	stock: 35
				// },
				// {
				// 	dateTime: "Apr 20",
				// 	intransit_ahm_md: 3,
				// 	md: 5,
				// 	intransit_d: 4,
				// 	d: 7,
				// 	stock: 35
				// },
				// {
				// 	dateTime: "May 20",
				// 	intransit_ahm_md: 2,
				// 	md: 13,
				// 	intransit_d: 7,
				// 	d: 3,
				// 	stock: 50
				// }
				// ];

				this.chart.colors.step = 2;
				this.chart.padding(30, 30, 10, 30);
				this.chart.legend = new am4charts.Legend();

				let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
				categoryAxis.dataFields.category = "dateTime";
				categoryAxis.renderer.grid.template.location = 0;

				let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
				valueAxis.min = 0;
				valueAxis.title.text = "No Of Stock"
				valueAxis.renderer.opposite = true
				// valueAxis.strictMinMax = true;
				valueAxis.calculateTotals = true;
				// valueAxis.renderer.minWidth = 50;

				let valueAxis2 = this.chart.yAxes.push(new am4charts.ValueAxis());
				valueAxis2.min = 0;
				valueAxis2.title.text = "Days"

				// valueAxis.strictMinMax = true;
				valueAxis2.calculateTotals = true;
				// valueAxis.renderer.minWidth = 50;

				this.chart.cursor = new am4charts.XYCursor();


				let series1 = this.chart.series.push(new am4charts.ColumnSeries());
				series1.columns.template.width = am4core.percent(80);
				series1.columns.template.tooltipText =
				"{name} : {valueY} Days";
				series1.name = "Intransit AHM-MD";
				series1.dataFields.categoryX = "dateTime";
				series1.dataFields.valueY = "intransit_ahm_md";
				series1.dataItems.template.locations.categoryX = 0.5;
				series1.stacked = true;
				series1.yAxis = valueAxis2
				series1.tooltip.pointerOrientation = "vertical";

				let bullet1 = series1.bullets.push(new am4charts.LabelBullet());
				bullet1.interactionsEnabled = false;
				bullet1.label.text = "{valueY}";
				bullet1.label.fill = am4core.color("#ffffff");
				bullet1.locationY = 0.5;

				let series2 = this.chart.series.push(new am4charts.ColumnSeries());
				series2.columns.template.width = am4core.percent(80);
				series2.columns.template.tooltipText =
				"{name} : {valueY} Days";
				series2.name = "Main Dealer";
				series2.dataFields.categoryX = "dateTime";
				series2.dataFields.valueY = "md";
				series2.stacked = true;
				series2.yAxis = valueAxis2
				series2.tooltip.pointerOrientation = "vertical";

				let bullet2 = series2.bullets.push(new am4charts.LabelBullet());
				bullet2.interactionsEnabled = false;
				bullet2.label.text = "{valueY}";
				bullet2.locationY = 0.5;
				bullet2.label.fill = am4core.color("#ffffff");

				let series3 = this.chart.series.push(new am4charts.ColumnSeries());
				series3.columns.template.width = am4core.percent(80);
				series3.columns.template.tooltipText =
				"{name} : {valueY} Days";
				series3.name = "InTransit Dealer";
				series3.dataFields.categoryX = "dateTime";
				series3.dataFields.valueY = "intransit_d";
				series3.stacked = true;
				series3.yAxis = valueAxis2
				series3.tooltip.pointerOrientation = "vertical";

				let bullet3 = series3.bullets.push(new am4charts.LabelBullet());
				bullet3.interactionsEnabled = false;
				bullet3.label.text = "{valueY}";
				bullet3.locationY = 0.5;
				bullet3.label.fill = am4core.color("#ffffff");

				let series4 = this.chart.series.push(new am4charts.ColumnSeries());
				series4.columns.template.width = am4core.percent(80);
				series4.columns.template.tooltipText =
				"{name} : {valueY} Days";
				series4.name = "Dealer";
				series4.dataFields.categoryX = "dateTime";
				series4.dataFields.valueY = "d";
				series4.stacked = true;
				series4.yAxis = valueAxis2
				series4.tooltip.pointerOrientation = "vertical";

				let bullet4 = series4.bullets.push(new am4charts.LabelBullet());
				bullet4.interactionsEnabled = false;
				bullet4.label.text = "{valueY}";
				bullet4.locationY = 0.5;
				bullet4.label.fill = am4core.color("#ffffff");

				let series5 = this.chart.series.push(new am4charts.LineSeries());
				series5.dataFields.valueY = 'stock';
				series5.dataFields.categoryX = "dateTime";
				series5.strokeWidth = 2;
				series5.name = 'No. Of Stock';
				series5.tooltipText = "Number of Stock On {dateTime}: [bold]{valueY}[/]";
				series5.tensionX = 0.8;
				series5.yAxis = valueAxis
				series5.showOnInit = true;

				let bullet5 = series5.bullets.push(new am4charts.Bullet());
				bullet5.width = 12;
				bullet5.height = 12;
				bullet5.horizontalCenter = "middle";
				bullet5.verticalCenter = "middle";

				let interfaceColors = new am4core.InterfaceColorSet();

				let triangle = bullet5.createChild(am4core.Triangle);
				triangle.stroke = interfaceColors.getFor("background");
				triangle.strokeWidth = 2;
				triangle.direction = "top";
				triangle.width = 12;
				triangle.height = 12;

				this.chart.scrollbarX = new am4core.Scrollbar();
			}
		}
	}
</script>