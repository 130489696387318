<script>/* eslint-disable */</script>
<template>
  <div style="height:280px" ref="DailyRegistered"></div>
</template>

<script>

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import animated from "@amcharts/amcharts4/themes/animated.js";

am4core.useTheme(animated);

export default {
  data(){
    return {
      chart: null,
      valueAxis : null
    }
  },
  props: ['data'],
  mounted(){
    this.createChart();
    this.loadData();
  },
  methods: {
    createChart () {
      this.chart = am4core.create(this.$refs.DailyRegistered, am4charts.XYChart);
      
      this.chart.colors.step = 2;

      // Add data

      // Create axes
      let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.minGridDistance = 50;
      dateAxis.title.text = "Date"

      // Create series
      this.valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
      this.valueAxis.title.text = "No of QR Registered"
      this.valueAxis.min = 0

      this.createAxisAndSeries("actual", "Actual", false, "circle" , false);
      this.createAxisAndSeries("target", "Target", false, "triangle" , true);

      // Add legend
      this.chart.legend = new am4charts.Legend();

      this.chart.exporting.menu = new am4core.ExportMenu();

      // Add cursor
      this.chart.cursor = new am4charts.XYCursor();
    },
    createAxisAndSeries(field, name, opposite, bullet , dotted) {
        
        let series = this.chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = field;
        series.dataFields.dateX = "date";
        series.strokeWidth = 2;
        series.name = name;
        series.tooltipText = "{name}: [bold]{valueY}[/]";
        series.tensionX = 0.8;
        series.showOnInit = true;
        if(dotted){
          series.strokeWidth = 3;
          series.strokeDasharray = "5,4";
        }
        
        
        let interfaceColors = new am4core.InterfaceColorSet();
        
        switch(bullet) {
          case "triangle":
            let bullet = series.bullets.push(new am4charts.Bullet());
            bullet.width = 12;
            bullet.height = 12;
            bullet.horizontalCenter = "middle";
            bullet.verticalCenter = "middle";
            
            let triangle = bullet.createChild(am4core.Triangle);
            triangle.stroke = interfaceColors.getFor("background");
            triangle.strokeWidth = 2;
            triangle.direction = "top";
            triangle.width = 12;
            triangle.height = 12;
            break;
          case "rectangle":
            let bullet1 = series.bullets.push(new am4charts.Bullet());
            bullet1.width = 10;
            bullet1.height = 10;
            bullet1.horizontalCenter = "middle";
            bullet1.verticalCenter = "middle";
            
            let rectangle = bullet1.createChild(am4core.Rectangle);
            rectangle.stroke = interfaceColors.getFor("background");
            rectangle.strokeWidth = 2;
            rectangle.width = 10;
            rectangle.height = 10;
            break;
          default:
            let bullet2 = series.bullets.push(new am4charts.CircleBullet());
            bullet2.circle.stroke = interfaceColors.getFor("background");
            bullet2.circle.strokeWidth = 2;
            break;
        }
        
        this.valueAxis.renderer.line.strokeOpacity = 1;
        this.valueAxis.renderer.line.strokeWidth = 2;
        this.valueAxis.renderer.line.stroke = series.stroke;
        this.valueAxis.renderer.labels.template.fill = series.stroke;
        this.valueAxis.renderer.opposite = opposite;
        
        var labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = "[bold]{valueY}";
      },
      loadData() {
        console.log("a");
        let length = this.chart.data.length;
        this.chart.addData(this.data , length);
      }
  }
}
</script>