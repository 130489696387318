<template>
  <div ref="byHour" style="min-height: 700px"></div>
</template>

<script>
  import * as am4core from "@amcharts/amcharts4/core"
  import * as am4charts from "@amcharts/amcharts4/charts"
  import am4themes_animated from "@amcharts/amcharts4/themes/animated"

  export default {
    data () {
      return {
        chart : null,
        monthNames : ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      }
    },
    props : ['filterUrl'],
    mounted () {
      this.createChart();
      this.fetchData();
    },
    watch : {
      'filterUrl' : function() {
        this.fetchData();
      }
    },
    methods : {
      fetchData(){
        let vm = this;
        var length = vm.chart.data.length;
        var compiledData = [];

        this.$http.get('/api/h2/qr-registration/by-hour?' + this.filterUrl)
        .then((resp)=>{
          var data = resp.data.data;
          for(var i = 0; i < data.length; i++){
            for(var j = 0; j < data[i].count.length; j++){
              compiledData.push({
                "hour" : data[i].hour,
                "month" : vm.monthNames[j],
                "value" : data[i].count[j]
              })
            }
          }
          for(var i = 0; i < resp.data.hourTotal.length; i++){
            compiledData.push({
              "hour" : resp.data.hourTotal[i].hour,
              "month" : "Total",
              "value" : resp.data.hourTotal[i].total
            })
          }
        })
        .then(()=>{
          vm.chart.addData(compiledData, length);
          // console.log(compiledData)
        })
        .catch((err)=>{
          console.log(err);
        })
      },
      createChart(){
        am4core.useTheme(am4themes_animated)

        this.chart = am4core.create(this.$refs.byHour, am4charts.XYChart)
        this.chart.maskBullets = false

        let xAxis = this.chart.xAxes.push(new am4charts.CategoryAxis())
        let yAxis = this.chart.yAxes.push(new am4charts.CategoryAxis())

        // let title = this.chart.titles.create()
        // title.text = "Traffic Distribution"
        // title.fontSize = 20
        // title.marginBottom = 30
        // title.fontWeight = "Bold"

        xAxis.dataFields.category = "month"
        yAxis.title.text = "Month"
        yAxis.dataFields.category = "hour"

        xAxis.renderer.grid.template.disabled = true
        xAxis.renderer.minGridDistance = 40

        yAxis.renderer.grid.template.disabled = true
        yAxis.renderer.inversed = true
        yAxis.renderer.minGridDistance = 30

        let series = this.chart.series.push(new am4charts.ColumnSeries())
        series.dataFields.categoryX = "month"
        series.dataFields.categoryY = "hour"
        series.dataFields.value = "value"
        series.sequencedInterpolation = true
        series.defaultState.transitionDuration = 3000

        let bullet2 = series.bullets.push(new am4charts.LabelBullet());
        bullet2.label.text = "[bold]{value}";
        bullet2.label.fill = am4core.color("#111");
        bullet2.zIndex = 1;
        bullet2.fontSize = 11;
        bullet2.interactionsEnabled = false;

        let bgColor = new am4core.InterfaceColorSet().getFor("background")

        let columnTemplate = series.columns.template
        columnTemplate.strokeWidth = 1
        columnTemplate.strokeOpacity = 0.2
        columnTemplate.stroke = "#333"
        columnTemplate.tooltipText = "{month}, {hour}: {value}"
        columnTemplate.width = am4core.percent(100)
        columnTemplate.height = am4core.percent(100)

        series.heatRules.push({
          target: columnTemplate,
          property: "fill",
          min: am4core.color("#fff"),
          max: am4core.color("#0080ff")
        })

        // heat legend
        let heatLegend = this.chart.bottomAxesContainer.createChild(am4charts.HeatLegend)
        heatLegend.minValue = 0
        heatLegend.width = am4core.percent(100)
        heatLegend.series = series
        heatLegend.valueAxis.renderer.labels.template.fontSize = 9
        heatLegend.valueAxis.renderer.minGridDistance = 30

        // heat legend behavior
        series.columns.template.events.on("over", function (event) {
          handleHover(event.target);
        })

        series.columns.template.events.on("hit", function (event) {
          handleHover(event.target);
        })

        function handleHover(column) {
          if (!isNaN(column.dataItem.value)) {
            heatLegend.valueAxis.showTooltipAt(column.dataItem.value)
          } else {
            heatLegend.valueAxis.hideTooltip();
          }
        }

        series.columns.template.events.on("out", function (event) {
          heatLegend.valueAxis.hideTooltip();
        })
      },
    }
  }
</script>

<style scoped>

</style>