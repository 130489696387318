<template>
  <div>
    <!-- Period Filter Button Start -->
    <!-- <div class="row">
        <div class="col-xs-0 col-sm-2 col-lg-8"></div>
        <div class="col-12 col-sm-12 col-lg-4">
            <div class="row ">
                <div class="col-lg col-sm col-xs-12 mb-2">
                    <button type="button" class="btn float-right"  :class="{ 'btn-outline-primary' : period != 1 , 'btn-primary' : period == 1}"  style="width:100%;border-radius:0.25rem" v-on:click="period = 1">MTD</button>
                </div>
                <div class="col-lg col-sm col-xs-12 mb-2">
                    <button type="button" class="btn float-right"  :class="{ 'btn-outline-primary' : period != 2 , 'btn-primary' : period == 2}"  style="width:100%;border-radius:0.25rem" v-on:click="period = 2">Last Month</button>
                </div>
                <div class="col-lg col-sm col-xs-12 mb-2">
                    <button type="button" class="btn float-right"  :class="{ 'btn-outline-primary' : period != 3 , 'btn-primary' : period == 3}"  style="width:100%;border-radius:0.25rem" v-on:click="period = 3">YTD</button>
                </div>
                <div class="col-lg col-sm col-xs-12 mb-2">
                    <button type="button" class="btn float-right"  :class="{ 'btn-outline-primary' : period != 4 , 'btn-primary' : period == 4}"  style="width:100%;border-radius:0.25rem" v-on:click="period = 4">Last Year</button>
                </div>
                <div class="col-lg col-sm col-xs-12 mb-2">
                    <button type="button" class="btn float-right"  :class="{ 'btn-outline-primary' : period != 5 , 'btn-primary' : period == 5}"  style="width:100%;border-radius:0.25rem" v-on:click="period = 5">All Time</button>
                </div>
                <div class="col-lg col-sm col-xs-12 mb-2">
                    <button type="button" class="btn float-right"  :class="{ 'btn-outline-primary' : period <= 6 , 'btn-primary' : period >= 6}"  style="width:100%;border-radius:0.25rem" v-on:click="period = 6">Custom</button>
                </div>
            </div>
        </div>
    </div> -->
    <!-- Period Filter Button End -->

    <b-card>
      <b-tabs content-class="mt-3" fill v-model="tabIndex">
        <b-tab title="Stock Overview" active>
          <stockDetails 
            :options="options" 
            :period="period" 
            v-if="tabLoad[0]" 
            :data="stockDetailsData"
            @loadAPI="loadAPI"
            :filter="filter.stockDetailsFilter" 
          >
          </stockDetails>
        </b-tab>

        
        <b-tab title="Stock Analytics">
          <stockAnalytics 
            :options="options" 
            :period="period" 
            :mobile="mobile" 
            v-if="tabLoad[1]" 
            :data="stockAnalyticsData"
            @loadAPI="loadAPI"
            :filter="filter.stockAnalyticsFilter"
          >
          </stockAnalytics>
        </b-tab>

        <b-tab title="Sales Analysis">
          <salesAnalysis 
            :options="options" 
            :period="period" 
            :mobile="mobile" 
            :data="salesAnalysisData"
            @loadAPI="loadAPI"
            :filter="filter.salesAnalysisFilter"
            v-if="tabLoad[2]"> 
          </salesAnalysis>
        </b-tab>

        <!-- <b-tab title="Territory Management">
          <territoryManagement 
            :options="options" 
            :period="period" 
            :mobile="mobile" 
            v-if="tabLoad[3]"
            :data="territoryManagementData"
            :filter="filter.territoryManagementFilter">
          </territoryManagement>
        </b-tab> -->


        <b-tab title="Mapping Summary">
          <mappingSummary 
            :options="options" 
            :period="period" 
            :mobile="mobile" 
            v-if="tabLoad[3]"
            @loadAPI="loadAPI"
            :filter="filter.mappingSummaryFilter"
            :data="mappingSummaryData">
          </mappingSummary>
        </b-tab>


        <b-tab title="Service Analysis">
          <serviceAnalysis 
            :options="options" 
            :period="period" 
            :mobile="mobile" 
            v-if="tabLoad[4]"
            @loadAPI="loadAPI"
            :filter="filter.serviceAnalysisFilter"
            :data="serviceAnalysisData">
          </serviceAnalysis>
        </b-tab>


        <b-tab title="Late Delivery and Shipping">
          <lateDeliveryAndShipping 
            :options="options" 
            :period="period" 
            :mobile="mobile" 
            v-if="tabLoad[5]"
            @loadAPI="loadAPI"
            :filter="filter.lateDeliveryAndShippingFilter"
            :data="lateDeliveryAndShippingData">
          </lateDeliveryAndShipping>
        </b-tab>
      </b-tabs>
    </b-card>

    <!-- Modal Custom Range Start-->
    <div v-if="showCustomModal">
      <transition name="modal">
          <div class="modal-mask">
              <div class="modal-wrapper" @click="showCustomModal = false">
                  <div class="row">
                      <div class="col-xs-2 col-sm-2 col-md-2 col-lg-4"></div>
                      <div class="col-xs-8 col-sm-8 col-md-8 col-lg-4">
                          <div class="modal-container" @click.stop>
                              <div class="modal-body">
                                  <div class="row">
                                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                          <div class="row">
                                              <h3 class="modalHeadline col" style="width: 100%; text-align: center">Custom Date Range</h3>
                                          </div>

                                          <p style="color:red">{{modalError}}</p>
                                          <div class="row">
                                              <div class="form-group col">
                                              <label for="start">Date:</label>
                                              <flat-pickr v-model="range" :config="rangeConfig" placeholder="Range picker" />
                                              </div>
                                          </div>
                                          <div class="row" style="margin-top:20px">
                                              <div class="col">
                                                  <button type="button" class="btn btn-secondary" style="width: 100%;" @click="showCustomModal = false">Cancel</button>
                                              </div>
                                              <div class="col">
                                                  <button type="button" class="btn" style="background-color: #F76E27; color: white; width: 100%" @click="period = 7">Go</button>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-xs-2 col-sm-2 col-md-2 col-lg-4"></div>
                  </div>
              </div>
          </div>
      </transition>
    </div>
	
  </div>
</template>

<script>
  function isRTL () {
  return document.documentElement.getAttribute('dir') === 'rtl' ||
    document.body.getAttribute('dir') === 'rtl'
  }

  import Multiselect  from 'vue-multiselect'
  import * as am4core from "@amcharts/amcharts4/core"
  import flatPickr from 'vue-flatpickr-component'
  import moment from 'moment'
  import listUrl from './listUrl.js'

  import stockDetails from './tabChildren/stockDetails'
  import stockAnalytics from './tabChildren/stockAnalytics'
  import lateDeliveryAndShipping from './tabChildren/lateDeliveryAndShipping'
  import salesAnalysis from './tabChildren/salesAnalysis'
  import territoryManagement from './tabChildren/territoryManagement'
  import mappingSummary from './tabChildren/mappingSummary'
  import serviceAnalysis from './tabChildren/serviceAnalysis'

  export default {
    name: 'Highlight',
    data () {
      let today = moment().format("YYYY-MM-DD");

      //1 Week = 7 Days (ex. 1-7, 8-14, 15-21, 22-28, 29-31)
      let firstDayOfWeek = moment().set('date', Math.floor((moment().date()-1) / 7) * 7 + 1).format("YYYY-MM-DD");
      let lastDayOfWeek = (moment(firstDayOfWeek).add(6, 'days') > moment().endOf('month') ? moment().endOf('month') : moment(firstDayOfWeek).add(6, 'days')).format("YYYY-MM-DD");

      return {
        mobile : this.isMobile(),
        buttonArray: ['MTD' , 'Last Month' , 'YTD' , 'Last Year' , 'All Time' , 'Custom'],
        period : 1,
        options : this.$store.getters.getFilterOptions,
        // modal settings
        showCustomModal: false,
        rangeConfig: {
          mode: 'range',
          altInput: true,
          // animate: !isRTL()
        },
        range: null,
        modalError: '',

        // filter settings
        filter: {
          stockDetailsFilter: {
            stockOverview : {
              period : 1,
              date_filter : 1,
              date : today,
            },
            mainDealerStockOptions : {
              region : '',
              segment : '',
              color : '',
              period : 1,
              date_filter : 1,
              date : today,
            },
            dealerStockOptions : {
              mainDealer : '',
              period : 1,
              date_filter : 1,
              date : today
            }
          },
          stockAnalyticsFilter: {
            // movementStockOptions : {
            //   mainDealer : '',
            //   warehouse : '',
            //   destination : '',
            //   category: '',
            //   by: { code: 'destination' , name : 'By Destination ( Out )'},
            // },
            stockDaysOptions : {
              region : '',
              type : '',
              color : '',
              period : 1,
              start : firstDayOfWeek,
              end : lastDayOfWeek
            },
            agingStockOptions : {
              type : '',
              region : '',
              segment : '',
              color : '',
              period : 1
            },
            averageAgingStockOptions : {
              segment : '',
              region : '',
              period : 1
            }
          },
          salesAnalysisFilter: {
            salesAnalysisTargetVsActualOptions : {
              segment : null,
              region : null,
              period : 1
            },
            salesTableOptions : {
              type : '',
              color : '',
              period : 1
            },
            salesKaresidenan : {
              type : '',
              color : '',
              period : 1
            }
          },
          mappingSummaryFilter : {
            mappingSummary : {
              period : 1
            }
          },
          territoryManagementFilter: {
            territoryOptions: {
              area: {
                name: 'Jawa Tengah',
                region: ['H350']
              },
              type: '',
              color: '',
              branch: ''
            },
          },
          serviceAnalysisFilter: {
            serviceAnalysisOptions : {
              region : null,
              period : 1
            }
          },
          lateDeliveryAndShippingFilter: {
            ahmToMd : {

            },
            mdToD : {
              maindealer : null,
              dealer : null,
            }
          },
        },

        // tab settings
        tabIndex: 0,
        tabLoad: [true, false, false, false, false, false],
        tabShow: 'stockDetails',

        // API Data 
        stockDetailsData: {
          stockOverview: null,
          mainDealerStock: null,
          dealerStock: null
        },
        stockAnalyticsData: {
          agingStockMD: null,
          agingStockD: null,
          agingStockEnd: null,
          stockDaysMD: null,
          stockDaysD: null,
          stockDaysEnd: null,
          movementStockSubSeries: null,
          movementStockDestination: null,
          movementStockTable: null,
          averageAgingMD: null,
          averageAgingD: null
        },
        salesAnalysisData: {
          salesAnalysisTargetVsActual: null,
          salesTableMD: null,
          salesTableKaresidenan: null
        },
        serviceAnalysisData: {
          serviceAnalysis: null,
          serviceAnalysisDealer: null
        },
        mappingSummaryData: {
          mappingH1: null,
          mappingH2: null,
          mappingTotal: null
        },
        territoryManagementData: {
          territory: null
        },
        lateDeliveryAndShippingData: {
          ahmToMd: null,
          mdToD: null
        }
      }
    },
    watch: {
      tabIndex: function () {

        if(!this.tabLoad[this.tabIndex]) {
          this.search(this.period , null , null)
          this.tabLoad[this.tabIndex] = true
        }
          

        
      },
      period: function () {
        this.tabLoad = new Array(6).fill(false)
        this.tabLoad[this.tabIndex] = true
        // this.source.cancel('Filter Has Changed');
        this.search(this.period , null , null)
        
      }
    },
    beforeDestroy () {
      am4core.disposeAllCharts()
    },
    components : {
      Multiselect ,
      flatPickr,
      stockDetails,
      stockAnalytics,
      lateDeliveryAndShipping,
      salesAnalysis,
      territoryManagement,
      mappingSummary,
      serviceAnalysis
    },
    mounted () {
      this.search(this.period , null, null)

      this.filter.lateDeliveryAndShippingFilter.mdToD.maindealer = this.options.region[0]
    },
    methods : {

      // This Function is to make the the filter Object to Query String Parameters
      async initialiseFilter (filterString, filterObj) {
        let keys = Object.keys(filterObj)

        for (var i = 0; i < keys.length; i++) {
            // check if the requested filter is an empty array or null or empty string
            if ( filterObj[keys[i]] == null || (typeof filterObj[keys[i]] == 'object' && filterObj[keys[i]].length <= 0) || (filterObj[keys[i]] == '' && typeof filterObj[keys[i]] != 'number'))
                continue

            if (keys[i] == 'type') {
                let newData = filterObj[keys[i]].toUpperCase().split(" ").join("")
                filterString += `${keys[i]}=${newData}&`
            }
            else
                filterString += `${keys[i]}=${filterObj[keys[i]]}&`
        }

        return filterString
      },

      // This Function is to Simplify Object Of Filter to for InitialiseFilter Function
      async transformFilter (filter) {
          let obj = {}
          let keys = Object.keys(filter)
          for (var i = 0 ; i < keys.length; i++) {
            if(filter[keys[i]] == null || (filter[keys[i]] == '' && typeof filter[keys[i]] != 'number') || keys[i] == 'period')
              continue;
            else if (keys[i] == 'area')
              obj['region'] = filter[keys[i]].region
            else if (keys[i] == 'type')
              obj[keys[i]] = filter[keys[i]].type ? filter[keys[i]].type : filter[keys[i]]
            else if (keys[i] == 'color')
              obj[keys[i]] = filter[keys[i]].color_code
            else if (keys[i] == 'segment')
              obj[keys[i]] = filter[keys[i]].segment
            else if (keys[i] == 'branch' || keys[i] == 'dealer')
              obj[keys[i]] = filter[keys[i]].loc_code
            else if (keys[i] == 'region' || keys[i] == 'maindealer')
              obj[keys[i]] = filter[keys[i]].loc_code
            else 
              obj[keys[i]] = filter[keys[i]]
          }
          return obj
      },

      // This Function is used to load All API
      translateIndex (index) {
        let array = ['stockOverview' , 'stockAnalytics' , 'salesAnalysis', 'mappingSummary', 'serviceAnalysis', 'lateDeliveryAndShipping']

        return array[index]
      },
      async search (index, url, params) {
        if(!url && !params) {
          url = listUrl.stringUrl[this.translateIndex(this.tabIndex)]
          params = listUrl.param[this.translateIndex(this.tabIndex)]
        }

        

        let keys = Object.keys(url)

        for(var i = 0 ; i < keys.length ; i ++) {
          // this.source = this.CancelToken.source();
          // console.log(keys)
          this.loadAPI(url[keys[i]] , params[keys[i]]) 
        }
      },

      // This Function is used to load All API
      async loadAPI (url , params) {
        let index = this.period
        let filterTemp = {
            period: this.period
        }

        // If User Chooses The Custom Date Range Option
        if (index == 6) {
            this.showCustomModal = true
            return
        }
        // Triggered after Custom Modal Date Range has been Inserted
        if (index == 7) {
            delete filterTemp.period
            // Add Start and End in Filter
            if(this.range) {
                this.modalError = ''
                let dateRange = this.range.split(' to ')
                filterTemp.start = dateRange[0]
                filterTemp.end = dateRange[1] ? dateRange[1] : dateRange[0]
                let dateStart = new moment(filterTemp.start)
                let dateEnd = new moment(filterTemp.end)
                var duration = moment.duration(dateEnd.diff(dateStart));
                duration.asDays() > 30 ? filterTemp.range = "month" : filterTemp.range = "day";
                
            }
            else {
                this.modalError = 'Please Input Date Range'
                return;
            }
            this.showCustomModal = false
        }
        // For the rest of the time range
        
        let filterString = await this.initialiseFilter('?', filterTemp)
        // console.log(url , params )
        for(var i = 0 ; i < url.length ; i++) {
          let filter = filterString
          let variable = params[i].var
          let parent = params[i].parent
          let filterHeader = params[i].filter
          let filterVar = params[i].filterVar

          this[parent][variable] = null
          
          if(this[parent][variable + 'Meta']) this[parent][variable + 'Meta'] = null

          let individualFilter = filter
          
          // Add Localised Filter Options to API

          if (filterVar) {
            
            let filterOptions = await this.transformFilter(this.filter[filterHeader][filterVar])
            if(variable == 'agingStockMD' || variable == 'averageAgingMD') filterOptions.typeDealer = "MAINDEALER"
            else if(variable == 'agingStockD' || variable == 'averageAgingD') filterOptions.typeDealer = "DEALER"
            individualFilter = await this.initialiseFilter(filter + '&' , filterOptions)
          }
          else if(this.filter[filterHeader] && this.filter[filterHeader][variable + 'Options']){
              let filterOptions = await this.transformFilter(this.filter[filterHeader][variable + 'Options'])
              individualFilter = await this.initialiseFilter(filter + '&' , filterOptions)
          }
          // console.log(filterVar ,filterHeader ,  this.filter[filterHeader][filterVar] , this.filter[filterHeader][variable + 'Options'])
          // console.log(individualFilter)
          
          // console.log(JSON.stringify(this.filter.lateDeliveryAndShippingFilter.mdToD))

          this.$http.get('/api/highlight' + url[i] + individualFilter)
          .then((resp) => {
            this[parent][variable] = resp.data.data

            if(resp.data.meta)
                this[parent][variable + 'Meta'] = resp.data.meta
          })
          .catch((err) => {
            if(this.$http.isCancel(err)) {
              console.log('Cancelled')
            }
            else {
              console.log(err)
            }
          })
        }
      }
    }
  }
</script>

<style scoped>
  .btn-primary {
    background-color: #CB2127;
    border-color: transparent;
    box-shadow: 1px 1px 3px 3px lightgrey;
  }

  .btn-outline-primary {
    color: #CB2127;
    box-shadow: 0 0 0 1px #CB2127 inset;
    border-color: transparent;
  }

  .btn-outline-primary:hover {
    color: white;
    background-color: #CB2127; 
  }

  .content-outer{
    padding:0 1.25%;
  }

  .content-inner{
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    border-radius: 6px;
    margin-top:8px;
    overflow-x : auto;
  }
</style>

<style>
  .nav-tabs .nav-link {
	border: 1px solid transparent;
	border-bottom: 1px solid #656f78;
  }

  .nav-link.active {
	border-bottom: none;
  }

  .Aligner-Header {
    padding-left:3% !important;
    color: #707070;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .Aligner-Header h3 {
    font-size: x-large;
  }  

  .highlightFilter .multiselect__tags  {
      background-color: #CB2127 !important;
  }

  .highlightFilter .multiselect__single , .highlightFilter .multiselect__input , .highlightFilter .multiselect__input::placeholder , .highlightFilter .multiselect__placeholder {
      background-color: #CB2127 !important;
      color: white;
  }
  
  .highlightFilter .multiselect__select:before{
    color: white !important;
  }

  .highlightFilterBtn {
    background-color: #CB2127;
    color:white;
    border-radius: 5px;
    height:42px;
    width: 100%;
  }

  .disabledButton .multiselect__select {
      background-color: #CB2127
  }

  .moreButton {
    text-align: center;
    vertical-align: middle;
    color: grey;
    cursor: pointer;
  }

  .moreButton:hover{
    text-decoration: underline;
  }
</style>

<style>
.modal-mask {
        position: fixed;
        z-index: 1050;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        width: 100%;
        margin: 0px auto;
        padding: 20px 30px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
        transition: all .3s ease;
        font-family: Helvetica, Arial, sans-serif;
    }

    .modal-header h3 {
        margin-top: 0;
        color: #42b983;
    }

    .modal-body {
        margin: 10px 0;
    }

    .modal-default-button {
        float: right;
    }

    /*
    * The following styles are auto-applied to elements with
    * transition="modal" when their visibility is toggled
    * by Vue.js.
    *
    * You can easily play with the modal transition by editing
    * these styles.
    */

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    /* MODAL STARTS */
    .modal-body{
        margin:0
    }

    .modal-body .moduleLine{
        margin-bottom: 0vh;
    }

    .modal-body .moduleLine a{
        text-decoration: none;
    }

    .modal-body .moduleLine .moduleBox:hover{
        background-color: #E0E0E0;
        transition: background-color 0.7s ease;
        color: #212121;
        transition: color 0.7s ease;
    }

    .modal-body .moduleLine .moduleBox{
        border:1px solid #BDBDBD;
        border-radius: 20px;
        padding: 20px 0;
        color:black;
        margin-bottom: 3vh;
    }

    .modal-body .moduleLine .moduleBox i{
        font-size:5vh;
    }

    h3.modalHeadline{
        margin:0 0 20px 0
    }
    /* MODAL ENDS */

    .modalContent tr th{
      text-align:right;
    }

    .modalContent tr .middleDivider{
      width: 50px;
      text-align: center;
    }
</style>