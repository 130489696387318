<template>
    <div>
        <!-- <ol class="breadcrumb pull-right">
			<li class="breadcrumb-item"><a href="javascript:;">Home</a></li>
			<li class="breadcrumb-item active">Dealer Service</li>
		</ol>

        <h1 class="page-header">Dealer Service</h1> -->
        <!-- <hr> -->

        <!-- <div class="row">
            <div class="col-md-4 offset-md-8">
                <period
                    @newDateRange="newDateRange"
                ></period>
            </div>
        </div> -->

        <!-- Tabs Start -->
        <div class="mt-5" style="">
            <ul class="nav nav-tabs" style="display: flex; justify-content: space-evenly;">
            <li class="nav-item" v-for="(tabName, index) in tabs">
                <a
                class="nav-link"
                :class="{
                    'active' : tab == index,
                    'clickAble' : tab != index
                }"
                @click="tab = index"
                >{{ tabName }}</a>
            </li>
            </ul>
        </div>
        <!-- Tabs End -->

        <div class="row" style="background-color: #fff; border: 0 solid rgba(24, 28, 33, 0.06); border-radius: 0.125rem; box-shadow: 0 10px 30px -15px rgba(24, 28, 33, 0.44); border-radius: 16px;">
            <!-- <div class="col-12" style="padding: 1.7rem"> -->
            <div class="col-12 p-0">

                <stock-overview
                    v-if="tab == 0"
                    :filter="filter.stockOverview"
                    :data="data.stockOverview"
                ></stock-overview>

                <stock-analytics
                    v-if="tab == 1"
                    :periodFilter="periodFilter"
                    :filter="filter.stockAnalytics"
                    :data="data.stockAnalytics"
                ></stock-analytics>

                <qr-registration-control
                    v-if="tab == 2"
                    :filter="filter.qrRegistrationControl"
                    :data="data.qrRegistrationControl"
                ></qr-registration-control>
            </div>
        </div>
    
        <!-- Tabs Content Start -->
        <!-- <service-overview
          v-if="tab == 0"
          :filter="filter.serviceOverview"
          :data="data.serviceOverview"
          @newDateRange="newDateRange"
        ></service-overview> -->
    
        <!-- <service-operation
          v-if="tab == 1"
          :filter="filter.serviceOperation"
          :data="data.serviceOperation"
          :periodFilter="periodFilter.serviceOperation"
          @newDateRange="newDateRange"
        ></service-operation> -->
        <!-- Tabs Content End -->
    </div>
</template>

<script>
    import qs from 'qs'
    import moment from 'moment'

    import period from './children/period';

    import stockOverview from './tabs/StockOverview';
    import stockAnalytics from './tabs/StockAnalytics';
    import qrRegistrationControl from './tabs/QRRegistrationControl';

    export default {
        data () {
            return {
                tab : 2,
                tabs : ['Stock Overview', 'Stock Analytics', ''],

                //periodFilter will be filled in resetOperations()
                periodFilter : {},

                //prevFilter acts as a record, will eventually have the same content of filter
                prevFilter : null,

                filter : {
                    stockOverview : {
                        stockOverviewTotal : {},
                        stockOverview : {
                            segment : null,
                            type : null,
                            color : null
                        }
                    },
                    stockAnalytics : {
                        agingStock : {
                            segment : null,
                            type : null,
                            color : null
                        },
                        agingStockStats : {
                            segment : null,
                            type : null,
                            color : null
                        },
                        agingStockDetails : {
                            segment : null,
                            type : null,
                            color : null
                        },
                    },
                    qrRegistrationControl : {
                        summary : {},
                        unregistered : {},
                        last7days : {}
                    }
                },

                //Each variable key is the url for the section, example:
                //          data.stockOverview.stockOverviewTotal is for:
                //          'https://{{server}}/highlight/warehouse/stockOverviewTotal'
                data : {
                    stockOverview : {
                        stockOverviewTotal : null,
                        stockOverview : null
                    },
                    stockAnalytics : {
                        agingStock : null,
                        agingStockStats : null,
                        agingStockDetails : null,
                    },
                    qrRegistrationControl : {
                        summary : null,
                        unregistered : null,
                        last7days : null,
                    }
                }
            }
        },
        components : {
            period,

            stockOverview,
            stockAnalytics,
            qrRegistrationControl
        },
        async mounted () {
            await this.resetOperations();

            this.fetchData();
        },
        watch : {
            //PERIOD FILTER
            'periodFilter' : {
                deep : true,
                async handler () {
                    this.fetchData();
                    // this.fetchData(await this.getCurrentPageVariable());
                },
            },

            //INDIVIDUAL FILTERS
            //Stock Overview
            'filter.stockOverview' : {
                deep : true,
                handler (val) {
                    for (var i in val) {
                        if (JSON.stringify(val[i]) !== JSON.stringify(this.prevFilter.stockOverview[i]))
                            this.fetchData('stockOverview', i);
                    }

                    this.prevFilter.stockOverview = JSON.parse(JSON.stringify(this.filter.stockOverview))
                }
            },
            //Stock Analytics
            'filter.stockAnalytics' : {
                deep : true,
                handler (val) {
                    for (var i in val) {
                        if (JSON.stringify(val[i]) !== JSON.stringify(this.prevFilter.stockAnalytics[i]))
                            this.fetchData('stockAnalytics', i);
                    }

                    this.prevFilter.stockAnalytics = JSON.parse(JSON.stringify(this.filter.stockAnalytics))
                }
            }
        },
        methods : {
            getCurrentPageVariable () {
                let keys = Object.keys(this.data);
                return keys[this.tab]
            },
            newDateRange (obj) {
                this.periodFilter = obj;

                for (var i in this.filter) {
                    for (var j in this.filter[i]) {
                        this.filter[i][j].start = obj.start;
                        this.filter[i][j].end = obj.end;
                    }
                }
            },
            resetOperations () {
                //Set Date Ranges
                // let start = moment().startOf('month').format('YYYY-MM-DD 00:00:00')
                // let end = moment().format('YYYY-MM-DD 23:59:59')
                let start = moment().subtract(1, 'days').format('YYYY-MM-DD 00:00:00')
                let end = moment().subtract(1, 'days').format('YYYY-MM-DD 23:59:59')

                this.periodFilter.start = start;
                this.periodFilter.end = end;

                for (var i in this.filter) {
                    for (var j in this.filter[i]) {
                        this.filter[i][j].start = start;
                        this.filter[i][j].end = end;
                    }
                }

                // Duplicate current filter to prevFilter
                this.prevFilter = JSON.parse(JSON.stringify(this.filter));
            },
            fetchData (group, subGroup) {
                var list = {}
                if(group) list = {group:subGroup?[subGroup]:Object.keys(this.data[group])}
                else {
                    var keys = Object.keys(this.data)
                    for(var i in keys) list[keys[i]] = Object.keys(this.data[keys[i]]);
                }

                for (var i in list){//stock overview, stock analytics, qr registration control
                    for (var j in list[i]){//real url
                        let groupA = group?group:i
                        let listA = list[i][j]
                        this.$http.get(`/api/highlight/warehouse/${listA}${qs.stringify(this.filter[groupA][listA], {skipNulls:true, addQueryPrefix:true})}`)
                        .then((resp)=>{
                            this.data[groupA][listA] = resp.data.data;
                        })
                        .catch((err)=>{console.log(err)})
                    }
                }
            },
        }
    }
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@500;700&display=swap');
    
    .chart--title {
        margin: 0;
        font: normal normal 500 18px/21px Ubuntu;
        color: #707070;
    }

    .btn-outline-download {
        box-shadow: 0px 2px 6px #0000001A;
        border-radius: 5px;
        font: normal normal 500 10px/11px Ubuntu;
        color: #676767;
    }

    .btn-elegant {
        background: #FFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 6px #00000014;
        border: 0.5px solid #EEE;
        padding: 0.5rem 2rem;
        font: normal normal 500 10px/11px Ubuntu;
    }

    .btn-elegant-unselected {
        background: #EEE 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 6px #00000014;
        padding: 0.5rem 2rem;
        font: normal normal 500 10px/11px Ubuntu;
    }

    .ubuntu--font {
        font-family: Ubuntu;
    }

    .hw--divider {
        width: 100%;
        height: 15px;
        background-color: #F5F5F5;

        margin: 20px 0;
    }

    .dealerService .multiselect__tags  {
        background-color: #09549F !important;
    }

    .dealerService .multiselect__single , .dealerService .multiselect__input , .dealerService .multiselect__input::placeholder , .dealerService .multiselect__placeholder {
        background-color: #09549F !important;
        color: white;
    }
    
    .dealerService .multiselect__select:before{
        color: white !important;
    }

    .dealerService.disabledButton .multiselect__select {
        background-color: #09549F
    }
</style>

<style scoped>
    .nav-tabs, .nav-link{
        border:1px solid transparent !important;
    }
    .nav-tabs {
        /* border-bottom: 1px solid #dee2e6 !important; */
        border-bottom: 0 !important;
    }
    .nav-link{
        border:1px solid !important;
        border-radius: 16px 16px 0 0;
        border-color: transparent !important;
        background-image: none;
        background-color: #fff;
        color: #EB979A !important;
    }
    .nav-link:before {
        content: "";
        box-shadow: 0 3px 6px #00000029;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
    }
    .nav-link.active{
        background-color: #fff;
        border-color:#E25A5A #E25A5A #fff !important;
        color: #E25A5A !important;
        /* background-color:#F5F5F5; */
    }

    .nav-item {
        width:33.3%;
        text-align: center;
        border-bottom: 0;
    }
    .clickAble:hover{
        cursor:pointer;
    }
</style>