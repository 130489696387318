<template>
    <div class="p-1">
        <div class="innerBox" :style="box_style">
            <h5 :style="title_style">{{ title }}</h5>
            <div class="row">
                <div class="col-8">
                    <h2>{{ numberWithCommas(value) }}</h2>
                </div>
                <div class="col-4 text-center align-middle" v-if="icon">
                    <i
                        class="fas"
                        :class="'fa-' + icon"
                        style="font-size:2.5em"
                        :style="icon_style"
                    ></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {

            }
        },
        props : ['title', 'title_style', 'value', 'box_style', 'icon', 'icon_style'],
        mounted () {

        },
        methods : {

        }
    }
</script>

<style scoped>
    .innerBox {
        border: 1px solid #DEDEDE;
        border-radius: 8px;
        padding:20px;
    }

    h5 {
        font: normal normal 500 14px/16px Ubuntu;
        letter-spacing: 0;
        color:#707070;
    }

    h2 {
        font: normal normal bold 40px/46px Ubuntu;
        /* font-weight: bold; */
        margin-bottom: 2px;
    }
</style>