<template>
  <div>
    <!--Filter Option-->
    <b-card title="Change Ownership" :sub-title="infoPopup.title" header-tag="h6" class="mb-4">
      <hr>
        <b-form>
            <b-form-row>
                <b-form-group label="Engine Number" class="col-md-6">
                    <b-input type="text" placeholder="Engine Number" v-model="filter.engine_no" />
                </b-form-group>
                <b-form-group label="Frame Number" class="col-md-6">
                    <b-input type="text" placeholder="Frame Number" v-model="filter.frame_no" />
                </b-form-group>
            </b-form-row>
            <b-form-row>
              <b-form-group label="QR Code" class="col-md-6">
                    <b-input type="text" placeholder="QR Code" v-model="filter.qrcode" />
                </b-form-group>
              <b-form-group label="From" class="col-md-3">
                <flat-pickr class="mt-1" v-model="filter.start" :config="rangeConfig" :placeholder="!isIEMode ? 'Range picker' : null"/>
              </b-form-group>
              <b-form-group label="To" class="col-md-3">
                <flat-pickr class="mt-1" v-model="filter.end" :config="rangeConfig" :placeholder="!isIEMode ? 'Range picker' : null"/>
              </b-form-group>
            </b-form-row>
            <b-btn @click="vue_universal_table.update++" :disabled="isFetching" class="col-md-2" type="button" variant="primary"><span class="fa fa-filter"></span>&nbsp;&nbsp;Search</b-btn>
            <b-btn @click="downloadExcel" :disabled="isFetching" class="col-md-2" type="button" variant="success"><span class="fa fa-file-excel"></span>&nbsp;&nbsp;Export To Excel</b-btn>
        </b-form>
    </b-card>

    <b-card class="mb-4">
      <vue-universal-table
        :api_url="'/api/reports/change-ownership'"
        :update="vue_universal_table.update"
        :fields="vue_universal_table.fields"
        :filter="filter"
        :fixed_header="true"
        :infinite_scroll="true"
        @fetchStart="isFetching = true"
        @fetchEnd="isFetching = false"
      ></vue-universal-table>
    </b-card>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'

export default {
  metaInfo: {
    title: 'Change Ownership Report'
  },
  data () {
    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    const endOfMonth   = moment().format('YYYY-MM-DD');
    return {
      isFetching : false,

      rangeConfig: {
        altInput: true,
        animate: !isRTL()
      },
      infoPopup : this.$store.state.hoverInformation.report.changeOwnership,
      selected : {
        range: `${startOfMonth} to ${endOfMonth}`,
      },
      filter : {
        start : startOfMonth,
        end : endOfMonth,
        engine_no : '',
        frame_no : '',
        qrcode : '',
      },
      vue_universal_table : {
        fields : [
          {
            title : 'Engine No',
            variable : 'engine_no'
          },
          {
            title : 'Frame No',
            variable : 'frame_no'
          },
          {
            title : 'QR Code',
            variable : 'qrcode'
          },
          {
            title : 'Old Owner',
            variable : 'old_owner'
          },
          {
            title : 'New Owner',
            variable : 'new_owner'
          },
          {
            title : 'Change Date',
            variable : 'change_date'
          }
        ],
        update : 0,
      }
    }
  },
  components: {
    flatPickr
  },
  mounted () {

  },
  watch : {
    'selected.range' : function () {
      let splitDateRange = this.selected.range.split(' to ');
      this.filter.start = splitDateRange[0]
      this.filter.end = splitDateRange[1]
    }
  },
  methods: {
    async downloadExcel (filter) {
      let url = '/api/reports/change-ownership/download-excel?' + await this.initialiseFilter()

      this.isFetching = true;

      this.$http.get(url , {responseType : 'blob'})
      .then((resp)=>{
        const blob = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = "Change Ownership Data (" + this.selected.range + ")";
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((err)=>{
        console.log(err);
      })
      .finally(()=>{
        this.isFetching = false;
      })
    },
    initialiseFilter(){
      if(!(typeof this.filter === 'object' && this.filter !== null)){
        this.filterUrl = '';
        return;
      }
      var keys = Object.keys(this.filter);
      let stringUrl = '';
      var queryParams = this.filter;

      for(var i = 0 ; i < keys.length; i++){
        if(!queryParams[keys[i]])
          continue;

        if(queryParams[keys[i]] == '' || queryParams[keys[i]].length == 0)
          continue 

        if((keys[i] == 'user_type') && queryParams[keys[i]] == 'All')
          continue;
        
        // if(keys[i] == 'start_hour' || keys[i] == 'end_hour')
        //   queryParams[keys[i]] += ":00";

        stringUrl += `&${keys[i]}=${queryParams[keys[i]]}`;
        
      }
      return stringUrl;
    },
  }
}

function isRTL () {
  return document.documentElement.getAttribute('dir') === 'rtl' || document.body.getAttribute('dir') === 'rtl'
}
</script>

<style>
  .dateCss div input{
    border: 1px solid #e8e8e8;
    border-radius: 5px !important;
    height: 42px;
    margin-top: 3px;
    padding-left: 1% !important;
  }
  .dateCss div input::placeholder{
    padding-left: 1%;
  }
</style>
