<template>
  <div>
    <!-- FILTER STARTS -->
    <div>
      <filter-complete
        :page_name="'Workshop Traffic'"
        :seperated_range="true"
        :fetch_filter="filterUrl.initial"
        @onFilter="setFilterUrl"
        :popUp="infoPopup"
      ></filter-complete>
    </div>
    <!-- FILTER ENDS -->
    <div class="mb-4 p-4 card">
      <h5>Traffic Distribution
        <span class="col-md-1 col-sm-1 col-2">
            <i class="fa fa-info-circle fa-lg" id="trafficDistribution"></i>
            <b-popover
            target="trafficDistribution"
            title="Traffic Distribution"
            triggers="hover focus"
            placement="top"
            :content="infoPopup.trafficDistribution"
            ></b-popover>
        </span>
      </h5>
      <TrafficDistribution
        v-if="filterUrl.ready"
        :filterUrl="filterUrl.url"
      ></TrafficDistribution>
    </div>
    <!-- <div class="p-4 mb-4 card">
      <h5>Total Traffic</h5>
      <div class="d-flex row"><chart-h1registered class="w-100" :height="230" /></div>
    </div> -->
  </div>
</template>

<script>
/* eslint-disable */
import TrafficDistribution from './childComponents/TrafficDistribution'
import Vue from 'vue'
import VueChartJs from 'vue-chartjs'

import filterComplete from '@/components/miscellaneous/filter-complete'

export default {
  name: 'workshop-traffic',
  metaInfo: {
    title: 'Workshop Traffic'
  },
  data () {
    return {
      filterUrl : {
        initial : 0,
        ready : false,
        url : '',
      },
      infoPopup : this.$store.state.hoverInformation.h2.workshopTraffic
    }
  },
  components: {
    filterComplete,
    TrafficDistribution,
  },
  mounted () {
    this.filterUrl.initial++;
  },
  methods: {
    setFilterUrl(urlString){
      this.filterUrl.url = urlString;
      this.filterUrl.ready = true;
    },
  }
}
</script>
