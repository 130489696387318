<template>
	<table class="tableBodyScroll table text-center">
		<thead>
			<tr style="background-color:black;color:white">
				<th>Month</th>
				<th>Year</th>
				<th>H1</th>
				<th>H2</th>
				<th>Total</th>
			</tr>
		</thead>
		<tbody>
			<tr v-for="(datum, index) in data" :key="index">
				<td>{{datum.bulan}}</td>
				<td>{{datum.tahun}}</td>
				<td>{{numberWithCommas(datum.h1)}}</td>
				<td>{{numberWithCommas(datum.h2)}}</td>
				<td>{{numberWithCommas(datum.total)}}</td>
			</tr>
			<tr>
				<td colspan="2"><b>Total QR</b></td>
				<td>{{numberWithCommas(dataTotal.h1)}}</td>
				<td>{{numberWithCommas(dataTotal.h2)}}</td>
				<td>{{numberWithCommas(dataTotal.total)}}</td>
			</tr>
		</tbody>
	</table>
</template>

<script>
export default {
	props: ['data'],
	data () {
		return {
			dataTotal : {
				month : 'Total QR',
				year : '',
				h1 : 0,
				h2 : 0,
				total : 0
			}
		}
	},
	methods: {
		numberWithCommas(x) {
			return parseInt(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		},
	},
	mounted () {
		this.calculateTotals()
	},
	methods : {
		calculateTotals () {
			for(var i = 0 ;  this.data.length ; i++) {
				this.dataTotal.h1 += parseInt(this.data[i].h1)
				this.dataTotal.h2 += parseInt(this.data[i].h2)
				this.dataTotal.total += parseInt(this.data[i].total)
			}
		}
	}
}
</script>