<template>
    <div style="padding: 1.7rem">
        <div class="row">
            <div class="col-md-8">
                <h4>Total Stock Overview
                    <span>
                        <i class="fa fa-info-circle fa-sm" id="totalStockOverview"></i>
                        <b-popover
                            target="totalStockOverview"
                            title="Total Stock Overview"
                            triggers="hover focus"
                            placement="top"
                            :content="infoPopup.totalStockOverview"
                        ></b-popover>
                    </span>
                </h4>
            </div>
            <!-- <div class="col-md-4 text-right">
                <button
                    class="btn btn-outline-download"
                    @click="modal.downloadDetail = true"
                >Download Detail&nbsp;&nbsp;<i class="fas fa-download"></i></button>
            </div> -->
        </div>

        <div class="row mt-5 mb-5">
            <div class="col-md col-12 mt-1">
                <div class="row">
                    <div class="col-6 text-right">
                        <img src="@/assets/img/WarehouseStock/IntransitAHM.png" style="width:100%;height:auto" />
                    </div>
                    <div class="col-6 text-left">
                        <h5 class="headingStats">In Transit<br>AHM - MD</h5>
                        <h5 class="headingStatsValue" v-if="data.stockOverviewTotal">{{numberWithCommas(data.stockOverviewTotal.intransit_ahm_md)}}</h5>
                        <h5 class="headingStatsValue" v-else>Loading......</h5>
                    </div>
                </div>
            </div>
            <div class="col-md col-12 mt-1 leftBorder">
                <div class="row">
                    <div class="col-6 text-right">
                        <img src="@/assets/img/WarehouseStock/MDStock.png" style="width:100%;height:auto" />
                    </div>
                    <div class="col-6 text-left">
                        <h5 class="headingStats">Main Dealer<br>Stock</h5>
                        <h5 class="headingStatsValue" v-if="data.stockOverviewTotal">{{numberWithCommas(data.stockOverviewTotal.md_stock)}}</h5>
                        <h5 class="headingStatsValue" v-else>Loading......</h5>
                    </div>
                </div>
            </div>
            <div class="col-md col-12 mt-1 leftBorder">
                <div class="row">
                    <div class="col-6 text-right">
                        <img src="@/assets/img/WarehouseStock/IntransitMD.png" style="width:100%;height:auto" />
                    </div>
                    <div class="col-6 text-left">
                        <h5 class="headingStats">In Transit<br>MD - D</h5>
                        <h5 class="headingStatsValue" v-if="data.stockOverviewTotal">{{numberWithCommas(data.stockOverviewTotal.intransit_md_d)}}</h5>
                        <h5 class="headingStatsValue" v-else>Loading......</h5>
                    </div>
                </div>
            </div>
            <div class="col-md col-12 mt-1 leftBorder">
                <div class="row">
                    <div class="col-6 text-right">
                        <img src="@/assets/img/WarehouseStock/TotalStock.png" style="width:100%;height:auto" />
                    </div>
                    <div class="col-6 text-left">
                        <h5 class="headingStats">Total<br>Stock</h5>
                        <h5 class="headingStatsValue" v-if="data.stockOverviewTotal">{{numberWithCommas(data.stockOverviewTotal.total_stock)}}</h5>
                        <h5 class="headingStatsValue" v-else>Loading......</h5>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-grey" style="height:10px;">
        </div>

        <div class="row mt-5">
            <div class="col-md-4 col-12">
                <h4>Warehouse Stock
                    <span>
                        <i class="fa fa-info-circle fa-sm" id="warehouseStock"></i>
                        <b-popover
                            target="warehouseStock"
                            title="Warehouse Stock"
                            triggers="hover focus"
                            placement="top"
                            :content="infoPopup.warehouseStock"
                        ></b-popover>
                    </span>
                </h4>
            </div>
            <div class="col-md-8 mt-1">
                <div class="row">
                    <div class="col text-right">
                        <Multiselect 
                            :options="options.segment.map(x => x.segment)"
                            :custom-label="opt => opt"
                            placeholder="All Segment"
                            class="multiselect-total-stock-overview"      
                            v-model="filter.stockOverview.segment"      
                        />
                    </div>
                    <div class="col text-right">
                        <Multiselect 
                            :options="options.type.map(x => x.type)"
                            :custom-label="opt => opt"
                            placeholder="All Type"      
                            class="multiselect-total-stock-overview"     
                            v-model="filter.stockOverview.type"  
                        />
                    </div>
                    <div class="col text-right">
                        <Multiselect 
                            :options="options.color.map(x => x.color_code)"
                            :custom-label="opt => options.color.find(x => x.color_code == opt).name"
                            placeholder="All Color"  
                            class="multiselect-total-stock-overview"
                            v-model="filter.stockOverview.color" 
                        />

                    </div>
                    <div class="col text-right">
                        <button
                            class="btn btn-outline-download"
                            @click="downloadWarehouseStockDetail"
                        >Download Detail&nbsp;&nbsp;<i class="fas fa-download"></i></button>
                    </div>
                </div>
                
            </div>
        </div>

        <table class="warehouseStockTable table tableBodyScroll mt-4 table-borderless">
            <thead>
                <tr>
                    <th>Type</th>
                    <th>Segment</th>
                    <th>Color</th>
                    <th>Region</th>
                    <th>In-Transit AHM - MD</th>
                    <th>MD Stock</th>
                    <th>In Transit MD - D</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(dat, index) in data.stockOverview" :key="index">
                    <td>{{dat.type ? dat.type : '-'}}</td>
                    <td>{{dat.segment ? dat.segment : '-'}}</td>
                    <td>{{dat.color ? dat.color : '-'}}</td>
                    <td>{{dat.region ? dat.region : '-'}}</td>
                    <td>{{numberWithCommas(dat.intransit_ahm_md)}}</td>
                    <td>{{numberWithCommas(dat.stock_md)}}</td>
                    <td>{{numberWithCommas(dat.intransit_md)}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import XLSX from 'xlsx'
    import Multiselect from 'vue-multiselect'

    export default {
        components : {
            Multiselect
        },
        props : ['filter' , 'data'],
        data () {
            return {
                infoPopup : this.$store.state.hoverInformation.highlightWarehouse.stockOverview,

                options : Object.assign({} , this.$store.getters.getFilterOptions)
            }
        },
        watch : {
            'filter.segment' : function () {
                this.filter.type = null
                
                this.options.type = this.$store.getters.getType(null , null)
                if(this.filter.segment) 
                    this.options.type = this.options.type.filter(x => x.segment == this.filter.segment)
            }
        },
        mounted () {

        },
        methods : {
            downloadWarehouseStockDetail () {
                let wb = XLSX.utils.book_new()
                var ws = XLSX.utils.json_to_sheet(this.data.stockOverview, {raw:true});
                XLSX.utils.book_append_sheet(wb, ws, 'Data')

                let exportFileName = `Warehouse Stock Detail.xlsx`
                XLSX.writeFile(wb, exportFileName)
            },
        }
    }
</script>

<style scoped>
    thead {
        border-top: 1px solid #DBDBDB;
        border-bottom: 1px solid #DBDBDB;
    }

    th {
        padding-left: 2rem;
    }

    td {
        padding-left: 2rem;
    }

    .btn-outline-download {
        min-height: 40px;
    }
    h4 {
        font-style: normal;
        font-variant: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        font-family: Ubuntu;
    }

    .bg-grey {
        background-color: #FBFBFB;
    }

    .headingStats {
        font-style: normal;
        font-variant: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 13px;
        color : #707070;
        font-family: Ubuntu;
    }

    .headingStatsValue {
        font-style: normal;
        font-variant: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color : #707070;
        font-family: Ubuntu;
    }

    .leftBorder {
        border-left: 1px solid #DBDBDB;
    }

    .multiselect-total-stock-overview {
        box-shadow: 0px 2px 6px #0000001A;
        border-radius: 5px;
        font: normal normal 500 10px/11px Ubuntu;
        color: #676767;
    }

    .warehouseStockTable th, .warehouseStockTable td {
        text-align: center;
    }
    
</style>

<style>
    .multiselect__placeholder {
        font-size:12px;
    }

    .multiselect-total-stock-overview > .multiselect__tags {
        border : 0px solid white;
    }

</style>