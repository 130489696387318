<template>
  <div id="ExistingIdChartStackedColumn" style="width: 100%; height: 500px"></div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

export default {
  name: "ExistingIdChartStackedColumn",
  props: {
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
    selectedYear: {
      type: [String, Number],
      default: null,
    },
    selectedChartStacked: {
      type: Object,
      default: null,
    },
    chartData: {
      type: Array, // Accept chart data directly from the parent
      required: true,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    startDate() {
      this.updateChart();
    },
    endDate() {
      this.updateChart();
    },
    selectedYearGlobal() {
      this.updateChart();
    },
    selectedChartStacked() {
      this.updateChart();
    },
    chartData: {
      handler(newData) {
        this.updateChart(newData); // Update chart whenever chartData changes
      },
      immediate: true,
    },
  },
  mounted() {
    this.createChart(this.chartData); // Create the chart when the component is mounted
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose(); // Ensure the chart is destroyed when the component is destroyed
      this.chart = null; // Clear the reference to avoid issues when re-creating the chart
    }
  },
  methods: {
    createChart(data) {
      let chart = am4core.create("ExistingIdChartStackedColumn", am4charts.XYChart);
      chart.data = data;

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "month";

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = "Registrations";

      const colors = ["#f2bc80", "#55b586", "#4e80ed", "#c2b6f6"];

      this.createSeries(chart, "2024", "2024", colors[3]);
      this.createSeries(chart, "2023", "2023", colors[2]);
      this.createSeries(chart, "2022", "2022", colors[1]);
      this.createSeries(chart, "Older", "<=2021", colors[0]);

      chart.cursor = new am4charts.XYCursor();

      // Add a legend
      chart.legend = new am4charts.Legend();

      // Modify global tooltip behavior
      chart.tooltip.pointerOrientation = "vertical";
      chart.tooltip.animationDuration = 150;

      this.chart = chart;
    },
    updateChart(data = this.chartData) {
      if (this.chart) {
        this.chart.data = data;
        this.chart.invalidateData(); // Refresh chart with new data
      } else {
        this.createChart(data); // If the chart is not initialized, create it
      }
    },
    createSeries(chart, field, name, color) {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "month";
      series.name = name;
      series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
      series.stacked = true;
      series.columns.template.fill = am4core.color(color);
      series.columns.template.stroke = am4core.color(color);
      series.columns.template.column.cornerRadiusTopLeft = 5;
      series.columns.template.column.cornerRadiusTopRight = 5;
      series.columns.template.column.cornerRadiusBottomLeft = 5;
      series.columns.template.column.cornerRadiusBottomRight = 5;
      series.columns.template.width = am4core.percent(50);

      // Custom tooltip
      let tooltip = series.tooltip;
      tooltip.pointerOrientation = "vertical";
      tooltip.background.cornerRadius = 5;
      tooltip.background.fill = am4core.color(color);
      tooltip.background.stroke = am4core.color(color).lighten(-0.2);
      tooltip.background.strokeOpacity = 0.8;
      tooltip.label.fill = am4core.color("#ffffff");
      tooltip.label.textAlign = "middle";

      // Tooltip text
      series.tooltipText = "{name}: [bold]{valueY}[/]";

      return series;
    },
  },
};
</script>

<style>
#ExistingIdChartStackedColumn {
  width: 100%;
  height: 500px;
}
</style>
