<template>
  <div>
    <!-- FILTER STARTS -->
    <div>
      <filter-complete
        :page_name="'QR Registration'"
        :seperated_range="true"
        :fetch_filter="filterUrl.initial"
        @onFilter="setFilterUrl"
        :popUp="infoPopup"
        :days_limit="31"
        export_url="/api/h2/qr-registration/export"
      ></filter-complete>
    </div>
    <!-- FILTER ENDS -->
    <div class="p-4 mb-4 card flex-column">
      <h5>Registration by Date
        <span class="col-md-1 col-sm-1 col-2">
            <i class="fa fa-info-circle fa-lg" id="registrationByDate"></i>
            <b-popover
            target="registrationByDate"
            title="Registration by Date"
            triggers="hover focus"
            placement="top"
            :content="infoPopup.registrationByDate"
            ></b-popover>
        </span>
      </h5>
      <div class="row">
        <!-- <div :class="(mobile) ? 'col-12' : 'col-8'"> -->
        <div class="col">
          <by-date
            v-if="filterUrl.ready"
            :filterUrl="filterUrl.url"
          ></by-date>
        </div>
        <!-- <div v-if="!mobile" class="col-4">
          <by-region
            v-if="filterUrl.ready"
            :filterUrl="filterUrl.url"
          ></by-region>
        </div> -->
      </div>
      <div class="row">
        <div class="col">
          <by-date-table
            v-if="filterUrl.ready"
            :filterUrl="filterUrl.url"
            :time_choices="timeChoices"
            type="region"
          ></by-date-table>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <by-date-table
            v-if="filterUrl.ready"
            :filterUrl="filterUrl.url"
            :time_choices="timeChoices"
            type="dealer"
          ></by-date-table>
        </div>
      </div>
    </div>

    <div class="p-4 mb-4 card flex-column" v-if="mobile">
      <h5>Registration by Region
        <span class="col-md-1 col-sm-1 col-2">
            <i class="fa fa-info-circle fa-lg" id="registrationByRegion"></i>
            <b-popover
            target="registrationByRegion"
            title="Registration by Region"
            triggers="hover focus"
            placement="top"
            :content="infoPopup.registrationByRegion"
            ></b-popover>
        </span>
      </h5>
      <by-region
        v-if="filterUrl.ready"
        :filterUrl="filterUrl.url"
      ></by-region>
    </div>

    <div class="p-4 mb-4 card flex-column">
      <h5>Registration by Hour
        <span class="col-md-1 col-sm-1 col-2">
            <i class="fa fa-info-circle fa-lg" id="registrationByHour"></i>
            <b-popover
            target="registrationByHour"
            title="Registration by Hour"
            triggers="hover focus"
            placement="top"
            :content="infoPopup.registrationByHour"
            ></b-popover>
        </span>
      </h5>
      <by-hour
        v-if="filterUrl.ready"
        :filterUrl="filterUrl.url"
      ></by-hour>
    </div>

    <div class="p-4 mb-4 card flex-column">
      <h5>Registration by Vehicle Segment
        <span class="col-md-1 col-sm-1 col-2">
            <i class="fa fa-info-circle fa-lg" id="registrationByVehicleSegment"></i>
            <b-popover
            target="registrationByVehicleSegment"
            title="Registration by Vehicle Segment"
            triggers="hover focus"
            placement="top"
            :content="infoPopup.registrationByVehicleSegment"
            ></b-popover>
        </span>
      </h5>
      <by-vehicle-segment
        v-if="filterUrl.ready"
        :filterUrl="filterUrl.url"
      ></by-vehicle-segment>
    </div>

    <!-- <div class="p-4 mb-4 card flex-column">
      <h5>Registration by Vehicle Type</h5>
      <totalNewQr :data="totalVehicleRegisteredByType.totalVehicleRegisteredByType" :isUpdate="isUpdate"></totalNewQr>
    </div> -->
    <!-- <div class="mb-4 p-4 card flex-column">
      <h5>Customer with Identifications</h5>
      <customerIdentity 
        v-if="filterUrl.ready"
        :filterUrl="filterUrl.url"
      ></customerIdentity>
    </div> -->
    <!-- <div class="mb-4 p-4 card flex-column">
      <h5>QR Code Registration by Region</h5>
      <ByRegion class="w-100"></ByRegion>
    </div> -->
  </div>
</template>

<script>
/* eslint-disable */
import qs from 'qs'

import totalNewQr from './childComponents/totalNewQr'

import filterComplete from '@/components/miscellaneous/filter-complete'

import byDate from './childComponents/byDate'
import byDateTable from './childComponents/byDateTable'
import byRegion from './childComponents/ByRegion'
import byHour from './childComponents/byHour'
import byVehicleSegment from './childComponents/byVehicleSegment'
import customerIdentity from './childComponents/customerIdentity'

import Vue from 'vue'

export default {
  name: 'qr-registered',
  metaInfo: {
    title: 'QR Registered'
  },
  data(){
    return{
      infoPopup: this.$store.state.hoverInformation.h2.qrRegistration,
      mobile : this.isMobile(),
      filterUrl : {
        initial : 0,
        ready : false,
        url : '',
      },
    }
  },
  components: {
    totalNewQr,
    filterComplete,
    byDate,
    byDateTable,
    byRegion,
    byHour,
    byVehicleSegment,
    customerIdentity,
  },
  mounted () {
    this.filterUrl.initial++;
  },
  computed : {
    timeChoices(){
      let monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      let obj = qs.parse(this.filterUrl.url)

      let startDate = new Date(obj.start)
      let endDate = new Date(obj.end)

      //IF start < end
      if(startDate.getTime() > endDate.getTime()) return [{label:'Invalid Date',value:'-1'}]

      var endMonth = endDate.getMonth()
      var endYear = endDate.getFullYear()

      var currentStartMonth = startDate.getMonth();
      var currentEndMonth = 11;//default, december
      
      var final = []

      for (var i = startDate.getFullYear(); i <= endYear; i++) {
        //IF current selected year IS last selected year, set last month
        if(i == endYear) currentEndMonth = endMonth;
        for (var j = currentStartMonth; j <= currentEndMonth; j++){
          final.push({
            label : `${monthNames[j]} ${i}`,
            value : `${i}-${j}`,//Year - Month
            month : `${j+1}`,
            year : `${i}`,
          })
        }
        currentStartMonth = 0;
      }

      return final;
    }
  },
  methods: {
    setFilterUrl(urlString){
      this.filterUrl.url = urlString;
      this.filterUrl.ready = true;
    },
  }
}
</script>

<style scoped>
  
</style>
