<script>
/* eslint-disable */
</script>

<template>
    <div>
        <b-card title="Real Time QR Registration" :sub-title="infoPopup.title" header-tag="h5" class="mb-4">
            <hr>
            <b-form>
                <b-form-row>
                    <b-form-group label="Region" class="col-md-3">
                        <Multiselect
                            class="mt-1"
                            v-model="filter.region"
                            :options="options.region.map(x => x.loc_code)"
                            :custom-label="opt => options.region.find(x => x.loc_code == opt).name"
                            :multiple="true"
                            placeholder="All Regions"
                        />
                    </b-form-group>
                    <b-form-group label="Branch" class="col-md-3">
                        <Multiselect 
                            class="mt-1"
                            v-model="filter.branch"
                            :options="options.branch.map(x => x.loc_code)"
                            :custom-label="opt => options.branch.find(x => x.loc_code == opt).name"
                            :multiple="true"
                            placeholder="All Branches"
                        />
                    </b-form-group>
                    <b-form-group label="Start Date" class="col-md-3 dateCss" style="height:42px" >
                        <datepicker name="start" id="start" :format="customFormatter" :disabled="true" bootstrap-styling v-model="filter.start"></datepicker>
                    </b-form-group>
                    <b-form-group label="End Date" class="col-md-3 dateCss" style="height:42px" >
                        <datepicker name="end" id="end" :format="customFormatter" :disabled="true" bootstrap-styling v-model="filter.end"></datepicker>
                    </b-form-group>
                </b-form-row>
                <b-form-row>
                    <b-form-group label="Segment" class="col-md-3">
                        <Multiselect 
                            class="mt-1"
                            v-model="filter.segment"
                            :options="options.segment.map(x => x.segment)"
                            :multiple="true"
                            placeholder="All Segments"
                        />
                    </b-form-group>
                    <b-form-group label="Series" class="col-md-3">
                        <Multiselect 
                            class="mt-1"
                            v-model="filter.series"
                            :options="options.series.map(x => x.series)"
                            :multiple="true"
                            placeholder="All Series"
                        />
                    </b-form-group>
                    <b-form-group label="Type" class="col-md-3">
                        <Multiselect 
                            class="mt-1"
                            v-model="filter.type"
                            :options="options.type.map(x => x.type)"
                            :multiple="true"
                            placeholder="All Vehicle Types"
                        />
                    </b-form-group>
                    <b-form-group label="Colour" class="col-md-3">
                        <Multiselect 
                            class="mt-1"
                            v-model="filter.colour"
                            :options="options.color.map(x => x.color_code)"
                            :custom-label="opt => options.color.find(x => x.color_code == opt).name"
                            :multiple="true"
                            placeholder="All Colours"
                        />
                    </b-form-group>
                </b-form-row>
                <b-form-row>
                    <b-form-group class="col-md-2">
                        <b-btn type="button" variant="primary" @click="vue_universal_table.update++" style="width: 100%">Filter</b-btn>
                    </b-form-group>
                    <b-form-group class="col-md-2">
                        <b-btn type="button" variant="success" @click="downloadQrRegistration(filter, 'Real Time QR Registration', true)"  style="width: 100%">Export XLSX</b-btn>
                    </b-form-group>
                </b-form-row>
            </b-form>
        </b-card>

        <div class="row">
            <div class="col-md-8 col-lg-12 col-xl-9">

                <!-- Popular queries -->
                <b-card no-body class="mb-4">
                    <div class="col-md-12 col-lg-12 col-xl-12 align-items-center p-4">
                        <h5>Registered Today
                            <span class="col-md-1 col-sm-1 col-2">
                                <i class="fa fa-info-circle fa" id="registeredToday"></i>
                                <b-popover
                                target="registeredToday"
                                title="Registered Today"
                                triggers="hover focus"
                                placement="top"
                                :content="infoPopup.registeredToday"
                                ></b-popover>
                            </span>
                        </h5>

                        <!-- <registered-today
                        ></registered-today> -->

                        <vue-universal-table
                            api_url="/api/h1/gi-qr-registration/table"
                            :filter="filter"
                            :pagination="false"
                            :disable_sort="true"

                            :fields="vue_universal_table.fields"
                            :process_data="vue_universal_table.process_data"
                            :update="vue_universal_table.update"

                            @totalRegistered="assignTotalRegistered"
                        ></vue-universal-table>

                    </div>
                </b-card>
                <!-- / Popular queries -->

            </div>
            <div class="col-md-4 col-lg-12 col-xl-3">

                <!-- Stats -->
                <div class="row">
                    <div class="col-sm-6 col-md-12 col-lg-6 col-xl-12">

                    <b-card no-body class="mb-4">
                        <b-card-header class="border-0 pb-0">Total Registered
                            <span class="col-md-1 col-sm-1 col-2">
                                <i class="fa fa-info-circle fa" id="totalRegistered"></i>
                                <b-popover
                                target="totalRegistered"
                                title="Total Registered"
                                triggers="hover focus"
                                placement="top"
                                :content="infoPopup.totalRegistered"
                                ></b-popover>
                            </span>
                        </b-card-header>
                        <b-card-body class="text-center text-success text-xlarge py-3">{{ totalRegistered ? numberWithCommas(totalRegistered) : 'No Data'}}</b-card-body>
                    </b-card>

                    </div>

                </div>
                <!-- / Stats -->

            </div>
            
        </div>
    </div>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';
    import Multiselect from 'vue-multiselect';
    import moment from 'moment';
    import qs from 'qs';

    export default {
        name: 'qr-registered',
        metaInfo: {
            title: 'QR Registered'
        },
        components: {
            Multiselect,
            Datepicker,
        },
        data() {

            const startDate = moment().format("YYYY-MM-DD")
            const endDate = moment().format("YYYY-MM-DD")
            // const startDate = '2021-06-01'
            // const endDate = '2021-06-02'

            var currDate = new Date();
            return {
                options : {
                    region : this.$store.getters.getRegion,
                    branch : this.$store.getters.getSimpleBranch(null, ['H1', 'H12']),
                    segment : this.$store.getters.getSegment,
                    series : this.$store.getters.getSimpleSeries(),
                    type : this.$store.getters.getSimpleType(),
                    color : this.$store.getters.getColor
                },
                filterData: null,
                filter: {
                    start: startDate,
                    end: endDate,
                    region: [],
                    branch: [],
                    segment: [],
                    series: [],
                    type: [],
                    colour: []
                },
                infoPopup: this.$store.state.hoverInformation.h1.qrRegistration.realTimeQRRegistration,
                totalRegistered : null,
                vue_universal_table : {
                    fields : [
                        {title:"Engine No", variable:"engine_no"},
                        {title:"Serial No", variable:"serial"},
                        {title:"QR Code", variable:"qrcode"},
                        {title:"Scan Source", variable:"scan_source"},
                        {title:"Region", variable:"region"},
                        {title:"Type", variable:"type"},
                        {title:"Color", variable:"color"},
                        {title:"Scan Date", variable:"scan_date"},
                        {title:"Created At", variable:"created_at"}
                    ],
                    update : 0,
                    process_data : "vm.$emit('totalRegistered', data.length)"
                },
            }
        },
        mounted() {

        },
        watch: {
            'filter.region': function () {
                this.filter.branch.length = 0;
                this.options.branch = this.$store.getters.getSimpleBranch(this.filter.region, ['H1', 'H12'])
            },
            'filter.segment': function () {
                this.filter.series.length = 0;
                this.options.series = this.$store.getters.getSimpleSeries(this.filter.segment)

                this.filter.type.length = 0;
                this.options.type = this.$store.getters.getSimpleType(this.filter.segment, this.filter.series)
            },
            'filter.series': function () {
                this.filter.type.length = 0;
                this.options.type = this.$store.getters.getSimpleType(this.filter.segment, this.filter.series)
            }
        },
        methods: {
            customFormatter: function (date) {
                return moment(date).format('Y-MM-DD');
            },
            assignTotalRegistered (x) {
                this.totalRegistered = x;
            },
        }
    }
</script>

<style>
    .dateCss div input{
    border: 1px solid #e8e8e8;
    border-radius: 5px !important;
    height: 42px;
    margin-top: 3px;
    padding-left: 1% !important;
    }

    .dateCss div input::placeholder{
    padding-left: 1%;
    }

</style>
    