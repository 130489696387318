<script>/* eslint-disable */</script>
<template>
  <div ref="SalesAnalysisChart" class="h-100" style="min-height: 500px"></div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

export default {
  data () {
    return {
      chart: null,
    }
  },
  props:['data', 'filter'],
  mounted () {
    this.createChart()
    this.processData()
  },
  methods: {
    processData () {
      var length = this.chart.data.length;
      
      this.chart.addData(this.data, length);
    },
    createChart () {
      this.chart = am4core.create(this.$refs.SalesAnalysisChart, am4charts.XYChart);

      var gradient = new am4core.LinearGradient();
      gradient.addColor(am4core.color("#142850"));
      gradient.addColor(am4core.color("#FFF"));

      var gradient2 = new am4core.LinearGradient();
      gradient2.addColor(am4core.color("#142850"));
      gradient2.addColor(am4core.color("#FFF"));

      // Create axes
      let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
      console.log(this.filter.karesidenan)
      categoryAxis.dataFields.category = 'region';
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;
      categoryAxis.title.text = "Region"

      categoryAxis.renderer.labels.template.rotation = 60;
      categoryAxis.renderer.labels.template.verticalCenter = "middle";
      categoryAxis.renderer.labels.template.horizontalCenter = "left";

      let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = "No of Sales";
      valueAxis.min = 0;

      // Create series
      // let series = chart.series.push(new am4charts.ColumnSeries());
      // series.dataFields.valueY = "last_month_actual";
      // series.dataFields.categoryX = "karesidenan";
      // series.clustered = false;
      // series.name = "Last Month Actual";
      // series.columns.template.width = am4core.percent(70);
      // series.tooltipText = "Last Month Actual Sales of {categoryX}: [bold]{valueY}[/]";
      // series.columns.template.fill = gradient;

      let series2 = this.chart.series.push(new am4charts.ColumnSeries());
      series2.dataFields.valueY = "currData";
      series2.dataFields.categoryX = 'region'
      series2.clustered = false;
      series2.name = "Number Of Sales";
      series2.columns.template.width = am4core.percent(50);
      series2.tooltipText = "Sales of {categoryX}: [bold]{valueY}[/]";
      // series2.columns.template.fill = gradient2;

      let series3 = this.chart.series.push(new am4charts.LineSeries());
      series3.dataFields.valueY = "target";
      series3.dataFields.categoryX = 'region'
      series3.strokeWidth = 2;
      series3.name = "Target";
      series3.tooltipText = "Target Sales of {categoryX}: [bold]{valueY}[/]";
      series3.tooltipColorSource = am4core.color("#000")
      series3.tensionX = 0.8;
      series3.showOnInit = true;

      let interfaceColors = new am4core.InterfaceColorSet();

      let bullet = series3.bullets.push(new am4charts.Bullet());
      bullet.width = 12;
      bullet.height = 12;
      bullet.horizontalCenter = "middle";
      bullet.verticalCenter = "middle";
      
      let triangle = bullet.createChild(am4core.Triangle);
      triangle.stroke = interfaceColors.getFor("background");
      triangle.strokeWidth = 2;
      triangle.direction = "top";
      triangle.width = 12;
      triangle.height = 12;

      this.chart.cursor = new am4charts.XYCursor();
      this.chart.cursor.lineX.disabled = true;
      this.chart.cursor.lineY.disabled = true;

      let labelBullet = series2.bullets.push(new am4charts.LabelBullet())
      labelBullet.label.horizontalCenter = "middle";
      labelBullet.label.dy = -10;
      // labelBullet.label.text = "{values.valueX.workingValue.formatNumber('#.0as')}";
      labelBullet.label.text = "{values.valueY.workingValue}";
      labelBullet.locationY = 1;

      // Add legend
      this.chart.legend = new am4charts.Legend();
    }
  }
}
</script>