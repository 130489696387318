<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th>Transporter</th>
          <th>Target</th>
          <th>Average Duration</th>
          <th>%Hit Traget<br>(No. of Rit)</th>
          <th>%Miss Traget<br>(No. of Rit)</th>
          <th>1-3 Days</th>
          <th>4-6 Days</th>
          <th>7-9 Days</th>
          <th>>10 Days</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="dat in ((showAll) ? data : data.slice(0, 4))">
          <td style="text-decoration: underline;">{{ dat.transporter }}</td>
          <td>{{ dat.target }} Day{{(dat.target > 1) ? 's' : ''}}</td>
          <td>{{ dat.averageDuration }} Day{{(dat.averageDuration > 1) ? 's' : ''}}</td>
          <td>{{ dat.hitTargetPercent }}% ({{ numberWithCommas(dat.hitTargetValue) }})</td>
          <td>{{ dat.missTargetPercent }}% ({{ numberWithCommas(dat.missTargetValue) }})</td>
          <td>{{ dat.days_1_3 }}%</td>
          <td>{{ dat.days_4_6 }}%</td>
          <td>{{ dat.days_7_9 }}%</td>
          <td>{{ dat.days_10 }}%</td>
        </tr>
        <tr v-if="showAllButton">
          <td v-if="!showAll" colspan="9" class="moreButton" @click="showAll = true">Lihat Semua</td>
          <td v-else colspan="9" class="moreButton" @click="showAll = false">Sembunyikan</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        showAllButton : false,
        showAll : true,
        data : [],
      }
    },
    props:['filter','period'],
    mounted () {

    },
    watch : {
      'period' : function () {
        this.fetchData();
      },
      'filter' : {
        deep : true,
        immediate : true,
        handler : 'fetchData',
      },
      'data' : {
        deep : true,
        immediate : true,
        handler : function (){
          if (this.data.length > 4){
            this.showAllButton = true;
            this.showAll = false;
          }
        }
      }
    },
    methods : {
      fetchData () {
        var transporters = ['Niaga', 'Indotama', 'Prolink', 'Puninar', 'Pratama', 'Jasasistem']
        var data = []
        for (var i = 0; i < transporters.length; i++) {
          var target = this.randomInt(0, 15000);
          var hit = this.randomInt(0, target);
          data.push({
            transporter : transporters[i],
            target : this.randomInt(1, 3),
            averageDuration : (Math.random() * (3 - 1) + 1).toFixed(1),
            hitTargetPercent : Math.round(hit/target*100),
            hitTargetValue : hit,
            missTargetPercent : Math.round((target-hit)/target*100),
            missTargetValue : target-hit,
            days_1_3 : this.randomInt(0, 5),
            days_4_6 : this.randomInt(0, 5),
            days_7_9 : this.randomInt(0, 5),
            days_10 : this.randomInt(0, 5)
          })
        }
        this.data = data;
      }
    }
  }
</script>

<style scoped>
  table tr th, table tr td {
    text-align: center;
    vertical-align: middle;
  }

  table tr th {
    border-bottom: 1px solid #F75047;
  }

</style>