<template>
    <div ref="agingStockPieChart" style="min-height: 400px;"></div>
</template>

<script>
    /* Imports */
    import * as am4core from "@amcharts/amcharts4/core";
    import * as am4charts from "@amcharts/amcharts4/charts";
    import am4themes_animated from "@amcharts/amcharts4/themes/animated";

    export default {
        data () {
            return {
                chart : null,
            }
        },
        props : ['data', 'total'],
        mounted () {
            this.createChart();
            this.getData();
        },
        watch : {
            'data' : {
                handler : 'getData',
            }
        },
        methods : {
            getData () {
                let length = this.chart.data.length;

                let total = 0;
                if (this.data && this.data.length > 0) {
                    this.data.map(x=>total+=x.count);
                }

                this.label.text = `Total:\n${this.numberWithCommas(total)}`
                // this.label.adapter.remove("text");
                // this.label.adapter.add("text", (text, target) => {
                //     console.log('test');
                //     return "[font-size:18px]Total[/]:\n[bold font-size:30px]" + this.numberWithCommas(total) + "[/]";
                // })

                this.chart.addData(this.data && this.data.length > 0 ? this.data : [{label:'No Data', count:0}],length);
            },
            createChart () {
                /* Chart code */
                // Themes begin
                am4core.useTheme(am4themes_animated);
                // Themes end

                // Create chart instance
                this.chart = am4core.create(this.$refs.agingStockPieChart, am4charts.PieChart);

                // Set inner radius
                this.chart.innerRadius = am4core.percent(50);

                // Add and configure Series
                let pieSeries = this.chart.series.push(new am4charts.PieSeries());
                pieSeries.dataFields.value = "count";
                pieSeries.dataFields.category = "label";
                pieSeries.slices.template.stroke = am4core.color("#fff");
                pieSeries.slices.template.strokeWidth = 2;
                pieSeries.slices.template.strokeOpacity = 1;
                // Disable outer label
                pieSeries.labels.template.disabled = true;

                // This creates initial animation
                pieSeries.hiddenState.properties.opacity = 1;
                pieSeries.hiddenState.properties.endAngle = -90;
                pieSeries.hiddenState.properties.startAngle = -90;

                let legend = this.chart.legend = new am4charts.Legend();
                legend.labels.template.text = "[bold {color}]{name}[/]"
                legend.valueLabels.template.disabled = true;

                // let container = this.chart.chartContainer.createChild(am4core.Container)
                // container.layout = "absolute";
                // container.toBack();
                // container.width = am4core.percent(100);

                // let totalTitle = container.createChild(am4core.Label);
                // totalTitle.text =

                // Add label
                this.label = this.chart.seriesContainer.createChild(am4core.Label);
                this.label.textAlign = "middle";
                this.label.horizontalCenter = "middle";
                this.label.verticalCenter = "middle";
                // this.label.dy = -20;
                this.label.fontSize = 25;
                // this.label.adapter.add("text", (text, target) => {
                //     return "[font-size:18px]Total[/]:\n[bold font-size:30px]" + this.numberWithCommas(this.total) + "[/]";
                // })

            }
        }
    }
</script>

<style scoped>

</style>