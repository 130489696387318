/* eslint-disable */
const state = {
  region: null,
  branch: null,
  segment: null,
  series: null,
  type: null,
  color: null,
  month: null,
  year: null
}

const getters = {
  getRegion : (state) => {
    return state.region
  },

  //Branch
  getBranch : (state) => (region) => {
    if(region == null || region.length == 0) return state.branch
    let regions = region.map(a => a.loc_code);
    return state.branch.filter((branc) => { return regions.indexOf(branc.region_code) !== -1 })
  },
  getSimpleBranch : (state) => (region, type_dealer) => {
    // if (region == null || region.length == 0) return state.branch
    // let regions = typeof region == 'string' ? [region] : region
    // return state.branch.filter((branc) => { return regions.indexOf(branc.region_code) !== -1 })


    if(
      (region == null || (typeof region != 'string' && region.length == 0)) &&
      (type_dealer == null || (typeof type_dealer != 'string' && type_dealer.length == 0))
    ) return state.branch

    var regions = []
    var type_dealers = []
    var compiledStr = ""

    //Array of 'region' strings or a string of 'region'
    if (region != null && (typeof region == 'string' || region.length > 0)){
      regions = typeof region == 'string' ? [region] : region
      compiledStr += "regions.indexOf(x.region_code) !== -1 &&"
    }

    //Array of 'series' strings or a string of 'series'
    if(type_dealer != null && (typeof type_dealer == 'string' || type_dealer.length > 0)){
      type_dealers = typeof type_dealer == 'string' ? [type_dealer] : type_dealer
      compiledStr += "type_dealers.indexOf(x.type_dealer) !== -1 &&"
    }

    if(compiledStr != "") compiledStr = compiledStr.slice(0, compiledStr.length-3) + " })";
    compiledStr = "state.branch.filter(x => { return " + compiledStr;

    return eval(compiledStr)
  },
  getBranchByCode : (state) => (region) => {
    if(region == null || region == '') return state.branch
    return state.branch.filter(branc => branc.region_code == region)
  },

  getSegment : (state) => {
    return state.segment
  },
  getSeries : (state) => (segment) => {
    if(segment == null || segment.length == 0) return state.series
    let segments = segment.map(a => a.segment);
    return state.series.filter((seri) => { return segments.indexOf(seri.segment) !== -1 })
  },
  getSimpleSeries : (state) => (segment) => {
    if(segment == null || segment.length == 0) return state.series
    let segments = typeof segment == 'string' ? [segment] : segment
    return state.series.filter((seri) => { return segments.indexOf(seri.segment) !== -1 })
  },
  getType : (state) => (segment, series) => {
    if(
      (segment == null || segment.length == 0) &&
      (series == null || series.length == 0)
    ) return state.type

    var counter = 0;
    var segments = []
    var seriess = []
    var compiledStr = "state.type.filter((singleType) => { return "

    if (segment != null && segment.length > 0){
      segments = segment.map(a => a.segment)
      compiledStr += "segments.indexOf(singleType.segment) !== -1 &&"
      counter++;
    }

    if(series != null && series.length > 0){
      seriess = series.map(a => a.series)
      compiledStr += "seriess.indexOf(singleType.series) !== -1 &&"
      counter++;
    }

    if(counter != 0) compiledStr = compiledStr.slice(0, compiledStr.length-3) + " })";

    return eval(compiledStr)
  },
  getSimpleType : (state) => (segment, series) => {
    if(
      (segment == null || (typeof segment != 'string' && segment.length == 0)) &&
      (series == null || (typeof series != 'string' && series.length == 0))
    ) return state.type

    var segments = []
    var seriess = []
    var compiledStr = ""

    //Array of 'segment' strings or a string of 'segment'
    if (segment != null && (typeof segment == 'string' || segment.length > 0)){
      segments = typeof segment == 'string' ? [segment] : segment
      compiledStr += "segments.indexOf(singleType.segment) !== -1 &&"
    }

    //Array of 'series' strings or a string of 'series'
    if(series != null && (typeof series == 'string' || series.length > 0)){
      seriess = typeof series == 'string' ? [series] : series
      compiledStr += "seriess.indexOf(singleType.series) !== -1 &&"
    }

    if(compiledStr != "") compiledStr = compiledStr.slice(0, compiledStr.length-3) + " })";
    compiledStr = "state.type.filter((singleType) => { return " + compiledStr;

    return eval(compiledStr)
  },
  getColor : (state) => {
    return state.color
  },
  getMonth : (state) => {
    return state.month
  },
  getYear : (state) => {
    return state.year
  },
  getFilterOptions : (state) => {
    const filterOptions = {
      region: state.region,
      branch: state.branch,
      segment: state.segment,
      series: state.series,
      type: state.type,
      color: state.color,
      month: state.month,
      year: state.year
    }
    return filterOptions
  }
}

const mutations = {
  regionStatus (state, region) {
    if(region){
      state.region = region;
    } else {
      state.region = null;
    }
  },
  branchStatus (state, branch) {
    if(branch){
      state.branch = branch;
    } else {
      state.branch = null;
    }
  },
  segmentStatus (state, segment) {
    if(segment){
      state.segment = segment;
    } else {
      state.segment = null;
    }
  },
  seriesStatus (state, series) {
    if(series){
      state.series = series;
    } else {
      state.series = null;
    }
  },
  typeStatus (state, type) {
    if(type){
      state.type = type;
    } else {
      state.type = null;
    }
  },
  colorStatus (state, color) {
    if(color){
      state.color = color;
    } else {
      state.color = null;
    }
  },
  monthStatus (state, month){
      if(month){
        state.month = month;
      } else {
        state.month = null;
      }
  },
  yearStatus (state, year) {
    if(year){
      state.year = year;
    } else {
      state.year = null;
    }
  },
  filterOptionsStatus (state, filterOptions){
    if(filterOptions){
      state.region = (filterOptions['region'] != null) ? filterOptions['region'] : null;
      state.branch = (filterOptions['branch'] != null) ? filterOptions['branch'] : null;
      state.segment = (filterOptions['segment'] != null) ? filterOptions['segment'] : null;
      state.series = (filterOptions['series'] != null) ? filterOptions['series'] : null;
      state.type = (filterOptions['type'] != null) ? filterOptions['type'] : null;
      state.color = (filterOptions['color'] != null) ? filterOptions['color'] : null;
      state.month = (filterOptions['month'] != null) ? filterOptions['month'] : null;
      state.year = (filterOptions['year'] != null) ? filterOptions['year'] : null;
    } else {
      state.region = null;
      state.branch = null;
      state.segment = null;
      state.series = null;
      state.type = null;
      state.color = null;
      state.month = null;
      state.year = null;
    }
  }
}

const actions = {
  setRegion ({ commit }, region){
    commit('regionStatus', region);
  },
  setBranch ({ commit }, branch){
    commit('branchStatus', branch);
  },
  setSegment ({ commit }, segment){
    commit('segmentStatus', segment);
  },
  setSeries ({ commit }, series){
    commit('seriesStatus', series);
  },
  setType ({ commit }, type){
    commit('typeStatus', type);
  },
  setColor ({ commit }, color){
    commit('colorStatus', color);
  },
  setMonth ({ commit }, month){
    commit('monthStatus', month);
  },
  setYear ({ commit }, year){
    commit('yearStatus', year);
  },
  setFilterOptions ({ commit }, filterOptions){
    commit('filterOptionsStatus', filterOptions);
  },
}

export default{
  state,
  getters,
  mutations,
  actions
}