<script>/* eslint-disable */</script>
<template>
  <div>
    <div style="height:400px" ref="AverageAgingLineChart"></div>
    <div style="height:200px;overflow-y:scroll">
      <div ref="legend"/>
    </div>
  </div>
  
</template>

<script>

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import animated from "@amcharts/amcharts4/themes/animated.js";

am4core.useTheme(animated);

export default {
  data(){
    return {
      chart: null,
      valueAxis : null
    }
  },
  mounted(){
    this.createChart();
    this.loadData()
  },
  beforeDestroy(){
    this.chart.dispose()
  },
  watch: {
    type: function () {
      this.loadData()
    }
  },
  props: ['mdData', 'type', 'dData'],
  methods: {
    loadData () {
      while(this.chart.series.length > 0) {
        this.chart.series.removeIndex(0).dispose();
      }
      // var data = []
      let data = this.type == "MD" ? this.mdData : this.dData
      // for(var i = 1; i < 10; i++){
      //   data.push({
      //     day: i,
      //     surabaya : this.randomInt(2500, 3000),
      //     malang : this.randomInt(2000, 2200)
      //   })
      // }


      // //DATA PROCESSING STARTS

      if(data == null || data.length == 0) data = [{day: 1, 'No Data' : 1}]

      let length = this.chart.data.length
      let keys = Object.keys(data[0])

      for(var i = 0 ; i < keys.length ; i++) {
        if(keys[i] == 'day')
          continue
        this.createAxisAndSeries(keys[i], keys[i], false, i % 3);
      }

      this.chart.addData(data, length)
    },
    createChart () {
      this.chart = am4core.create(this.$refs.AverageAgingLineChart, am4charts.XYChart);
      
      this.chart.colors.step = 2;

      // Create axes
    //   let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
    //   dateAxis.renderer.minGridDistance = 50;
    //   dateAxis.title.text = "Stock Days"
      let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis())
      categoryAxis.dataFields.category = 'day'
      categoryAxis.title.text = "Days"

      // Create series
      this.valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
      this.valueAxis.title.text = "No of Vehicles"
      this.valueAxis.min = 0
      this.valueAxis.renderer.labels.template.fill = am4core.color("#000");

      // Add legend
      var legendContainer = am4core.create(this.$refs.legend, am4core.Container);
      legendContainer.width = am4core.percent(100);
      legendContainer.height = am4core.percent(100);
      this.chart.legend = new am4charts.Legend()
      this.chart.legend.parent = legendContainer

      this.chart.exporting.menu = new am4core.ExportMenu();

      // Add cursor
      // this.chart.cursor = new am4charts.XYCursor();
    },
    createAxisAndSeries(field, name, opposite, bullet) {
        
        let series = this.chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = field;
        series.dataFields.categoryX = "day";
        series.strokeWidth = 2;
        series.name = name;
        series.tooltipText = "{name}: [bold]{valueY}[/]";
        series.tensionX = 0.8;
        series.showOnInit = true;
        
        let interfaceColors = new am4core.InterfaceColorSet();
        
        switch(bullet) {
          case 0:
            let bullet = series.bullets.push(new am4charts.Bullet());
            bullet.width = 12;
            bullet.height = 12;
            bullet.horizontalCenter = "middle";
            bullet.verticalCenter = "middle";
            
            let triangle = bullet.createChild(am4core.Triangle);
            triangle.stroke = interfaceColors.getFor("background");
            triangle.strokeWidth = 2;
            triangle.direction = "top";
            triangle.width = 12;
            triangle.height = 12;
            break;
          case 1:
            let bullet1 = series.bullets.push(new am4charts.Bullet());
            bullet1.width = 10;
            bullet1.height = 10;
            bullet1.horizontalCenter = "middle";
            bullet1.verticalCenter = "middle";
            
            let rectangle = bullet1.createChild(am4core.Rectangle);
            rectangle.stroke = interfaceColors.getFor("background");
            rectangle.strokeWidth = 2;
            rectangle.width = 10;
            rectangle.height = 10;
            break;
          default:
            let bullet2 = series.bullets.push(new am4charts.CircleBullet());
            bullet2.circle.stroke = interfaceColors.getFor("background");
            bullet2.circle.strokeWidth = 2;
            break;
        }
        
        this.valueAxis.renderer.line.strokeOpacity = 1;
        this.valueAxis.renderer.line.strokeWidth = 2;
        this.valueAxis.renderer.line.stroke = series.stroke;
        // this.valueAxis.renderer.labels.template.fill = series.stroke;
        this.valueAxis.renderer.opposite = opposite;
      }
  }
}
</script>