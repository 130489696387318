/* eslint-disable */
const state = {
    company : null,
    companyLogo : null
}

const getters = {
    company : state => {
        return state.company
    },
    companyLogo : state => {
        return state.companyLogo
    },
    companyDetails : state => {
        const companyDetails = {
            company : state.company,
            companyLogo : state.companyLogo
        }
        return companyDetails
    }
}

const mutations = {
    companyStatus (state, company){
        if(company){
            this.state.company = company;
        } else {
            this.state.company = null;
        }
    },
    companyLogoStatus (state, companyLogo){
        if(companyLogo){
            this.state.companyLogo = companyLogo;
        } else {
            this.state.companyLogo = null;
        }
    },
    companyDetailsStatus (state, companyDetails){
        if(companyDetails){
            this.state['company'] = companyDetails['company'];
            this.state.companyLogo = companyDetails['companyLogo'];
        } else {
            this.state.company = null;
            this.state.companyLogo = null;
        }
    }
}

const actions = {
    setCompany ({ commit }, company){
        commit('companyStatus', company);
    },
    setCompanyLogo ({ commit }, companyLogo){
        commit('companyLogoStatus', companyLogo);
    },
    setCompanyDetails ({ commit }, companyDetails){
        commit('companyDetailsStatus', companyDetails);
    }
}

export default{
    state,
    getters,
    mutations,
    actions
}