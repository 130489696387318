<template>
    <div ref="wmoChart" style="min-height: 400px"></div>
</template>

<script>
    /* Imports */
    import * as am4core from "@amcharts/amcharts4/core";
    import * as am4charts from "@amcharts/amcharts4/charts";

    export default {
        data() {
            return {
                chart : null,
            }
        },
        props : ['data'],
        mounted() {
            this.createChart();
            this.getData();
        },
        watch : {
            'data' : {
                deep : true,
                handler : 'getData'
            },
        },
        methods: {
            getData () {
                let length = this.chart.data.length;

                let data = JSON.parse(JSON.stringify(this.data));

                for (var i = 0; i < data.length; i++) {
                    let value = data[i].good_issue;
                    let unreg = data[i].unregistered;
                    data[i].unregisteredPercent = unreg / value * 100;
                }

                this.chart.addData(data && data.length > 0 ? data : [{region: 'No Data', good_issue: 0, unregistered: 0, unregisteredPercent: 0}], length)

            },
            createChart() {
                /* Chart code */
                // Themes begin
                // Themes end

                // Create chart instance
                this.chart = am4core.create(this.$refs.wmoChart, am4charts.XYChart);
                this.chart.scrollbarX = new am4core.Scrollbar();

                // Create axes
                let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
                categoryAxis.dataFields.category = "region";
                categoryAxis.renderer.grid.template.location = 0;
                categoryAxis.renderer.minGridDistance = 60;
                categoryAxis.tooltip.disabled = false;

                let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
                valueAxis.renderer.minWidth = 50;
                valueAxis.min = 0;
                valueAxis.cursorTooltipEnabled = false;
                valueAxis.title.text = "Goods Issued";
                valueAxis.title.fontWeight = "bold";

                // Create series
                let series = this.chart.series.push(new am4charts.ColumnSeries());
                series.sequencedInterpolation = true;
                series.dataFields.valueY = "good_issue";
                series.dataFields.categoryX = "region";
                // series.tooltipText = "[{}: bold]{valueY}[/]";
                series.tooltipText = "Goods Issued: {valueY.formatNumber('#,###')}[/]";
                series.columns.template.strokeWidth = 0;
                series.name = "Goods Issued"

                series.tooltip.pointerOrientation = "vertical";

                series.columns.template.column.cornerRadiusTopLeft = 10;
                series.columns.template.column.cornerRadiusTopRight = 10;
                series.columns.template.column.fillOpacity = 0.8;

                var labelBullet = series.bullets.push(new am4charts.LabelBullet());
                labelBullet.label.text = "[bold]{valueY}";
                labelBullet.label.dy = -10;

                // on hover, make corner radiuses bigger
                let hoverState = series.columns.template.column.states.create("hover");
                hoverState.properties.cornerRadiusTopLeft = 0;
                hoverState.properties.cornerRadiusTopRight = 0;
                hoverState.properties.fillOpacity = 1;

                // series.columns.template.adapter.add("fill", (fill, target) => {
                //     return this.chart.colors.getIndex(target.dataItem.index);
                // })


                let paretoValueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
                paretoValueAxis.renderer.opposite = true;
                paretoValueAxis.min = 0;
                paretoValueAxis.max = 100;
                paretoValueAxis.strictMinMax = true;
                paretoValueAxis.renderer.grid.template.disabled = true;
                paretoValueAxis.numberFormatter = new am4core.NumberFormatter();
                paretoValueAxis.numberFormatter.numberFormat = "#'%'"
                paretoValueAxis.cursorTooltipEnabled = false;
                paretoValueAxis.title.text = "Unregistered";
                paretoValueAxis.title.fontWeight = "bold";

                let paretoSeries = this.chart.series.push(new am4charts.LineSeries())
                paretoSeries.dataFields.valueY = "unregisteredPercent";
                paretoSeries.dataFields.categoryX = "region";
                paretoSeries.yAxis = paretoValueAxis;
                paretoSeries.tooltipText = "Unregistered: {valueY.formatNumber('#.0')}% ({unregistered.formatNumber('#,###')})[/]";
                paretoSeries.bullets.push(new am4charts.CircleBullet());
                paretoSeries.strokeWidth = 2;
                paretoSeries.stroke = new am4core.InterfaceColorSet().getFor("alternativeBackground");
                paretoSeries.strokeOpacity = 0.5;
                paretoSeries.name = "Unregistered";

                // Cursor
                this.chart.cursor = new am4charts.XYCursor();
                this.chart.cursor.behavior = "panX";

                // Legend
                this.chart.legend = new am4charts.Legend();
            },
        }
    }
</script>

<style scoped>

</style>