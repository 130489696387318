<template>
  <transition name="modal">
      <div class="modal-mask">
          <div class="modal-wrapper" @click="$emit('closeModal')">
              <div class="row">
                  <div class="col-xs-0 col-sm-0 col-md-1 col-lg-2"></div>
                  <div class="col-xs-12 col-sm-12 col-md-10 col-lg-8">
                      <div class="modal-container" @click.stop>

                          <div class="modal-header row">
                            <div class="col-10">
                              <h2>{{type}}</h2>
                            </div>
                            <div class="col-2" style="justify-content: flex-end;">
                              <button type="button" class="close" @click="$emit('closeModal')">
                                  <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                          </div>
                          
                          <div class="modal-body">
                            <!-- <pre>
                              <code>{{str}}</code>
                            </pre> -->
                            <span style="white-space: pre;">
                              <code>{{JSON.stringify(data, undefined, 4)}}</code>
                            </span>
                          </div>
                      </div>
                  </div>
                  <div class="col-xs-0 col-sm-0 col-md-1 col-lg-2"></div>
              </div>
          </div>
      </div>
  </transition>
</template>

<script>
  export default {
    data(){
      return{
        mobile : this.isMobile(),
        str : null,
      }
    },
    props:['data', 'type'],
    mounted(){
      this.printPretty();
    },
    watch : {
      
    },
    methods:{
      printPretty(){
        var obj = {a:1, 'b':'foo', c:[false,'false',null, 'null', {d:{e:1.3e5,f:'1.3e5'}}]};
        this.str = JSON.stringify(obj, undefined, 4);
      },
      printPrettyColor(){
        this.str = this.syntaxHighlight(this.data);
      },
      syntaxHighlight(json) {
        console.log(JSON.stringify(json))
        json = JSON.stringify(json).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
          var cls = 'numberHighlight';
          if (/^"/.test(match)) {
            if (/:$/.test(match)) {
              cls = 'keyHighlight';
            } else {
              cls = 'stringHighlight';
            }
          } else if (/true|false/.test(match)) {
            cls = 'booleanHighlight';
          } else if (/null/.test(match)) {
            cls = 'nullHighlight';
          }
          return '<span class="' + cls + '">' + match + '</span>';
        });
      }
    }
  }
</script>

<style>

</style>

<style scoped>
  .modal-mask {
      position: fixed;
      z-index: 1020;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .5);
      display: table;
      transition: opacity .3s ease;
  }

  .modal-wrapper {
      display: table-cell;
      vertical-align: middle;
  }

  .modal-container {
      width: 100%;
      margin: 0px auto;
      padding: 20px 30px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
      transition: all .3s ease;
      font-family: Helvetica, Arial, sans-serif;
  }

  .modal-header h3 {
      margin-top: 0;
      color: #42b983;
  }

  .modal-body {
      margin: 20px 0;
      overflow: auto;
      max-height: 80vh;
      max-width: 80vw;
  }

  .modal-default-button {
      float: right;
  }

  /*
  * The following styles are auto-applied to elements with
  * transition="modal" when their visibility is toggled
  * by Vue.js.
  *
  * You can easily play with the modal transition by editing
  * these styles.
  */

  .modal-enter {
      opacity: 0;
  }

  .modal-leave-active {
      opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
  }

  /* MODAL STARTS */
  .modal-body{
      margin:0
  }

  .modal-body .moduleLine{
      margin-bottom: 0vh;
  }

  .modal-body .moduleLine a{
      text-decoration: none;
  }

  .modal-body .moduleLine .moduleBox:hover{
      background-color: #E0E0E0;
      transition: background-color 0.7s ease;
      color: #212121;
      transition: color 0.7s ease;
  }

  .modal-body .moduleLine .moduleBox{
      border:1px solid #BDBDBD;
      border-radius: 20px;
      padding: 20px 0;
      color:black;
      margin-bottom: 3vh;
  }

  .modal-body .moduleLine .moduleBox i{
      font-size:5vh;
  }

  h3.modalHeadline{
      margin:0 0 20px 0;
      font-weight: bold;
      text-align : center;
  }
  /* MODAL ENDS */

  code{
    background-color: transparent;
    color:black;
  }

  pre {outline: 1px solid #ccc; padding: 5px; margin: 5px; }
  .stringHighlight { color: green; }
  .numberHighlight { color: darkorange; }
  .booleanHighlight { color: blue; }
  .nullHighlight { color: magenta; }
  .keyHighlight { color: red; }

</style>