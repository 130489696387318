<template>
  <div>

    <div style="padding: 0px 2%;" class="mt-2">
        <div class="row shadow rounded heightStats" style="background-color:white;">
            <!-- <div class="col-12 col-md statsPadding h-100">
                <div class="borderRight borderBottom h-100" style="padding-right:2%;">
                    <div class="row Aligner" :class="(mobile) ? 'h-100 borderBottom' : 'h-100'">
                        <div class="col-6">
                            <img src="@/assets/img/HighlightsV2/Intransit.png" style="width:100%;"/>
                        </div>
                        <div class="col-6">
                            <p>Shipping List AHM</p>
                            <h4 v-if="data">{{numberWithCommas(data.slahm)}}</h4>
                            <h4 v-else>Loading.....</h4>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="col-12 col-md statsPadding h-100">
                <div class="borderRight borderBottom h-100" style="padding-right:2%;">
                    <div class="row Aligner" :class="(mobile) ? 'h-100 borderBottom' : 'h-100'">
                        <div class="col-6">
                            <img src="@/assets/img/HighlightsV2/Intransit.png" style="width:100%;"/>
                        </div>
                        <div class="col-6">
                            <p>In Transit AHM-MD</p>
                            <h4 v-if="data">{{numberWithCommas(data.intransit_ahm_to_md)}}</h4>
                            <h4 v-else>Loading.....</h4>
                        </div>
                    </div>
                    <!-- <div class="row Aligner mt-2" :class="(mobile) ? 'h-100' : 'h-50'">
                        <div class="col-6">
                            <img src="@/assets/img/Highlights/Intransit.png" style="width:100%;"/>
                        </div>
                        <div class="col-6">
                            <p>Virtual In Transit AHM-MD</p>
                            <h4 v-if="data">{{numberWithCommas(data.virtual_intransit_ahm_md)}}</h4>
                            <h4 v-else>Loading.....</h4>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="col-12 col-md statsPadding h-100">
                <div class="borderRight borderBottom h-100" style="padding-right:2%;">
                    <div class="row Aligner" :class="(mobile) ? 'h-100 borderBottom' : 'h-100'">
                        <div class="col-6">
                            <img src="@/assets/img/HighlightsV2/Dealer.png" style="width:100%;"/>
                        </div>
                        <div class="col-6">
                            <p>Main Dealer Stock</p>
                            <h4 v-if="data">{{numberWithCommas(data.maindealer)}}</h4>
                            <h4 v-else>Loading.....</h4>
                        </div>
                    </div>
                    <!-- <div class="row Aligner mt-2" :class="(mobile) ? 'h-100' : 'h-50'">
                        <div class="col-6">
                            <img src="@/assets/img/Highlights/Dealer.png" style="width:100%;"/>
                        </div>
                        <div class="col-6">
                            <p>Main Dealer Virtual Stock</p>
                            <h4 v-if="data">{{numberWithCommas(data.maindealer_virtual_stock)}}</h4>
                            <h4 v-else>Loading.....</h4>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="col-12 col-md statsPadding h-100">
                <div class="borderRight borderBottom h-100" style="padding-right:2%;">
                    <div class="row Aligner h-100">
                        <div class="col-6">
                            <img src="@/assets/img/HighlightsV2/Intransit.png" style="width:100%;"/>
                        </div>
                        <div class="col-6">
                            <p>In Transit MD - D</p>
                            <h4 v-if="data">{{numberWithCommas(data.intransit_md_to_d)}}</h4>
                            <h4 v-else>Loading.....</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md statsPadding h-100">
                <div class="borderRight borderBottom h-100" style="padding-right:2%;">
                    <div class="row Aligner h-100">
                        <div class="col-6">
                            <img src="@/assets/img/HighlightsV2/Dealer.png" style="width:100%;"/>
                        </div>
                        <div class="col-6">
                            <p>Dealer Stock</p>
                            <h4 v-if="data">{{numberWithCommas(data.dealer)}}</h4>
                            <h4 v-else>Loading.....</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md statsPadding h-100">
                <div style="padding-right:2%;" class="h-100">
                    <div class="row Aligner h-100">
                        <div class="col-6">
                            <img src="@/assets/img/HighlightsV2/Total.png" style="width:100%;"/>
                        </div>
                        <div class="col-6">
                            <p>Total Stock</p>
                            <h4 v-if="data">{{numberWithCommas(data.total_onhand)}}</h4>
                            <h4 v-else>Loading.....</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Total Stock Overview End-->
  </div>
</template>

<script>
  export default {
    data () {
      return {
        mobile : this.isMobile(),
      }
    },
    props: ['data'],
    mounted () {

    },
    methods : {
        numberWithCommas(x) {
            return parseInt(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    }
  }
</script>

<style scoped>
  @media screen and (min-width: 950px) {
      .borderRight {
        border-right: 2px solid #F4F4F4;
      }

      .heightStats {
        height: 225px;
      }

      .heightStockDays {
        height: 100%;
        min-height:400px;
      }

      .statsPadding {
        padding: 30px 1%;
      }

      h4 {
        overflow-wrap: break-word;
      }
  }

  @media only screen and (max-device-width: 768px) {
      .borderBottom {
        border-bottom: 2px solid #F4F4F4;
      }

      .heightStats {
        height: 100%;
      }

      .heightStockDays {
        height: 100%;
      }

      .statsPadding {
        padding: 15px 1%;
      }
  }
  .Aligner {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Aligner .col-6 p {
    margin-bottom: 10px;
  }
</style>
