/* eslint-disable */
import Vue from "vue";
import VueX from "vuex";
import App from "./App";
import router from "./router";

import BootstrapVue from "bootstrap-vue";

import globals from "./globals";
import Popper from "popper.js";
import * as VueGoogleMaps from "vue2-google-maps";

import env from "./env";

// import mixins
import mixinMethods from "@/assets/mixin/methods.js";

// apm plugin
import { ApmVuePlugin } from "@elastic/apm-rum-vue";

//axios
import Axios from "axios";
Axios.defaults.baseURL = env.urlStart;
Vue.prototype.$http = Axios; // to run axios, just use this.$http

//store
import storeOptions from "./store/store.js";

//import SweetAlert
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

//Custom table
import vueUniversalTable from "@/components/table/vue_universal_table.vue";

Vue.config.productionTip = true;

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false;

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(VueSweetalert2);
Vue.use(VueX);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyB-HQz5XF0qUyvvRjUjQU1raI_JT5gdNws",
    libraries: "places",
  },
});

Vue.component("vue-universal-table", vueUniversalTable);

Vue.mixin({
  // Global RTL flag
  data: globals,
  // Mixin Methods
  methods: mixinMethods,
});

//Google Analytics
import VueGtag from "vue-gtag";
Vue.use(
  VueGtag,
  {
    config: { id: env.gaId },
  },
  router
);

Vue.use(ApmVuePlugin, {
  router,
  config: {
    serviceName: env.serviceName
      ? env.serviceName
      : "hso-dashboard-development",
    // agent configuration
    serviceVersion: "1.0.0",
    environment: env.environment ? env.environment : "development",
    serverUrl: env.serverUrl ? env.serverUrl : "http://localhost:8200",
  },
});

//Leaflet Map
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import { Icon } from "leaflet";

Vue.component("l-map", LMap);
Vue.component("l-tile-layer", LTileLayer);
Vue.component("l-marker", LMarker);
Vue.component("l-popup", LPopup);

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

//Font Awesome
import "@fortawesome/fontawesome-free/css/all.css";

const store = new VueX.Store(storeOptions);

import hoverInformation from "./hoverInformation";

store.state.hoverInformation = hoverInformation.info;

router.beforeEach((to, from, next) => {
  let pageAuth = to.matched[0].meta.auth
    ? to.matched[0].meta.auth
    : to.meta.auth;
  let userData = store.state.userData.userData;
  var allowed_roles = ["SUPER ADMIN", "ADMIN"];

  // if (to.path == '/login' && userData) next({name : 'Highlight'})
  if (to.path == "/login" && userData) {
    console.log(userData);
    if (
      userData.modules.map((x) => parseInt(x)).indexOf(1) !== -1 ||
      allowed_roles.indexOf(userData.role) != -1
    )
      next({ name: "Highlight" });
    else if (userData.modules.map((x) => parseInt(x)).indexOf(50) !== -1)
      // 50 == Highlight Warehouse
      next({ name: "HighlightWarehouse" });
    else next({ name: "Welcome" });
  } else if (!pageAuth) next();
  else if (pageAuth && !userData) next("/login");
  else next();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
