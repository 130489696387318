<script>/* eslint-disable */</script>

<template>
  <div ref="AgingStockPieChart" style="height: 400px"></div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themesAnimated from '@amcharts/amcharts4/themes/animated'

export default {
  data () {
    return {
      chart: null,
      total: 0,
      selectedData: 'data'
    }
  },
  props: ['data', 'dealerData' , 'selected'],
  mounted () {
    this.createChart()
    this.generateData()
  },
  watch: {
    selected : function () {
      if(this.selected == 'MD')
        this.selectedData = 'data'
      else
        this.selectedData = 'dealerData'

      this.generateData()
    }
  },
  methods: {
    generateData () {  
      let length = this.chart.data.length
      let keys = Object.keys(this[this.selectedData])
      let data = []
      let total = 0

      for (var i = 0 ; i < keys.length; i++) {
        if(keys[i] == 'from_date' || keys[i]== 'to_date' || keys[i] == 'total')
          continue;
        let range = keys[i].split('day_')[1]
        let obj = {
          range: range.includes('_') ? range.replace('_' , ' - ') + '': '> ' + range + "",
          count: this[this.selectedData][keys[i]] ? this[this.selectedData][keys[i]] : 0
        }

        total += parseInt(obj.count)

        data.push(obj)
      }
      
      this.label2.text = parseInt(total).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      if(total == 0)
        data.push({
          range: 'No Data',
          count: 1
        })
      data.reverse()
      
      this.chart.addData(data, length)
    },
    createChart () {
      /* Chart code */
      // Themes begin
      am4core.useTheme(am4themesAnimated)
      // Themes end

      // Create chart instance
      this.chart = am4core.create(this.$refs.AgingStockPieChart, am4charts.PieChart)

      // Add and configure Series
      let pieSeries = this.chart.series.push(new am4charts.PieSeries())
      pieSeries.dataFields.value = 'count'
      pieSeries.dataFields.category = 'range'

      // pieSeries.colors.list = [
      //   am4core.color("#845EC2"),
      //   am4core.color("#D65DB1"),
      //   am4core.color("#FF6F91"),
      //   am4core.color("#FF9671"),
      //   am4core.color("#FFC75F"),
      //   am4core.color("#F9F871"),
      // ];

      // Let's cut a hole in our Pie chart the size of 30% the radius
      this.chart.innerRadius = am4core.percent(50)

      // Put a thick white border around each Slice
      pieSeries.slices.template.stroke = am4core.color('#fff')
      pieSeries.slices.template.strokeWidth = 2
      pieSeries.slices.template.strokeOpacity = 1
      pieSeries.slices.template.tooltipText = 'Vehicles of Aging Range {category} Days: [bold]{value}[/]'
      pieSeries.slices.template
        // change the cursor on hover to make it apparent the object can be interacted with
        .cursorOverStyle = [
          {
            'property': 'cursor',
            'value': 'pointer'
          }
        ]

      // pieSeries.alignLabels = false
      // pieSeries.labels.template.bent = true
      pieSeries.labels.template.radius = 3
      pieSeries.labels.template.disabled = true

      var label = pieSeries.createChild(am4core.Label);
      label.text = "Unit Left";
      label.horizontalCenter = "middle";
      label.verticalCenter = "bottom";
      label.fontSize = 15;

      this.label2 = pieSeries.createChild(am4core.Label);
      this.label2.horizontalCenter = "middle";
      this.label2.verticalCenter = "top";
      this.label2.fontWeight = "bold";
      this.label2.fontSize = 25;

      pieSeries.ticks.template.disabled = true

      // Create a base filter effect (as if it's not there) for the hover to return to
      let shadow = pieSeries.slices.template.filters.push(new am4core.DropShadowFilter())
      shadow.opacity = 0

      // Create hover state
      let hoverState = pieSeries.slices.template.states.getKey('hover') // normally we have to create the hover state, in this case it already exists

      // Slightly shift the shadow and make it more prominent on hover
      let hoverShadow = hoverState.filters.push(new am4core.DropShadowFilter())
      hoverShadow.opacity = 0.7
      hoverShadow.blur = 5

      // Add a legend
      this.chart.legend = new am4charts.Legend()
    }
  }
}
</script>

<style scoped>

</style>
