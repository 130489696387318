/* eslint-disable */
const state = {
    userData : null
}

const getters = {
    currentUser : state => {
        return state.userData
    }
}

const mutations = {
    userStatus (state, user){
        if(user){
            state.userData = user;
        } else {
            state.userData = null;
        }
    }
}

const actions = {
    setUser ({ commit }, user){
        commit('userStatus', user);
    }
}

export default{
    state,
    getters,
    mutations,
    actions
}