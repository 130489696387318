<template>
    <div>
        <!--Filter Option-->
        <b-card title="Download Report" :sub-title="infoPopup.title" header-tag="h6" class="mb-4">
            <div class="row">

                <b-form-group label="Report" class="col-md-3">
                    <b-input class="mt-1" type="text" placeholder="Type report name" v-model="filter.report" />
                </b-form-group>

                <b-form-group label="Requested By" class="col-md-3">
                    <b-input class="mt-1" type="text" placeholder="Type user" v-model="filter.user" />
                </b-form-group>

                <b-form-group label="Status" class="col-md-3">
                    <Multiselect
                      v-model="filter.status"
                      class="mt-1"
                      :options="options.status.map(x => x.value)"
                      :custom-label="opt => options.status.find(x => x.value == opt).title"
                      placeholder="Choose Status"
                    ></Multiselect>
                </b-form-group>

                <b-form-group class="col-md-3" style="display: flex; flex-direction: column-reverse;">
                    <button
                        class="btn btn-primary w-100"
                        style="height: 40px;"
                        type="button"
                        @click="fetchData"
                    >
                        <!-- <i class="fas fa-sync"></i>&nbsp;&nbsp;&nbsp;Search -->
                        Search
                    </button>
                </b-form-group>

                <div class="col-md-3" style="display: flex; justify-content: flex-end;">
                </div>
            </div>
        </b-card>

        <b-card class="mb-4">
            <table class="table" v-if="data">
                <thead>
                    <tr>
                        <th>Report Name</th>
                        <th>Requested By</th>
                        <th>Filters Used</th>
                        <th>Status</th>
                        <th>Download Link</th>
                        <th>File Size</th>
                        <th>Requested At</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="dat in data">
                        <td>{{ dat.report_name }}</td>
                        <td>{{ dat.username }}</td>
                        <td>{{ dat.filters }}</td>
                        <td :style="{'color' : statusDownload[dat.status.toLowerCase()].color}">{{ dat.status }}</td>
                        <td>
                            <span v-if="dat.download_url">
                                <a target="_blank" rel="noopener noreferrer" :href="dat.download_url">{{ statusDownload[dat.status.toLowerCase()].label }}</a>
                            </span>
                            <span v-else>{{ statusDownload[dat.status.toLowerCase()].label }}</span>
                        </td>
                        <td>{{ dat.file_size ? dat.file_size : '-' }}</td>
                        <td>{{ dat.requested_at }}</td>
                    </tr>
                </tbody>
            </table>
            <fold v-else :loading="true"></fold>
        </b-card>
    </div>
</template>

<script>
    import qs from 'qs'
    import XLSX from 'xlsx'

    import flatPickr from 'vue-flatpickr-component'
    import moment from 'moment'
    import Multiselect from 'vue-multiselect'
    import { FoldSpinner } from 'vue-spinners'

    export default {
        metaInfo: {
            title: 'Download Report'
        },
        data() {
            return {
                infoPopup: this.$store.state.hoverInformation.report.downloadReport,
                data : null,
                statusDownload : {
                    'requested' : {
                        color : 'Grey',
                        label : 'In Queue'
                    },
                    'processing' : {
                        color : 'Orange',
                        label : 'Please Wait'
                    },
                    'ready' : {
                        color : 'Green',
                        label : 'Click Here to Download'
                    },
                    'data empty' : {
                        color : 'Blue',
                        label : '[No Data]'
                    },
                    'deleted' : {
                        color : 'Red',
                        label : '[Removed]'
                    }
                },
                options : {
                    status : [
                        {title : 'Requested', value : 0},
                        {title : 'Processing', value : 1},
                        {title : 'Ready', value : 2},
                        {title : 'Data Empty', value : 3},
                        {title : 'Deleted', value : 4}
                    ]
                },
                filter : {
                    report : '',
                    user : '',
                    status : ''
                }
            }
        },
        components: {
            flatPickr,
            Multiselect,
            fold: FoldSpinner
        },
        mounted() {
            this.fetchData();
        },
        methods: {
            fetchData () {
                this.data = null;

                //Set timeout to show loading bar
                setTimeout(()=>{
                    let filters = JSON.parse(JSON.stringify(this.filter))
                    for (var i in filters) if (filters[i] === '' || filters[i] === null) delete filters[i]

                    this.$http.get(`/api/reports/download-report${qs.stringify(filters, {skipNulls:true, addQueryPrefix:true})}`)
                    .then((resp)=>{
                        let data = resp.data.data;

                        //Process data
                        for (var i in data) {

                            //Removing all non used filters
                            let filter = [];
                            let filters = data[i].filters.split(",");
                            for (var j in filters) {
                                if (filters[j].split(":")[1] != ' ') filter.push(filters[j]);
                            }

                            data[i].filters = filter.join(", ");
                            data[i].requested_at = moment(data[i].requested_at).format("DD MMM YYYY HH:mm:ss");
                        }

                        this.data = data;
                    })
                    .catch((err)=>{console.log(err)})
                }, 1000)                
            }   
        }
    }
</script>

<style>
    thead tr th,
    tbody tr td {
        text-align: center;
        vertical-align: middle;
    }
</style>