let info = {
    highlight : {
        stockOverview : {
            totalStockOverview : 'Jumlah Total Stok.',
            mainDealerStock : 'Jumlah Stok Main Dealer.',
            dealerStockInRegion : 'Jumlah Stock Dealer Di Region.'
        },
        stockAnalytics : {
            stockDays : 'Rata - rata stock dalam 1 periode.',
            agingStockAnalysis : 'Analisa lama kendaraan dalam gudang.',
            averageAgingStock : 'Jumlah rata rata Stok tersimpan.'
        },
        salesAnalysis : {
            salesAnalysis : 'Analisa penjualan.',
            salesMainDealer : 'Penjualan di main dealer.',
            salesDealer : 'Penjualan di dealer.'
        },
        territoryManagement : {
            territoryManagement : 'Fitur yang menunjukkan persebaran pembeli pada suatu dealer, ditunjukkan dalam bentuk peta.'
        },
        mappingSummary : {
            mappingSummary : "Informasi tentang jumlah mapping antara QR dan nomor unik kendaraan yang telah terajadi di H1 dan H2 dalam periode yang dipilih."
        },
        serviceAnalysis : {
            serviceAnalysis : 'Jumlah rata - rata service per dealer.',
            top10DealersByService : '10 dealer dengan jumlah service tertinggi.'
        },
        lateDeliveryAndShipping : {
            ahmToMd : "Jumlah pengiriman kendaraan yang terjadi dari AHM ke MD",
            mdToD : "Jumlah pengiriman kendaraan yang terjadi dari MD ke D"
        }
    },
    highlightWarehouse : {
        stockOverview : {
            totalStockOverview : 'Jumlah stock kendaraan yang berada di in-transit dan gudang',
            warehouseStock : 'Daftar perincian kendaraan yang berada di in-transit dan gudang'
        },
        stockAnalytics : {
            agingStock : 'Lamanya kendaraan yang berada di gudang berdasarkan tanggal GR yang dikirmkan hingga hari ini',
            detailAgingStock : 'Detail data kendaraan yang masih berada di gudang warehouse dengan rentang lama waktu penyimpanan'
        },
        qrRegistrationControl : {
            qrRegistrationControl : 'Data jumlah kendaraan yang sudah termapping QR. Data yang ditampilkan adalah H-1 saja',
            stats : {
                goodsReceived : 'Jumlah kiriman data GRAHM TO MD tertampil pada waktu H-1',
                goodsIssue : 'Jumlah kiriman data GIMD TO D tertampil pada waktu H-1',
                mappingQr : 'Jumlah kiriman data GIMD TO D yang sudah termapping dengan QR Code saat dikirimkan. Data yang di tampilkan adalah data H-1',
                unregistered : 'Jumlah kiriman data GIMD TO D yang dikirimkan yang belum termapping dengan QR Code. Data yang di tampilkan adalah data H-1',
            },
            warehouseMappingOverview : 'Jumlah mapping yang dilakukan pada warehouse berdasarkan jumlah data GI yang dikirimkan',
            unregisteredDetails : 'Jumlah kendaraan yang belum di mapping dengan qrcode berdasarkan data GI yang dikirmkan pada H-1'
        },
    },
    h1 : {
        qrRegistration : {
            realTimeQRRegistration : {
                title : 'Laporan registrasi QR hari ini',
                registeredToday : 'Detil QR yang di registrasi hari ini',
                totalRegistered : 'Total QR yang telah di registerasi hari ini'
            },
            dailyQrRegistration : {
                title : 'Laporan registrasi QR harian dihitung maksimal h-1',
                dailyRegisteredChart : 'Registrasi harian.',
                totalRegistered : 'Total Registrasi QR',
                avgDailyRegistered : 'Rata rata Registrasi per hari.',
                vehicleMappingSummary : 'Ringkasan dari mapping kendaraan dengan QR Code.',
                registeredByRegion : 'Registrasi QR per Region.',
                registeredBySegment : 'Registrasi QR per Segment.',
                registeredBySeries : 'Grafik Perbandingan Registrasi QR per series '
            },
            monthlyQrRegistration : {
                title : 'Registrasi QR bulanan dihitung maksimal h-1',
                vehicleMapping : 'Pemetaan kendaran pada main dealer'
            },
            replacedQr : {
                title : 'Analisa Pergantian QR Code di H1',
                qrReplacement : 'Grafik ini menunjukkan Jumlah pergantian QR di H1 per hari.',
                qrReplacementReasons : 'Grafik ini menunjukkan statistik alasan Pergantian QR pada Motor.'
            },
        },
        stockDetails: {
            stockAvailability : {
                title : 'Detail dari Stok yang Tersedia',
                stockByLocation: 'Grafik ini merepresentasikan lokasi Stok motor yang tersedia.',
                currentEndingStock: 'Total jumlah Stok motor tersedia.',
                mostSeriesInStock: 'Series Stok motor terbanyak.',
                stockAvailibilityBySegmentAndSeries : 'Grafik ini menunjukkan Statistik Jumlah Stok Motor Tersedia per Segment dan Series.',
                stockAvailabilityByTypesAndColour : 'Grafik ini menunjukkan Statistik Jumlah Stok Motor Tersedia dan Series yang terpilih per Tipe dan Warna Motor.'
            },
            stockIntransit: {
                title : 'Detail dari Stok yang sedang dalam pengiriman',
                stockIntransit: 'Grafik ini menunjukkan jumlah motor yang dikirim dari masing main dealer kepada tujuan dealer tersebut.',
                totalIntransit: 'Total Stok yang sedang dalam pengiriman.',
                intransitSummary: 'Tabel ini merepresentasikan angka jumlah Motor yang sedang dalam pengiriman oleh Main Dealer.'
            },
            averageDaysOfStock: {
                title : 'Stok kendaraan rata-rata per hari',
                averageDaysOfStockByLocation: 'Grafik ini merepresentasikan rata rata stok kendaraan harian per lokasi.',
                averageDaysOfStockBySegmentAndSeries : 'Grafik ini menunjukkan rata rata stok kendaraan harian per Segment dan Series.',
                averageDaysOfStockByTypesAndColour : 'Grafik ini menunjukkan rata rata stok kendaraan harian dan Series yang terpilih per Tipe dan Warna Motor.'
            },
            agingStock: {
                title: 'Detail dari lamanya stok yang saat ini masih di gudang',
                agingRangeBySegments: 'Grafik ini menunjukkan statistik rentang umur stok berdasarkan segment.',
                agingTimeBySegments: 'Tabel dibawah ini menunjukkan umur stok berdasarkan segment.'
            }
        },
        sales : {
            regionalSales : {
                title : 'Analisa Penjualan Motor di Main Dealer',
                stats : {
                    totalSales : 'Total Penjualan Motor di Main Dealer.',
                    bestPerformingRegion : 'Main Dealer dengan penjualan terbesar sesuai filter tanggal.',
                    bestProductSeries : 'Series Motor Paling Laris sesuai filter tanggal.'
                },
                top10SalesByRegion : 'Grafik ini menunjukkan perbandingan region dengan penjualan motor terbanyak.',
                salesByRegion : 'Tabel ini merepresentasikan angka jumlah Penjualan Motor oleh Main Dealer.',
                salesBySegmentAndSeries : 'Grafik ini menunjukkan Statistik Jumlah Penjualan Motor per Segment dan Series.',
                salesByTypesAndColour : 'Grafik ini menunjukkan Statistik Jumlah Penjualan Motor dan Series yang terpilih per Tipe dan Warna Motor.'
            },
            retailSales : {
                title : 'Analisa Penjualan Motor oleh Dealer',
                stats : {
                    totalSales : 'Total Penjualan Motor di dealer.',
                    bestPerformingDealer : 'Dealer dengan penjualan terbesar sesuai filter tanggal.',
                    bestProductSeries : 'Series Motor Paling Laris sesuai filter tanggal.'
                },
                top10SalesByDealer : 'Grafik ini menunjukkan perbandingan 10 Dealer terbesar dengan penjualan motor terbanyak.',
                salesByDealer : 'Tabel ini merepresentasikan angka jumlah Penjualan Motor oleh Dealer.',
                salesBySegmentAndSeries : 'Grafik ini menunjukkan Statistik Jumlah Penjualan Motor per Segment dan Series.',
                salesByTypesAndColour : 'Grafik ini menunjukkan Statistik Jumlah Penjualan Motor dan Series yang terpilih per Tipe dan Warna Motor.'
            }
        },
        warehouseBehaviourIncoming : {
            title : 'Analisa Kegiatan Penerimaan Kendaraan Gudang Main Dealer Astra Motor',
            incomingPatternByDay : 'Grafik ini menunjukkan rata rata pola penerimaan kendaraan oleh Gudang Main Dealer per hari.',
            incomingPatternByHour : 'Grafik ini menunjukkan rata rata pola penerimaan kendaraan oleh Gudang Main Dealer per jam.',
            incomingVehicleByDate : 'Grafik ini menunjukkan penerimaan kendaraan oleh gudang main dealer per hari.'
        },
        warehouseBehaviourOutgoing : {
            title : 'Analisa Kegiatan Pengeluaran Kendaraan Gudang Main Dealer Astra Motor',
            outgoingPatternByDay : 'Grafik ini menunjukkan rata rata pola pengeluaran kendaraan oleh Gudang Main Dealer per hari.',
            outgoingPatternByHour : 'Grafik ini menunjukkan rata rata pola pengeluaran kendaraan oleh Gudang Main Dealer per jam.',
            outgoingVehicleByDate : 'Grafik ini menunjukkan pengeluaran kendaraan oleh gudang main dealer per hari.'
        },
        leadTimeDeliveryOnDealer : {
            title : 'Analisa Waktu Pengirimaan Kendaraan Ber Motor Kepada Dealer',
            leadTimeDeliveryOnDealer : 'Grafik ini menunjukkan rata rata pola waktu pengiriman motor kepada dealer per hari.'
        },
        GIGR : {
            GRMD : {
                title : 'Analisa Penerimaan Motor Di Gudang Main Dealer Astra Motor',
                GRMDSummary : 'Grafik ini menunjukkan Penerimaan Motor di Gudang Astra Motor per hari.',
                TotalGRMD : 'Total Jumlah Motor yang diterima Oleh Gudang Main Dealer.',
                GRMDDetails : 'Tabel ini menunjukkan Penerimaan Motor di Gudang Main Dealer per region dan hari.'
            },
            GIMD : {
                title : 'Analisa Pengiriman Motor Dari Gudang Main Dealer Astra Motor',
                GIMDSummary : 'Grafik ini menunjukkan Pengiriman Motor dari Gudang Astra Motor per hari.',
                TotalGIMD : 'Total Jumlah Motor yang dikirim Oleh Gudang Main Dealer.',
                GIMDDetails : 'Tabel ini menunjukkan Pengiriman Motor di Gudang Main Dealer per hari.'
            },
            GRD : {
                title : 'Analisa Penerimaan Motor Oleh Dealer Astra Motor',
                GRDSummary : 'Grafik ini menunjukkan Penerimaan Motor di Dealer per hari.',
                TotalGRD : 'Total Jumlah Motor yang Diterima Oleh Dealer.',
                GRDDetails : 'Tabel ini menunjukkan Penerimaan Motor di Dealer per hari.'
            }
        },
        customerLocation : {
            title : 'Analisa Titik Lokasi Customer Astra Motor',
            customerLocationBySegmentAndSeries : 'Grafik ini menunjukkan Statistik Jumlah Kepunyaan Motor Customer di Lokasi Tertentu per Segment dan Series.',
            customerLocationByTypesAndColour : 'Grafik ini menunjukkan Statistik Jumlah Kepunyaan Motor Customer di Lokasi Tertentu dan Series yang terpilih per Tipe dan Warna Motor.'
        },
        vehicleDeviation : {
            title : 'Analisa Motor yang terscan diluar tujuan asli'
        },
        
    },
    h2 : {
        qrRegistration : {
            title : 'Analisa Registrasi QR di H2',
            registrationByDate : 'Grafik ini menunjukkan Jumlah qr teregistrasi di H2 per hari.',
            registrationByRegion : 'Grafik ini menunjukkan Jumlah qr teregistrasi di H2 per region.',
            registrationByHour : 'Grafik ini menunjukkan Jumlah qr teregistrasi di H2 per jam pada bulan tertentu.',
            registrationByVehicleSegment : "Grafik ini menunjukkan Jumlah qr teregistrasi di H2 per Segment Motor."
        },
        replacedQr : {
            title : 'Analisa Pergantian QR Code di H2',
            qrReplacement : 'Grafik ini menunjukkan Jumlah pergantian QR di H2 per hari.',
            qrReplacementReasons : 'Grafik ini menunjukkan statistik alasan Pergantian QR pada Motor.'
        },
        changeOwnership : {
            title : 'Analisa Pergantian Kepemilikan Motor',
            changeOwnershipByVehicleType : "Grafik ini menunjukkan statistik tipe motor Pergantian Kepemilikan."
        },
        serviceCycle : {
            title : 'Analisa Rutinitas Motor Servis di H2',
            h1h2Comparison : 'Grafik ini menunjukkan statistik Pembeli Motor Astra yang balik servis ke H2 dibandingkan oleh Motor yang hanya servis di H2.',
            averageServiceCycle : 'Grafik ini menunjukkan rutinitas motor yang pernah servis di H2 untuk balik melakukan servis. New Customer merepresentasikan Motor yang baru pertama kali servis di H2 dan No Record merepresentasikan Motor yang tidak pernah servis lagi di H2.'
        },
        workshopTraffic : {
            title : 'Analisa Kepadatan Servis di H2',
            trafficDistribution : 'Grafik ini menunjukkan kepadatan servis di H2 per jam pada hari tertentu.'
        }
    },
    report : {
        h1Report : {
            title : 'Laporan H1',
            tableHeaders : {
                QrRegistered : 'Jumlah QR yang di mapping pada gudang MD. (GI + GR Scan)',
                QrReplacement : 'Jumlah QR yang diganti.',
                IdCustomer : 'Jumlah Konsumen (Sales) berdasarkan KTP.',
                IdCustomerWithQr : 'Jumlah Konsumen (Sales) berdasarkan KTP yang memiliki qr.',
                GRMD : 'Jumlah Kendaraan yang diterima oleh MD.',
                GIMD : 'Jumlah Kendaraan yang dikirim dari MD.',
                GRDealer : 'Jumlah Kendaraan yang diterima oleh Dealer.',
                GIDealer : 'Jumlah Kendaraan yang dikirim dari Dealer.',
                SPGScan : 'Jumlah Kendaraan di scan saat diterima oleh Dealer.',
                BSTKScan : 'Jumlah Kendaraan di scan saat dikirim dari Dealer.',
                SLAHM : 'Jumlah Kendaraan yang ada di Shipping List AHM',
            },
            h1DetailsByRegion : 'Tabel ini menjelaskan detail dari pengiriman data H1 oleh CIST kepada QTrust by api berdasarkan region.',
            h1DetailsByDealer : 'Tabel ini menjelaskan detail dari pengiriman data H1 oleh CIST kepada QTrust by api berdasarkan dealer.',
            h1DetailsByMonth : 'Tabel ini menjelaskan detail dari pengiriman data H1 oleh CIST kepada QTrust by api berdasarkan bulan dan tahun.'
        },
        h2Report : {
            title : 'Laporan H2',
            tableHeaders : {
                QrRegistered : 'Jumlah registrasi stiker ASMOID (Jumlah New ID + QR Replacement) pada retang waktu yang ditentukan.',
                QrReplacement : 'Jumlah stiker ASMOID yang diganti di dealer yang disebabkan karena hilang/rusak. Kendaraan tersebut sudah pernah ditempel oleh stiker ASMOID sebelumnya pada rentang waktu yang ditentukan.',
                newId : 'Jumlah registrasi QR Code yang terhubung dengan Nomor Mesin Kendaraan (stiker ASMOID) dimana nomor kendaraan tersebut belum pernah terregistrasi pada ASMOID.',
                existingId : 'Jumlah kendaraan ber stiker ASMOID yang melakukan servis di dealer pada rentang waktu yang ditentukan.',
                Billing  : 'Jumlah total dari kendaraan yang sudah selesai servis di H2',
                totalQRCode : 'Jumlah total dari penambahan antara Pendaftaran QR dan QR Lama',
                
                // h1h2 : 'Jumlah kendaraan yang melakukan pemelian di H1 melakukan service pertama kali di dealer (H2) untuk pertama kali pada retang waktu yang ditentukan.',
                // h2h2 : 'Jumlah kendaraan pelanggan H2 yang melakukan service lebih dari satu kali pada retang waktu yang ditentukan.',
                // h1service : 'Jumlah frekuensi servis dimana kendaraan berasal dari H1.',
                // h2service : 'Jumlah frekuensi servis dimana kendaraan yang berasal dari H2 (bukan dari H1).',
                // service : 'Total kendaraan yang melakukan servis di dealer, termasuk sudah memliki stiker ASMOID (Existing ID), kendaraan yang servis melalui pembelian dari H1 (H1 to H2) dan kendaraan pelanggan yang sudah pernah servis sebelumnya (H2 to H2) pada rentang waktu yang ditentukan.',
                h12h2 : 'Jumlah kendaraan yang melakukan pembelian dan melakukan servis dealer yang sama pada rentang waktu yang ditentukan.',
                h2h2Own : 'Jumlah kendaraan yang dulunya pernah servis di ahass tersebut pada periode servis'
            },
            h2DetailsByRegion : 'Tabel ini menjelaskan detail dari pengiriman data H2 oleh CIST kepada QTrust by api berdasarkan region.',
            h2DetailsByDealer : 'Tabel ini menjelaskan detail dari pengiriman data H2 oleh CIST kepada QTrust by api berdasarkan dealer.',
            h2DetailsByMonth : 'Tabel ini menjelaskan detail dari pengiriman data H2 oleh CIST kepada QTrust by api berdasarkan bulan dan tahun.'
        },
        summaryQrRegistration : {
            title : 'Laporan Registrasi QR'
        },
        summaryDocumentData : {
            title : 'Laporan Penerimaan Data Dokumen'
        },
        summaryLog : {
            title : 'Laporan Log Penerimaan Data Oleh QTrust'
        },
        vehicleSearch : {
            title : 'Laporan Pencarian Kendaraan',
            modal : {
                vehicleInformation : 'Informasi Kendaraan.',
                vehicleTrackingMap : 'Grafik ini menunjukkan Timeline Kendaraan ter scan dan deteksi oleh QTrust.',
                serviceHistory : 'Informasi Service Kendaraan di H2.',
                customerClaim : 'Informasi tentang pemilikan kendaraan.'
            }
        },
        qrcode : {
            title : 'Laporan Pencarian QR Code',
            stats : {
                totalActive : 'Total QR Code yang sudah dipakai dan ditempel moto di Astra Motor.',
                totalInActive : 'Total QR Code yang belom terpakai dan terregistrasi di system QTrust.',
                totalDelete : 'Total QR Code yang sudah tergganti oleh Qr Code Baru.'

            }
        },
        changeOwnership : {
            title : 'Laporan Pergantian Kepemilikan Motor'
        },
        customer : {
            title : 'Laporan Pencarian Pelanggan'
        },
        qrCodeReplacement : {
            title : 'Laporan Pergantian QR Code Motor'
        },
        apiLogReports : {
            title : 'Laporan Pengeckan API Endpoint Penerimaan Data Secara Detail'
        },
        errorReport : {
            title : 'Laporan Error Dari Client API'
        },
        tracking : {
            title : 'Laporan Tracking Lokasi Kendaraan Bermotor'
        },
        stock : {
            title : 'Stok Kendaraan Bermotor'
        },
        downloadReport : {
            title : 'Daftar dari report download yang telah di request'
        },
    },
    setting : {
        userManagement : {
            title : 'Pengaturan User di Dashboard'
        },
        target : {
            title : 'Pengaturan Target Pencapaian Astra Motor'
        }
    }
}


export default { info }
