<template>
  <div>
    <!-- FILTER STARTS -->
    <div>
      <filter-complete
        :page_name="'Change Ownership'"
        :seperated_range="true"
        :fetch_filter="filterUrl.initial"
        @onFilter="setFilterUrl"
        :popUp="infoPopup"
      ></filter-complete>
    </div>
    <!-- FILTER ENDS -->

    <div class="mb-4 p-4 card">
      <h5>Change Ownership by Vehicle Type 
        <span class="col-md-1 col-sm-1 col-2">
            <i class="fa fa-info-circle fa-lg" id="changeOwnership"></i>
            <b-popover
            target="changeOwnership"
            title="Change Ownership by Vehicle Type "
            triggers="hover focus"
            placement="top"
            :content="infoPopup.changeOwnershipByVehicleType"
            ></b-popover>
        </span>
      </h5>
      <change-ownership-by-vehicle-segment 
        v-if="filterUrl.ready"
        :filterUrl="filterUrl.url"
      ></change-ownership-by-vehicle-segment>
      <!-- <totalNewQr style="margin-left:10px" class="mt-4" :data="totalVehicleRegisteredByType.totalVehicleRegisteredByType" :isUpdate="isUpdate"></totalNewQr> -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import filterComplete from '@/components/miscellaneous/filter-complete'

import changeOwnershipByVehicleSegment from './childComponents/ChangeOwnershipByVehicleSegment'

export default {
  name: 'change-ownership',
  metaInfo: {
    title: 'Change Ownership'
  },
  data () {
    return {
      filterUrl : {
        initial : 0,
        ready : false,
        url : '',
      },
      infoPopup : this.$store.state.hoverInformation.h2.changeOwnership
    }
  },
  components: {
    filterComplete,

    changeOwnershipByVehicleSegment,
  },
  mounted () {
    this.filterUrl.initial++;
  },
  watch: {

  },
  methods: {
    setFilterUrl(urlString){
      this.filterUrl.url = urlString;
      this.filterUrl.ready = true;
    },
  }
}
</script>

<style scoped>
  .table-div{
      display: block;
      overflow: scroll;
  }
  .bold{
      font-weight: bold;
  }
  .no-data {
      text-align: center;
      /* width: 100%; */
  }
  .secondrow {
    margin-top: 20px;
  }
</style>
