<script>/* eslint-disable */</script>

<template>
  <div style="height:500px;" class="w-100" ref="stackedBarAging"></div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import animated from "@amcharts/amcharts4/themes/animated.js";

am4core.useTheme(animated);

export default {
  props: ['data'],
  data () {
    return {
      chart : null
    }
  },
  mounted () {
    this.createChart();
    this.loadData();
  },
  methods: {
    loadData () {
      let length = this.chart.data.length
      this.chart.addData(this.data , length)
    },
    createChart () {
      this.chart = am4core.create(this.$refs.stackedBarAging, am4charts.XYChart);

      // Create axes
      let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "name";
      categoryAxis.numberFormatter.numberFormat = "#";
      categoryAxis.renderer.inversed = true;
      categoryAxis.title.text = "Region"
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.cellStartLocation = 0.1;
      categoryAxis.renderer.cellEndLocation = 0.9;
      categoryAxis.renderer.labels.template.rotation = -45;
      categoryAxis.renderer.minGridDistance = 30;


      let  valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis()); 
      // valueAxis.renderer.opposite = true;
      valueAxis.title.text = "Number of Stock"
      valueAxis.numberFormatter = new am4core.NumberFormatter();
      valueAxis.numberFormatter.numberFormat = "#,###";

      this.chart.legend = new am4charts.Legend()

      this.createSeries("count", "Number of Stock");

    },
    createSeries (field, name) {
      let series = this.chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "name";
      series.name = name;
      series.columns.template.tooltipText = "{name}: [bold]{valueY.formatNumber('#,###')}[/]";
      series.columns.template.height = am4core.percent(100);
      series.sequencedInterpolation = true;

      let valueLabel = series.bullets.push(new am4charts.LabelBullet());
      // valueLabel.label.text = "{valueY}";
      valueLabel.label.horizontalCenter = "left";
      valueLabel.label.dx = 10;
      valueLabel.label.hideOversized = false;
      valueLabel.label.truncate = false;

      let categoryLabel = series.bullets.push(new am4charts.LabelBullet());
      // categoryLabel.label.text = "{name}";
      categoryLabel.label.horizontalCenter = "right";
      categoryLabel.label.dx = -10;
      categoryLabel.label.fill = am4core.color("#fff");
      categoryLabel.label.hideOversized = false;
      categoryLabel.label.truncate = false;

      let labelBullet = series.bullets.push(new am4charts.LabelBullet())
      labelBullet.label.horizontalCenter = "middle";
      labelBullet.label.dy = -10;
      labelBullet.label.text = "{values.valueY.workingValue}";
      labelBullet.locationY = 1;
    }
  }
  
}
</script>