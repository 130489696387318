<template>
    <div ref="last7DaysLineChart" style="min-height: 370px;"></div>
</template>

<script>
    /* Imports */
    import * as am4core from "@amcharts/amcharts4/core";
    import * as am4charts from "@amcharts/amcharts4/charts";
    import am4themes_animated from "@amcharts/amcharts4/themes/animated";

    export default {
        data () {
            return {
                chart : null,
                valueAxis : null,
            }
        },
        props : ['data'],
        mounted () {
            this.createChart();
            this.getData();
        },
        watch : {
            data : {
                handler : 'getData'
            }
        },
        methods : {
            getData () {
                let length = this.chart.data.length

                this.chart.addData(this.data && this.data.length > 0 ? this.data : [], length);
            },
            createChart () {
                /* Chart code */
                // Themes begin
                am4core.useTheme(am4themes_animated);
                // Themes end

                // Create chart instance
                this.chart = am4core.create(this.$refs.last7DaysLineChart, am4charts.XYChart);

                //

                // Increase contrast by taking evey second color
                this.chart.colors.step = 2;

                // Create axes
                let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
                dateAxis.renderer.minGridDistance = 50;


                this.valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
                this.valueAxis.min = 0;
                this.valueAxis.title.text = 'Number Of Items';

                this.createAxisAndSeries("good_issue", "Good Issue", false, "circle");
                // this.createAxisAndSeries("views", "Views", true, "triangle");
                this.createAxisAndSeries("unregistered", "Unregistered", true, "rectangle");

                // Add legend
                this.chart.legend = new am4charts.Legend();

                // Add cursor
                this.chart.cursor = new am4charts.XYCursor();
            },
            createAxisAndSeries(field, name, opposite, bull) {
                // if(chart.yAxes.indexOf(valueAxis) != 0){
                //     valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
                // }
                
                let series = this.chart.series.push(new am4charts.LineSeries());
                series.dataFields.valueY = field;
                series.dataFields.dateX = "date";
                series.strokeWidth = 2;
                series.yAxis = this.valueAxis;
                series.name = name;
                series.tooltipText = "{name}: [bold]{valueY}[/]";
                series.tensionX = 0.8;
                series.showOnInit = true;

                var labelBullet = series.bullets.push(new am4charts.LabelBullet());
                labelBullet.label.text = "[bold]{valueY}";
                labelBullet.label.dy = -10;
                
                let interfaceColors = new am4core.InterfaceColorSet();
                let bullet;
                
                switch(bull) {
                    case "triangle":
                        bullet = series.bullets.push(new am4charts.Bullet());
                        bullet.width = 12;
                        bullet.height = 12;
                        bullet.horizontalCenter = "middle";
                        bullet.verticalCenter = "middle";
                        
                        let triangle = bullet.createChild(am4core.Triangle);
                        triangle.stroke = interfaceColors.getFor("background");
                        triangle.strokeWidth = 2;
                        triangle.direction = "top";
                        triangle.width = 12;
                        triangle.height = 12;
                        break;
                    case "rectangle":
                        bullet = series.bullets.push(new am4charts.Bullet());
                        bullet.width = 10;
                        bullet.height = 10;
                        bullet.horizontalCenter = "middle";
                        bullet.verticalCenter = "middle";
                        
                        let rectangle = bullet.createChild(am4core.Rectangle);
                        rectangle.stroke = interfaceColors.getFor("background");
                        rectangle.strokeWidth = 2;
                        rectangle.width = 10;
                        rectangle.height = 10;
                        break;
                    default:
                        bullet = series.bullets.push(new am4charts.CircleBullet());
                        bullet.circle.stroke = interfaceColors.getFor("background");
                        bullet.circle.strokeWidth = 2;
                        break;
                }
                
                // valueAxis.renderer.line.strokeOpacity = 1;
                // valueAxis.renderer.line.strokeWidth = 2;
                // valueAxis.renderer.line.stroke = series.stroke;
                // valueAxis.renderer.labels.template.fill = series.stroke;
                // valueAxis.renderer.opposite = opposite;
            }
        }
    }
</script>

<style scoped>

</style>