<template>
  <b-navbar toggleable="lg" :variant="getLayoutNavbarBg()" class="layout-navbar align-items-lg-center container-p-x">

    <!-- Brand demo (see demo.css) -->
    <b-navbar-brand to="/" class="app-brand demo d-lg-none py-0 mr-4">
      <span class="app-brand-text demo font-weight-normal ml-2"><img :src="`${publicUrl}img/logo.png`"></span>
    </b-navbar-brand>

    <!-- Sidenav toggle (see demo.css) -->
    <b-navbar-nav class="layout-sidenav-toggle d-lg-none align-items-lg-center mr-auto" v-if="sidenavToggle">
      <a class="nav-item nav-link px-0 mr-lg-4" href="javascript:void(0)" @click="toggleSidenav">
        <i class="ion ion-md-menu text-large align-middle" />
      </a>
    </b-navbar-nav>

    <b-navbar-toggle target="app-layout-navbar"></b-navbar-toggle>

    <b-collapse is-nav id="app-layout-navbar">
      <!-- Divider -->
      <hr class="d-lg-none w-100 my-2">

      <b-navbar-nav class="align-items-lg-center ml-auto">
        <!-- <b-nav-item-dropdown no-caret :right="!isRTL" class="demo-navbar-notifications mr-lg-3">
          <template slot="button-content">
            <i class="ion ion-md-notifications-outline navbar-icon align-middle"></i>
            <span class="badge badge-primary badge-dot indicator"></span>
            <span class="d-lg-none align-middle">&nbsp; Notifications</span>
          </template>

          <div class="bg-primary text-center text-white font-weight-bold p-3">
            1 New Notifications
          </div>
          <b-list-group flush>
            <b-list-group-item href="javascript:void(0)" class="media d-flex align-items-center">
              <div class="ui-icon ui-icon-sm ion ion-md-home bg-secondary border-0 text-white"></div>
              <div class="media-body line-height-condenced ml-3">
                <div class="text-dark">QR Code 1381480114804108 not found</div>
                <div class="text-light small mt-1">
                  Client API /vehicle_claim: QR Code 1381480114804108 not found
                </div>
                <div class="text-light small mt-1">12h ago</div>
              </div>
            </b-list-group-item>

          </b-list-group>

          <a href="javascript:void(0)" class="d-block text-center text-light small p-2 my-1">Show all notifications</a>
        </b-nav-item-dropdown> -->

        <!-- Divider -->
        <div class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1">|</div>

        <b-nav-item-dropdown :right="!isRTL" class="demo-navbar-user">
          <template slot="button-content">
            <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
              <!-- <img :src="`${publicUrl}img/avatars/1.png`" alt class="d-block ui-w-30 rounded-circle"> -->
              <span class="px-1 mr-lg-2 ml-2 ml-lg-0" v-if="$store.state.userData.userData">{{$store.state.userData.userData.username}}</span>
              <span class="px-1 mr-lg-2 ml-2 ml-lg-0" v-else></span>
            </span>
          </template>

          <b-dd-item @click="showMyProfile"><i class="ion ion-ios-person text-lightest"></i> &nbsp; My profile</b-dd-item>
          <b-dd-divider />
          <b-dd-item @click="logout"><i class="ion ion-ios-log-out text-danger"></i> &nbsp; Log Out</b-dd-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  name: 'app-layout-navbar',

  props: {
    sidenavToggle: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    toggleSidenav () {
      this.layoutHelpers.toggleCollapsed()
    },

    getLayoutNavbarBg () {
      return this.layoutNavbarBg
    },
    showMyProfile () {
      this.$router.push({name: 'profilePage'})
    },
    logout () {
      this.$store.dispatch('setUser', null)

      // delete existing filter options
      let keys = Object.keys(this.$store.state.filterData)
      for (var i = 0 ; i < keys.length ; i ++) {
        this.$store.state.filterData[keys[i]] = null
      }

      console.log(this.$store.state.filterData)
      this.$store.dispatch('setFilterOptions' , null)
      this.$store.dispatch('setUser', null)

      this.$router.push({path: '/login'})
    }
  }
}
</script>
