<template>
  <div style='min-height:400px' ref='servicedurationboxplot'></div>
</template>

<script>
/* eslint-disable */
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

import material from '@amcharts/amcharts4/themes/material.js'
import animated from '@amcharts/amcharts4/themes/animated.js'

am4core.useTheme(animated)
am4core.useTheme(material)

export default {
mounted () {
  this.createChart()
  this.dummyData()
},
data () {
  return {
    chart: null
  }
},
props: ['data'],
methods: {
  dummyData () {
    let data = [{
      'day': 'Saturday',
      'min': 121,
      'avg': 2124,
      'max': 3615
    },
    {
      'day': 'Friday',
      'min': 129,
      'avg': 669,
      'max': 4169
    },
    {
      'day': 'Thursday',
      'min': 89,
      'avg': 353,
      'max': 686
    },
    {
      'day': 'Wednesday',
      'min': 5,
      'avg': 291,
      'max': 640
    },
    {
      'day': 'Tuesday',
      'min': 70,
      'avg': 350,
      'max': 945
    },
    {
      'day': 'Monday',
      'min': 47,
      'avg': 322,
      'max': 989
    }]

    var keys = Object.keys(data[0])
    for (var i = 0; i < data.length; i++) {
      for (var j = 0; j < keys.length; j++) {
        if (keys[j] === 'day') continue
        let chartValue = Math.round(data[i][keys[j]] / 60 * 100) / 100
        var quotient = Math.floor(data[i][keys[j]] / 60)
        var remainder = data[i][keys[j]] % 60

        data[i][keys[j]] = chartValue
        data[i][keys[j] + 'string'] = quotient + ' h and ' + remainder + ' min'
      }
    }

    let length = this.chart.data.length
    this.chart.addData(data, length)
  },
  createChart () {
    this.chart = am4core.create(this.$refs.servicedurationboxplot, am4charts.XYChart)
    this.chart.paddingRight = 20

    // this.chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd'

    let categoryAxis = this.chart.yAxes.push(new am4charts.CategoryAxis())
    categoryAxis.dataFields.category = 'day'
    categoryAxis.renderer.minGridDistance = 40
    categoryAxis.renderer.grid.template.location = 0

    let valueAxis = this.chart.xAxes.push(new am4charts.ValueAxis())
    valueAxis.tooltip.disabled = true
    valueAxis.title.text = 'Time By Hour (H)'

    let series = this.chart.series.push(new am4charts.CandlestickSeries())
    series.dataFields.categoryY = 'day'
    series.dataFields.valueX = 'avg'
    series.dataFields.openValueX = 'avg'
    series.dataFields.lowValueX = 'min'
    series.dataFields.highValueX = 'max'
    series.simplifiedProcessing = true
    series.tooltipText = 'Min: {minstring}\nMax: {maxstring}\nAvg: {avgstring}'
    series.tooltip.fontWeight = 'bold'
    series.riseFromOpenState = undefined
    series.dropFromOpenState = undefined
    series.strokeWidth = 2.5

    this.chart.cursor = new am4charts.XYCursor()

    let topSeries = this.chart.series.push(new am4charts.StepLineSeries())
    topSeries.noRisers = true
    topSeries.startLocation = 0.2
    topSeries.endLocation = 0.8
    topSeries.dataFields.valueX = 'max'
    topSeries.dataFields.categoryY = 'day'
    topSeries.stroke = this.chart.colors.getIndex(0)
    topSeries.strokeWidth = 3

    let bottomSeries = this.chart.series.push(new am4charts.StepLineSeries())
    bottomSeries.noRisers = true
    bottomSeries.startLocation = 0.2
    bottomSeries.endLocation = 0.8
    bottomSeries.dataFields.valueX = 'min'
    bottomSeries.dataFields.categoryY = 'day'
    bottomSeries.stroke = this.chart.colors.getIndex(0)
    bottomSeries.strokeWidth = 3

    this.chart.scrollbarX = new am4core.Scrollbar()
    // this.this.chart.data = this.data
  },
  getData () {
    let length = this.chart.data.length
    this.chart.addData(this.data, length)
  }
}
}
</script>
