<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper" @click="$emit('closeModal')">
        <div class="row">
          <div class="col-xs-0 col-sm-0 col-md-1 col-lg-2"></div>
          <div class="col-xs-12 col-sm-12 col-md-10 col-lg-8">
            <div class="modal-container" @click.stop>

                <div class="modal-header" style="justify-content: flex-end;">
                  <button type="button" class="close" @click="$emit('closeModal')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div class="modal-body">
                  <h2 class="title" style="margin-bottom: 20px;">Register New User</h2>
                  <div class="row row-space">
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                      <div class="input-group">
                        <label class="label">Full Name<span style="color: red;">*</span></label>
                        <input class="input--style-4" type="text" name="name" v-model="form.fullName">
                      </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                      <div class="input-group">
                        <label class="label">Username<span style="color: red;">*</span></label>
                        <input class="input--style-4" type="text" name="username" v-model="form.username">
                      </div>
                    </div>
                  </div>
                  <div class="row row-space">
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                      <div class="input-group">
                        <label class="label">Password<span style="color: red;">*</span></label>
                        <input class="input--style-4" type="password" name="password" v-model="form.password">
                      </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                      <div class="input-group">
                        <label class="label">Re-type Password<span style="color: red;">*</span></label>
                        <input class="input--style-4" type="password" name="retype_password" v-model="retype_password">
                      </div>
                    </div>
                  </div>
                  <div class="row row-space">
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                      <div class="input-group">
                        <label class="label">User Type<span style="color: red;">*</span></label>
                        <MultiSelect
                          v-model="form.type"
                          :options="['USER_REGION', 'DEALER']"
                          :allow-empty="false"
                          placeholder="Choose Type"
                        ></MultiSelect>
                      </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                      <div class="input-group" v-if="form.type == 'USER_REGION'">
                        <label class="label">Region View Access<span style="color: red;">*</span></label>
                        <MultiSelect
                          v-model="form.permission"
                          :options="options.region.map(x => x.loc_code)"
                          :custom-label="opt => options.region.find(x => x.loc_code == opt).name"
                          :multiple="true"
                          placeholder="Choose Region Access"
                        ></MultiSelect>
                      </div>
                      <div class="input-group" v-else>
                        <label class="label">Dealer View Access<span style="color: red;">*</span></label>
                        <MultiSelect
                          v-model="form.permission"
                          :options="options.branch.map(x => x.loc_code)"
                          :custom-label="opt => options.branch.find(x => x.loc_code == opt).name"
                          :multiple="true"
                          placeholder="Choose Dealer Access"
                        ></MultiSelect>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <label class="label text-left">Module<span style="color: red;">*</span></label>
                  </div>
                  <div class="row" v-if="moduleConfig != null">
                    <!-- Module Selection Starts -->
                    <div v-for="(module, index) in structure" class="innerBox w-100 mt-3" :class="(module.children != null) ? 'hoverable' : ''" @click="openBox(index)">
                      <div class="row">
                        <div class="custom-control custom-checkbox align-middle" :class="(mobile) ? 'col-8' : 'col-4'" style="padding-left:35px">
                          <input v-if="module.children == null" type="checkbox" class="custom-control-input" :id="module.name" :value="module.id" v-model="selected[index]" :disabled="form.type == 'DEALER'">
                          <input v-else type="checkbox" class="custom-control-input" :id="module.name" v-indeterminate="moduleConfig[index].indeterminate" v-model="moduleConfig[index].checked" @change="selectAll(index)" :disabled="form.type == 'DEALER'">

                          <label class="custom-control-label" :for="module.name">
                            <h4 style="margin: 0">{{ module.name }}</h4>
                          </label>
                        </div>
                        <div class="text-right" :class="(mobile) ? 'col-4' : 'col-8'">
                          <i v-if="module.children != null" class="fas fa-angle-right" :class="(moduleConfig[index].boxOpen) ? 'fa-rotate-90' : ''" style="font-size: 150%;"></i>
                        </div>
                      </div>
                      <hr v-if="module.children != null && moduleConfig[index].boxOpen">
                      <!-- Children Start -->
                      <div v-if="module.children != null && moduleConfig[index].boxOpen" class="row ml-3" @click.stop>
                        <div v-for="littleModule in module.children" class="col-12 mt-2 custom-control custom-checkbox align-middle" style="padding-left:35px">

                          <input
                            type="checkbox"
                            v-model="selected[index]"
                            class="custom-control-input"
                            :value="littleModule.id"
                            :id="littleModule.name"
                            :disabled="form.type == 'DEALER'"
                            @change="select(index)"
                          >
                          <label class="custom-control-label" :for="littleModule.name">{{ littleModule.name }}</label>
                        </div>
                      </div>
                      <!-- Children End -->
                    </div>
                    <!-- Module Selection Ends -->
                    <!-- <button class="btn btn-primary" type="button" @click="form.modules.push('Work Order Listing')">a</button> -->
                  </div>
                  <!-- Button Starts -->
                  <div class="row mt-5">
                    <div class="col text-center">
                      <button type="button" class="btn btn-outline-danger" style="font-size:140%" @click="$emit('closeModal')">Cancel</button>
                      <button type="button" class="btn btn-primary ml-5" style="font-size:150%" @click="submitForm()">Register</button>
                    </div>
                  </div>
                  <!-- Button Ends -->
                </div>
            </div>
          </div>
        </div>
        <div class="col-xs-0 col-sm-0 col-md-1 col-lg-2"></div>
      </div>
    </div>
  </transition>
</template>
  
<script>
    import MultiSelect from 'vue-multiselect';
    import modulesList from './modulesList.json'
  
    export default {
      data () {
        return{
          mobile : this.isMobile(),
          options : {
            region : this.$store.getters.getRegion,
            branch : this.$store.getters.getSimpleBranch(null, ['H12', 'H2'])
          },
          form : {
            fullName : '',
            username : '',
            password : '',
            type : 'USER_REGION',
            region : '',
            permission : [],
            modules : [],
          },
          retype_password : '',
          moduleConfig : null,
          selectModule : [],
          structure : [],
          selected : [],
        }
      },
      directives: {
        indeterminate: function(el, binding) {
          el.indeterminate = Boolean(binding.value)
        }
      },
      components : {
        MultiSelect
      },
      mounted () {
        this.generateStructure()
      },
      watch : {
        "form.type" () {
          this.form.permission.splice(0);

          if (this.form.type == 'DEALER') {
            let posReport = -1;
            for (var i = 0 ; i < this.structure.length; i++) {
              this.selected[i] = [];
              this.moduleConfig[i].checked = false;
              if (this.structure[i].name == 'Report') posReport = i;
            }
            if (posReport == -1) {
              this.errorPrint("An error has occured, please contact QTrust Admin");
            } else {
              this.selected[posReport].push(33);
              this.selected[posReport].push(53);

              this.select(posReport);
              if (!this.moduleConfig[index].boxOpen) this.openBox(posReport)
            }
          }

        }
      },
      methods : {
        openBox(index){
          if(this.structure[index].children == null) return
          for(var i in this.moduleConfig){
            if(i == index)
              this.moduleConfig[index].boxOpen = !this.moduleConfig[index].boxOpen
            else
              this.moduleConfig[i].boxOpen = false
          }
        },
        selectAll(item){
          this.selected[item] = []
          if(this.moduleConfig[item].checked){
            this.selected[item] = this.selected[item].concat(this.structure[item].children.map(x => x.id))
          }
          this.moduleConfig[item].indeterminate = false;
        },
        select(item){
          this.moduleConfig[item].checked = false;
          if(this.selected[item].length == 0){
            this.moduleConfig[item].indeterminate = false;
          } else if (this.selected[item].length == this.structure[item].children.length){
            this.moduleConfig[item].indeterminate = false;
            this.moduleConfig[item].checked = true;
          } else {
            this.moduleConfig[item].indeterminate = true;
          }
        },
        concatModules(){
          this.form.modules.length = 0;
          for(var i = 0; i < this.selected.length; i++){
            this.form.modules = this.form.modules.concat(this.selected[i]);
          }
          // console.log(JSON.stringify(this.form.modules));
        },
        generateStructure(){
          this.structure = JSON.parse(JSON.stringify(this.$store.state.modules));
          this.structure[4].children.splice(0,1);
          this.generateModuleConfig()
        },
        async generateModuleConfig(){
          var moduleConfig = []
          for(var i = 0; i < this.structure.length; i++){
            this.selected[i] = []
            moduleConfig.push({
              boxOpen : false,
              checked : false,
              indeterminate : false
            })
          }
          this.moduleConfig = moduleConfig
        },
          validateForm(){
            var valid = true;
            var error = [];
  
            if(this.form.fullName == '' || this.form.fullName == null){//Full Name [Required]
              valid = false;
              error.push('Full Name is a required Field!');
            }
  
            if(this.form.username == '' || this.form.username == null){//Username [Required]
              valid = false;
              error.push('Username is a required Field!');
            }
  
            if(this.form.password == '' || this.form.password == null){//Password [Required]
              valid = false;
              error.push('Password is a required Field!');
            }
  
            if(this.retype_password == '' || this.retype_password == null){//Retype Password [Required]
              valid = false;
              error.push('Retype Password is a required Field!');
            } else {
              if(this.form.password !== this.retype_password){//Password and Retype Password Not The Same
                valid = false;
                error.push('Password and Re-Type Password does not match!');
              }
            }
            
            if(this.form.permission.length == 0){//Region View Access [Required]
              valid = false;
              error.push('Region View Access has to have at least one region');
            }
            
            if(this.form.modules.length == 0){//Modules [Required]
              valid = false;
              error.push('Modules has to have at least one module selected');
            }
  
            if(!valid) this.$emit('triggerAlert', !valid, error);
            else return valid;
          },
          async submitForm(){
            let vm = this;
            await this.concatModules();
            if(await vm.validateForm()){
              this.$http.post('/api/user/userDashboardRegister', this.form)
              .then((resp)=>{
                // alert(`Partshop ${vm.form.name} has been created`);
                vm.$emit('triggerAlert', false, [`User Region ${vm.form.username} has been created`]);
              })
              .catch((err)=>{
                console.log(err.response.data);
                vm.$emit('triggerAlert', true, err.response.data.message);
              })
            }
          },
      }
    }
</script>
  
<style scoped>


  /* ==========================================================================
  #FORM made by Colorlib (https://colorlib.com)
  ========================================================================== */
  input {
    outline: none;
    margin: 0;
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    width: 100%;
    font-size: 14px;
    font-family: inherit;
  }
  
  .input--style-4 {
    line-height: 50px;
    background: #fafafa;
    -webkit-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding: 0 20px;
    font-size: 16px;
    color: #666;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  
  .input--style-4::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #666;
  }
  
  .input--style-4:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #666;
    opacity: 1;
  }
  
  .input--style-4::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #666;
    opacity: 1;
  }
  
  .input--style-4:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #666;
  }
  
  .input--style-4:-ms-input-placeholder {
    /* Microsoft Edge */
    color: #666;
  }
  
  .label {
    font-size: 16px;
    color: #555;
    text-transform: capitalize;
    display: block;
    margin-bottom: 5px;
  }
  
  .radio-container {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    font-size: 16px;
    color: #666;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  .radio-container input:checked ~ .checkmark {
    background-color: #e5e5e5;
  }
  
  .radio-container input:checked ~ .checkmark:after {
    display: block;
  }
  
  .radio-container .checkmark:after {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background: #57b846;
  }
  
  .checkmark {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #e5e5e5;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  }
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .input-group {
    position: relative;
    /* margin-bottom: 22px; */
    padding:20px;
  }
  
  .input-group-icon {
    position: relative;
  }
  
  .input-icon {
    position: absolute;
    font-size: 18px;
    color: #999;
    right: 18px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: pointer;
  }
  
  /* ==========================================================================
     #TITLE made by Colorlib (https://colorlib.com)
     ========================================================================== */
  .title {
    font-size: 40px;
    color: #525252;
    margin-bottom: 40px;
    text-align: center;
  }

  .modal-body{
    max-height: 80vh;
    overflow-y:auto;
    overflow-x:auto;
  }
  .innerBox{
    padding: 1.5rem 1.5rem 1.5rem 2.25rem;
    border: 1px solid #EEEEEE;
    border-radius: 5px;
    background-color: white;
    box-shadow: 2px 2px 5px #E0E0E0;
  }
  .custom-control{
    margin-bottom: 0;
  }

  .hoverable:hover{
    cursor: pointer;
  }
</style>
  