<template>
  <div>
    <!--Filter Option-->
    <b-card title="Good Receiving Main Dealer" :sub-title="infoPopup.title" header-tag="h5" class="mb-4">
      <hr>
      <b-form>
        <b-form-row>
          <b-form-group label="Start Date" class="col-md-6 dateCss" style="height:42px" >
            <datepicker name="start" id="start"  bootstrap-styling v-model="filter.start"></datepicker>
          </b-form-group>
          <b-form-group label="End Date" class="col-md-6 dateCss" style="height:42px" >
            <datepicker name="end" id="end"  bootstrap-styling v-model="filter.end"></datepicker>
          </b-form-group>
          
        </b-form-row>
        <b-form-row class="mt-3">
          <b-form-group class="col-md-1">
            <b-btn type="button" variant="primary" @click="search(0)" :disabled="isFetching">Filter</b-btn>
          </b-form-group>
        </b-form-row>
      </b-form>
    </b-card>

    <!-- First Row Start-->
    <div class="row">
        <!-- Chart -->
        <div class="col-md-12 col-lg-12 col-xl-9 mt-3" style="min-height:350px" >

        
            <b-card no-body style="height:100%" class="p-4">
                <h5>GR MD Summary
                  <span class="col-md-1 col-sm-1 col-2">
                      <i class="fa fa-info-circle fa-sm" id="GRMDSummary"></i>
                      <b-popover
                      target="GRMDSummary"
                      title="GR MD Summary"
                      triggers="hover focus"
                      placement="top"
                      :content="infoPopup.GRMDSummary"
                      ></b-popover>
                  </span>
                </h5>
                <div class="col-md-12 col-lg-12 col-xl-12 align-items-center p-1" >
                
                    <div class="d-flex row" v-if="data">
                        <GRMDChart class="w-100" :data="data"/>
                    </div>
                    <div v-else class="text-center">
                        <h4>Loading......</h4>
                    </div>
                </div>
            </b-card>
        
        </div>
        <!-- Chart End -->
        <!-- Total Stats -->
        <div class="col-md-12 col-lg-12 col-xl-3 mt-3">
            <div class="row" style="height:20%">
                <div class="col-sm-6 col-md-12 col-lg-12 col-xl-12" style="height:100%">
                    <!-- <b-card no-body class="mb-4">
                        <b-card-header class="border-0 pb-0">Total Intransit</b-card-header>
                        <b-card-body class="text-center text-success text-xlarge py-3">3,235</b-card-body>
                    </b-card> -->
                    <div class="stats-inner-box mb-4">
                        <div class="row" style="height: 100%">
                            <div class="col-8">
                            <p>Total GR MD
                              <span class="col-md-1 col-sm-1 col-2">
                                <i class="fa fa-info-circle fa-sm" id="TotalGRMD"></i>
                                <b-popover
                                target="TotalGRMD"
                                title="Total GR MD"
                                triggers="hover focus"
                                placement="top"
                                :content="infoPopup.TotalGRMD"
                                ></b-popover>
                            </span>
                            </p>
                            <p class="stats-value" v-if="meta">
                                {{ numberWithCommas(meta.total)}}
                                <!-- 5000 -->
                            </p>
                            <p v-else>
                                Loading.....
                            </p>
                            </div>
                            <div class="col-4 icon">
                            <i class="fas fa-motorcycle"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Total Stats End -->
    </div>
    <!--First Row End-->

    <!-- Second Row -->
    <div class="row mt-4">
        <div class="col-12">
            <b-card no-body style="height:100%" class="p-4">

                <div class="row mb-3">
                    <h4 class="col-md-2 col-sm-3 col-10 Aligner-Header ">GR MD Details
                    </h4>
                    <span class="col-md-1 col-sm-1 col-2">
                        <i class="fa fa-info-circle fa-lg" id="GRMDDetails"></i>
                        <b-popover
                        target="GRMDDetails"
                        title="GR MD Details"
                        triggers="hover focus"
                        placement="top"
                        :content="infoPopup.GRMDDetails"
                        ></b-popover>
                    </span>
                    <button v-if="data && data.length > 0" @click="search(1)" class="offset-md-7 offset-sm-4 col-md-2 col-sm-4 col-12 btn btn-success w-100" style="max-height:46px"><span class="fa fa-file-excel"/> Export</button>
                </div>
                <table class="table">
                    <thead style="color:white" class="text-center">
                        <tr>
                            <th class="bg-red">Date</th>
                            <th class="bg-blue" v-for="header in headers" :key="header">{{header}}</th>
                            <th class="bg-green">Total</th>
                        </tr>
                    </thead>
                    <tbody v-if="!data" style="color:black">
                        <tr class="text-center">
                            <td colspan="2"><h4>Loading.....</h4></td>
                        </tr>
                    </tbody>
                    <tbody v-else-if="data.length > 0" style="color:black" class="text-center">
                        <tr v-for="(dat, index) in data" :key="index">
                            <td class="bg-lg-red">{{dat.date}}</td>
                            <td v-for="header in headers" :key="header">{{numberWithCommas(dat[header])}}</td>
                            <td class="bg-lg-green">{{numberWithCommas(dat.total)}}</td>
                        </tr>
                        <tr>
                          <td class="bg-green" style="color: white;">Total</td>
                          <td class="bg-lg-green" v-for="header in headers" :key="header">{{ numberWithCommas(totalData[header]) }}</td>
                          <td class="bg-lg-green">{{ numberWithCommas(totalData.total) }}</td>
                        </tr>
                    </tbody>
                    <tbody v-else style="color:black">
                        <tr class="text-center">
                            <td colspan="2"><h5>No Data Available For the Given Period</h5></td>
                        </tr>
                    </tbody>
                </table>
            </b-card>
        </div>
    </div>
    <!-- Second Row End -->


  </div>
</template>

<script>
// library
import Multiselect  from 'vue-multiselect'
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'

//chart
import GRMDChart from './childComponents/GRMDChart'


export default {
  name: 'gr-md',
  metaInfo: {
    title: 'Good Receiving Main Dealer'
  },
  components: {
    // library
    Multiselect ,
    Datepicker,
    //  charts
    GRMDChart

  },
  data () {
    return {
      isFetching : false,

      region: 'ALL',
      intransit: 'Intransit from AHM to Main Dealer',
      filterData: Object.assign({} , this.$store.state.filterData),
      filter: {
        start: new moment().startOf('month').toDate(),
        end: new moment().toDate()
      },
      data: null,
      totalData : {total: 0},
      meta: null,
      headers: [],
      infoPopup: this.$store.state.hoverInformation.h1.GIGR.GRMD
    }
  },
  mounted () {
    this.search(0)
  },
  watch: {

  },
  methods: {
    numberWithCommas(x) {
      return parseInt(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async search (flag) {
      this.isFetching = true;

      let filter = await this.transformFilter(this.filter)
      filter = await this.initializeFilter(filter)

      if(flag == 0)
          this.loadAPI(filter)
      else
          this.downloadExcel(filter)
    },
    
    downloadExcel (filter) {
        let url = '/api/h1/gr-maindealer' + filter + 'exportExcel=1'
        
        this.$http.get( url , {responseType : 'blob'})
        .then((resp)=>{
            const blob = new Blob([resp.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            let url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = `GR MD ( ${new moment(this.filter.start).format("Y-MM-DD")} - ${new moment(this.filter.end).format("Y-MM-DD")} )`;
            a.click();
            window.URL.revokeObjectURL(url);
        })
        .catch((err)=>{
            console.log(err);
        })
        .finally(()=>{
          this.isFetching = false;
        })
    },
    loadAPI (filter) {
      this.data = null
      let url = '/api/h1/gr-maindealer' + filter

      this.$http.get(url)
      .then((resp) => {
        this.data = resp.data.data
        this.meta = resp.data.meta

        this.headers.length = 0
        if(this.data.length <= 0) return;
        this.totalData = {total: 0};
        let keys = Object.keys(this.data[0])

        for(var i = 0 ; i < this.data.length ; i++) {
            this.data[i].total = 0
            for(var j = 0 ; j < keys.length ; j++) {
                if(keys[j] == 'date')
                    continue

                if(!this.headers.includes(keys[j])) this.headers.push(keys[j])
                this.data[i].total += parseInt(this.data[i][keys[j]])

                if (this.totalData[keys[j]] == null) this.totalData[keys[j]] = parseInt(this.data[i][keys[j]])
                else this.totalData[keys[j]] += parseInt(this.data[i][keys[j]])
            }
        }
        this.data.map(x => this.totalData.total += x.total);
        this.headers.sort()
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(()=>{
        this.isFetching = false;
      })
    },
    async initializeFilter (filters) {
      let filterUrl = '?'
      let keys = Object.keys(filters)
      for (var i = 0; i < keys.length; i++) {
        filterUrl += `${keys[i]}=${filters[keys[i]]}&`
      }
      
      return filterUrl
    },
    async transformFilter (filter) {
      let obj = {}
      let keys = Object.keys(filter)
      for (var i = 0 ; i < keys.length; i++) {
          obj[keys[i]] = new moment(filter[keys[i]]).format("Y-MM-DD")
      }
      return obj
    }
  }
}

</script>

<style>
  .dateCss div input{
    border: 1px solid #e8e8e8;
    border-radius: 5px !important;
    height: 42px;
    margin-top: 3px;
    padding-left: 1% !important;
  }

  .dateCss div input::placeholder{
    padding-left: 1%;
  }

</style>

<style>
	.bg-lg-green {
		background-color: 	#98FB98;
	}

	.bg-green {
		background-color: 	#006400;
	}

	.bg-lg-blue {
		background-color: #89CFF0;
	}

	.bg-blue {
		background-color: #000033;
	}

	.bg-red {
		background-color: #DC143C;
	}

	.bg-lg-red {
		background-color: #FA8072;
	}
</style>
