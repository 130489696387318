<template>
    <div>
        <!-- <ol class="breadcrumb pull-right">
			<li class="breadcrumb-item"><a href="javascript:;">Home</a></li>
			<li class="breadcrumb-item active">Dealer Service</li>
		</ol>

        <h1 class="page-header">Dealer Service</h1> -->
        <!-- <hr> -->

        <div class="row">
            <div class="col-md-2">
                <Multiselect
                    v-model="selected.qrRegistrationControl.region"
                    class="warehouseMappingOverview"
                    :options="options.region.map(x => x.loc_code)"
                    :custom-label="opt => options.region.find(x => x.loc_code == opt).name"
                    placeholder="All Region"
                ></Multiselect>
            </div>
            <div class="col-md-4 offset-md-6">
                <!-- <period
                    @newDateRange="newDateRange"
                ></period> -->
            </div>
        </div>

        <!-- Tabs Start -->
        <!-- <div class="mt-5" style="">
            <ul class="nav nav-tabs" style="display: flex; justify-content: space-evenly;">
            <li class="nav-item" v-for="(tabName, index) in tabs">
                <a
                class="nav-link"
                :class="{
                    'active' : tab == index,
                    'clickAble' : tab != index
                }"
                @click="tab = index"
                >{{ tabName }}</a>
            </li>
            </ul>
        </div> -->
        <!-- Tabs End -->

        <div class="row mt-3" style="background-color: #fff; border: 0 solid rgba(24, 28, 33, 0.06); border-radius: 0.125rem; box-shadow: 0 10px 30px -15px rgba(24, 28, 33, 0.44); border-radius: 16px;">
            <div class="col-12" style="padding: 1.7rem">
                <div>
                    <div class="row">
                        <div class="col-md-8">
                            <h4>QR Registration Control
                                <span>
                                    <i class="fa fa-info-circle fa-sm" id="qrRegistrationControl"></i>
                                    <b-popover
                                        target="qrRegistrationControl"
                                        title="QR Registration Control"
                                        triggers="hover focus"
                                        placement="top"
                                        :content="infoPopup.qrRegistrationControl"
                                    ></b-popover>
                                </span>
                            </h4>
                        </div>
                        <div class="col-md-4 text-right">
                            <button
                                class="btn btn-outline-download"
                                @click="modal.downloadDetail = true"
                            >Download Detail&nbsp;&nbsp;<i class="fas fa-download"></i></button>
                        </div>
                    </div>
            
                    <!-- Stats Start -->
                    <div class="row mb-3">
                        <div class="col-md">
                            <small-stats
                                id="goodsReceived"
                                title="Goods Received"
                                v-if="data.qrRegistrationControl.summary.good_receive != null"
                                :value="data.qrRegistrationControl.summary.good_receive ? data.qrRegistrationControl.summary.good_receive : 0"
                            ></small-stats>
                            <b-popover
                                target="goodsReceived"
                                title="Goods Received"
                                triggers="hover focus"
                                placement="top"
                                :content="infoPopup.stats.goodsReceived"
                            ></b-popover>
                        </div>
                        <div class="col-md">
                            <small-stats
                                id="goodsIssue"
                                title="Goods Issue"
                                v-if="data.qrRegistrationControl.summary.good_issue != null"
                                :value="data.qrRegistrationControl.summary.good_issue ? data.qrRegistrationControl.summary.good_issue : 0"
                            ></small-stats>
                            <b-popover
                                target="goodsIssue"
                                title="Goods Issue"
                                triggers="hover focus"
                                placement="top"
                                :content="infoPopup.stats.goodsIssue"
                            ></b-popover>
                        </div>
                        <div class="col-md">
                            <small-stats
                                id="mappingQr"
                                title="Mapping QR"
                                v-if="data.qrRegistrationControl.summary.registered != null"
                                :value="data.qrRegistrationControl.summary.registered ? data.qrRegistrationControl.summary.registered : 0"
                            ></small-stats>
                            <b-popover
                                target="mappingQr"
                                title="Mapping QR"
                                triggers="hover focus"
                                placement="top"
                                :content="infoPopup.stats.mappingQr"
                            ></b-popover>
                        </div>
                        <div class="col-md">
                            <small-stats
                                id="unregistered"
                                title="Unregistered"
                                title_style="color:white"
                                v-if="data.qrRegistrationControl.summary.unregistered != null"
                                :value="data.qrRegistrationControl.summary.unregistered ? data.qrRegistrationControl.summary.unregistered : 0"
                                icon="exclamation-triangle"
                                icon_style="color:white"
                                box_style="background-color:#E25A5A; color:#fff"
                            ></small-stats>
                            <b-popover
                                target="unregistered"
                                title="Unregistered"
                                triggers="hover focus"
                                placement="top"
                                :content="infoPopup.stats.unregistered"
                            ></b-popover>
                        </div>
                    </div>
                    <!-- Stats End -->

                    <!-- Warehouse Mapping Overview Start -->
                    <div class="row mt-5">
                        <div class="col-md-8">
                            <h4>Warehouse Mapping Overview
                                <span>
                                    <i class="fa fa-info-circle fa-sm" id="warehouseMappingOverview"></i>
                                    <b-popover
                                        target="warehouseMappingOverview"
                                        title="Warehouse Mapping Overview"
                                        triggers="hover focus"
                                        placement="top"
                                        :content="infoPopup.warehouseMappingOverview"
                                    ></b-popover>
                                </span>
                            </h4>
                        </div>
                        <div class="col-md-4 text-right">
                            <button
                                class="btn btn-outline-download"
                            >Download Detail&nbsp;&nbsp;<i class="fas fa-download"></i></button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <wmo-chart
                                v-if="data.qrRegistrationControl.mappingOverview"
                                :data="data.qrRegistrationControl.mappingOverview"
                            ></wmo-chart>
                        </div>
                    </div>
                    <!-- Warehouse Mapping Overview End -->
            
                    <!-- Unregistered Details Start -->
                    <div class="row mt-5">
                        <div class="col-md-8">
                            <h4>Unregistered Details
                                <span>
                                    <i class="fa fa-info-circle fa-sm" id="unregisteredDetails"></i>
                                    <b-popover
                                        target="unregisteredDetails"
                                        title="Unregistered Details"
                                        triggers="hover focus"
                                        placement="top"
                                        :content="infoPopup.unregisteredDetails"
                                    ></b-popover>
                                </span>
                            </h4>
                        </div>
                        <div class="col-md-4 text-right">
                            <button
                                class="btn btn-outline-download"
                                @click="downloadLast7Days"
                            >Download Detail&nbsp;&nbsp;<i class="fas fa-download"></i></button>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-4">
                            <h4 class="chart--title text-center">Segment</h4>
                            <unregistered-details-pie-chart
                                v-if="data.qrRegistrationControl.unregistered"
                                :data="data.qrRegistrationControl.unregistered"
                            ></unregistered-details-pie-chart>
                        </div>
                        <div class="col-md-8" style="border-left: 0.5px solid #DBDBDB;">
                            <h4 class="chart--title" :class="isMobile() ? 'text-center' : 'pl-4'">Last 7 Days</h4>
                            <last7-days-line-chart
                                style="margin-top: 30px"
                                v-if="data.qrRegistrationControl.last7days"
                                :data="data.qrRegistrationControl.last7days"
                            ></last7-days-line-chart>
                        </div>
                    </div>
                    <!-- Unregistered Details End -->
            
                    <!-- Modal Start -->
                    <download-detail
                        v-if="modal.downloadDetail"
                        @closeModal="modal.downloadDetail = false"
                    ></download-detail>
                    <!-- Modal End -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import qs from 'qs'
    import moment from 'moment'
    import XLSX from 'xlsx'
    import Multiselect  from 'vue-multiselect'

    import period from './children/period';

    import smallStats from './children/smallStats'
    import downloadDetail from './children/downloadDetail'

    import unregisteredDetailsPieChart from './charts/unregisteredDetailsPieChart'
    import wmoChart from './charts/wmoChart'
    import last7DaysLineChart from './charts/last7DaysLineChart'

    export default {
        data () {
            return {
                infoPopup : this.$store.state.hoverInformation.highlightWarehouse.qrRegistrationControl,
                tab : 2,
                tabs : ['Stock Overview', 'Stock Analytics', 'QR Registration Control'],
                periodFilter : {

                },
                prevFilter : {
                    serviceOverview : {},
                    serviceOperation : {}
                },
                options : {
                    region : this.$store.getters.getRegion,
                },
                selected : {
                    qrRegistrationControl : {
                        region : null,
                    }
                },
                filter : {
                    stockOverview : {

                    },
                    stockAnalytics : {

                    },
                    qrRegistrationControl : {
                        summary : {},
                        mappingOverview : {},
                        unregistered : {},
                        last7days : {}
                    }
                },
                data : {
                    stockOverview : {

                    },
                    stockAnalytics : {

                    },
                    qrRegistrationControl : {
                        summary : null,
                        mappingOverview : null,
                        unregistered : null,
                        last7days : null,
                    }
                },


                modal : {
                    downloadDetail : false,
                },
                regionLabel : 'All Region',
                regionList : []
            }
        },
        components : {
            Multiselect,
            period,

            smallStats,
            downloadDetail,

            unregisteredDetailsPieChart,
            wmoChart,
            last7DaysLineChart,
        },
        async mounted () {
            await this.resetOperations();
            this.fetchData();

            let permission = this.$store.getters.currentUser.permission
            if (permission && permission.length){
                this.regionLabel = permission.join(", ")
                this.regionList = [];
            } else
                this.regionLabel = 'All Region'
        },
        watch : {
            //PERIOD FILTER
            // 'periodFilter.serviceOverview' : {
            //     deep : true,
            //     handler () {
            //         this.fetchData('serviceOverview')
            //     }
            // },
            // 'periodFilter.serviceOperation' : {
            //     deep : true,
            //     handler () {this.fetchData('serviceOperation')}
            // },
            'periodFilter' : {
                deep : true,
                async handler () {
                    this.fetchData(await this.getCurrentPageVariable());
                },
            },

            'selected.qrRegistrationControl' : {
                deep : true,
                async handler () {
                    for (var i in this.filter.qrRegistrationControl) this.filter.qrRegistrationControl[i].region = this.selected.qrRegistrationControl.region;
                    this.fetchData(await this.getCurrentPageVariable());
                },
            },

            //INDIVIDUAL FILTERS
            //Service Overview
            'filter.serviceOverview' : {
                deep : true,
                handler (val) {
                    for (var i in val) {
                        if (JSON.stringify(val[i]) !== JSON.stringify(this.prevFilter.serviceOverview[i]))
                            this.fetchData('serviceOverview', i);
                    }

                    this.prevFilter.serviceOverview = JSON.parse(JSON.stringify(this.filter.serviceOverview))
                }
            },
            //Service Operation
            'filter.serviceOperation' : {
                deep : true,
                handler (val) {
                    for (var i in val) {
                        if (JSON.stringify(val[i]) !== JSON.stringify(this.prevFilter.serviceOperation[i]))
                            this.fetchData('serviceOperation', i);
                    }

                    this.prevFilter.serviceOperation = JSON.parse(JSON.stringify(this.filter.serviceOperation))
                }
            }
        },
        methods : {
            getCurrentPageVariable () {
                let keys = Object.keys(this.data);
                return keys[this.tab]
            },
            newDateRange (obj) {
                this.periodFilter = obj;

                for (var i in this.filter) {
                    for (var j in this.filter[i]) {
                        this.filter[i][j].start = obj.start;
                        this.filter[i][j].end = obj.end;
                    }
                }
            },
            resetOperations () {
                //Set Date Ranges
                // let start = moment().startOf('month').format('YYYY-MM-DD 00:00:00')
                // let end = moment().format('YYYY-MM-DD 23:59:59')
                let start = moment().subtract(1, 'days').format('YYYY-MM-DD 00:00:00')
                let end = moment().subtract(1, 'days').format('YYYY-MM-DD 23:59:59')

                this.periodFilter.start = start;
                this.periodFilter.end = end;

                for (var i in this.filter) {
                    for (var j in this.filter[i]) {
                        this.filter[i][j].start = start;
                        this.filter[i][j].end = end;
                    }
                }

                for (var i in this.filter) {
                    this.prevFilter[i] = JSON.parse(JSON.stringify(this.filter[i]));
                }
            },
            fetchData (group, subGroup) {
                var list = {}
                if(group) list = {group:subGroup?[subGroup]:Object.keys(this.data[group])}
                else {
                    var keys = Object.keys(this.data)
                    for(var i in keys) list[keys[i]] = Object.keys(this.data[keys[i]]);
                }
                console.log(JSON.stringify(list));

                for (var i in list){//stock overview, stock operation
                    for (var j in list[i]){//real url
                        let groupA = group?group:i
                        let listA = list[i][j]
                        this.$http.get(`/api/highlight/warehouse/${listA}${qs.stringify(this.filter[groupA][listA], {skipNulls:true, addQueryPrefix:true})}`)
                        .then((resp)=>{
                            this.data[groupA][listA] = resp.data.data;
                        })
                        .catch((err)=>{console.log(err)})
                    }
                }
            },
            downloadLast7Days () {
                this.$http.get(`/api/highlight/warehouse/last7days/download?start=${this.filter.qrRegistrationControl.last7days.start}&end=${this.filter.qrRegistrationControl.last7days.end}`)
                .then(async (resp)=>{
                    await this.exportFile(resp.data.data)
                })
                .catch((err)=>{console.log(err)})
            },
            async exportFile(data){
                let wb = XLSX.utils.book_new()
                var ws = XLSX.utils.json_to_sheet(data, {raw:true});
                XLSX.utils.book_append_sheet(wb, ws, 'Data')

                let exportFileName = `Last 7 Days (${this.filter.qrRegistrationControl.last7days.start} - ${this.filter.qrRegistrationControl.last7days.end}).xlsx`
                XLSX.writeFile(wb, exportFileName)
            },
        }
    }
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@500;700&display=swap');
    
    .chart--title {
        margin: 0;
        font: normal normal 500 18px/21px Ubuntu;
        color: #707070;
    }

    .btn-outline-download {
        box-shadow: 0px 2px 6px #0000001A;
        border-radius: 5px;
        font: normal normal 500 10px/11px Ubuntu;
        color: #676767;
    }

    .btn-elegant {
        background: #FFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 6px #00000014;
        border: 0.5px solid #EEE;
        padding: 0.5rem 2rem;
        font: normal normal 500 10px/11px Ubuntu;
    }

    .btn-elegant-unselected {
        background: #EEE 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 6px #00000014;
        padding: 0.5rem 2rem;
        font: normal normal 500 10px/11px Ubuntu;
    }

    .warehouseMappingOverview .multiselect__tags  {
        background-color: #E25A5A !important;
    }

    .warehouseMappingOverview .multiselect__single , .warehouseMappingOverview .multiselect__input , .warehouseMappingOverview .multiselect__input::placeholder , .warehouseMappingOverview .multiselect__placeholder {
        background-color: #E25A5A !important;
        color: white;
    }
    
    .warehouseMappingOverview .multiselect__select:before{
        color: white !important;
    }

    .warehouseMappingOverview.disabledButton .multiselect__select {
        background-color: #E25A5A
    }
</style>

<style scoped>
    .nav-tabs, .nav-link{
        border:1px solid transparent !important;
    }
    .nav-tabs {
        /* border-bottom: 1px solid #dee2e6 !important; */
        border-bottom: 0 !important;
    }
    .nav-link{
        border:1px solid !important;
        border-radius: 16px 16px 0 0;
        border-color: transparent !important;
        background-image: none;
        background-color: #fff;
        color: #EB979A !important;
    }
    .nav-link:before {
        content: "";
        box-shadow: 0 3px 6px #00000029;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
    }
    .nav-link.active{
        background-color: #fff;
        border-color:#E25A5A #E25A5A #fff !important;
        color: #E25A5A !important;
        /* background-color:#F5F5F5; */
    }

    .nav-item {
        width:33.3%;
        text-align: center;
        border-bottom: 0;
    }
    .clickAble:hover{
        cursor:pointer;
    }
    h4 {
        font-style: normal;
        font-variant: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        font-family: Ubuntu;
    }
</style>