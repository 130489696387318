<template>
  <div>
    <b-card header="Service Duration" header-tag="h5" class="mb-4">
      <b-form>
        <b-form-row>
          <b-form-group label="Month" class="col-md-3">
            <b-select class="custom-select mr-sm-2 mb-2 mb-sm-0" value="January" :options="['January', 'February', 'March']" />
          </b-form-group>
          <b-form-group label="Year" class="col-md-3">
            <b-select class="custom-select mr-sm-2 mb-2 mb-sm-0" value="2020" :options="['2020', '2019', '2018']" />
          </b-form-group>
          <b-form-group label="Warehouse Location" class="col-md-5">
            <b-select class="custom-select mr-sm-2 mb-2 mb-sm-0" value="All" :options="['All','Semarang','Denpasar','NTB','Yogyakarta','Palembang']" />
          </b-form-group>
          <b-form-group class="col-md-2">
            <b-btn type="submit" variant="primary">Filter</b-btn>
          </b-form-group>
        </b-form-row>
      </b-form>
    </b-card>
    <div class="inner-box">
      <h6>Highlights</h6>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 stats-outer-box">
          <div class="stats-inner-box" style="background-color: #FF5B57">
            <div class="row" style="height: 100%">
                <div class="col-8">
                  <p>Max Waiting Time</p>
                  <p class="stats-value">
                    48 Hours
                  </p>
                  <!-- <h2>2,000,000</h2> -->
                </div>
                <div class="col-4 icon">
                  <i class="fas fa-clock"></i>
                </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 stats-outer-box">
          <div class="stats-inner-box" style="background-color:#49B6D6">
              <div class="row" style="height: 100%">
                  <div class="col-8">
                    <p>Average Waiting Time</p>
                    <p class="stats-value">
                      4 Hours
                      <!-- <span class="stats-value-support">(4,5)</span> -->
                    </p>
                  </div>
                  <div class="col-4 icon">
                    <i class="fas fa-hourglass-half"></i>
                  </div>
              </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 stats-outer-box">
          <div class="stats-inner-box" style="background-color: #F59C19;">
              <div class="row" style="height: 100%">
                  <div class="col-8">
                    <p>Average Settlement</p>
                    <p class="stats-value">
                      Rp. 120,000
                      <!-- <span class="stats-value-support">(20,000 sold)</span> -->
                    </p>
                  </div>
                  <div class="col-4 icon">
                    <i class="fas fa-money-bill-wave"></i>
                  </div>
              </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 stats-outer-box">
          <div class="stats-inner-box">
              <div class="row" style="height: 100%">
                  <div class="col-8">
                    <p>Maximum Settlement</p>
                    <p class="stats-value">
                      Rp. 82,020,000
                      <!-- <span class="stats-value-support">(20,000 sold)</span> -->
                    </p>
                  </div>
                  <div class="col-4 icon">
                    <i class="fas fa-credit-card"></i>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="inner-box">
      <h6>Service Duration by Day</h6>
      <service-duration-box-plot></service-duration-box-plot>
      <service-duration-table></service-duration-table>
    </div>
  </div>
</template>

<script>
import ServiceDurationBoxPlot from './childComponents/ServiceDurationBoxPlot'
import ServiceDurationTable from './childComponents/ServiceDurationTable'

export default {
  name: 'service-duration',
  metaInfo: {
    title: 'Service Duration'
  },
  components: {
    // flatPickr
    ServiceDurationBoxPlot,
    ServiceDurationTable
  },
  data () {
    return {

    }
  },
  mounted () {
  },
  methods: {

  }
}
</script>

<style scoped>
  .stats-outer-box{
    padding:20px;
  }

  .stats-inner-box{
    border-radius: 10px;
    background-color: #348FE2;
    height: 100%;
    padding-bottom:10px
  }

  .stats-inner-box p, .stats-inner-box h2{
    color: white;
  }
  .stats-inner-box p{
    margin:5% 0 0 12.5%;
  }
  .stats-inner-box .stats-value{
    margin-top: 0;
    margin-left:10%;
    font-size: 2em;
    font-weight: 500;
  }
  .stats-inner-box .stats-value .stats-value-support{
    font-size: 0.5em;
    font-weight: normal;
  }
  .stats-inner-box h2{
    margin-left:10%
  }
  .stats-inner-box .icon{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .stats-inner-box .icon i{
    color: #00000050;
    font-size: 3em;
  }
  .inner-box{
    border-radius: 5px;
    background-color: #fff;
    padding: 20px;
    height: 100%;
    margin-bottom: 25px;
  }
</style>
