<template>
    <div style="padding: 1.7rem">
        <div class="row">
            <div class="col-md-8">
                <h4>QR Registration Control ({{ regionLabel }})
					<span>
						<i class="fa fa-info-circle fa-sm" id="qrRegistrationControl"></i>
						<b-popover
                            target="qrRegistrationControl"
                            title="QR Registration Control"
                            triggers="hover focus"
                            placement="top"
                            :content="infoPopup.qrRegistrationControl"
						></b-popover>
					</span>
                </h4>
            </div>
            <div class="col-md-4 text-right">
                <button
                    class="btn btn-outline-download"
                    @click="modal.downloadDetail = true"
                >Download Detail&nbsp;&nbsp;<i class="fas fa-download"></i></button>
            </div>
        </div>

        <!-- Stats Start -->
        <div class="row mb-3">
            <div class="col-md">
                <small-stats
                    id="goodsReceived"
                    title="Goods Received"
                    :value="data.summary.good_receive ? data.summary.good_receive : 0"
                ></small-stats>
                <b-popover
                    target="goodsReceived"
                    title="Goods Received"
                    triggers="hover focus"
                    placement="top"
                    :content="infoPopup.stats.goodsReceived"
                ></b-popover>
            </div>
            <div class="col-md">
                <small-stats
                    id="goodsIssue"
                    title="Goods Issue"
                    :value="data.summary.good_issue ? data.summary.good_issue : 0"
                ></small-stats>
                <b-popover
                    target="goodsIssue"
                    title="Goods Issue"
                    triggers="hover focus"
                    placement="top"
                    :content="infoPopup.stats.goodsIssue"
                ></b-popover>
            </div>
            <div class="col-md">
                <small-stats
                    id="mappingQr"
                    title="Mapping QR"
                    :value="data.summary.registered ? data.summary.registered : 0"
                ></small-stats>
                <b-popover
                    target="mappingQr"
                    title="Mapping QR"
                    triggers="hover focus"
                    placement="top"
                    :content="infoPopup.stats.mappingQr"
                ></b-popover>
            </div>
            <div class="col-md">
                <small-stats
                    id="unregistered"
                    title="Unregistered"
                    title_style="color:white"
                    :value="data.summary.unregistered ? data.summary.unregistered : 0"
                    icon="exclamation-triangle"
                    icon_style="color:white"
                    box_style="background-color:#E25A5A; color:#fff"
                ></small-stats>
                <b-popover
                    target="unregistered"
                    title="Unregistered"
                    triggers="hover focus"
                    placement="top"
                    :content="infoPopup.stats.unregistered"
                ></b-popover>
            </div>
        </div>
        <!-- Stats End -->

        <!-- Unregistered Details Start -->
        <div class="row mt-5">
            <div class="col-md-8">
                <h4>Unregistered Details
					<span>
						<i class="fa fa-info-circle fa-sm" id="unregisteredDetails"></i>
						<b-popover
                            target="unregisteredDetails"
                            title="Unregistered Details"
                            triggers="hover focus"
                            placement="top"
                            :content="infoPopup.unregisteredDetails"
						></b-popover>
					</span>
                </h4>
            </div>
            <div class="col-md-4 text-right">
                <button
                    class="btn btn-outline-download"
                    @click="downloadLast7Days"
                >Download Detail&nbsp;&nbsp;<i class="fas fa-download"></i></button>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-4">
                <h4 class="chart--title text-center">Segment</h4>
                <unregistered-details-pie-chart
                    v-if="data.unregistered"
                    :data="data.unregistered"
                ></unregistered-details-pie-chart>
            </div>
            <div class="col-md-8" style="border-left: 0.5px solid #DBDBDB;">
                <h4 class="chart--title" :class="isMobile() ? 'text-center' : 'pl-4'">Last 7 Days</h4>
                <last7-days-line-chart
                    style="margin-top: 30px"
                    v-if="data.last7days"
                    :data="data.last7days"
                ></last7-days-line-chart>
            </div>
        </div>
        <!-- Unregistered Details End -->

        <!-- Modal Start -->
        <download-detail
            v-if="modal.downloadDetail"
            :regionList="regionList"
            @closeModal="modal.downloadDetail = false"
        ></download-detail>
        <!-- Modal End -->
    </div>
</template>

<script>
    import XLSX from 'xlsx'

    import smallStats from './children/smallStats'
    import downloadDetail from './children/downloadDetail'

    import unregisteredDetailsPieChart from './charts/unregisteredDetailsPieChart'
    import last7DaysLineChart from './charts/last7DaysLineChart'

    export default {
        data () {
            return {
                modal : {
                    downloadDetail : false,
                },
                regionLabel : 'All Region',
                regionList : [],
                infoPopup : this.$store.state.hoverInformation.highlightWarehouse.qrRegistrationControl
            }
        },
        props : ['data', 'filter'],
        components : {
            smallStats,
            downloadDetail,

            unregisteredDetailsPieChart,
            last7DaysLineChart,
        },
        mounted () {
            let permission = this.$store.getters.currentUser.permission
            if (permission && permission.length){
                this.filter.summary.region = permission.join(", ");
                this.filter.unregistered.region = permission.join(", ");
                this.filter.last7days.region = permission.join(", ");

                this.regionLabel = permission.join(", ")
                this.regionList = [];
            } else
                this.regionLabel = 'All Region'
        },
        methods : {
            downloadLast7Days () {
                this.$http.get(`/api/highlight/warehouse/last7days/download?start=${this.filter.last7days.start}&end=${this.filter.last7days.end}`)
                .then(async (resp)=>{
                    await this.exportFile(resp.data.data)
                })
                .catch((err)=>{console.log(err)})
            },
            async exportFile(data){
                let wb = XLSX.utils.book_new()
                var ws = XLSX.utils.json_to_sheet(data, {raw:true});
                XLSX.utils.book_append_sheet(wb, ws, 'Data')

                let exportFileName = `Last 7 Days (${this.filter.last7days.start} - ${this.filter.last7days.end}).xlsx`
                XLSX.writeFile(wb, exportFileName)
            },
        }
    }
</script>

<style scoped>
    h4 {
        font-style: normal;
        font-variant: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        font-family: Ubuntu;
    }
</style>