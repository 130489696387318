<template>
  <div>
    <div class="row">
      <div class="col text-right">
        <button class="btn btn-sm btn-primary" type="button" @click="exportExcel">Export To Excel</button>
      </div>
    </div>
    <div ref='replacementReason' style='min-height:450px'></div>
  </div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import material from '@amcharts/amcharts4/themes/material.js'
import animated from '@amcharts/amcharts4/themes/animated.js'

import XLSX from 'xlsx'

am4core.useTheme(material)
am4core.useTheme(animated)

export default {
  data () {
    return {
      dataForExport: null,
      chart: null,
      chart2: null,
      selectedSlice: null,
      pieSeries2: null,
      pieSeries: null,
      line1: null,
      line2: null
    }
  },
  props : ['filterUrl'],
  mounted () {
    this.createChart();
    this.getData();
  },
  watch : {
    'filterUrl' : function() {
      this.getData();
    }
  },
  methods: {
    getData(){
      var length = this.chart.data.length;
      let vm = this;

      this.$http.get('/api/h2/replaced-qr/reasons?' + this.filterUrl)
      .then((resp)=>{
        var data = resp.data.data;
        this.dataForExport = data;
        vm.chart.addData((data != null && data.length != 0) ? data : {'count' : 1, 'reason' : 'No Data'}, length);
      })
    },
    exportExcel() {
      let wb = XLSX.utils.book_new()
      let exportFileName = `Replaced QR Reasons.xlsx`
      var ws = XLSX.utils.json_to_sheet(this.dataForExport, {raw:true});
      XLSX.utils.book_append_sheet(wb, ws, 'Data')
      XLSX.writeFile(wb, exportFileName)
    },
    createChart () {
      let container = am4core.create(this.$refs.replacementReason, am4core.Container)
      container.width = am4core.percent(100)
      container.height = am4core.percent(100)
      container.layout = 'horizontal'

      this.chart = container.createChild(am4charts.PieChart)

      // Add and configure Series
      this.pieSeries = this.chart.series.push(new am4charts.PieSeries())
      this.pieSeries.dataFields.value = 'count'
      this.pieSeries.dataFields.category = 'reason'
      this.pieSeries.slices.template.states.getKey('active').properties.shiftRadius = 0
      // this.pieSeries.labels.template.text = "{category}: {value.percent.formatNumber('#.#')}% ({value} Units)"
      this.pieSeries.labels.template.text = "{category}: {value} Units"

      let selectSlice = this.selectSlice

      // this.pieSeries.colors.list = [am4core.color('blue'), am4core.color('orange'), am4core.color('yellow'), am4core.color('red')]

      this.pieSeries.slices.template.events.on('hit', function (event) {
        selectSlice(event.target.dataItem)
      })

      this.chart.legend = new am4charts.Legend();
    },
    selectSlice (dataItem) {
      this.selectedSlice = dataItem.slice

      let fill = this.selectedSlice.fill

      let count = dataItem.dataContext.subData.length
      this.pieSeries2.colors.list = []
      for (var i = 0; i < count; i++) {
        this.pieSeries2.colors.list.push(fill.brighten(i * 2 / count))
      }

      this.chart2.data = dataItem.dataContext.subData
      this.pieSeries2.appear()

      let middleAngle = this.selectedSlice.middleAngle
      let firstAngle = this.pieSeries.slices.getIndex(0).startAngle
      let animation = this.pieSeries.animate([{ property: 'startAngle', to: firstAngle - middleAngle }, { property: 'endAngle', to: firstAngle - middleAngle + 360 }], 600, am4core.ease.sinOut)
      animation.events.on('animationprogress', this.updateLines)

      this.selectedSlice.events.on('transformed', this.updateLines)

    //  var animation = this.chart2.animate({property:'dx', from:-container.pixelWidth / 2, to:0}, 2000, am4core.ease.elasticOut)
    //  animation.events.on('animationprogress', this.updateLines)
    },
    updateLines () {
      if (this.selectedSlice) {
        let p11 = { x: this.selectedSlice.radius * am4core.math.cos(this.selectedSlice.startAngle), y: this.selectedSlice.radius * am4core.math.sin(this.selectedSlice.startAngle) }
        let p12 = { x: this.selectedSlice.radius * am4core.math.cos(this.selectedSlice.startAngle + this.selectedSlice.arc), y: this.selectedSlice.radius * am4core.math.sin(this.selectedSlice.startAngle + this.selectedSlice.arc) }

        p11 = am4core.utils.spritePointToSvg(p11, this.selectedSlice)
        p12 = am4core.utils.spritePointToSvg(p12, this.selectedSlice)

        let p21 = { x: 0, y: -this.pieSeries2.pixelRadius }
        let p22 = { x: 0, y: this.pieSeries2.pixelRadius }

        p21 = am4core.utils.spritePointToSvg(p21, this.pieSeries2)
        p22 = am4core.utils.spritePointToSvg(p22, this.pieSeries2)

        this.line1.x1 = p11.x
        this.line1.x2 = p21.x
        this.line1.y1 = p11.y
        this.line1.y2 = p21.y

        this.line2.x1 = p12.x
        this.line2.x2 = p22.x
        this.line2.y1 = p12.y
        this.line2.y2 = p22.y
      }
    }
  }
}
</script>
