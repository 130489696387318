<script>/* eslint-disable */</script>
<template>
	<div>
		<div ref="trafficDistribution" style="min-height: 700px">
		</div>
		<br>

		<table class="table table-bordered">
			<thead>
				<th class="text-center">Day</th>
				<th class="text-center" v-for="i in 7">{{ dayNames[i-1] }}</th>
			</thead>
			<tbody>
				<tr>
					<td class="text-center">Total :</td>
					<td class="text-center" v-if="dataReady" v-for="(percentage , index) in totalDaily" :key="percentage">
						{{percentage.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}} ( {{percentageDaily[index]}}% )</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core"
import * as am4charts from "@amcharts/amcharts4/charts"
import am4themes_animated from "@amcharts/amcharts4/themes/animated"
import axios from 'axios'

export default {
	data() {
		return {
			company: sessionStorage.getItem('company'),
			data: [],
			dataPercentage: null,
			totalDaily: null,
			chartData: [],
			chart: null,
			percentageDaily: [],
			dataReady: false,
			dayNames : ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
		}
	},
	props: ['filterUrl'],
	mounted() {
		this.generateChart()
		// this.generateData()
		this.fetchData()
	},
	watch : {
		'filterUrl' : function (){
			this.fetchData();
		}
	},
	methods: {
		fetchData() {
			let vm = this;
			vm.dataReady = false;

			this.$http.get('/api/h2/workshop-traffic?' + this.filterUrl)
			.then((resp)=>{
				vm.data = resp.data.data
				vm.countPercentageTotal()

				var totalService = 0;
				for (var i = 0; i < this.totalDaily.length; i++) {
					totalService += this.totalDaily[i]
				}
				for (var i = 0; i < this.totalDaily.length; i++) {
					this.percentageDaily[i] = parseFloat(this.totalDaily[i] / totalService * 100).toFixed(2);
				}
				this.dataReady = true;
			})
			.catch((err)=>{
				console.log(err);
			})
		},
		countTotal() {
			var total = [0, 0, 0, 0, 0, 0, 0]
			for (var i = 0; i < this.data.length; i++) {
				for (var j = 0; j < 7; j++) {
					total[j] += this.data[i].day[j]
				}
			}
			this.totalDaily = total;
		},
		countPercentageTotal() {
			this.countTotal()
			var dataPercentage = JSON.parse(JSON.stringify(this.data))

			for (var i = 0; i < dataPercentage.length; i++) {
				for (var j = 0; j < dataPercentage[i].day.length; j++) {
					if (this.totalDaily[j] > 0) {
						if (dataPercentage[i].day[j] > 0) {
							dataPercentage[i].day[j] = dataPercentage[i].day[j] / this.totalDaily[j] * 100
							dataPercentage[i].day[j] = Math.round(dataPercentage[i].day[j] * 100) / 100
						} else {
							dataPercentage[i].day[j] = 0;
						}
					} else {
						dataPercentage[i].day[j] = 1 / dataPercentage.length * 100;
						dataPercentage[i].day[j] = Math.round(dataPercentage[i].day[j] * 100) / 100;
					}
				}
			}
			this.dataPercentage = dataPercentage;

			this.processDataPercentage();
		},
		processDataPercentage() {
			var subData = {};
			var length = this.chart.data.length;
			this.chartData.length = 0;
			for (var i = 0; i < this.dataPercentage.length; i++) {
				for (var j = 0; j < 7; j++) {
					if (this.data[i].hour - 9 <= 0) {
						subData = {
							"hour": "0" + this.dataPercentage[i].hour + ":00",
							"weekday": this.dayNames[j],
							"value": this.dataPercentage[i].day[j]
						}
					} else {
						subData = {
							"hour": "" + this.data[i].hour + ":00",
							"weekday": this.dayNames[j],
							"value": this.dataPercentage[i].day[j]
						}
					}
					this.chartData.push(subData)
				}
			}
			this.chart.addData(this.chartData, length);
		},
		generateChart() {
			am4core.useTheme(am4themes_animated)

			this.chart = am4core.create(this.$refs.trafficDistribution, am4charts.XYChart)
			this.chart.maskBullets = false

			let xAxis = this.chart.xAxes.push(new am4charts.CategoryAxis())
			let yAxis = this.chart.yAxes.push(new am4charts.CategoryAxis())

			// let title = this.chart.titles.create()
			// title.text = "Traffic Distribution"
			// title.fontSize = 20
			// title.marginBottom = 30
			// title.fontWeight = "Bold"

			xAxis.dataFields.category = "weekday"
			yAxis.title.text = "Hour"
			yAxis.dataFields.category = "hour"

			xAxis.renderer.grid.template.disabled = true
			xAxis.renderer.minGridDistance = 40

			yAxis.renderer.grid.template.disabled = true
			yAxis.renderer.inversed = true
			yAxis.renderer.minGridDistance = 30

			let series = this.chart.series.push(new am4charts.ColumnSeries())
			series.dataFields.categoryX = "weekday"
			series.dataFields.categoryY = "hour"
			series.dataFields.value = "value"
			series.sequencedInterpolation = true
			series.defaultState.transitionDuration = 3000

			let bullet2 = series.bullets.push(new am4charts.LabelBullet());
			bullet2.label.text = "[bold]{value}%";
			bullet2.label.fill = am4core.color("#111");
			bullet2.zIndex = 1;
			bullet2.fontSize = 11;
			bullet2.interactionsEnabled = false;

			let bgColor = new am4core.InterfaceColorSet().getFor("background")

			let columnTemplate = series.columns.template
			columnTemplate.strokeWidth = 1
			columnTemplate.strokeOpacity = 0.2
			columnTemplate.stroke = "#333"
			columnTemplate.tooltipText = "{weekday}, {hour}: {value.workingValue.formatNumber('#.')}%"
			columnTemplate.width = am4core.percent(100)
			columnTemplate.height = am4core.percent(100)

			series.heatRules.push({
				target: columnTemplate,
				property: "fill",
				min: am4core.color("#fff"),
				max: am4core.color("#0080ff")
			})

			// heat legend
			let heatLegend = this.chart.bottomAxesContainer.createChild(am4charts.HeatLegend)
			heatLegend.minValue = 0
			heatLegend.width = am4core.percent(100)
			heatLegend.series = series
			heatLegend.valueAxis.renderer.labels.template.fontSize = 9
			heatLegend.valueAxis.renderer.minGridDistance = 30

			// heat legend behavior
			series.columns.template.events.on("over", function (event) {
				handleHover(event.target);
			})

			series.columns.template.events.on("hit", function (event) {
				handleHover(event.target);
			})

			function handleHover(column) {
				if (!isNaN(column.dataItem.value)) {
					heatLegend.valueAxis.showTooltipAt(column.dataItem.value)
				} else {
					heatLegend.valueAxis.hideTooltip();
				}
			}

			series.columns.template.events.on("out", function (event) {
				heatLegend.valueAxis.hideTooltip();
			})
		}
	}
}
</script>

<style scoped>
	.trafficDistribution {
		border: 1px solid black;
		background-color: white;
		text-align: center;
		padding: 3%;
		overflow: scroll;
	}

	.trafficDistributionTitle {
		margin-bottom: 20px
	}

	.tableTraffic {
		border-top: 1.5px solid black;
		font-weight: bold;
		text-align: center;
		margin-bottom: 0px;
	}

	.tableBox {
		border-left: 1.5px solid black;
		border-right: 1.5px solid black;
		border-top: 1px solid #757575;
		border-bottom: 1px solid #757575;
	}
</style>