<script>/* eslint-disable */</script>
<template>
    <div>
        <!--Filter Option-->
        <b-card title="H1 Report" :sub-title="infoPopup.title" header-tag="h6" class="mb-4">
            <hr>
            <b-form>
                <b-form-row>
                    <!-- <b-form-group label="Branch" class="col-md-3">
                        <Multiselect 
                            v-if="filterData"
                            class="mt-1"
                            :options="filterData.branch"
                            label="name"
                            track-by="loc_code"
                            :multiple="true"
                            v-model="filter.branch"
                            placeholder="All Branches"
                        />
                    </b-form-group> -->
                    <b-form-group label="Start Date" class="col-md-6 dateCss" style="height:42px" >
                        <datepicker name="start" id="start" :format="customFormatter" bootstrap-styling v-model="filter.start"></datepicker>
                    </b-form-group>
                    <b-form-group label="End Date" class="col-md-6 dateCss" style="height:42px" >
                        <datepicker name="end" id="end" :format="customFormatter" bootstrap-styling v-model="filter.end"></datepicker>
                    </b-form-group>
                </b-form-row>
                <b-btn class="col-md-4 mt-4" type="button" variant="primary" @click="search(0)" :disabled="isFetching"><span class="fa fa-filter"></span>&nbsp;&nbsp;Search</b-btn>
            </b-form>
        </b-card>

        <b-card class="mb-4" v-if="!firstDataLoad">
            <div class="row pt-5 pb-5">
                <div class="col">
                    <h2 class="text-center" style="color:grey">Click Search to Load Data!</h2>
                </div>
            </div>
        </b-card>

        <b-card class="mb-4" v-if="firstDataLoad">
            <div class="row mb-3">
                <h4 class="col-md-2 col-sm-4 col-10 Aligner-Header ">
                    H1 Details By Region
                </h4>
                <span class="col-md-1 col-sm-1 col-2">
                    <i class="fa fa-info-circle fa-lg" id="h1DetailsByRegion"></i>
                    <b-popover
                    target="h1DetailsByRegion"
                    title="H1 Details By Region"
                    triggers="hover focus"
                    placement="top"
                    :content="infoPopup.h1DetailsByRegion"
                    ></b-popover>
                </span>
                <div class="col-md-1"></div>
                <!-- <b-form-group label="Region" class="offset-md-4 col-md-3 Aligner-Header"> -->
                        
                <!-- </b-form-group> -->
                <div class="offset-md-6 col-md-2 col-sm-4 col-12 Aligner-Header">
                    <button @click="search(1,'/h1-report/by-region')" class=" btn btn-success w-100" style="max-height:46px"><span class="fa fa-file-excel"/> Export</button>
                </div>
            </div>
            <div class="w-100"  v-if="by_region"  style="overflow-x:scroll">
                <table class=" tableBodyScroll table text-center">
                <thead>
                    <tr style="background-color:black;color:white">
                        <th>Region Code</th>
                        <th>Region Name</th>
                        <th id="qr-registered-region">QR Registered</th>
                        <b-popover
                            target="qr-registered-region"
                            title="QR Registered"
                            triggers="hover focus"
                            placement="top"
                            :content="infoPopup.tableHeaders.QrRegistered"
                        ></b-popover>
                        <th id="qr-replacement-region">QR Replacement</th>
                        <b-popover
                            target="qr-replacement-region"
                            title="QR Replacement"
                            triggers="hover focus"
                            placement="top"
                            :content="infoPopup.tableHeaders.QrReplacement"
                        ></b-popover>
                        <th id="slahm-region">SLAHM</th>
                        <b-popover
                            target="slahm-region"
                            title="SLAHM"
                            triggers="hover focus"
                            placement="top"
                            :content="infoPopup.tableHeaders.SLAHM"
                        ></b-popover>
                        <th id="gr-md-region">GR MD</th>
                        <b-popover
                            target="gr-md-region"
                            title="GR MD"
                            triggers="hover focus"
                            placement="top"
                            :content="infoPopup.tableHeaders.GRMD"
                        ></b-popover>
                        <th id="gi-md-region">GI MD</th>
                        <b-popover
                            target="gi-md-region"
                            title="GI MD"
                            triggers="hover focus"
                            placement="top"
                            :content="infoPopup.tableHeaders.GIMD"
                        ></b-popover>
                        <th id="gr-d-region">SPG</th>
                        <b-popover
                            target="gr-d-region"
                            title="SPG"
                            triggers="hover focus"
                            placement="top"
                            :content="infoPopup.tableHeaders.GRDealer"
                        ></b-popover>
                        <th id="gi-d-region">BSTK</th>
                        <b-popover
                            target="gi-d-region"
                            title="BSTK"
                            triggers="hover focus"
                            placement="top"
                            :content="infoPopup.tableHeaders.GIDealer"
                        ></b-popover>
                        <th id="spg-scan-region">SPG Scan</th>
                        <b-popover
                            target="spg-scan-region"
                            title="SPG Scan"
                            triggers="hover focus"
                            placement="top"
                            :content="infoPopup.tableHeaders.SPGScan"
                        ></b-popover>
                        <th id="bstk-scan-region">BSTK Scan</th>
                        <b-popover
                            target="bstk-scan-region"
                            title="BSTK Scan"
                            triggers="hover focus"
                            placement="top"
                            :content="infoPopup.tableHeaders.BSTKScan"
                        ></b-popover>
                        <th id="id-customer-region">ID Customer</th>
                        <b-popover
                            target="id-customer-region"
                            title="ID Customer"
                            triggers="hover focus"
                            placement="top"
                            :content="infoPopup.tableHeaders.IdCustomer"
                        ></b-popover>
                        <th id="id-customer-qr-region">ID Customer with QR</th>
                        <b-popover
                            target="id-customer-qr-region"
                            title="ID Customer With QR"
                            triggers="hover focus"
                            placement="top"
                            :content="infoPopup.tableHeaders.IdCustomerWithQr"
                        ></b-popover>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data, index) in by_region" :key="index">
                        <td>{{data.code}}</td>
                        <td>{{data.name}}</td>
                        <td>{{numberWithCommas(data.qr_register)}}</td>
                        <td>{{numberWithCommas(data.qr_replacement)}}</td>
                        <td>{{numberWithCommas(data.slahm)}}</td>
                        <td>{{numberWithCommas(data.gr_md)}}</td>
                        <td>{{numberWithCommas(data.gi_md)}}</td>
                        <td>{{numberWithCommas(data.gr_dealer)}}</td>
                        <td>{{numberWithCommas(data.gi_dealer)}}</td>
                        <td>{{numberWithCommas(data.spg_scan)}}</td>
                        <td>{{numberWithCommas(data.bstk_scan)}}</td>
                        <td>{{numberWithCommas(data.id_cust)}}</td>
                        <td>{{numberWithCommas(data.id_cust_with_qr)}}</td>
                    </tr>
                </tbody>
            </table>
            </div>
            
            <div class="text-center w-100" v-else>
                <h5>Loading.......</h5>
            </div>
        </b-card>

        <b-card class="mb-4" v-if="firstDataLoad">
            <div class="row mb-3">
                <h4 class="col-md-2 col-sm-4 col-12 Aligner-Header ">H1 Details By Dealer</h4>
                <span class="col-md-1 col-sm-1 col-2 mt-2">
                    <i class="fa fa-info-circle fa-lg" id="h1DetailsByDealer"></i>
                    <b-popover
                    target="h1DetailsByDealer"
                    title="H1 Details By Dealer"
                    triggers="hover focus"
                    placement="top"
                    :content="infoPopup.h1DetailsByDealer"
                    ></b-popover>
                </span>
                <div class="col-md-1"></div>
                <!-- <b-form-group label="Region" class="offset-md-4 col-md-3 Aligner-Header"> -->
                <div class="offset-md-2 col-md-2 ">
                    <Multiselect 
                        v-if="filterData"
                        class="mt-1"
                        :options="filterData.region"
                        label="name"
                        track-by="loc_code"
                        :multiple="true"
                        v-model="filter.regionDealer"
                        placeholder="All Region"
                    />
                </div>
                <div class="col-md-2 ">
                    <Multiselect 
                        v-if="filterData"
                        class="mt-1"
                        :options="filterData.branch"
                        label="name"
                        track-by="loc_code"
                        :multiple="true"
                        v-model="filter.branch"
                        placeholder="All Branch"
                    />
                </div>
                <!--                         v-model="filterByDealer.region" -->
                        
                <!-- </b-form-group> -->
                <div class="col-md-2 col-sm-4 col-12 Aligner-Header">
                    <button @click="search(1,'/h1-report/by-dealer')" class=" btn btn-success w-100" style="max-height:46px"><span class="fa fa-file-excel"/> Export</button>
                </div>
            </div>
            <div class="w-100"  v-if="by_dealer"  style="overflow-x:scroll">
                <table class=" tableBodyScroll table text-center">
                <thead>
                    <tr style="background-color:black;color:white">
                        <th>Dealer Code</th>
                        <th>Dealer Name</th>
                        <th :id="header + '-dealer'" v-for="(header, index) in headerPopup.filter(x=>x!='GRMD'&&x!='SLAHM')" :key="header">
                            {{headerTitle[index]}}
                            <b-popover
                                :target="header + '-dealer'"
                                :title="headerTitle[index]"
                                triggers="hover focus"
                                placement="top"
                                :content="infoPopup.tableHeaders[header]"
                            ></b-popover>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data, index) in showAllData || by_dealer.length < 20 ? by_dealer : by_dealer.slice(0,20)" :key="index">
                        <td>{{data.code}}</td>
                        <td>{{data.name}}</td>
                        <td>{{numberWithCommas(data.qr_register)}}</td>
                        <td>{{numberWithCommas(data.qr_replacement)}}</td>
                        <!-- <td>{{numberWithCommas(data.gr_md)}}</td> -->
                        <td>{{numberWithCommas(data.gi_md)}}</td>
                        <td>{{numberWithCommas(data.gr_dealer)}}</td>
                        <td>{{numberWithCommas(data.gi_dealer)}}</td>
                        <td>{{numberWithCommas(data.spg_scan)}}</td>
                        <td>{{numberWithCommas(data.bstk_scan)}}</td>
                        <td>{{numberWithCommas(data.id_cust)}}</td>
                        <td>{{numberWithCommas(data.id_cust_with_qr)}}</td>
                    </tr>
                    <tr>
                        <td colspan="10">
                            <a v-if="!showAllData" style="color:grey" @click="showAllData = true">Show All</a>
                            <a v-else style="color:grey" @click="showAllData = false">Hide</a>
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>
            
            <div class="text-center w-100" v-else>
                <h5>Loading.......</h5>
            </div>
        </b-card>

        <b-card v-if="firstDataLoad">
            <div class="row mb-3 ">
                <h4 class="col-md-2 col-sm-4 col-12 Aligner-Header ">H1 Details By Month</h4>
                <span class="col-md-1 col-sm-1 col-2 mt-2">
                    <i class="fa fa-info-circle fa-lg" id="h1DetailsByMonth"></i>
                    <b-popover
                    target="h1DetailsByMonth"
                    title="H1 Details By Month"
                    triggers="hover focus"
                    placement="top"
                    :content="infoPopup.h1DetailsByMonth"
                    ></b-popover>
                </span>
                <div class="col-md-1"></div>
                <!-- <b-form-group label="Region" class="offset-md-4 col-md-3 Aligner-Header"> -->
                <div class="offset-md-2 col-md-4 ">
                    <Multiselect 
                        v-if="filterData"
                        class="mt-1"
                        :options="filterData.region"
                        label="name"
                        track-by="loc_code"
                        :multiple="true"
                        v-model="filter.region"
                        placeholder="Select Region ( Default : All Region )"
                    />
                </div>
                        
                <!-- </b-form-group> -->
                <div class="col-md-2 col-sm-4 col-12 Aligner-Header">
                    <button @click="search(1,'/h1-report/by-month')" class=" btn btn-success w-100" style="max-height:46px"><span class="fa fa-file-excel"/> Export</button>
                </div>
                
            </div>
            
            <div class="w-100"  v-if="by_month"  style="overflow-x:scroll">
                <table class="table tableBodyScroll text-center">
                <thead>
                    <tr style="background-color:black;color:white">
                        <th>Month</th>
                        <th>Year</th>
                        <th id="qr-registered-month">QR Registered</th>
                        <b-popover
                            target="qr-registered-month"
                            title="QR Registered"
                            triggers="hover focus"
                            placement="top"
                            :content="infoPopup.tableHeaders.QrRegistered"
                        ></b-popover>
                        <th id="qr-replacement-month">QR Replacement</th>
                        <b-popover
                            target="qr-replacement-month"
                            title="QR Replacement"
                            triggers="hover focus"
                            placement="top"
                            :content="infoPopup.tableHeaders.QrReplacement"
                        ></b-popover>
                        <th id="slahm-month">SLAHM</th>
                        <b-popover
                            target="slahm-month"
                            title="SLAHM"
                            triggers="hover focus"
                            placement="top"
                            :content="infoPopup.tableHeaders.SLAHM"
                        ></b-popover>
                        <th id="gr-md-month">GR MD</th>
                        <b-popover
                            target="gr-md-month"
                            title="GR MD"
                            triggers="hover focus"
                            placement="top"
                            :content="infoPopup.tableHeaders.GRMD"
                        ></b-popover>
                        <th id="gi-md-month">GI MD</th>
                        <b-popover
                            target="gi-md-month"
                            title="GI MD"
                            triggers="hover focus"
                            placement="top"
                            :content="infoPopup.tableHeaders.GIMD"
                        ></b-popover>
                        <th id="gr-d-month">SPG</th>
                        <b-popover
                            target="gr-d-month"
                            title="SPG"
                            triggers="hover focus"
                            placement="top"
                            :content="infoPopup.tableHeaders.GRDealer"
                        ></b-popover>
                        <th id="gi-d-month">BSTK</th>
                        <b-popover
                            target="gi-d-month"
                            title="BSTK"
                            triggers="hover focus"
                            placement="top"
                            :content="infoPopup.tableHeaders.GIDealer"
                        ></b-popover>
                        <th id="spg-scan-month">SPG Scan</th>
                        <b-popover
                            target="spg-scan-month"
                            title="SPG Scan"
                            triggers="hover focus"
                            placement="top"
                            :content="infoPopup.tableHeaders.SPGScan"
                        ></b-popover>
                        <th id="bstk-scan-month">BSTK Scan</th>
                        <b-popover
                            target="bstk-scan-month"
                            title="BSTK Scan"
                            triggers="hover focus"
                            placement="top"
                            :content="infoPopup.tableHeaders.BSTKScan"
                        ></b-popover>
                        <th id="id-customer-month">ID Customer</th>
                        <b-popover
                            target="id-customer-month"
                            title="ID Customer"
                            triggers="hover focus"
                            placement="top"
                            :content="infoPopup.tableHeaders.IdCustomer"
                        ></b-popover>
                        <th id="id-customer-qr-month">ID Customer with QR</th>
                        <b-popover
                            target="id-customer-qr-month"
                            title="ID Customer With QR"
                            triggers="hover focus"
                            placement="top"
                            :content="infoPopup.tableHeaders.IdCustomerWithQr"
                        ></b-popover>
                        <th>Options</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data, index) in by_month" :key="index">
                        <td>{{data.bulan}}</td>
                        <td>{{data.tahun}}</td>
                        <td>{{numberWithCommas(data.qr_register)}}</td>
                        <td>{{numberWithCommas(data.qr_replacement)}}</td>
                        <td>{{numberWithCommas(data.slahm)}}</td>
                        <td>{{numberWithCommas(data.gr_md)}}</td>
                        <td>{{numberWithCommas(data.gi_md)}}</td>
                        <td>{{numberWithCommas(data.gr_dealer)}}</td>
                        <td>{{numberWithCommas(data.gi_dealer)}}</td>
                        <td>{{numberWithCommas(data.spg_scan)}}</td>
                        <td>{{numberWithCommas(data.bstk_scan)}}</td>
                        <td>{{numberWithCommas(data.id_cust)}}</td>
                        <td>{{numberWithCommas(data.id_cust_with_qr)}}</td>
                        <td><button class="btn btn-primary w-100" @click="getMonthDetail(data.bulan , data.tahun)">Details</button></td>
                    </tr>
                </tbody>
            </table>
            </div>
            
            <div class="text-center w-100" v-else>
                <h5>Loading.......</h5>
            </div>
        </b-card>

        <detailModal v-on:closeModal="showModal = false" v-if="showModal" :data="summaryData" :period="period"></detailModal>
    </div>
</template>

<script>
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'
import Multiselect  from 'vue-multiselect'
import { options } from '@amcharts/amcharts4/core'

import detailModal from './childComponents/detailModal'

export default {
	components: {
        Datepicker,
        Multiselect,
        detailModal
    },
    data () {
        const startDate = moment().subtract(7, "days").format("YYYY-MM-DD");
        const currentDate = moment().format("YYYY-MM-DD");

        return {
            firstDataLoad : false,
            isFetching : false,

            filterData: null,
            filter: {
                start: startDate, 
                end: currentDate,
                region: [],
                regionDealer: [],
                branch: []
            },
            by_month: null,
            by_region: null,
            by_dealer: null,
            showModal: false,
            showAllData: false,
            summaryData: null,
            infoPopup: this.$store.state.hoverInformation.report.h1Report,
            headerPopup : Object.keys(this.$store.state.hoverInformation.report.h1Report.tableHeaders),
            // headerTitle : ['QR Registered', 'QR Replacement' ,  'GR MD' , 'GI MD' , 'GR Dealer' , 'GI Dealer','ID Customer' , 'ID Customer With QR' ]
            headerTitle : ['QR Registered', 'QR Replacement' , 'GI MD' , 'SPG' , 'BSTK', 'SPG Scan', 'BSTK Scan', 'ID Customer' , 'ID Customer With QR' ]
        }
    },
    mounted () {
        this.loadFilter()
        // this.search(0, null)
    },
    watch: {
        'filter.region' : function () {
            this.search(0 , ['/h1-report/by-month'])
        },
        'filter.regionDealer' : function () {
            this.search(0 , ['/h1-report/by-dealer'])
        },
        'filter.branch' : function () {
            this.search(0 , ['/h1-report/by-dealer'])
        }
    },
    methods: {
        async getMonthDetail (month , year) {
            let date = moment(`01 ${month} ${year}`)

            let filter = {
                region : this.filter.region,
                month : date.month() + 1,
                year : date.year()
            }

            filter = await this.transformFilter(filter)
            filter = await this.initializeFilter(filter)

            let url = "/api/reports/h1-report/daily-detail"
 
            this.$http.get(url + filter)
            .then((resp) => {
                this.summaryData = resp.data.data
                this.showModal = true
                this.period = filter
            })
            .catch((err) => {
                console.log(err)
            })


        },
        loadFilter (type) {
            if (type == 'region'){
                this.filter.branch.length = 0;
                this.filterData.branch = this.$store.getters.getBranch(this.filter.region)
            } else if (type == 'segment'){
                // this.filter.series.length = 0;
                this.filterData.series = this.$store.getters.getSeries(this.filter.segment)

                this.filter.type.length = 0;
                this.filterData.type = this.$store.getters.getType(this.filter.segment, this.filter.series)
            } else if (type == 'series'){
                this.filter.type.length = 0;
                this.filterData.type = this.$store.getters.getType(this.filter.segment, this.filter.series)
            } else {
                this.filterData = this.$store.getters.getFilterOptions
            } 
        },
        async search (flag, url) {
            
            this.isFetching = true;

            let filter = Object.assign({} , this.filter)
            if(url == null) url = ['/h1-report/by-month', '/h1-report/by-region', '/h1-report/by-dealer']
            
            if(flag == 1) {
                this.downloadExcel(filter, url)
                return;
            }

            console.log(await this.loadAPI(filter, url));
            this.firstDataLoad = true;

        },
        async downloadExcel (filter , url) {
            let variable = url.split('/')[2].split('-').join(' ')

            if(variable == 'by region') delete filter.region

            filter = await this.transformFilter(filter)
            filter = await this.initializeFilter(filter)
            filter += '&exportExcel=1'
            
            this.$http.get('/api/reports' + url + filter , {responseType : 'blob'})
            .then((resp)=>{
                const blob = new Blob([resp.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                let url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = "H1 report " +  variable;
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((err)=>{
                console.log(err);
            })
        },
        async loadAPI(filter, url) {
            
            for (var i = 0 ; i < url.length ; i++) {
                let obj = Object.assign({} , filter)

                let variable = url[i].split('/')[2].split('-').join('_')

                this[variable] = null;

                if(variable == 'by_region') {
                    delete obj.region 
                    delete obj.branch
                }
                else if(variable == 'by_dealer') {
                    obj.region = obj.regionDealer
                    delete obj.regionDealer
                }
                else {
                    delete obj.regionDealer
                    delete obj.branch
                }

                obj = await this.transformFilter(obj)
                obj = await this.initializeFilter(obj)


                this.$http.get('/api/reports' + url[i] + obj)
                .then((resp) => {
                    var data = resp.data.data;
                    if(variable == 'by_region'){
                        //Filter out unknown
                        data = data.filter(x=>x.code!='Unknown')

                        var length = data.length
                        if(length >= 1){
                            var obj = {
                                code : "Total",
                                name : "Total"
                            }

                            //Get keys, filter out 'name' and 'code'
                            var keys = Object.keys(data[0])
                            keys = keys.filter(x=>x!='name'&&x!='code')

                            //DECLARE Total to 0
                            for(var i = 0; i < keys.length; i++){
                                obj[keys[i]] = 0;
                            }

                            //Add all to each total
                            for(var i = 0; i < data.length; i++){
                                for (var j = 0; j < keys.length; j++){
                                    obj[keys[j]] += parseInt(data[i][keys[j]]);
                                }
                            }
                            console.log(JSON.stringify(obj));
                            data.push(obj);
                        }
                    }
                    this[variable] = data
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(()=>{
                    this.isFetching = false;
                })
            }
        },
        async initializeFilter (filters) {
            let filterUrl = '?'
            let keys = Object.keys(filters)
            for (var i = 0; i < keys.length; i++) {
                if(typeof filters[keys[i]] == 'object'  && filters[keys[i]].length > 0)
                filterUrl += `${keys[i]}=${filters[keys[i]]}&`
                else if( filters[keys[i]] != null && filters[keys[i]] != ''){
                filterUrl += `${keys[i]}=${filters[keys[i]]}&`
                }
            }
            
            return filterUrl
        },
        async transformFilter (filter) {
            let obj = {}
            let keys = Object.keys(filter)
            for (var i = 0 ; i < keys.length; i++) {
                if (keys[i] == 'region' || keys[i] == 'branch') {
                    obj[keys[i]] = []
                    for (var j = 0; j < filter[keys[i]].length; j++) {
                        keys[i] == 'region' || keys[i] == 'branch' ? obj[keys[i]].push(filter[keys[i]][j].loc_code) : obj[keys[i]].push(filter[keys[i]][j][keys[i].toLowerCase()])
                    }
                }
                else if(keys[i] == 'start' || keys[i] == 'end')  {
                    obj[keys[i]] = moment(filter[keys[i]]).format("YYYY-MM-DD")
                }
                else {
                    obj[keys[i]] = filter[keys[i]]
                }
            }
            return obj
        },
        customFormatter : function(date){
			return moment(date).format('Y-MM-DD');
		},
        numberWithCommas(x) {
        return parseInt(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }
}
</script>

<style scoped>
    td .btn {
        line-height: 1;
    }

    .Aligner-Header {
        padding-left:3% !important;
        color: #707070;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
</style>