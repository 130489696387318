<template>
	<div>
		<!-- Mapping Summary Start -->
		<div class="row mt-5" style="padding-right:1%">
			<div class="col-12 Aligner-Header">
				<h3>Mapping Summary
					<span class="col-md-1 col-sm-1 col-2">
						<i class="fa fa-info-circle fa-sm" id="mappingSummary"></i>
						<b-popover
						target="mappingSummary"
						title="Mapping Summary"
						triggers="hover focus"
						placement="top"
						:content="infoPopup.mappingSummary"
						></b-popover>
					</span>
				</h3>
			</div>
			</div>
			<div class="content-outer">
			<div class="content-inner" style="padding: 2%;">
				<div class="row">
					<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 stats-outer-box">
						<div class="stats-inner-box" style="background-color: #F59C19;">
							<div class="row" style="height: 100%">
								<div class="col-8">
								<p>Total Mapping Overall</p>
								<p class="stats-value" v-if="data.mappingTotal">
									{{numberWithCommas(data.mappingTotal.total)}}
								</p>
								<p class="stats-value" v-else>
									Loading.....
									<!-- <span class="stats-value-support">(4,5)</span> -->
								</p>
								</div>
								<div class="col-4 icon">
								<i class="fas fa-qrcode"></i>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 stats-outer-box">
						<div class="stats-inner-box" style="background-color:#DC143C">
							<div class="row" style="height: 100%">
								<div class="col-8">
								<p>Total Mapping H1</p>
								<p class="stats-value" v-if="data.mappingTotal">
									{{numberWithCommas(data.mappingTotal.total_h1)}}
									<!-- <span class="stats-value-support">(4,5)</span> -->
								</p>
								<p class="stats-value" v-else>
									Loading.....
									<!-- <span class="stats-value-support">(4,5)</span> -->
								</p>
								</div>
								<div class="col-4 icon">
								<i class="fas fa-qrcode"></i>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 stats-outer-box">
						<div class="stats-inner-box" style="background-color: #BA55D3;">
							<div class="row" style="height: 100%">
								<div class="col-8">
								<p>Total Mapping H2</p>
								<p class="stats-value" v-if="data.mappingTotal">
									{{numberWithCommas(data.mappingTotal.total_h2)}}
									<!-- <span class="stats-value-support">(4,5)</span> -->
								</p>
								<p class="stats-value" v-else>
									Loading.....
									<!-- <span class="stats-value-support">(4,5)</span> -->
								</p>
								</div>
								<div class="col-4 icon">
								<i class="fas fa-qrcode"></i>
								</div>
							</div>
						</div>
					</div>
				</div>
				<p style="font-weight: bold; font-size: large;">H1</p>
				<mapping-summary-chart :data="data.mappingH1" :type="'H1'" v-if="data.mappingH1"
				></mapping-summary-chart>
				<div v-else style="text-align:center; margin-top:20px"><h4>Loading...</h4></div>
				<p style="font-weight: bold; font-size: large;">H2</p>
				<mapping-summary-chart :data="data.mappingH2" :type="'H2'" v-if="data.mappingH2"
				></mapping-summary-chart>
				<div v-else style="text-align:center; margin-top:20px"><h4>Loading...</h4></div>
			</div>
		</div>
		<!-- Mapping Summary End -->
	</div>
</template>

<script>
  import Multiselect  from 'vue-multiselect'
  import mappingSummaryChart from './../children/mappingSummaryChart.vue'

export default {
	props: ['options' , 'period', 'mobile', 'data'],
	components: {
		Multiselect ,
		mappingSummaryChart
	},
	data () {
		return {
			infoPopup : this.$store.state.hoverInformation.highlight.mappingSummary
		}
	},
	methods: {
		numberWithCommas(x) {
            return parseInt(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
	}
}
</script>