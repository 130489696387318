<template>
  <div>
    <!--Filter Option-->
    <b-card header="Lead Time" header-tag="h6" class="mb-4">
        <b-form>
            <div class="row mb-3">
              <div class="col-md-3" style="padding-right:0px">
                <button class="btn w-100" type="button" @click="selected.flag = 'AHMtoMD'" :class="{'btn-primary' : selected.flag == 'AHMtoMD' , 'btn-outline-primary' : selected.flag == 'MDtoD'}" style="border-radius:10px 0px 0px 10px">AHM tO MD</button>
              </div>
              <div class="col-md-3" style="padding-left:0px;">
                <button class="btn w-100" type="button" @click="selected.flag = 'MDtoD'" :class="{'btn-primary' : selected.flag == 'MDtoD' , 'btn-outline-primary' : selected.flag == 'AHMtoMD'}" style="border-radius:0px 10px 10px 0px">MD to D</button>
              </div>
            </div>
            <b-form-row>
              <b-form-group label="Activity" class="col-md-4">
                <Multiselect
                  v-model="filter.activity"
                  class="mt-1"
                  :options="selected.flag == 'AHMtoMD' ? ['SLAHM' , 'GRAHMTOMD'] : ['GIMDTOD' , 'SPG']"
                  :searchable="true"
                  :close-on-select="true"
                  :multiple="false"
                  placeholder="Choose Activity"
                ></Multiselect>
              </b-form-group>
              <b-form-group label="Date" class="col-md-4 pt-2">
                <flatPickr
                  v-model="selected.date"
                  :config="rangeConfig"
                  placeholder="Pilih Tanggal"
                ></flatPickr>
              </b-form-group>
              <b-form-group label="Region" class="col-md-4">
                <Multiselect
                  v-model="filter.region"
                  class="mt-1"
                  :options="options.region.map(x => x.loc_code)"
                  :custom-label="opt => options.region.find(x => x.loc_code == opt).name"
                  :searchable="true"
                  :close-on-select="true"
                  :multiple="false"
                  placeholder="Choose Region"
                ></Multiselect>
              </b-form-group>
              <!-- <b-form-group label="Branch" class="col-md-3">
                <Multiselect
                  v-model="filter.branch"
                  class="mt-1"
                  :options="options.branch.map(typ => typ.alias_code)"
                  :custom-label="opt => options.branch.find(x => x.alias_code == opt).name"
                  :searchable="true"
                  :close-on-select="true"
                  :multiple="false"
                  placeholder="Choose Branch"
                ></Multiselect>
              </b-form-group> -->
            </b-form-row>
            <b-form-row class="mt-2">
              <b-form-group label="Engine Number" class="col-md-6">
                  <b-input class="mt-1" type="text" placeholder="Enter Engine Number" v-model="filter.engineNo" />
              </b-form-group>
              <b-form-group label="QR Code" class="col-md-6">
                  <b-input class="mt-1" type="text" placeholder="Enter QR Code" v-model="filter.qrcode" />
              </b-form-group>
            </b-form-row>
            <b-btn @click="filter.flag = selected.flag;vue_universal_table.update++" :disabled="isFetching" class="col-md-3 mt-2" type="button" variant="primary"><span class="fa fa-filter"></span>&nbsp;&nbsp;Search</b-btn>
            <b-btn @click="downloadExcel" :disabled="isFetching" class="col-md-3 mt-2" type="button" variant="success"><span class="fa fa-file-excel"></span>&nbsp;&nbsp;Export To Excel</b-btn>
        </b-form>
    </b-card>

    <b-card class="mb-4">

      <vue-universal-table
        :api_url="'/api/reports/lead-time'"
        :fields="selected.flag == 'AHMtoMD' ? vue_universal_table.fields : vue_universal_table.fieldsDealer"
        :update="vue_universal_table.update"
        :filter="filter"
        :process_data="vue_universal_table.process_data"
        :fixed_header="true"
        :infinite_scroll="true"
        @fetchStart="isFetching = true"
        @fetchEnd="isFetching = false"
      ></vue-universal-table>
    </b-card>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import Multiselect from 'vue-multiselect'

export default {
  metaInfo: {
    title: 'Lead Time Report'
  },
  data () {
    const startOfMonth = moment().startOf('day').add( -1 , 'day').format('YYYY-MM-DD');
    return {
      isFetching : false,

      rangeConfig: {
        altInput: true,
        altInputClass: 'form-control input flatpickr-input w-100',
        animate: !isRTL()
      },
      options : {
        region : [],
        branch : [],
      },
      selected : {
        region : null,
        date : startOfMonth,
        flag : 'AHMtoMD'
      },
      filter : {
        activity : 'GRAHMTOMD',
        start : startOfMonth,
        end : startOfMonth,
        flag : 'AHMtoMD',
        region : [],
        engine_no : '',
        qrcode : ''
      },
      vue_universal_table : {
        selectedField : null,
        fields : [
          {
            title : 'QR Code',
            variable : 'qrcode'
          },
          {
            title : 'Engine No',
            variable : 'engine_no'
          },
          {
            title : 'To Region',
            variable : 'current_region'
          },
          {
            title : 'GI Date',
            variable : 'GI_date'
          },
          {
            title : 'GR Date',
            variable : 'GR_date'
          },
          {
            title : 'Lead Time (Hour)',
            variable : 'lead_time'
          }
        ],
        fieldsDealer : [
          {
            title : 'QR Code',
            variable : 'qrcode'
          },
          {
            title : 'Engine No',
            variable : 'engine_no'
          },
          {
            title : 'From Region',
            variable : 'region'
          },
          {
            title : 'To Dealer',
            variable : 'dealer'
          },
          {
            title : 'GI Date',
            variable : 'GI_date'
          },
          {
            title : 'GR Date',
            variable : 'GR_date'
          },
          {
            title : 'Lead Time (Hour)',
            variable : 'lead_time'
          }
        ],
        process_data : `
          for (var i in data) {
            data[i].lead_time = data[i].lead_time + ' Hour(s)'
          }
        `,
        update : 0,
      }
    }
  },
  components: {
    flatPickr,
    Multiselect
  },
  mounted () {
    this.options.type = this.$store.getters.getType()
    this.options.region = this.$store.getters.getRegion
    this.options.branch = this.$store.getters.getBranch()
    this.vue_universal_table.selectedField = this.vue_universal_table.fields
  },
  watch : {
    'selected.flag' : function () {
      if(this.selected.flag == 'AHMtoMD') {
        this.filter.activity = 'GRAHMTOMD'
        this.vue_universal_table.selectedField = this.vue_universal_table.fields
      }
      else {
        this.filter.activity = 'GIMDTOD'
        this.vue_universal_table.selectedField = this.vue_universal_table.fieldsDealer
      }
    },
    'selected.date' : function () {
      console.log(this.selected.date)
      if(this.selected.date.includes('to')) {
        let dateString = this.selected.date.split(' to ')
        this.filter.start = dateString[0]
        this.filter.end = dateString[1]
      }
      else {
        this.filter.start = this.selected.date
        this.filter.end = this.selected.date
      }
    }
  },
  methods: {
    async downloadExcel (filter) {
      let url = '/api/reports/lead-time/download-excel?' + await this.initialiseFilter()

      this.isFetching = true;

      this.$http.get(url , {responseType : 'blob'})
      .then((resp)=>{
        const blob = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = "Lead Time Data Download";
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((err)=>{
        console.log(err);
      })
      .finally(()=>{
        this.isFetching = false;
      })
    },
    initialiseFilter(){
      if(!(typeof this.filter === 'object' && this.filter !== null)){
        this.filterUrl = '';
        return;
      }
      var keys = Object.keys(this.filter);
      let stringUrl = '';
      var queryParams = this.filter;

      for(var i = 0 ; i < keys.length; i++){
        if(!queryParams[keys[i]])
          continue;

        if(queryParams[keys[i]] == '' || queryParams[keys[i]].length == 0)
          continue 

        if((keys[i] == 'user_type') && queryParams[keys[i]] == 'All')
          continue;
        
        // if(keys[i] == 'start_hour' || keys[i] == 'end_hour')
        //   queryParams[keys[i]] += ":00";

        stringUrl += `&${keys[i]}=${queryParams[keys[i]]}`;
        
      }
      return stringUrl;
    },
  }
}

function isRTL () {
  return document.documentElement.getAttribute('dir') === 'rtl' || document.body.getAttribute('dir') === 'rtl'
}
</script>

<style>
  .dateCss div input{
    border: 1px solid #e8e8e8;
    border-radius: 5px !important;
    height: 42px;
    margin-top: 3px;
    padding-left: 1% !important;
  }
  .dateCss div input::placeholder{
    padding-left: 1%;
  }
</style>
<style scoped>
  .btn-primary {
    background-color: #CB2127;
    border-color: transparent;
    /* box-shadow: 1px 1px 3px 3px lightgrey; */
  }

  .btn-outline-primary {
    color: #CB2127;
    box-shadow: 0 0 0 1px #CB2127 inset;
    border-color: transparent;
  }

  .btn-outline-primary:hover {
    color: white;
    background-color: #CB2127; 
  }
</style>
