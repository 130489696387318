<template>
	<div>
		<!-- Sales Analysis Start -->
		<div class="row mt-5" style="padding-right:1%">
			<div class="col-12 col-md-8 Aligner-Header">
				<h3>Sales Analysis
					<span class="col-md-1 col-sm-1 col-2">
						<i class="fa fa-info-circle fa-sm" id="salesAnalysis"></i>
						<b-popover
						target="salesAnalysis"
						title="Sales Analysis"
						triggers="hover focus"
						placement="top"
						:content="infoPopup.salesAnalysis"
						></b-popover>
					</span>
				</h3>
			</div>
			<div class="col-12 col-md-4 row">
				<div class="col-12 col-md highlightFilter">
				<Multiselect 
					class="mt-1 "
					:options="options.segment"
					:searchable="true"
					:close-on-select="true"
					label="segment"
					:show-labels="false"
					v-model="filter.salesAnalysisTargetVsActualOptions.segment"
					placeholder="All Segment"
				/>
				</div>
				<div class="col-12 col-md highlightFilter">
				<Multiselect 
					class="mt-1 "
					:options="options.region"
					:searchable="true"
					:close-on-select="true"
					label="name"
					:show-labels="false"
					v-model="filter.salesAnalysisTargetVsActualOptions.region"
					placeholder="All Regions"
				/>
				</div>
			</div>
			</div>
			<div class="content-outer">
			<div class="content-inner" style="padding: 2%;">
				<p>Sales Target vs Actual Analysis</p>
				<sales-analysis-chart
					:data="data.salesAnalysisTargetVsActual"
					v-if="data.salesAnalysisTargetVsActual"
					:filter="filter.salesAnalysisTargetVsActualOptions"
				></sales-analysis-chart>
				<div v-else style="text-align:center; margin-top:20px"><h4>Loading...</h4></div>
			</div>
			</div>
			<!-- Sales Analysis End -->

			<!-- Sales Main Dealer / Karesidenan Start -->
			<div class="row mt-5 mb-3" style="padding-right:1%">
			<div class="col-12 col-md-6 Aligner-Header">
				<h3>Sales <span style="font-weight: 100;">Main Dealer</span>
					<span class="col-md-1 col-sm-1 col-2">
						<i class="fa fa-info-circle fa-sm" id="salesMainDealer"></i>
						<b-popover
						target="salesMainDealer"
						title="Sales Main Dealer"
						triggers="hover focus"
						placement="top"
						:content="infoPopup.salesMainDealer"
						></b-popover>
					</span>
				</h3>
			</div>
			<div class="col-12 col-md-2 Aligner-Header" v-if="!mobile">
				<h3>Sales <span style="font-weight: 100;">Dealer</span>
					<span class="col-md-1 col-sm-1 col-2">
						<i class="fa fa-info-circle fa-sm" id="salesDealer"></i>
						<b-popover
						target="salesDealer"
						title="Sales Dealer"
						triggers="hover focus"
						placement="top"
						:content="infoPopup.salesDealer"
						></b-popover>
					</span>
				</h3>
			</div>
			<div class="col-12 col-md-4 row" v-if="!mobile">
				<div class="col-12 col-md highlightFilter">
				<Multiselect 
					class="mt-1 "
					:options="options.type"
					:searchable="true"
					:close-on-select="true"
					:show-labels="false"
					label="type"
					v-model="filter.salesTableOptions.type"
					placeholder="All Type"
				/>
				</div>
				<div class="col-12 col-md highlightFilter">
				<Multiselect 
					class="mt-1 "
					:options="options.color"
					:searchable="true"
					:close-on-select="true"
					label="name"
					:show-labels="false"
					v-model="filter.salesTableOptions.color"
					placeholder="All Color"
				/>
				</div>
			</div>
			</div>
			<div class="row">
			<div class="content-outer col-12 col-md-6">
				<div class="content-inner" style="padding: 0 3%; height: 100%;height: 500px; overflow-y:scroll">
				<sales-main-dealer-table
					:data="data.salesTableMD"
				></sales-main-dealer-table>
				</div>
			</div>
			<div class="col-12 col-md-2 Aligner-Header mt-4" v-if="mobile">
				<h3>Sales <span style="font-weight: 100;">Dealer</span></h3>
			</div>
			<div class="col-12 col-md-4 row" v-if="mobile">
				<div class="col-12 col-md highlightFilter">
				<Multiselect 
					class="mt-1 "
					:options="options.type"
					:searchable="true"
					:close-on-select="true"
					label="type"
					:show-labels="false"
					v-model="filter.salesKaresidenan.type"
					placeholder="All Type"
				/>
				</div>
				<div class="col-12 col-md highlightFilter">
				<Multiselect 
					class="mt-1 "
					:options="options.color"
					:searchable="true"
					label="name"
					:close-on-select="true"
					:show-labels="false"
					v-model="filter.salesKaresidenan.color"
					placeholder="All Color"
				/>
				</div>
			</div>
			<div class="content-outer col-12 col-md-6">
				<div class="content-inner" style="padding: 0 3%; height: 500px; overflow-y:scroll">
				<sales-dealer-table
					:data="data.salesTableKaresidenan"
				></sales-dealer-table>
				</div>
			</div>
		</div>
		<!-- Sales Main Dealer / Karesidenan -->
	</div>
</template>

<script>
	import Multiselect  from 'vue-multiselect'
	import salesAnalysisChart from './../children/sales/analysisChart.vue'
	import salesMainDealerTable from './../children/sales/mainDealerTable.vue'
	import salesDealerTable from './../children/sales/dealerTable.vue'
	import listUrl from './../listUrl.js'

export default {
	props: ['options' , 'period', 'mobile', 'filter' ,'data'],
	components: {
		Multiselect ,
		salesAnalysisChart,
      	salesMainDealerTable,
      	salesDealerTable,
	},
	watch : {
		"filter.salesAnalysisTargetVsActualOptions": {
			deep: true,
			handler: function () {
				let url = listUrl.stringUrl.salesAnalysis.targetVsActual
				let params = listUrl.param.salesAnalysis.targetVsActual

				this.$emit('loadAPI' , url , params )
			}
		},
		"filter.salesTableOptions": {
			deep: true,
			handler: function () {
				let url = listUrl.stringUrl.salesAnalysis.mainDealerSales.concat(listUrl.stringUrl.salesAnalysis.dealerSales)
				let params = listUrl.param.salesAnalysis.mainDealerSales.concat(listUrl.param.salesAnalysis.dealerSales)

				this.$emit('loadAPI' , url , params )
			}
		},
		"filter.salesKaresidenan": {
			deep: true,
			handler: function () {
				let url = listUrl.stringUrl.salesAnalysis.dealerSales
				let params = listUrl.param.salesAnalysis.dealerSales

				this.$emit('loadAPI' , url , params )
			}
		},
	},
	data () {
		return {
			infoPopup : this.$store.state.hoverInformation.highlight.salesAnalysis
		}
	}
}
</script>