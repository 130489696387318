<template>
  <div>
    <table class="table tableBodyScroll">
      <thead>
        <tr>
          <th >Dealer (D)</th>
          <th style="border-bottom: 0">In Transit MD - D</th>
          <th style="border-bottom: 0">On-Hand Dealer</th>
          <!-- <th style="border-bottom: 0">Realy For Sales</th> -->
          <th style="border-bottom: 0">In-Transit Dealer<br>To Customer</th>
        </tr>
      </thead>
        <tbody v-if="stockData">
        <tr v-for="stock in ((showAll) ? stockData : stockData.slice(0, 6))">
          <td>{{stock.current_loc_code}} - {{ stock.name }}</td>
          <td>{{ numberWithCommas(stock.intransit_md_d) }}</td>
          <td>{{ numberWithCommas(stock.warehouse_stock_onhand) }}</td>
          <!-- <td>{{ numberWithCommas(stock.sold_stock) }}</td> -->
          <td>{{ numberWithCommas(stock.intransit_dealer_customer) }}</td>
        </tr>
        <tr v-if="showAllButton">
          <td v-if="!showAll" colspan="8" class="moreButton" @click="showAll = true">Lihat Semua</td>
          <td v-else colspan="8" class="moreButton" @click="showAll = false">Sembunyikan</td>
        </tr>
      </tbody>
     
    </table>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        showAllButton : true,
        showAll : false,
        stockData : [],
      }
    },
    props:['data'],
    mounted () {
      let total = 0
      let totalSales = 0
      for(var i = 0 ; i < this.data.length ; i++) {
        total += parseInt(this.data[i].warehouse_stock_onhand)
        totalSales += parseInt(this.data[i].sold_stock)
      }

      console.log(total , totalSales)
    },
    watch : {
      'data' : {
        handler(){
          var data = this.data
          if(!data) return;
          if (data.length > 0 && data[0].current_loc_code == 'Unknown') {
            data.splice(0, 1);
          }
          this.stockData = data;
        },
        deep : true,
        immediate : true
      }
    },
    methods : {
      numberWithCommas(x) {
        return parseInt(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    }
  }
</script>

<style scoped>
  table tr th, table tr td {
    text-align: center;
    vertical-align: middle;
  }

  table tr th {
    border-bottom: 1px solid #F75047;
  }

  

</style>