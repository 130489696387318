<template>
	<div>
		<div style="display: flex; flex-direction: row;">
			<div class="innerBox" style="
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 7.5px 10px;
                font-family: proxima-nova, sans-serif;
            ">
				<button class="btn" style="
                    font-size: 16px;
                    color: #6c6c6c;
                    font-weight: bold;
                    padding: 4px;
                    font-family: proxima-nova, sans-serif;
                " @click="$router.go(-1)">
					<i class="fas fa-angle-left mr-2"></i>Back
				</button>
				<p class="mb-0" style="
                    font-size: 16px;
                    color: #b9b9b9;
                    font-weight: 600;
                    font-family: proxima-nova, sans-serif;
                ">
					| Tracking Last Visit <i class="fas fa-angle-right"></i>
				</p>
				<p class="mb-0 ml-1" style="
                    font-size: 16px;
                    color: #4b4b4b;
                    font-weight: bold;
                    font-family: proxima-nova, sans-serif;
                ">
					{{ engineNo }}
				</p>
			</div>
			<div class="col-12 col-md-4 ml-auto">
				<button class="hso-download-button btn rounded" @click="downloadExcel">
					Download Excel<i class="fas fa-file-download ml-2"></i>
				</button>
			</div>
		</div>

		<b-card class="mb-4">
			<div class="d-flex justify-content-between align-items-center">
				<div>
					<h5 class="h2-card-title" style="color: #9d9d9d; font-weight: bold;">
						Engine Number
					</h5>
					<h4 class="h2-card-subtitle" style="color: #4B4B4B; font-weight: bold;">
						{{ engineNo }}
					</h4>
				</div>
				<div class="d-flex" style="color: #4B4B4B;">{{ formattedStartDate }} -
					{{ formattedEndDate }}</div>
			</div>

			<div class="mb-3"></div>
			<div v-if="loading">Loading...</div>
			<div v-else-if="error">{{ error }}</div>
			<div v-else>
				<table class="table">
					<thead>
						<tr style="color: #9d9d9d; background-color: white">
							<th class="hso-table-header">Dealer</th>
							<th class="hso-table-header">Total Visit</th>
							<th class="hso-table-header">Last 3 Month</th>
							<th class="hso-table-header">Last 6 Month</th>
							<th class="hso-table-header">Last 9 Month</th>
							<th class="hso-table-header">Last 12 Month</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in detailData" :key="item.loc_code">
							<td class="hso-table-body">{{ item.loc_code }} - {{ item.name }}</td>
							<td class="hso-table-body">{{ item.total_visit }}</td>
							<td class="hso-table-body">{{ item.last_3_month }}</td>
							<td class="hso-table-body">{{ item.last_6_month }}</td>
							<td class="hso-table-body">{{ item.last_9_month }}</td>
							<td class="hso-table-body">{{ item.last_12_month }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</b-card>
	</div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import * as XLSX from "xlsx";

export default {
	name: "H2DetailVisit",
	data() {
		return {
			engineNo: this.$route.params.engineNo,
			startDate: this.$route.query.start,
			endDate: this.$route.query.end,
			detailData: [],
			loading: true,
			error: null,
		};
	},
	computed: {
		formattedStartDate() {
			return moment(this.startDate).format("DD/MM/YYYY");
		},
		formattedEndDate() {
			return moment(this.endDate).format("DD/MM/YYYY");
		},
	},
	mounted() {
		this.fetchDetailData();
	},
	methods: {
		fetchDetailData() {
			this.loading = true;
			this.error = null;

			axios
				.get(`/api/distribution/tracking/last-visit-detail`, {
					params: {
						start: this.startDate,
						end: this.endDate,
						engine_no: this.engineNo,
					},
				})
				.then((response) => {
					this.detailData = response.data.data;
					this.loading = false;
				})
				.catch((error) => {
					console.error("Error fetching detail data:", error);
					this.error =
						"An error occurred while fetching the data. Please try again.";
					this.loading = false;
				});
		},
		downloadExcel() {
			// Prepare data for Excel
			const data = this.detailData.map(item => ({
				Dealer: `${item.loc_code} - ${item.name}`,
				"Total Visit": item.total_visit,
				"Last 3 Month": item.last_3_month,
				"Last 6 Month": item.last_6_month,
				"Last 9 Month": item.last_9_month,
				"Last 12 Month": item.last_12_month
			}));

			// Convert to worksheet
			const ws = XLSX.utils.json_to_sheet(data);

			// Create a new workbook and add the worksheet
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "Last Visit Data");

			// Generate Excel file and trigger download
			XLSX.writeFile(wb, `Last_Visit_Data_${this.engineNo}.xlsx`);
		}
	},
};
</script>

<style scoped>
.hso-table-header {
	color: #9D9D9D;
	background-color: #FFFFFF;
	text-align: left;
}

.hso-table-body {
	border-bottom: none;
	border-top: none;
	text-align: left;
}

.hso-download-button {
	margin-top: 12px;
	height: 40px;
	margin-top: 0px;
	color: #2F2F2F;
	background-color: #FFFFFF;
	box-shadow: none;
	width: 100%;
	white-space: nowrap;
}
</style>
