<template>
	<div>
		<!-- Average Number of Service Start -->
		<div class="row mt-5 mb-3" style="padding-right:1%">
			<div class="col-12 col-md-10 Aligner-Header">
				<h3>Average Number of Service
					<span class="col-md-1 col-sm-1 col-2">
						<i class="fa fa-info-circle fa-sm" id="serviceAnalysis"></i>
						<b-popover
						target="serviceAnalysis"
						title="Average Number of Service"
						triggers="hover focus"
						placement="top"
						:content="infoPopup.serviceAnalysis"
						></b-popover>
					</span>
				</h3>
			</div>
			<div class="col-12 col-md-2 row">
				<!-- <div class="col-12 col-md highlightFilter">
				<Multiselect 
					class="mt-1 "
					:options="options.service"
					:searchable="true"
					:close-on-select="true"
					:show-labels="false"
					v-model="filter.averageNumberOfService.service"
					placeholder="All Service"
				/>
				</div> -->
				<div class="col-12 col-md highlightFilter">
				<Multiselect 
					class="mt-1 "
					:options="options.region"
					:searchable="true"
					:close-on-select="true"
					label="name"
					:show-labels="false"
					v-model="filter.serviceAnalysisOptions.region"
					placeholder="All Regions"
				/>
				</div>
			</div>
			</div>
			<div class="content-outer">
				<div class="content-inner" style="padding: 2%;">
					<p>Service Target vs Actual</p>
					<average-number-of-service-chart :data="data.serviceAnalysis" v-if="data.serviceAnalysis" :filter="filter.serviceAnalysisOptions"
					></average-number-of-service-chart>
					<div v-else style="text-align:center; margin-top:20px"><h4>Loading...</h4></div>
				</div>
				<div class=" Aligner-Header mt-3">
					<h3>Top 10 Dealers - By Service
						<span class="col-md-1 col-sm-1 col-2">
							<i class="fa fa-info-circle fa-sm" id="top10DealersByService"></i>
							<b-popover
							target="top10DealersByService"
							title="Top 10 Dealers - By Service"
							triggers="hover focus"
							placement="top"
							:content="infoPopup.top10DealersByService"
							></b-popover>
						</span>
					</h3>
				</div>
				<div class="content-inner" style="padding: 2%;">
					
					
					<table class="table">
						<thead>
							<tr>
								<th>Dealer</th>
								<th>Region</th>
								<th>Number of Service</th>
							</tr>
						</thead>
						<tbody v-if="data.serviceAnalysisDealer" > 
							<tr v-for="dealer in data.serviceAnalysisDealer" :key="dealer.current_loc_code">
								<td>{{dealer.current_loc_code}} - {{dealer.name}}</td>
								<td>{{dealer.region}}</td>
								<td>{{numberWithCommas(dealer.count)}}</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td colspan="3">Loading.....</td>
							</tr>
						</tbody>
					</table>
				</div>
		</div>
		<!-- Average Number of Service End -->
	</div>
</template>

<script>
  import Multiselect  from 'vue-multiselect'
  import averageNumberOfServiceChart from './../children/averageNumberOfServiceChart.vue'
  import listUrl from './../listUrl.js'

export default {
	props: ['options' , 'period', 'mobile', 'filter' , 'data'],
	components: {
		Multiselect ,
		averageNumberOfServiceChart
	},
	watch : {
		"filter.serviceAnalysisOptions": {
			deep: true,
			handler: function () {
				let url = listUrl.stringUrl.serviceAnalysis.targetVsActual.concat(listUrl.stringUrl.serviceAnalysis.dealerTable)
				let params = listUrl.param.serviceAnalysis.targetVsActual.concat(listUrl.param.serviceAnalysis.dealerTable)

				this.$emit('loadAPI' , url , params )
			}
		}
	},
	data () {
		return {
			infoPopup : this.$store.state.hoverInformation.highlight.serviceAnalysis
		}
	},
	methods: {
		numberWithCommas(x) {
			return parseInt(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		},
	}
}
</script>