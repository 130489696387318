<template>
    <div>
        <div class="row">
            <div class="col text-center" style="height:70vh; display: flex; flex-direction: column; justify-content: center;">
                <span class="welcome">Welcome</span>
                <span style="font-size:20px">Click on a module to begin</span>
                <i class="fas fa-arrow-circle-left mt-3" style="font-size:60px; color:#BDBDBD"></i>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {

            }
        },
        mounted () {

        },
        methods : {

        }
    }
</script>

<style scoped>
    .welcome{
        color: #616161;
        font-size: 90px;
        text-shadow: 4px 4px 6px #BDBDBD;
    }
</style>