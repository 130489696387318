<template>
    <div ref="agingStockBarChart" style="min-height: 400px;"></div>
</template>

<script>
    /* Imports */
    import * as am4core from "@amcharts/amcharts4/core";
    import * as am4charts from "@amcharts/amcharts4/charts";
    import am4themes_animated from "@amcharts/amcharts4/themes/animated";

    export default {
        data () {
            return {
                chart : null,
            }
        },
        props : ['data'],
        mounted () {
            this.createChart();
            this.getData();
        },
        watch : {
            data : {
                deep : true,
                handler : 'getData'
            }
        },
        methods : {
            getData () {
                var length = this.chart.data.length;
                
                this.chart.addData(this.data && this.data.length ? this.data : [{label: 'No Data', count: '1'}], length);
            },
            createChart () {
                /* Chart code */
                // Themes begin
                am4core.useTheme(am4themes_animated);
                // Themes end

                // Create chart instance
                this.chart = am4core.create(this.$refs.agingStockBarChart, am4charts.XYChart);
                // this.chart.scrollbarX = new am4core.Scrollbar();

                // Create axes
                let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
                categoryAxis.dataFields.category = "label";
                categoryAxis.renderer.grid.template.location = 0;
                categoryAxis.renderer.minGridDistance = 30;
                // categoryAxis.renderer.labels.template.horizontalCenter = "middle";
                // categoryAxis.renderer.labels.template.verticalCenter = "middle";
                // categoryAxis.renderer.labels.template.rotation = 270;
                categoryAxis.tooltip.disabled = true;
                // categoryAxis.renderer.minHeight = 110;

                let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
                valueAxis.renderer.minWidth = 50;
                valueAxis.min = 0;

                // Create series
                let series = this.chart.series.push(new am4charts.ColumnSeries());
                series.sequencedInterpolation = true;
                series.dataFields.valueY = "count";
                series.dataFields.categoryX = "label";
                series.tooltipText = "[{categoryX}: bold]{valueY}[/]";
                series.columns.template.strokeWidth = 0;

                series.tooltip.pointerOrientation = "vertical";

                series.columns.template.column.cornerRadiusTopLeft = 10;
                series.columns.template.column.cornerRadiusTopRight = 10;
                series.columns.template.column.fillOpacity = 0.8;

                // on hover, make corner radiuses bigger
                let hoverState = series.columns.template.column.states.create("hover");
                hoverState.properties.cornerRadiusTopLeft = 0;
                hoverState.properties.cornerRadiusTopRight = 0;
                hoverState.properties.fillOpacity = 1;

                series.columns.template.adapter.add("fill", (fill, target) => {
                    return this.chart.colors.getIndex(target.dataItem.index);
                });

                // Cursor
                this.chart.cursor = new am4charts.XYCursor();

            }
        }
    }
</script>

<style scoped>

</style>