<script>/* eslint-disable */</script>
<template>
    <div>
        <!--Filter Option-->
        <b-card title="H2 Report" :sub-title="infoPopup.title" header-tag="h6" class="mb-4">
            <hr>
            <b-form>
                <b-form-row>
                    <b-form-group label="Start Date" class="col-md-6 dateCss" style="height:42px" >
                        <datepicker name="start" id="start" :format="customFormatter" bootstrap-styling v-model="filter.start"></datepicker>
                    </b-form-group>
                    <b-form-group label="End Date" class="col-md-6 dateCss" style="height:42px" >
                        <datepicker name="end" id="end" :format="customFormatter" bootstrap-styling v-model="filter.end"></datepicker>
                    </b-form-group>
                </b-form-row>
                <b-btn class="col-md-4 mt-4" type="button" variant="primary" @click="search(0)" :disabled="isFetching"><span class="fa fa-filter"></span>&nbsp;&nbsp;Search</b-btn>
            </b-form>
        </b-card>

        <b-card class="mb-4" v-if="!firstDataLoad">
            <div class="row pt-5 pb-5">
                <div class="col">
                    <h2 class="text-center" style="color:grey">Click Search to Load Data!</h2>
                </div>
            </div>
        </b-card>

        <b-card class="mb-4" v-if="firstDataLoad && userRole != 'DEALER'">
            <div class="row mb-3">
                <h4 class="col-md-2 col-sm-4 col-10 Aligner-Header ">H2 Details By Region</h4>
                <!-- <b-form-group label="Region" class="offset-md-4 col-md-3 Aligner-Header"> -->

                <span class="col-md-1 col-sm-1 col-2">
                    <i class="fa fa-info-circle fa-lg" id="h2DetailsByRegion"></i>
                    <b-popover
                    target="h2DetailsByRegion"
                    title="H2 Details By Region"
                    triggers="hover focus"
                    placement="top"
                    :content="infoPopup.h2DetailsByRegion"
                    ></b-popover>
                </span>
                <div class="col-md-1"></div>
                <!-- </b-form-group> -->
                <div class="offset-md-6 col-md-2 col-sm-4 col-12 Aligner-Header">
                    <button @click="search(1,'/h2-report/by-region')" class=" btn btn-success w-100" style="max-height:46px"><span class="fa fa-file-excel"/> Export</button>
                </div>
            </div>
            <div class="w-100"  v-if="by_region"  style="overflow-x:scroll">
                <table class="table tableBodyScroll text-center">
                <thead>
                    <tr>
                        <th>Region Code</th>
                        <th>Region Name</th>
                        <th :id="header + '-region'" v-for="(header, index) in headerPopup" :key="header">
                            {{headerTitle[index]}}
                            <b-popover
                                :target="header + '-region'"
                                :title="headerTitle[index]"
                                triggers="hover focus"
                                placement="top"
                                :content="infoPopup.tableHeaders[header]"
                            ></b-popover>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data, index) in by_region" :key="index">
                        <td>{{data.code}}</td>
                        <td>{{data.name}}</td>
                        <td>{{numberWithCommas(data.qr_register)}}</td>
                        <!-- <td>{{numberWithCommas(parseInt(data.qr_replacement) + parseInt(data.new_id))}}</td> -->
                        <td>{{numberWithCommas(data.qr_replacement)}}</td>
                        <td>{{numberWithCommas(data.new_id)}}</td>
                        <td>{{numberWithCommas(data.existing_id)}}</td>
                        <td>{{numberWithCommas(data.billing)}}</td>
                        <td>{{numberWithCommas(data.totalQRCode)}}</td>
                        <!-- <td>{{numberWithCommas(data.h1_h2)}}</td>
                        <td>{{numberWithCommas(data.h2_h2)}}</td>
                        <td>{{numberWithCommas(data.h1_service)}}</td>
                        <td>{{numberWithCommas(data.h2_service)}}</td>
                        <td>{{numberWithCommas(data.service)}}</td> -->
                        <td>{{numberWithCommas(data.h12_h2)}}</td>
                        <td>{{numberWithCommas(data.h2_h2_own)}}</td>
                    </tr>
                </tbody>
            </table>
            </div>
            
            <div class="text-center w-100" v-else>
                <h5>Loading.......</h5>
            </div>
        </b-card>

        <b-card class="mb-4" v-if="firstDataLoad">
            <div class="row mb-3">
                <h4 class="col-md-2 col-sm-4 col-12 Aligner-Header ">H2 Details By Dealer</h4>
                <span class="col-md-1 col-sm-1 col-2 mt-2">
                    <i class="fa fa-info-circle fa-lg" id="h2DetailsByDealer"></i>
                    <b-popover
                    target="h2DetailsByDealer"
                    title="H2 Details By Dealer"
                    triggers="hover focus"
                    placement="top"
                    :content="infoPopup.h2DetailsByDealer"
                    ></b-popover>
                </span>
                <div class="col-md-1"></div>
                <!-- <b-form-group label="Region" class="offset-md-4 col-md-3 Aligner-Header"> -->
                <div class="offset-md-2 col-md-4 ">
                    <Multiselect 
                        v-if="filterData"
                        class="mt-1"
                        :options="filterData.region"
                        label="name"
                        track-by="loc_code"
                        :multiple="true"
                        v-model="filterByDealer.region"
                        placeholder="Select Region ( Default : All Region )"
                    />
                </div>
                        
                <!-- </b-form-group> -->
                <div class="col-md-2 col-sm-4 col-12 Aligner-Header">
                    <button @click="search(1,'/h2-report/by-dealer')" class=" btn btn-success w-100" style="max-height:46px"><span class="fa fa-file-excel"/> Export</button>
                </div>
            </div>
            <div class="w-100"  v-if="by_dealer"  style="overflow-x:scroll">
                <table class="table tableBodyScroll text-center">
                <thead>
                    <tr>
                        <th>Dealer Code</th>
                        <th>Dealer Name</th>
                        <th :id="header + '-dealer'" v-for="(header, index) in headerPopup" :key="header">
                            {{headerTitle[index]}}
                            <b-popover
                                :target="header + '-dealer'"
                                :title="headerTitle[index]"
                                triggers="hover focus"
                                placement="top"
                                :content="infoPopup.tableHeaders[header]"
                            ></b-popover>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data, index) in showAllData ? by_dealer : by_dealer.slice(0,20)" :key="index">
                        <td>{{data.code}}</td>
                        <td>{{data.name}}</td>
                        <td>{{numberWithCommas(data.qr_register)}}</td>
                        <!-- <td>{{numberWithCommas(parseInt(data.qr_replacement) + parseInt(data.new_id))}}</td> -->
                        <td>{{numberWithCommas(data.qr_replacement)}}</td>
                        <td>{{numberWithCommas(data.new_id)}}</td>
                        <td>{{numberWithCommas(data.existing_id)}}</td>
                        <td>{{numberWithCommas(data.billing)}}</td>
                        <td>{{numberWithCommas(data.totalQRCode)}}</td>
                        <!-- <td>{{numberWithCommas(data.h1_h2)}}</td>
                        <td>{{numberWithCommas(data.h2_h2)}}</td>
                        <td>{{numberWithCommas(data.h1_service)}}</td>
                        <td>{{numberWithCommas(data.h2_service)}}</td>
                        <td>{{numberWithCommas(data.service)}}</td> -->
                        <td>{{numberWithCommas(data.h12_h2)}}</td>
                        <td>{{numberWithCommas(data.h2_h2_own)}}</td>
                    </tr>
                    <tr>
                        <td colspan="10">
                            <a v-if="!showAllData" style="color:grey; cursor:pointer" @click="showAllData = true">Show All</a>
                            <a v-else style="color:grey; cursor:pointer" @click="showAllData = false">Hide</a>
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>
            
            <div class="text-center w-100" v-else>
                <h5>Loading.......</h5>
            </div>
        </b-card>

        <b-card v-if="firstDataLoad">
            <div class="row mb-3">
                <h4 class="col-md-2 col-sm-4 col-12 Aligner-Header ">H2 Details By Month</h4>
                <span class="col-md-1 col-sm-1 col-2 mt-2">
                    <i class="fa fa-info-circle fa-lg" id="h2DetailsByMonth"></i>
                    <b-popover
                    target="h2DetailsByMonth"
                    title="H2 Details By Month"
                    triggers="hover focus"
                    placement="top"
                    :content="infoPopup.h2DetailsByMonth"
                    ></b-popover>
                </span>

                <div class="col-md-1"></div>
                <!-- <b-form-group label="Region" class="offset-md-4 col-md-3 Aligner-Header"> -->
                <div class=" col-md-3 ">
                    <Multiselect 
                        v-if="filterData"
                        class="mt-1"
                        :options="filterData.region"
                        label="name"
                        track-by="loc_code"
                        :multiple="true"
                        v-model="filterByMonth.region"
                        placeholder="Select Region"
                    />
                </div>

                <div class="col-md-3 ">
                    <Multiselect 
                        v-if="filterData"
                        class="mt-1"
                        :options="filterData.branch"
                        label="name"
                        track-by="loc_code"
                        :multiple="true"
                        v-model="filterByMonth.branch"
                        placeholder="Select Dealer"
                    />
                </div>
                        
                <!-- </b-form-group> -->
                <div class="col-md-2 col-sm-4 col-12 Aligner-Header">
                    <button @click="search(1,'/h2-report/by-month')" class=" btn btn-success w-100" style="max-height:46px"><span class="fa fa-file-excel"/> Export</button>
                </div>
            </div>
            
            <div class="w-100"  v-if="by_month"  style="overflow-x:scroll">
                <table class="table tableBodyScroll text-center">
                <thead>
                    <tr>
                        <th>Month</th>
                        <th>Year</th>
                        <th :id="header + '-month'" v-for="(header, index) in headerPopup" :key="header">
                            {{headerTitle[index]}}
                            <b-popover
                                :target="header + '-month'"
                                :title="headerTitle[index]"
                                triggers="hover focus"
                                placement="top"
                                :content="infoPopup.tableHeaders[header]"
                            ></b-popover>
                        </th>
                        <th>Options</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data, index) in by_month" :key="index">
                        <td>{{data.bulan}}</td>
                        <td>{{data.tahun}}</td>
                        <td>{{numberWithCommas(data.qr_register)}}</td>
                        <!-- <td>{{numberWithCommas(parseInt(data.qr_replacement) + parseInt(data.new_id))}}</td> -->
                        <td>{{numberWithCommas(data.qr_replacement)}}</td>
                        <td>{{numberWithCommas(data.new_id)}}</td>
                        <td>{{numberWithCommas(data.existing_id)}}</td>
                        <td>{{numberWithCommas(data.billing)}}</td>
                        <td>{{numberWithCommas(data.totalQRCode)}}</td>
                        <!-- <td>{{numberWithCommas(data.h1_h2)}}</td>
                        <td>{{numberWithCommas(data.h2_h2)}}</td>
                        <td>{{numberWithCommas(data.h1_service)}}</td>
                        <td>{{numberWithCommas(data.h2_service)}}</td>
                        <td>{{numberWithCommas(data.service)}}</td> -->
                        <td>{{numberWithCommas(data.h12_h2)}}</td>
                        <td>{{numberWithCommas(data.h2_h2_own)}}</td>
                        <td><button class="btn btn-primary w-100" @click="getMonthDetail(data.bulan , data.tahun)">Details</button></td>
                    </tr>
                </tbody>
            </table>
            </div>
            
            <div class="text-center w-100" v-else>
                <h5>Loading.......</h5>
            </div>
        </b-card>

        <detailModal v-on:closeModal="showModal = false" v-if="showModal" :data="summaryData" :period="period"></detailModal>        
    </div>
</template>

<script>
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'
import Multiselect  from 'vue-multiselect'
import { options } from '@amcharts/amcharts4/core'

import detailModal from './childComponents/detailModal'

export default {
	components: {
        Datepicker,
        Multiselect,
        detailModal
    },
    data () {
        const startDate = moment().subtract(7, "days").format("YYYY-MM-DD");
        const currentDate = moment().format("YYYY-MM-DD");

        return {
            userRole: this.$store.getters.currentUser.role,
            firstDataLoad : false,
            isFetching : false,

            filterData: null,
            filter: {
                start: startDate, 
                end: currentDate,
                // branch: []
            },
            filterByDealer : {
                start: startDate, 
                end: currentDate,
                region : []
            },
            filterByMonth : {
                start: startDate, 
                end: currentDate,
                region : [],
                branch : []
            },
            by_month: null,
            by_region: null,
            by_dealer: null,
            showModal: false,
            summaryData: null,
            period: null,
            showAllData : false,
            infoPopup: this.$store.state.hoverInformation.report.h2Report,
            headerPopup : Object.keys(this.$store.state.hoverInformation.report.h2Report.tableHeaders),
            headerTitle : ['Pendaftaran QR', 'Ganti QR' , 'Konsumen Baru' , 'QR Lama', 'Billing' , 'Total QR Code' , 'H1 to H2 Own', 'H2 to H2 Own']
            // headerTitle : ['Pendaftaran QR', 'Ganti QR' , 'Konsumen Baru' , 'QR Lama' , 'H1 to H2' , 'H2 to H2' , 'H1 Service' , 'H2 Service' , 'Total Service' , 'H12 to H2 Own']
        }
    },
    mounted () {
        this.loadFilter()
        // this.search(0, null)
    },
    methods: {
        async getMonthDetail (month , year) {
            let date = moment(`01 ${month} ${year}`)

            let filter = {
                region : this.filterByMonth.region,
                branch: this.filterByMonth.branch,
                month : date.month() + 1,
                year : date.year()
            }

            filter = await this.transformFilter(filter)
            filter = await this.initializeFilter(filter)

            let url = "/api/reports/h2-report/daily-detail"
 
            this.$http.get(url + filter)
            .then((resp) => {
                let data = resp.data.data;
                
                // Get Total QR Code
                for (var i in data) data[i].totalQRCode = parseInt(data[i].qr_register) + parseInt(data[i].existing_id)

                this.summaryData = data
                this.showModal = true
                this.period = filter
            })
            .catch((err) => {
                console.log(err)
            })


        },
        loadFilter (type) {
            if (type == 'region'){
                this.filterByMonth.branch.length = 0;
                this.filterData.branch = this.$store.getters.getBranch(this.filterByMonth.region)
            }  else {
                this.filterData = this.$store.getters.getFilterOptions
            } 
        },
        async search (flag, url) {
            
            this.isFetching = true;

            if(url == null) url = ['/h2-report/by-month', '/h2-report/by-region', '/h2-report/by-dealer']
            
            if(flag == 1) {
                
                this.downloadExcel(url)
                return;
            }

            this.loadAPI(url)
            this.firstDataLoad = true;

        },
        async downloadExcel (url) {
            let variable = url.split('/')[2].split('-').join(' ')

            let filterType = variable == 'by region' ? 'filter' : variable == 'by dealer' ? 'filterByDealer' : 'filterByMonth'

            let filter = await this.transformFilter(this[filterType])
            filter = await this.initializeFilter(filter)

            filter += '&exportExcel=1'
            url = url + filter
            
            this.$http.get('/api/reports' + url , {responseType : 'blob'})
            .then((resp)=>{
                const blob = new Blob([resp.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                let url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = "H2 report " +  variable;
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((err)=>{
                console.log(err);
            })
        },
        async loadAPI(url) {
            for (var i = 0 ; i < url.length ; i++) {
                let variable = url[i].split('/')[2].split('-').join('_')

                this[variable] = null;

                let filterType = variable == 'by_region' ? 'filter' : variable == 'by_dealer' ? 'filterByDealer' : 'filterByMonth'

                let filter = await this.transformFilter(this[filterType])
                filter = await this.initializeFilter(filter)

                this.$http.get('/api/reports' + url[i] + filter)
                .then((resp) => {
                    var data = resp.data.data;
                    if(variable == 'by_region'){
                        //Filter out unknown
                        // data = data.filter(x=>x.code!='Unknown')

                        var length = data.length
                        if(length >= 1){
                            var obj = {
                                code : "Total",
                                name : "Total"
                            }

                            //Get keys, filter out 'name' and 'code'
                            var keys = Object.keys(data[0])
                            keys = keys.filter(x=>x!='name'&&x!='code')

                            //DECLARE Total to 0
                            for(var i = 0; i < keys.length; i++){
                                obj[keys[i]] = 0;
                            }

                            //Add all to each total
                            for(var i = 0; i < data.length; i++){
                                for (var j = 0; j < keys.length; j++){
                                    obj[keys[j]] += parseInt(data[i][keys[j]]);
                                }
                            }
                            console.log(JSON.stringify(obj));
                            data.push(obj);
                        }
                    } else if (variable == 'by_dealer'){
                        // data = data.filter(x=>x.code!='Unknown')
                    }

                    // Get Total QR Code
                    for (var i in data) data[i].totalQRCode = parseInt(data[i].qr_register) + parseInt(data[i].existing_id)

                    this[variable] = data
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(()=>{
                    this.isFetching = false;
                })
            }
        },
        async initializeFilter (filters) {
            let filterUrl = '?'
            let keys = Object.keys(filters)
            for (var i = 0; i < keys.length; i++) {
                if(typeof filters[keys[i]] == 'object'  && filters[keys[i]].length > 0)
                filterUrl += `${keys[i]}=${filters[keys[i]]}&`
                else if( filters[keys[i]] != null && filters[keys[i]] != ''){
                filterUrl += `${keys[i]}=${filters[keys[i]]}&`
                }
            }
            
            return filterUrl
        },
        async transformFilter (filter) {
            let obj = {}
            let keys = Object.keys(filter)
            for (var i = 0 ; i < keys.length; i++) {
                if (keys[i] == 'region' || keys[i] == 'branch') {
                    obj[keys[i]] = []
                    for (var j = 0; j < filter[keys[i]].length; j++) {
                        keys[i] == 'region' || keys[i] == 'branch' ? obj[keys[i]].push(filter[keys[i]][j].loc_code) : obj[keys[i]].push(filter[keys[i]][j][keys[i].toLowerCase()])
                    }
                }
                else if(keys[i] == 'start' || keys[i] == 'end')  {
                    obj[keys[i]] = moment(filter[keys[i]]).format("YYYY-MM-DD")
                }
                else {
                    obj[keys[i]] = filter[keys[i]]
                }
            }
            return obj
        },
        customFormatter : function(date){
			return moment(date).format('Y-MM-DD');
		},
        numberWithCommas(x) {
        return parseInt(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    },
    watch: {
        'filterByMonth.region' : function() {
            this.loadFilter('region')
            this.search(0 , ['/h2-report/by-month'])
        },
        'filterByMonth.branch' : function() {
            this.search(0 , ['/h2-report/by-month'])
        },
        'filterByDealer.region' : function() {
            this.search(0 , ['/h2-report/by-dealer'])
        }, 'filter.start' : function () {
            this.filterByDealer.start = this.filter.start
            this.filterByMonth.start = this.filter.start
        }, 'filter.end' : function () {
            this.filterByDealer.end = this.filter.end
            this.filterByMonth.end = this.filter.end
        }
  }
}
</script>

<style scoped>
    td .btn {
        line-height: 1;
    }

    .Aligner-Header {
        padding-left:3% !important;
        color: #707070;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
</style>