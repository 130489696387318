import { render, staticRenderFns } from "./StockAvailability.vue?vue&type=template&id=0d1ce9bb"
import script from "./StockAvailability.vue?vue&type=script&lang=js"
export * from "./StockAvailability.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports