<template>
  <div class="layout-wrapper layout-1">
    <div class="layout-inner">
      <app-layout-navbar />

      <div class="layout-container">
        <app-layout-sidenav />
        <!-- <sidebar></sidebar> -->

        <div class="layout-content">
          <div class="router-transitions container-fluid flex-grow-1 container-p-y">
            <!-- <router-view></router-view> -->
            <dashboard></dashboard>
          </div>

          <app-layout-footer />
        </div>
      </div>
    </div>
    <div class="layout-overlay" @click="closeSidenav"></div>
  </div>
</template>

<script>
import LayoutNavbar from './LayoutNavbar'
import LayoutSidenav from './LayoutSidenav'
import LayoutFooter from './LayoutFooter'

import dashboard from '@/components/Dashboard.vue'

export default {
  name: 'app-layout-1',
  components: {
    'app-layout-navbar': LayoutNavbar,
    'app-layout-sidenav': LayoutSidenav,
    'app-layout-footer': LayoutFooter,
    dashboard,
  },

  mounted () {
    this.layoutHelpers.init()
    this.layoutHelpers.update()
    this.layoutHelpers.setAutoUpdate(true)
  },

  beforeDestroy () {
    this.layoutHelpers.destroy()
  },

  methods: {
    closeSidenav () {
      this.layoutHelpers.setCollapsed(true)
    }
  }
}
</script>
