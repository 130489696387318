<script>/* eslint-disable */</script>
<template>
	<div style="overflow-x:scroll">
		<table class="table text-center">
			<thead style="color:white">
				<th class="bg-red">REGION</th>
				<th v-for="x in monthLength" :key="x"  class="bg-blue" >{{x}}</th>
				<th class="bg-green">Total</th>
			</thead>
			<tbody style="color:black">
				<tr v-for="datum in data" :key="datum.loc_code">
					<td class="bg-lg-red">{{datum.loc_code}}</td>
					<td v-for="x in monthLength" :key="x" :class="{'bg-lg-blue' : x == today.date() && parseInt(today.month()) + 1 == parseInt(filter.month.value) && today.year() == filter.year}">{{datum[`day_${x}`]}}</td>
					<td class="bg-lg-green">{{datum.total}}</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import moment from 'moment'

export default {
	props: ['data' , 'filter'],
	data () {
		let monthLength = new Date(this.filter.year, this.filter.month.value , 0).getDate()
		let today = new moment()
		return {
			monthLength,
			today,
			moment
		}
	}
}
</script>

<style scoped>
	.bg-lg-green {
		background-color: 	#98FB98;
	}

	.bg-green {
		background-color: 	#006400;
	}

	.bg-lg-blue {
		background-color: #89CFF0;
	}

	.bg-blue {
		background-color: #000033;
	}

	.bg-red {
		background-color: #DC143C;
	}

	.bg-lg-red {
		background-color: #FA8072;
	}
</style>