<template>
	<div>

			<!-- Stock Days Start -->
			<div class=" Aligner-Header mt-4">
				<h3>Stock Days
					<span class="col-md-1 col-sm-1 col-2">
						<i class="fa fa-info-circle fa-sm" id="stockDays"></i>
						<b-popover
						target="stockDays"
						title="Stock Days"
						triggers="hover focus"
						placement="top"
						:content="infoPopup.stockDays"
						></b-popover>
					</span>
				</h3>
			</div>
			<div class="row mt-2 mb-3" style="padding-right:1%">
				<div class="col-md-5 col-12 row" style="padding-top:6px;">
					<div class="col" style="padding-right:0">
						<button 
							style="border-radius : 6px 0px 0px 6px"
							type="button" 
							class="w-100 btn" 
							:class="{'btn-outline-primary' : stockDaysButtonOptions != 'MD', 'btn-primary' : stockDaysButtonOptions == 'MD'}" 
							@click="stockDaysButtonOptions = 'MD'"
						>
							Main Dealer
						</button>
					</div>
					<div class="col" style="padding-left:0">
						<button 
							style="border-radius : 0px 6px 6px 0px"
							type="button" 
							class="w-100 btn" 
							:class="{'btn-outline-primary' : stockDaysButtonOptions != 'D', 'btn-primary' : stockDaysButtonOptions == 'D'}" 
							@click="stockDaysButtonOptions = 'D'"
						>
							Dealer
						</button>
					</div>
				</div>
				<div class="col-md-1 col-12"/>
				<div class="col-12 col-md-6 row">
					<div class="col-12 col-md-4 highlightFilter">
						<Multiselect 
							class="mt-1 "
							:options="options.region"
							:searchable="true"
							label="name"
							:close-on-select="true"
							:show-labels="false"
							:allow-empty="true"
							v-model="filter.stockDaysOptions.region"
							placeholder="All Region"
						/>
					</div>
					<div class="col-12 col-md-4 highlightFilter">
						<Multiselect 
							class="mt-1 "
							:options="options.type"
							:searchable="true"
							label="type"
							:close-on-select="true"
							:show-labels="false"
							v-model="filter.stockDaysOptions.type"
							placeholder="All Type"
						/>
					</div>
					<div class="col-12 col-md-4 highlightFilter">
						<Multiselect 
							class="mt-1 "
							:options="options.color"
							:searchable="true"
							label="name"
							:close-on-select="true"
							:show-labels="false"
							v-model="filter.stockDaysOptions.color"
							placeholder="All Color"
						/>
					</div>
					<div class="col-12 col-md-4 highlightFilter">
						<!-- Month Grouping Start -->
						<div class="row w-100" style="margin: 12px 0 0 0">
							<div class="col-3" style="padding:0">
								<button 
									style="border-radius : 6px 0px 0px 6px;border: 1px solid #CB2127; height: 40px; background-color: #CB2127;"
									type="button" 
									class="w-100 btn btn-primary"
									@click="sdFilter.month--"
									:disabled="sdFilter.month <= 0"
								>
									<i class="fa fa-caret-left" aria-hidden="true" style="color:white"></i>
								</button>
							</div>
							<div class="col-6" style="padding-left:0;padding-right:0">
								<p
									class="btn w-100 align-middle"
									style="border: 1px solid #CB2127;border-radius:0px; height: 40px"
								>
									{{months[sdFilter.month]}}
								</p>
							</div>
							<div class="col-3" style="padding:0">
								<button 
									style="border-radius : 0px 6px 6px 0px;border: 1px solid #CB2127; height: 40px; background-color: #CB2127;"
									type="button" 
									class="w-100 btn btn-primary"
									@click="sdFilter.month++"
									:disabled="sdFilter.month >= 11"
								>
									<i class="fa fa-caret-right" aria-hidden="true" style="color:white"></i>
								</button>
							</div>
							
						</div>
						<!-- Month Grouping End -->
					</div>
					<div class="col-12 col-md-4 highlightFilter">
						<!-- Week Grouping Start -->
						<div class="row w-100" style="margin: 12px 0 0 0">
							<div class="col-3" style="padding:0">
								<button 
									style="border-radius : 6px 0px 0px 6px;border: 1px solid #CB2127; height: 40px; background-color: #CB2127;"
									type="button" 
									class="w-100 btn btn-primary"
									@click="sdFilter.week--"
									:disabled="sdFilter.week <= 0"
								>
									<i class="fa fa-caret-left" aria-hidden="true" style="color:white"></i>
								</button>
							</div>
							<div class="col-6" style="padding-left:0;padding-right:0">
								<p
									class="btn w-100 align-middle"
									style="border: 1px solid #CB2127;border-radius:0px; height: 40px"
								>
									Week {{sdFilter.week}}
								</p>
							</div>
							<div class="col-3" style="padding:0">
								<button 
									style="border-radius : 0px 6px 6px 0px;border: 1px solid #CB2127; height: 40px; background-color: #CB2127;"
									type="button" 
									class="w-100 btn btn-primary"
									@click="sdFilter.week++"
									:disabled="sdFilter.week >= maxWeekOfMonth"
								>
									<i class="fa fa-caret-right" aria-hidden="true" style="color:white"></i>
								</button>
							</div>
						</div>
						<!-- Week Grouping End -->
					</div>
					<div class="col-12 col-md-4 highlightFilter">
						<button
							class="btn highlightFilterBtn"
							style="margin-top: 12px; height: 40px"
							@click="downloadStockDays"
						>
							Download <i class="fas fa-download"></i>
						</button>
					</div>
				</div>
			</div>
			<div class="content-outer">
			<div class="content-inner" style="padding: 2% 2% 0 2%">
				<div class="row">
				<div class="col" style="display: flex; flex-direction: row-reverse;">
					<!-- <p style="font-size: large; color: #707070; font-weight: bold;">Stock Days as of today <span style="font-size: x-large;">{{stockDaysAvg}}</span></p> -->
				</div>
				</div>
				<stock-days-chart
					:period="period"
					:mdData= "data.stockDaysMD"
					:dData= "data.stockDaysD"
					:type="stockDaysButtonOptions"
					v-on:setAverageStockDays="updateAverageStockDays"
					v-if="data.stockDaysMD && data.stockDaysD"
				></stock-days-chart>
				<div v-else style="text-align:center; margin-top:20px"><h4>Loading...</h4></div>
			</div>
			</div>
			<!-- Stock Days End -->

			<!-- Aging Stock Analysis Start -->
			<div class="Aligner-Header mt-4">
				<h3>Aging Stock Analysis
					<span class="col-md-1 col-sm-1 col-2">
						<i class="fa fa-info-circle fa-sm" id="agingStockAnalysis"></i>
						<b-popover
						target="agingStockAnalysis"
						title="Aging Stock Analysis"
						triggers="hover focus"
						placement="top"
						:content="infoPopup.agingStockAnalysis"
						></b-popover>
					</span>
				</h3>
			</div>
			<div class="row mt-5 mb-3" style="padding-right:1%">
			<div class="col-md-5 col-12 row" style="padding-top:6px;">
				<!-- <div class="col" style="padding-right:0">
					<button 
						style="border-radius : 6px 0px 0px 6px"
						type="button" 
						class="w-100 btn" 
						:class="{'btn-outline-primary' : agingStockButtonOptions != 'End To End', 'btn-primary' : agingStockButtonOptions == 'End To End'}" 
						@click="agingStockButtonOptions = 'End To End'"
					>
						End To End
					</button>
				</div> -->
				<div class="col" style="padding:0">
					<button 
						style="border-radius : 6px 0px 0px 6px"
						type="button" 
						class="w-100 btn" 
						:class="{'btn-outline-primary' : agingStockButtonOptions != 'MD', 'btn-primary' : agingStockButtonOptions == 'MD'}" 
						@click="agingStockButtonOptions = 'MD'"
					>
						Main Dealer
					</button>
				</div>
				<div class="col" style="padding-left:0">
					<button 
						style="border-radius : 0px 6px 6px 0px"
						type="button" 
						class="w-100 btn" 
						:class="{'btn-outline-primary' : agingStockButtonOptions != 'D', 'btn-primary' : agingStockButtonOptions == 'D'}" 
						@click="agingStockButtonOptions = 'D'"
					>
						Dealer
					</button>
				</div>
			</div>
			<div class="col-md-1 col-12"></div>
			<div class="col-12 col-md-6 row">
				<div class="col-12 col-md highlightFilter">
				<Multiselect 
					class="mt-1 "
					:options="options.region"
					:searchable="true"
					:close-on-select="true"
					:show-labels="false"
					label="name"
					v-model="filter.agingStockOptions.region"
					placeholder="All Regions"
				/>
				</div>
				<div class="col-12 col-md highlightFilter">
				<Multiselect 
					class="mt-1 "
					:options="options.segment"
					:searchable="true"
					:close-on-select="true"
					label="segment"
					:show-labels="false"
					v-model="filter.agingStockOptions.segment"
					placeholder="All Segment"
				/>
				</div>
				<div class="col-12 col-md highlightFilter">
				<Multiselect 
					class="mt-1 "
					:options="options.color"
					:searchable="true"
					label="name"
					:close-on-select="true"
					:show-labels="false"
					v-model="filter.agingStockOptions.color"
					placeholder="All Color"
				/>
				</div>
			</div>
			</div>
			<div class="content-outer">
				<div class="content-inner row" style="padding: 2%;" v-if="agingStockButtonOptions == 'MD' || agingStockButtonOptions == 'D'">
					<div class="col-12 col-md-8">
					<aging-stock-main-dealer-analysis-bar-chart :data="data.agingStockMD[0]" :dealerData="data.agingStockD[0]" :selected="agingStockButtonOptions" v-if="data.agingStockMD && data.agingStockD"
					></aging-stock-main-dealer-analysis-bar-chart>
					<div v-else style="text-align:center; margin-top:20px"><h4>Loading...</h4></div>
					</div>
					<div class="col-12 col-md-4">
					<aging-stock-main-dealer-analysis-pie-chart :data="data.agingStockMD[0]" :dealerData="data.agingStockD[0]" :selected="agingStockButtonOptions" v-if="data.agingStockMD && data.agingStockD"
					></aging-stock-main-dealer-analysis-pie-chart>
					<div v-else style="text-align:center; margin-top:20px"><h4>Loading...</h4></div>
					</div>
				</div>
				<!-- <div class="content-inner row" style="padding: 2%;" v-else>
					<aging-stock-end-to-end-chart class="col-12"
					:data="data.agingStockEnd" v-if="data.agingStockEnd">
					</aging-stock-end-to-end-chart>
					<div v-else style="text-align:center; margin-top:20px"><h4>Loading...</h4></div>
				</div> -->
			</div>
			<!-- Aging Stock Analysis End -->

			<!-- Average Aging Stock Start -->
			<!-- <div class="Aligner-Header">
				<h3>Average Aging Stock
					<span class="col-md-1 col-sm-1 col-2">
						<i class="fa fa-info-circle fa-sm" id="averageAgingStock"></i>
						<b-popover
						target="averageAgingStock"
						title="Average Aging Stock"
						triggers="hover focus"
						placement="top"
						:content="infoPopup.averageAgingStock"
						></b-popover>
					</span>
				</h3>
			</div>
			<div class="row mt-2 mb-3" style="padding-right:1%">
			<div class="col-md-4 col-12 row" style="padding-top:6px;">
				<div class="col" style="padding-right:0">
					<button 
						style="border-radius : 6px 0px 0px 6px"
						type="button" 
						class="w-100 btn" 
						:class="{'btn-outline-primary' : averageAgingStockButtonOptions != 'MD', 'btn-primary' : averageAgingStockButtonOptions == 'MD'}" 
						@click="averageAgingStockButtonOptions = 'MD'"
					>
						Main Dealer
					</button>
				</div>
				<div class="col" style="padding-left:0">
					<button 
						style="border-radius : 0px 6px 6px 0px"
						type="button" 
						class="w-100 btn" 
						:class="{'btn-outline-primary' : averageAgingStockButtonOptions != 'D', 'btn-primary' : averageAgingStockButtonOptions == 'D'}" 
						@click="averageAgingStockButtonOptions = 'D'"
					>
						Dealer
					</button>
				</div>
			</div>
			<div class="col-md-4 col-12"></div>
			<div class="col-12 col-md-4 row">
				<div class="col-12 col-md highlightFilter">
				<Multiselect 
					class="mt-1 "
					:options="options.segment"
					:searchable="true"
					:close-on-select="true"
					label="segment"
					:show-labels="false"
					v-model="filter.averageAgingStockOptions.segment"
					placeholder="All Segment"
				/>
				</div>
				<div class="col-12 col-md highlightFilter">
				<Multiselect 
					class="mt-1 "
					:options="options.region"
					:searchable="true"
					:close-on-select="true"
					label="name"
					:show-labels="false"
					v-model="filter.averageAgingStockOptions.region"
					placeholder="All Regions"
				/>
				</div>
			</div>
			</div>
			<div class="content-outer">
			<div class="content-inner" style="padding: 2%;">
				<average-aging-stock-main-dealer 
					:mdData="data.averageAgingMD" 
					:dData="data.averageAgingD"
					:type="averageAgingStockButtonOptions"
					v-if="data.averageAgingMD && data.averageAgingD"
				></average-aging-stock-main-dealer>
				<div v-else style="text-align:center; margin-top:20px"><h4>Loading...</h4></div>
			</div>
			</div> -->
			<!-- Average Aging Stock Main Dealer -->
	</div>
</template>

<script>
	import XLSX from 'xlsx'
	import moment from 'moment'
	import Multiselect  from 'vue-multiselect'
	import movementStockTable from './../children/movementStockTable.vue'
	import movementStockVehicleChart from './../children/movementStockVehicleChart.vue'
	import movementStockAreaChart from './../children/movementStockAreaChart.vue'
	import stockDaysChart from './../children/stockDaysChart.vue'
	import agingStockMainDealerAnalysisBarChart from './../children/agingStockAnalysis/mainDealerBarChart.vue'
	import agingStockMainDealerAnalysisPieChart from './../children/agingStockAnalysis/mainDealerPieChart.vue'
	import agingStockEndToEndChart from './../children/agingStockAnalysis/agingStockEndToEndChart'
	import averageAgingStockMainDealer from './../children/averageAgingStock/mainDealerChart.vue'
	import listUrl from './../listUrl.js'

export default {
	components: {
		Multiselect ,
		movementStockTable,
		movementStockVehicleChart,
		movementStockAreaChart,
		stockDaysChart,
		agingStockMainDealerAnalysisBarChart,
		agingStockMainDealerAnalysisPieChart,
		agingStockEndToEndChart,
		averageAgingStockMainDealer
	},
	props: ['options' , 'period', 'mobile', 'data', 'filter'],
	data () {
		return {
			movementStockButtonOptions: 'Chart Summary',
			averageAgingStockButtonOptions: 'MD',
			stockDaysButtonOptions: 'MD',
			agingStockButtonOptions: 'MD',
			// agingStockButtonOptions: 'End To End',
			stockDaysAvg: 0,
			infoPopup : this.$store.state.hoverInformation.highlight.stockAnalytics,

			months : ['Jan', 'Feb', ' Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
			maxWeekOfMonth : Math.ceil(moment().endOf('month').date() / 7),
			sdFilter : {
				month : moment().month(),
				week : Math.floor((moment().date() - 1) / 7) + 1
			}
		}
	},
	watch : {
		// Stock Days Month Options
		"sdFilter.month" () {
			this.maxWeekOfMonth = Math.ceil(moment().set('month', this.sdFilter.month).endOf('month').date() / 7)
			if (this.sdFilter.week > this.maxWeekOfMonth) this.sdFilter.week = 1;
		},

		// Translate Month & Week to Start & End Dates
		"sdFilter" : {
			deep : true,
			handler () {
				//1 Week = 7 Days (ex. 1-7, 8-14, 15-21, 22-28, 29-31)
				let start = moment().set({month:this.sdFilter.month, date:(this.sdFilter.week-1) * 7 + 1}).format("YYYY-MM-DD");
				let end = (moment(start).add(6, 'days') > moment(start).endOf('month') ? moment(start).endOf('month') : moment(start).add(6, 'days')).format("YYYY-MM-DD");

				this.filter.stockDaysOptions.start = start;
				this.filter.stockDaysOptions.end = end;
			}
		},

		"filter.agingStockOptions": {
			deep: true,
			handler: function () {
				let url = listUrl.stringUrl.stockAnalytics.agingStockAnalysis
				let params = listUrl.param.stockAnalytics.agingStockAnalysis

				this.$emit('loadAPI' , url , params )
        	}
      	},

      	// Filter Stock Days
		"filter.stockDaysOptions": {
			deep: true,
			handler: function () {
				let url = listUrl.stringUrl.stockAnalytics.stockDays
				let params = listUrl.param.stockAnalytics.stockDays

				this.$emit('loadAPI' , url , params )
			}
		},

		// Filter Average Aging
		"filter.averageAgingStockOptions": {
			deep: true,
			handler: function () {
				let url = listUrl.stringUrl.stockAnalytics.averageAgingStock
				let params = listUrl.param.stockAnalytics.averageAgingStock

				this.$emit('loadAPI' , url , params )
			}
		},
	},
	methods : {
		updateAverageStockDays(avg) {
			this.stockDaysAvg = avg
		},
		featureUnavailable () {
			this.$swal.fire(
				'Feature is unavailable at the moment',
				'',
				'warning'
			)
		},
		downloadStockDays () {
			let wb = XLSX.utils.book_new()
			var ws = XLSX.utils.json_to_sheet(this.stockDaysButtonOptions == 'MD' ? this.data.stockDaysMD : this.data.stockDaysD, {raw:true});
			XLSX.utils.book_append_sheet(wb, ws, 'Data')

			let exportFileName = `Stock Days ${this.months[this.sdFilter.month]} Week ${this.sdFilter.week}.xlsx`
			XLSX.writeFile(wb, exportFileName)
		}
	}
}
</script>

<style scoped>
	.btn-primary {
		background-color: #CB2127;
		border-color: #CB2127;
	}

	.btn-outline-primary {
		border-color: #CB2127;
		color: #CB2127;
		box-shadow: 0 0 0 1px #CB2127 inset;
	}

	.btn-outline-primary:hover {
		background-color: #CB2127;
		color: white;
	}

	.btn-outline-primary:focus {
		background-color:#CB2127;
	}

	.btn-primary:disabled {
		background-color: #CB2127 !important;
		opacity: .7;
	}
</style>