<template>
	<div>
		<div class="innerBox" style="
		  display: flex;
		  flex-direction: row;
		  align-items: center;
		  padding: 7.5px 10px;
		  font-family: proxima-nova, sans-serif;
		">
			<button class="btn" style="
			font-size: 16px;
			color: #6c6c6c;
			background-color: #F5F5F5;
			border: #F5F5F5;
			font-weight: bold;
			padding: 4px;
			font-family: proxima-nova, sans-serif;
		  " @click="$router.go(-1)">
				<i class="fas fa-angle-left mr-2"></i>Back
			</button>
			<p class="mb-0" style="
			font-size: 16px;
			color: #B9B9B9;
			font-weight: bold;
			font-family: proxima-nova, sans-serif;
		  ">
				| Tracking Last Visit Journey <i class="fas fa-angle-right"></i>
			</p>
			<p class="mb-0 ml-1" style="
			font-size: 16px;
			color: #4b4b4b;
			font-weight: bold;
			font-family: proxima-nova, sans-serif;
		  ">
				{{ engineNo }}
			</p>
		</div>

		<div class="row">
			<div class="col-md-4">
				<div class="engine-info col-md-12">
					<h2 style="color: #383838;">Engine Number</h2>
					<p class="engine-number mb-3">{{ engineNo }}</p>

					<div v-if="loading" class="loading">
						<b-spinner label="Loading..."></b-spinner>
					</div>

					<p v-else-if="error" class="error">{{ error }}</p>

					<ul v-else class="location-list">
						<li v-for="(location, index) in detailData" :key="index">
							<span class="dot"></span>
							<div class="location-info">
								<div class="location-details">
									<p class="location-name">
										{{ location.loc_code }} - {{ location.name }}
									</p>
									<p class="location-date">{{ location.visit_date }}</p>
								</div>
								<button v-if="location.lat && location.lng" @click="panToLocation(location)"
									class="btn-square">
									<i class="fas fa-map-marker-alt" style="color: #695BFC; font-size: large;"></i>
								</button>
							</div>
						</li>

					</ul>
				</div>
			</div>

			<div class="map-info col-md-8">
				<div v-if="locationsWithCoordinates.length > 0" class="map-container">
					<h3 class="ml-3" style="font-weight: bold">Dealer Journey</h3>
					<l-map ref="mapRef"
						style="height: 70vh; border-bottom-left-radius: 8px; border-bottom-right-radius: 8px;"
						:zoom="10" :center="mapCenter">
						<l-tile-layer
							url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"></l-tile-layer>
						<l-marker v-for="(location, index) in locationsWithCoordinates" :key="index"
							:lat-lng="[parseFloat(location.lat), parseFloat(location.lng)]" :icon="customIcon">
							<l-popup>
								<div>
									<strong>{{ location.name }}</strong><br />
									{{ location.visit_date }}
								</div>
							</l-popup>
						</l-marker>
					</l-map>
				</div>
				<p v-else class="no-map-data">No map data available</p>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { LMap, LTileLayer, LMarker, LPopup, LIcon } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "@fortawesome/fontawesome-free/css/all.css";

export default {
	name: "H2VisitJourney",
	data() {
		return {
			engineNo: this.$route.params.engineNo,
			startDate: this.$route.query.start,
			endDate: this.$route.query.end,
			detailData: [],
			loading: true,
			error: null,
			selectedLocation: null,
			customIcon: new L.DivIcon({
				html: '<i class="fas fa-map-marker-alt" style="color: #D93636; font-size: 40px;"></i>',
				className: "custom-div-icon",
				iconSize: [40, 40], // Increase the size of the icon
				iconAnchor: [20, 40], // Adjust anchor point to center the marker
				popupAnchor: [0, -40], // Adjust popup position
				shadowUrl: null,
			}),
		};
	},
	computed: {
		formattedStartDate() {
			return moment(this.startDate).format("DD/MM/YYYY");
		},
		formattedEndDate() {
			return moment(this.endDate).format("DD/MM/YYYY");
		},
		mapCenter() {
			if (this.selectedLocation) {
				return [
					parseFloat(this.selectedLocation.lat),
					parseFloat(this.selectedLocation.lng),
				];
			} else if (this.locationsWithCoordinates.length > 0) {
				const firstLocation = this.locationsWithCoordinates[0];
				return [parseFloat(firstLocation.lat), parseFloat(firstLocation.lng)];
			}
			return [0, 0]; // Default center if no coordinates available
		},
		locationsWithCoordinates() {
			return this.detailData.filter((location) => location.lat && location.lng);
		},
	},
	mounted() {
		this.fetchDetailData();
	},
	components: {
		LMap,
		LTileLayer,
		LMarker,
		LPopup,
	},
	methods: {
		fetchDetailData() {
			this.loading = true;
			this.error = null;

			axios
				.get(`/api/distribution/tracking/last-visit-journey`, {
					params: {
						start: this.startDate,
						end: this.endDate,
						engine_no: this.engineNo,
					},
				})
				.then((response) => {
					this.detailData = response.data.data;
					this.loading = false;
				})
				.catch((error) => {
					console.error("Error fetching detail data:", error);
					this.error =
						"An error occurred while fetching the data. Please try again.";
					this.loading = false;
				});
		},
		panToLocation(location) {
			this.selectedLocation = location;
			if (this.$refs.mapRef) {
				this.$refs.mapRef.leafletObject.setView(
					[parseFloat(location.lat), parseFloat(location.lng)],
					13
				);
			}
		},
	},
};
</script>

<style scoped>
.engine-info {
	font-family: proxima-nova, sans-serif;
	padding: 20px;
	background-color: white;
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0);
	max-height: 71vh;
	/* Adjust height to fit screen */
	overflow: hidden;
	/* Hide overflow content */
	display: flex;
	flex-direction: column;
}

.map-info {
	font-family: proxima-nova, sans-serif;
	padding: 0px;
	background-color: white;
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0);
	height: 71vh;
}

h2 {
	font-size: 14px;
	color: #888;
	margin-bottom: 5px;
}

.engine-number {
	color: #393939;
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 5px;
}

.date-range {
	font-size: 14px;
	color: #888;
	margin-bottom: 20px;
}

.location-list {
	list-style-type: none;
	padding: 0;
	margin: 0;
	overflow-y: scroll;
	/* Make the list scrollable */
	flex-grow: 1;
	/* Allow the list to expand and fill available space */
}

/* Hide scrollbar for WebKit browsers (Chrome, Safari) */
.location-list::-webkit-scrollbar {
	width: 0;
	/* Remove scrollbar width */
	height: 0;
	/* Remove scrollbar height */
}

/* Hide scrollbar for Firefox */
.location-list {
	scrollbar-width: none;
	/* Hide scrollbar */
}

/* Optional: Hide scrollbar for Internet Explorer */
.location-list {
	-ms-overflow-style: none;
	/* IE and Edge */
}

.location-list::before {
	content: "";
	position: flex;
	top: 5px;
	left: 4px;
	bottom: 5px;
	width: 2px;
	background-color: #e0e0e0;
}

.location-list li {
	display: flex;
	align-items: center;
	margin-bottom: 10%;
	/* Adjust as needed */
}

.dot {
	width: 10px;
	height: 10px;
	background-color: #4285f4;
	border-radius: 50%;
	margin-right: 10px;
	margin-top: 5px;
	position: relative;
	z-index: 1;
}

.location-info {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-grow: 1;
}

.location-details {
	flex: 1;
}

.location-name {
	color: #4B4B4B;
	font-weight: bold;
	font-size: 14px;
	margin: 0;
}

.location-date {
	color: #A8A8A8;
	font-size: 12px;
	margin: 0;
}

.btn {
	font-size: 14px;
	padding: 5px 10px;
	margin-left: 10px;
	display: flex;
	align-items: center;
	border: 1px solid #E8E8E8;
	background-color: #FFFFFF;
}

.btn .fa-map-marker-alt {
	color: #695BFC;
}

.btn-square {
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid #E8E8E8;
	background-color: #FFFFFF;
	border-radius: 5px;
	align-self: end;
}

.loading,
.error {
	text-align: center;
	padding: 20px;
}

.error {
	color: red;
}

.map-container {
	margin-top: 20px;
}

.map-container h3 {
	font-size: 16px;
	margin-bottom: 10px;
}

.no-map-data {
	margin-top: 20px;
	font-style: italic;
	color: #888;
}

@import "~leaflet/dist/leaflet.css";
</style>