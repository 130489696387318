<template>
    <div>

        <div v-if="isLoading">
            <h2 class="placeholderTitle text-center mt-5 mb-5">Loading...</h2>
        </div>

        <div v-else-if="data && !isLoading">
            <div class="row">
                <div :class="isBackDate ? 'col-md-10' : 'col-md-8'"></div>
                <div class="col-md-2" v-if="!isBackDate">
                    <button
                        class="btn btn-outline-download mb-3"
                        @click="$emit('requestReload')"
                    >Reload Data</button>
                </div>
                <div class="col-md-2">
                    <button
                        class="btn btn-outline-download mb-3"
                        @click="exportXLSX"
                    >Download Detail&nbsp;&nbsp;<i class="fas fa-download"></i></button>
                </div>
            </div>
            
            <div class="h2DealerReportTable">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th
                                v-for="header in tableHeaders"
                                class="clickable"
                                @click="sortTable(header.variable)"
                            >{{ header.title }}{{ header.variable == sort.by ? sort.order ? '▲' : '▼' : '' }}</th>
                            <th><i class="fas fa-cog"></i></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(dat, index) in data">
                            <td>{{ dat.engine_no }}</td>
                            <td>{{ dat.serial }}</td>
                            <td>{{ dat.qrcode }}</td>
                            <td>{{ dat.old_qr ? dat.old_qr : '-' }}</td>
                            <td>{{ dat.commercial_name }}</td>
                            <td>{{ dat.activity }}</td>
                            <td>{{ dat.active_date.split(",").pop() }}</td>
                            <td>
                                <button class="btn" style="text-decoration: underline;" :id="'dataDetail' + index">Detail</button>
                                <b-popover
                                :target="'dataDetail' + index"
                                :title="'Detail ' + dat.engine_no"
                                triggers="hover focus"
                                data-html="true"
                                >Warna : {{dat.color_description}}<br><br>Segment : {{dat.segment}}<br><br>Scan Date : {{dat.active_date}}</b-popover>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div v-else>
            <h2 class="placeholderTitle text-center mt-5 mb-5">Select region and branch</h2>
        </div>

    </div>
</template>

<script>
    import moment from 'moment'
    import XLSX from 'xlsx'

    export default {
        data () {
            return {
                tableHeaders : [
                    {title : "Nomor Mesin", variable: "engine_no"},
                    {title : "Nomor Serial", variable: "serial"},
                    {title : "QR Code", variable: "qrcode"},
                    {title : "QR Lama", variable: "old_qr"},
                    {title : "Commercial Name", variable: "commercial_name"},
                    {title : "Activity", variable: "activity"},
                    {title : "Scan Date", variable: "active_date"}
                ],
                sort: {
                    order : false,
                    by : "active_date"
                },
                dummyData : null,
            }
        },
        props : ['data', 'selected', 'filter', 'isLoading', 'isBackDate'],
        mounted () {
            // this.generateDummyData();
        },
        methods : {
            generateDummyData () {
                let data = []

                let types = ['Scoopy', 'Beat Pop', 'Genio', 'CBR', 'Beatstreet', 'ADV']
                let addActivities = ['New Mapping', 'Replacement QR', 'Existing QR']
                let colors = ['Red', 'Green', 'Blue']
                let segments = ['Matic', 'Cub', 'Sport', 'Business']

                for (var i = 0; i < 1000; i++) {
                    data.push({
                        engine_no : this.randomInt(100000000, 999999999),
                        qrcode : this.randomInt(100000000, 999999999),
                        active_date : moment().subtract(this.randomInt(0,100000), 'seconds').format("YYYY-MM-DD HH:mm:ss"),
                        rquuid : this.randomInt(100000000, 999999999),
                        old_qr : this.randomInt(0,100) % 2 == 0 ? this.randomInt(100000000, 999999999) : '-',
                        commerical_name : types[this.randomInt(0,1000) % types.length],
                        activity : addActivities[this.randomInt(0,1000) % addActivities.length],
                        color_description : colors[this.randomInt(0,1000) % colors.length],
                        segment : segments[this.randomInt(0,1000) % segments.length]
                    })
                }

                this.dummyData = data;
            },
            exportXLSX () {
                console.log('exporting...');
                let wb = XLSX.utils.book_new()
                var ws = XLSX.utils.json_to_sheet(this.data, {raw:true});
                XLSX.utils.book_append_sheet(wb, ws, 'Data')
                XLSX.writeFile(wb, `${this.filter.branch} ${this.selected.range}.xlsx`)
            },
            sortTable (variable) {
                if (this.sort.by == variable) this.sort.order = !this.sort.order;
                else {
                    this.sort.by = variable;
                    this.sort.order = true;
                }

                this.$emit("requestReload", null, this.sort);
            }
        }
    }
</script>

<style scoped>
    .btn-outline-download {
        box-shadow: 0px 2px 6px #0000001A;
        border-radius: 5px;
        font: normal normal 500 10px/11px Ubuntu;
        color: #676767;

        min-height: 30px;
        width: 100%;
    }

    .placeholderTitle {
        color: #616161;
        font-size: 60px;
        text-shadow: 4px 4px 6px #BDBDBD;
    }

    table thead tr th, table tbody tr td {
        text-align: center;
    }

    .h2DealerReportTable {
        /* width: 100%; */
        overflow-x: auto;
    }

    .clickable {
        cursor: pointer;
    }
</style>