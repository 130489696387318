<script>/* eslint-disable */</script>
<template>
  <div>
    <div ref='byType' style='min-height:350px'></div>
    <div class="row">
       <div class="col-8" ref="legend"></div>
       <div class="col-4" ref="legend2"></div>
    </div>
   
  </div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import spiritedaway from '@amcharts/amcharts4/themes/spiritedaway.js'
import animated from '@amcharts/amcharts4/themes/animated.js'

am4core.useTheme(spiritedaway)
am4core.useTheme(animated)

export default {
  props: ['data'],
  mounted () {
    this.createChart()
    this.loadData()
  },
  data () {
    return {
      chart: null,
      chart2: null,
      selectedSlice: null,
      pieSeries2: null,
      pieSeries: null,
      line1: null,
      line2: null
    }
  },
  methods: {
    loadData () {
      let length = this.chart.data.length
      for (var i = 0 ; i < this.data.length ; i++) {
        this.data[i].calc = Math.round(this.data[i].average / 24 * 100) /100
        this.data[i].day = Math.floor(this.data[i].calc)
        this.data[i].hour = Math.floor((this.data[i].calc % 1) * 24)
        for (var j = 0 ; j < this.data[i].subData.length; j++) {
          this.data[i].subData[j].calc = Math.round(this.data[i].subData[j].average / 24 * 100) /100
          this.data[i].subData[j].day = Math.floor(this.data[i].subData[j].calc)
          this.data[i].subData[j].hour = Math.floor((this.data[i].subData[j].calc % 1) * 24)
        }
      }
      this.chart.addData(this.data, length)
    },
    createChart () {
      let container = am4core.create(this.$refs.byType, am4core.Container)
      container.width = am4core.percent(100)
      container.height = am4core.percent(100)
      container.layout = 'horizontal'

      this.chart = container.createChild(am4charts.PieChart)

      // Add and configure Series
      this.pieSeries = this.chart.series.push(new am4charts.PieSeries())
      this.pieSeries.dataFields.value = 'average'
      this.pieSeries.dataFields.category = 'type'
      this.pieSeries.slices.template.states.getKey('active').properties.shiftRadius = 0
      this.pieSeries.slices.template.tooltipText = '{type}: [bold]{day} Days {hour} H'
      this.pieSeries.labels.template.text = "{category}: {value} H ({value.percent.formatNumber('#.#')}%)"

      let selectSlice = this.selectSlice

      this.pieSeries.slices.template.events.on('hit', function (event) {
        selectSlice(event.target.dataItem)
      })
      this.chart2 = container.createChild(am4charts.PieChart)
      this.chart2.width = am4core.percent(30)
      this.chart2.radius = am4core.percent(80)

      // Add and configure Series
      this.pieSeries2 = this.chart2.series.push(new am4charts.PieSeries())
      this.pieSeries2.dataFields.value = 'average'
      this.pieSeries2.dataFields.category = 'color'
      this.pieSeries2.slices.template.states.getKey('active').properties.shiftRadius = 0
      this.pieSeries2.slices.template.tooltipText = '{color}: [bold]{day} Days {hour} H'
      //  this.pieSeries2.labels.template.radius = am4core.percent(50)
      //  this.pieSeries2.labels.template.inside = true
      //  this.pieSeries2.labels.template.fill = am4core.color('#ffffff')
      this.pieSeries2.labels.template.disabled = true
      this.pieSeries2.ticks.template.disabled = true
      this.pieSeries2.alignLabels = false
      this.pieSeries2.events.on('positionchanged', this.updateLines)

      let interfaceColors = new am4core.InterfaceColorSet()

      this.line1 = container.createChild(am4core.Line)
      this.line1.strokeDasharray = '2,2'
      this.line1.strokeOpacity = 0.5
      this.line1.stroke = interfaceColors.getFor('alternativeBackground')
      this.line1.isMeasured = false

      this.line2 = container.createChild(am4core.Line)
      this.line2.strokeDasharray = '2,2'
      this.line2.strokeOpacity = 0.5
      this.line2.stroke = interfaceColors.getFor('alternativeBackground')
      this.line2.isMeasured = false
      
      var legendContainer = am4core.create(this.$refs.legend, am4core.Container);
      legendContainer.width = am4core.percent(100);
      legendContainer.height = am4core.percent(100);
      this.chart.legend = new am4charts.Legend()
      this.chart.legend.parent = legendContainer

      var legendContainer2 = am4core.create(this.$refs.legend2, am4core.Container);
      legendContainer2.width = am4core.percent(100);
      legendContainer2.height = am4core.percent(100);
      this.chart2.legend = new am4charts.Legend()
      this.chart2.legend.parent = legendContainer2
    },
    selectSlice (dataItem) {
      this.selectedSlice = dataItem.slice

      // let fill = this.selectedSlice.fill

      let count = dataItem.dataContext.subData.length
      // this.pieSeries2.colors.list = []
      // for (var i = 0; i < count; i++) {
      //   // this.pieSeries2.colors.list.push(fill.brighten(i * 2 / count))
      //   this.pieSeries2.colors.list.push(am4core.color(dataItem.dataContext.subData[i].color.toLowerCase()))
      // }

      this.chart2.data = dataItem.dataContext.subData
      this.pieSeries2.appear()

      let middleAngle = this.selectedSlice.middleAngle
      let firstAngle = this.pieSeries.slices.getIndex(0).startAngle
      let animation = this.pieSeries.animate([{ property: 'startAngle', to: firstAngle - middleAngle }, { property: 'endAngle', to: firstAngle - middleAngle + 360 }], 600, am4core.ease.sinOut)
      animation.events.on('animationprogress', this.updateLines)

      this.selectedSlice.events.on('transformed', this.updateLines)

    //  var animation = this.chart2.animate({property:'dx', from:-container.pixelWidth / 2, to:0}, 2000, am4core.ease.elasticOut)
    //  animation.events.on('animationprogress', this.updateLines)
    },
    updateLines () {
      if (this.selectedSlice) {
        let p11 = { x: this.selectedSlice.radius * am4core.math.cos(this.selectedSlice.startAngle), y: this.selectedSlice.radius * am4core.math.sin(this.selectedSlice.startAngle) }
        let p12 = { x: this.selectedSlice.radius * am4core.math.cos(this.selectedSlice.startAngle + this.selectedSlice.arc), y: this.selectedSlice.radius * am4core.math.sin(this.selectedSlice.startAngle + this.selectedSlice.arc) }

        p11 = am4core.utils.spritePointToSvg(p11, this.selectedSlice)
        p12 = am4core.utils.spritePointToSvg(p12, this.selectedSlice)

        let p21 = { x: 0, y: -this.pieSeries2.pixelRadius }
        let p22 = { x: 0, y: this.pieSeries2.pixelRadius }

        p21 = am4core.utils.spritePointToSvg(p21, this.pieSeries2)
        p22 = am4core.utils.spritePointToSvg(p22, this.pieSeries2)

        this.line1.x1 = p11.x
        this.line1.x2 = p21.x
        this.line1.y1 = p11.y
        this.line1.y2 = p21.y

        this.line2.x1 = p12.x
        this.line2.x2 = p22.x
        this.line2.y1 = p12.y
        this.line2.y2 = p22.y
      }
    }
  }
}
</script>
