<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th >Main Dealer (MD)<br>Region</th>
          <!-- <th rowspan="2">Pending SIPB</th> -->
          <th >In Transit AHM - MD</th>
          <th >Warehouse Stock</th>
          <!-- <th rowspan="2">Pending DO</th> -->
        </tr>
        <tr>
          <!-- <th>Carried</th>
          <th>Virtual</th>
          <th>On-Hand</th>
          <th>Virtual</th> -->
        </tr>
      </thead>
      <tbody v-if="mdStockData">
        <tr v-for="stock in mdStockData">
          <td style="text-decoration: underline;">{{ stock.region }} - {{stock.name}}</td>
          <!-- <td>{{ numberWithCommas(stock.sipb) }}</td> -->
          <td>{{ numberWithCommas(stock.intransit_ahm_md) }}</td>
          <!-- <td>{{ numberWithCommas(stock.virtual) }}</td> -->
          <td>{{ numberWithCommas(stock.warehouse_stock_onhand) }}</td>
          <!-- <td>{{ numberWithCommas(stock.stockVirtual) }}</td>
          <td>{{ numberWithCommas(stock.pendingDo) }}</td> -->
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import Multiselect  from 'vue-multiselect'

  export default {
    data () {
      return {
        mdStockData : [],
      }
    },
    props:['data'],
    watch : {
      'data' : {
        handler(){
          var data = this.data
          if(!data) return;
          if (data.length > 0 && data[0].region == 'Unknown') {
            data.splice(0, 1);
          }
          this.mdStockData = data;
        },
        deep : true,
        immediate : true
      }
    },
    methods : {
      numberWithCommas(x) {
        return parseInt(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    }
  }
</script>

<style scoped>
  table tr th, table tr td {
    text-align: center;
    vertical-align: middle;
  }

  table tr th {
    border-bottom: 1px solid #F75047;
  }

</style>