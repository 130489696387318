<template>
	<div id="existingIdStackedColumnChart" style="width: 100%; height: 500px"></div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import axios from "axios";

am4core.useTheme(am4themes_animated);

export default {
	name: "StackedColumnChart",
	props: {
		startDate: {
			type: String,
			required: true
		},
		endDate: {
			type: String,
			required: true
		},
		selectedYear: {
			type: [String, Number],
			default: null
		},
		selectedChartDealer: {
			type: Object,
			default: null
		}
	},
	data() {
		return {
			chart: null
		};
	},
	watch: {
		startDate() {
			this.updateChart();
		},
		endDate() {
			this.updateChart();
		},
		selectedYear() {
			this.updateChart();
		},
		selectedChartDealer() {
			this.updateChart();
		}
	},
	mounted() {
		this.createChart();
	},
	beforeDestroy() {
		if (this.chart) {
			this.chart.dispose();
		}
	},
	methods: {
		createChart() {
			this.fetchData().then(chartData => {
				let chart = am4core.create("existingIdStackedColumnChart", am4charts.XYChart);
				chart.data = chartData;

				let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
				categoryAxis.dataFields.category = "month";

				let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
				valueAxis.title.text = "Registrations";

				const colors = ["#f2bc80", "#55b586", "#4e80ed", "#c2b6f6"];

				this.createSeries(chart, "2024", "2024", colors[3]);
				this.createSeries(chart, "2023", "2023", colors[2]);
				this.createSeries(chart, "2022", "2022", colors[1]);
				this.createSeries(chart, "Older", "<=2021", colors[0]);

				chart.cursor = new am4charts.XYCursor();

				// Add a legend
				chart.legend = new am4charts.Legend();

				// Modify global tooltip behavior
				chart.tooltip.pointerOrientation = "vertical";
				chart.tooltip.animationDuration = 150;

				this.chart = chart;
			});
		},
		updateChart() {
			this.fetchData().then(chartData => {
				if (this.chart) {
					this.chart.data = chartData;
					this.chart.invalidateData();
				}
			});
		},
		fetchData() {
			return axios.get("/api/distribution/replacement", {
				params: {
					start: this.startDate,
					end: this.endDate,
					manufacture_year: this.selectedYear,
					dealer: this.selectedChartDealer ? this.selectedChartDealer.loc_code : null
				},
			})
				.then(response => {
					const apiData = response.data.data;
					return this.transformData(apiData);
				})
				.catch(error => {
					console.error("There was an error fetching the API data:", error);
					return [];
				});
		},
		filterDataByYear(data) {
			if (this.selectedYear && this.selectedYear !== 'All') {
				return data.filter(item => item.year_group === this.selectedYear.toString());
			}
			return data;
		},
		transformData(apiData) {
			const chartData = [];
			Object.keys(apiData).forEach((month) => {
				const cleanedMonth = month.replace('2024', '').trim();
				const monthData = apiData[month];
				const transformedData = { month: cleanedMonth };
				monthData.forEach((data) => {
					transformedData[data.year_group] = data.value;
				});
				chartData.push(transformedData);
			});
			return chartData;
		},
		createSeries(chart, field, name, color) {
			let series = chart.series.push(new am4charts.ColumnSeries());
			series.dataFields.valueY = field;
			series.dataFields.categoryX = "month";
			series.name = name;
			series.stacked = true;
			series.columns.template.fill = am4core.color(color);
			series.columns.template.stroke = am4core.color(color);
			series.columns.template.column.cornerRadiusTopLeft = 5;
			series.columns.template.column.cornerRadiusTopRight = 5;
			series.columns.template.column.cornerRadiusBottomLeft = 5;
			series.columns.template.column.cornerRadiusBottomRight = 5;
			series.columns.template.width = am4core.percent(50);

			// Custom tooltip
			let tooltip = series.tooltip;
			tooltip.pointerOrientation = "horizontal";
			tooltip.background.cornerRadius = 5;
			tooltip.background.fill = am4core.color(color);
			tooltip.background.stroke = am4core.color(color).lighten(-0.2);
			tooltip.background.strokeOpacity = 0.8;
			tooltip.label.fill = am4core.color("#ffffff");
			tooltip.label.textAlign = "middle";

			// Tooltip text
			series.tooltipText = "{name}: [bold]{valueY}[/]";

			return series;
		}
	}
};
</script>

<style>
#existingIdStackedColumnChart {
	width: 100%;
	height: 500px;
}
</style>