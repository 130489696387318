<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th>Dealer</th>
          <!-- <th>Invoiced</th>
          <th>SSU</th> -->
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="stock in data">
          <template v-if="stock.sold_dealer">
            <td >{{ stock.sold_dealer }}</td>
            <!-- <td>{{ numberWithCommas(stock.invoice) }}</td>
            <td>{{ numberWithCommas(stock.ssu) }}</td> -->
            <td>{{ numberWithCommas(stock.total) }}</td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import Multiselect  from 'vue-multiselect'

  export default {
    data () {
      return {
      }
    },
    props:['filter','period', 'data'],
    mounted () {

    },
    methods : {
        numberWithCommas(x) {
            return parseInt(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    }
  }
</script>

<style scoped>
  table tr th, table tr td {
    text-align: center;
    vertical-align: middle;
  }

  table tr th {
    border-bottom: 1px solid #F75047;
  }

</style>