<template>
	<div>
		<b-card :title="'Vehicle Type'" header-tag="h5" class="mb-4">
			<hr>
			<b-form>
				<!-- <b-form-row>
					<b-form-group label="Region" class="col-md-12">
						<Multiselect 
							v-if="filterOptions && filterOptions.region"
							class="mt-1"
							:options="filterOptions.region.map(x => x.loc_code)"
							:custom-label="opt => filterOptions.region.find(x => x.loc_code == opt).name"
							v-model="filter.region"
							placeholder="All Regions"
						/>
					</b-form-group>
				</b-form-row> -->
				<!-- <b-form-row>
					<b-form-group label="Search" class="col-md-12 dateCss">
						<input type="text"  v-model="filter.search" class="form-control"/>
					</b-form-group>
				</b-form-row>
				<b-form-row>
					<b-form-group  class="col-md-2 ">
						<button type="button" class="btn btn-primary" @click="loadAPI">Submit</button>
					</b-form-group>
				</b-form-row> -->
			</b-form>
		</b-card>
		<div class="text-right">
			<button class="btn btn-primary" @click="addVehicleType">New Vehicle Type</button>
		</div>
		<div class="card mt-2 mb-4" v-if="keysTable && dataTable">
			
			<table class="table text-center">
				<thead>
					<th v-for="key in keysTable" :key="key">{{prettifyKeys(key)}}</th>
					<th colspan="2"><i class="fa fa-cog"></i></th>
				</thead>
				<tbody>
					<tr v-for="data in dataTable" :key="data.id">
						<td v-for="key in keysTable" :key="key + data.id">{{data[key]}}</td>
						<td><button class="btn btn-outline-primary" @click="editVehicleType(data)"><i class="fa fa-edit"></i></button></td>
						<!-- <td><button class="btn btn-outline-danger" @click="deleteLocation(data.id)"><i class="fa fa-times"></i></button></td> -->
					</tr>
				</tbody>
			</table>
		</div>

		<!-- <vehicleTypeModal 
			v-if="modal.vehicleTypeModal"
			@closeModal="modal.vehicleTypeModal = false"
			:data="modal.data"
			:region="filterOptions.region"
			@refreshData="loadAPI"
		/> -->
		<ModifyVehicleType
			v-if="modal.vehicleTypeModal"
			@closeModal="modal.vehicleTypeModal = false"
			@refreshData="loadAPI"
			:data="modal.data"
		/>

	</div>
</template>

<script>
import vue_universal_table from '../../table/vue_universal_table.vue'
import Multiselect from 'vue-multiselect'
import ModifyVehicleType from './children/modifyVehicleType.vue'

// import vehicleTypeModal from './childComponents/vehicleTypeModal.vue'
export default {
  	components: { 
        vue_universal_table, 
        Multiselect,
    	ModifyVehicleType , 
        // vehicleTypeModal 
    },
	data () {
		return {
			dataTable : null,
			keysTable : null,
			filter : {
				region : null,
				search : null
			},
			filterOptions : null,
			modal : {
				vehicleTypeModal : false,
				data : null
			}
		}
	},
	mounted () {
		this.loadAPI ()
		this.filterOptions = Object.assign({} , this.$store.getters.getFilterOptions)
	},
	methods : {
		addVehicleType () {
			this.modal.data = null
			this.modal.vehicleTypeModal = true
		},
		editVehicleType (data) {
			this.modal.data = data
			this.modal.vehicleTypeModal = true
		},
		// deleteLocation (id) {
		// 	this.$http.delete('/api/locations/delete/' + id)
		// 	.then((resp) => {
		// 		this.$store.dispatch('triggerAlert' , [true , 'Successfully Deleted Location'])
		// 		this.loadAPI()
		// 	})
		// 	.catch((err) => {
		// 		console.log(err)
		// 	})
		// },
		async loadAPI () {
			let filter = await this.processFilter(this.filter)
			this.$http.get('/api/master/type' + filter)
			.then((resp) => {
				let data = resp.data.data
				this.keysTable = Object.keys(data[0])


				for(var i = 0 ; i < data.length ; i++) {
					for(var j = 0 ; j < this.keysTable.length ; j++) {
						if(!(this.keysTable[j] == 'is_active' || this.keysTable[j] == 'H1' || this.keysTable[j] == 'H2' || this.keysTable[j] == 'H3'))
							continue;
						
						if(data[i][this.keysTable[j]]) {
							data[i][this.keysTable[j]] = '✔'
						}
						else {
							data[i][this.keysTable[j]] = '✘'
						}
					}
				}

				this.dataTable = data

			})
			.catch((err) => {
				console.log(err)
			})
		},
		async processFilter (filter) {
			let url = '?'
			let keys = Object.keys(filter)

			for(var i = 0 ; i < keys.length ; i++) {
				let key = keys[i]
				if(filter[key])
					url += `${key}=${filter[key]}&`
			}

			return url
		},
		prettifyKeys (key) {
			if(key.includes('_'))
				key = key.split('_').join(' ')
			
			key = key.charAt(0).toUpperCase() + key.slice(1 , key.length)

			return key
		}
	}
	
}
</script>

<style>
  .dateCss input{
    border: 1px solid #e8e8e8;
    border-radius: 5px !important;
    height: 42px;
    margin-top: 3px;
    padding-left: 1% !important;
  }

  .dateCss input::placeholder{
    padding-left: 1%;
  }

</style>
