<template>
    <div style='min-height:500px' ref='leadtimeboxplot'></div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

import material from '@amcharts/amcharts4/themes/material.js'
import animated from '@amcharts/amcharts4/themes/animated.js'

am4core.useTheme(animated)
am4core.useTheme(material)

export default {
  mounted () {
    this.createChart()
    this.getData()
  },
  data () {
    return {
      chart: null
    }
  },
  watch: {
    '$store.state.updateData': function () {
      this.getData()
    }
  },
  props: ['data'],
  methods: {
    createChart () {
      this.chart = am4core.create(this.$refs.leadtimeboxplot, am4charts.XYChart)
      this.chart.paddingRight = 20

      // this.chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd'

      let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis())
      categoryAxis.dataFields.category = 'bulan'
      categoryAxis.renderer.minGridDistance = 40
      categoryAxis.renderer.grid.template.location = 0

      let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis())
      valueAxis.tooltip.disabled = true
      valueAxis.min = 0;
      valueAxis.title.text = 'Time By Hour (H)'

      // let series = this.chart.series.push(new am4charts.CandlestickSeries())
      // series.dataFields.categoryY = 'bulan'
      // series.dataFields.valueX = 'average'
      // series.dataFields.openValueX = 'average'
      // series.dataFields.lowValueX = 'minimum'
      // series.dataFields.highValueX = 'maximum'
      // series.simplifiedProcessing = true
      // series.tooltipText = 'Min: {minimumstring}\nMax: {maximumstring}\nAvg: {averagestring}'
      // series.tooltip.fontWeight = 'bold'
      // series.riseFromOpenState = undefined
      // series.dropFromOpenState = undefined
      // series.strokeWidth = 2.5
      
      var series = this.chart.series.push(new am4charts.ColumnSeries());
	    series.dataFields.valueY = "averagefloat";
      
      series.dataFields.categoryX = "bulan";
      series.name = "Average";
      series.columns.template.tooltipText = "Average Lead Time {categoryX}: {averagestring}";
      series.columns.template.fillOpacity = .8;

      let series2 = this.chart.series.push(new am4charts.LineSeries());
      series2.dataFields.valueY = "minimumfloat";
      series2.dataFields.categoryX = "bulan";
      series2.strokeWidth = 2;
      series2.name = 'Minimum';
      series2.tooltipText = "Minimum Lead Time {categoryX}: {minimumstring}";
      series2.tensionX = 0.8;
      series2.showOnInit = true;

      let series3 = this.chart.series.push(new am4charts.LineSeries());
      series3.dataFields.valueY = "maximumfloat";
      series3.dataFields.categoryX = "bulan";
      series3.strokeWidth = 2;
      series3.name = 'Maximum';
      series3.tooltipText = "Maximum Lead Time {categoryX}: {maximumstring}";
      series3.tensionX = 0.8;
      series3.showOnInit = true;

      let interfaceColors = new am4core.InterfaceColorSet();

      let bullet = series2.bullets.push(new am4charts.Bullet());
      bullet.width = 12;
      bullet.height = 12;
      bullet.horizontalCenter = "middle";
      bullet.verticalCenter = "middle";
      
      let triangle = bullet.createChild(am4core.Triangle);
      triangle.stroke = interfaceColors.getFor("background");
      triangle.strokeWidth = 2;
      triangle.direction = "top";
      triangle.width = 12;
      triangle.height = 12;

      let interfaceColors2 = new am4core.InterfaceColorSet();

      let bullet2 = series3.bullets.push(new am4charts.Bullet());
      bullet2.width = 12;
      bullet2.height = 12;
      bullet2.horizontalCenter = "middle";
      bullet2.verticalCenter = "middle";
      
      let triangle2 = bullet2.createChild(am4core.Triangle);
      triangle2.stroke = interfaceColors2.getFor("background");
      triangle2.strokeWidth = 2;
      triangle2.direction = "top";
      triangle2.width = 12;
      triangle2.height = 12;

      this.chart.legend = new am4charts.Legend();

      this.chart.cursor = new am4charts.XYCursor()

      // let topSeries = this.chart.series.push(new am4charts.StepLineSeries())
      // topSeries.noRisers = true
      // topSeries.startLocation = 0.2
      // topSeries.endLocation = 0.8
      // topSeries.dataFields.valueX = 'maximum'
      // topSeries.dataFields.categoryY = 'bulan'
      // topSeries.stroke = this.chart.colors.getIndex(0)
      // topSeries.strokeWidth = 3

      // let bottomSeries = this.chart.series.push(new am4charts.StepLineSeries())
      // bottomSeries.noRisers = true
      // bottomSeries.startLocation = 0.2
      // bottomSeries.endLocation = 0.8
      // bottomSeries.dataFields.valueX = 'minimum'
      // bottomSeries.dataFields.categoryY = 'bulan'
      // bottomSeries.stroke = this.chart.colors.getIndex(0)
      // bottomSeries.strokeWidth = 3

      this.chart.scrollbarY = new am4core.Scrollbar()
      // this.this.chart.data = this.data
    },
    getData () {
      let length = this.chart.data.length
      this.chart.addData(this.data, length)
    }
  }
}
</script>
