<template>
  <div id='app'>
    <router-view/>
  </div>
</template>

<style src='@/vendor/styles/bootstrap-material.scss' lang='scss'></style>
<style src='@/vendor/styles/appwork-material.scss' lang='scss'></style>
<style src='@/vendor/styles/theme-air-material.scss' lang='scss'></style>
<style src='@/vendor/styles/colors-material.scss' lang='scss'></style>
<style src='@/vendor/styles/uikit.scss' lang='scss'></style>
<style src='./style.scss' lang='scss'></style>
<style src='@/vendor/libs/vuejs-datepicker/vuejs-datepicker.scss' lang='scss'></style>
<style src='@/vendor/libs/vue-flatpickr-component/vue-flatpickr-component.scss' lang='scss'></style>

<script>
/* eslint-disable */
import moment from 'moment'

export default {
  name: 'app',
  metaInfo: {
    title: 'Index',
    titleTemplate: '%s - ASMO ID Dashboard'
  },
  data () {
    return {
      refCount: 0,
      isLoading: false,
      filterDone: 0
    }
  },
  watch: {

  },
  updated () {
    // Remove loading state
    setTimeout(() => document.body.classList.remove('app-loading'), 1)
  },
  created () {
    var user = this.$store.state.userData.userData

    if(user){
      this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + user.token
      this.$http.defaults.headers.common['userdata'] = user.user_id
    }

    this.$http.interceptors.request.use((config) => {
		  this.setLoading(true)
			return config
		}, (error) => {
			this.setLoading(false)
			return Promise.reject(error)
		})

		this.$http.interceptors.response.use(async (response) => {
      this.setLoading(false)
      // console.log(response.data)
      //if response type is XLSX
      if(response.headers["content-type"] == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
				return response;
			//if response code is 200, continue request
			if(response.data.code == 200)
				return response
			//if response has session / unauthorised problems, logout and delete remaining request
			else if(response.data.code == 13 ){
				await this.logout()
				return new Promise(() => {})
			}
			//if every other error response
			else {
        return Promise.reject(response)
      }
		}, (error) => {
      this.setLoading(false)
      // console.log(error)
      
			return Promise.reject(error)
		})

    
  },
  async mounted () {
    await this.loadFilterData(null , null)
  },
  methods: {
    setLoading (isLoading) {
      if (isLoading) {
        this.refCount++
        // this.$swal({
        //   title: 'Loading.....'
        // })
        // this.$swal.showLoading()
        this.isLoading = true
      } else if (this.refCount > 0) {
        this.refCount--
        // this.$swal.close()
        this.isLoading = (this.refCount > 0)
      }
    },
    logout () {
      this.$store.dispatch('setUser', null)
      
      // delete filter data when log out
      let keys = Object.keys(this.$store.state.filterData)
      for (var i = 0 ; i < keys.length ; i ++) {
        this.$store.state.filterData[keys[i]] = null
      }
      this.$store.dispatch('setFilterOptions' , null)
      this.$store.dispatch('setUser', null)
      this.$router.push({path: '/login'})
      sessionStorage.setItem('sessionExpired' , 'Session has expired. Please Re-Login to the account to Access this Page.')
    }
  }
}
</script>

<style>
   @import url('https://fonts.googleapis.com/css?family=Ubuntu');

  .stats-outer-box{
    padding:20px;
  }

  .stats-inner-box{
    border-radius: 10px;
    background-color: #348FE2;
    height: 85px;
  }

  .stats-inner-box p, .stats-inner-box h2{
    color: white;
  }
  .stats-inner-box p{
    margin:5% 0 0 12.5%;
  }
  .stats-inner-box .stats-value{
    margin-top: 0;
    margin-left:10%;
    font-size: 1.75em;
    font-weight: 500;
  }
  .stats-inner-box .stats-value .stats-value-support{
    font-size: 0.5em;
    font-weight: normal;
  }
  .stats-inner-box h2{
    margin-left:10%
  }
  .stats-inner-box .icon{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .stats-inner-box .icon i{
    color: #00000050;
    font-size: 3em;
  }

  .outer-box{
    padding:20px;
  }
  .inner-box{
    border-radius: 10px;
    background-color: #fff;
    padding: 20px;
    height: 85px;
  }

  table.tableBodyScroll tbody {
    display: block;
    max-height: 400px;
    overflow-y: scroll;
  }

  table.tableBodyScroll thead, table.tableBodyScroll tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
