/*
    Ini List of String Url untuk di load api. Param itu untuk set variable filter dan datanya mw di taro dimana.
    - Marvin
    Teritory Management Not Included 
*/

let stringUrl  = {
    stockOverview : {
        stats : [
            '/stock-summary/all'
        ],
        mainDealerStock : [
            '/main-dealer-stock'
        ],
        dealerStock : [
            '/dealer-stock'
        ]
    },
    stockAnalytics : {
        agingStockAnalysis : [
            '/aging-stock-analysis', 
            '/aging-stock-analysis',
            // '/aging-stock-analysis/end-to-end'
        ],
        stockDays : [
            '/stock-days/maindealer', 
            '/stock-days/dealer',
            // '/stock-days/end-to-end'
        ],
        // averageAgingStock : [
        //     '/average-aging-stock',
        //     '/average-aging-stock'
        // ]
    },
    salesAnalysis : {
        targetVsActual : [
            '/sales-analysis/target-vs-actual'
        ],
        mainDealerSales : [
            '/sales-analysis/maindealer'
        ],
        dealerSales : [
            '/sales-analysis/dealer'
        ]
    },
    mappingSummary : {
        mappingSummary : [
            '/mapping-summary/h1', 
            '/mapping-summary/h2',
            '/mapping-summary/total',
        ]
    },
    serviceAnalysis : {
        targetVsActual : [
            '/total/service-h2',
        ],
        dealerTable : [
            '/service-analysis/top-ten-dealer'
        ]
    },
    lateDeliveryAndShipping : {
        ahmToMd : [
            '/late-delivery-shipping/ahm-to-md'
        ],
        mdToD : [
            '/late-delivery-shipping/md-to-d'
        ],
    }
}

let param  = {
    stockOverview : {
        stats : [
            {
                var: 'stockOverview',
                parent: 'stockDetailsData',
                filter : 'stockDetailsFilter',
                filterVar : 'stockOverview'
            }
        ],
        mainDealerStock : [
            {
                var: 'mainDealerStock',
                parent: 'stockDetailsData',
                filter: 'stockDetailsFilter'
            }
        ],
        dealerStock : [
            {
                var: 'dealerStock',
                parent: 'stockDetailsData',
                filter: 'stockDetailsFilter'
            }
        ]
    },
    stockAnalytics : {
        agingStockAnalysis : [
            {
                var: 'agingStockMD',
                parent: 'stockAnalyticsData',
                filter: 'stockAnalyticsFilter',
                filterVar: 'agingStockOptions'
            }, {
                var: 'agingStockD',
                parent: 'stockAnalyticsData',
                filter: 'stockAnalyticsFilter',
                filterVar: 'agingStockOptions'
            }
            // , {
            //     var: 'agingStockEnd',
            //     parent: 'stockAnalyticsData',
            //     filter: 'stockAnalyticsFilter',
            //     filterVar: 'agingStockOptions'
            // }
        ],
        stockDays : [
            {
                var: 'stockDaysMD',
                parent: 'stockAnalyticsData',
                filter: 'stockAnalyticsFilter',
                filterVar: 'stockDaysOptions'
            }, {
                var: 'stockDaysD',
                parent: 'stockAnalyticsData',
                filter: 'stockAnalyticsFilter',
                filterVar: 'stockDaysOptions'
            } 
            // ,{
            //     var: 'stockDaysEnd',
            //     parent: 'stockAnalyticsData',
            //     filter: 'stockAnalyticsFilter',
            //     filterVar: 'stockDaysOptions'
            // }
        ],
        averageAgingStock : [
            {
                var: 'averageAgingMD',
                parent: 'stockAnalyticsData',
                filter: 'stockAnalyticsFilter',
                filterVar: 'averageAgingStockOptions'
            }, {
                var: 'averageAgingD',
                parent: 'stockAnalyticsData',
                filter: 'stockAnalyticsFilter',
                filterVar: 'averageAgingStockOptions'
            }
        ]
    },
    salesAnalysis : {
        targetVsActual : [
            {
                var: 'salesAnalysisTargetVsActual',
                parent: 'salesAnalysisData',
                filter: 'salesAnalysisFilter'
            }
        ],
        mainDealerSales : [
            {
                var: 'salesTableMD',
                parent: 'salesAnalysisData',
                filter: 'salesAnalysisFilter',
                filterVar: 'salesTableOptions'
            }
        ],
        dealerSales : [
            {
                var: 'salesTableKaresidenan',
                parent: 'salesAnalysisData',
                filter: 'salesAnalysisFilter',
                filterVar: 'salesTableOptions'
            }
        ]
    },
    mappingSummary : {
        mappingSummary : [
            {
                var: 'mappingH1',
                parent: 'mappingSummaryData',
                filter : 'mappingSummaryFilter',
                filterVar : 'mappingSummary'
            }, {
                var: 'mappingH2',
                parent: 'mappingSummaryData',
                filter : 'mappingSummaryFilter',
                filterVar : 'mappingSummary'
            }, {
                var: 'mappingTotal',
                parent: 'mappingSummaryData',
                filter : 'mappingSummaryFilter',
                filterVar : 'mappingSummary'
            }
        ]
    },
    serviceAnalysis : {
        targetVsActual : [
            {
                var: 'serviceAnalysis',
                parent: 'serviceAnalysisData',
                filter: 'serviceAnalysisFilter'
            }
        ],
        dealerTable : [
            {
                var: 'serviceAnalysisDealer',
                parent: 'serviceAnalysisData',
                filter: 'serviceAnalysisFilter'
            }
        ]
    },
    lateDeliveryAndShipping : {
        ahmToMd : [
            {
                var: 'ahmToMd',
                parent: 'lateDeliveryAndShippingData',
                filter: 'lateDeliveryAndShippingFilter'
            }
        ],
        mdToD : [
            {
                var: 'mdToD',
                parent: 'lateDeliveryAndShippingData',
                filter: 'lateDeliveryAndShippingFilter',
                filterVar : 'mdToD'
            }
        ],
    },
}

module.exports = {stringUrl , param}