<template>
  <div>
    <!--Filter Option-->
    <b-card title="Average Days Of Stock" :sub-title="infoPopup.title" header-tag="h5" class="mb-4">
      <hr>
      <b-form>
          <b-form-row>
              <b-form-group label="Region" class="col-md-3">
              <Multiselect 
                  v-if="filterData"
                  class="mt-1"
                  :options="filterData.region"
                  label="name"
                  track-by="loc_code"
                  :multiple="true"
                  v-model="filter.region"
                  placeholder="All Regions"
                  :close-on-select="false"
              />
              </b-form-group>
              <b-form-group label="Branch" class="col-md-3">
              <Multiselect 
                  v-if="filterData"
                  class="mt-1"
                  :options="filterData.branch"
                  label="name"
                  track-by="loc_code"
                  :multiple="true"
                  v-model="filter.branch"
                  placeholder="All Branches"
                  :close-on-select="false"
              />
              </b-form-group>
              <b-form-group label="Month" class="col-md-3" style="height:42px" >
              <Multiselect 
                  class="mt-1"
                  :options="filterData.month"
                  v-model="filter.month"
                  label="name"
                  track-by="value"
                  :close-on-select="false"
              />
              </b-form-group>
              <b-form-group label="Year" class="col-md-3" style="height:42px" >
              <Multiselect 
                  class="mt-1"
                  :options="filterData.year"
                  v-model="filter.year"
                  :close-on-select="false"
              />
              </b-form-group>
          </b-form-row>
          <b-form-row>
          <b-form-group label="Segment" class="col-md-3">
           <Multiselect 
            class="mt-1"
            v-if="filterData"
            :options="filterData.segment"
            label="segment"
            track-by="segment"
            :multiple="true"
            v-model="filter.segment"
            placeholder="All Segments"
            :close-on-select="false"
           />
          </b-form-group>
          <b-form-group label="Series" class="col-md-3">
           <Multiselect 
            class="mt-1"
            v-if="filterData"
            :options="filterData.series"
            label="series"
            track-by="series"
            :multiple="true"
            v-model="filter.series"
            placeholder="All Series"
            :close-on-select="false"
           />
          </b-form-group>
          <b-form-group label="Type" class="col-md-3">
           <Multiselect 
            class="mt-1"
            v-if="filterData"
            :options="filterData.type"
            label="type"
            track-by="type"
            :multiple="true"
            v-model="filter.type"
            placeholder="All Vehicle Types"
            :close-on-select="false"
           />
          </b-form-group>
          <b-form-group label="Colour" class="col-md-3">
           <Multiselect 
            class="mt-1"
            v-if="filterData"
            :options="filterData.color"
            v-model="filter.colour"
            label="name"
            track-by="color_code"
            :multiple="true"
            placeholder="All Colours"
            :close-on-select="false"
           />
          </b-form-group>
        </b-form-row>
          <b-form-row>
            <b-form-group class='col-md-1'>
              <b-btn type='button' variant='primary' @click="search(0)">Filter</b-btn>
            </b-form-group>
          </b-form-row>
      </b-form>
    </b-card>
    <!--Stats-->
    <div class='row'>
       <div class='col-md-12 col-lg-12 col-xl-12'>
         <!--Average Days Of Stock By Region-->
        <b-card no-body>
            <div class='col-md-12 col-lg-12 col-xl-12 align-items-center p-4'>
              <h5>Average Days Of Stock By Location
                <span class="col-md-1 col-sm-1 col-2">
                    <i class="fa fa-info-circle fa" id="averageDaysOfStockByLocation"></i>
                    <b-popover
                    target="averageDaysOfStockByLocation"
                    title="Average Days Of Stock By Location"
                    triggers="hover focus"
                    placement="top"
                    :content="infoPopup.averageDaysOfStockByLocation"
                    ></b-popover>
                </span>
              </h5>
              <div class='d-flex row'><ByRegion class='w-100' v-if="averageDaysStock" :data="averageDaysStock" /></div>
            </div>
        </b-card>
      </div>
      <!--end-->
      <!--Average Days of Stock By Series-->
      <div class='col-md-12 col-lg-12 col-xl-12 mt-4'>
        <b-card no-body>
            <div class='col-md-12 col-lg-12 col-xl-12 align-items-center p-4'>
              <h5>Average Days Of Stock By Segment and Series
                <span class="col-md-1 col-sm-1 col-2">
                    <i class="fa fa-info-circle fa" id="averageDaysOfStockBySegmentAndSeries"></i>
                    <b-popover
                    target="averageDaysOfStockBySegmentAndSeries"
                    title="Average Days Of Stock By Segment and Series"
                    triggers="hover focus"
                    placement="top"
                    :content="infoPopup.averageDaysOfStockBySegmentAndSeries"
                    ></b-popover>
                </span>
              </h5>
              <div class='d-flex row'><BySegment @getSeries="getSeries" class='w-100' v-if="averageSegmentSeries" :data="averageSegmentSeries" /></div>
            </div>
        </b-card>
      </div>
      <!--Average Days of Stock By Type-->
      <div class='col-md-12 col-lg-12 col-xl-12 mt-4' v-if="averageTypeColour">
        <b-card no-body>
            <div class='col-md-12 col-lg-12 col-xl-12 align-items-center p-4'>
              <h5>Average Days Of Stock By Types and Colours
                <span class="col-md-1 col-sm-1 col-2">
                    <i class="fa fa-info-circle fa" id="averageDaysOfStockByTypesAndColour"></i>
                    <b-popover
                    target="averageDaysOfStockByTypesAndColour"
                    title="Average Days Of Stock By Types and Colours"
                    triggers="hover focus"
                    placement="top"
                    :content="infoPopup.averageDaysOfStockByTypesAndColour"
                    ></b-popover>
                </span>
              </h5>
              <div class='d-flex row'><ByTypes class='w-100'  :data="averageTypeColour" /></div>
            </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
// Library
import Multiselect  from 'vue-multiselect'
import moment from 'moment'
//  charts
import ByRegion from './childComponents/ByRegion'
import BySegment from './childComponents/BySegment'
import ByTypes from './childComponents/ByTypes.vue'
export default {
  name: 'stock-intransit',
  metaInfo: {
    title: 'Average Days of Stock'
  },
  methods: {
    
  },
  components: {
    Multiselect ,
    //  charts
    ByRegion,
    BySegment,
    ByTypes
  },
  data() {
    return {
      // Filter Variables
      filterData: this.$store.getters.getFilterOptions,
      filter: {
        region: [],
        branch: [],
        segment: [],
        series: [],
        type: [],
        colour: [],
        month:  {
          name: new moment().format("MMMM"),
          value: new moment().format("M")
        },
        year:  new moment().format("YYYY")
      },
      // API Variables
      averageDaysStock: null,
      averageSegmentSeries: null,
      averageTypeColour: null,
      selectSeries: 0,

      infoPopup: this.$store.state.hoverInformation.h1.stockDetails.averageDaysOfStock
    }
  },
  mounted () {
      this.search(0)
    },
    watch: {
    'filter.region' : function() {
      this.loadFilter('region')
    },
    'filter.segment' : function () {
      this.loadFilter('segment')
    },
    'filter.series' : function () {
      this.loadFilter('series')
    }
  },
    methods: {
        // API Load Functions
        loadTypesColours () {
          this.averageTypeColour = null
          let url = '/api/h1/average-days-of-stock/by-types-colours' + this.$store.state.filterUrl

          this.$http.get(url)
          .then((resp) => {
            this.averageTypeColour = resp.data.data
          })
          .then(()=>{
            window.scrollTo(0, document.body.scrollHeight);
          })
          .catch((err) => {
            console.log(err)
          })
        },
        loadAverageDaysStock () {
          this.averageDaysStock = null
          let url = '/api/h1/average-days-of-stock/by-region' + this.$store.state.filterUrl

          this.$http.get(url)
          .then((resp) => {
            this.averageDaysStock = resp.data.data
          })
          .catch((err) => {
            console.log(err)
          })
        },
        loadVehicleTypes () {
          this.averageSegmentSeries = null
          let url = '/api/h1/average-days-of-stock/by-segment-series' + this.$store.state.filterUrl

          this.$http.get(url)
          .then((resp) => {
            this.averageSegmentSeries = resp.data.data
          })
          .catch((err) => {
            console.log(err)
          })
        },
        // Filter Search Trigger
        async search(flag) {
          let filters = await this.transformFilter(this.filter)
          this.$store.state.filterUrl = await this.initializeFilter(filters)
          if (this.filter.series.length > 0 && flag == 1) {
            this.averageSegmentSeries = null
            this.loadTypesColours()
            this.loadAverageDaysStock()
          }
          else {
            
            if(this.filter.series.length > 0){
              this.loadTypesColours()
            }
            else {
              this.averageTypeColour = null
              this.loadVehicleTypes()
              this.loadAverageDaysStock()
            }
          }
        },
        getSeries (data, segment) {
          let series = data
          this.filter.series.length = 0
          this.selectSeries = 1
          this.filter.series.push({
            series: series
          })
          this.filter.segment.length = 0
          this.filter.segment.push({
            segment: segment
          })
          this.search(0)
        },
        // Filter Settings
        async initializeFilter (filters) {
          let filterUrl = '?'
          let keys = Object.keys(filters)
          for (var i = 0; i < keys.length; i++) {
            if(typeof filters[keys[i]] == 'object' && filters[keys[i]].length > 0)
              filterUrl += `${keys[i]}=${filters[keys[i]]}&`
            else if (typeof filters[keys[i]] != 'object') {
              filterUrl += `${keys[i]}=${filters[keys[i]]}&`
            }
          }
          
          return filterUrl
        },
        async transformFilter (filter) {
          let obj = {}
          let keys = Object.keys(filter)
          console.log(filter)
          for (var i = 0 ; i < keys.length; i++) {
            if (keys[i] != 'month' && keys[i] != 'year') {
              obj[keys[i]] = []
              for (var j = 0; j < filter[keys[i]].length; j++) {
                keys[i] == 'region' || keys[i] == 'branch' ? obj[keys[i]].push(filter[keys[i]][j].loc_code) : obj[keys[i]].push(filter[keys[i]][j][keys[i].toLowerCase()])
              }
            }
            else if (keys[i] == 'month') {
              obj[keys[i]] = filter[keys[i]].value
            }
            else {
              obj[keys[i]] = filter[keys[i]]
            }
          }
          return obj
        },
        loadFilter(type){
          if (type == 'region'){
            this.filter.branch.length = 0;
            this.filterData.branch = this.$store.getters.getBranch(this.filter.region)
          } else if (type == 'segment'){
            if(this.selectSeries != 1){
              this.filter.series.length = 0;
              this.selectSeries = 0
            }
            this.filterData.series = this.$store.getters.getSeries(this.filter.segment)

            this.filter.type.length = 0;
            this.filterData.type = this.$store.getters.getType(this.filter.segment, this.filter.series)
          } else if (type == 'series'){
            this.filter.type.length = 0;
            this.filterData.type = this.$store.getters.getType(this.filter.segment, this.filter.series)
          } else {
            this.filterData = this.$store.getters.getfilterData
          } 
        },
    }
}

</script>
