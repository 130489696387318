<template>
    <div ref="QRRegistrationChart" style="height: 400px;"></div>
</template>

<script>
    /* Imports */
    import * as am4core from "@amcharts/amcharts4/core";
    import * as am4charts from "@amcharts/amcharts4/charts";
    import am4themes_animated from "@amcharts/amcharts4/themes/animated";

    export default {
        data () {
            return {
                chart : null,
            }
        },
        props : ['data'],
        mounted () {
            this.createChart()
            this.addData()
        },
        watch : {
            data : {
                deep : true,
                handler : 'addData',
            },
        },
        methods : {
            addData(){
                var length = this.chart.data.length
                var data = [{bulan:'No Data', h1:1, h2:1}]
                if(this.data && this.data.length > 0){
                    data.length = 0
                    for(var i in this.data)
                    data.push({
                        bulan : `${this.data[i].bulan.slice(0,3)} ${this.data[i].tahun}`,
                        h1 : this.data[i].h1,
                        h2 : this.data[i].h2
                    })
                }
                this.chart.addData(data, length)
            },
            createChart(){
                /* Chart code */
                // Themes begin
                am4core.useTheme(am4themes_animated);
                // Themes end

                // Create chart instance
                this.chart = am4core.create(this.$refs.QRRegistrationChart, am4charts.XYChart);

                // Create axes
                let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
                categoryAxis.dataFields.category = "bulan";
                categoryAxis.renderer.grid.template.location = 0;


                let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
                valueAxis.renderer.inside = true;
                valueAxis.renderer.labels.template.disabled = true;
                valueAxis.min = 0; 

                this.chart.cursor = new am4charts.XYCursor();

                this.createSeries("h1", "H1");
                this.createSeries("h2", "H2");

                // Legend
                this.chart.legend = new am4charts.Legend();
            },
            createSeries(field, name) {
                
                // Set up series
                let series = this.chart.series.push(new am4charts.ColumnSeries());
                series.name = name;
                series.dataFields.valueY = field;
                series.dataFields.categoryX = "bulan";
                series.sequencedInterpolation = true;
                
                // Make it stacked
                series.stacked = true;
                
                // Configure columns
                series.columns.template.width = am4core.percent(60);
                series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";
                
                // Add label
                let labelBullet = series.bullets.push(new am4charts.LabelBullet());
                labelBullet.label.text = "{valueY}";
                labelBullet.locationY = 0.5;
                labelBullet.label.hideOversized = true;
                
                return series;
            }
        }
    }
</script>

<style scoped>

</style>