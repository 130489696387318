
<script>/* eslint-disable */</script>
<template>
	<div ref="ByDayChart" style="min-height:500px"></div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly.js";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import axios from 'axios'
am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_kelly);

export default{
  name : 'CustomerHour',
  props : ['data', 'type'],
  data(){
    return {
      company : sessionStorage.getItem('company'),
      chart : null,
      indicator : null,
      isUpdates : this.isUpdate,
      flag : false //Prevent Duplication Showing Loading Indicator
    }
  },
  mounted(){
	this.createMChart();
	this.getData()
  },
  methods : {
    createMChart(){
      this.chart = am4core.create(this.$refs.ByDayChart, am4charts.XYChart);
      this.chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

      this.chart.dateFormatter.dateFormat = "dd MMMM";

      let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.minGridDistance = 80;
      dateAxis.dateFormats.setKey("date", "dd MMM");
      dateAxis.periodChangeDateFormats.setKey("date", "dd MMM");

      var valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = "Quantity";
      valueAxis.title.rotation = 0;
      valueAxis.title.align = "center";
      this.xAxis = true;
      valueAxis.maxPrecision = 0;
      valueAxis.min = 0;

      // Create series
      var series = this.chart.series.push(new am4charts.ColumnSeries());
	  series.dataFields.valueY = "count";
      
      series.dataFields.dateX = "date";
      series.name = "Rate";
      series.columns.template.tooltipText = `Number of ${ this.capitalize(this.type) } Vehicle on {dateX}: [bold]{valueY}[/]`;
      series.columns.template.fillOpacity = .8;

      var labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = "[bold]{valueY}";
      labelBullet.label.dy = -15;



      this.chart.legend = new am4charts.Legend();

      var columnTemplate = series.columns.template;
      columnTemplate.strokeWidth = 2;
      columnTemplate.strokeOpacity = 1;

    },
    getData(){
	  let length  = this.chart.data.length
	  this.chart.addData(this.data , length)
    }
  }
}
</script>
