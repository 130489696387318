<template>
  <div>
    <b-card header="Service Order" header-tag="h5" class="mb-4">
      <b-form>
        <b-form-row>
          <b-form-group label="Month" class="col-md-3">
            <b-select class="custom-select mr-sm-2 mb-2 mb-sm-0" value="January" :options="['January', 'February', 'March']" />
          </b-form-group>
          <b-form-group label="Year" class="col-md-3">
            <b-select class="custom-select mr-sm-2 mb-2 mb-sm-0" value="2020" :options="['2020', '2019', '2018']" />
          </b-form-group>
          <b-form-group label="Warehouse Location" class="col-md-5">
            <b-select class="custom-select mr-sm-2 mb-2 mb-sm-0" value="All" :options="['All','Semarang','Denpasar','NTB','Yogyakarta','Palembang']" />
          </b-form-group>
          <b-form-group class="col-md-2">
            <b-btn type="submit" variant="primary">Filter</b-btn>
          </b-form-group>
        </b-form-row>
      </b-form>
    </b-card>

    <div class="inner-box">
      <h6>Vehicle Type Serviced</h6>
      <vehicle-type-serviced></vehicle-type-serviced>
    </div>

    <div class="inner-box">
      <h6>Vehicle Service Type</h6>
      <vehicle-service-type></vehicle-service-type>
    </div>

    <div class="inner-box">
      <h6>Revenue</h6>
      <revenue></revenue>
    </div>
  </div>
</template>

<script>
import VehicleTypeServiced from './childComponents/VehicleTypeServiced'
import VehicleServiceType from './childComponents/VehicleServiceType'
import Revenue from './childComponents/Revenue'

export default {
  name: 'service-order',
  metaInfo: {
    title: 'Service Order'
  },
  components: {
    VehicleTypeServiced,
    VehicleServiceType,
    Revenue
  },
  data: () => ({

  }),
  mounted () {

  }
}
</script>

<style scoped>
  .inner-box{
    border-radius: 5px;
    background-color: #fff;
    padding: 20px;
    height: 100%;
    margin-bottom: 25px;
  }
</style>
