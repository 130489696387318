<template>
  <sidenav :orientation="orientation" :class="curClasses">

    <!-- Brand demo (see src/demo.css) -->
    <div class="app-brand demo" v-if="orientation !== 'horizontal'">
      <router-link to="/" class="app-brand-text demo sidenav-text font-weight-normal ml-2">ASMO ID</router-link>
      <a href="javascript:void(0)" class="layout-sidenav-toggle sidenav-link text-large ml-auto" style="padding-left:4rem" @click="toggleSidenav()">
        <i class="ion ion-md-menu align-middle"></i>
      </a>
    </div>
    <div class="sidenav-divider mt-0" v-if="orientation !== 'horizontal'"></div>
    <!-- Inner -->
    <div class="sidenav-inner demo" :class="{ 'py-1': orientation !== 'horizontal' }">

      <template v-for="data in modules">

        <!-- NO CHILDREN 1 -->
        <sidenav-router-link
          v-if="!data.children"
          :icon="'fa ' + data.icon"
          :to="data.link"
          :exact="true"
        >{{ data.name }}</sidenav-router-link>

        <!-- CHILDREN 1 -->
        <sidenav-menu :icon="'fa ' + data.icon" :active="isMenuActive('/h1')" :open="isMenuOpen('/h1')" v-else>
          <template slot="link-text">{{ data.name }}</template>

          <template v-for="dat in data.children">
            <!-- NO CHILDREN 2 -->
            <sidenav-router-link
              v-if="!dat.children"
              :to="dat.link"
              :exact="true"
            >{{ dat.name }}</sidenav-router-link>

            <!-- CHILDREN 2 -->
            <sidenav-menu v-else>
              <template slot="link-text">{{ dat.name }}</template>
              <sidenav-router-link
                v-for="da in dat.children"
                :to="da.link"
                :exact="true"
              >{{ da.name }}</sidenav-router-link>
            </sidenav-menu>
          </template>
  
          <!-- Sales Start -->
        </sidenav-menu>
      </template>

    </div>
  </sidenav>
</template>

<script>
import { Sidenav, SidenavLink, SidenavRouterLink, SidenavMenu, SidenavHeader, SidenavBlock, SidenavDivider } from '@/vendor/libs/sidenav'

export default {
  name: 'app-layout-sidenav',
  data(){
    return {
      modules : [],
    }
  },
  components: {
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider
    /* eslint-enable vue/no-unused-components */
  },

  props: {
    orientation: {
      type: String,
      default: 'vertical'
    }
  },
  mounted(){
    this.getModules();
  },

  computed: {
    curClasses () {
      let bg = this.layoutSidenavBg

      if (this.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
        bg = bg
          .replace(' sidenav-dark', '')
          .replace(' sidenav-light', '')
          .replace('-darker', '')
          .replace('-dark', '')
      }

      return `bg-${bg} ` + (
        this.orientation !== 'horizontal'
          ? 'layout-sidenav'
          : 'layout-sidenav-horizontal container-p-x flex-grow-0'
      )
    }
  },

  methods: {
    isMenuActive (url) {
      return this.$route.path.indexOf(url) === 0
    },

    isMenuOpen (url) {
      return this.$route.path.indexOf(url) === 0 && this.orientation !== 'horizontal'
    },

    toggleSidenav () {
      this.layoutHelpers.toggleCollapsed()
    },

    getModules () {
      let rawModules = JSON.parse(JSON.stringify(this.$store.state.modules))
      let modules = JSON.parse(JSON.stringify(rawModules))
      let userAccess = this.$store.getters.currentUser;

      if(userAccess.role != "SUPER ADMIN" && userAccess.role != "ADMIN"){

        let i = 0;
        while(modules[i]){
          if(!modules[i].children){
            if (userAccess.modules.map(x=>parseInt(x)).indexOf(modules[i].id) == -1) modules.splice(i, 1);
            else i++;
          } else {
            let j = 0;
            while(modules[i].children[j]){
              if (userAccess.modules.map(x=>parseInt(x)).indexOf(modules[i].children[j].id) == -1) modules[i].children.splice(j, 1);
              else j++;
            }
            if(modules[i].children.length == 0) modules.splice(i, 1)
            else i++;
          }
        }
      }

      this.modules = modules;
    }
  }
}
</script>

<style>
  .sidenav-item {
    padding-left: 5px;
    padding-right: 5px;
  }

  .sidenav-link {
    /* padding-left: 4rem !important; */
    color: black !important;
  }

  .router-link-exact-active a {
    color: white !important;
    font-weight: bold;
    border-radius: 10px;
    background-color: #ff1b30 !important;
  }
</style>
