import moment from 'moment'
import XLSX from 'xlsx'
import qs from 'qs'

var methods={
  capitalize(s){
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  },
  errorPrint(errorMessage){//PRINTS ERROR IN RED COLOR
      var error = "%c" + errorMessage;
      console.log(error, 'color:#ff0000')
  },
  printTest(){//PRINTS THE WORD 'TEST'
      console.log("test");
  },
  isRTL () {
    return document.documentElement.getAttribute('dir') === 'rtl' || document.body.getAttribute('dir') === 'rtl'
  },
  numberWithCommas(x) {
    return parseInt(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  randomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
  },
  isMobile(){
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      return true;
    } else {
      return false;
    }
  },
  toCapitalizedWords(name){
    var words = name.match(/[A-Za-z][a-z]*/g) || [];
    return words.map(this.capitalize).join(" ");
  },
  exportExcel(data, title) {
    let wb = XLSX.utils.book_new()
    let exportFileName = title ? `${title}.xlsx` : 'Exported Data.xlsx'
    var ws = XLSX.utils.json_to_sheet(data, {raw:true});
    XLSX.utils.book_append_sheet(wb, ws, 'Data')
    XLSX.writeFile(wb, exportFileName)
  },
  exportCsv(data, title) {
    let wb = XLSX.utils.book_new()
    let exportFileName = title ? `${title}.csv` : 'Exported Data.csv'
    var ws = XLSX.utils.json_to_sheet(data, {raw:true});
    XLSX.utils.book_append_sheet(wb, ws, 'Data')
    XLSX.writeFile(wb, exportFileName)
  },

  //Load Filter Data
  async loadFilterData (filterList , filterListOption) {
    filterList = filterList ? filterList : ['month', 'year', 'regions', 'branch', 'segment', 'series', 'type', 'colour']
    filterListOption = filterListOption ? filterListOption : ['month', 'year', 'region', 'branch', 'segment', 'series', 'type', 'color']

    for (var i = 0 ; i < filterList.length; i++) {
      // if(!this.$store.state.filterData[filterList[i]] || this.$store.state.filterOptions[filterListOption[i]] == null){
        if (filterList[i] == 'month') {
          let month = []
          let monthName = ['January' , 'February' , 'March' , 'April', 'May', 'June' , 'July', 'August', 'September', 'October' , 'November', 'December']
          for (var j = 0 ; j < 12; j++) {
              month.push({
                value: j + 1,
                name: monthName[j]
              })
          }

          this.$store.dispatch('setMonth', month);
          this.$store.state.filterData.month = month;
        } else if (filterList[i] == 'year') {
          let year = []
          for (var j = 2018 ; j <= new Date().getFullYear(); j++) {
              year.push(j)
          }

          this.$store.dispatch('setYear', year);
          this.$store.state.filterData.year = year;
        } else {
          await this.$http.get(`/api/master/${filterList[i]}`)
          .then((resp) => {
            let data = resp.data.data;
  
            if(filterList[i] == 'regions' || filterList[i] == 'branch')
            {
              for (var j = 0 ; j < data.length ; j ++) {
                data[j].name = filterList[i] == 'branch' ? `${data[j].loc_code} - ${data[j].name} - ${data[j].type_dealer}`  : `${data[j].loc_code} - ${data[j].name}` 
              }
            }
            else if ( filterList[i] == 'colour') {
              for (var j = 0 ; j < data.length ; j ++) {
                data[j].name = `${data[j].color_code} - ${data[j].color_desc}` 
              }
            }
  
            this.$store.dispatch(`set${this.capitalize(filterListOption[i])}`, data)
            this.$store.state.filterData[filterList[i]] = data
          })
          .catch((err) => {
            console.log(err)
          })
        }//ELSE ENDS
      // }//IF ENDS
    }//FOR LOOP ENDS

    // console.log(JSON.stringify(this.$store.getters.getColor))
  },
  loadModules(){
    this.$http.get('/api/master/modules')
    .then((resp)=>{
      let obj = []
      for (var i = 0 ; i < resp.data.data.length ; i++) {
        let data = resp.data.data[i]
        
        if(data.parent_id) {
          let parent = resp.data.data.find(x => x.id == data.parent_id)
          !parent.children ? parent.children = [data] : parent.children.push(data)  
        }
        else obj.push(data)

        delete data.parent_id
        delete data.parent_name
        !data.link ? delete data.link : '';
        !data.icon ? delete data.icon : '';
      }
      
      this.$store.state.modules = obj;
    })
  },

  downloadQrRegistration (filter, title, isRealTime) {
    let keys = Object.keys(filter)
    
    for(var i = 0 ; i < keys.length ; i++) {
      if(!Array.isArray(filter[keys[i]]))
        continue
      else if(filter[keys[i]].length == 0)
        delete filter[keys[i]]
    }

    console.log(filter)

    this.$http.get(`/api/h1/gi-qr-registration/mapping-download?${isRealTime ? 'page=real time&' : ''}${qs.stringify(filter, {skipNulls:true, arrayFormat: 'comma'})}`)
    .then((resp)=>{
        this.exportExcel(resp.data.data, `${title} (${moment(filter.start).format("DD MMM YYYY")} - ${moment(filter.end).format("DD MMM YYYY")})`);
    })
    .catch((err)=>{console.log(err)})
},
}

export default methods;