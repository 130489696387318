<template>
	<transition name="modal" >
		<div class="modal-mask">
			<div class="modal-wrapper" @click="$emit('closeModal')">
				<div class="row">
					<div class="col-xs-0 col-sm-0 col-md-3 col-lg-3"></div>
					<div class="col-xs-12 col-sm-12 col-md-7 col-lg-7">
						<div class="modal-container" @click.stop>

							<div class="modal-header" style="justify-content: flex-end;">
								<button type="button" class="close" @click="$emit('closeModal')">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							
							<div class="modal-body" style="overflow-y:scroll;height:75vh !important">
								<div class="row mb-3">
									<h4 class="col-md-3 col-sm-4 col-12 Aligner-Header ">H2 Daily Details</h4>
									<button @click="downloadExcelFile()" class="offset-md-7 offset-sm-4 col-md-2 col-sm-4 col-12 btn btn-success w-100" style="max-height:46px"><span class="fa fa-file-excel"/> Export</button>
								</div>
								<div class="w-100"  style="overflow-x:scroll">
									<table class="table text-center">
									<thead>
										<tr style="background-color:black;color:white">
											<th>Date</th>
											<th>Pendaftaran QR</th>
                      <th>Ganti QR</th>
                      <th>Konsumen Baru</th>
                      <th>QR Lama</th>
                      <th>Total QR Code</th>
                      <!-- <th>H1 to H2</th>
                      <th>H2 to H2</th>
                      <th>H1 Service</th>
                      <th>H2 Service</th>
                      <th>Total Service</th> -->
                      <th>H1 to H2 Own</th>
                      <th>H2 to H2 Own</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(data, index) in data" :key="index">
											<td>{{data.date}}</td>
											<td>{{numberWithCommas(data.qr_register)}}</td>
                      <!-- <td>{{numberWithCommas(parseInt(data.qr_replacement) + parseInt(data.new_id))}}</td> -->
                      <td>{{numberWithCommas(data.qr_replacement)}}</td>
                      <td>{{numberWithCommas(data.new_id)}}</td>
                      <td>{{numberWithCommas(data.existing_id)}}</td>
                      <td>{{numberWithCommas(data.totalQRCode)}}</td>
                      <!-- <td>{{numberWithCommas(data.h1_h2)}}</td>
                      <td>{{numberWithCommas(data.h2_h2)}}</td>
                      <td>{{numberWithCommas(data.h1_service)}}</td>
                      <td>{{numberWithCommas(data.h2_service)}}</td>
                      <td>{{numberWithCommas(data.service)}}</td> -->
                      <td>{{numberWithCommas(data.h12_h2)}}</td>
                      <td>{{numberWithCommas(data.h2_h2_own)}}</td>
										</tr>
									</tbody>
								</table>
								</div>
							</div>
						</div>
					</div>
					<!-- <div class="col-xs-0 col-sm-0 col-md-3 col-lg-3"></div> -->
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
// Library
import moment from 'moment'
export default {
	props:['data', 'period'],
	data () {
		return {
			moment: moment,
		}
	},
	methods: {
		downloadExcelFile () {
            this.$http.get('/api/reports/h2-report/daily-detail' + this.period + '&exportExcel=1' , {responseType : 'blob'})
            .then((resp)=>{
                const blob = new Blob([resp.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                let url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = "H1 report daily";
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((err)=>{
                console.log(err);
            })
		}
	}
}
</script>

<style scoped>
	.Aligner-Header {
        padding-left:3% !important;
        color: #707070;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
  .modal-mask {
      position: fixed;
      z-index: 1020;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .5);
      display: table;
      transition: opacity .3s ease;
  }

  .modal-wrapper {
      display: table-cell;
      vertical-align: middle;
	  padding-top:10vh;
  }

  .modal-container {
      width: 100%;
      margin: 0px auto;
      padding: 20px 30px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
      transition: all .3s ease;
      font-family: Helvetica, Arial, sans-serif;
  }

  .modal-header h3 {
      margin-top: 0;
      color: #42b983;
  }

  .modal-body {
      margin: 20px 0;
  }

  .modal-default-button {
      float: right;
  }

  /*
  * The following styles are auto-applied to elements with
  * transition="modal" when their visibility is toggled
  * by Vue.js.
  *
  * You can easily play with the modal transition by editing
  * these styles.
  */

  .modal-enter {
      opacity: 0;
  }

  .modal-leave-active {
      opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
  }

  /* MODAL STARTS */
  .modal-body{
      margin:0
  }

  .modal-body .moduleLine{
      margin-bottom: 0vh;
  }

  .modal-body .moduleLine a{
      text-decoration: none;
  }

  .modal-body .moduleLine .moduleBox:hover{
      background-color: #E0E0E0;
      transition: background-color 0.7s ease;
      color: #212121;
      transition: color 0.7s ease;
  }

  .modal-body .moduleLine .moduleBox{
      border:1px solid #BDBDBD;
      border-radius: 20px;
      padding: 20px 0;
      color:black;
      margin-bottom: 3vh;
  }

  .modal-body .moduleLine .moduleBox i{
      font-size:5vh;
  }

  h3.modalHeadline{
      margin:0 0 20px 0;
      font-weight: bold;
      text-align : center;
  }
  /* MODAL ENDS */

  .button_outer_box{
    padding:30px;
  }
  .button_inner_box{
    border:1px solid #E0E0E0;
    border-radius:15px;
    box-shadow:5px 5px 15px 1px;
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    position: relative; /* If you want text inside of it */
  }
  .button_inner_box:hover{
    cursor: pointer;
  }

  .button_text{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .slightlyGray{
    color:#424242
  }

  .hoverBig{
    -webkit-animation: hoverBig 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: hoverBig 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }
  .unHoverBig{
    -webkit-animation: unHoverBig 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: unHoverBig 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }
  @-webkit-keyframes hoverBig {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
  }
  @keyframes hoverBig {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
  }
  @-webkit-keyframes unHoverBig {
    0% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes unHoverBig {
    0% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
	h5 {
		font-weight: bold;
	}
	p {
		padding-left: 10px;
		font-size: 15px;
	}
	td {
		font-size: 15px;
	}
</style>