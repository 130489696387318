<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th>Region</th>
          <th>Target</th>
          <th>Average Duration</th>
          <th>No. of Rit</th>
          <th>In-Transit (Rit)</th>
          <th>Arrived (Rit)</th>
          <th>Leadtime Histogram</th>
          <!-- <th>1-3 Days</th>
          <th>4-6 Days</th>
          <th>7-9 Days</th>
          <th>>10 Days</th> -->
        </tr>
      </thead>
      <tbody>
        <template v-if="!data">
          <td colspan="100%" class="text-center">Loading...</td>
        </template>
        <template v-else-if="data.length == 0">
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <!-- <td>-</td>
          <td>-</td>
          <td>-</td> -->
        </template>
        <template v-else>
          <tr v-for="dat in ((showAll) ? data : data.slice(0, 2))">
            <td>{{ dat.region }} - {{ dat.name }}</td>
            <td>{{ dat.target }} Day{{(parseFloat(dat.target) > 1) ? 's' : ''}}</td>
            <td>{{ dat.avg }} Day{{(parseFloat(dat.avg) > 1) ? 's' : ''}}</td>
            <td>{{ dat.total }}</td>
            <td>{{ dat.intransit }}</td>
            <td>{{ dat.arrived }}</td>
            <td>
              <microBarChart
                :data="dat.histogram"
              ></microBarChart>
            </td>
            <!-- <td>{{ dat.day_1_3 }}%</td>
            <td>{{ dat.day_4_7 }}%</td>
            <td>{{ dat.day_7_9 }}%</td>
            <td>{{ dat.day_10 }}%</td> -->
          </tr>
          <tr v-if="showAllButton">
            <td v-if="!showAll" colspan="9" class="moreButton" @click="showAll = true">Lihat Semua</td>
            <td v-else colspan="9" class="moreButton" @click="showAll = false">Sembunyikan</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
  import microBarChart from './microBarChart'

  export default {
    data () {
      return {
        showAllButton : false,
        showAll : true,
        // data : [],
      }
    },
    props:['filter','period', 'data'],
    components : {
      microBarChart,
    },
    mounted () {

    },
    watch : {
      'period' : function () {
        // this.fetchData();
      },
      // 'filter' : {
      //   deep : true,
      //   immediate : true,
      //   handler : 'fetchData',
      // },
      'data' : {
        deep : true,
        immediate : true,
        handler : function (){
          if (!this.data) return;

          if (this.data.length == 0) {
            this.showAllButton = false;
          } else if (this.data.length > 2){
            this.showAllButton = true;
            this.showAll = false;
          }
        }
      }
    },
    methods : {
      getData () {
        if (this.data != null) this.data = data;
      },
      fetchData () {
        var regions = ['H100 - Jakarta', 'H200 - Singapore', 'H300 - Kuala Lumpur']
        var data = []
        for (var i = 0; i < regions.length; i++) {
          var target = this.randomInt(0, 15000);
          var hit = this.randomInt(0, target);
          data.push({
            region : regions[i],
            target : this.randomInt(1, 3),
            averageDuration : (Math.random() * (3 - 1) + 1).toFixed(1),
            noOfRit : this.randomInt(0, 100),
            inTransit : this.randomInt(0, 50),
            arrived : this.randomInt(0, 60),
            days_1_3 : this.randomInt(0, 5),
            days_4_6 : this.randomInt(0, 5),
            days_7_9 : this.randomInt(0, 5),
            days_10 : this.randomInt(0, 5)
          })
        }
        this.data = data;
      },
    }
  }
</script>

<style scoped>
  table tr th, table tr td {
    text-align: center;
    vertical-align: middle;
  }

  table tr th {
    border-bottom: 1px solid #F75047;
  }

</style>