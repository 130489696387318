<template>
    <div style="min-height:70px" ref="microBarChart"></div>
</template>

<script>
    import * as am4core from "@amcharts/amcharts4/core";
    import * as am4charts from "@amcharts/amcharts4/charts";
    import am4themes_animated from "@amcharts/amcharts4/themes/animated";

    export default {
        data () {
            return {
                chart : null,
                translations : {
                    "day_1_3" : "1 - 3",
                    "day_4_7" : "4 - 7",
                    "day_7_9" : "7 - 9",
                    "day_10_12" : "10 - 12",
                    "day_13_15" : "13 - 15",
                    "day_16_18" : "16 - 18",
                    "day_19_21" : "19 - 21",
                    "day_22_25" : "22 - 25",
                    "day_26_29" : "26 - 29",
                    "day_30" : "30+"
                }
            }
        },
        props : ['data'],
        mounted () {
            this.createChart();
            this.getData();
        },
        watch : {
            'data' : {
                handler : 'getData'
            }
        },
        methods : {
            getData () {
                let length = this.chart.data.length;
                let data = JSON.parse(JSON.stringify(this.data));

                if (data && data.length > 0) {
                    for (var i in this.data) {
                        let splits = data[i].x_axis.split("_")

                        if (splits.length == 3) {
                            data[i].days_range = `${splits[1]} - ${splits[2]}`
                        } else if (splits.length == 2) {
                            if (i == this.data.length-1) {
                                data[i].days_range = `≥ ${splits[1]}`
                            } else {
                                data[i].days_range = `${splits[1]}`
                            }
                        }
                        // data[i].days_range = this.translations[data[i].x_axis];
                    }
                } else {
                    data = [{days_range:'No Data', rit_qty:0}]
                }

                this.chart.addData(data, length);
            },
            createChart () {
                // Create chart instance
                this.chart = am4core.create(this.$refs.microBarChart, am4charts.XYChart);

                // Create axes
                let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
                categoryAxis.dataFields.category = "days_range";
                categoryAxis.renderer.grid.template.location = 0;
                categoryAxis.renderer.minGridDistance = 30;

                let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
                valueAxis.min = 0;

                // Create series
                let series = this.chart.series.push(new am4charts.ColumnSeries());
                series.dataFields.valueY = "rit_qty";
                series.dataFields.categoryX = "days_range";
                series.name = "rit_qty";
                series.columns.template.tooltipText = "Day {categoryX}: [bold]{valueY}[/]";
                series.columns.template.fillOpacity = .8;

                let columnTemplate = series.columns.template;
                columnTemplate.strokeWidth = 2;
                columnTemplate.strokeOpacity = 1;
            },
        }
    }
</script>

<style scoped>

</style>