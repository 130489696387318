/* eslint-disable */
import Vue from "vue";
import Router from "vue-router";
import Meta from "vue-meta";
import NotFound from "@/components/NotFound";

import globals from "@/globals";

// Layouts
import Layout1 from "@/layout/Layout1";

Vue.use(Router);
Vue.use(Meta);

const router = new Router({
    base: "/",
    mode: "history",
    routes: [
        {
            path: "/",
            redirect: "/login",
            meta: {
                auth: false,
            },
        },
        {
            path: "/login",
            name: "Login",
            meta: {
                auth: false,
            },
            component: () => import("@/components/Login/Login"),
            // },{
            //   path: '//dashboard/',
            //   component: Layout1,
            //   meta: {
            //     auth: true
            //   }
        },
        {
            path: "/profile",
            name: "profilePage",
            meta: {
                auth: true,
            },
            component: Layout1,
        },
        {
            // welcome
            path: "/welcome",
            name: "Welcome",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            // hightlights
            path: "/highlight",
            name: "Highlight",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            // hightlights
            path: "/warehouse-mapping-overview",
            name: "WarehouseMappingOverview",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            // highlight warehouse
            path: "/highlight-warehouse",
            name: "HighlightWarehouse",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            // H1 Menus
            path: "/h1/real-time",
            name: "RealTimeQRRegistration",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/h1/qr-registered",
            name: "QRRegistered",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/h1/incoming-vehicle",
            name: "MonthQRRegistration",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/h1/replaced-qr",
            name: "ReplacedQrH1",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/h1/warehouse-behavior/incoming",
            name: "IncomingWarehouseBehaviour",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/h1/warehouse-behavior/outgoing",
            name: "OutgoingWarehouseBehaviour",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/h1/stock-availability",
            name: "StockAvailability",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/h1/stock-intransit",
            name: "StockIntransit",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/h1/avg-stock",
            name: "AverageDaysOfStock",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/h1/aging-stock",
            name: "AgingStock",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/h1/lead-time",
            name: "LeadTime",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/h1/sales",
            name: "Sales",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/h1/gr-md",
            name: "GRMD",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/h1/gi-md",
            name: "GIMD",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/h1/gr-d",
            name: "GRD",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/h1/retail-sales",
            name: "RetailSales",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/h1/customer-location",
            name: "CustomerLocation",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/h1/deviation",
            name: "Deviation",
            component: Layout1,
            meta: {
                auth: true,
            },
        }, // H2 Menus
        {
            path: "/h2/qr-registration",
            name: "QrRegistration",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/h2/replaced-qr",
            name: "ReplacedQr",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/h2/change-ownership",
            name: "ChangeOwnership",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/h2/service-order",
            name: "ServiceOrder",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/h2/service-cycle",
            name: "ServiceCycle",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/h2/workshop-traffic",
            name: "WorkshopTraffic",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/h2/service-duration",
            name: "ServiceDuration",
            component: Layout1,
            meta: {
                auth: true,
            },
        }, // Reports
        {
            path: "/report/summary-qr",
            name: "SummaryQRRegistration",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/report/summary-log",
            name: "SummaryLog",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/report/summary-document-data",
            name: "SummaryDocumentData",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/report/vehicle-search",
            name: "VehicleSearch",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/report/h1",
            name: "H1Reports",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/report/h2",
            name: "H2Reports",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/report/h2-dealer",
            name: "H2DealerReport",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/report/qrcode",
            name: "QRCode",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/report/change-ownership",
            name: "ChangeOwnershipReport",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/report/customer",
            name: "Customer",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/report/qrcode-replacement",
            name: "QrCodeReplacement",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/report/log-report",
            name: "LogReport",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/report/log-report/:id",
            name: "LogDetailReport",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/report/error-report",
            name: "ErrorReport",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/report/tracking",
            name: "TrackingReport",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/report/stock",
            name: "StockReport",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/report/lead-time",
            name: "LeadTimeReport",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/report/download-report",
            name: "DownloadReport",
            component: Layout1,
            meta: {
                auth: true,
            },
        }, // Distribution Report
        {
            path: "/distribution-report/qr-registration",
            name: "QRegistration",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/distribution-report/replacement",
            name: "Replacement",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/distribution-report/h2-report-visit",
            name: "H2ReportVisit",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/h2-detail-visit/:engineNo",
            name: "H2DetailVisit",
            component: Layout1,
            meta: {
                auth: true,
            },
        }, 
        {
            path: "/h2-visit-journey/:engineNo",
            name: "H2VisitJourney",
            component: Layout1,
            meta: {
                auth: true,
            },
        }, // Settings
        {
            path: "/setting/user-management",
            name: "UserManagement",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/setting/target",
            name: "Target",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        ///setting/locations
        {
            path: "/setting/locations",
            name: "Locations",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/setting/vehicle-type",
            name: "VehicleType",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            path: "/setting/color",
            name: "Color",
            component: Layout1,
            meta: {
                auth: true,
            },
        },
        {
            // 404 Not Found page
            path: "/*",
            component: NotFound,
        },
    ],
});

router.afterEach(() => {
    // Remove initial splash screen
    const splashScreen = document.querySelector(".app-splash-screen");
    if (splashScreen) {
        splashScreen.style.opacity = 0;
        setTimeout(
            () =>
                splashScreen &&
                splashScreen.parentNode.removeChild(splashScreen),
            300
        );
    }

    // On small screens collapse sidenav
    if (
        window.layoutHelpers &&
        window.layoutHelpers.isSmallScreen() &&
        !window.layoutHelpers.isCollapsed()
    ) {
        setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10);
    }

    // Scroll to top of the page
    globals().scrollTop(0, 0);
});

router.beforeEach((to, from, next) => {
    // Set loading state
    document.body.classList.add("app-loading");

    // Add tiny timeout to finish page transition
    setTimeout(() => next(), 10);
});

export default router;
