<script>/* eslint-disable */</script>
<template>
  <div>
    <!-- Filter Starts -->
    <!--Filter Option-->
    <b-card title="Regional Sales" :sub-title="infoPopup.title" header-tag="h5" class="mb-4">
      <hr>
      <b-form>
        <b-form-row>
          <b-form-group label="Start Date" class="col-md-6 dateCss" style="height:42px" >
            <datepicker name="start" id="start" :format="customFormatter" bootstrap-styling v-model="filter.start"></datepicker>
          </b-form-group>
          <b-form-group label="End Date" class="col-md-6 dateCss" style="height:42px" >
            <datepicker name="end" id="end" :format="customFormatter" bootstrap-styling v-model="filter.end"></datepicker>
          </b-form-group>
        </b-form-row>
        <b-form-row class="mt-4">
          <b-form-group label="Segment" class="col-md-3">
           <Multiselect
            :close-on-select="false" 
            class="mt-1"
            v-if="filterData"
            :options="filterData.segment"
            label="segment"
            track-by="segment"
            :multiple="true"
            v-model="filter.segment"
            placeholder="All Segments"
           />
          </b-form-group>
          <b-form-group label="Series" class="col-md-3">
           <Multiselect 
            :close-on-select="false"
            class="mt-1"
            v-if="filterData"
            :options="filterData.series"
            label="series"
            track-by="series"
            :multiple="true"
            v-model="filter.series"
            placeholder="All Series"
           />
          </b-form-group>
          <b-form-group label="Type" class="col-md-3">
           <Multiselect 
            :close-on-select="false"
            class="mt-1"
            v-if="filterData"
            :options="filterData.type"
            label="type"
            track-by="type"
            :multiple="true"
            v-model="filter.type"
            placeholder="All Vehicle Types"
           />
          </b-form-group>
          <b-form-group label="Colour" class="col-md-3">
           <Multiselect 
            :close-on-select="false"
            class="mt-1"
            v-if="filterData"
            :options="filterData.color"
            v-model="filter.colour"
            label="name"
            track-by="color_code"
            :multiple="true"
            placeholder="All Colours"
           />
          </b-form-group>
        </b-form-row>
        <b-form-row>
          <b-form-group class="col-md-1">
            <b-btn type="button" variant="primary" @click="search(0)">Filter</b-btn>
          </b-form-group>
        </b-form-row>
      </b-form>
    </b-card>
    <!-- Filter Ends -->
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 stats-outer-box">
        <div class="stats-inner-box">
          <div class="row" style="height: 100%">
              <div class="col-8">
                <p>Total Sales
                  <span class="col-md-1 col-sm-1 col-2">
                      <i class="fa fa-info-circle fa" id="totalSales"></i>
                      <b-popover
                      target="totalSales"
                      title="Total Sales"
                      triggers="hover focus"
                      placement="top"
                      :content="infoPopup.stats.totalSales"
                      ></b-popover>
                  </span>
                </p>
                <p class="stats-value" v-if="salesStats">
                  {{numberWithCommas(salesStats.total)}}
                </p>
                <p class="stats-value" v-else>
                  Loading.....
                </p>
                <!-- <h2>2,000,000</h2> -->
              </div>
              <div class="col-4 icon">
                <i class="fas fa-motorcycle"></i>
              </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 stats-outer-box">
        <div class="stats-inner-box" style="background-color:#49B6D6">
            <div class="row" style="height: 100%">
                <div class="col-8">
                  <p>Best Performing Region
                    <span class="col-md-1 col-sm-1 col-2">
                        <i class="fa fa-info-circle fa" id="bestPerformingRegion"></i>
                        <b-popover
                        target="bestPerformingRegion"
                        title="Best Performing Region"
                        triggers="hover focus"
                        placement="top"
                        :content="infoPopup.stats.bestPerformingRegion"
                        ></b-popover>
                    </span>
                  </p>
                  <p class="stats-value" v-if="salesStats">
                    {{salesStats.best_region.region}}
                    <span class="stats-value-support">({{numberWithCommas(salesStats.best_region.sold)}} sold)</span>
                  </p>
                  <p class="stats-value" v-else>
                    Loading.....
                    <span class="stats-value-support">(Loading.... sold)</span>
                  </p>
                </div>
                <div class="col-4 icon">
                  <i class="fas fa-store"></i>
                </div>
            </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 stats-outer-box">
        <div class="stats-inner-box" style="background-color: #FF5B57">
            <div class="row" style="height: 100%">
                <div class="col-8">
                  <p>Best Product Series
                    <span class="col-md-1 col-sm-1 col-2">
                        <i class="fa fa-info-circle fa" id="bestProductSeries"></i>
                        <b-popover
                        target="bestProductSeries"
                        title="Best Product Series"
                        triggers="hover focus"
                        placement="top"
                        :content="infoPopup.stats.bestProductSeries"
                        ></b-popover>
                    </span>
                  </p>
                  <p class="stats-value" v-if="salesStats">
                    {{salesStats.best_series.series ? salesStats.best_series.series : 'Unknown'}}
                    <span class="stats-value-support">({{numberWithCommas(salesStats.best_series.sold)}} sold)</span>
                  </p>
                  <p class="stats-value" v-else>
                    Loading.....
                    <span class="stats-value-support">(Loading.... sold)</span>
                  </p>
                </div>
                <div class="col-4 icon">
                  <i class="fas fa-motorcycle"></i>
                </div>
            </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 outer-box">
        <div class="inner-box" style="height: 100%">
          <h2 style="text-align: center; font-weight: 700">Sales By Region
            <span class="col-md-1 col-sm-1 col-2">
              <i class="fa fa-info-circle fa" id="top10SalesByRegion"></i>
              <b-popover
              target="top10SalesByRegion"
              title="Sales By Region"
              triggers="hover focus"
              placement="top"
              :content="infoPopup.top10SalesByRegion"
              ></b-popover>
            </span>
          </h2>
          <sales-by-region v-if="topTenSalesRegion" :data="topTenSalesRegion"></sales-by-region>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 outer-box">
        <div class="inner-box" style="height: 565px; overflow: auto;">
          <h3 style="text-align: center; font-weight: 700">Sales By Region
            <span class="col-md-1 col-sm-1 col-2">
              <i class="fa fa-info-circle fa" id="salesByRegion"></i>
              <b-popover
              target="salesByRegion"
              title="Sales By Region"
              triggers="hover focus"
              placement="top"
              :content="infoPopup.salesByRegion"
              ></b-popover>
            </span>
          </h3>
          <table class="table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Region</th>
                <th>Region Name</th>
                <!-- <th>Target</th>
                <th>Percentage</th> -->
                <th>Qty</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(salesData, index) in salesByRegionData" :key="salesData.region">
                <td>{{index + 1}}</td>
                <td>{{salesData.region}}</td>
                <td>{{searchRegionName(salesData.region)}}</td>
                <!-- <td>{{salesData.target}}</td>
                <td :style="{'color': salesData.qty > salesData.target ? 'green': 'red'}">
                  {{salesData.target == 0 ? '--' : Math.round(salesData.qty / salesData.target * 100) - 100 }} <i :class="{'fas fa-caret-up' : (salesData.qty >= salesData.target), 'fas fa-caret-down' : (salesData.qty < salesData.target)}"></i>
                </td> -->
                <td :style="{'color': salesData.qty >= salesData.target ? 'green': 'red'}">{{numberWithCommas(salesData.qty)}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Segment Series -->
      <div class='col-md-12 col-lg-12 col-xl-12 mt-4' v-if="bySegmentSeries">
        <b-card no-body>
            <div class='col-md-12 col-lg-12 col-xl-12 align-items-center p-4'>
              <h5>Sales By Segment and Series
                <span class="col-md-1 col-sm-1 col-2">
                  <i class="fa fa-info-circle fa" id="salesBySegmentAndSeries"></i>
                  <b-popover
                  target="salesBySegmentAndSeries"
                  title="Sales By Segment and Series"
                  triggers="hover focus"
                  placement="top"
                  :content="infoPopup.salesBySegmentAndSeries"
                  ></b-popover>
                </span>
              </h5>
              <div class='d-flex row'><BySegment @getSeries="getSeries" class='w-100'  :data="bySegmentSeries" /></div>
            </div>
        </b-card>
      </div>
      <!--Type and Colours-->
      <div class='col-md-12 col-lg-12 col-xl-12 mt-4' v-if="byTypesColours">
        <b-card no-body>
            <div class='col-md-12 col-lg-12 col-xl-12 align-items-center p-4'>
              <h5>Sales By Types and Colours
                <span class="col-md-1 col-sm-1 col-2">
                  <i class="fa fa-info-circle fa" id="salesByTypesAndColour"></i>
                  <b-popover
                  target="salesByTypesAndColour"
                  title="Sales By Types and Colours"
                  triggers="hover focus"
                  placement="top"
                  :content="infoPopup.salesByTypesAndColour"
                  ></b-popover>
                </span>
              </h5>
              <div class='d-flex row'><ByTypes class='w-100'  :data="byTypesColours" /></div>
            </div>
        </b-card>
      </div>
      <!-- end -->

      <!-- Start Region Sales Table -->
      <div class="col-12 mt-4 ">
        <b-card no-body style="padding:20px">
          <div class="row">
            <div class="col-md-4 col-12 mt-2">
              <h5 class="ml-3">Regional Sales Table</h5>
            </div>
            <div class="offset-md-2 col-md-4 col-12">
              <input class="form-control" type="text" placeholder="Search Engine Number" v-model="engine_number"/>
            </div>
            <div class="col-md-2 col-12">
              <button type="button" class="btn btn-info" @click="downloadRegionalSalesTable">Download CSV</button>
            </div>
          </div>
          
          <vue-universal-table 
            api_url="/api/h1/regional-sales/table"
            :infinite_scroll="true"
            :filter="transformFilterComputed"
            :update="update"
          />
        </b-card>
      </div>
      <!-- End Region Sales Table -->
  </div>
</template>

<script>
import Multiselect  from 'vue-multiselect'
import Moment from 'moment'
import Datepicker from 'vuejs-datepicker';
import debounce from 'debounce';
import qs from 'qs';

// Charts
import SalesByRegion from './childComponents/SalesByRegion'
import BySegment from './childComponents/BySegment'
import ByTypes from './childComponents/ByTypes'

export default {
  name: 'regionl-sales',
  metaInfo: {
    title: 'Regional Sales'
  },
  components: {
    // Library
    Datepicker,
    Multiselect ,
    // Components
    SalesByRegion,
    BySegment,
    ByTypes
  },
  data () {

    const startOfMonth = Moment().startOf('month').toDate();
    const endOfMonth   = Moment().toDate()

    return {
      //filter data
      filterData: null,
      filter: {
        start : startOfMonth,
        end : endOfMonth,
        region: [],
        branch: [],
        segment: [],
        series: [],
        type: [],
        colour: []
      },
      prevFilter : {
        start : startOfMonth,
        end : endOfMonth,
      },
      // api data
      salesStats: null,
      topTenSalesRegion: null,
      salesByRegionData: null,
      topTenSalesSeries: null,
      bySegmentSeries: null,
      byTypesColours: null,
      infoPopup: this.$store.state.hoverInformation.h1.sales.regionalSales,
      selectSeries: 0,
      update : 0,
      engine_number : ''
    }
  },
  mounted () {
    this.loadFilter()
    this.search(0)()
  },
  computed : {
    transformFilterComputed : function () {
      
      let keys = Object.keys(this.filter)
      let filter = Object.assign({} , this.filter)

      for(var i = 0 ; i < keys.length ; i++) {
        // Array
        if(Array.isArray(filter[keys[i]]) && filter[keys[i]].length > 0){
          if(keys[i] == 'colour')
            filter[keys[i]] = filter[keys[i]].map(x => x.color_code).join(',')

          else {
            filter[keys[i]] = filter[keys[i]].map(x => x[keys[i]]).join(',')
          }

        }
      }
      if (this.engine_number) filter.engine_no = this.engine_number
      return filter
    }
  },
  watch: {
    'filter.region' : function() {
      this.loadFilter('region')
    },
    'filter.segment' : function () {
      this.loadFilter('segment')
    },
    'filter.series' : function () {
      this.loadFilter('series')
    },
    'filter.start' : {handler : 'checkDateFilter'},
    'filter.end' : {handler : 'checkDateFilter'},
    engine_number : debounce(function (newVal) {
      this.update++
    } , 2000)
  },
  methods: {
    customFormatter : function(date){
			return Moment(date).format('Y-MM-DD');
		},
    numberWithCommas(x) {
      return parseInt(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    searchRegionName (region) {
      let regionName = ''

      for (var i = 0 ; i < this.filterData.region.length; i++) {
        if (this.filterData.region[i].loc_code == region) {
          regionName = this.filterData.region[i].name.split(" - ")[1]
        }
      }
      return regionName
    },
    checkDateFilter () {
      if (this.prevFilter.start == this.filter.start &&
          this.prevFilter.end == this.filter.end) return;

      let start = Moment(this.filter.start);
      let end = Moment(this.filter.end);

      let allowed = true;

      if (end.diff(start, 'seconds') < 0) {
        this.$swal.fire({
          icon: 'error',
          title : 'Error',
          text: 'Start date cannot be greater than end date!'
        })
        allowed = false;
      }
      // else if (end.diff(start, 'days') > 30) {
      //   this.$swal.fire({
      //     icon: 'error',
      //     title : 'Error',
      //     text: 'Date cannot be more than 31 days!'
      //   })
      //   allowed = false;
      // }

      if (allowed) {
        this.prevFilter.start = this.filter.start;
        this.prevFilter.end = this.filter.end;
      } else {
        this.filter.start = this.prevFilter.start;
        this.filter.end = this.prevFilter.end;
      }
    },
    downloadRegionalSalesTable() {
      this.$http.get(`/api/h1/regional-sales/download${qs.stringify(this.transformFilterComputed, {skipNulls:true, addQueryPrefix:true})}`)
      .then((resp)=>{
        this.$swal.fire({
          title: 'Report Submitted',
          text: "Regional sales document request has been successfully submitted",
          icon: 'success',
          showCancelButton: true,
          confirmButtonColor: '#3085D6',
          cancelButtonColor: '#D33',
          confirmButtonText: 'To Download Page',
          cancelButtonText: 'Ok'
        }).then((result) => {
          console.log(result);
          if (result.value) {
            console.log('confirmed');
            this.$router.push(`/report/download-report/`)
          }
        })
      })
      .catch((err)=>{console.log(err)})
    },
    // API Stats
    regionSalesStats () {
      let url = "/api/h1/regional-sales" + this.$store.state.filterUrl
      this.salesStats = null

      this.$http.get(url)
      .then((resp) => {
        this.salesStats = resp.data.data
      })
      .catch((err) => {
        console.log(err)
      })
    },
    // Top 10 Sales Region Chart
    topTenByRegion () {
      let url = "/api/h1/regional-sales/top-ten-by-region" + this.$store.state.filterUrl
      this.topTenSalesRegion = null

      this.$http.get(url)
      .then((resp) => {
        this.topTenSalesRegion = resp.data.data
      })
      .catch((err) => {
        console.log(err)
      })
    },
    // Top 10 Sales Series Chart
    topTenBySeries () {
      let url = "/api/h1/regional-sales/top-ten-by-series" + this.$store.state.filterUrl
      this.topTenSalesSeries = null

      this.$http.get(url)
      .then((resp) => {
        this.topTenSalesSeries = resp.data.data
      })
      .catch((err) => {
        console.log(err)
      })
    },
    // Sales Region Table
    salesByRegion () {
      let url = "/api/h1/regional-sales/by-region" + this.$store.state.filterUrl
      this.salesByRegionData = null

      this.$http.get(url)
      .then((resp) => {
        this.salesByRegionData = resp.data.data
      })
      .catch((err) => {
        console.log(err)
      })
    },
    async bySegmentSeriesAPI () {
      let url = "/api/h1/regional-sales/by-segment-series" + this.$store.state.filterUrl
      this.bySegmentSeries = null

      this.$http.get(url)
      .then((resp) => {
        this.bySegmentSeries = resp.data.data
      })
      .catch((err) => {
        console.log(err)
      })
    },
    async byTypesColoursAPI () {
      let url = "/api/h1/regional-sales/by-types-colours" + this.$store.state.filterUrl
      this.byTypesColours = null

      this.$http.get(url)
      .then((resp) => {
        this.byTypesColours = resp.data.data
      })
      .catch((err) => {
        console.log(err)
      })
    },
    // Filter Settings Start
    async initializeFilter (filters) {
      let filterUrl = '?'
      let keys = Object.keys(filters)
      for (var i = 0; i < keys.length; i++) {
        if(typeof filters[keys[i]] == 'object'  && filters[keys[i]].length > 0)
          filterUrl += `${keys[i]}=${filters[keys[i]]}&`
        else if( filters[keys[i]] != null && filters[keys[i]] != ''){
          filterUrl += `${keys[i]}=${filters[keys[i]]}&`
        }
      }
      
      return filterUrl
    },
    async transformFilter (filter) {
      let obj = {}
      let keys = Object.keys(filter)
      for (var i = 0 ; i < keys.length; i++) {
        if (keys[i] != 'start' && keys[i] != 'end') {
          obj[keys[i]] = []
          for (var j = 0; j < filter[keys[i]].length; j++) {
            keys[i] == 'region' || keys[i] == 'branch' ? obj[keys[i]].push(filter[keys[i]][j].loc_code) : obj[keys[i]].push(filter[keys[i]][j][keys[i].toLowerCase()])
          }
        }
        else  {
          obj[keys[i]] = Moment(filter[keys[i]]).format("YYYY-MM-DD")
        }
      }
      return obj
    },
    async search (flag) {
      this.update++;
      let filters = await this.transformFilter(this.filter)
      this.$store.state.filterUrl = await this.initializeFilter(filters)
      if (this.filter.series.length > 0 && flag == 1) {
        this.bySegmentSeries = null
        this.byTypesColoursAPI()
        this.salesByRegion()
        this.regionSalesStats()
        this.topTenByRegion()
      }
      else {
        console.log(this.filter.series)
        if(this.filter.series.length > 0){
          this.byTypesColoursAPI()
        }
        else {
          this.byTypesColours = null
          this.bySegmentSeriesAPI()
          this.salesByRegion()
          this.regionSalesStats()
          this.topTenByRegion()
        }
      }
    },
    getSeries (data, segment) {
      let series = data
      this.filter.series.length = 0
      this.selectSeries = 1
      this.filter.series.push({
        series: series
      })
      this.filter.segment.length = 0
      this.filter.segment.push({
        segment: segment
      })
      this.search(0)
    },
    loadFilter (type) {
      if (type == 'region'){
        this.filter.branch.length = 0;
        this.filterData.branch = this.$store.getters.getBranch(this.filter.region)
      } else if (type == 'segment'){
        if(this.selectSeries != 1){
          this.filter.series.length = 0;
          this.selectSeries = 0
        }
       
        this.filterData.series = this.$store.getters.getSeries(this.filter.segment)

        this.filter.type.length = 0;
        this.filterData.type = this.$store.getters.getType(this.filter.segment, this.filter.series)
      } else if (type == 'series'){
        this.filter.type.length = 0;
        this.filterData.type = this.$store.getters.getType(this.filter.segment, this.filter.series)
      } else {
        this.filterData = this.$store.getters.getFilterOptions
      } 
    }
    // Filter Settings End
  }
}

function isRTL () {
  return document.documentElement.getAttribute('dir') === 'rtl' || document.body.getAttribute('dir') === 'rtl'
}
</script>

<style scoped>

  .stats-outer-box{
    padding:20px;
  }

  .stats-inner-box{
    border-radius: 10px;
    background-color: #348FE2;
    height: 85px;
  }

  .stats-inner-box p, .stats-inner-box h2{
    color: white;
  }
  .stats-inner-box p{
    margin:5% 0 0 12.5%;
  }
  .stats-inner-box .stats-value{
    margin-top: 0;
    margin-left:10%;
    font-size: 2em;
    font-weight: 500;
  }
  .stats-inner-box .stats-value .stats-value-support{
    font-size: 0.5em;
    font-weight: normal;
  }
  .stats-inner-box h2{
    margin-left:10%
  }
  .stats-inner-box .icon{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .stats-inner-box .icon i{
    color: #00000050;
    font-size: 3em;
  }

  .outer-box{
    padding:20px;
  }
  .inner-box{
    border-radius: 10px;
    background-color: #fff;
    padding: 20px;
    height: 85px;
  }
</style>

<style>
  .dateCss div input{
    border: 1px solid #e8e8e8;
    border-radius: 5px !important;
    height: 42px;
    margin-top: 3px;
    padding-left: 1% !important;
  }

  .dateCss div input::placeholder{
    padding-left: 1%;
  }

</style>
