import { render, staticRenderFns } from "./QRRegistrationControl.vue?vue&type=template&id=40d45850&scoped=true"
import script from "./QRRegistrationControl.vue?vue&type=script&lang=js"
export * from "./QRRegistrationControl.vue?vue&type=script&lang=js"
import style0 from "./QRRegistrationControl.vue?vue&type=style&index=0&id=40d45850&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40d45850",
  null
  
)

export default component.exports