<template>
  <div v-if="dataReady">
    <div class="row">
      <div class="col">
        <button type="button" class="btn btn-sm btn-primary ml-4" @click="$router.go(-1)">< Back</button>
      </div>
    </div>

    <!-- STATS STARTS -->
    <div class="row">
      <div :class="{'outerBox' : !mobile, 'outerBoxM' : mobile}">
        <div class="col innerBox row">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div class="form-group row h-100">
              <label class="col-sm-3 col-form-label stats-label verticalCenter">Log ID</label>
              <div class="col-sm-9 verticalCenter">
                <p class="stats-value">{{logInfo.log_id}}</p>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div class="form-group row h-100">
              <label class="col-sm-3 col-form-label stats-label verticalCenter">Executed At</label>
              <div class="col-sm-9 verticalCenter">
                <p class="stats-value">{{new moment(logInfo.created_at).format("YYYY-MM-DD HH:mm:ss")}}</p>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div class="form-group row h-100">
              <label class="col-sm-3 col-form-label stats-label verticalCenter">API Type</label>
              <div class="col-sm-9 verticalCenter">
                <p class="stats-value">{{logInfo.process_type}}</p>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div class="form-group row h-100">
              <label class="col-sm-3 col-form-label stats-label verticalCenter">API Endpoint</label>
              <div class="col-sm-9 verticalCenter">
                <p class="stats-value">{{logInfo.endpoint}}</p>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div class="form-group row h-100">
              <label class="col-sm-3 col-form-label stats-label verticalCenter">Status</label>
              <div class="col-sm-9 verticalCenter">
                <p class="stats-value" style="font-weight: bold; font-size: 140%;" :style="(logInfo.status == 'Success') ? 'color:green' : 'color:red'">{{logInfo.status}}</p>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 verticalCenter">
            <div class="btn-group row w-100" :style="(!mobile) ? 'height: 80%' : ''" role="group" aria-label="Basic example">
              <button class="btn btn-outline-primary h-100" @click="showReq()">REQUEST</button>
              <button class="btn btn-outline-danger h-100" @click="showResp()">RESPONSE</button>
              <button class="btn btn-outline-success h-100" @click="showMap()" v-if="logInfo.status == 'Success' && data">API RESULT</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- STATS ENDS -->

    <!-- CODE MODAL STARTS -->
    <codeModal
      v-if="showCodeModal"
      :data="modalData"
      :type="modalType"
      @closeModal="showCodeModal = false"
    ></codeModal>
    <!-- CODE MODAL ENDS -->

    <!-- TABLE MAPPING STARTS -->
    <b-card class="shadow" v-if="logInfo.status.toUpperCase() == 'SUCCESS' && data">
      <h2 class="mb-3" style="text-align: center;">{{logInfo.process_type.toUpperCase()}}</h2>
      <table class="table table-bordered">
		  <thead>
			  <th>No.</th>
			  <th>Data Type</th>
			  <th>Data Detail</th>
		  </thead>
		  <tbody v-for="(data, index) in data.json.Data">
			  <tr>
				  <td :rowspan="Object.keys(data).length">{{index + 1}}</td>
				  <td v-if="data.Activity">Activity</td>
				  <td v-if="data.Activity">{{data.Activity}}</td>
			  </tr>
			  <tr v-if="data.Customer">
				  <td>Customer</td>
				  <td>
					  <p v-for="key in Object.keys(data.Customer)" :key="key">{{key.toUpperCase().split("_").join(" ")}} =  {{data.Customer[key]}}</p>
				  </td>
			  </tr>
			  <tr v-if="data.Location">
				  <td>Location</td>
				  <td>
					  <p v-for="key in Object.keys(data.Location)" :key="key">{{key.toUpperCase().split("_").join(" ")}} =  {{data.Location[key]}}</p>
				  </td>
			  </tr>
			  <tr v-if="data.DateTime">
				  <td>Date Time</td>
				  <td>
					  <p v-for="key in Object.keys(data.DateTime)" :key="key">{{key.toUpperCase().split("_").join(" ")}} =  {{data.DateTime[key]}}</p>
				  </td>
			  </tr>
			  <tr v-if="data.Equipment">
				  <td>Equipment</td>
				  <td>
					  <p v-for="key in Object.keys(data.Equipment)" :key="key">{{key.toUpperCase().split("_").join(" ")}} =  {{data.Equipment[key]}}</p>
				  </td>
			  </tr>
		  </tbody>
	  </table>
    </b-card>
    <!-- TABLE MAPPING ENDS -->

    <!-- TABLE RESPONSE STARTS -->
    <div class="row" v-else-if="data">
      <div class="col" :class="{'outerBoxFailed' : !mobile}">
        <div class="innerBox">
          <h2 class="mb-3" style="text-align: center"><span style="color: red;">[Error]</span></h2>
          <table class="table">
            <thead>
              <tr>
                <th>Variable</th>
                <th>Problem</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="prob in problem" :class="{'border-top-thick' : (prob.variable != '' && prob.variable != null)}">
                <th>{{prob.variable}}</th>
                <td>{{prob.problem}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- TABLE RESPONSE ENDS -->

	<div class="text-center" v-else>
		<h3>NO LOG API DETAIL AVAILABLE</h3>
	</div>

  </div>
</template>

<script>
  import codeModal from './codeModal'
  import moment from 'moment'

  export default {
    data () {
      return {
        mobile : this.isMobile(),
        dataReady : false,
		showCodeModal : false,
		moment: moment,

        id : this.$route.query.id,
        data : null,
        modalData : null,
        modalType : '',

        problem : [],
      }
    },
    components : {
      codeModal
    },
    mounted () {
      this.getDetails();
    },
    methods : {
      getDetails(){
		let vm = this;

        this.$http.get('/api/reports/log-api/detail/' + this.$route.params.id)
        .then((resp)=>{
		  vm.data = resp.data.data[0];
		  console.log(vm.data)
		  if(typeof vm.data.json == 'string') vm.data.json = JSON.parse(vm.data.json)
		  vm.logInfo = this.$store.state.logInfo

        })
        .then(()=>{
		  if(!isNaN(vm.logInfo.status)) vm.logInfo.status = vm.logInfo.status == '200' ? "Success" : "Failed"
          if(vm.logInfo.status == "Failed") vm.parseProblems();
          vm.dataReady = true;
        })
        .catch((err)=>{
		  vm.logInfo = this.$store.state.logInfo
		  if(!isNaN(vm.logInfo.status)) vm.logInfo.status = vm.logInfo.status == '200' ? "Success" : "Failed"
		  vm.dataReady = true;
          console.log(err);
        })
      },
      parseProblems(){
		console.log(this.logInfo)
        var problemKeys = Object.keys(JSON.parse(this.logInfo.respond))

        var compiled = []

        for(var i = 0; i < problemKeys.length; i++){
          for(var j = 0 ; j < this.logInfo.respond[problemKeys[i]].length; j++){
            compiled.push({
              "variable" : (j == 0) ? problemKeys[i] : '',
              "problem" : this.logInfo.respond[problemKeys[i]][j]
            })
          }
        }

        this.problem = compiled;
      },
      showReq(){
        this.modalData = JSON.parse(this.logInfo.api_string);
        this.modalType = "API Request";
        this.showCodeModal = true;
      },
      showResp(){
		this.modalData = this.logInfo.respond
		while (typeof this.modalData == 'string') {
			this.modalData = JSON.parse(this.modalData);
		}
        this.modalType = "API Response";
        this.showCodeModal = true;
      },
      showMap(){
        this.modalData = this.data;
        this.modalType = "API RESULT";
        this.showCodeModal = true;
      },
    }
  }
</script>

<style scoped>
  .outerBox{
    padding:20px 0 30px 30px;
    width: 100%;
  }
  .outerBoxM{
    padding:20px 0 20px 20px;
    width: 100%;
  }
  .outerBoxFailed{
    padding:20px 100px;
  }
  .innerBox{
    border:1px solid #E0E0E0;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 3px 3px 10px -5px;
  }
  .verticalCenter{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .stats-label{
    font-size: 120%;
    font-weight: 700;
  }
  .stats-value{
    margin-bottom: 0;
  }
  .border-top-thick{
    border-top:2px solid black;
  }
  thead tr th{
    font-size: 140%;
  }
</style>