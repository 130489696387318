<script>/* eslint-disable */</script>
<template>
    <div>
        <b-card title="Summary Document Data" :sub-title="infoPopup.title" header-tag="h5" class="mb-4">
            <hr>
            <b-form>
                <b-form-row>
					<div class="col-md-12 col-12" >
						<div class="row">
							<b-form-group label="Filter By" class="col-md-4 col-12" style="height:42px" >
                                <Multiselect
									class="mt-1"
                                    :options="options.filter.map(x => x.value)"
                                    :custom-label="opt => options.filter.find(x => x.value == opt).name"
									v-model="filter.filter"
                                />
							</b-form-group>
							<b-form-group label="Month" class="col-md-4 col-12" style="height:42px" >
								<Multiselect 
									class="mt-1"
                                    :options="filterData.month.map(x => x.value)"
                                    :custom-label="opt => filterData.month.find(x => x.value == opt).name"
									v-model="filter.month"
								/>
							</b-form-group>
							<b-form-group label="Year" class="col-md-4 col-12" style="height:42px" >
								<Multiselect 
									class="mt-1"
									:options="filterData.year"
									v-model="filter.year"
								/>
							</b-form-group>
						</div>
					</div>
				</b-form-row>
                <b-form-row>
                    <b-form-group class="col-md-2 mt-3">
                        <b-btn class="w-100" type="button" variant="primary" @click="loadAPI" :disabled="isFetching">Filter</b-btn>
                    </b-form-group>
					<b-form-group class="col-md-2 mt-3">
                        <b-btn class="w-100" type="button" variant="success" @click="exportExcel" :disabled="isFetching"><span class="fa fa-file-excel"></span>&nbsp;&nbsp;Export To Excel</b-btn>
                    </b-form-group>
                </b-form-row>
            </b-form>
        </b-card>

        <div class="row mt-5 mb-5" v-if="isFetching">
            <div class="col">
                <h1 class="text-center" style="
                color: #616161;
                text-shadow: 4px 4px 6px #BDBDBD;">Loading...</h1>
            </div>
        </div>

        <div class="row mb-2" v-if="!isFetching">

            <div class="col-12">
                <b-card no-body class="mb-4">
                    <div style="overflow-x:scroll">
                        <logTable :data="data"></logTable>
                    </div>
                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
import qs from 'qs'
import XLSX from 'xlsx'

import Multiselect  from 'vue-multiselect'
import moment, { months } from 'moment'

import logTable from './childComponents/logTable'
export default {
    components: {
		Multiselect ,
		// Components
		logTable
    },
    data() {
		let startOfYear = new moment().startOf('year');

        return {
            isFetching : false,

            filterData: Object.assign({} , this.$store.state.filterData),
            options : {
                filter:[
                    {name:'Tanggal Dokumen', value:'documentData'},
                    {name:'Tanggal Penerimaan Data', value:'other'}
                ]
            },
            filter: {
                filter : 'documentData',
				month:  parseInt(new moment().format("M")),
                year:  new moment().format("YYYY")
            },
			data: null,
            mobile: this.isMobile(),
            infoPopup : this.$store.state.hoverInformation.report.summaryDocumentData
        }
    },
    mounted () {
        this.loadAPI()
    },
    methods: {
        exportExcel() {
            let wb = XLSX.utils.book_new()
            let exportFileName = `Summary Document Data (${this.filterData.month.find(x=>x.value == this.filter.month).name} ${this.filter.year}).xlsx`
            var ws = XLSX.utils.json_to_sheet(this.data, {raw:true});
            XLSX.utils.book_append_sheet(wb, ws, 'Data')
            XLSX.writeFile(wb, exportFileName)
        },
        loadAPI () {
            this.isFetching = true;

            this.data = null
            let url = "/api/reports/summary/data-document?" + qs.stringify(this.filter, {skipNulls:true})

            this.$http.get(url)
            .then((resp) => {
                this.data = resp.data.data;                
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(()=>{
                this.isFetching = false;
            })
        },
		isMobile() {
			if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				return true
			} else {
				return false
			}
		}
    }
}
</script>

<style scoped>
	.Aligner {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.Aligner-item {
		max-width: 50%;
	}

	.rounded {
		border-radius: 10%;
	}
</style>