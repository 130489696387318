<template>
  <div>
    <!--Filter Option-->
    <b-card title="Stock Intransit" :sub-title="infoPopup.title" header-tag="h5" class="mb-4">
      <hr>
      <b-form>
        <b-form-row>
          <b-form-group label="Intransit Type" class="col-md-6">
           <Multiselect 
            class="mt-1"
            :close-on-select="false"
            :options="['Intransit from Main Dealer to Dealer']"
            v-model="filter.intransitType"
            placeholder=""
           />
          </b-form-group>
          <b-form-group label="Region" class="col-md-6">
           <Multiselect 
            :close-on-select="false"
            class="mt-1"
            :options="filterData.region"
            label="name"
            track-by="loc_code"
            :multiple="true"
            v-model="filter.start"
            placeholder="All Regions"
           />
          </b-form-group>
          
        </b-form-row>
        <b-form-row>
          <b-form-group label="Segment" class="col-md-3">
           <Multiselect 
            class="mt-1"
            :close-on-select="false"
            :options="filterData.segment"
            label="segment"
            track-by="segment"
            :multiple="true"
            v-model="filter.segment"
            placeholder="All Segments"
           />
          </b-form-group>
          <b-form-group label="Series" class="col-md-3">
           <Multiselect 
            class="mt-1"
            :close-on-select="false"
            :options="filterData.series"
            label="series"
            track-by="series"
            :multiple="true"
            v-model="filter.series"
            placeholder="All Series"
           />
          </b-form-group>
          <b-form-group label="Type" class="col-md-3">
           <Multiselect 
            class="mt-1"
            :close-on-select="false"
            :options="filterData.type"
            label="type"
            track-by="type"
            :multiple="true"
            v-model="filter.type"
            placeholder="All Vehicle Types"
           />
          </b-form-group>
          <b-form-group label="Colour" class="col-md-3">
           <Multiselect 
            class="mt-1"
            :close-on-select="false"
            :options="filterData.color"
            v-model="filter.color"
            label="name"
            track-by="color_code"
            :multiple="true"
            placeholder="All Colours"
           />
          </b-form-group>
        </b-form-row>
        <b-form-row>
          <b-form-group class="col-md-1">
            <b-btn type="button" variant="primary" @click="search(1)">Filter</b-btn>
          </b-form-group>
        </b-form-row>
      </b-form>
    </b-card>
    <!--Stats-->
    <div class="row" v-if="data">
       <div class="col-md-12 col-lg-12 col-xl-9 mt-3" style="min-height:575px">

        <!-- Popular queries -->
        <b-card no-body style="height:100%">
            <div class="col-md-12 col-lg-12 col-xl-12 align-items-center p-4">
              <h5>Stock Intransit
                <span class="col-md-1 col-sm-1 col-2">
                    <i class="fa fa-info-circle fa" id="stockIntransit"></i>
                    <b-popover
                    target="stockIntransit"
                    title="Stock Intransit"
                    triggers="hover focus"
                    placement="top"
                    :content="infoPopup.stockIntransit"
                    ></b-popover>
                </span>
              </h5>
              <div class="d-flex row"><StockIntransit class="w-100" :data="data.stock" :filter="filter"/></div>
            </div>
        </b-card>
        <!-- / Popular queries -->
      </div>
      <div class="col-md-12 col-lg-12 col-xl-3 mt-3">
        <div class="row" style="height:20%">
          <div class="col-sm-6 col-md-12 col-lg-12 col-xl-12" style="height:100%">
              <!-- <b-card no-body class="mb-4">
                <b-card-header class="border-0 pb-0">Total Intransit</b-card-header>
                <b-card-body class="text-center text-success text-xlarge py-3">3,235</b-card-body>
              </b-card> -->
              <div class="stats-inner-box mb-4">
                <div class="row" style="height: 100%">
                    <div class="col-8">
                      <p>Total Intransit
                        <span class="col-md-1 col-sm-1 col-2">
                            <i class="fa fa-info-circle fa" id="totalIntransit"></i>
                            <b-popover
                            target="totalIntransit"
                            title="Total Intransit"
                            triggers="hover focus"
                            placement="top"
                            :content="infoPopup.totalIntransit"
                            ></b-popover>
                        </span>
                      </p>
                      <p class="stats-value">
                        {{ numberWithCommas(data.total)}}
                        <!-- 5000 -->
                      </p>
                    </div>
                    <div class="col-4 icon">
                      <i class="fas fa-motorcycle"></i>
                    </div>
                </div>
              </div>
          </div>
        </div>
        <!-- Stats -->
        <div class="row" style="height:80%">
          <div class="col-sm-6 col-md-12 col-lg-12 col-xl-12"  style="height:100%">
              <b-card no-body class="mb-4 p-3"  style="height:100%">
                <h4 class="text-center">Intransit Summary
                  <span class="col-md-1 col-sm-1 col-2">
                      <i class="fa fa-info-circle fa" id="intransitSummary"></i>
                      <b-popover
                      target="intransitSummary"
                      title="Intransit Summary"
                      triggers="hover focus"
                      placement="top"
                      :content="infoPopup.intransitSummary"
                      ></b-popover>
                  </span>
                </h4>
                <table class="table">
                  <thead>
                      <th>Region</th>
                      <th>Total</th>
                  </thead>
                  <tbody>
                      <tr v-for="data in data.summary">
                          <td>{{data.start}}</td>
                          <td>{{ numberWithCommas(data.count) }}</td>
                      </tr>
                  </tbody>
              </table>
            </b-card>
          </div>
        </div>
        <!-- / Stats -->
      </div>
    </div>
  </div>
</template>

<script>
// library
import Multiselect  from 'vue-multiselect'
//  charts
import StockIntransit from './childComponents/stockIntransitBubble'

export default {
  name: 'stock-intransit',
  metaInfo: {
    title: 'Stock Intransit'
  },
  components: {
    // library
    Multiselect ,
    //  charts
    StockIntransit
  },
  data () {
    return {
      range: null,
      region: 'ALL',
      intransit: 'Intransit from AHM to Main Dealer',
      rangeConfig: {
        mode: 'range',
        altInput: true,
        animate: !isRTL()
      },
      filterData: null,
      filter: {
        intransitType: ['Intransit from Main Dealer to Dealer'],
        start: [],
        segment: [],
        series: [],
        type: [],
        colour: []
      },
      data: null,
      infoPopup: this.$store.state.hoverInformation.h1.stockDetails.stockIntransit
    }
  },
  mounted () {
    this.loadFilter()
    this.search()
  },
  watch: {
    'filter.region' : function() {
      this.loadFilter('region')
    },
    'filter.segment' : function () {
      this.loadFilter('segment')
    },
    'filter.series' : function () {
      this.loadFilter('series')
    }
  },
  methods: {
    async search () {
      let filter = await this.transformFilter(this.filter)
      filter = await this.initializeFilter(filter)
      this.loadAPI(filter)
    },
    loadAPI (filter) {
      this.data = null
      let url = '/api/h1/stock-intransit' + filter

      this.$http.get(url)
      .then((resp) => {
        this.data = resp.data.data
      })
      .then(()=> {
        let obj = {}

        let data = this.data

        for(var i = 0 ; i < data.length ; i++) {
          if(obj[data[i].start] == null) {
            obj[data[i].start] = []
            obj[data[i].start].push(data[i])
          }
          else if(obj[data[i].start].length > 5) {
            continue;
          } 
          console.log(obj[data[i].start])
        }

        console.log(obj)
      })
      .catch((err) => {
        console.log(err)
      })
    },
    async initializeFilter (filters) {
      let filterUrl = '?'
      let keys = Object.keys(filters)
      for (var i = 0; i < keys.length; i++) {
        if(filters[keys[i]].length > 0)
          filterUrl += `${keys[i]}=${filters[keys[i]]}&`
      }
      
      return filterUrl
    },
    async transformFilter (filter) {
      let obj = {}
      let keys = Object.keys(filter)
      for (var i = 0 ; i < keys.length; i++) {
        if (keys[i] != 'intransitType') {
          obj[keys[i]] = []
          for (var j = 0; j < filter[keys[i]].length; j++) {
            keys[i] == 'start' ? obj[keys[i]].push(filter[keys[i]][j].loc_code) : obj[keys[i]].push(filter[keys[i]][j][keys[i].toLowerCase()])
          }
        }
        else {
          obj[keys[i]] = filter[keys[i]]
        }
      }
      return obj
    },
    loadFilter (type) {
      if (type == 'region'){
        this.filter.branch.length = 0;
        this.filterData.branch = this.$store.getters.getBranch(this.filter.region)
      } else if (type == 'segment'){
        this.filter.series.length = 0;
        this.filterData.series = this.$store.getters.getSeries(this.filter.segment)

        this.filter.type.length = 0;
        this.filterData.type = this.$store.getters.getType(this.filter.segment, this.filter.series)
      } else if (type == 'series'){
        this.filter.type.length = 0;
        this.filterData.type = this.$store.getters.getType(this.filter.segment, this.filter.series)
      } else {
        this.filterData = this.$store.getters.getFilterOptions
      } 
    },
  }
}

function isRTL () {
  return document.documentElement.getAttribute('dir') === 'rtl' || document.body.getAttribute('dir') === 'rtl'
}
</script>
