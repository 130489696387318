<template>
    <div>
        <table class="table">
            <thead>
                <tr>
                    <th>Segment</th>
                    <th>Type</th>
                    <th>Color</th>
                    <th>1-3 Days</th>
                    <th>4-6 Days</th>
                    <th>7-9 Days</th>
                    <th>10-30 Days</th>
                    <th>30-90 Days</th>
                    <th>>90 Days</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in shownData">
                    <td>{{ item.segment ? item.segment : 'No Data' }}</td>
                    <td>{{ item.type ? item.type : 'No Data' }}</td>
                    <td>{{ item.color ? item.color : 'No Data' }}</td>
                    <td>{{ item.day_1_3 ? numberWithCommas(item.day_1_3) : '0' }}</td>
                    <td>{{ item.day_4_6 ? numberWithCommas(item.day_4_6) : '0' }}</td>
                    <td>{{ item.day_7_9 ? numberWithCommas(item.day_7_9) : '0' }}</td>
                    <td>{{ item.day_10_30 ? numberWithCommas(item.day_10_30) : '0' }}</td>
                    <td>{{ item.day_30_90 ? numberWithCommas(item.day_30_90) : '0' }}</td>
                    <td>{{ item.day_90 ? numberWithCommas(item.day_90) : '0' }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                dummyData : [],
                shownData : [],
            }
        },
        props : ['data'],
        mounted () {
            this.checkData();
        },
        watch : {
            data : {
                deep : true,
                handler : 'checkData'
            }
        },
        methods : {
            checkData () {
                if (this.data && this.data.length > 0) this.shownData = this.data;
            },
            generateDummyData () {
                let data = [];

                for (var i = 0; i < 20; i++) {
                    data.push({
                        "segment" : "Matic",
                        "type": "Honda Beatstreet",
                        "color": "All Color",
                        "day_1_3" : this.randomInt(0, 500),
                        "day_4_6" : this.randomInt(0, 100),
                        "day_7_9" : this.randomInt(0, 30),
                        "day_10_30" : this.randomInt(0, 20),
                        "day_30_90" : this.randomInt(0, 10),
                        "day_90" : this.randomInt(0, 5)
                    })
                }

                this.dummyData = data;
            },
        }
    }
</script>

<style scoped>
    table {
        font-family: Ubuntu;
    }

    table thead tr th {
        border-top: 0;
        border-bottom: 0.5px solid #FF0009;
        font-weight: 700;
    }

    tbody {
        display: block;
        max-height: 300px;
        overflow-y: scroll;
    }

    thead, tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
    }

    thead {
        width: calc( 100% - 1em );
    }

    tbody::-webkit-scrollbar{
        width:1em;
    }
    tbody::-webkit-scrollbar-track{
        background-color:#F1F1F1;
        border-radius: 20px;
    }
    tbody::-webkit-scrollbar-thumb{
        background-color:#E0E0E0;
        border-radius: 20px;
    }
</style>