<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th rowspan="2">Type</th>
          <th rowspan="2">Color</th>
          <th colspan="2" style="border-bottom: 0">Transfer Stock</th>
          <th rowspan="2">Warehouse<br>{{ type == 'In' ? 'Origin' : 'Destination'}}</th>
        </tr>
        <tr>
          <th>In-Transit</th>
          <th>Sent</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="stock in data">
          <td>{{ stock.type }}</td>
          <td>{{ stock.color_code }} - {{stock.color_desc}}</td>
          <td>{{ numberWithCommas(stock.intransit) }}</td>
          <td>{{ numberWithCommas(stock.send) }}</td>
          <td v-if="type == 'In'">{{ stock.wh_origin }} - {{stock.wh_origin_name}}</td>
          <td v-if="type == 'Out'">{{ stock.wh_destination }} - {{stock.wh_destination_name}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        testTypes : ['CBR-20', 'CBR-150', 'Beat', 'Vario', 'Scoopy', 'Beatstreet'],
        testColor : ['Red', 'Black', 'White', 'Silver', 'Red', 'Silver'],
        testDestination : ['Jombang', 'Ngawi', 'Gubeng', 'Wonokromo', 'Tegalsari', 'Gubeng']
      }
    },
    props:['filter','period', 'type', 'data'],
    mounted () {
      console.log(this.data)
    }
  }
</script>

<style scoped>
  table tr th, table tr td {
    text-align: center;
    vertical-align: middle;
  }

  table tr th {
    border-bottom: 1px solid #F75047;
  }

</style>