<template>
  <table class='table table-bordered'>
    <tr>
      <th>Days</th>
      <th>Min</th>
      <th>Max</th>
      <th>Avg</th>
    </tr>
    <tr v-for='dailyData in data.slice().reverse()' :key="dailyData">
      <th >{{ dailyData.day }}</th>
      <template>
          <td>{{dailyData.minstring}}</td>
          <td>{{dailyData.maxstring}}</td>
          <td>{{dailyData.avgstring}}</td>
      </template>
    </tr>
  </table>
</template>

<script>
/* eslint-disable */
export default {
  data () {
    return {
      data : null,
    }
  },
  mounted () {
    this.dummyData()
  },
  methods : {
    dummyData () {
      this.data = [{
        'day': 'Saturday',
        'min': 121,
        'avg': 2124,
        'max': 3615
      },
      {
        'day': 'Friday',
        'min': 129,
        'avg': 669,
        'max': 4169
      },
      {
        'day': 'Thursday',
        'min': 89,
        'avg': 353,
        'max': 686
      },
      {
        'day': 'Wednesday',
        'min': 5,
        'avg': 291,
        'max': 640
      },
      {
        'day': 'Tuesday',
        'min': 70,
        'avg': 350,
        'max': 945
      },
      {
        'day': 'Monday',
        'min': 47,
        'avg': 322,
        'max': 989
      }]

      var keys = Object.keys(this.data[0])
      for (var i = 0; i < this.data.length; i++) {
        for (var j = 0; j < keys.length; j++) {
          if (keys[j] === 'day') continue
          let chartValue = Math.round(this.data[i][keys[j]] / 60 * 100) / 100
          var quotient = Math.floor(this.data[i][keys[j]] / 60)
          var remainder = this.data[i][keys[j]] % 60

          this.data[i][keys[j]] = chartValue
          this.data[i][keys[j] + 'string'] = quotient + ' h and ' + remainder + ' min'
        }
      }
    },
  }
}
</script>

<style scoped>
  th, td{
    text-align: center;
  }
</style>
