<template>
  <div>
    <div>
      <div class="inner-box" style="overflow-x: auto;">
        <table class="table table-striped text-center">
          <thead>
            <tr>
              <th>Time Range</th>
              <th v-for="x in monthLength" :key="x">{{x > 9 ?  x : '0' + x}}</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(hour, index) in tableHeaders">
              <th class="text-center">{{hour.rowName}}</th>
              <td class="text-center" v-for="dat in data">{{ dat.tgl >= currFullDate ? '-' : numberWithCommas(parseInt(dat[hour.rowVariable]))}}</td>
              <td> {{numberWithCommas(hourDaily[index])}}</td>
            </tr>
            <tr>
              <th>Total</th>
              <td v-for="total in totalDaily">{{ numberWithCommas(total) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint eqeqeq:0 */
import moment from 'moment'

export default {
  data () {
    var now = new Date(this.filter.year , parseInt(this.filter.month.value) - 1 , 1)
    var currDate = new Date().getDate()
    var currMonth = now.getMonth()
    var currYear = now.getFullYear()
    var monthLength = new Date(currYear, currMonth + 1, 0).getDate()

    return {
      moment: moment,
      currFullDate: moment().format("YYYY-MM-DD"),

      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      currDate: currDate,
      currMonth: currMonth,
      currYear: currYear,
      monthLength: monthLength,
      tableHeaders: [],
      hourDaily: null,
      totalDaily: null
    }
  },
  props: ['data' , 'filter'],
  async mounted () {
    let tableHeaders = await this.generateData()
    this.calculateTotalDaily(tableHeaders)
    this.calculateTotalHour(tableHeaders)
  },
  computed: {
  },
  watch: {
  },
  methods: {
    generateData () {
      let data = this.data[0]
      this.rowCount = Object.keys(data).length - 1

      let keys = Object.keys(data)
      for (var i = 1 ; i < keys.length ; i++) {
        let tableHeaders = keys[i].split('_')
        this.tableHeaders.push({
          rowName : `${tableHeaders[1].slice(0,2)}:${tableHeaders[1].slice(2,4)} - ${tableHeaders[2].slice(0,2)}:${tableHeaders[2].slice(2,4)}`,
          rowVariable : keys[i]
        })
      }

      return this.tableHeaders
    },
    calculateTotalDaily (tableHeaders) {
      // Normalize Everything to 0
      console.log(this.monthLength)
      this.totalDaily = new Array(this.monthLength).fill(0)
      console.log(this.data)
      // Start Calculation
      for (var i = 0; i < this.monthLength; i++) {
        for (var j = 0; j < this.tableHeaders.length; j++) {
          this.totalDaily[i] += parseInt(this.data[i][tableHeaders[j].rowVariable])
        }
      }
      this.totalDaily.push(this.totalDaily.reduce((a, b) => a + b, 0))
    },
    calculateTotalHour (tableHeaders) {
      this.hourDaily = new Array(tableHeaders.length).fill(0)

      for (var i = 0 ; i < tableHeaders.length ; i ++) {
        for (var j = 0 ; j < this.monthLength; j++) {
          this.hourDaily[i] += parseInt(this.data[j][tableHeaders[i].rowVariable])
        }
       }

    },
    numberWithCommas(x) {
      return parseInt(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    randomValue () {
      return Math.floor(Math.random() * 100) + 1
    }
  }
}
</script>

<style scoped>
  /* .table-div{
      display: block;
      overflow: scroll;
  } */
  .dataAvailable{
      color: #76FF03;
      font-weight: bold;
      font-size: 1.15em;
  }
  .bold{
      font-weight: bold;
  }
  .no-data {
      text-align: center;
      /* width: 100%; */
  }
  .lightGreen{
      background-color: #C8E6C9;
  }

  .boxHigh{
      border: 2px solid white !important;
      border-bottom: 1px solid white !important;

      background-color:#263238;

      font-size:110%;
      font-weight: bold;
  }

  .boxMid{
      border: 2px solid white;
      border-top: 1px solid white;
      border-bottom: 1px solid white;

      background-color:#263238;

      font-size:1.25em;
      font-weight: bold;
  }

  .boxLow{
      border: 2px solid white;
      border-top: 1px solid white;

      background-color:#263238;

      font-size:110%;
      font-weight: bold;
  }

  .currentTime{
      background-color: #fff;
      color: black;
      font-size: 1.3em;
      text-decoration: underline;
  }
  .outerLayer{
      padding:20px;
  }
  .innerLayer{
      height: 100%;
      border:1px solid white;
      border-radius: 20px;
      box-shadow: 5px 5px 5px black;
  }

  table thead tr th, table tbody tr td{
      text-align: center;
      vertical-align: middle;
  }

  .type{
      text-align: center;
      height: 100%;
      vertical-align: middle;
  }
  .type thead th{
      font-size: 130%;
      background-color: #212121;
  }
  /* .type{
      overflow: hidden;
  } */

  .flexBox{
      display: flex;
      flex-flow: column;
  }
  .flexTop{
      flex: 0 1 auto;
  }
  .flexContent{
      flex: 1 1 auto;
  }

  /* FANCY CLOCK STARTS */
  #clock {
      font-family: 'Share Tech Mono', monospace;
      color: #ffffff;
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #daf6ff;
      text-shadow: 0 0 20px rgba(10, 175, 230, 1),  0 0 20px rgba(10, 175, 230, 0);
  }

  #clock .time {
      letter-spacing: 0.05em;
      font-size: 80px;
      padding: 5px 0;
  }

  #clock .date {
      letter-spacing: 0.1em;
      font-size: 24px;
  }
  /* FANCY CLOCK ENDS */

  .inner-box{
    border-radius: 5px;
    background-color: #fff;
    padding: 20px;
    height: 100%;
  }

  tbody tr td{
    padding : 5px 0px;
  }

  thead tr th{
    padding : 5px 0px;
  }
</style>
