<template>
    <div class="tooltip-container">
        <slot></slot>
        <div class="tooltip" v-if="isVisible">
            <div class="tooltip-title">{{ title }}</div>
            <div class="tooltip-content">{{ content }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        content: String
    },
    data() {
        return {
            isVisible: false
        }
    },
    methods: {
        showTooltip() {
            this.isVisible = true
        },
        hideTooltip() {
            this.isVisible = false
        }
    }
}
</script>

<style scoped>
.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 200px;
}

.tooltip-title {
    font-weight: bold;
    margin-bottom: 5px;
}

.tooltip-content {
    font-size: 0.9em;
    color: #666;
}
</style>