<template>
    <div class="mt-5">
        <div class="row">

            <div class="col-md-6" style="border-right: 1px solid #F2F2F2">
                <div class="row">
                    <div class="col-md-8 offset-md-2">
                        <h4 class="ubuntu--font mb-4">Aging Stock Tertinggi</h4>
                        <div class="row">
                            <div class="col-md-4 offset-md-1 align-middle">
                                <div>
                                    <img src="@/assets/img/HighlightsV2/Total.png" style="width:100%;"/>
                                </div>
                            </div>
                            <div class="col-md-7">
                                <p class="st-b">{{ data.max_aging.type ? data.max_aging.type : 'No Data' }}</p>
                                <table class="table">
                                    <tr>
                                        <td class="st-a">Warna</td>
                                        <td class="st-b">{{ data.max_aging.color ? data.max_aging.color : '-' }}</td>
                                    </tr>
                                    <tr>
                                        <td class="st-a">Aging Stock</td>
                                        <td class="st-b">{{ data.max_aging.aging_days ? parseInt(data.max_aging.aging_days) : '-' }} Days</td>
                                    </tr>
                                    <tr>
                                        <td class="st-a">Tgl GR</td>
                                        <td class="st-b">{{ data.max_aging.last_scan ? data.max_aging.last_scan.split(" ")[0] : '-' }}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6" style="border-right: 1px solid #F2F2F2">
                <div class="row">
                    <div class="col-md-8 offset-md-2">
                        <h4 class="ubuntu--font mb-4">Aging Stock Terendah</h4>
                        <div class="row">
                            <div class="col-md-4 offset-md-1 align-middle">
                                <div>
                                    <img src="@/assets/img/HighlightsV2/Total.png" style="width:100%;"/>
                                </div>
                            </div>
                            <div class="col-md-7">
                                <p class="st-b">{{ data.min_aging.type ? data.min_aging.type : 'No Data' }}</p>
                                <table class="table">
                                    <tr>
                                        <td class="st-a">Warna</td>
                                        <td class="st-b">{{ data.min_aging.color ? data.min_aging.color : '-' }}</td>
                                    </tr>
                                    <tr>
                                        <td class="st-a">Aging Stock</td>
                                        <td class="st-b">{{ data.min_aging.aging_days ? parseInt(data.min_aging.aging_days) : '-' }} Days</td>
                                    </tr>
                                    <tr>
                                        <td class="st-a">Tgl GR</td>
                                        <td class="st-b">{{ data.min_aging.last_scan ? data.min_aging.last_scan.split(" ")[0] : '-' }}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {

            }
        },
        props : ['data'],
        mounted () {

        },
        methods : {

        }
    }
</script>

<style scoped>
    h4 {
        font: normal normal 500 20px/23px Ubuntu;
        text-align: center;
    }

    .table td {
        border : 0;
    }

    .st-a, .st-b {
        vertical-align: middle;
    }

    .st-a {
        font: normal normal 500 12px/13px Ubuntu;
        padding-left: 0;
    }

    .st-b {
        font: normal normal 500 14px/15px Ubuntu;
        font-weight: bold;
    }
</style>