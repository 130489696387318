<template>
  <div>
    <!--Filter Option-->
    <b-card title="Customer Location" :sub-title="infoPopup.title" header-tag="h5" class="mb-4">
      <hr>
      <b-form>
          <b-form-row>
              <b-form-group label="Region" class="col-md-3">
              <Multiselect 
                  v-if="filterData"
                  class="mt-1"
                  :options="filterData.regions"
                  label="name"
                  track-by="loc_code"
                  :multiple="true"
                  v-model="filter.region"
                  placeholder="All Regions"
                  :close-on-select="false"
              />
              </b-form-group>
              <b-form-group label="Branch" class="col-md-3">
              <Multiselect 
                  v-if="filterData"
                  class="mt-1"
                  :options="filterData.branch"
                  label="name"
                  track-by="loc_code"
                  :multiple="true"
                  v-model="filter.branch"
                  placeholder="All Branches"
                  :close-on-select="false"
              />
              </b-form-group>
              <b-form-group label="Month" class="col-md-3" style="height:42px" >
              <Multiselect 
                  class="mt-1"
                  :options="filterData.month"
                  v-model="filter.month"
                  label="name"
                  track-by="value"
                  :close-on-select="false"
              />
              </b-form-group>
              <b-form-group label="Year" class="col-md-3" style="height:42px" >
              <Multiselect 
                  class="mt-1"
                  :options="filterData.year"
                  v-model="filter.year"
                  :close-on-select="false"
              />
              </b-form-group>
          </b-form-row>
          <b-form-row>
          <b-form-group label="Segment" class="col-md-3">
           <Multiselect 
            class="mt-1"
            v-if="filterData"
            :options="filterData.segment"
            label="segment"
            track-by="segment"
            :multiple="true"
            v-model="filter.segment"
            placeholder="All Segments"
            :close-on-select="false"
           />
          </b-form-group>
          <b-form-group label="Series" class="col-md-3">
           <Multiselect 
            class="mt-1"
            v-if="filterData"
            :options="filterData.series"
            label="series"
            track-by="series"
            :multiple="true"
            v-model="filter.series"
            placeholder="All Series"
            :close-on-select="false"
           />
          </b-form-group>
          <b-form-group label="Type" class="col-md-3">
           <Multiselect 
            class="mt-1"
            v-if="filterData"
            :options="filterData.type"
            label="type"
            track-by="type"
            :multiple="true"
            v-model="filter.type"
            placeholder="All Vehicle Types"
            :close-on-select="false"
           />
          </b-form-group>
          <b-form-group label="Colour" class="col-md-3">
           <Multiselect 
            class="mt-1"
            v-if="filterData"
            :options="filterData.colour"
            v-model="filter.colour"
            label="name"
            track-by="color_code"
            :multiple="true"
            placeholder="All Colours"
            :close-on-select="false"
           />
          </b-form-group>
        </b-form-row>
          <b-form-row>
            <b-form-group class='col-md-1'>
              <b-btn type='button' variant='primary' @click="search(0)">Filter</b-btn>
            </b-form-group>
          </b-form-row>
      </b-form>
    </b-card>

        <b-card>
            <GmapMap
                :center="{lat:-2.548926, lng:118.0148634}"
                :zoom="5"
                map-type-id="terrain"
                style="width: 100%; min-height: 500px"
            >
                <GmapCluster>
                <gmap-marker v-for="(m, index) in markers"
                :position="m.lat ? m: {lat: 0.00000 , lng: 0.0000}"
                :clickable="true" :draggable="true"
                :key="index"
                ></gmap-marker>
                </GmapCluster>
            </GmapMap>
        </b-card>

        <div style="margin:0;">
            <div class='mt-4'>
                <b-card no-body>
                    <div class='col-md-12 col-lg-12 col-xl-12 align-items-center p-4'>
                    <h5>Customer Location By Segment and Series 
                      <span>
                        <i class="fa fa-info-circle fa-sm" id="customerLocationBySegmentAndSeries"></i>
                        <b-popover
                        target="customerLocationBySegmentAndSeries"
                        title="Customer Location By Segment and Series"
                        triggers="hover focus"
                        placement="top"
                        :content="infoPopup.customerLocationBySegmentAndSeries"
                        ></b-popover>
                    </span>
                    </h5>
                    <div class='d-flex row'><BySegment @getSeries="getSeries" class='w-100' :data="customerSegmentSeries" v-if="customerSegmentSeries" /></div>
                    </div>
                </b-card>
            </div>
            <!--Average Days of Stock By Type-->
            <div class='mt-4' v-if="customerTypesColour">
                <b-card no-body>
                    <div class='col-md-12 col-lg-12 col-xl-12 align-items-center p-4'>
                    <h5>Customer Location By Types and Colours
                      <span>
                          <i class="fa fa-info-circle fa-sm" id="customerLocationByTypesAndColours"></i>
                          <b-popover
                          target="customerLocationByTypesAndColours"
                          title="Customer Location By Types and Colours"
                          triggers="hover focus"
                          placement="top"
                          :content="infoPopup.customerLocationByTypesAndColours"
                          ></b-popover>
                      </span>
                    </h5>
                    <div class='d-flex row'><ByTypes class='w-100' :data="customerTypesColour" /></div>
                    </div>
                </b-card>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
// Library
import Multiselect  from 'vue-multiselect'
import moment from 'moment'
import GmapCluster from 'vue2-google-maps/dist/components/cluster' // replace src with dist if you have Babel issues
//  charts
import ByTypes from './childComponents/ByTypes';
import BySegment from './childComponents/BySegment';

export default {
  name: 'customer-location',
  metaInfo: {
    title: 'H1 Customer Location'
  },
  methods: {
    
  },
  components: {
    Multiselect ,
    GmapCluster,
    //  charts
    BySegment,
    ByTypes
  },
  data() {
    return {
      // Filter Variables
        filterData: {
          regions: [],
          branch: [],
          segment: [],
          series: [],
          type: [],
          colour: [],
          month: [],
          year: []
        },
        filter: {
          region: [],
          branch: [],
          segment: [],
          series: [],
          type: [],
          colour: [],
          month:  {
              name: new moment().format("MMMM"),
              value: new moment().format("M")
          },
          year:  new moment().format("YYYY")
        },
        // API Variables
        markers: null,
        customerSegmentSeries: null,
        customerTypesColour: null,
        infoPopup: this.$store.state.hoverInformation.h1.customerLocation
        
    }
  },
  mounted () {
        this.search(0)
        this.loadFilter(this.filter)
    },
    watch: {
        'filter.region' : function() {
        let region = []
        for(var i = 0 ; i < this.filter.region.length ; i++){
            region.push(this.filter.region[i].loc_code)
        }
        this.loadFilter('region' , region)
        },
        'filter.segment' : function () {
          let segment = []
          for(var i = 0 ; i < this.filter.segment.length ; i++){
            segment.push(this.filter.segment[i].segment)
          }
          this.loadFilter('segment' , segment)
        },
        'filter.series' : function () {
          let series = []
          for(var i = 0 ; i < this.filter.series.length ; i++){
            series.push(this.filter.series[i].series)
          }
          this.loadFilter('series' , series)
        }
    },
    methods: {
        // API Load Functions
        loadTypesColours () {
          this.customerTypesColour = null
          let url = '/api/h1/customer-location/by-types-colours' + this.$store.state.filterUrl

          this.$http.get(url)
          .then((resp) => {
            this.customerTypesColour = resp.data.data
          })
          .then(()=>{
            window.scrollTo(0, document.body.scrollHeight);
          })
          .catch((err) => {
            console.log(err)
          })
        },
        loadMaps () {
          this.markers = null
          let url = '/api/h1/customer-location/map' + this.$store.state.filterUrl

          this.$http.get(url)
          .then((resp) => {
            this.markers = resp.data.data
          })
          .catch((err) => {
            console.log(err)
          })
        },
        loadVehicleTypes () {
          this.customerSegmentSeries = null
          let url = '/api/h1/customer-location/by-segment-series' + this.$store.state.filterUrl

          this.$http.get(url)
          .then((resp) => {
            this.customerSegmentSeries = resp.data.data
          })
          .catch((err) => {
            console.log(err)
          })
        },
        // Filter Search Trigger
        async search(flag) {
          let filters = await this.transformFilter(this.filter)
          this.$store.state.filterUrl = await this.initializeFilter(filters)
          if (this.filter.series.length > 0 && flag == 1) {
            this.averageSegmentSeries = null
            this.loadTypesColours()
            this.loadMaps()
          }
          else {
            
            if(this.filter.series.length > 0){
              this.loadTypesColours()
            }
            else {
              this.averageTypeColour = null
              this.loadVehicleTypes()
              this.loadMaps()
            }
          }
        },
        getSeries (data, segment) {
          let series = data
          this.filter.segment.push({
            segment: segment
          })
          this.filter.series.length = 0
          this.filter.series.push({
            series: series
          })
          
          this.search(0)
        },
        // Filter Settings
        async initializeFilter (filters) {
          let filterUrl = '?'
          let keys = Object.keys(filters)
          for (var i = 0; i < keys.length; i++) {
            if(typeof filters[keys[i]] == 'object' && filters[keys[i]].length > 0)
              filterUrl += `${keys[i]}=${filters[keys[i]]}&`
            else if (typeof filters[keys[i]] != 'object') {
              filterUrl += `${keys[i]}=${filters[keys[i]]}&`
            }
          }
          
          return filterUrl
        },
        async transformFilter (filter) {
          let obj = {}
          let keys = Object.keys(filter)
          console.log(filter)
          for (var i = 0 ; i < keys.length; i++) {
            if (keys[i] != 'month' && keys[i] != 'year') {
              obj[keys[i]] = []
              for (var j = 0; j < filter[keys[i]].length; j++) {
                keys[i] == 'region' || keys[i] == 'branch' ? obj[keys[i]].push(filter[keys[i]][j].loc_code) : obj[keys[i]].push(filter[keys[i]][j][keys[i].toLowerCase()])
              }
            }
            else if (keys[i] == 'month') {
              obj[keys[i]] = filter[keys[i]].value
            }
            else {
              obj[keys[i]] = filter[keys[i]]
            }
          }
          return obj
        },
        loadFilter (type , data) {
            this.filterData = Object.assign({} , this.$store.state.filterData)
            if (type == 'region') {
                this.filterData.branch = Object.assign([] , this.$store.state.filterData.branch)
                this.filter.branch.length = 0
                if(this.filter.region.length == 0){
                return;
                } 
                let branch = []
                for(var i = 0 ; i < this.filterData.branch.length; i++){
                if(data.includes(this.filterData.branch[i].region_code))
                    branch.push(this.filterData.branch[i])
                }

                this.filterData.branch = branch
            }
            
            if (type == 'segment') {
                // this.filter.series.length = 0
                this.filter.type.length = 0
                this.filterData.series = Object.assign([] , this.$store.state.filterData.series)
                this.filterData.type = Object.assign([] , this.$store.state.filterData.type)
                if(this.filter.segment.length == 0){
                
                  return;
                } 
                let series = []
                let type = []
                for(var i = 0 ; i < this.filterData.series.length; i++){
                if(data.includes(this.filterData.series[i].segment))
                    series.push(this.filterData.series[i])
                }

                for(var i = 0 ; i < this.filterData.type.length; i++){
                if(data.includes(this.filterData.type[i].segment))
                    type.push(this.filterData.type[i])
                }

                this.filterData.series = series
                this.filterData.type = type
            }

            if (type == 'series') {
                this.filterData.type = Object.assign([] , this.$store.state.filterData.type)
                this.filter.type.length = 0
                if(this.filter.series.length == 0){
                  return;
                } 
                let type = []
                for(var i = 0 ; i < this.filterData.type.length; i++){
                if(data.includes(this.filterData.type[i].series))
                    type.push(this.filterData.type[i])
                }
                this.filterData.type = type
            }
        },
    }
}

</script>
