<template>
	<div>
		<!-- Late Delivery/Shipping AHM to MD Start -->
		<div class="row mt-2 mb-3" style="padding-right:1%">
			<div class="col-12 col-md-10">
				<div class="Aligner-Header">
					<h3>Late Delivery/Shipping <span style="font-weight: 100;">AHM to MD</span>
						<span class="col-md-1 col-sm-1 col-2">
							<i class="fa fa-info-circle fa-sm" id="ahmToMd"></i>
							<b-popover
							target="ahmToMd"
							title="Late Delivery/Shipping AHM to MD"
							triggers="hover focus"
							placement="top"
							:content="infoPopup.ahmToMd"
							></b-popover>
						</span>
					</h3>
				</div>
			</div>
			<div class="col-12 col-md-2 row">
				<div class="col-12 col-md highlightFilter">
					<button
						class="mt-1 btn highlightFilterBtn"
						@click="modal.download = true"
					>
						Download <i class="fas fa-download"></i>
					</button>
				</div>
			</div>
		</div>
		<div class="content-outer">
			<div class="content-inner" style="padding: 0 3%">
				<late-delivery-shipping-ahm-to-md
					:data="data.ahmToMd"
				></late-delivery-shipping-ahm-to-md>
			</div>
		</div>
		<!-- Late Delivery/Shipping AHM to MD End -->

		<!-- Late Delivery/Shipping MD to D Start -->
		<div class="row mt-2 mb-3" style="padding-right:1%">
			<div class="col-12 col-md-6">
				<div class="Aligner-Header">
					<h3>Late Delivery/Shipping <span style="font-weight: 100;">MD to D</span>
						<span class="col-md-1 col-sm-1 col-2">
							<i class="fa fa-info-circle fa-sm" id="mdToD"></i>
							<b-popover
								target="mdToD"
								title="Late Delivery/Shipping MD to D"
								triggers="hover focus"
								placement="top"
								:content="infoPopup.mdToD"
							></b-popover>
						</span>
					</h3>
				</div>
			</div>
			<div class="col-12 col-md-6 row">
				<div class="col-12 col-md highlightFilter">
					<Multiselect
						class="mt-1"
						:options="options.region"
						:custom-label="opt => options.region.find(x => x == opt).name"
						v-model="filter.mdToD.maindealer"
						:allow-empty="false"
						placeholder="All Main Dealer"
					/>
				</div>
				<div class="col-12 col-md highlightFilter">
					<Multiselect
						class="mt-1"
						:options="options.branch"
						:custom-label="opt => options.branch.find(x => x == opt).name"
						v-model="filter.mdToD.dealer"
						placeholder="All Branch"
					/>
				</div>
				<div class="col-12 col-md highlightFilter">
					<button
						class="mt-1 btn highlightFilterBtn"
						@click="downloadMdToD"
					>
						Download <i class="fas fa-download"></i>
					</button>
				</div>
			</div>
		</div>
		<div class="content-outer">
			<div class="content-inner" style="padding: 0 3%">
				<late-delivery-shipping-md-to-d
					:data="data.mdToD"
				></late-delivery-shipping-md-to-d>
			</div>
		</div>
		<!-- Late Delivery/Shipping MD to D End -->

		<!-- Late Delivery/Shipping Transporter MD to D Start -->
		<!-- <div class="Aligner-Header">
			<h3>Late Delivery/Shipping Transporter</h3>
		</div>
		<div class="row mt-2 mb-3" style="padding-right:1%">
			<div class="col-md-5 col-12 row" style="padding-top:6px;">
				<div class="col" style="padding-right:0">
					<button 
						style="border-radius : 6px 0px 0px 6px"
						type="button" 
						class="w-100 btn" 
						:class="{'btn-outline-primary' : lateDeliveryShippingTransporterButton != 'MD', 'btn-primary' : lateDeliveryShippingTransporterButton == 'MD'}" 
						@click="lateDeliveryShippingTransporterButton = 'MD'"
					>
						Main Dealer
					</button>
				</div>
				<div class="col" style="padding-left:0">
					<button 
						style="border-radius : 0px 6px 6px 0px"
						type="button" 
						class="w-100 btn" 
						:class="{'btn-outline-primary' : lateDeliveryShippingTransporterButton != 'D', 'btn-primary' : lateDeliveryShippingTransporterButton == 'D'}" 
						@click="movementStockButtonOptions = 'D'"
					>
						Dealer
					</button>
				</div>
			</div>
			<div class="col-md-5 col-12"></div>
			<div class="col-12  col-md-2 row">
				<div class="col-12 col-md highlightFilter">
				<Multiselect 
					class="mt-1 "
					:options="options.transporter"
					:searchable="true"
					:close-on-select="true"
					:show-labels="false"
					v-model="filter.lateDeliveryShippingTransporterMdToD.transporter"
					placeholder="All Transporter"
				/>
				</div>
			</div>
		</div>
		<div class="content-outer">
			<div class="content-inner" style="padding: 0 3%">
				<late-delivery-shipping-transporter-md-to-d
				></late-delivery-shipping-transporter-md-to-d>
			</div>
		</div> -->
		<!-- Late Delivery/Shipping Transporter MD to D End -->

		<!-- Download Detail Start -->
		<download-detail
			v-if="modal.download"
			@closeModal="modal.download = false"
		></download-detail>
		<!-- Download Detail End -->

	</div>
</template>

<script>
	import Multiselect  from 'vue-multiselect'

	import lateDeliveryShippingAhmToMd from './../children/lateDeliveryShipping/ahmToMd.vue'
	import lateDeliveryShippingMdToD from './../children/lateDeliveryShipping/mdToD.vue'
	import lateDeliveryShippingTransporterMdToD from './../children/lateDeliveryShipping/transporterMdToD.vue'

	import downloadDetail from './../children/lateDeliveryShipping/downloadDetailModal'

	import listUrl from './../listUrl.js'

export default {
	data () {
		return {
			infoPopup : this.$store.state.hoverInformation.highlight.lateDeliveryAndShipping,
			modal : {
				download : false,
			},
			lateDeliveryShippingTransporterButton : 'MD',
		}
	},
	props: ['options' , 'period', 'mobile', 'filter', 'data'],
	components: {
		Multiselect ,
		lateDeliveryShippingAhmToMd,
      	lateDeliveryShippingMdToD,
		lateDeliveryShippingTransporterMdToD,
		
		downloadDetail,
	},
	watch : {
		"filter.mdToD": {
			deep: true,
			handler: function () {
				let url = listUrl.stringUrl.lateDeliveryAndShipping.mdToD
				let params = listUrl.param.lateDeliveryAndShipping.mdToD

				this.$emit('loadAPI' , url , params )
			}
		},
	},
	methods : {
		downloadMdToD () {
			// console.log(this.period);
			// console.log(this.filter.mdToD.maindealer ? this.filter.mdToD.maindealer.loc_code : 'nada');
			// console.log(this.filter.mdToD.dealer ? this.filter.mdToD.dealer.loc_code : 'nada');

			let url = `/api/highlight/late-delivery-shipping/export-md-to-d?period=${this.period}`

			// Main Dealer
			if (this.filter.mdToD.maindealer) url += `&maindealer=${this.filter.mdToD.maindealer.loc_code}`;
			else return;

			// Dealer
			if (this.filter.mdToD.dealer) url += `&dealer=${this.filter.mdToD.dealer.loc_code}`;

			this.$http.get(url)
			.then((resp)=>{
				this.exportExcel(resp.data.data, `Late Delivery/Shipping MD(${this.filter.mdToD.maindealer.loc_code}) to D(${this.filter.mdToD.dealer ? this.filter.mdToD.dealer.loc_code : 'All Dealer'})`)
			})
			.catch((err)=>{console.log(err)})
		}
	}
}
</script>

<style scoped>
	.btn-primary {
		background-color: #CB2127;
		border-color: #CB2127;
	}

	.btn-outline-primary {
		border-color: #CB2127;
		color: #CB2127;
		box-shadow: 0 0 0 1px #CB2127 inset;
	}

	.btn-outline-primary:hover {
		background-color: #CB2127;
		color: white;
	}

	.btn-outline-primary:focus {
		background-color:#CB2127;
	}
</style>