<template>
  <div>
    <h3 class='text-center mt-2'>Aging Range By Segments
      <span class="col-md-1 col-sm-1 col-2">
          <i class="fa fa-info-circle fa" id="agingRangeBySegments"></i>
          <b-popover
          target="agingRangeBySegments"
          title="Aging Range By Segments"
          triggers="hover focus"
          placement="top"
          :content="infoPopup.agingRangeBySegments"
          ></b-popover>
      </span>
    </h3>
    <hr>
    <div ref='minMaxAging' style='min-height:500px'></div>
  </div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import kelly from '@amcharts/amcharts4/themes/kelly.js'
import animated from '@amcharts/amcharts4/themes/animated.js'

am4core.useTheme(kelly)
am4core.useTheme(animated)

export default {
  props: ['data'],
  data () {
    return {
      chart: null,
      infoPopup: this.$store.state.hoverInformation.h1.stockDetails.agingStock
    }
  },
  mounted () {
    this.createChart()
    this.loadData()
  },
  watch: {
    '$store.state.updateData': function () {
      console.log(this.$store.state.filterUrl)
      this.loadData()
    }
  },
  methods: {
    loadData () {
      let length = this.chart.data.length

      for (var i = 0 ; i < this.data.length ; i++) { 
        this.data[i].min = Math.round(this.data[i].minimum / 24 * 100) /100
        this.data[i].min_day = Math.floor(this.data[i].min)
        this.data[i].min_hour = Math.floor((this.data[i].min % 1) * 24)

        this.data[i].max = Math.round(this.data[i].maximum / 24 * 100) /100
        this.data[i].max_day = Math.floor(this.data[i].max)
        this.data[i].max_hour = Math.floor((this.data[i].max % 1) * 24)
      }

      (this.data)
      this.chart.addData(this.data, length)
    },
    createChart () {
      this.chart = am4core.create(this.$refs.minMaxAging, am4charts.XYChart)

      // this.chart.data = data
      let categoryAxis = this.chart.yAxes.push(new am4charts.CategoryAxis())
      categoryAxis.renderer.grid.template.location = 0
      categoryAxis.dataFields.category = 'segment'
      categoryAxis.renderer.minGridDistance = 15
      categoryAxis.renderer.grid.template.location = 0.5
      categoryAxis.renderer.grid.template.strokeDasharray = '1,3'
      categoryAxis.renderer.labels.template.horizontalCenter = 'center'
      categoryAxis.renderer.labels.template.location = 0.5
      categoryAxis.title.text = 'Motor Segments'
      categoryAxis.title.fontWeight = 'bold'
      // categoryAxis.renderer.inside = true

      let valueAxis = this.chart.xAxes.push(new am4charts.ValueAxis())
      valueAxis.tooltip.disabled = true
      valueAxis.min = 0
      valueAxis.renderer.ticks.template.disabled = true
      valueAxis.renderer.axisFills.template.disabled = true
      valueAxis.title.text = 'Aging (Days)'
      valueAxis.title.fontWeight = 'bold'

      let series = this.chart.series.push(new am4charts.ColumnSeries())
      series.dataFields.categoryY = 'segment'
      series.dataFields.openValueX = 'min'
      series.dataFields.valueX = 'max'
      series.tooltipText = 'Minimum Aging : {min_day} Days {min_hour} Hours \n  Maximum Aging : {max_day} Days {max_hour} Hours'
      series.sequencedInterpolation = true
      // series.fillOpacity = 0
      series.strokeOpacity = 1
      series.columns.template.height = 2
      series.tooltip.pointerOrientation = 'horizontal'

      let openBullet = series.bullets.create(am4charts.CircleBullet)
      openBullet.locationX = 1

      let closeBullet = series.bullets.create(am4charts.CircleBullet)

      closeBullet.fill = this.chart.colors.getIndex(4)
      closeBullet.stroke = closeBullet.fill

      this.chart.cursor = new am4charts.XYCursor()
    }
  }
}
</script>
