<template>
    <transition name="modal">
          <div class="modal-mask">
              <div class="modal-wrapper" @click="$emit('closeModal')">
                  <div class="row">
                      <div class="col-xs-0 col-sm-0 col-md-1 col-lg-2"></div>
                      <div class="col-xs-12 col-sm-12 col-md-10 col-lg-8">
                          <div class="modal-container" @click.stop>
  
                              <div class="modal-header row" style="justify-content: flex-end;">
                                  <button type="button" class="close" @click="$emit('closeModal')">
                                      <span aria-hidden="true">&times;</span>
                                  </button>
                              </div>
                              
                              <div class="modal-body">
                                  <h3>{{data ? 'Edit' : 'Add'}} Color</h3>
                                  <hr>
  
                                  <template v-if="currData">
                                      <div class="row mt-1" v-for="key in Object.keys(currData)" :key="key">
                                          <div class="col-md-4 mt-auto mb-auto">
                                              <h5 class="mt-3">{{prettifyKeys(key)}}</h5>
                                          </div>
                                          <div class="col-md-1 mt-auto mb-auto">
                                              <h5 class="mt-3">:</h5>
                                          </div>
                                          <div class="col-md-7" v-if="key == 'H1' || key == 'H2' || key == 'H3' || key == 'is_active'">
                                              <div class="form-check form-check-inline mt-3">
                                                  <input class="form-check-input" type="radio" :id="'inlineRadio1' + key" :value="1" v-model="currData[key]">
                                                  <label class="form-check-label" :for="'inlineRadio1' + key">Active</label>
                                              </div>
                                              <div class="form-check form-check-inline mt-3">
                                                  <input class="form-check-input" type="radio" :id="'inlineRadio2' + key" :value="0" v-model="currData[key]">
                                                  <label class="form-check-label" :for="'inlineRadio2' + key">Not Active</label>
                                              </div>
                                          </div>
                                          <div class="col-md-7 dateCss" v-else>
                                              <input type="text" class="form-control" :disabled="key == 'id'" v-model="currData[key]"/>
                                          </div>
  
                                      </div>
                                  </template>
  
                                  <div class="row mt-2">
                                      <div class="offset-md-8 col-md-2">
                                          <button class="btn btn-primary w-100" @click="submit">Submit</button>
                                      </div>
                                      <div class="col-md-2">
                                          <button class="btn btn-danger w-100" @click="$emit('closeModal')">Cancel</button>
                                      </div>
                                  </div>
                                      
                              </div>
                          </div>
                      </div>
                      <div class="col-xs-0 col-sm-0 col-md-1 col-lg-2"></div>
                  </div>
              </div>
          </div>
    </transition>
  </template>
  
  <script>
    import MultiSelect from 'vue-multiselect';
    
    
  export default{
      data () {
          return{
              currData : null
          }
      },
      props: ['data'],
      components : {
            MultiSelect,
      },
      mounted () {
          if(this.data){
              this.currData = Object.assign({} , this.data)
            //   this.currData.lat = null
            //   this.currData.lng = null
            //   this.currData.H1 = this.currData.H1 == '✔' ? 1 : 0
            //   this.currData.H2 = this.currData.H2 == '✔' ? 1 : 0
            //   this.currData.H3 = this.currData.H3 == '✔' ? 1 : 0
            //   this.currData.is_active = this.currData.is_active == '✔' ? 1 : 0
            //   delete this.currData.created_at
            //   delete this.currData.updated_at
            //   delete this.currData.created_by
            //   delete this.currData.updated_by
          }
          else {
              this.currData = Object.assign({} , {
                color_code : '',
                color_desc : ""
              })
          }
          console.log(this.currData)
              
      },
      watch : {
        
      },
      methods: {
          async submit () {
              let url = this.data ? '/api/master/update-color' : '/api/master/add-color'
              let method = this.data ? 'put' : 'post'
  
              let data = await this.processData(this.currData)
  
              this.$http[method](url , data)
              .then((resp) => {
                  // this.$store.dispatch('triggerAlert' , [true , 'Locations Has Been Saved'])
                  this.loadFilterData(['branch'] , ['branch'])
                  this.$emit('refreshData')
                  this.$emit('closeModal')
              })
              .catch((err) => {
                  console.log(err.response.data)
                  this.$store.dispatch('triggerAlert' , [false ,err.response.data.data.map(x => x.message)])
              })
          },
          async processData (data) {
              let newObj = {}
              let keys = Object.keys(data)
  
              for(var i = 0 ; i < keys.length ; i++) {
                  if(typeof data[keys[i]] != 'object') {
                      newObj[keys[i]] = data[keys[i]]
                  }
              }
  
              return newObj
          },
          prettifyKeys (key) {
              if(key.includes('_'))
                  key = key.split('_').join(' ')
              
              key = key.charAt(0).toUpperCase() + key.slice(1 , key.length)
  
              return key
          }
      }
  }
  </script>
  
  <style>
  
  </style>
  
  <style scoped>
    .modal-mask {
        position: fixed;
        z-index: 1020;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .3s ease;
    }
  
    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }
  
    .modal-container {
        width: 100%;
        margin: 0px auto;
        padding: 20px 30px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
        transition: all .3s ease;
        font-family: Helvetica, Arial, sans-serif;
    }
  
    .modal-header h3 {
        margin-top: 0;
        color: #42b983;
    }
  
    .modal-body {
        margin: 20px 0;
    }
  
    .modal-default-button {
        float: right;
    }
  
    /*
    * The following styles are auto-applied to elements with
    * transition="modal" when their visibility is toggled
    * by Vue.js.
    *
    * You can easily play with the modal transition by editing
    * these styles.
    */
  
    .modal-enter {
        opacity: 0;
    }
  
    .modal-leave-active {
        opacity: 0;
    }
  
    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
  
    /* MODAL STARTS */
    .modal-body{
        margin:0
    }
  
    .modal-body .moduleLine{
        margin-bottom: 0vh;
    }
  
    .modal-body .moduleLine a{
        text-decoration: none;
    }
  
    .modal-body .moduleLine .moduleBox:hover{
        background-color: #E0E0E0;
        transition: background-color 0.7s ease;
        color: #212121;
        transition: color 0.7s ease;
    }
  
    .modal-body .moduleLine .moduleBox{
        border:1px solid #BDBDBD;
        border-radius: 20px;
        padding: 20px 0;
        color:black;
        margin-bottom: 3vh;
    }
  
    .modal-body .moduleLine .moduleBox i{
        font-size:5vh;
    }
  
    h3.modalHeadline{
        margin:0 0 20px 0;
        font-weight: bold;
        text-align : center;
    }
    /* MODAL ENDS */
  </style>