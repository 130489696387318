<template>
  <div>
    <!-- FILTER STARTS -->
    <div>
      <filter-complete
        :page_name="'Replaced QR'"
        :seperated_range="true"
        :fetch_filter="filterUrl.initial"
        @onFilter="setFilterUrl"
        :popUp="infoPopup"
        :days_limit="31"
        export_url="/api/h2/replaced-qr/export"
      ></filter-complete>
    </div>
    <!-- FILTER ENDS -->

    <div class="p-4 mb-4 card">
      <h5>QR Replacements
        <span class="col-md-1 col-sm-1 col-2">
            <i class="fa fa-info-circle fa-lg" id="qrReplacement"></i>
            <b-popover
            target="qrReplacement"
            title="QR Replacements"
            triggers="hover focus"
            placement="top"
            :content="infoPopup.qrReplacement"
            ></b-popover>
        </span>
      </h5>
      <replaced-qr-by-date
        v-if="filterUrl.ready"
        :filterUrl="filterUrl.url"
      ></replaced-qr-by-date>
      <div class="row">
        <div class="col">
          <replaced-qr-by-date-table
            v-if="filterUrl.ready"
            :filterUrl="filterUrl.url"
            :time_choices="timeChoices"
            type="region"
          ></replaced-qr-by-date-table>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <replaced-qr-by-date-table
            v-if="filterUrl.ready"
            :filterUrl="filterUrl.url"
            :time_choices="timeChoices"
            type="dealer"
          ></replaced-qr-by-date-table>
        </div>
      </div>
    </div>
    <!-- <div class="mb-4 p-4 card">
      <h5>QR Replacements (By Region)</h5>
      <ReplacementByRegion class="w-100"></ReplacementByRegion>
    </div> -->
    <div class="mb-4 p-4 card">
      <h5>QR Replacement Reasons
        <span class="col-md-1 col-sm-1 col-2">
            <i class="fa fa-info-circle fa-lg" id="qrReplacementReasons"></i>
            <b-popover
            target="qrReplacementReasons"
            title="QR Replacements Reasons"
            triggers="hover focus"
            placement="top"
            :content="infoPopup.qrReplacementReasons"
            ></b-popover>
        </span>
      </h5>
      <replaced-qr-reason
        v-if="filterUrl.ready"
        :filterUrl="filterUrl.url"
      ></replaced-qr-reason>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import qs from 'qs'

import filterComplete from '@/components/miscellaneous/filter-complete'

import replacedQrByDate from './childComponents/ReplacedQrByDate'
import replacedQrByDateTable from './childComponents/ReplacedQrByDateTable'
import replacedQrReason from './childComponents/ReplacedQrReason'
import ReplacementByRegion from './childComponents/ReplacementByRegion'

export default {
  name: 'replaced-qr',
  metaInfo: {
    title: 'Replaced QR'
  },
  data () {
    return {
      filterUrl : {
        initial : 0,
        ready : false,
        url : '',
      },
      infoPopup: this.$store.state.hoverInformation.h2.replacedQr
    }
  },
  components: {
    filterComplete,

    replacedQrByDate,
    replacedQrByDateTable,
    replacedQrReason,
    ReplacementByRegion
  },
  watch: {
    sampleData: function () {
      this.calcTotalPerHour()
      this.monthLength = new Date(this.filter.year, this.filter.month + 1, 0).getDate()
    }
  },
  computed : {
    timeChoices(){
      let monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      let obj = qs.parse(this.filterUrl.url)

      let startDate = new Date(obj.start)
      let endDate = new Date(obj.end)

      //IF start < end
      if(startDate.getTime() > endDate.getTime()) return [{label:'Invalid Date',value:'-1'}]

      var endMonth = endDate.getMonth()
      var endYear = endDate.getFullYear()

      var currentStartMonth = startDate.getMonth();
      var currentEndMonth = 11;//default, december
      
      var final = []

      for (var i = startDate.getFullYear(); i <= endYear; i++) {
        //IF current selected year IS last selected year, set last month
        if(i == endYear) currentEndMonth = endMonth;
        for (var j = currentStartMonth; j <= currentEndMonth; j++){
          final.push({
            label : `${monthNames[j]} ${i}`,
            value : `${i}-${j}`,//Year - Month
            month : `${j+1}`,
            year : `${i}`,
          })
        }
        currentStartMonth = 0;
      }

      return final;
    }
  },
  mounted () {
    this.filterUrl.initial++;
  },
  methods: {
    setFilterUrl(urlString){
      this.filterUrl.url = urlString;
      this.filterUrl.ready = true;
    },
  }
}
</script>

<style scoped>
  .table-div{
      display: block;
      overflow: scroll;
  }
  .bold{
      font-weight: bold;
  }
  .no-data {
      text-align: center;
      /* width: 100%; */
  }
  .secondrow {
    margin-top: 20px;
  }
</style>
