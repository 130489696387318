<script>/* eslint-disable */</script>
<template>
  <div style="height:350px" ref="AverageAgingLineChart"></div>
</template>

<script>

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import animated from "@amcharts/amcharts4/themes/animated.js";

import moment from 'moment'

am4core.useTheme(animated);

export default {
  data(){
    return {
      chart: null,
      valueAxis : null
    }
  },
  mounted(){
    this.createChart();
    this.loadData()
  },
  beforeDestroy(){
    this.chart.dispose()
  },
  props: ['mdData' , 'dData', 'endData', 'type'],
  watch : {
    type: function() {
      this.loadData()
    }
  },
  methods: {
    loadData () {

      while(this.chart.series.length > 0) {
        this.chart.series.removeIndex(0).dispose();
      }

      let data = this.type == 'MD' ? this.mdData : this.type == 'D' ? this.dData : this.endData


      //DATA PROCESSING STARTS

      let length = this.chart.data.length
      if(data == null || data.length == 0){
        data = [{stock_date : moment().format('YYYY-MM-DD'), 'NO DATA' : 1}]
        this.createAxisAndSeries('NO DATA', 'NO DATA', false, 1);
        this.chart.addData(data, length)
        return;
      }
      let keys = Object.keys(data[0])

      for(var i = 0 ; i < keys.length ; i++) {
        if(keys[i] == 'stock_date')
          continue
        let titleAxis = "Average " + keys[i].split('_')[1].split('day')[1] + " Days"
  
        this.createAxisAndSeries(keys[i], titleAxis, false, i % 3);
      }

      this.chart.addData(data, length)
      
    },
    createChart () {
      this.chart = am4core.create(this.$refs.AverageAgingLineChart, am4charts.XYChart);
      
      this.chart.colors.step = 2;

      // Create axes
    //   let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
    //   dateAxis.renderer.minGridDistance = 50;
    //   dateAxis.title.text = "Stock Days"
      let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis())
      dateAxis.title.text = "Date"

      // Create series
      this.valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
      this.valueAxis.title.text = "No. of Avg Days"
      this.valueAxis.min = 0;

      // Add legend
      this.chart.legend = new am4charts.Legend();

      // this.chart.exporting.menu = new am4core.ExportMenu();

      // Add cursor
      this.chart.cursor = new am4charts.XYCursor();
    },
    createAxisAndSeries(field, name, opposite, bullet) {
        
        let series = this.chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = field;
        series.dataFields.dateX = "stock_date";
        series.strokeWidth = 2;
        series.name = name;
        series.tooltipText = "{name}: [bold]{valueY.formatNumber('#,###.')}";
        series.tensionX = 0.8;
        series.showOnInit = true;
        
        let interfaceColors = new am4core.InterfaceColorSet();
        
        switch(bullet) {
          case 0:
            let bullet = series.bullets.push(new am4charts.Bullet());
            bullet.width = 12;
            bullet.height = 12;
            bullet.horizontalCenter = "middle";
            bullet.verticalCenter = "middle";
            
            let triangle = bullet.createChild(am4core.Triangle);
            triangle.stroke = interfaceColors.getFor("background");
            triangle.strokeWidth = 2;
            triangle.direction = "top";
            triangle.width = 12;
            triangle.height = 12;
            break;
          case 1:
            let bullet1 = series.bullets.push(new am4charts.Bullet());
            bullet1.width = 10;
            bullet1.height = 10;
            bullet1.horizontalCenter = "middle";
            bullet1.verticalCenter = "middle";
            
            let rectangle = bullet1.createChild(am4core.Rectangle);
            rectangle.stroke = interfaceColors.getFor("background");
            rectangle.strokeWidth = 2;
            rectangle.width = 10;
            rectangle.height = 10;
            break;
          default:
            let bullet2 = series.bullets.push(new am4charts.CircleBullet());
            bullet2.circle.stroke = interfaceColors.getFor("background");
            bullet2.circle.strokeWidth = 2;
            break;
        }
        
        this.valueAxis.renderer.line.strokeOpacity = 1;
        this.valueAxis.renderer.line.strokeWidth = 2;
        this.valueAxis.renderer.line.stroke = series.stroke;
        this.valueAxis.renderer.labels.template.fill = series.stroke;
        this.valueAxis.renderer.opposite = opposite;
      }
  }
}
</script>