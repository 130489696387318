<template>
  <transition name="modal">
      <div class="modal-mask">
          <div class="modal-wrapper" @click="$emit('closeModal')">
              <div class="row">
                  <div class="col-xs-0 col-sm-0 col-md-1 col-lg-2"></div>
                  <div class="col-xs-12 col-sm-12 col-md-10 col-lg-8">
                      <div class="modal-container" @click.stop>

                        <div class="modal-header row" style="justify-content: flex-end;">
                          <button type="button" class="close" @click="$emit('closeModal')">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                          
                          <div class="modal-body">
                                <h3>Upload Target</h3>
                                <hr>
                                <h4>Download Template File</h4>
                                <div class="row mt-3">
                                    <!-- <div class="col-md-4 col-12">
                                        <a href="/template/target.csv" class="btn btn-success w-100">Download Sample File (CSV Version)</a>
                                    </div> -->
                                    <div class="col-md-4 col-12">
                                            <a href="/template/target.xlsx" class="btn btn-primary w-100">Download Sample File (XLSX Version)</a>
                                    </div>
                                </div>
                                <hr>
                                <div>
                                    
                                    <div class="form-group">
                                        <label class="text-right h5" for="uploadFile">Upload File (Max 2 Mb):</label>
                                        <br>
                                        <input type="file" @change="validateSize" class="form-control" name="Upload File"/>
                                        <br>
                                        <p v-if="fileError != ''" style="color:red">{{fileError}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4 col-md-8 col-xs-12 offset-lg-4 offset-md-2">
                                        <button class="btn btn-primary mt-3 mr-2" :disabled="!(fileError == '' && file != null)" v-on:click="uploadFile()">Submit File</button>
                                        <button class="btn btn-danger mt-3" v-on:click="$emit('closeModal')">Cancel</button>
                                    </div>
                                </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-xs-0 col-sm-0 col-md-1 col-lg-2"></div>
              </div>
          </div>
      </div>
  </transition>
</template>

<script>
  import MultiSelect from 'vue-multiselect';
  
  
  export default{
    data(){
      return{
          file: null,
          fileError: ''
      }
    },
    props:['tooltipMessage'],
    components : {
      MultiSelect,
    },
    mounted(){
    },
    watch : {
      
    },
    methods: {
        validateSize : function(data){
            var file = data.target.files[0];
            console.log(file.type)
            // if(file.type != "text/csv" && file.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
            if(file.type != "text/csv" && file.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && file.type != 'application/vnd.ms-excel')
            {
                this.fileError = "Unsupported File Type! Only accepts CSV or XLSX Files";
                return false;
            }

            var size  = file.size / 1024 / 1024;

            if(size > 2)
            {
                this.fileError = "File size is too big";
                return false;
            }

            this.fileError = "";
            this.file = file;
            return true;
        },
        uploadFile : function(){
            let vm = this;
            let url = "/api/target/uploadTargets"

            let formData = new FormData()

            formData.append('file' , this.file)
            this.$http.post(url , formData , {headers : {"Content-Type":'multipart/form-data'}})
            .then((resp)=>{
                let data = resp.data.data;

                if(data.error.length >= 1 ){
                    let error = [];
                    for(var i = 0; i < data.error.length ; i++)
                        error.push(data.error[i].error);
                    console.log(error);
                    throw new Error(error);
                }
                vm.$emit('triggerAlert', false, [`Target has been successfully uploaded`]);
                vm.$emit('closeModal')
            })
            .catch((err)=>{
                this.fileError = "Bulk Input Failed with Given Response From Server " + err;
            })
            
        }
    }
  }
</script>

<style>

</style>

<style scoped>
  .modal-mask {
      position: fixed;
      z-index: 1020;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .5);
      display: table;
      transition: opacity .3s ease;
  }

  .modal-wrapper {
      display: table-cell;
      vertical-align: middle;
  }

  .modal-container {
      width: 100%;
      margin: 0px auto;
      padding: 20px 30px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
      transition: all .3s ease;
      font-family: Helvetica, Arial, sans-serif;
  }

  .modal-header h3 {
      margin-top: 0;
      color: #42b983;
  }

  .modal-body {
      margin: 20px 0;
  }

  .modal-default-button {
      float: right;
  }

  /*
  * The following styles are auto-applied to elements with
  * transition="modal" when their visibility is toggled
  * by Vue.js.
  *
  * You can easily play with the modal transition by editing
  * these styles.
  */

  .modal-enter {
      opacity: 0;
  }

  .modal-leave-active {
      opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
  }

  /* MODAL STARTS */
  .modal-body{
      margin:0
  }

  .modal-body .moduleLine{
      margin-bottom: 0vh;
  }

  .modal-body .moduleLine a{
      text-decoration: none;
  }

  .modal-body .moduleLine .moduleBox:hover{
      background-color: #E0E0E0;
      transition: background-color 0.7s ease;
      color: #212121;
      transition: color 0.7s ease;
  }

  .modal-body .moduleLine .moduleBox{
      border:1px solid #BDBDBD;
      border-radius: 20px;
      padding: 20px 0;
      color:black;
      margin-bottom: 3vh;
  }

  .modal-body .moduleLine .moduleBox i{
      font-size:5vh;
  }

  h3.modalHeadline{
      margin:0 0 20px 0;
      font-weight: bold;
      text-align : center;
  }
  /* MODAL ENDS */
</style>