<template>
  <div ref="salesByRegion" style="height: 480px"></div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themesAnimated from '@amcharts/amcharts4/themes/animated'

export default {
  data () {
    return {
      chart: null
    }
  },
  mounted () {
    this.createChart()
    this.generateData()
  },
  props: ['data'],
  watch: {
    'data': function () {
      this.generateData()
    }
  },
  methods: {
    generateData () {
      var length = this.chart.data.length
      let data = this.data
      this.chart.addData(data, length)
    },
    createChart () {
      /* Chart code */
      // Themes begin
      am4core.useTheme(am4themesAnimated)
      // Themes end

      // Create chart instance
      this.chart = am4core.create(this.$refs.salesByRegion, am4charts.PieChart)

      // Add and configure Series
      let pieSeries = this.chart.series.push(new am4charts.PieSeries())
      pieSeries.dataFields.value = 'sold'
      pieSeries.dataFields.category = 'region'

      // Let's cut a hole in our Pie chart the size of 30% the radius
      this.chart.innerRadius = am4core.percent(30)

      // Put a thick white border around each Slice
      pieSeries.slices.template.stroke = am4core.color('#fff')
      pieSeries.slices.template.strokeWidth = 2
      pieSeries.slices.template.strokeOpacity = 1
      pieSeries.slices.template
        // change the cursor on hover to make it apparent the object can be interacted with
        .cursorOverStyle = [
          {
            'property': 'cursor',
            'value': 'pointer'
          }
        ]
      pieSeries.labels.template.text = "{category}: {value} ({value.percent.formatNumber('#.#')}%)"
      // pieSeries.alignLabels = false
      // pieSeries.labels.template.bent = true
      // pieSeries.labels.template.radius = 3
      // pieSeries.labels.template.padding(0, 0, 0, 0)

      // pieSeries.ticks.template.disabled = true

      // Create a base filter effect (as if it's not there) for the hover to return to
      let shadow = pieSeries.slices.template.filters.push(new am4core.DropShadowFilter())
      shadow.opacity = 0

      // Create hover state
      let hoverState = pieSeries.slices.template.states.getKey('hover') // normally we have to create the hover state, in this case it already exists

      // Slightly shift the shadow and make it more prominent on hover
      let hoverShadow = hoverState.filters.push(new am4core.DropShadowFilter())
      hoverShadow.opacity = 0.7
      hoverShadow.blur = 5

      // Add a legend
      this.chart.legend = new am4charts.Legend()
    }
  }
}
</script>

<style scoped>

</style>
