<template>
  <div ref='vehicleServiceType' style='min-height:450px'></div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themesAnimated from '@amcharts/amcharts4/themes/animated.js'

export default {
  data () {

  },
  mounted () {
    this.createChart()
  },
  methods: {
    createChart () {
      /* Chart code */
      // Themes begin
      am4core.useTheme(am4themesAnimated)
      // Themes end

      // Create chart instance
      let chart = am4core.create(this.$refs.vehicleServiceType, am4charts.PieChart)

      // Add data
      chart.data = [
        { 'sector': 'Service Lengkap', 'size': 10.0 },
        { 'sector': 'Heavy Repair', 'size': 0.0 },
        { 'sector': 'Light Repair', 'size': 0.0 },
        { 'sector': 'Service Ringan', 'size': 0.0 },
        { 'sector': 'Ganti Oli', 'size': 0.0 },
        { 'sector': 'Others', 'size': 0.0 }
      ]

      // Add label
      chart.innerRadius = 100
      // let label = chart.seriesContainer.createChild(am4core.Label)
      // label.text = '30,000,000'
      // label.horizontalCenter = 'middle'
      // label.verticalCenter = 'middle'
      // label.fontSize = 50

      // Add and configure Series
      let pieSeries = chart.series.push(new am4charts.PieSeries())
      pieSeries.dataFields.value = 'size'
      pieSeries.dataFields.category = 'sector'
    }
  }
}
</script>
