<template>
	<div>
		<!-- Total Stock Overview Start -->
		<div class="row mt-4">
			<div class="col-12 col-md-4 Aligner-Header">
				<h3>Total Stock Overview
					<span class="col-md-1 col-sm-1 col-2">
						<i class="fa fa-info-circle fa-sm" id="totalStockOverview"></i>
						<b-popover
						target="totalStockOverview"
						title="Total Stock Overview"
						triggers="hover focus"
						placement="top"
						:content="infoPopup.totalStockOverview"
						></b-popover>
					</span>
				</h3>
			</div>
			<div class="col-md-2 offset-md-4 text-right">
				<span title="This applies to all data below">
					<toggle-button 
						v-model="selected.date_filter"
						:width="100"
						:height="30"
						color="#7dCE94" 
						:sync="true" 
						:labels="{unchecked: 'Semua', checked: 'Dengan QR'}"
					/>
				</span>
			</div>
			<div class="col-md-2" style="padding:0 24px 0 12px">
				<flat-pickr
					class="mt-3 mb-3"
					v-model="selected.date"
					:config="rangeConfig"
					:placeholder="!isIEMode ? 'Range picker' : null"
				/>
			</div>
		</div>
		<stats :data="data.stockOverview"></stats>

		<!-- Main Dealer Stock Start -->
		<div class="row mt-5 mb-3" style="padding-right:1%">
			<div class="col-12 col-md-6 Aligner-Header">
				<h3>Main Dealer Stock
					<span class="col-md-1 col-sm-1 col-2">
						<i class="fa fa-info-circle fa-sm" id="mainDealerStock"></i>
						<b-popover
						target="mainDealerStock"
						title="Main Dealer Stock"
						triggers="hover focus"
						placement="top"
						:content="infoPopup.mainDealerStock"
						></b-popover>
					</span>
				</h3>
				
			</div>
			<div class="col-12 col-md-6 row">
				<div class="col-12 col-md highlightFilter">
				<Multiselect 
					class="mt-1 "
					:options="options.region"
					:searchable="true"
					:close-on-select="true"
					label="name"
					:show-labels="false"
					v-model="filter.mainDealerStockOptions.region"
					placeholder="All Regions"
				/>
				</div>
				<div class="col-12 col-md highlightFilter">
				<Multiselect 
					class="mt-1 "
					:options="options.segment"
					:searchable="true"
					label="segment"
					:close-on-select="true"
					:show-labels="false"
					v-model="filter.mainDealerStockOptions.segment"
					placeholder="All Segments"
				/>
				</div>
				<div class="col-12 col-md highlightFilter">
				<Multiselect 
					class="mt-1 "
					:options="options.color"
					:searchable="true"
					label="name"
					:close-on-select="true"
					:show-labels="false"
					v-model="filter.mainDealerStockOptions.color"
					placeholder="All Color"
				/>
				</div>
				<!-- <div class="col-12 col-md highlightFilter pt-1">
					<flat-pickr
						class="mt-3 mb-3"
						v-model="filter.mainDealerStockOptions.date"
						:config="rangeConfig"
						:placeholder="!isIEMode ? 'Range picker' : null"
					/>
				</div> -->
			</div>
			</div>
			<div class="content-outer">
			<div class="content-inner" style="padding: 0 3%">
				<main-dealer-stock
					v-if="data.mainDealerStock"
					:data="data.mainDealerStock"
				></main-dealer-stock>
				<div
					v-else
					style="width: 100%; text-align: center; padding: 50px 0;"
				>
					<cube :loading="true"></cube>
				</div>
			</div>
			</div>
			<!-- Main Dealer Stock End -->

			<!-- Dealer Stock Start -->
			<div class="row mt-5 mb-3" style="padding-right:1%">
			<div class="col-12 col-md-10 Aligner-Header">
				<h3>Dealer Stock In Region
					<span class="col-md-1 col-sm-1 col-2">
						<i class="fa fa-info-circle fa-sm" id="dealerStockInRegion"></i>
						<b-popover
						target="dealerStockInRegion"
						title="Dealer Stock In Region"
						triggers="hover focus"
						placement="top"
						:content="infoPopup.dealerStockInRegion"
						></b-popover>
					</span>
				</h3>
			</div>
			<div class="col-12 col-md-2 row">
				<div class="col-12 col-md highlightFilter">
				<Multiselect 
					class="mt-1"
					:options="options.region"
					:searchable="true"
					:close-on-select="true"
					:show-labels="false"
					label="name"
					:allow-empty="true"
					v-model="filter.dealerStockOptions.region"
					placeholder="All Region"
				/>
				</div>
			</div>
			</div>
			<div class="content-outer">
			<div class="content-inner" style="padding: 0 3%;">
				<dealer-stock
					v-if="data.dealerStock"
					:data="data.dealerStock"
				></dealer-stock>
				<div
					v-else
					style="width: 100%; text-align: center; padding: 50px 0;"
				>
					<fold :loading="true"></fold>
				</div>
			</div>
		</div>
		<!-- Dealer Stock End -->
	</div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import Multiselect  from 'vue-multiselect';
import moment from 'moment';
import { ToggleButton } from 'vue-js-toggle-button'
import { FoldSpinner, CubeSpinner } from 'vue-spinners'

import stats from './../children/stats.vue'
import mainDealerStock from './../children/mainDealerStock.vue'
import dealerStock from './../children/dealerStock.vue'
import listUrl from './../listUrl.js'

export default {
	data () {
		return {
            rangeConfig: {
                altInput: true,
                altInputClass: 'form-control input hso-highlight-flatpickr w-100',
                animate: !this.isRTL()
            },
			infoPopup : this.$store.state.hoverInformation.highlight.stockOverview,
			selected : {
				date_filter : true,
				date : moment().format("YYYY-MM-DD"),
			},
		}
	},
	props: ['options', 'period', 'data', 'filter'],
	components: {
      	fold : FoldSpinner,
		cube : CubeSpinner,
		flatPickr,
		Multiselect,
		ToggleButton,
		// Components
		stats,
		mainDealerStock,
		dealerStock
	},
	watch : {
		"filter.mainDealerStockOptions": {
			deep: true,
			handler: function () {
				let url = listUrl.stringUrl.stockOverview.mainDealerStock
				let params = listUrl.param.stockOverview.mainDealerStock

				this.$emit('loadAPI' , url , params )
			}
		},
		"filter.dealerStockOptions": {
			deep: true,
			handler: function () {
				let url = listUrl.stringUrl.stockOverview.dealerStock
				let params = listUrl.param.stockOverview.dealerStock

				this.$emit('loadAPI' , url , params )
			}
		},
		"filter.stockOverview": {
			deep: true,
			handler: function () {
				let url = listUrl.stringUrl.stockOverview.stats
				let params = listUrl.param.stockOverview.stats

				this.$emit('loadAPI' , url , params )
			}
		},
		"selected.date_filter"(){
			let value = this.selected.date_filter ? 1 : 0

			this.filter.stockOverview.date_filter = value
			this.filter.mainDealerStockOptions.date_filter = value
			this.filter.dealerStockOptions.date_filter = value
		},
		"selected.date" () {
			this.filter.stockOverview.date = this.selected.date
			this.filter.mainDealerStockOptions.date = this.selected.date
			this.filter.dealerStockOptions.date = this.selected.date
		},
	},
}
</script>

<style>
.hso-highlight-flatpickr {
    border: 0;
    background: #CB2127 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 0px 6px #0000001A;
    border-radius: 5px !important;
    padding: 7px 0px 7px 8px !important;
    height: 40px;
}

.hso-highlight-flatpickr:focus {
    border: 0;
    box-shadow: 0px 0px 6px #0000001A;
}

.hso-highlight-flatpickr:hover{
	cursor: pointer;
}

.hso-highlight-flatpickr.input{
	color: white;
}
</style>