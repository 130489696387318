// const urlStart = 'http://localhost:8000'
var environment = 'staging'
var serverUrl = 'https://mon.qtrust.id:40013';
var serviceName = 'asmoid-dashboard';
var secretToken = 'PpUw5ZibLVZFORV';
const gaId = 'G-9HQMCWL639';
const urlStart = 'https://sasmoid-dash.qtrust.id/'

export default { environment ,gaId, serverUrl, serviceName, secretToken ,urlStart }

