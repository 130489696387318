<template>
  <div>
    <!--Filter Option-->
    <b-card title="Stock" :sub-title="infoPopup.title" header-tag="h6" class="mb-4">
      <hr>
        <b-form>
            <b-form-row>
              <b-form-group label="Stock Date" class="col-md-6">
                <flat-pickr class="mt-1" v-model="filter.date" :config="rangeConfig" :placeholder="!isIEMode ? 'Select From Date' : null"/>
              </b-form-group>
              <b-form-group label="Engine Number" class="col-md-6">
                  <b-input class="mt-1" type="text" placeholder="Engine Number" v-model="filter.engine_no" />
              </b-form-group>
            </b-form-row>
            <b-form-row>
              <b-form-group label="Region" class="col-md-3">
                <Multiselect
                  v-model="filter.region"
                  class="mt-1"
                  :options="options.region.map(x => x.loc_code)"
                  :custom-label="opt => options.region.find(x => x.loc_code == opt).name"
                  placeholder="Choose Region"
                ></Multiselect>
              </b-form-group>
              <b-form-group label="Branch" class="col-md-3">
                <Multiselect
                  v-model="filter.branch"
                  class="mt-1"
                  :options="options.branch.map(x => x.loc_code)"
                  :custom-label="opt => options.branch.find(x => x.loc_code == opt).name"
                  placeholder="Choose Branch"
                ></Multiselect>
              </b-form-group>
              <b-form-group label="Type" class="col-md-3">
                <Multiselect                  
                  v-model="filter.type"
                  class="mt-1"
                  :options="options.type.map(typ => typ.type)"
                  :custom-label="opt => options.type.find(x => x.type == opt).type"
                  :searchable="true"
                  :close-on-select="true"
                  :multiple="false"
                  placeholder="Choose Type"
                ></Multiselect>
              </b-form-group>
              <b-form-group label="Inventory Age" class="col-md-3">
                <Multiselect
                  v-model="filter.time"
                  class="mt-1"
                  :options="options.time.map(typ => typ.value)"
                  :custom-label="opt => options.time.find(x => x.value == opt).title"
                  :searchable="true"
                  :close-on-select="true"
                  :multiple="false"
                  placeholder="Choose Inventory Age"
                ></Multiselect>
              </b-form-group>
            </b-form-row>
            <b-btn @click="vue_universal_table.update++" :disabled="isFetching" class="col-md-2" type="button" variant="primary"><span class="fa fa-filter"></span>&nbsp;&nbsp;Search</b-btn>
            <b-btn @click="downloadExcel" :disabled="isFetching" class="col-md-2" type="button" variant="success"><span class="fa fa-file-excel"></span>&nbsp;&nbsp;Export To Excel</b-btn>
        </b-form>
    </b-card>

    <b-card class="mb-4">
      <vue-universal-table
        :api_url="'/api/reports/stock'"
        :update="vue_universal_table.update"
        :fields="vue_universal_table.fields"
        :filter="filter"
        :fixed_header="true"
        :infinite_scroll="true"
        @fetchStart="isFetching = true"
        @fetchEnd="isFetching = false"
      ></vue-universal-table>
    </b-card>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import Multiselect from 'vue-multiselect'

export default {
  metaInfo: {
    title: 'Stock Report'
  },
  data () {
    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    const endOfMonth   = moment().format('YYYY-MM-DD');
    return {
      isFetching : false,

      infoPopup : this.$store.state.hoverInformation.report.stock,
      rangeConfig: {
        altInput: true,
        animate: !isRTL()
      },
      options : {
        type : this.$store.getters.getSimpleType(),
        time : [
          {title : '< 7 Days', value : '7'},
          {title : '7 to 30 Days', value : '30'},
          {title : '30 to 90 Days', value : '90'},
          {title : '90 to 180 Days', value : '180'},
          {title : '> 180 Days', value : '181'},
        ],
        region : this.$store.getters.getRegion,
        branch : this.$store.getters.getSimpleBranch(),
      },
      selected : {
        region : null,
      },
      filter : {
        date : startOfMonth,
        engine_no : '',
        region : [],
        branch : [],
        time : [],
        type : [],
      },
      vue_universal_table : {
        fields : [
          {
            title : 'Current Region',
            variable : 'current_region'
          },
          {
            title : 'Current Branch',
            variable : 'current_branch'
          },
          {
            title : 'Engine Number',
            variable : 'engine_no'
          },
          {
            title : 'Frame Number',
            variable : 'frame_no'
          },
          {
            title : 'Type',
            variable : 'type'
          },
          {
            title : 'Color',
            variable : 'color_code'
          },
          {
            title : 'Inventory Age',
            variable : 'inventory_age'
          },
          {
            title : 'Last Scanned',
            variable : 'last_scan_date'
          }
        ],
        update : 0,
      }
    }
  },
  components: {
    flatPickr,
    Multiselect
  },
  mounted () {

  },
  watch : {
    'filter.region' () {
      this.filter.branch = null;
      this.options.branch = this.$store.getters.getSimpleBranch(this.filter.region)
    },
  },
  methods: {
    async downloadExcel (filter) {
      let url = '/api/reports/stock/download-excel?' + await this.initialiseFilter()

      this.isFetching = true;

      this.$http.get(url , {responseType : 'blob'})
      .then((resp)=>{
        const blob = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = "Stock Data (" + this.filter.start + " to " + this.filter.end + ")";
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((err)=>{
        console.log(err);
      })
      .finally(()=>{
        this.isFetching = false;
      })
    },
    initialiseFilter(){
      if(!(typeof this.filter === 'object' && this.filter !== null)){
        this.filterUrl = '';
        return;
      }
      var keys = Object.keys(this.filter);
      let stringUrl = '';
      var queryParams = this.filter;

      for(var i = 0 ; i < keys.length; i++){
        if(!queryParams[keys[i]])
          continue;

        if(queryParams[keys[i]] == '' || queryParams[keys[i]].length == 0)
          continue 

        if((keys[i] == 'user_type') && queryParams[keys[i]] == 'All')
          continue;
        
        // if(keys[i] == 'start_hour' || keys[i] == 'end_hour')
        //   queryParams[keys[i]] += ":00";

        stringUrl += `&${keys[i]}=${queryParams[keys[i]]}`;
        
      }
      return stringUrl;
    },
  }
}

function isRTL () {
  return document.documentElement.getAttribute('dir') === 'rtl' || document.body.getAttribute('dir') === 'rtl'
}
</script>

<style>
  .dateCss div input{
    border: 1px solid #e8e8e8;
    border-radius: 5px !important;
    height: 42px;
    margin-top: 3px;
    padding-left: 1% !important;
  }
  .dateCss div input::placeholder{
    padding-left: 1%;
  }
</style>
