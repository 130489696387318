<template>
  <div ref="byRegion" style="min-height: 700px"></div>
</template>

<script>
  import * as am4core from "@amcharts/amcharts4/core";
  import * as am4charts from "@amcharts/amcharts4/charts";

  export default {
    data () {
      return {
        chart : null,
      }
    },
    props : ['filterUrl'],
    mounted () {
      this.createChart();
      this.fetchData();
    },
    watch : {
      'filterUrl' : function () {
        this.fetchData();
      }
    },
    methods : {
      fetchData() {
        let vm = this;
        var length = vm.chart.data.length;
        console.log("test");

        this.$http.get('/api/h2/qr-registration/by-region?' + this.filterUrl)
        .then((resp)=>{
          var data = resp.data.data;
          for(var i = 0; i < data.length; i++){
            data[i].color = vm.chart.colors.next();
            data[i].region = data[i].current_region + " - " + data[i].region_name
          }
          vm.chart.addData(data, length);
        })
        .catch((err)=>{
          console.log(err);
        })
      },
      createChart () {
        this.chart = am4core.create(this.$refs.byRegion, am4charts.XYChart);

        // Create axes
        let categoryAxis = this.chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "region";
        categoryAxis.numberFormatter.numberFormat = "#";
        categoryAxis.renderer.inversed = true;

        let  valueAxis = this.chart.xAxes.push(new am4charts.ValueAxis()); 
        valueAxis.renderer.opposite = true;

        // Create series
        let series = this.chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueX = "count";
        series.dataFields.categoryY = "region";
        series.name = "Qty";
        series.columns.template.propertyFields.fill = "color";
        series.columns.template.tooltipText = "{valueX}";
        series.columns.template.column.stroke = am4core.color("#fff");
        series.columns.template.column.strokeOpacity = 0.2;
      }
    }
  }
</script>

<style scoped>

</style>