<script>/* eslint-disable */</script>
<template>
  <div ref='byRegion' style='min-height:650px'></div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import spiritedaway from '@amcharts/amcharts4/themes/spiritedaway.js'

am4core.useTheme(spiritedaway)

export default {
  mounted () {
    this.createChart()
    this.loadData()
  },
  props: ['data'],
  data () {
    return {
      chart: null
    }
  },
  methods: {
    loadData () {
      let length = this.chart.data.length
      for (var i = 0 ; i < this.data.length ; i++) {
        this.data[i].average = Math.round(this.data[i].average / 24 * 100) /100
        this.data[i].day = Math.floor(this.data[i].average)
        this.data[i].hour = Math.floor((this.data[i].average % 1) * 24)
        this.data[i].color = this.chart.colors.next()
      }
      this.chart.addData(this.data , length)
    },
    createChart () {
      this.chart = am4core.create(this.$refs.byRegion, am4charts.XYChart)

      let categoryAxis = this.chart.yAxes.push(new am4charts.CategoryAxis())
      categoryAxis.dataFields.category = 'region'
      categoryAxis.numberFormatter.numberFormat = '#'
      categoryAxis.renderer.inversed = true
      categoryAxis.renderer.grid.template.location = 0
      categoryAxis.renderer.cellStartLocation = 0.1
      categoryAxis.renderer.cellEndLocation = 0.9

      let valueAxis = this.chart.xAxes.push(new am4charts.ValueAxis())
      valueAxis.renderer.opposite = true

      this.chart.legend = new am4charts.Legend()

      this.createSeries('average', 'Average Stock By Time')
    },
    createSeries (field, name) {
      let series = this.chart.series.push(new am4charts.ColumnSeries())
      series.dataFields.valueX = field
      series.dataFields.categoryY = 'region'
      series.name = name
      series.columns.template.tooltipText = '{name}: [bold]{day} Days {hour} H'
      series.columns.template.height = am4core.percent(100)
      series.sequencedInterpolation = true

      let categoryLabel = series.bullets.push(new am4charts.LabelBullet())
      categoryLabel.label.horizontalCenter = 'right'
      categoryLabel.label.dx = -10
      categoryLabel.label.fill = am4core.color('#fff')
      categoryLabel.label.hideOversized = false
      categoryLabel.label.truncate = false
    }
  }
}
</script>
