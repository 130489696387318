<template>
	<div>
		<!-- Teritory Management Start-->
		<div class="row mt-5" style="padding-right:1%">
			<div class="col-12 col-md-4 Aligner-Header mt-4">
				<h3>Territory Management
					<span class="col-md-1 col-sm-1 col-2">
						<i class="fa fa-info-circle fa-sm" id="territoryManagement"></i>
						<b-popover
						target="territoryManagement"
						title="Territory Management"
						triggers="hover focus"
						placement="top"
						:content="infoPopup.territoryManagement"
						></b-popover>
					</span>
				</h3>
				<span>Choose 1 Area To Use This Feature</span>
			</div>
			<div class="col-12 col-md-8 row">
				<div class="col-12 col-md highlightFilter">
				<Multiselect 
					class="mt-1 "
					:options="areaOption"
					:searchable="true"
					label="name"
					:close-on-select="true"
					:show-labels="false"
					v-model="filter.territoryOptions.area"
					:placeholder="filter.territoryOptions.area.name"
				/>
				</div>
				<div class="col-12 col-md highlightFilter">
				<Multiselect 
					class="mt-1 "
					:options="options.branch"
					:searchable="true"
					:close-on-select="true"
					label="name"
					:show-labels="false"
					v-model="filter.territoryOptions.branch"
					placeholder="Choose Dealer"
				/>
				</div>
				<div class="col-12 col-md highlightFilter">
				<Multiselect 
					class="mt-1 "
					:options="options.type"
					:searchable="true"
					label="type"
					:close-on-select="true"
					:show-labels="false"
					v-model="filter.territoryOptions.type"
					placeholder="All Type"
				/>
				</div>
				<div class="col-12 col-md highlightFilter">
				<Multiselect 
					class="mt-1 "
					:options="options.color"
					:searchable="true"
					label="name"
					:close-on-select="true"
					:show-labels="false"
					v-model="filter.territoryOptions.color"
					placeholder="All Color"
				/>
				</div>
			</div>
			</div>
			<div class="row mt-2">
			<div class="content-outer col-12">
				<div class="content-inner" style="padding: 0 3%; height: 100%;">
				<TerittoryManagement 
					:area="filter.territoryOptions.area" 
					:data="data.territory[0]" 
					v-if="data.territory"
				></TerittoryManagement>
				<div v-else style="text-align:center; margin-top:20px"><h4>Loading...</h4></div>
				</div>
			</div>
		</div>
		<!-- Teritory Management End-->
	</div>
</template>

<script>
  import Multiselect  from 'vue-multiselect'
  import TerittoryManagement from './../children/TerritoryManagement/MapLoad'

export default {
	props: ['options' , 'period', 'mobile', 'data' , 'filter'],
	components: {
		Multiselect ,
		TerittoryManagement
	},
	data () {
		return {
			infoPopup : this.$store.state.hoverInformation.highlight.territoryManagement,
			areaOption: [
				{
					"name":"Jawa Tengah",
					"region":[
						'H350'
					]
				},
				{
					"name":"Banten",
					"region":[
						'H265'
					]
				},
				{
					"name":"Bengkulu",
					"region":[
						'H650'
					]
				},
				{
					"name":"Gorontalo",
					"region":[

					]
				},
				{
					"name":"Aceh",
					"region":[

					]
				},
				{
					"name":"Bali",
					"region":[
						'H540'
					]
				},
				{
					"name":"Bangka-Belitung",
					"region":[

					]
				},
				{
					"name":"Irian Jaya Barat",
					"region":[

					]
				},
				{
					"name":"Jakarta Raya",
					"region":[
						'H101'
					]
				},
				{
					"name":"Jambi",
					"region":[
						'H620'
					]
				},
				{
					"name":"Jawa Barat",
					"region":[
						'H257' , 'H258' , 'H259'
					]
				},
				{
					"name":"Jawa Timur",
					"region":[
						'H465'
					]
				},
				{
					"name":"Kalimantan Barat",
					"region":[

					]
				},
				{
					"name":"Kalimantan Selatan",
					"region":[
						'H720'
					]
				},
				{
					"name":"Kalimantan Tengah",
					"region":[

					]
				},
				{
					"name":"Kalimantan Timur",
					"region":[
						'H700', 'H800'
					]
				},
				{
					"name":"Kepulauan Riau",
					"region":[
						'H630'
					]
				},
				{
					"name":"Lampung",
					"region":[
						'H660' , 'H661', 'H663'
					]
				},
				{
					"name":"Maluku",
					"region":[

					]
				},
				{
					"name":"Maluku Utara",
					"region":[

					]
				},
				{
					"name":"Nusa Tenggara Barat",
					"region":[
						'H530'
					]
				},
				{
					"name":"Nusa Tenggara Timur",
					"region":[

					]
				},
				{
					"name":"Papua",
					"region":[
						'H798' , 'H790'
					]
				},
				{
					"name":"Riau",
					"region":[

					]
				},
				{
					"name":"Sulawesi Barat",
					"region":[

					]
				},
				{
					"name":"Sulawesi Selatan",
					"region":[
						'H760'
					]
				},
				{
					"name":"Sulawesi Tengah",
					"region":[

					]
				},
				{
					"name":"Sulawesi Tenggara",
					"region":[

					]
				},
				{
					"name":"Sulawesi Utara",
					"region":[

					]
				},
				{
					"name":"Sumatera Barat",
					"region":[

					]
				},
				{
					"name":"Sumatera Selatan",
					"region":[
						'H600'
					]
				},
				{
					"name":"Sumatera Utara",
					"region":[

					]
				},
				{
					"name":"Yogyakarta",
					"region":[
						'H360'
					]
				}
			]
		}
	},
	mounted () {
		console.log(this.filter.territoryOptions)
	}
}
</script>