<script>/* eslint-disable */</script>
<template>
    <div>
        <!--Filter Option-->
        <b-card title="Vehicle Search" :sub-title="infoPopup.title"  header-tag="h6" class="mb-4">
            <hr>
            <b-form>
                <b-form-row>
                    <b-form-group label="Region" class="col-md-3">
                        <Multiselect 
                            v-if="filterData"
                            class="mt-1"
                            :options="filterData.regions.map(x => x.loc_code)"
                            :custom-label="opt => filterData.regions.find(x => x.loc_code == opt).name"
                            :multiple="true"
                            v-model="filter.region"
                            placeholder="All Regions"
                        />
                    </b-form-group>
                    <b-form-group label="Branch" class="col-md-3">
                        <Multiselect 
                            v-if="filterData"
                            class="mt-1"
                            :options="filterData.branch.map(x => x.loc_code)"

                            :custom-label="opt => filterData.branch.find(x => x.loc_code == opt).name"
                            :multiple="true"
                            v-model="filter.branch"
                            placeholder="All Branches"
                        />
                    </b-form-group>
                    <b-form-group label="Start Date" class="col-md-3 dateCss" style="height:42px" >
                        <datepicker name="start" id="start" :format="customFormatter" bootstrap-styling v-model="filter.start"></datepicker>
                    </b-form-group>
                    <b-form-group label="End Date" class="col-md-3 dateCss" style="height:42px" >
                        <datepicker name="end" id="end" :format="customFormatter" bootstrap-styling v-model="filter.end"></datepicker>
                    </b-form-group>
                    
                </b-form-row>
                <b-form-row>
                    <b-form-group label="Scan Source" class="col-md-6">
                        <Multiselect 
                            v-if="filterData"
                            class="mt-1"
                            :options="['H1' , 'H2']"
                            :multiple="false"
                            v-model="filter.scan_source"
                            placeholder="All Vehicle"
                        />
                    </b-form-group>
                    <b-form-group label="Engine Number" class="col-md-6 dateCss" style="height:42px" >
                        <b-form-input v-model="filter.engine_no" />
                    </b-form-group>
                </b-form-row>
                <b-btn class="col-md-4 mt-4" type="button" variant="primary" @click="search(0)" :disabled="isFetching"><span class="fa fa-filter"></span>&nbsp;&nbsp;Search</b-btn>
                <b-btn class="col-md-4 mt-4" type="button" variant="success" @click="search(1)" :disabled="isFetching"><span class="fa fa-file-excel"></span>&nbsp;&nbsp;Export To Excel</b-btn>
            </b-form>
        </b-card>
        <b-card>
            <vue-universal-table
                :fields="vue_universal_table.fields"
                :buttons="vue_universal_table.buttons"
                :update="vue_universal_table.update"
                :api_url="'/api/reports/vehicle-search'"
                :filter="filter"
                :disable_sort="true"
                :fixed_header="true"
                :infinite_scroll="true"
                @onDetails="loadDetailVehicle"
                @fetchStart="isFetching = true"
                @fetchEnd="isFetching = false"
            ></vue-universal-table>
        </b-card>

        <vehicleDetailModal v-on:closeModal="showModal = false" v-if="showModal" :data="vehicleData" :info="vehicleInfo" :popUp="infoPopup.modal"></vehicleDetailModal>        
    </div>
</template>

<script>
import moment from 'moment'
import Multiselect  from 'vue-multiselect'
import Datepicker from 'vuejs-datepicker'
// Component
import vehicleDetailModal from './childComponents/vehicleDetailModal'


export default {
    metaInfo: {
        title: 'Vehicle Search Report'
    },
    components: {
        Multiselect ,
        Datepicker,
        // component
        vehicleDetailModal
    },
    data () {
        let today = new moment()
        let startOfMonth = new moment().startOf('month')

        return {
            isFetching : false,
            vue_universal_table : {
                fields : [
                    {
                        title: 'QR Code',
                        variable: 'qrcode'
                    },
                    {
                        title : 'Engine Number',
                        variable : 'engine_no'
                    },
                    {
                        title : 'Frame Number',
                        variable : 'frame_no'
                    },
                    {
                        title : 'Commercial Name',
                        variable : 'commercial_name'
                    },
                    {
                        title : 'Current Location',
                        variable : 'current_loc_code'
                    },
                    {
                        title : 'Current Activity',
                        variable : 'current_activity'
                    },
                    {
                        title: 'Status',
                        variable: 'status'
                    },
                    {
                        title : 'Created At',
                        variable : 'created_at',
                        date_format : 'YYYY-MM-DD HH:mm:ss'
                    }
                ],
				buttons : [
					{
						title : 'Details',
						class : 'fa fa-motorcycle btn-outline-primary'
					}
				],
				update : 0,
            },
            moment: moment,
            filter: {
                region: [],
                branch: [],
                start: '',
                end: '',
                scan_source: ''
            },
            filterData: Object.assign({}, this.$store.state.filterData),
            meta: null,
            tableData: null,
            vehicleData: null,
            vehicleInfo: null,
            showModal: false,
            infoPopup : this.$store.state.hoverInformation.report.vehicleSearch
        }
    },
    mounted () {
        this.search(0)
    },
    watch: {
        'filter.page': function () {
            this.search()
        },
        'filter.limit': function () {
            this.filter.page = 1;
            this.search()
        }
    },
    methods: {
        customFormatter : function(date){
			return moment(date).format('Y-MM-DD');
		},
        downloadExcel (filter) {
            let url = '/api/reports/vehicle-search/download-excel' + filter

            this.$http.get(url , {responseType : 'blob'})
            .then((resp)=>{
                const blob = new Blob([resp.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                let url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = "Vehicle Data (" + this.filter.range + ")";
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((err)=>{
                console.log(err);
            })
        },
        loadDetailVehicle (vehicleInfo) {
            this.vehicleData = null
            this.showModal = false
            this.vehicleInfo = JSON.parse(vehicleInfo)
            let url = `/api/reports/vehicle-search/tracking/${this.vehicleInfo.engine_no}`

            this.$http.get(url)
            .then((resp) => {
                this.vehicleData = resp.data.data
                this.showModal = true
            })
            .catch((err) => {
                console.log(err)
            })
        },
        loadAPI (filter) {
            this.tableData = null
            let url = '/api/reports/vehicle-search' + filter

            this.$http.get(url)
            .then((resp) => {
                this.tableData = resp.data.data
                this.meta = resp.data.meta
                console.log(this.tableData)
                console.log(this.meta)
            })
            .catch((err) => {
                console.log(err)
            })
        },
        async search (excel) {
            this.isFetching = true;
            if(excel == 0) this.vue_universal_table.update++
            let filter = await this.transformFilter(this.filter)
            let filterString = await this.initialiseFilter(filter)
            console.log(filterString)
            if(excel == 1) this.downloadExcel(filterString)
        },
        async transformFilter (filter) {
            let obj = {}
            let keys = Object.keys(filter)
            for (var i = 0 ; i < keys.length; i++) {
                if(keys[i] == 'engine_no' || keys[i] == 'start' || keys[i] == 'end'){
                    obj[keys[i]] = filter[keys[i]]
                    continue
                }
                else {
                    obj[keys[i]] = []
                    for (var j = 0; j < filter[keys[i]].length; j++) {
                        keys[i] == 'region' || keys[i] == 'branch' ? obj[keys[i]].push(filter[keys[i]][j].loc_code) : obj[keys[i]].push(filter[keys[i]][j][keys[i].toLowerCase()])
                    }
                }
            }
            return obj
        },
        async initialiseFilter (filter) {
            let filterString = '?'
            let keys = Object.keys(filter)

            for (var i = 0; i < keys.length; i++) {
                if(keys[i] != 'start' && keys[i] != 'end'){
                    if(Array.isArray(filter[keys[i]])){
                        if(filter[keys[i]].length > 0) {
                            filterString += `${keys[i]}=${filter[keys[i]]}&`
                        }
                    }
                    else if(filter[keys[i]] != '' || filter[keys[i]] != null)
                        filterString += `${keys[i]}=${filter[keys[i]]}&`
                }
                else {
                    if(filter[keys[i]] == '') continue
                    filterString += `${keys[i]}=${moment(filter[keys[i]]).format('Y-MM-DD')}&`
                }
            }
            return filterString
        }
    }
}
</script>

<style>
  .dateCss div input{
    border: 1px solid #e8e8e8;
    border-radius: 5px !important;
    height: 42px;
    margin-top: 3px;
    padding-left: 1% !important;
  }

  .dateCss div input::placeholder{
    padding-left: 1%;
  }

</style>

