<template>
  <div>
    <div class="row mt-3 mb-2">
      <div class="col-md-4">
        <Multiselect
          v-model="monthChosen"
          :options="options.timeChoices"
          track-by="value"
          label="label"
          :allow-empty="false"
          :multiple="false"
          placeholder="Choose Month"
        ></Multiselect>
      </div>
      <div class="col-md-4 text-center">
        <h3 class="mb-0">Details by {{ capitalize(type) }}</h3>
        <p v-if="type == 'dealer'">Top 10 Dealer</p>
      </div>
      <div class="col-md-4 text-right">
        <button class="btn btn-sm btn-primary" @click="requestExportExcel">Export To Excel</button>
      </div>
    </div>
    <div class="row">
      <div class="col" style="overflow-x: auto;">
        <table class="table w-100" v-if="data">
          <thead>
            <tr>
              <th rowspan="2" class="text-center" style="vertical-align: middle;">{{ capitalize(type) }} Code</th>
              <th :colspan="maximumDate" class="text-center">Date</th>
              <th rowspan="2" class="text-center" style="vertical-align: middle;">Total</th>
            </tr>
            <tr>
              <th v-for="i in maximumDate" class="text-center">{{ i }}</th>
            </tr>
          </thead>
          <tbody style="max-height:300px;">

            <!-- IS Fetching -->
            <tr v-if="isFetching">
              <td colspan="100%">
                <h3 class="text-center p-5">Loading...</h3>
              </td>
            </tr>

            <!-- IS Data Empty -->
            <tr v-else-if="dataList.length == 0">
              <td colspan="100%">
                <h3 class="text-center p-5">Data Empty!</h3>
              </td>
            </tr>

            <!-- Show Data -->
            <tr v-for="dat in dataList" v-else>
              <td>{{ dat.label }}</td>
              <td v-for="da in dat.dateCounts" class="text-center">{{ numberWithCommas(da) }}</td>
              <td>{{ numberWithCommas(dat.total) }}</td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import XLSX from 'xlsx'
  import moment from 'moment'
  import Multiselect  from 'vue-multiselect'

  export default {
    data () {
      return {
        isFetching : false,
        options : {
          timeChoices : [],
        },
        monthChosen : null,
        data : [],
        rawData : [],
      }
    },
    props : ['filterUrl', 'time_choices', 'type'],
    components : {
      Multiselect,
    },
    mounted () {
      this.fetchData()
    },
    watch : {
      'filterUrl' : function () {
        this.fetchData()
      },
      'time_choices' : {
        handler () {
          if (this.monthChosen == '') this.monthChosen = this.time_choices[0]
          else if (this.time_choices.map(x=>x.value).indexOf(this.monthChosen) === -1) this.monthChosen = this.time_choices[0]
          this.options.timeChoices = this.time_choices
        },
        immediate:true
      },
      'monthChosen' : function () {
        this.fetchData();
      },
    },
    computed : {
      dataList(){
        if(this.data.length <= 10 || this.type == 'region') return this.data;
        //Create TOP 10 For Details By Dealer
        else return this.data.slice(0,10);
      },
      maximumDate () {
        return moment().set({'year':this.monthChosen.year, 'month':parseInt(this.monthChosen.month)-1}).daysInMonth();
      }
    },
    methods : {
      fetchData(){
        this.isFetching = true;
        
        //Original URL
        var url = `/api/h2/replaced-qr/table${this.type == 'dealer' ? '-dealer' : ''}?`;

        //Add Filters
        url += `${this.filterUrl}&month=${this.monthChosen.month}&year=${this.monthChosen.year}`;

        this.$http.get(url)
        .then((resp)=>{
          var data = resp.data.data

          this.rawData = data;
          this.splitRegion(data);
        })
        .catch((err)=>{console.log(err);})
        .finally(()=>{
          this.isFetching = false;
        })
      },
      requestExportExcel() {
        //Original URL
        var url = `/api/h2/replaced-qr/table-dealer/download?table=${this.type == 'dealer' ? 'DEALER' : 'REGION'}&`

        //Add Filters
        url += `${this.filterUrl}&month=${this.monthChosen.month}&year=${this.monthChosen.year}`;

        this.$http.get(url)
        .then((resp)=>{
          this.$swal.fire({
            title: 'Request Submitted',
            text: `Replaced QR Details by ${this.type == 'dealer' ? 'Dealer' : 'Region'} document request has been successfully submitted`,
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#3085D6',
            cancelButtonColor: '#D33',
            confirmButtonText: 'To Download Page',
            cancelButtonText: 'Ok'
          }).then((result) => {
            if (result.value) {
              console.log('confirmed');
              this.$router.push(`/report/download-report/`)
            }
          })
        })
      },
      splitRegion(data) {

        let localVar = this.type == 'dealer' ? 'dealer' : 'region_code'

        let finalData = [];

        let lastData = "";
        let arrayCounter = -1;//Number of array in final data
        // let roundCounter = 1;//Position of date in object
        let totalCounter = 0;//Total counter

        for (var i = 0; i < data.length; i++) {
          if (data[i][localVar] != lastData) {
            //Add each data's total <+++
            if (arrayCounter!=-1) finalData[arrayCounter]['total'] = totalCounter

            //Reset for new
            lastData = data[i][localVar];
            arrayCounter++;
            totalCounter = 0;
            // roundCounter = 1;

            //Add new array and each data's label<+++
            finalData.push({'label':data[i][localVar], 'dateCounts' : []});
          }

          //Add each date's data<+++
          finalData[arrayCounter].dateCounts.push(data[i].count);

          //Counter adds
          // roundCounter++;
          totalCounter += parseInt(data[i].count);
        }

        //Add the last data's total
        if (data.length != 0) finalData[arrayCounter]['total'] = totalCounter;

        this.data = finalData;
      },
    }
  }
</script>

<style scoped>

</style>