<template>
  <div id="chartdiv" ref="scanByDate" style="min-height:600px"></div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

export default{
  props : ['filterUrl'],
  name : 'replacedQrByDate',
  data(){
    return {
      chart : null,
      indicator1 : null,
      isUpdates : this.isUpdate,
      indicatorOn : false
    }
  },
  mounted(){
    this.createMChart();
    this.generateChartData();
  },
  watch : {
    filterUrl: function(){
      this.generateChartData();
    }
  },
  methods : {
    generateChartData(){
      var length = this.chart.data.length;
      let vm = this;
      this.showIndicator();

      this.$http.get('/api/h1/replaced-qr?' + this.filterUrl)
      .then((resp)=>{
        var data = resp.data.data;
    
        for(var i = 0 ; i < data.length ; i ++){
          data[i].tanggal = new Date(data[i].tanggal)
        }

        vm.chart.addData(data , length);
      })
      .then(()=>{
        vm.hideIndicator();
      })
  },
  createMChart(){
    //first
    this.chart = am4core.create(this.$refs.scanByDate, am4charts.XYChart);

    this.chart.data = this.chartData;
    this.chart.preloader.disabled = true;
    this.chart.exporting.menu = new am4core.ExportMenu();
    this.chart.exporting.menu.items =
    [
      {
        "label": "...",
        "menu": [
          {
            "label": "Image",
            "menu": [
              { "type": "png", "label": "PNG" },
              { "type": "jpg", "label": "JPG" },
              { "type": "gif", "label": "GIF" },
              { "type": "svg", "label": "SVG" },
              { "type": "pdf", "label": "PDF" }
            ]
          }
        ]
      }
    ];

    let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 50;

    let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "QR Replaced"
    valueAxis.maxPrecision = 0;
    valueAxis.min = 0;

    this.createAxisAndSeries(this.chart,"count", "Quantity", false, "circle", valueAxis);

    // Add cursor
    this.chart.cursor = new am4charts.XYCursor();
    // this.chart2.cursor = new am4charts.XYCursor();

    this.chart.legend = new am4charts.Legend();

  },
  createAxisAndSeries(chart,field, name, opposite, bullet, valueAxis){

    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = field;
    series.dataFields.dateX = "tanggal";
    series.strokeWidth = 2;
    series.yAxis = valueAxis;
    series.name = name;
    series.tooltipText = "{name}: [bold]{valueY}[/]";
    series.tensionX = 0.8;

    let interfaceColors = new am4core.InterfaceColorSet();

    switch(bullet) {
      case "triangle":
        let bullet1 = series.bullets.push(new am4charts.Bullet());
        bullet1.width = 12;
        bullet1.height = 12;
        bullet1.horizontalCenter = "middle";
        bullet1.verticalCenter = "middle";

        let triangle = bullet1.createChild(am4core.Triangle);
        triangle.stroke = interfaceColors.getFor("background");
        triangle.strokeWidth = 2;
        triangle.direction = "top";
        triangle.width = 12;
        triangle.height = 12;
        break;
      case "rectangle":
        let bullet2 = series.bullets.push(new am4charts.Bullet());
        bullet2.width = 10;
        bullet2.height = 10;
        bullet2.horizontalCenter = "middle";
        bullet2.verticalCenter = "middle";

        let rectangle = bullet2.createChild(am4core.Rectangle);
        rectangle.stroke = interfaceColors.getFor("background");
        rectangle.strokeWidth = 2;
        rectangle.width = 10;
        rectangle.height = 10;
        break;
      default:
        let bullet3 = series.bullets.push(new am4charts.CircleBullet());
        bullet3.circle.stroke = interfaceColors.getFor("background");
        bullet3.circle.strokeWidth = 2;
        break;
    }

    var labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.text = "[bold]{valueY}";
    // labelBullet.label.dy = -15;

    valueAxis.renderer.line.strokeOpacity = 1;
    valueAxis.renderer.line.strokeWidth = 2;
    valueAxis.renderer.line.stroke = series.stroke;
    valueAxis.renderer.labels.template.fill = series.stroke;
    valueAxis.renderer.opposite = opposite;
    valueAxis.renderer.grid.template.disabled = true;
  },
  createAxisAndSeriesSales(chart,field, name, opposite, bullet, valueAxis){

    let valueAxisSales = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxisSales.title.text = "Scans"
    valueAxisSales.maxPrecision = 0;
    valueAxisSales.min = 0;

    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = field;
    series.dataFields.dateX = "tanggal";
    series.strokeWidth = 2;
    series.yAxis = valueAxisSales;
    series.name = name;
    series.tooltipText = "{name}: [bold]{valueY}[/]";
    series.tensionX = 0.8;

    let interfaceColors = new am4core.InterfaceColorSet();

    let bullet1 = series.bullets.push(new am4charts.Bullet());
    bullet1.width = 12;
    bullet1.height = 12;
    bullet1.horizontalCenter = "middle";
    bullet1.verticalCenter = "middle";

    let triangle = bullet1.createChild(am4core.Triangle);
    triangle.stroke = interfaceColors.getFor("background");
    triangle.strokeWidth = 2;
    triangle.direction = "top";
    triangle.width = 12;
    triangle.height = 12;

    valueAxisSales.renderer.line.strokeOpacity = 1;
    valueAxisSales.renderer.line.strokeWidth = 2;
    valueAxisSales.renderer.line.stroke = series.stroke;
    valueAxisSales.renderer.labels.template.fill = series.stroke;
    valueAxisSales.renderer.opposite = opposite;
    valueAxisSales.renderer.grid.template.disabled = true;
  },
  showIndicator() {
    if(this.indicatorOn == true)
      return;

    this.indicatorOn = true;

    this.indicator1 = this.chart.tooltipContainer.createChild(am4core.Container);
    this.indicator1.background.fill = am4core.color("#fff");
    this.indicator1.background.fillOpacity = 0.8;
    this.indicator1.width = am4core.percent(100);
    this.indicator1.height = am4core.percent(100);

    let indicatorLabel1 = this.indicator1.createChild(am4core.Label);
    indicatorLabel1.text = "Loading stuff...";
    indicatorLabel1.align = "center";
    indicatorLabel1.valign = "middle";
    indicatorLabel1.fontSize = 20;

    // this.indicator2 = this.chart2.tooltipContainer.createChild(am4core.Container);
    // this.indicator2.background.fill = am4core.color("#fff");
    // this.indicator2.background.fillOpacity = 0.8;
    // this.indicator2.width = am4core.percent(100);
    // this.indicator2.height = am4core.percent(100);
    //
    // let indicatorLabel2 = this.indicator2.createChild(am4core.Label);
    // indicatorLabel2.text = "Loading stuff...";
    // indicatorLabel2.align = "center";
    // indicatorLabel2.valign = "middle";
    // indicatorLabel2.fontSize = 20;
    },
    hideIndicator(){
      this.indicatorOn = false;
      this.indicator1.hide();
    }
  }
}
</script>
