
<script>/* eslint-disable */</script>
<template>
	<div ref="ByHourChart" style="min-height:500px"></div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly.js";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import axios from 'axios'
am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_kelly);

export default{
  name : 'CustomerHour',
  props : ['data', 'type'],
  data(){
    return {
      company : sessionStorage.getItem('company'),
      chart : null,
      indicator : null,
      isUpdates : this.isUpdate,
      flag : false //Prevent Duplication Showing Loading Indicator
    }
  },
  mounted(){
	this.createMChart();
	this.getData()
  },
  methods : {
    createMChart(){
      this.chart = am4core.create(this.$refs.ByHourChart, am4charts.XYChart);
      this.chart.hiddenState.properties.opacity = 0; // this creates initial fade-in


      var categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "hour";
      categoryAxis.autoGridCount = false;
      categoryAxis.title.text = "Hour (H)"

      var valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = "Quantity";
      valueAxis.title.rotation = 0;
      valueAxis.title.align = "center";
      this.xAxis = true;
      valueAxis.maxPrecision = 0;
      valueAxis.min = 0;

      // Create series
      var series = this.chart.series.push(new am4charts.ColumnSeries());

	  series.dataFields.valueY = "count";
	  series.name = "Average";
      
      series.dataFields.categoryX = "hour";
      series.columns.template.tooltipText = `Average ${ this.capitalize(this.type) } At [bold]{categoryX}:00 : [bold]{valueY}[/]`;
      series.columns.template.fillOpacity = .8;

      var labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = "[bold]{valueY}";
      labelBullet.label.dy = -15;

    //   var lineSeries = this.chart.series.push(new am4charts.LineSeries());
    //   lineSeries.name = "Max";
    //   lineSeries.dataFields.valueY = "max";
    //   lineSeries.dataFields.categoryX = "hour";

    //   var bullet = lineSeries.bullets.push(new am4charts.Bullet());
    //   bullet.tooltipText = "[#fff font-size: 15px]{name} in {categoryX}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]"
    //   var circle = bullet.createChild(am4core.Circle);
    //   circle.radius = 4;
    //   circle.fill = am4core.color("#fff");
    //   circle.strokeWidth = 3;

    //   var lineSeries2 = this.chart.series.push(new am4charts.LineSeries());
    //   lineSeries2.name = "Min";
    //   lineSeries2.dataFields.valueY = "min";
    //   lineSeries2.dataFields.categoryX = "hour";

    //   var bullet2 = lineSeries2.bullets.push(new am4charts.Bullet());
    //   bullet2.tooltipText = "[#fff font-size: 15px]{name} in {categoryX}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]"
    //   var circle2 = bullet2.createChild(am4core.Circle);
    //   circle2.radius = 4;
    //   circle2.fill = am4core.color("#fff");
    //   circle2.strokeWidth = 3;

      this.chart.legend = new am4charts.Legend();

      var columnTemplate = series.columns.template;
      columnTemplate.strokeWidth = 2;
      columnTemplate.strokeOpacity = 1;
    },
    getData(){
	  let length  = this.chart.data.length
	  this.chart.addData(this.data , length)
    }
  }
}
</script>
