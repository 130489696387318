<template>
	<div ref="stockIntransitBubble" style="height:750px"></div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import spiritedaway from '@amcharts/amcharts4/themes/spiritedaway.js'
import animated from '@amcharts/amcharts4/themes/animated.js'
import * as am4plugins_forceDirected from "@amcharts/amcharts4/plugins/forceDirected";

am4core.useTheme(spiritedaway)
am4core.useTheme(animated)

export default {
	mounted () {
		this.createChart()
		this.loadData()
	},
	props: ['data', 'filter'],
	methods: {
		async loadData () {
			let length = this.chart.data.length
			let obj = {}
			let data = await this.splitRegion(obj , this.data)
			
			let newData = await this.formatData(data)
			console.log(newData)
			this.chart.addData(newData , length)
		},
		formatData(data) {
			let newData = []

			let keys = Object.keys(data)

			for(var i = 0 ; i < keys.length ; i++) {
				let obj = {}
				obj.name = keys[i]

				obj.children = []
				let dataChildren = data[keys[i]]

				for(var j = 0 ; j < dataChildren.length ; j++) {
					obj.children.push({
						name : dataChildren[j].tujuan,
						value : dataChildren[j].count
					})
				}

				newData.push(obj)
			}

			return newData;
		},
		splitRegion(obj , data) {

			data.sort((a, b) =>  (b.count)- (a.count));
			for(var i = 0 ; i < data.length ; i++) {

				if(!obj[data[i].start]) {
					obj[data[i].start] = []
					obj[data[i].start].push(data[i])
				}
				else{
					obj[data[i].start].push(data[i])
				}
			}
			return obj
		},
		createChart () {
			this.chart = am4core.create(this.$refs.stockIntransitBubble, am4plugins_forceDirected.ForceDirectedTree)
			let networkSeries = this.chart.series.push(new am4plugins_forceDirected.ForceDirectedSeries())
			networkSeries.dataFields.linkWith = "linkWith";
			networkSeries.dataFields.name = "name";
			networkSeries.dataFields.id = "name";
			networkSeries.dataFields.value = "value";
			networkSeries.dataFields.children = "children";
			networkSeries.links.template.distance = 1;
			networkSeries.nodes.template.tooltipText = "{name} : {value} Unit";
			networkSeries.nodes.template.fillOpacity = 1;
			networkSeries.nodes.template.outerCircle.scale = 1;

			networkSeries.nodes.template.label.text = "{name}"
			networkSeries.fontSize = 8;
			networkSeries.nodes.template.label.hideOversized = true;
			networkSeries.nodes.template.label.truncate = true;
			networkSeries.minRadius = am4core.percent(2);
			networkSeries.manyBodyStrength = -5;
			networkSeries.links.template.strokeOpacity = 0;
		}
	}

}
</script>