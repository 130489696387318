<script>/* eslint-disable */</script>
<template>
  <div ref="MappingSummaryChart" class="h-100" style="min-height: 400px"></div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

export default {
  data () {
    return {
      chart: null,
    }
  },
  props:['data', 'type'],
  mounted () {
    this.createChart()
    this.processData()
  },
  methods: {
    processData () {
      var length = this.chart.data.length;
      
      var data = this.data
      
      this.chart.addData(data, length);
    },
    createChart () {
      this.chart = am4core.create(this.$refs.MappingSummaryChart, am4charts.XYChart);

      // var gradient = new am4core.LinearGradient();
      // gradient.addColor(am4core.color("#142850"));
      // gradient.addColor(am4core.color("#FFF"));

      // var gradient2 = new am4core.LinearGradient();
      // gradient2.addColor(am4core.color("#FFC400"));
      // gradient2.addColor(am4core.color("#FFEDB2"));

      // Create axes
      let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = 'name';
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;
      categoryAxis.title.text = 'Region'

      let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = "No of QR Registered";
      valueAxis.min = 0;

      

      // Create series
      let series = this.chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = "count";
      series.dataFields.categoryX = 'name'
      series.clustered = false;
      series.name = "Actual";
      series.columns.template.width = am4core.percent(70);
      series.tooltipText = "Actual Mapping of {categoryX}: [bold]{valueY}[/]";

      var labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.horizontalCenter = "middle";
      labelBullet.label.dy = -10;
      labelBullet.label.text = "[bold]{valueY}";
      labelBullet.locationY = 1;

      // series.columns.template.fill = gradient;

      // let series2 = this.chart.series.push(new am4charts.ColumnSeries());
      // series2.dataFields.valueY = "current";
      // series2.dataFields.categoryX = "karesidenan";
      // series2.clustered = false;
      // series2.name = "Number Of Sales";
      // series2.columns.template.width = am4core.percent(50);
      // series2.tooltipText = "Sales of {categoryX}: [bold]{valueY}[/]";
      // series2.columns.template.fill = gradient2;

      // let series3 = this.chart.series.push(new am4charts.LineSeries());
      // series3.dataFields.valueY = "target";
      // series3.dataFields.categoryX = "karesidenan";
      // series3.strokeWidth = 2;
      // series3.name = "Target";
      // series3.tooltipText = "Target Sales of {categoryX}: [bold]{valueY}[/]";
      // series3.tooltipColorSource = am4core.color("#000")
      // series3.tensionX = 0.8;
      // series3.showOnInit = true;

      // let interfaceColors = new am4core.InterfaceColorSet();

      // let bullet = series3.bullets.push(new am4charts.Bullet());
      // bullet.width = 12;
      // bullet.height = 12;
      // bullet.horizontalCenter = "middle";
      // bullet.verticalCenter = "middle";
      
      // let triangle = bullet.createChild(am4core.Triangle);
      // triangle.stroke = interfaceColors.getFor("background");
      // triangle.strokeWidth = 2;
      // triangle.direction = "top";
      // triangle.width = 12;
      // triangle.height = 12;

      this.chart.cursor = new am4charts.XYCursor();
      this.chart.cursor.lineX.disabled = true;
      this.chart.cursor.lineY.disabled = true;

      // Add legend
      this.chart.legend = new am4charts.Legend();
    }
  }
}
</script>