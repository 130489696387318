<template>
    <div>

        <div class="row" style="background-color: #fff; border: 0 solid rgba(24, 28, 33, 0.06); border-radius: 0.125rem; box-shadow: 0 10px 30px -15px rgba(24, 28, 33, 0.44); border-radius: 16px;">
            <div class="col-12" style="padding: 1.7rem">
                
                <!-- Filter Start -->
                <div class="row">
                    <div class="col-md-4">
                        <h3 class="chart--title">H2 Dealer Report</h3>

                        <h3 class="chart--subtitle mt-3">Total Service : {{ data ? numberWithCommas(data.length) : '-' }}</h3>
                    </div>
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-md-4">
                                <flat-pickr
                                    class="mt-3 mb-3"
                                    v-model="selected.range"
                                    :config="rangeConfig"
                                    :placeholder="!isIEMode ? 'Range picker' : null"
                                />
                            </div>
                            <div class="col-md-4">
                                <MultiSelect
                                    class="multiselect-h2-dealer-report"
                                    v-model="filter.region"
                                    :options="options.region.map(x => x.loc_code)"
                                    :custom-label="opt => options.region.find(x => x.loc_code == opt).name"
                                    :allow-empty="false"
                                    placeholder="Select Region"
                                ></MultiSelect>
                            </div>
                            <div class="col-md-4">
                                <MultiSelect
                                    class="multiselect-h2-dealer-report"
                                    v-model="filter.branch"
                                    :options="options.branch.map(x => x.loc_code)"
                                    :custom-label="opt => options.branch.find(x => x.loc_code == opt).name"
                                    :disabled="!filter.region"
                                    :allow-empty="false"
                                    placeholder="Select Branch"
                                ></MultiSelect>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Filter End -->
                
                <h2-dealer-report-table
                    class="mt-3"
                    :data="data"
                    :selected="selected"
                    :filter="filter"
                    :isLoading="isLoading"
                    :isBackDate="isBackDate"
                    @requestReload="fetchData"
                ></h2-dealer-report-table>

            </div>
        </div>

    </div>
</template>

<script>
    import MultiSelect from 'vue-multiselect';
    import flatPickr from 'vue-flatpickr-component';
    import moment from 'moment';
    import qs from 'qs';

    import h2DealerReportTable from './children/h2DealerReportTable';

    export default {
        data () {
            return {
                rangeConfig: {
                    mode: 'range',
                    altInput: true,
                    altInputClass: 'form-control input custom-download-flatpickr w-100',
                    animate: !this.isRTL()
                },
                
                isLoading : false,
                isBackDate : false,
                isWaitingFetch : false,

                proposedStart : null,
                proposedEnd : null,

                options : {
                    region : this.$store.getters.getRegion,
                    branch : this.$store.getters.getSimpleBranch()
                },
                selected : {
                    range : `${moment().subtract(7, "days").format('YYYY-MM-DD')} to ${moment().format('YYYY-MM-DD')}`
                },
                prevFilter : {
                    range : `${moment().subtract(7, "days").format('YYYY-MM-DD')} to ${moment().format('YYYY-MM-DD')}`,
                    start : moment().subtract(7, 'days').format('YYYY-MM-DD 00:00:00'),
                    end : moment().format('YYYY-MM-DD 23:59:59')
                },
                filter : {
                    start : moment().subtract(7, 'days').format('YYYY-MM-DD 00:00:00'),
                    end : moment().format('YYYY-MM-DD 23:59:59'),
                    region : null,
                    branch : null,
                    order : 'DESC',
                    by : 'active_date'
                },

                data : null,
            }
        },
        components: {
            MultiSelect,
            flatPickr,

            h2DealerReportTable
        },
        mounted () {
            this.autoFetch();
        },
        watch : {
            'selected.range' () {
                let splitDateRange = this.selected.range.split(' to ');

                let proposedStart = splitDateRange[0] ? moment(splitDateRange[0]).format('YYYY-MM-DD 00:00:00') : null;
                let proposedEnd = splitDateRange[0] ? moment(splitDateRange[splitDateRange.length == 2 ? 1 : 0]).format('YYYY-MM-DD 23:59:59') : null;

                this.isBackDate = !(moment().isBetween(moment(proposedStart), moment(proposedEnd)) || moment().isSame(moment(proposedEnd)));

                if (moment(proposedEnd).diff(moment(proposedStart), 'days') > 31) {
                    this.$swal.fire(
                        'Date selection cannot be more than 31 days',
                        '',
                        'warning'
                    )

                    //Revert to previous filter
                    this.selected.range = this.prevFilter.range;
                    this.filter.start = this.prevFilter.start;
                    this.filter.end = this.prevFilter.end;
                } else {
                    this.proposedStart = proposedStart
                    this.proposedEnd = proposedEnd

                    this.debounceFetch();
                }

            },
            'filter.region': function () {
                this.data = null;

                this.filter.branch = null;
                this.options.branch = this.$store.getters.getSimpleBranch(this.filter.region)
            },
            'filter.branch' () {
                this.data = null;

                if (this.filter.branch) this.fetchData();
            },
        },
        methods : {
            autoFetch () {
                //Auto fetch every 5 minutes
                setInterval(()=>{
                    if (this.filter.start && this.filter.end && this.filter.region && this.filter.branch && !this.isBackDate) this.fetchData(true);
                }, 300000);
            },
            debounceFetch () {
                if (this.isWaitingFetch) return;
                this.isWaitingFetch = true;

                //Wait for 2.5 seconds
                setTimeout(()=>{
                    this.prevFilter.range = this.selected.range;
                    this.prevFilter.start = this.filter.start = this.proposedStart;
                    this.prevFilter.end = this.filter.end = this.proposedEnd;

                    this.fetchData();
                    this.isWaitingFetch = false;
                }, 2500);
            },
            fetchData (isSilent, sort) {
                if (this.filter.branch == null) return;
                if (!isSilent) this.isLoading = true;

                if (sort) {
                    this.filter.order = sort.order ? 'ASC' : 'DESC'
                    this.filter.by = sort.by
                }

                this.$http.get(`/api/reports/h2-report/details?${qs.stringify(this.filter, {skipNulls:true})}`)
                .then((resp)=>{
                    this.data = resp.data.data;
                })
                .catch((err)=>{ console.log(err) })
                .finally(()=>{
                    this.isLoading = false;
                })
            }
        }
    }
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@500;700&display=swap');

    .chart--title {
        margin: 0;
        font: normal normal 500 18px/21px Ubuntu;
        color: #707070;
    }
    
    .chart--subtitle {
        margin: 0;
        font: normal normal 500 14px/18px Ubuntu;
        color: #A2A2A2;
    }

    .btn-elegant {
        background: #FFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 6px #00000014;
        border: 0.5px solid #EEE;
        padding: 0.5rem 2rem;
        font: normal normal 500 10px/11px Ubuntu;
    }

    .btn-elegant-unselected {
        background: #EEE 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 6px #00000014;
        padding: 0.5rem 2rem;
        font: normal normal 500 10px/11px Ubuntu;
    }

    .multiselect-h2-dealer-report {
        box-shadow: 0px 2px 6px #0000001A;
        border-radius: 5px;
        font: normal normal 500 10px/11px Ubuntu;
        color: #676767;
    }

    .h2DealerReport .multiselect__tags  {
        background-color: #E25A5A !important;
    }

    .h2DealerReport .multiselect__single , .h2DealerReport .multiselect__input , .h2DealerReport .multiselect__input::placeholder , .h2DealerReport .multiselect__placeholder {
        background-color: #E25A5A !important;
        color: white;
    }
    
    .h2DealerReport .multiselect__select:before{
        color: white !important;
    }

    .h2DealerReport.disabledButton .multiselect__select {
        background-color: #E25A5A
    }

    .elegant-flatpickr {
        border: 0;
        background: #FFF 0% 0% no-repeat padding-box !important;
        box-shadow: 0px 0px 6px #0000001A;
        border-radius: 5px !important;
        padding: 7px 0px 7px 8px !important;
        height: 40px;
    }

    .elegant-flatpickr:focus {
        border: 0;
        box-shadow: 0px 0px 6px #0000001A;
    }
</style>

<style scoped>
</style>